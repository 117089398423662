import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { formatFullDate } from "../../utils/DateFormat";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { AiOutlineUserAdd } from "react-icons/ai";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { listview } from "../../models/modelList";
import PageSelector from "../../utils/PageSelector";



// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  toggleEdit: any;
  handleState: any;
};

type State = {
  blackout: boolean;
  contacts: any;
  search: string;
  loaded: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  authorization: boolean;
};

type TenantContact = {
  id: number;
  contact: string;
  phone_number: string; 
  first_name:string;
  last_name:string;
  email: string;
};

class OtherContactList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      contacts: [],
      search: "",
      loaded: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      authorization: true,
    };
  }

  handleModal = (id: string) => {
    this.props.handleState(id, false);
  };

  handleEdit = (id: number) => {
    store.dispatch({ type: "tenantIdTransfer", id: id });
    this.props.toggleEdit("editTenant", false);
  };

  handleClick = (id: number) => {
    store.dispatch({type: "tenantIdTransfer", id: id});
    this.props.toggleEdit("tenantProfile", false);
  }
  componentDidMount = async () => {
    this.updateList();
    store.dispatch({ type: "updateTenantList", listUpdate: this.updateList })
  };

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
    
  }

  setPerPage = async (val: number) => {
    await this.setState({perpage: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState({
          curpage: this.state.totalpages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }
  
  // Populate list entries with corporate contact relation from database
  updateList = async (contactName?: string) => {
    let search = this.state.search.trim();

    console.log({search: search, page: this.state.curpage, perpage: this.state.perpage});
    const allContacts: listview<TenantContact[]> = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_TENANTS}?${this.state.search ? `search=${this.state.search.trim()}&`: ''}page=${this.state.curpage}&perpage=${this.state.perpage}`, 
    )

    let contactsList = []; // to reset the state and rerender the page

    if (allContacts.status != "Success") {
      this.setState({
        loaded: true,
        authorization: allContacts.status == "Error Unauthorized" ? false : true
      })
      return;
    }

    this.setState({totalpages: allContacts.data.pages})
    for (let contact of allContacts.data.rows) {
      let contactObj = {
        id: -1,
        contact: "",
        phone_number: "",
        name:"",
        email: "",
      };
      contactObj.id = contact.id;
      contactObj.contact = contact.contact;
      contactObj.phone_number = contact.phone_number;
      contactObj.name = contact.first_name+" "+contact.last_name;
      contactObj.email = contact.email;

      contactsList.push(contactObj);
    }
    this.setState({ contacts: contactsList, loaded: true });
  };

  render() {
    // const { history } = this.props;
    let Blackout = this.state.blackout;

    let blackoutStyles = "transition-all duration-200";
    let Warnings = "";
    let blackoutToggle = (
      <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"} />
    );

    if (Blackout === true) {
      blackoutStyles = "transition-all duration-200 opacity-10 ";
      Warnings = "Do Not Book";
      blackoutToggle = (
        <FaPowerOff
          className={"text-2xl text-green-500 hover:text-green-300"}
        />
      );
    }

    let TableRows = <></>;
    var rows = [];
    let today = new Date();
    let testDueDate = new Date(2021, 7, 8, 4, 20);
    let altBg = "";
    let OverDueStyle = "text-gray-700";
    let OverDueStyle2 = "text-gray-700";
    let OverDue = <></>;

    for (var i = 0; i < this.state.contacts.length; i++) {
      let contactId = this.state.contacts[i].id;
      if (testDueDate > today) {
        OverDueStyle = "text-white text-sm  w-72";
        OverDueStyle2 = "text-red-400 mr-10";
        OverDue = (
          <span className="bg-red-500 py-1 px-4 rounded-full text-white">
            Overdue
          </span>
        );
      }

      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }

      TableRows = (
        <tr
          key={i.toString()}
          className={
            altBg +
            " cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"
          }
        >
          {/* Suite Name */}

          <td
            onClick={() => this.handleClick(contactId)}
            className={" text-purple-500 w-20 py-4 pl-4"}
          >
            {contactId}
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.handleClick(contactId)}
            className={
              "  w-auto font-light text-sm text-gray-500 px-1 py-4 grid grid-cols-1"
            }
          >
            <span className="text-gray-700">
              {this.state.contacts[i].email}
            </span> 
          </td>

          {/* Name */}
          <td
            onClick={() => this.handleClick(contactId)}
            className={
              "  w-auto font-light text-sm text-gray-500 px-1 py-4"
            }
          >
            <span className="text-gray-700">
              {this.state.contacts[i].name}
            </span>
          </td>



          {/* Type */}

          <td
            onClick={() => this.handleClick(contactId)}
            className={"  font-normal  text-gray-400 px-1  py-4"}
          >
            {this.state.contacts[i].phone_number}
          </td>

          {/* Booking Warnings */}

          <td className=" w-32 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                onClick={() => this.handleEdit(contactId)}
                className={"text-gray-700 text-2xl mr-2 hover:text-gray-500"}
              />
            </div>
          </td>
        </tr>
      );

      rows.push(TableRows);
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-row align-center items-center mb-6 w-full">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm  py-1 w-1/3 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                className="text-sm searchBox mx-2 w-full"
                placeholder="Search tenant name/email"
                id="search"
                value={this.state.search}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>
            {/* <span className="py-1 px-4 font-medium margin text-gray-500 ">
              Filters:
            </span>

            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Recent
            </button>
            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Corporate
            </button>
            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Budget
            </button> */}
          </div>
          <PageSelector
            curpage={this.state.curpage}
            totalpages={this.state.totalpages}
            increase={this.pageUp}
            decrease={this.pageDown}
            setPerPage={this.setPerPage}
            perPageOptions={[10,20,30,40,50]}
          />
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="text-left font-normal text-gray-500 ">
              <th className="py-2 pl-4">ID</th>
              <th className="py-2  ">Contact</th>
              <th className="py-2  ">Name</th>
              <th className="py-2  ">Phone Number</th>

              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={3} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(OtherContactList);
