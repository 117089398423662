import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch } from "react-router-dom";
import PaymentsQuickView from "./PaymentsQuickView";
import InvoiceQuickView from "./InvoiceQuickView";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DashBar from "../DashBar";


type Props = {
  userData: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
};

class InvoiceFunctions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-screen  lg:rounded-tl-lg py-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}
        <InvoiceQuickView
          handleModalToggle={this.handleInvoiceView}
          onoff={this.state.toggleInvoice}
          info={undefined}
        />
        <PaymentsQuickView
          handleModalToggle={this.handleQuickView}
          onoff={this.state.toggleQuickView}
          InvoiceQuickView={this.handleInvoiceView}
        />

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1  w-full pt-16 lg:pt-6 pb-4">
          <DashBar placeholder={""}/>

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Finance Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          {/* Heading Info Area  */}

          <div className=" my-4 grid grid-cols-1 bg-white max-w-3xl px-6 py-8 shadow-sm rounded-lg border border-gray-400 mx-auto">
            <div className="grid grid-cols-3 gap-3 w-full">
              <button className="  whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center">
                <span className="font-normal text-sm ">Update Deloitte RV</span>
              </button>

              <button className="  whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center">
                <span className="font-normal text-sm ">
                  One Time Update Line Type
                </span>
              </button>

              <button className="  whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center">
                <span className=" font-normal text-sm ">
                  Send Cleaning Notification
                </span>
              </button>
            </div>

            <form className="mt-6 py-3 border-b w-full border-gray-400">
              <input type="file" />
            </form>
          </div>

          <div className=" my-4 grid grid-cols-1 bg-white max-w-3xl px-6 py-8 shadow-sm rounded-lg border border-gray-400 mx-auto">
            <span className="text-gray-300 mx-auto">
              Additional Content / Functions can be added in column
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceFunctions;

export function InvoiceList(props: any) {
  let currentDate = props.todaysDate;
  let testDueDate = new Date(2021, 7, 8, 4, 20);
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;
  let OverDueStyle = "text-gray-700";
  let OverDueStyle2 = "text-gray-700";
  let OverDue = <></>;

  for (var i = 0; i < length; i++) {
    if (testDueDate > currentDate) {
      OverDueStyle = "text-white text-sm  w-72";
      OverDueStyle2 = "text-red-400 mr-10";
      OverDue = (
        <span className="bg-red-500 py-1 px-4 rounded-full">Overdue</span>
      );
    }

    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    Payments = (
      <tr
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="py-4 pl-5 text-purple-400 font-thin ">{i}</td>
        <td className={OverDueStyle + " py-3 font-thin  "}>
          <span className={OverDueStyle2}>{formatFullDate(testDueDate)}</span>{" "}
          {OverDue}
        </td>
        <td className="py-3 text-gray-400 font-thin ">$2,130.00</td>
        <td className="py-3  font-thin ">Matt</td>
        <td className=" ">Credit Card</td>
        <td className=" "></td>
        <td className=" "></td>
        <td className=" text-right ">
          <button
            onClick={props.viewInvoice}
            className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
          >
            View Invoice
          </button>
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}
