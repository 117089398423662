import { BooleanEnumType } from "aws-sdk/clients/workdocs";
import { Component, ReactNode, useState } from "react";
import { AiOutlineRotateRight, AiOutlineVerticalLeft } from "react-icons/ai";
import { FcCancel } from "react-icons/fc";
import { RiCreativeCommonsZeroLine } from "react-icons/ri";
import { VoidExpression } from "typescript";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import ToggleSwitch from "../ToggleSwitch";

type Props = {
  title: string;
  handleModalToggle: any;
  ErrorNote: any;
  onoff: boolean;
  id: number;
};

interface ModalState {
  unit_id: number;
  start_date: any;
  end_date: any;
  reason: string;
  other_reason: string;
  onoff: boolean;
  nonDeterminedEnd: boolean;
  unit: any; //Need to change this type to Unit once we refactor - Newman 
  bookings: any[]; //Need to change this type to Bookings[] once we refactor - Newman 
}

class UnitActiveModal extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      unit_id: this.props.id,
      start_date: new Date(),
      end_date: new Date(),
      reason: "",
      other_reason: "",
      onoff: props.onoff,
      nonDeterminedEnd: true,
      unit: null,
      bookings: [],
    };
  }

  handleClick = async (event: any) => {
      event.preventDefault();
    
      //Call the Unit Deactivation Route
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler('Loading', 'Pending', `Deactivating Unit ${this.props.id}`)
      const response = await fetchRequestJSON('PUT', Constants.API_PATH_UNIT_TOGGLE_ACTIVE,{
        id: this.props.id
      })
      console.log(response)
      if(response?.status === "Success") {
        //Indicate notification handler than close the modal 
        NotificationHandler('Success', 'Success', "Please navigate to the inactive unit list to view the inactive unit")
        this.props.handleModalToggle();
      } 
      else {
        //Need to list out which bookings are preventing unit deactivation 
        this.setState({bookings: response?.data})
        NotificationHandler('Error', 'Error', response?.data ?? '')
      }
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff });
      this.setState({ bookings: [] });
    }
  };

  componentDidMount = () => {
    if (this.props.id) {
      this.setState({ unit_id: this.props.id });  
    }
  };

  handleToggleChange = () => {
    this.setState({ nonDeterminedEnd: !this.state.nonDeterminedEnd });
  };

  render() {
    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={`fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 ${
          !this.props.onoff ? "hidden" : ""
        }`}
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          {/* <IoIosArrowBack className="text-white text-3xl absolute transform -translate-x-28 -translate-y-6" /> */}
          {/* <FcCancel className="text-red-400 text-3xl mb-3" /> */}

          <span className="text-xl font-bold text-gray-800 text-left">
            {this.props.title}
          </span>
          <span className="text-xl text-red-400 my-3">PLEASE NOTE: This Unit will not be able to be reactivated after deactivation. If you need to reactivate, you must recreate a new unit.</span>
          {
            <>
              <span className="text-xl text-green-400">{this.state.bookings.length ? `Upcoming or Ongoing Bookings that are preventing deactivation: ` : ''}</span>
              <div className="flex flex-row md:flex-col w-full h-full mb-3 justify-around sm:flex-col flex-wrap">
                {this.state.bookings.map((reportingData) => (
                  <>
                    <span><strong>Booking #ID: {reportingData.id}</strong></span>
                    <span>Check In: {reportingData.check_in}</span>
                    <span>Check Out: {reportingData.check_out}</span>
                    <br/>
                  </>
                ))}
              </div>
            </>
          }
          <button className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded" onClick={(event) => this.handleClick(event)}> 
            Toggle Inactivation
          </button>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(UnitActiveModal);
