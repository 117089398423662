import { useEffect } from "react";
import axios, * as Axios from "axios";
import AWS, { AWSError } from "aws-sdk";
import { getCookie } from "../views/privateRoute";
import Amplify from "aws-amplify";
import crypto from 'crypto';
import store from "../redux/store";
import {   
  fetchAuthSession,
  getCurrentUser 
} from "aws-amplify/auth";
import { Constants } from "./apiLinkConstants";
//import AmazonCognitoIdentity from 'amazon-cognito-identity-js';

type AttributeListType = {
  Value: string; 
  Name: string;
};
const ID_POOL_ID = `${process.env.REACT_APP_IDENTITY_POOL_ID}`;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: ID_POOL_ID,
});

AWS.config.update({ region: "us-east-1" });

const hashSecret = (clientSecret: string, username: string, clientId: string) => crypto.createHmac('SHA256', clientSecret)
  .update(username + clientId)
  .digest('base64')

export async function signUpUser(attributeList: AttributeListType[], username: string, password: string) {
  const {credentials} = await fetchAuthSession();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: "us-east-1",
    credentials: credentials
  });
  let email = ""; 
  let name = "Test Test"; 

  for(let i = 0; i < attributeList.length; i++) {
    let thisAttribute = attributeList[i]; 
    if(thisAttribute.Name === "email") {
      email = thisAttribute.Value; 
    }else if(thisAttribute.Name === "name") {
      name = thisAttribute.Value;
    }
  }

  let hashKey = hashSecret("3o7p9nsqg5lqn6qi7l2ad2h6489kqtkors30ht9ba8k50ln8073",username, "18qpnd5c3g9sp9kctbb4qvncc0");
  const params = {
    ClientId: "18qpnd5c3g9sp9kctbb4qvncc0",
    Password: password, 
    Username: username, 
    UserAttributes: attributeList,
    SecretHash: hashKey
  }; 

  cognitoidentityserviceprovider.signUp(params, function(err, data) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    console.log('data is ' + data);

    store.dispatch({
      type: "registerSuccess",
      message: "Successfully created the accunt",
      user: {
        fname: name.substring(0, name.indexOf(' ')),
        lname: name.substring(name.indexOf(' ') + 1),
        email: email,
        password: password,
      },
    });
  }); 


}

export async function getUserList() {
  let userCheck = true;
  const {credentials} = await fetchAuthSession();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: "us-east-1",
    credentials: credentials
  });
 
  // Initialize the pagination token as undefined
  let paginationToken: string | undefined;

  let req:any = {
    Users: []
  }
  while(userCheck) {
    let response = await cognitoidentityserviceprovider.listUsers({
      UserPoolId: "us-east-1_pwG6SHGYo",
      Limit: 0,
      PaginationToken: paginationToken,
    }).promise();

    req.Users.push(...response.Users);
    console.log(response)
    if(response.PaginationToken) {
      paginationToken = response.PaginationToken
      // req.push(...response.Users);
    } else {
      userCheck=false;
    }
  } 

  // let response = await cognitoidentityserviceprovider.listUsers({
  //   UserPoolId: "us-east-1_pwG6SHGYo",
  //   Limit: 0,
  //   PaginationToken: paginationToken,
  // }).promise();

  // console.log(response)
  // req.Users.push(...response.Users)

  // if(response.PaginationToken) {
  //   let x = await cognitoidentityserviceprovider.listUsers({
  //     UserPoolId: "us-east-1_pwG6SHGYo",
  //     Limit: 0,
  //     PaginationToken: response.PaginationToken,
  //   }).promise()
  //   req.Users.push(...x.Users)
  // }
  // console.log(req)
  return req; 
}

export function parseJwt (token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export async function getCognitoUserGroupsForLoggedInUser() {
  // let res = await Auth.currentAuthenticatedUser();
  // let decryptedToken = await parseJwt(res.signInUserSession.idToken.jwtToken); 
  const {
    tokens: session
  } = await fetchAuthSession();

  let decryptedToken = parseJwt(session.idToken.toString())
  return decryptedToken['cognito:groups']; 
}


export async function getGroupList(group: string) {
  const {credentials} = await fetchAuthSession();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: "us-east-1",
    credentials: credentials
  });
  const params = {
    GroupName: group,
    UserPoolId: "us-east-1_pwG6SHGYo", /* required */
  };
  return new Promise((reject, resolve) => {
      cognitoidentityserviceprovider.listUsersInGroup(params, function(err, data) {
        if (err) {
          console.log("FAILED")
          reject(err); // an error occurred
        } else {
          resolve(data);           // successful response
          console.log(data);
        } 
      });
    });
  };


export async function joinGroup(group: string, username: string) {
  const {credentials} = await fetchAuthSession();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: "us-east-1",
    credentials: credentials
  });
  const params = {
    GroupName: group,
    UserPoolId: "us-east-1_pwG6SHGYo", /* required */
    Username: username
  };
  let req = await cognitoidentityserviceprovider.adminAddUserToGroup(params, function(err, data) {
    if (err) {
      console.log("FAILED")
      console.log(err, err.stack); // an error occurred
    } else {
      console.log(data);           // successful response
    }     
  });
}

export async function leaveGroup(group: string, username: string) {
  const {credentials} = await fetchAuthSession();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: "us-east-1",
    credentials: credentials
  });
  const params = {
    GroupName: group,
    UserPoolId: "us-east-1_pwG6SHGYo", /* required */
    Username: username
  };
  let req = await cognitoidentityserviceprovider.adminRemoveUserFromGroup(params, function(err, data) {
    if (err) {
      console.log("FAILED")
      console.log(err, err.stack); // an error occurred
    } else {
      console.log(data);           // successful response
    }     
  });
}

export const GetData = () => {
  const token = getCookie("idToken");

  var params = {
    IdentityPoolId: ID_POOL_ID /* required */,
    Logins: {
      "cognito-idp.us-east-1.amazonaws.com/us-east-1_pwG6SHGYo": token,
    },
  };

  let CognitoIdentity = new AWS.CognitoIdentity();
  CognitoIdentity.getId(params, function (err, data) {
    if (err) {
      console.log(err, err.stack);
    } else {
      console.log(data.IdentityId);
      const CredParams = {
        IdentityId: `${data.IdentityId}`,
        Logins: {
          "cognito-idp.us-east-1.amazonaws.com/us-east-1_pwG6SHGYo": token,
        },
      };
      CognitoIdentity.getCredentialsForIdentity(
        CredParams,
        function (err, data) {
          if (err) {
            console.log(err, err.stack);
          } else {
            console.log(`res: ${data}`);
          }
        }
      );
    }
  });

  return <div className=" mx-auto mt-1">Loading...</div>;
};

export const GetAppStage = () => {
  let AppStage = process.env.REACT_APP_AWS_API_GATEWAY_LINK.split("/").pop().toLowerCase()
  type StagePackage = {
    name1: string,
    name2: string,
    email1: string,
    email2: string,
    logoPath: string,
    bg_main: string,
    bg_alt: string,
    hstNumber: string,
  }

  const staywell: StagePackage = {
    name1: "StayWell",
    name2: "Stay Well",
    email1: "info@staywell.charity",
    email2: "info@staywell.charity",
    logoPath: "/img/staywelllogo.png",
    bg_main: "bg-staywell-main",
    bg_alt: "bg-staywell-alt",
    hstNumber: "72165 4127 RT0001"
    // hstNumber: "82598 5237 RT0001",
  }

  const svsrelo: StagePackage = {
    name1: "SVSRELO",
    name2: "SVS Relo Group Inc.",
    email1: "info@svsrelo.com",
    email2: "payments@svsrelo.com",
    logoPath: "/img/svslogo.png",
    bg_main: 'bg-gray-900',
    bg_alt: 'bg-gray-800',
    // hstNumber: "79222 4677 RT0001",
    hstNumber: "82598 5237 RT0001",
  }

  const svsnorm: StagePackage = {
    name1: "SVSRELO",
    name2: "SVS Relo Group Inc.",
    email1: "info@svsrelo.com",
    email2: "payments@svsrelo.com",
    logoPath: "/img/svslogo.png",
    bg_main: 'bg-gray-900',
    bg_alt: 'bg-gray-800',
    hstNumber: "79222 4677 RT0001",
    // hstNumber: "82598 5237 RT0001",
  }

  const skyview: StagePackage = {
    name1: "SKYVIEWSUITES",
    name2: "placeholder",
    email1: "info@skyviewsuites.com",
    email2: "payments@skyviewsuites.com",
    logoPath: "/img/svslogo.png",
    bg_main: 'bg-gray-900',
    bg_alt: 'bg-gray-800',
    hstNumber: "82598 5237 RT0001"
  }
  switch (AppStage) {
    case "test":
      return svsrelo;
    case "svsrelo":
      return svsrelo;
    case "staywell":
      return staywell;
    case "skyview":
      return skyview;
  }
  return svsrelo;
}

export default GetData;


export async function localRoute(CRUD_type: string, route: string, requestBody?: any) {
  let response: any;
  // The port is dynamic based on what your booking microservice is set to. You could make this a .env variable to ensure all devs are using 3001 for bookings locally.
  let localhost: string = 'http://localhost:3001';

  if (CRUD_type.toLowerCase() === 'get') {
    await axios.get(
      localhost + route,
      {
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3001",
          "Access-Control-Allow-Credentials": "true"
        }
      }
    ).then((res) => {
      console.log("AXIOS STATUS: " + res.status)
      console.log(res)
      response = res.data;
    })
  }
  else if (CRUD_type.toLowerCase() === 'post') {
    await axios.post(
      localhost + route, 
      requestBody,
      {
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3001",
          "Access-Control-Allow-Credentials": "true"
        }
      }
    ).then((res) => {
      console.log("AXIOS STATUS: " + res.status)
      console.log(res)
      response = res.data;
    })
  }
  else if (CRUD_type.toLocaleLowerCase() === 'delete') {
    await axios.delete(
      localhost + route,
      {
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3001",
          "Access-Control-Allow-Credentials": "true"
        }
      },
    ).then((res) => {
      console.log("AXIOS STATUS: " + res.status)
      console.log(res)
      response = res.data;
    })
  }

  return response;
}