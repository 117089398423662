import { SetStateAction, useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider"
import store from "../../../../redux/store";

type Props = {
  toggle: boolean;
  handleCreateBarToggle: any; //This is a function, need to rename this when we refactor
}

const NewParkingSpot = (props:Props) => {
    // const [toggle, setToggle] = useState(props.toggle);
    const [building, setBuilding] = useState([]);
    const [units, setUnits] = useState([]);
    const [unitName, setUnitsName] = useState('');
    const [owners, setOwners] = useState([]);

    //Payload for Creating Parking Spot:
    const [buildingID, setBuildingID] = useState('');
    const [unitID, setUnitID] = useState(-1);
    const [ownerID, setOwnerID] = useState(-1);
    const [parkingLevel, setParkingLevel] = useState('');
    const [parkingNotes, setParkingNotes] = useState('');

    //Notificatoin Handler Logic:
    const dispatch = useNotification();

    const HandleError = (type: string, title: string, message: string,) => {
      dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
      })
    }
    //-----------------------

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        const allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);
        setBuilding(allBuildings.data.sort((buildingOne:any, buildingTwo:any) => buildingOne.building_name.localeCompare(buildingTwo.building_name)))

        const allOwners = await fetchGetJSON(Constants.API_PATH_OWNER_GET_ALL);
        setOwners(allOwners.data.sort((ownerOne:any, ownerTwo:any) => ownerOne.first_name.localeCompare(ownerTwo.first_name)))
    }  


    const handleBuildingChange = async(event: { target: { value:string; }; }) => {
      setBuildingID(event.target.value)
      setUnitID(-1);
      //Instantiate the units dropdown list based on the building selected:

      const getUnitsByBuilding = await fetchGetJSON(`${Constants.API_PATH_BUILDING_GET_UNITS}${event.target.value}`)
   
      if(getUnitsByBuilding.status == 'Success') {
        setUnits(getUnitsByBuilding.data);
      }
    }

    const handleChange = async(event: { target: { id: string; value: string; }; }) => {
      const { id, value} = event.target;
      id == "units" ? setUnitID(+value) : id == "notes" ? setParkingNotes(event.target.value) : setParkingLevel(value);

      if(id == "units" && value != "-1") {
        const unitName = units.filter((units) => {return units.id == value})
        console.log(unitName[0].suite_name)
        setUnitsName(unitName[0].suite_name);
      }
    } 

    const handleSubmit = async () => {
      //Create the parking:
      //Check if Parking Level Location and Building exist:
      if(!buildingID || !parkingLevel || ownerID == -1){
        HandleError('Error', 'Error', 'No Parking Level, Building, or Owner selected. Those are required fields.')
        return
      }
      
      const buildingName = building.filter((building)=>{return building.id == buildingID})

      const parkingPayload = {
        building_id: buildingID,
        parking_spot_level: parkingLevel,
        unit_id: unitID == -1 ? null : unitID,
        parking_spot_location: unitID == -1 ? buildingName[0].building_name : null, //Set to null as BE will handle parking spot location name if unit is provided
        parking_spot_notes: parkingNotes,
        owner_id: ownerID
      }

      const createParking = await fetchRequestJSON('POST', Constants.API_PATH_PARKING_SPOT_CREATE, parkingPayload)

      if(createParking.status == 'Success') {
        HandleError('Success', 'Success', createParking.data)

        //Redux below:
        let update = store.getState().parkingSpotReducer.listUpdate;
        update()
        return
      } else {
        HandleError('Error', 'Error', createParking.data)
        return
      }
    }

    const contentCreation = () => {
        return (
            <form className="flex flex-col pl-6 pr-12 py-4">
                <strong className="text-gray-600 text-md font-bold mb-4 mt-2">
                    General Info
                </strong>
                <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select Building*</strong>
                    </span>
                    <select
                        id="buildings"
                        onChange={handleBuildingChange}
                        // value={this.state.suite_building}
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                        placeholder="Choose"
                    >
                        <option selected disabled>Please Select a Building (Required)</option>
                        {building.map((building:any) => 
                          <option value={building.id}>{building.building_name}</option>
                        )}
                    </select>
                 </label>
                 <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select Unit (Optional)</strong>
                    </span>
                    <select
                        id="units"
                        onChange={handleChange}
                        value={unitID}
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                        placeholder="Choose"
                    >
                        <option selected value={-1}>This Parking Does Not Belong To a Core Unit</option>
                        {units.map((unit:any) => 
                          <option value={unit?.id}>{unit?.suite_name}</option>
                        )}
                    </select>
                 </label>
                 <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select Owner*</strong>
                    </span>
                    <select
                        id="owner"
                        onChange={(event) => setOwnerID(+event.target.value)}
                        value={ownerID}
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                        placeholder="Choose"
                    >
                        <option selected value={-1}>Please Select a Owner (required)</option>
                        {owners.map((owner:any) => 
                          <option value={owner?.id}>{owner?.first_name} {owner?.last_name} | {owner?.email}</option>
                        )}
                    </select>
                 </label>
                 {
                  unitID == -1 &&
                  <label className="block pl-3 mb-5">
                      <strong>Please note: By Selecting No Unit, the Parking Spot will be defaulted to the building you have selected.</strong>
                  </label>
                 }
                  <label className="block pl-3 mb-5 text-gray-500 text-s">
                      Selected Unit: <u>{unitID == -1 ? 'No Unit Selected' : unitName}</u>
                  </label>

                 <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Parking Level Location*</strong>
                    </span>
                    <input
                        id="parking_level"
                        onChange={handleChange}
                        // value={this.state.suite_address}
                        type="text"
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                        placeholder="Ex. Level A2 - #130"
                    />
                 </label>
                 <label className="block pl-3 mb-5 mt-2">
                    <strong className="text-gray-400 text-xs">Parking Spot Notes</strong>
                    <textarea
                        id="notes"
                        className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows={3}
                        onChange={handleChange}
                    ></textarea>
                </label>
            </form>
        )
    }

    return (
       <div className={
         props.toggle ?  "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + 
         "z-50 opacity-100 translate-x-0 lg:max-w-lg w-full" : "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide"
       }> 
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={props.handleCreateBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            New Parking Spot {props.toggle}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
          </span>
        </div>
        {contentCreation()}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => handleSubmit()}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    )
}

export default NewParkingSpot 