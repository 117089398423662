import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/College Park/College Park Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/College Park/College Park Parking.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/College Park/College Park View.png";

function CollegeParkParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            At the base of 761 Bay Street, just slightly south of the main
            building entrance is a large, 3 lane entry way for public parking.
            This is marked with a large blue "PUBLIC PARKING" sign; use the
            farthest right lane to access the first set of entry gates.
          </p>
          <p className="mt-3">
            When at the first set of gates, use the white card that's attached
            to the parking remote provided to tap the sensor marked "Hold Proxy
            Card Here". This sensor is located just below the blue screen and
            payment options for public parking, and using your white proxy card
            will allow you to bypass public parking to access resident parking.
            Once the first set of gates opens, proceed immediately left, past
            the signs for the car wash.
          </p>
          <p className="mt-3">
            The first garage door on your left side will be marked by a sign
            above that says "Residents of College Park 761 & 763 Bay St.
            Residents only". Approach this door slowly and when close, push the
            large button on the parking remote, this will open the first door.
            There is a second gate behind the first door, use the smaller of the
            two buttons on the remote to open the second set of gates, and you
            will now be in the resident parking garage.
          </p>
          <p className="mt-3">
            Follow signs for 761 or 763 Bay (depending on your unit address) and
            you will have arrived. Note that the parking remote and white proxy
            card is required to exit the garage each time, so you keep them in
            your vehicle at all times.
          </p>
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0">
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="h-full w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point form summary:
            <p className="my-3">
              <strong>
                Entrance accessible via College St., key landmark is the large
                blue "Public Parking" sign
              </strong>
            </p>
            <ul className="list-disc ml-5">
              <li>
                Tap white card to scanner on area indicated via "Hold Proxy Card
                Here" at the first set of gates
              </li>
              <li>Enter the garage and proceed immediately to the left</li>
              <li>
                Pull up to first garage door on the left, marked with "Residents
                of College Park 761 & 763 Bay St. Residents only" sign
              </li>
              <li>
                Press large button on remote to open first door, and smaller
                button to open the second gate
              </li>
              <li>
                Proceed down to your designated parking space, making sure to
                follow signs for 761 Bay or 763 Bay which will coincide with
                your unit address.
              </li>
              <li>
                Note that the parking remote and white proxy card is required to
                exit the garage each time, so you keep them in your vehicle at
                all times.
              </li>
            </ul>
          </p>
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-1/2 mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default CollegeParkParking;
