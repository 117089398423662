import { Component } from "react";

type Props = {
  label: string;
};

class Label extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }
}

export default Label;
