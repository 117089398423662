import { Component, MouseEvent } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { FiEdit } from "react-icons/fi";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type InvoiceLine = {
  id: number;
  line_type: string;
  entry: string;
  cost: number;
};


interface Props {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  invoiceLine: InvoiceLine;
  ErrorNote: any;
}

interface ModalState {
  title: string;
  onoff: boolean;

  id: number;
  line_entry: string;
  line_type: string;
  cost: number;
}

class EditInvoiceLine extends Component<Props, ModalState> {

  constructor(props: any) {
    super(props);
    console.log(props);
    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      line_entry: "",
      line_type: "",
      id: -1,
      cost: 0
    };
  }
  
  componentDidMount = async () => {
    this.setState({
      id: this.props.invoiceLine.id,
      line_entry: this.props.invoiceLine.entry,
      cost: this.props.invoiceLine.cost,
      line_type: this.props.invoiceLine.line_type
    })
  }


  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff })
    }
  };

  // Find proper unit and building to transfer booking
  handleClick = async (event:any) => {
    event.preventDefault();

    const NotificationHandler = this.props.ErrorNote;

    let res = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_INVOICE_LINE_EDIT + this.props.invoiceLine.id,
      {
        line_type: this.state.line_type === this.props.invoiceLine.line_type ? this.props.invoiceLine.line_type : this.state.line_type,
        entry: this.state.line_entry === this.props.invoiceLine.entry ? this.props.invoiceLine.entry : this.state.line_entry,
        cost: this.state.cost === this.props.invoiceLine.cost ? this.props.invoiceLine.cost : this.state.cost
      }
    );
    console.log(res);
    if(res.status === "Success") {
      NotificationHandler('Success', 'Your Invoice Line has been succesfully edited, please refresh to see changes')
    }
    else {
      NotificationHandler('Warning', `Your Invoice Line has been unsuccesfully edited`)
    }
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleTypeChange = async (event: any) => {
    await this.setState({ line_type: event.target.value });
  };


  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <FiEdit className="text-gray-600 text-3xl mb-3" />
          <span className="text-xl font-bold text-gray-800 text-left">{this.state.title}</span>
          <span className="text-xs text-center text-gray-400"></span>
          <form className="w-full">
            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Line Entry</span>
                <input
                  id="line_entry"
                  onChange={this.handleInputChange}
                  value={this.state.line_entry}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              </label>

              <label className="block ">
                <span className="text-gray-400 text-xs">
                  <b>Line Type:</b>
                </span>
                <select
                  id="new_type"
                  onChange={this.handleTypeChange}
                  value={this.state.line_type}
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                >
                  <option value="Deposit">Deposit</option>
                  <option value="Rent">Rent</option>
                  <option value="HST">HST</option>
                  <option value="Parking">Parking</option>
                  <option value="Rent Refund">Rent Refund</option>
                  <option value="Cleaning Fee">Cleaning Fee</option>
                  <option value="Referral Fee">Referral Fee</option>
                  <option value="StayWell Referral Fee">StayWell Referral Fee</option>
                  <option value="Regular Cleaning Fee">Regular Cleaning Fee</option>
                  <option value="Other">Other</option>
                </select>
              </label>

              <label className=" block  ">
                <span className="text-gray-400 text-xs">Cost</span>
                <input
                  id="cost"
                  onChange={this.handleInputChange}
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  type="number"
                  value={this.state.cost}
                >
                </input>
              </label>
            </div>
            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={(event) => {
                  this.handleClick(event);
                  // this.props.handleModalToggle();
                }}
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(EditInvoiceLine);
