import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Qwest/Qwest Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Qwest/Qwest Parking.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Qwest/Qwest View 1.png";

function QwestParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-2/3">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            Access to the parking garage is at the rear of the building, via the
            alleyway that runs parallel to Richmond St. This alleyway can be
            accessed via Duncan on the west side, or Simcoe on the east side.
          </p>
          <p className="mt-3">
            The entrance to parking at Qwest is marked by two large light posts
            and a large grey roll up door. Pull your vehicle up nice and close
            to the gate, and press the #1 button on your remote to open it. Once
            the gate opens, proceed down to the ramp, past the visitor parking,
            and up to the second set of gates. At this gate, use the #1 button
            again to open them, then proceed down to your assigned parking
            space.
          </p>
          <p className="mt-3 mb-5 md:mb-0">
            Please note that the #2 button on your remote will set off an alarm
            if pushed; please avoid pushing that button when accessing parking.
            Also note that the parking remote is required to exit the garage
            each time, so it's advised that you keep the remote in your vehicle
            at all times.
          </p>
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="mt-2"
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-1/2">
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-2/5 w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point Form Summary:
            <p className="my-3">
              <strong>
                Entrance accessible via alleyway at rear of building, off Duncan
                St.
              </strong>
            </p>
            <ul className="list-disc ml-5">
              <li>
                Enter alleyway off Duncan St. and proceed forward until you see
                two light posts on the right; about 3⁄4 of the way down the
                alley.
              </li>
              <li>
                Pull up close to large grey roll up garage door and push the
                button on parking remote to open the door
              </li>
              <li>
                Proceed down the ramp, past visitor parking, and use the same
                button on remote to open the double gates
              </li>
              <li>Proceed down to designated parking space.</li>
            </ul>
            <p className="mt-3">
                Note that you require the remote to leave the parking garage,
                please keep it in your vehicle at all times
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}

export default QwestParking;
