export type State = {
  curId: number;
  listUpdate: any;
};

const initialState: State = {
  curId: -1,
  listUpdate: undefined,
};

type Action =
  | { type: "buildingIdTransfer"; id: number }
  | { type: "buildingListUpdate"; listUpdate: any };

export const buildingReducer = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "buildingIdTransfer":
      state.curId = action.id;
      return state;
    case "buildingListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    default:
      return state;
  }
};

export default buildingReducer;
