import NavBar from "../components/NavBar";
import Bookings from "../components/Bookings/Bookings";
import BookingProfile from "../components/Bookings/BookingProfile";
import Units from "../components/Units/Units";
import UnitProfile from "../components/Units/UnitProfile";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { Component } from "react";
import ManualBooking from "../components/Bookings/ManualBooking";
import ManualInfo from "../components/Bookings/ManualInfo";
import CheckInForm from "../components/CheckIns/CheckInForm";
import StepperBar from "../components/StepperBar";
import InfoKeys from "../components/CheckIns/InfoKeys";
import CheckInSummary from "../components/CheckIns/CheckInSummary";
import CheckInList from "../components/CheckIns/CheckInList";
import GlobalSettings from "../components/GlobalSettings";
import GlobalBugReporting from "../components/GlobalBugReporting";
import OpsDash from "../components/Dashboards/OpsDash";
import FinDash from "../components/Dashboards/Finance/FinanceDash";
import SalesDash from "../components/Dashboards/Sales/SalesDash";
import DashInvoice from "../components/Dashboards/Finance/DashInvoices";
import QuickAction from "../components/QuickAction";
import CleaningAnalytics from "../components/Dashboards/Cleaning/CleaningAnalytics";
import OfficeDash from "../components/Dashboards/Office/OfficeDash";
import ArchiveForms from "../components/Dashboards/Cleaning/ArchiveForms";
import CleaningCalendar from "../components/Dashboards/Cleaning/CleaningCalendar";
import CleanerPortal from "../components/Dashboards/Cleaning/CleanerPortal";
import Payroll from "../components/Dashboards/Cleaning/Payroll";
import Cleaners from "../components/Dashboards/Cleaning/Cleaners";
import Invoices from "../components/Invoices/Invoices";
import Finance from "../components/Finance/Finance";
import Leads from "../components/Leads/Leads";
import OwnerProfile from "../components/Units/OwnerProfile";
import BuildingProfile from "../components/Units/BuildingProfile";
import Rolodex from "../components/Dashboards/Sales/Rolodex";
import InvoiceFunctions from "../components/Dashboards/Finance/InvoiceFunctions";
import FinReports from "../components/Dashboards/Finance/FinReports";
import CleaningForm from "../components/Dashboards/Cleaning/CleaningForms";
import Contacts from "../components/Contacts/Contacts";
import CorporateProfile from "../components/Contacts/CorporateProfile";
import CorpContactProfile from "../components/Contacts/CorpContactProfile";
import NotificationBanner from "../components/Notifications/NotificationProvider";
import BlackoutProfile from "../components/Units/BlackoutProfile";
import { ExtensionList } from "../components/Extensions/ExtensionList";
import Extensions from "../components/Extensions/Extensions";
import store from "../redux/store";
import { Reporting } from "../components/Reporting/Reporting";
import { getCognitoUserGroupsForLoggedInUser } from "../api-requests/api-gateway";
import { UserTypes, arUserTypes } from "../redux/userReducer";
import Header from "../components/Units/InfoSheetComponents/Header";
import Body from "../components/Units/InfoSheetComponents/Body";
import ExtensionForm from "../components/Extensions/ExtensionForm";
import CheckOutForm from "../components/Bookings/CheckOut/CheckOutForm";
import BookingAgreement from "../components/Bookings/BookingAgreement";
import Parkings from "../components/Parking/Parking"
import ParkingSpotProfile from "../components/Parking/Parking Spots Components/Read/ParkingSpotProfile";
import ParkingSpotBlackoutProfile from "../components/Parking/Parking Spots Blackout Components/Read/ParkingSpotBlackoutProfile";
import ParkingProfile from "../components/Parking/Parking Components/Read/ParkingProfile";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";


// const SomeComponent = withRouter(props => <AppView {...props}/>);

// let arUserTypes = [
//   "Ops",
//   "Finance",
//   "Sales",
//   "Cleaning",
//   "CleanersL1L3",
//   "Office",
//   "CleanerAdmin",
//   "Admin",
//   "QAM_L4"
// ];

function PageViews(props: any) {
  // type Props = {
  //   UserType: any;
  //   UserName: any;
  // }
  console.log(props)
  let history = useHistory();

  let { url } = useRouteMatch();
  let userTypes: string[] = props.userData.UserType;

  
  for(const userType of userTypes) {
    // better place to update userTypes
    const storeUserData = async () => {
      await store.dispatch({
        type: "changeUserType",
        newUserType: userType,
      });
    };
    storeUserData();
  }


  let UserDash = <></>;

  let DashSubPages: any = [];

  for(const userType of props.userData.UserType) {
    if(arUserTypes.includes(userType)) {
      console.log("User Type is in arUserTypes")
      UserDash = (
        <DynamicUserComp type={userType} data={props.userData} />
      );
      if (userType=== "Finance") {
        DashSubPages = [
          <Route 
            // key={i.toString()}
            exact path={`${url}/dashboard/invoices`}
          >
            <DashInvoice userData={props.userData} />
          </Route>,

          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/invoice-functions`}
          >
            <InvoiceFunctions userData={props.userData} />
          </Route>,

          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/finance-reports`}
          >
            <FinReports userData={props.userData} />
          </Route>,
        ];
      }

      if (userType === "CleanerAdmin" || userType=== "Admin" || userType === "QAM_L4") {
        DashSubPages = [
          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/archive-forms`}
          >
            <ArchiveForms userData={props.userData} />
          </Route>,

          // <Route
          //   key={i.toString()}
          //   exact
          //   path={`${url}/dashboard/cleaning-calendar`}
          // >
          //   <CleaningCalendar userData={props.userData} />
          // </Route>,

          <Route 
            // key={i.toString()}
            exact path={`${url}/dashboard/payroll`}
          >
            <Payroll userData={props.userData} />
          </Route>,

          // <Route 
          //   // key={i.toString()}
          //   exact path={`${url}/dashboard/cleaners`}
          // >
          //   <Cleaners userData={props.userData} />
          // </Route>,

          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/cleaning-analytics`}
          >
            <CleaningAnalytics userData={props.userData} />
          </Route>,

          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/cleaner-portal`}
          >
            <CleanerPortal />
          </Route>,

          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/archive-forms/formid`}
          >
            <CleaningForm userData={props.userData} />
          </Route>,
        ];
      }

      if (userType=== "Sales") {
        DashSubPages = [
          <Route
            // key={i.toString()}
            exact
            path={`${url}/dashboard/archive-forms`}
          >
            <ArchiveForms userData={props.userData} />
          </Route>,

          <Route 
            // key={i.toString()}
            exact 
            path={`${url}/dashboard/rolodex`}
          >
            <Rolodex userData={props.userData} />
          </Route>,

          <Route 
            // key={i.toString()} 
            exact 
            path={`${url}/dashboard/payroll`}
          >
            <Payroll userData={props.userData} />
          </Route>,

          <Route 
            // key={i.toString()} 
            exact 
            path={`${url}/dashboard/cleaners`}
          >
            <Cleaners userData={props.userData} />
          </Route>,
        ];
      }
    }
  } 


  return (
    <div>
      <Switch>
        <Route 
            // key={i.toString()}
            exact path={`${url}/dashboard/cleaners`}
          >
            <Cleaners userData={props.userData} />
        </Route>,
        <Route exact path={`${url}/dashboard`}>
          {UserDash}
        </Route>

        {DashSubPages}

        <Route
            exact
            path={`${url}/dashboard/cleaning-calendar`}
          >
            <CleaningCalendar userData={props.userData} />
          </Route>,


        <Route exact path={`${url}/bookings`}>
          <Bookings />
        </Route>

        <Route exact path={`${url}/parkings`}>
          <Parkings
            history={history}
            location={`${url}/parkings`}
          />
        </Route>

        <Route exact path={`${url}/reporting`}>
          <Reporting />
        </Route>

        <Route exact path={`${url}/bookings/new/manual-booking`}>
          <ManualBooking />
        </Route>

        <Route exact path={`${url}/bookings/new/manual-booking/info`}>
          <ManualInfo />
        </Route>

        <Route exact path={`${url}/bookings/bookingprofile`}>
          <BookingProfile history={history} />
        </Route>

        <Route exact path={`${url}/invoices`}>
          <Invoices />
        </Route>

        <Route exact path={`${url}/finance`}>
          <Finance />
        </Route>

        <Route exact path={`${url}/leads`}>
          <Leads />
        </Route>

        <Route exact path={`${url}/contacts`}>
          <Contacts />
        </Route>

        <Route exact path={`${url}/contacts/profile`}>
          <CorporateProfile history={history} />
        </Route>

        <Route exact path={`${url}/contacts/corpcontactprofile/`}>
          <CorpContactProfile />
        </Route>

        <Route exact path={`${url}/check ins/`}>
          <CheckInList />
        </Route>

        <Route exact path={`${url}/check ins/bookingid`}>
          <CheckInForm />
        </Route>

        <Route exact path={`${url}/check ins/bookingid/unit info & keys`}>
          <InfoKeys />
        </Route>

        <Route exact path={`${url}/check ins/bookingid/check in summary`}>
          <CheckInSummary />
        </Route>

        <Route exact path={`${url}/units`}>
          <Units />
        </Route>

        <Route exact path={`${url}/units/unitprofile`}>
          <UnitProfile
            history={history}
            location={`${url}/units/unitprofile`}
          />
        </Route>

        <Route exact path={`${url}/dashboard/finance-dash`}>
          <FinDash userData={''} />;  
        </Route>

        <Route exact path={`${url}/dashboard/office-dash`}>
          <OfficeDash userData={''} />;  
        </Route>

        <Route exact path={`${url}/dashboard/sales-dash`}>
          <SalesDash userData={''} />;  
        </Route>

        <Route exact path={`${url}/parkings/parkingprofile/`}>
          <ParkingProfile
            history={history}
            location={`${url}/parkings/parkingprofile/`}
          />
        </Route>

        <Route exact path={`${url}/parkings/parkingspotprofile/`}>
          <ParkingSpotProfile
            history={history}
            location={`${url}/parkings/parkingspotprofile/`}
          />
        </Route>

        <Route exact path={`${url}/units/unitprofile/viewsheet`}>
          <Header />
          <Body />
        </Route>

        <Route exact path={`${url}/bookings/undefined/viewsheet/:unitId`}>
          <Header />
          <Body />
        </Route>

        <Route
          exact
          path={[
            `${url}/units/ownerprofile/`,
            `${url}/units/unitprofile/ownerprofile/`,
          ]}
        >
          <OwnerProfile />
        </Route>

        <Route exact path={`${url}/units/blackoutprofile/`}>
          <BlackoutProfile />
        </Route>
        <Route exact path={`${url}/parkings/blackoutprofile/`}>
          <ParkingSpotBlackoutProfile />
        </Route>

        <Route exact path={`${url}/units/buildingprofile`}>
          <BuildingProfile />
        </Route>

        <Route exact path={`${url}/extensions`}>
          <Extensions />
        </Route>
      </Switch>
    </div>
  );
}

type Props = {
  UserName: string;
};

type State = {
  toggleBug: boolean;
  toggleSettings: boolean;
  UserType: string[];
  user_f_name: string;
  user_l_name: string;
  user_position: string;
  user_id: number;
  everAdmin: boolean;
  pfp: string;
};

class AppView extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);

    this.state = {
      toggleBug: false,
      toggleSettings: false,
      UserType: [],
      user_f_name: "",
      user_l_name: "",
      user_position: "",
      user_id: -1,
      everAdmin: false,
      pfp: "/img/place3.jpg",
    };
  }

  componentDidMount = async () => {
    let everAdmin = false;
    let userGroups = await getCognitoUserGroupsForLoggedInUser();
    console.log(userGroups)
    if (userGroups) {
      let thisUserType: string[] = [];
      if (userGroups.length > 0) {
        for (let i = 0; i < userGroups.length; i++) {
          let curGroup:string = userGroups[i];
          console.log(curGroup)
          if (curGroup in UserTypes) {
            if (curGroup == UserTypes.Admin) {
              everAdmin = true;
              thisUserType.push(curGroup);
              console.log("THIS USER TYPE:", thisUserType);
              this.setState({ UserType: thisUserType, everAdmin: everAdmin });
            } else {
              console.log(curGroup)
              thisUserType.push(curGroup);

              console.log("THIS USER TYPE:", thisUserType);
              this.setState({ UserType: thisUserType, everAdmin: everAdmin });
            }
          }
        }
      }

      // MOVED TO PAGEVIEW TO STORE IN REDUX (UPDATES IT MORE ACCURATELY)
      // await store.dispatch({
      //   type: "changeUserType",
      //   newUserType: thisUserType,
      // });
    }

    // let res = await Auth.currentAuthenticatedUser({ bypassCache: true });
    let res = await fetchUserAttributes();
    let currentUser = await getCurrentUser();
    this.setState({
      user_f_name: res.name
        ? res.name.split(" ")[0]
        : "NO",
      user_l_name: res.name
        ? res.name.split(" ")[1]
        : "NAME",
      user_position: res.profile || "",
      user_id: +currentUser.userId,
      pfp: res.picture || "/img/place3.jpg",
    });
  };

  handleSettingsModal = (state?: any) => {
    if (state && this.state.toggleSettings === false) {
      this.setState({ toggleSettings: true });
    } else {
      this.setState({ toggleSettings: false });
    }
  };

  handleBugModal = (state?: any) => {
    if (state && this.state.toggleBug === false) {
      this.setState({ toggleBug: true });
    } else {
      this.setState({ toggleBug: false });
    }
  };

  handleUserTypeChange = async () => {
    let currentIndex = arUserTypes.indexOf(this.state.UserType[0]);
    if (currentIndex === 6) {
      currentIndex = -1;
    }
    let newIndex = arUserTypes[currentIndex + 1];
    console.log(newIndex);
    this.setState({ UserType: [...this.state.UserType, newIndex] });
    store.dispatch({ type: "changeUserType", newUserType: newIndex });
    console.log("APPVIEW", await store.getState().userReducer.userType);
  };

  render() {
    let path = this.props.location.pathname;
    // console.log(this.state);
    let NavType = (
      <NavBar
        openSettings={this.handleSettingsModal}
        openBug={this.handleBugModal}
        userData={this.state}
      />
    );
    let bodyPadding = "lg:pl-24";

    if (path.includes("/check ins/booking")) {
      NavType = <StepperBar />;
      bodyPadding = "pl-80";
    }

    let quickAction = <> </>;
    if (this.state.everAdmin) {
      quickAction = (
        <QuickAction
          ChangeUserType={this.handleUserTypeChange}
          CurrentUserType={this.state.UserType}
        />
      );
    }

    return (
      <div className="w-full bg-gray-900">
        <GlobalSettings
          SettingsToggle={this.handleSettingsModal}
          onoff={this.state.toggleSettings}
        />
        <GlobalBugReporting
          BugReportToggle={this.handleBugModal}
          onoff={this.state.toggleBug}
        />
        {NavType}
        {quickAction}

        <div
          id="contentCon"
          className={" transition-all duration-300 " + bodyPadding}
        >
          <PageViews userData={this.state} UserName={this.props.UserName} />
        </div>
      </div>
    );
  }
}

export default withRouter(AppView);

const DynamicUserComp = (props: any) => {
  let output;
  console.log(props.type)
  switch (props.type) {
    case "Ops":
      output = <OpsDash userData={props.data} />;
      break;

    case "Finance":
      output = <FinDash userData={props.data} />;
      break;

    case "Finance_Controller":
      output = <FinDash userData={props.data} />;
      break;

    case "Payments_Offshore":
      output = <FinDash userData={props.data} />;
      break;

    case "Sales":
      output = <SalesDash userData={props.data} />;
      break;

    case "CleanerAdmin":
      output = <CleanerPortal />;
      break;
  
    case "QAM_L4":
      output = <CleanerPortal />;
      break;

    case "Cleaning":
      output = <CleanerPortal />;
      break;

    case "CleanersL1L3":
      output = <CleanerPortal />;
      break;

    case "Office":
      output = <OfficeDash userData={props.data} />;
      break;

    case "Primary":
      output = <OfficeDash userData={props.data} />;
      break;

    case "Admin":
      output = <SalesDash userData={props.data} />;
      break;

    // unknown type ... output null to not render
    default: // to return nothing, use null
      output = <SalesDash userData={props.data} />;
      break;
  }

  return output;
};
