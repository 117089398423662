import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { CgExtensionAdd } from "react-icons/cg";
import { GiBackwardTime, GiForwardSun } from "react-icons/gi";

type Props = {
    parkingInfo: any;
    handleStartDateModal:any;
    handleShortenModal:any;
    handleExtendModal:any;
}

function ModifyParking(props:Props) {
  return (
    <div>
        {/* Modify Start and End Date */}
        <div className="slide-in-blurred-top font-display border px-3 py-6 mt-2">
            <div className="flex-wrap flex justify-between  flex-row">
                <div className="w-auto flex flex-col">
                    <h2 className=" lg:mr-4 pb-1 inline-block align-middle font-regular text-xl lg:text-base">
                        Parking Start Date:  <span className="text-gray-400">|</span>{" "}
                        <span className="font-bold">{props.parkingInfo?.start_date}</span>{" "}
                    </h2>
                    <div className="flex flex-col w-60">
                        <button
                            onClick={() => props.handleStartDateModal('Early')}
                            className=" mt-3 mb-3 lg:mt-0 transition-all duration-200 ease-in-out hover:bg-gray-200   rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row items-center  text-xs"
                        >
                            <GiBackwardTime className="text-lg text-green-500" />
                            <span className="ml-2">Early Check In</span>
                        </button>

                        <button
                            onClick={() => props.handleStartDateModal('Late')}
                            className="lg:mt-0 transition-all duration-200 ease-in-out hover:bg-gray-200  rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row items-center  text-xs"
                        >
                            <GiForwardSun className="text-lg text-green-500 " />
                            <span className="ml-2">Late Check In</span>
                        </button>
                    </div>
                </div>

                <div className="w-50 flex flex-col justify-end ">
                    <h2 className=" lg:mr-4 pb-1 inline-block align-middle font-regular text-xl lg:text-base">
                        Parking End Date:  <span className="text-gray-400">|</span>{" "}
                        <span className="font-bold">{props.parkingInfo?.end_date}</span>{" "}
                    </h2>
                    <button
                        onClick={() => props.handleShortenModal()}
                        className=" mt-3 mb-3 lg:mt-0 transition-all duration-200 ease-in-out hover:bg-gray-200   rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row items-center  text-xs"
                    >
                        <AiOutlineClockCircle className="text-lg text-blue-500" />
                        <span className="ml-2">Shorten Parking</span>
                    </button>

                    <button
                        onClick={() => props.handleExtendModal()}
                        className=" mt-4 lg:mt-0 transition-all duration-200 ease-in-out hover:bg-gray-200 rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row items-center  text-xs"
                    >
                        <CgExtensionAdd className="text-lg text-blue-500" />
                        <span className="ml-2">Extend Parking</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ModifyParking;
