import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";

type BlackoutData = {
    id: number;
    start_date: string;
    end_date: string;
    reason: string;
    unit_id: number;
}; 

type Props = {
  blackoutData: BlackoutData;
  toggle: any;
  handleEditBarToggle: any;
  handleInputChange: any;
  handleToggleChange: any;
};

type EditBlackoutState = {
    id: number;
    start_date: string;
    end_date: string;
    reason: string;
    unit_id: number;
};

class EditBlackout extends Component<Props, EditBlackoutState> {
  constructor(props: any) {
      
    super(props);
    this.state = {
      id: this.props.blackoutData.id, 
      start_date: this.props.blackoutData.start_date,
      end_date: this.props.blackoutData.end_date, 
      reason: this.props.blackoutData.reason,
      unit_id: this.props.blackoutData.unit_id
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // Update fields with the current information
  componentDidMount = async () => {
    const blackout = this.props.blackoutData; 

    this.setState({
      id: blackout.id, 
      start_date: blackout.start_date, 
      end_date: blackout.end_date, 
      reason: blackout.reason, 
      unit_id: blackout.unit_id
    });
  };

  // Fill out fields for edit request body and send the edit post request
  handleClick = async () => {

    this.props.handleEditBarToggle();
    const blackoutEditBody = {
        id: this.state.id,
        fields: {
          start_date: this.state.start_date, 
          end_date: this.state.end_date, 
          reason: this.state.reason, 
          unit_id: this.state.unit_id,
        }
    };

    let res = await fetchRequestJSON("PUT", Constants.API_PATH_BLACKOUT_EDIT, blackoutEditBody); 
    if(res.status != "Success") {
        alert("Error with your edit request.");
    } else{
        let updateProfile = store.getState().blackoutReducer.blackoutProfileUpdate;
        updateProfile(this.state.id);
    }
  };

  editBuilding = async (buildingEditBody: any) => {
    return await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BUILDING_EDIT,
      buildingEditBody
    );
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Blackout";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let content;

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Start Date</span>
          <input
            id="start_date"
            onChange={this.handleInputChange}
            value={this.state.start_date.substr(0, 10)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder={this.state.start_date.substr(0, 10)}
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">End Date</span>
          <input
            id="end_date"
            onChange={this.handleInputChange}
            value={this.state.end_date.substr(0, 10)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className=" pl-3 w-full grid grid-cols-2 gap-3 mb-5">
          <label className="block  ">
            <span className="text-gray-400 text-xs">Unit ID</span>
            <input
              id="unit_id"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.unit_id}
              type="number"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>
        </div>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Reason</span>
          <input
            id="reason"
            onChange={this.handleInputChange}
            placeholder={this.state.reason}
            type="text"
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></input>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>

        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => this.handleClick()}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default EditBlackout;
