import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Autocomplete from "@mui/material/Autocomplete";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
// import Select from 'react-select'
import { Constants } from "../../api-requests/apiLinkConstants";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { useNotification } from "../Notifications/NotificationProvider";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Chart from "react-apexcharts";
import TextField from "@mui/material/TextField";
import { selectedIdsLookupSelector } from "@mui/x-data-grid";
import Loading from "../LoadingWidget";
import ToggleSwitch from "../ToggleSwitch";
import { AnyARecord } from "dns";
import { ScrollToTop } from "./ScrollToTop";

type dailyReport = {
  checkInDay: string;
  occupiedRooms: number;
  activeUnits: number;
  blackOutNumber: number;
  availableRooms: number;
  occupancyRate: number;
  dailyADR: number;
  revPar: number;
  dailyCost: number;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2d3748",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          backgroundColor: "#10B981", // Change to your desired color
          color: "white", // Change to your desired text color
          "& .MuiChip-deleteIcon": {
            color: "white", // Change to your desired delete icon color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#10B981", // Change to your desired label color
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#10B981", // Change to your desired hover color
          },
        },
      },
    },
  },
});

const dayjs = require("dayjs");
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const ProspectiveGeneralReporting = React.memo(() => {
  //Selected Payload Values:
  const [selectDate, setSelectDate] = useState(dayjs());

  //Daily Report 90-Day Window
  const [dailyReport, setDailyReport] = useState([]);
  const [occupancyReport, setOccupancyReport] = useState([]);
  const [adrReport, setADRReport] = useState([]);

  //Historical Daily Report
  const [historicalDailyReport, setHistoricalDailyReport] = useState([]);
  const [historicalOccupancyReport, setHistoricalOccupancyReport] = useState([]);
  const [historicalADRReport, setHistoricalADRReport] = useState([]);

  //Last Year Daily Report
  const [lastYearDailyReport, setLastYearDailyReport] = useState([]);
  const [lastYearOccupancyReport, setLastYearOccupancyReport] = useState([]);
  const [lastYearADRReport, setLastYearADRReport] = useState([]);

  //Monhtly Report
  const [monthlyOccupancy, setMonthlyOccupancy] = useState(0);
  const [secondMonthlyOccupancy, setSecondMonthlyOccupancy] = useState(0);
  const [thirdMonthlyOccupancy, setThirdMonthlyOccupancy] = useState(0);

  //Last Year Monhtly Report
  const [lastYearMonthlyOccupancy, setLastYearMonthlyOccupancy] = useState(0);
  const [lastYearSecondMonthlyOccupancy, setLastYearSecondMonthlyOccupancy] =
    useState(0);
  const [lastYearThirdMonthlyOccupancy, setLastYearThirdMonthlyOccupancy] =
    useState(0);

  //Last Year Monhtly Report
  const [
    lastYearHistoricalMonthlyOccupancy,
    setLastYearHistoricalMonthlyOccupancy,
  ] = useState(0);
  const [
    lastYearHistoricalSecondMonthlyOccupancy,
    setLastYearHistoricalSecondMonthlyOccupancy,
  ] = useState(0);
  const [
    lastYearHistoricalThirdMonthlyOccupancy,
    setLastYearHistoricalThirdMonthlyOccupancy,
  ] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [historicalToggle, setHistoricalToggle] = useState(false);
  //Holds current suite type when changing the date
  const [currentSuiteType, setCurrentSuiteType] = useState(null);

  //Unit Suite Type --> Can change this all to one state variable --> look into this
  const [bedroomOne, setBedroomOne] = useState(false);
  const [bedroomTwo, setBedroomTwo] = useState(false);
  const [bedroomThree, setBedroomThree] = useState(false);
  const [bedroomJuniorTwo, setBedroomJuniorTwo] = useState(false);
  const [studio, setStudio] = useState(false);

  //Filter Day View Type
  const [is30, setIs30] = useState(false);
  const [is60, setIs60] = useState(false);
  const [is90, setIs90] = useState(false);
  const [activeFilterName, setActiveFilterName] = useState('30');

  //Selected Date
  const [dateSelector, setDateSelector] = useState('');

  //Unit Suite Type --> Can change this all to one state variable --> look into this
  const dispatch = useNotification();

  useEffect(() => {
    searchDate(selectDate);
  }, []);

  useEffect(() => {
    console.log("Setting Map Line Data")
    mapLineData("30");
  }, [dailyReport, historicalDailyReport, lastYearDailyReport,])

  const HandleError = (type: string, title: string, message: string) => {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  };

  const handleButtonClick = (selectedVariable:any, selectedVariableSetter:any, suiteType:string) => {
    setBedroomOne(false);
    setBedroomTwo(false);
    setBedroomThree(false);
    setBedroomJuniorTwo(false);
    setStudio(false);
    
    //Assign State Variables:
    selectedVariableSetter(!selectedVariable);
    // setCurrentSuiteType(suiteType);

    //Fetch Data
    // Conditional is if the selected button is false 
    if(!selectedVariable == false) {
      setCurrentSuiteType(null);
      searchDate(selectDate, 'all suite types');
    } else {
      setCurrentSuiteType(suiteType);
      searchDate(selectDate, suiteType);
    }
  };

  const handleDayFilter = (selectedVariable:boolean, selectedVariableSetter:any, filterType: string) => {
    setIs30(false);
    setIs60(false);
    setIs90(false);

    //Assign State Variable:
    selectedVariableSetter(!selectedVariable)

    //Set Suite Type
    //If the selected variable is false, default view is 30 days
    mapLineData(!selectedVariable == true ? filterType : "30")
    setActiveFilterName(!selectedVariable == true ? filterType : "30")
  }

  const mapLineData = (filter:string) => {
    console.log("Filtering Days")
    const filterDay = dayjs(selectDate).add(+filter, "day");
    const filterLastYear = dayjs(selectDate).add(+filter, "day").subtract(1, "year");
    
    //Set Occupancy Report:
    setOccupancyReport(dailyReport.filter((report: dailyReport) => {
      return report.checkInDay <= filterDay.format('YYYY-MM-DD')
    })
    .map((report: dailyReport) => report?.occupancyRate?.toFixed(2)))

    setLastYearOccupancyReport(lastYearDailyReport.filter((report: dailyReport) => {
      return report.checkInDay <= filterLastYear.format('YYYY-MM-DD')
    })
    .map((report: dailyReport) => report?.occupancyRate?.toFixed(2)))

    setHistoricalOccupancyReport(historicalDailyReport.filter((report: dailyReport) => {
      return report.checkInDay <= filterLastYear.format('YYYY-MM-DD')
    })
    .map((report: dailyReport) => report?.occupancyRate?.toFixed(2)))


    //Set ADR Report
    setADRReport(dailyReport.filter((report: dailyReport) => {
      return report.checkInDay <= filterDay.format('YYYY-MM-DD')
    })
    .map((report: dailyReport) => report?.dailyADR?.toFixed(2)))

    setLastYearADRReport(lastYearDailyReport.filter((report: dailyReport) => {
      return report.checkInDay <= filterLastYear.format('YYYY-MM-DD')
    })
    .map((report: dailyReport) => report?.dailyADR?.toFixed(2)))

    setHistoricalADRReport(historicalDailyReport.filter((report: dailyReport) => {
      return report.checkInDay <= filterLastYear.format('YYYY-MM-DD')
    })
    .map((report: dailyReport) => report?.dailyADR?.toFixed(2)))
  }

  const searchDate = async (selectDate: string, suiteType?:string) => {
    //Commented out for now, we allow for prospective looking to future dates
    // if(dayjs(selectDate).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')) {
    //   HandleError(
    //     "Warning",
    //     "Incorrect Submission",
    //     `The selected date ${dayjs(selectDate).format('YYYY-MM-DD')} cannot be greater than today's date (${dayjs().format('YYYY-MM-DD')})`
    //   )
    //   return
    // } 

    HandleError(
      "Loading",
      "Loading Please Wait",
      `Fetching Reporting ${suiteType ? `for ${suiteType}` : currentSuiteType ? `for ${currentSuiteType}` : `for all suite types. Selected Date: ${selectDate}`}`
    );
    setIsLoading(true);
    let querySuiteType = ''
    if(suiteType) {
      querySuiteType = suiteType != 'all suite types' ? `?suiteType=${suiteType}`:`?suitType=${currentSuiteType}`
    } else {
      querySuiteType = `?suiteType`
    }
    setSelectDate(dayjs(selectDate).format('YYYY-MM-DD'));
    const reportingData = await fetchGetJSON(`${Constants.API_PATH_BOOKING_PROSPECTIVE_REPORT}${querySuiteType}&dateSelected=${dayjs(selectDate).format('YYYY-MM-DD')}`,
      // Constants.API_PATH_BOOKING_PROSPECTIVE_REPORT, {
      //   suiteType: suiteType ? suiteType != 'all suite types' ? suiteType : null : currentSuiteType,
      //   dateSelected: dayjs(selectDate).format('YYYY-MM-DD'),
      // }
    );
    HandleError('Success', 'Success',`Fetched Reporting  ${suiteType ? `for ${suiteType}` : currentSuiteType ? `for ${currentSuiteType}` : 'for all suite types'}`);
    setIsLoading(false);

    //Set Daily:
    setDailyReport(reportingData.currentYearReport.dailyReport);
    setLastYearDailyReport(reportingData.oneYearBack.dailyReport);
    setHistoricalDailyReport(reportingData.historicalOneYearBack.dailyReport);

    // Set Monthly:
    setMonthlyOccupancy(
      reportingData.currentYearReport.occupancyRateThreeMonths[0].occupancyRate.toFixed(2)
    );
    setSecondMonthlyOccupancy(
      reportingData.currentYearReport.occupancyRateThreeMonths[1].occupancyRate.toFixed(2)
    );
    setThirdMonthlyOccupancy(
      reportingData.currentYearReport.occupancyRateThreeMonths[2].occupancyRate.toFixed(2)
    );

    //Set Last Year's Monthly 90-Day Occupancy:
    setLastYearMonthlyOccupancy(
      reportingData.oneYearBack.occupancyRateThreeMonths[0].occupancyRate.toFixed(2)
    );
    setLastYearSecondMonthlyOccupancy(
      reportingData.oneYearBack.occupancyRateThreeMonths[1].occupancyRate.toFixed(2)
    );
    setLastYearThirdMonthlyOccupancy(
      reportingData.oneYearBack.occupancyRateThreeMonths[2].occupancyRate.toFixed(2)
    );

    //Set Last Year's Historical Occupancy:
    setLastYearHistoricalMonthlyOccupancy(
      reportingData.historicalOneYearBack.occupancyRateThreeMonths[0].occupancyRate.toFixed(2)
    );
    setLastYearHistoricalSecondMonthlyOccupancy(
      reportingData.historicalOneYearBack.occupancyRateThreeMonths[1].occupancyRate.toFixed(2)
    );
    setLastYearHistoricalThirdMonthlyOccupancy(
      reportingData.historicalOneYearBack.occupancyRateThreeMonths[2].occupancyRate.toFixed(2)
    );
  };

  //Table Creation:
  const tableCreation = (
    thisYear: boolean,
    historical: boolean
  ): JSX.Element => {
    return (
      <TableContainer sx={{ overflow: "visible" }}>
        <Table
          sx={{ minWidth: 700, height: "auto" }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Day Of Report</StyledTableCell>
              <StyledTableCell>Occupied Rooms</StyledTableCell>
              <StyledTableCell>Active Units</StyledTableCell>
              <StyledTableCell>Blackout Days</StyledTableCell>
              <StyledTableCell>Available Rooms</StyledTableCell>
              <StyledTableCell>Daily Occupancy Rate</StyledTableCell>
              <StyledTableCell>Daily Revenue</StyledTableCell>
              <StyledTableCell>Daily ADR</StyledTableCell>
              <StyledTableCell>Daily Rev Par</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {thisYear == true && historical == false
              ? dailyReport.map((report: dailyReport) => (
                  <TableRow key={report.checkInDay}>
                    <TableCell align="left">
                      {dayjs(report.checkInDay).format("LL")}
                    </TableCell>
                    <TableCell align="left">{report.occupiedRooms}</TableCell>
                    <TableCell align="left">{report.activeUnits}</TableCell>
                    <TableCell align="left">{report.blackOutNumber}</TableCell>
                    <TableCell align="left">{report.availableRooms}</TableCell>
                    <TableCell align="left">{report.occupancyRate}%</TableCell>
                    <TableCell align="left">
                      $
                      {report?.dailyCost.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.dailyCost
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {report?.dailyADR.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.dailyADR
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {report?.revPar.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.revPar
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                  </TableRow>
                ))
              : thisYear == false && historical == false
              ? lastYearDailyReport.map((report: dailyReport) => (
                  <TableRow key={report.checkInDay}>
                    <TableCell align="left">
                      {dayjs(report.checkInDay).format("LL")}
                    </TableCell>
                    <TableCell align="left">{report.occupiedRooms}</TableCell>
                    <TableCell align="left">{report.activeUnits}</TableCell>
                    <TableCell align="left">{report.blackOutNumber}</TableCell>
                    <TableCell align="left">{report.availableRooms}</TableCell>
                    <TableCell align="left">{report.occupancyRate}%</TableCell>
                    <TableCell align="left">
                      $
                      {report?.dailyCost.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.dailyCost
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {report?.dailyADR.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.dailyADR
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {report?.revPar.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.revPar
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                  </TableRow>
                ))
              : historicalDailyReport.map((report: dailyReport) => (
                  <TableRow key={report.checkInDay}>
                    <TableCell align="left">
                      {dayjs(report.checkInDay).format("LL")}
                    </TableCell>
                    <TableCell align="left">{report.occupiedRooms}</TableCell>
                    <TableCell align="left">{report.activeUnits}</TableCell>
                    <TableCell align="left">{report.blackOutNumber}</TableCell>
                    <TableCell align="left">{report.availableRooms}</TableCell>
                    <TableCell align="left">{report.occupancyRate}%</TableCell>
                    <TableCell align="left">
                      $
                      {report?.dailyCost.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.dailyCost
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {report?.dailyADR.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.dailyADR
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {report?.revPar.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                      {!report.revPar
                        .toLocaleString("en-US", { maximumFractionDigits: 2 })
                        .includes(".")
                        ? ".00"
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <div className="font-display bg-white lg:rounded-tl-lg">
        <div className="mt-10 text-center text-lg">
          <h1 className="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-1xl">
            Prospective General Reporting
          </h1>
        </div>
        <div className="mt-10 text-center text-lg">
          <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl">{`${dayjs(
            selectDate
          )
            .subtract(1, "year")
            .format("YYYY")} and ${dayjs(selectDate).format(
            "YYYY"
          )} Reporting for Occupancy, ADR, and RevPar for ${dayjs(
            selectDate
          ).format("MMM DD")} to ${dayjs(selectDate)
            .add(90, "day")
            .format("MMM DD")}`}</h1>
        </div>
        <p className="text-center text-lg"><strong>Please Note:</strong> Only <u>confirmed retail bookings</u> and <u>confirmed</u> or <u>tentative corporate bookings</u> are part of the data set included in this report.<br/>Any <strong>tentative retail</strong> bookings will <strong>NOT BE</strong> included in the prospective report.</p>
      </div>
      {isLoading ? (
        <div className="flex bg-white mt-20 px-3 py-5 pb-1 justify-center">
          <Loading size="3x-large" type="big-spiral" />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center mt-3 mb-5">
                <span className="text-gray-600 text-lg mr-3">Select Prospective Date to View: </span>
                <div className="w-1/7 flex flex-row">
                  <input
                      id="start date"
                      onChange={(input) => {
                        setDateSelector(input.target.value);
                      }}
                      type="date"
                      className="mt-0 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                  />
                  <button
                    className="ml-5 border px-3 hover:bg-green-400 bg-green-500 text-white my-3"
                    onClick={() =>  searchDate(dateSelector, currentSuiteType)}
                    >
                    Update Report
                  </button>
                </div>
          </div>
          <h1 className="mt-2 mb-2 text-xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl lg:text-1xl  ">
            <u>{`${dayjs(selectDate).format(
              "YYYY"
            )} Monthly Occupancy Reporting for ${dayjs(selectDate).format(
              "MMMM"
            )} to ${dayjs(selectDate).add(2, "month").format("MMMM")}`}</u>
          </h1>
          <div className="flex md:flex-row w-full h-full mb-3 justify-around sm:flex-col">
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={monthlyOccupancy}
                data={monthlyOccupancy}
                title={`Monthly Occupancy Rate for ${dayjs(selectDate).format(
                  "MMMM"
                )} ${dayjs(selectDate).format('YYYY')} on ${dayjs(selectDate).format('LL')}`}
                button={"Graph"}
              />
            </div>
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={secondMonthlyOccupancy}
                data={secondMonthlyOccupancy}
                title={`Monthly Occupancy Rate for ${dayjs(selectDate)
                  .add(1, "month")
                  .format("MMMM")} ${dayjs(selectDate).add(1, "month").format('YYYY')} on ${dayjs(selectDate).format('LL')}`}
                button={"Graph"}
              />
            </div>
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={thirdMonthlyOccupancy}
                data={thirdMonthlyOccupancy}
                title={`Monthly Occupancy Rate for ${dayjs(selectDate)
                  .add(2, "month")
                  .format("MMMM")} ${dayjs(selectDate).add(2, "month").format('YYYY')} on ${dayjs(selectDate).format('LL')}`}
                button={"Graph"}
              />
            </div>
          </div>
          <h1 className="mb-3 text-xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl lg:text-1xl  ">
            <u>{`${dayjs(selectDate)
              .subtract(1, "year")
              .format("YYYY")} Monthly Occupancy Reporting for ${dayjs(
              selectDate
            ).format("MMMM")} to ${dayjs(selectDate).add(2, "month").format("MMMM")}`}</u>
          </h1>
          <div className="flex md:flex-row w-full h-full mb-2 justify-around sm:flex-col">
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={lastYearMonthlyOccupancy}
                data={lastYearMonthlyOccupancy}
                title={`Monthly Occupancy Rate for ${dayjs(selectDate)
                  .subtract(1, "year")
                  .format("MMMM")} ${dayjs(selectDate)
                  .subtract(1, "year")
                  .format("YYYY")} on ${dayjs(selectDate).subtract(1, "year").format('LL')}`}
                button={"Graph"}
              />
            </div>
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={lastYearSecondMonthlyOccupancy}
                data={lastYearSecondMonthlyOccupancy}
                title={`Monthly Occupancy Rate for ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(1, "month")
                  .format("MMMM")} ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(1, "month")
                  .format("YYYY")} on ${dayjs(selectDate).subtract(1, "year").format('LL')}`}
                button={"Graph"}
              />
            </div>
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={lastYearThirdMonthlyOccupancy}
                data={lastYearThirdMonthlyOccupancy}
                title={`Monthly Occupancy Rate for ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(2, "month")
                  .format("MMMM")} ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(2, "month")
                  .format("YYYY")} on ${dayjs(selectDate).subtract(1, "year").format('LL')}`}
                button={"Graph"}
              />
            </div>
          </div>
          <hr className="my-5" />
          <div className="flex md:flex-row w-full h-full mb-2 justify-around sm:flex-col">
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={lastYearHistoricalMonthlyOccupancy}
                data={lastYearHistoricalMonthlyOccupancy}
                title={`Historical Occupancy Rate for ${dayjs(selectDate)
                  .subtract(1, "year")
                  .format("MMMM")} ${dayjs(selectDate)
                  .subtract(1, "year")
                  .format("YYYY")}`}
                button={"Graph"}
              />
            </div>
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={lastYearHistoricalSecondMonthlyOccupancy}
                data={lastYearHistoricalSecondMonthlyOccupancy}
                title={`Historical Occupancy Rate for ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(1, "month")
                  .format("MMMM")} ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(1, "month")
                  .format("YYYY")}`}
                button={"Graph"}
              />
            </div>
            <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5">
              <StatBubbles
                key={lastYearHistoricalThirdMonthlyOccupancy}
                data={lastYearHistoricalThirdMonthlyOccupancy}
                title={`Historical Occupancy Rate for ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(2, "month")
                  .format("MMMM")} ${dayjs(selectDate)
                  .subtract(1, "year")
                  .add(2, "month")
                  .format("YYYY")}`}
                button={"Graph"}
              />
            </div>
          </div>

          {/* Add Line Graph here: */}
          <div className="mt-10 text-center text-lg">
            <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl  ">{`Daily Reporting for Occupancy, ADR, and ${dayjs(
              selectDate
            ).format("MMM DD, YYYY")} to ${dayjs(selectDate)
              .add(90, "day")
              .format("MMM DD, YYYY")}`}</h1>
          </div>
          <span className=" transition-all duration-600 text-gray-600 text-m mb-3">
              Filter by Suite Type:
            </span>
          <div className="flex flex-row flex-wrap mb-5">
              <button 
                  key={'Studio'}
                  className={
                    (studio ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                    " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                  }
                  onClick={() => handleButtonClick(studio, setStudio, 'Studio')}
                >
                  {`Studio`}
              </button>
              <button 
                key={'1 Bedroom'}
                className={
                  (bedroomOne ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomOne, setBedroomOne, '1 Bedroom')}
              >
                {`1 Bedroom`}
              </button>
              <button 
                key={'2 Bedroom'}
                className={
                  (bedroomTwo ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomTwo, setBedroomTwo, '2 Bedroom')}
              >
                {`2 Bedroom`}
              </button>
              <button 
                key={'Junior 2 Bedroom'}
                className={
                  (bedroomJuniorTwo ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomJuniorTwo, setBedroomJuniorTwo, 'Junior 2 Bedroom')}
              >
                {`Junior 2 Bedroom`}
              </button>
              <button 
                key={'3 Bedroom'}
                className={
                  (bedroomThree ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomThree, setBedroomThree, '3 Bedroom')}
              >
                {`3 Bedroom`}
              </button>
          </div>
          <span className=" transition-all duration-600 text-gray-600 text-m mb-3">
              Filter by Day Range:
          </span>
          <div className="flex flex-row flex-wrap mb-5">
              <button 
                  key={'is30'}
                  className={
                    (is30 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                    " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                  }
                  onClick={() => handleDayFilter(is30, setIs30, '30')}
                >
                  {`30 Day`}
              </button>
              <button 
                key={'is60'}
                className={
                  (is60 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => handleDayFilter(is60, setIs60, '60')}
              >
                {`60 Day`}
              </button>
              <button 
                key={'is90'}
                className={
                  (is90 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => handleDayFilter(is90, setIs90, '90')}
              >
                {`90 Day`}
              </button>
          </div>
          <div className="flex flex-row mb-2 sm:pr-2">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Toggle to See Historical
            </span>
            <ToggleSwitch
              id="toggleHistorical"
              handleStateToggle={() => setHistoricalToggle(!historicalToggle)}
              className="justify-center align-center"
              size="lg"
              onoff={historicalToggle}
            />
          </div>
          <div
            className={`flex flex-row w-full h-full mb-3 my-10 justify-around ${
              historicalToggle ? "hidden" : ""
            }`}
          >
            <div className="w-3/4">
              <LoadLineGraph
                data={occupancyReport}
                fullData={dailyReport}
                lastyearData={lastYearOccupancyReport}
                name={`${activeFilterName}-Day Window Occupancy Report`}
                type="Occupancy Rate"
                month={dayjs(selectDate).format("MMMM")}
                selectDate={selectDate}
                historical={false}
                filterDay={activeFilterName}
              />
            </div>
          </div>
          <div
            className={`flex flex-row w-full h-full mb-3 my-10 justify-around ${
              historicalToggle ? "hidden" : ""
            }`}
          >
            <div className="w-3/4">
              <LoadLineGraph
                data={adrReport}
                fullData={dailyReport}
                lastyearData={lastYearADRReport}
                name={`${activeFilterName}-Day Window ADR Report`}
                type="ADR"
                month={dayjs(selectDate).format("MMMM")}
                selectDate={selectDate}
                historical={false}
                filterDay={activeFilterName}
              />
            </div>
          </div>
          <div
            className={`flex flex-row w-full h-full mb-3 my-10 justify-around ${
              historicalToggle ? "" : "hidden"
            }`}
          >
            <div className="w-3/4">
              <LoadLineGraph
                data={occupancyReport}
                fullData={dailyReport}
                lastyearData={historicalOccupancyReport}
                // lastyearHistorical={historicalDailyReport}
                name={`${activeFilterName}-Day Window Occupancy Report`}
                type="Occupancy Rate"
                month={dayjs(selectDate).format("MMMM")}
                selectDate={selectDate}
                historical={true}
                filterDay={activeFilterName}
              />
            </div>
          </div>
          <div
            className={`flex flex-row w-full h-full mb-3 my-10 justify-around ${
              historicalToggle ? "" : "hidden"
            }`}
          >
            <div className="w-3/4">
              <LoadLineGraph
                data={adrReport}
                fullData={dailyReport}
                lastyearData={historicalADRReport}
                // lastyearHistorical={historicalDailyReport}
                name={`${activeFilterName}-Day Window ADR Report`}
                type="ADR"
                month={dayjs(selectDate).format("MMMM")}
                selectDate={selectDate}
                historical={true}
                filterDay={activeFilterName}
              />
            </div>
          </div>
          <div>
            <h1 className="text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl   mb-2">
              Current 90 Day Window Breakdown:{" "}
            </h1>
            {tableCreation(true, false)}
          </div>
          <div className="my-10">
            <h1 className="text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl   mb-2">
              Last Year 90 Day Window Breakdown:{" "}
            </h1>
            {tableCreation(false, false)}
          </div>
          <div className="my-10">
            <h1 className="text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl   mb-2">
              Last Year Historical Window Breakdown:{" "}
            </h1>
            {tableCreation(false, true)}
          </div>
          <ScrollToTop/>
        </>
      )}
    </div>
  );
});

const StatBubbles = React.memo((props: any) => {
  let Button = <></>;
  let series = [props.data];
  const radialOptions = {
    colors: ["#10b981"],
    dataLabels: {
      enabled: false,
    },

    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            offsetY: -2,
            fontSize: "20px",
            color: "#047857",

            fontFamily: "Circular Std, sans-serif",
          },
        },
      },
    },
  };

  let regularStyling = "flex flex-col items-left justify-left mt-5";
  if (props.button === "Graph") {
    regularStyling = "flex flex-col items-left justify-right";
  }

  return (
    <div className="flex flex-col appear  bg-white shadow-md   py-5 px-5 rounded-lg h-full">
      <div className="flex flex-col ">
        <span className="text-green-500 text-4xl leading-none font-bold  ">
          {props.data}%
        </span>

        <span className="text-green-700  text-base pt-1">{props.title}</span>
        <span className=" text-sm font-thin text-gray-400">
          {props.subtitle}
        </span>
      </div>

      <div className={regularStyling}>
        <div className=" w-full h-full transform -translate-x-5 -translate-y-5">
          <div className="relative ml-10">
            <Chart
              options={radialOptions}
              series={series}
              type="radialBar"
              height="225px"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const LoadLineGraph = React.memo((props: any) => {
  //Grab 30 Day Window from this day
  const checkInDate = props.fullData.map((report: dailyReport) =>
    dayjs(report.checkInDay).format("MMM D, YYYY")
  );

  const series = [
    {
      name: `${props.name} ${dayjs().format("YYYY")}`,
      data: props.data,
    },
    {
      name: !props.historical
        ? `${props.name} ${dayjs().subtract(1, "year").format("YYYY")}`
        : `Historical ${props.type} ${dayjs()
            .subtract(1, "year")
            .format("YYYY")}`,
      data: props.lastyearData,
    },
  ];

  //Need to define type later
  let options:any = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    colors: ["#10B981", "#0000FF",],
    title: {
      text: `${props.filterDay}-Day Window ${props.type} from ${dayjs(props.selectDate).format(
        "MMM DD, YYYY"
      )} to ${dayjs(props.selectDate).add(+props.filterDay, "day").format("MMM DD, YYYY")}`,
      align: "left",
      style: {
        fontSize: "20px",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: checkInDate,
    },
  };

  if(props.type == "Occupancy Rate") {
    options["yaxis"] = {
      max: 100,
      min: 0,
    }
  }

  if (props.historical) {
    options.colors[1] = "#ff5349";
  }

  return (
    <div className=" w-full h-full transform -translate-x-5 -translate-y-5">
      <Chart
        options={options}
        series={series}
        type="line"
        height="350px"
        width="100%"
      />
    </div>
  );
});
