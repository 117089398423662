import { GiHazardSign } from "react-icons/gi";
import store from "../../../../redux/store";
import { useNotification } from "../../../Notifications/NotificationProvider";
import { SetStateAction, useEffect, useState } from "react";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { FiPlus } from "react-icons/fi";
import React from "react";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  id: number;
  parkingSpotLocation: string;
};
const NewParkingSpotBlackoutsModal = React.memo((props: Props) => {
    const [parkingSpotName, setParkingSpotName] = useState(props.parkingSpotLocation);
    const [onOff, setOnOff] = useState(props.onoff)

    //Payload:
    const [parkingSpotID, setParkingSpotID] = useState(props.id);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [reason, setReason] = useState('')

    //Notificatoin Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    const handleModal = (event: any) => {
        console.log("In New Parking Spot Blackout Handle Modal")
        console.log(props.onoff)
        if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
          props.handleModalToggle(props.onoff);
          setOnOff(!onOff);
        }
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        if(!props.id || !startDate || !endDate){
            NotificationHandler('Error', 'Error', 'Parking Spot Blackout needs to have a parking spot, start date, and end date.')
            return
        }

        if(startDate > endDate) {
            NotificationHandler('Warning', 'Error', 'Start Date is greater than End Date')
            return
        }

        if(startDate == endDate) {
          NotificationHandler('Error', 'Error', 'Start Date CANNOT be equal to End Date. Blackout days are counted as nightly.')
          return
        }

        const parkingSpotPayload = {
            start_date: startDate,
            end_date: endDate,
            parking_spot_id: props.id,
            reason: reason,
        }

        const createParking = await fetchRequestJSON('POST', Constants.API_PATH_PARKING_SPOT_BLACKOUTS_CREATE, parkingSpotPayload)

        if(createParking.status == 'Success') {
            NotificationHandler('Success', 'Success', createParking.data)
            //Redux below:
            let update = store.getState().parkingSpotReducer.listUpdate;
            update()
            return
        } else {
            NotificationHandler('Error', 'Error', createParking.status)
            return
        }
    }

  return (
    <div
      id="outsideDiv"
      onClick={handleModal}
      className={`fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 
      ${ !props.onoff ? "hidden" : "" }`
      }
    >
      <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
        {/* <IoIosArrowBack className="text-white text-3xl absolute transform -translate-x-28 -translate-y-6" /> */}
        <FiPlus className="text-blue-600 text-4xl mb-3" />

        <span className="text-xl font-bold text-gray-800 text-left">
          {props.title}
        </span>
        <span className="text-xs text-center text-gray-400"></span>
        <form>
          <div className="grid grid-cols-3 gap-6 w-full my-5">
            <label className="block  ">
              <span className="text-gray-400 text-xs">
                <strong>Parking Spot Location:</strong>
              </span>
              <br className=" py-1 "/>
              {props.parkingSpotLocation}
            </label>

            <label className="block ">
              <span className="text-gray-400 text-xs">
                <strong>Start Date:</strong>
              </span>
              <input
                id="start_date"
                onChange={(input) => setStartDate(input.target.value)}
                type="date"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              />
              {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
            </label>

            <label className="block ">
              <span className="text-gray-400 text-xs">
                <strong>End Date:</strong>
              </span>
              <input
                id="end_date"
                onChange={(input) => setEndDate(input.target.value)}
                type="date"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              />
            </label>

            <label className="block ">
              <span className="text-gray-400 text-xs">
                <strong>Reason:</strong>
              </span>
              <textarea
                        id="notes"
                        className="mt-1 block w-96 rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows={3}
                        onChange={(input) => {setReason(input.target.value)}}
              />
            </label>
          </div>

          <div className="flex flex-row justify-start w-full mt-6">
            <button
              onClick={(input) => handleSubmit(input)}
              className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
            >
              Add Blackout
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default NewParkingSpotBlackoutsModal
