import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import {Amplify} from "aws-amplify";
import store from "../redux/store";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAuthSession, fetchUserAttributes, getCurrentUser, signIn } from "aws-amplify/auth";
// import awsconfig from "./awsExports";
import awsmobile from "./awsExports";
import dayjs from "../utils/Dayjs";



export const returnJWTToken = async() => {
  // const cred = await fetchAuthSession();
  // console.log(cred)
  // // const token = cred?.signInUserSession.idToken.jwtToken
  // const token = cred?.tokens.idToken;
  // console.log(token)

  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  return idToken.toString()
}

Amplify.configure(awsmobile);
const existingConfig = Amplify.getConfig();

Amplify.configure({
  ...existingConfig,
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API?.REST,
      'Admin System': {
        endpoint:  process.env.REACT_APP_AWS_API_GATEWAY_LINK,
      },
    } 
  }
})


type Fields = {
  email: string;
  password: string;
};

let errorMessage = "";

const mapStateToProps = (state: any) => ({
  helperText: state.reducer.helperText,
});

export const LoginForm = () => {
  const { REACT_APP_API_LOGIN } = process.env;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { handleSubmit } = useForm<Fields>();
  const onSubmit = (data: Fields) => {
    data.email = email;
    data.password = password;
  };
  const history = useHistory();
  useEffect(() => {
    // Check to see if user is already logged in:
    try {
      let res = getCurrentUser().then(
        (result) => {
          if (result) {
            history.push("/user/dashboard");
          }
        }
      );
    } catch {
    }
  }, [])
  //verify user on login
  const AuthLogin = () => {
    signIn({
      username: email,
      password: password,
    })
      .then(
        async (response:any) => {
          console.log('log in response: ')
          console.log(response);

          if (response.isSignedIn) {
            const currentUser = await getCurrentUser();
            console.log(currentUser)
            const currentUserAttributes = await fetchUserAttributes();
            console.log(currentUserAttributes)
            const cred = await fetchAuthSession();
            
            console.log(cred)

            store.dispatch({
              type: "loginSuccess",
              message: "Success.",
              user: { username: email, password: password },
            });

            // sets the cookie expiry to the current time (login) + 15 hours
            let currentDate = new Date();
            currentDate.setTime(currentDate.getTime() + 15 * 1000 * 60 * 60);
            let expires = ";expires=" + currentDate.toUTCString();
            // storing the tokens in their respective locations
            document.cookie =
              "accessToken" +
              "=" +
              cred.tokens.accessToken +
              expires;
            document.cookie =
              "idToken" +
              "=" +
              cred.tokens.idToken +
              expires;
            document.cookie="sessionToken"+"="+cred.credentials.sessionToken;
            localStorage.setItem(
              "refreshToken",
              // JSON.stringify(cred.credentials.expiration)
              //dayjs(cred.credentials.expiration).subtract(59, 'minute').toDate()
              cred.credentials.expiration.toString()
            );
            // console.log(document.cookie)
            history.push("/user/dashboard");
            window.location.reload();
          }
        },
        (error) => {
          console.log(error);
          errorMessage = error.message;
          store.dispatch({
            type: "loginError",
            message: "Invalid email or password",
          });
        }
      )
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <form
      name="loginForm"
      className="flex flex-col "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group-collection flex flex-col">
        <label className="block py-2">
          <span className="text-gray-700">Email address</span>
          <input
            type="email"
            className="mt-1 block w-full shadow-sm rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            placeholder="john@example.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>

        <label className="block py-2">
          <span className="text-gray-700">Password</span>
          <input
            type="password"
            className="mt-1 block w-full shadow-sm rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            placeholder="***Secret Password***"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </label>
      </div>

      <div className="py-3">Just checking the API: {REACT_APP_API_LOGIN}</div>

      <button
        type="submit"
        className=" hover:bg-gray-300 py-1 text-white text-sm   px-8 w-full rounded-md shadow-sm bg-green-500"
        onClick={() => AuthLogin()}
      >
        Log In
      </button>

      <div className=" mx-auto mt-1">
        {store.getState().reducer.isLoginSuccess && (
          <div className="text-red-400 text-sm ">Login Success!</div>
        )}
        {store.getState().reducer.loginError && (
          <div className="text-red-400 text-sm ">
            Error Message: {errorMessage}
          </div>
        )}
      </div>
    </form>
  );
};

export default connect(mapStateToProps)(LoginForm);
