import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider"
import store from "../../../../redux/store";
type Props = {
    toggle: boolean;
    handleEditBarToggle: any; //This is a function, need to rename this when we refactor
    parkingSpotInfo: any;
}
const ParkingSpotEditModal = (props:Props) => {
       // const [toggle, setToggle] = useState(props.toggle);
       const [building, setBuilding] = useState([]);
       const [units, setUnits] = useState([]);
       const [unitName, setUnitsName] = useState('');
   
       //Payload for Creating Parking Spot:
       const [buildingID, setBuildingID] = useState(props.parkingSpotInfo?.building_id);
       const [unitID, setUnitID] = useState(props.parkingSpotInfo?.unit_id ? props.parkingSpotInfo.unit_id : -1);
       const [parkingLevel, setParkingLevel] = useState(props.parkingSpotInfo?.parking_spot_level);
       const [parkingNotes, setParkingNotes] = useState(props.parkingSpotInfo?.parking_spot_notes);
   
       const [parkingSpotInfo, setParkingSpotInfo] = useState(props.parkingSpotInfo)

       //Notification Handler Logic:
       const dispatch = useNotification();
   
       const HandleError = (type: string, title: string, message: string,) => {
         dispatch({
           type: type.toUpperCase(),
           title: title,
           message: message
         })
       }
       //-----------------------
   
       useEffect(() => {
           loadBuildings();
       }, []);
   
       const loadBuildings = async() => {
           const allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);
           setBuilding(allBuildings.data.sort((buildingOne:any, buildingTwo:any) => buildingOne.building_name.localeCompare(buildingTwo.building_name)))
           
           if(parkingSpotInfo.unit_id) {
                const getUnitsByBuilding = await fetchGetJSON(`${Constants.API_PATH_BUILDING_GET_UNITS}${parkingSpotInfo.building_id}`)
        
                if(getUnitsByBuilding.status == 'Success') {
                    setUnits(getUnitsByBuilding.data);
                    const unitName = units.filter((units) => {return units.id == unitID})
                    setUnitsName(unitName[0]?.suite_name);
                }
           }
       }  
   
       const handleBuildingChange = async(event: { target: { value:string; }; }) => {
         setBuildingID(event.target.value)
         setUnitID(-1);
         //Instantiate the units dropdown list based on the building selected:
   
         const getUnitsByBuilding = await fetchGetJSON(`${Constants.API_PATH_BUILDING_GET_UNITS}${event.target.value}`)
      
         if(getUnitsByBuilding.status == 'Success') {
           setUnits(getUnitsByBuilding.data);
         }
       }
   
       const handleChange = async(event: { target: { id: string; value: string; }; }) => {
         const { id, value} = event.target;
         id == "units" ? setUnitID(+value) : id == "notes" ? setParkingNotes(event.target.value) : setParkingLevel(value);
   
         if(id == "units" && value != "-1") {
           const unitName = units.filter((units) => {return units.id == value})
           console.log(unitName[0].suite_name)
           setUnitsName(unitName[0].suite_name);
         }
       } 
   
       const handleSubmit = async () => {
         //Edit parking:
         //Check if Parking Level Location and Building exist:
         if(!buildingID || !parkingLevel){
           HandleError('Error', 'Error', 'No Parking Level or Building ID selected. Those are required fields.')
           return
         }
         
         //If the parking spot has changed their original unitID or original buildingID, the parking spot level must be different
         if(buildingID != parkingSpotInfo.building_id && parkingSpotInfo.parking_spot_level == parkingLevel) {
            HandleError('Error', 'Error', 'Parking Level Location must also be edited since a change of building for the parking spot has been requested.')
            return
         } 
   
         const editParkingPayload = {
           building_id: buildingID,
           unit_id: unitID == -1 ? null : unitID,
           parking_spot_level: parkingLevel,
           parking_spot_notes: parkingNotes
         }
   
         const editParking = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_SPOT_EDIT, {
            id: parkingSpotInfo.id,
            fields: editParkingPayload
         })
   
         if(editParking.status == 'Success') {
           HandleError('Success', 'Success', 'Parking Spot has been succesfully edited')
   
           //Redux below to update parking spot profile:
           let update = store.getState().parkingSpotReducer.profileUpdate;
           update()
           //Prop below to close the modal once the parking spot is finished
           props.handleEditBarToggle()
           return
         } else {
           HandleError('Error', 'Error', 'Parking spot has been unsuccesful for edits')
           return
         }
       }
   
       const contentCreation = () => {
           return (
               <form className="flex flex-col pl-6 pr-12 py-4">
                   <strong className="text-gray-600 text-md font-bold mb-4 mt-2">
                       General Info
                   </strong>
                   <label className="block pl-3 mb-5">
                       <span className="text-gray-400 text-xs">
                           <strong>Select Building*</strong>
                       </span>
                       <select
                           id="buildings"
                           onChange={handleBuildingChange}
                           value={buildingID}
                           className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                           placeholder="Choose"
                       >
                            {building.map((building:any) => 
                                <option value={building.id}>{building.building_name}</option>
                            )}
                       </select>
                    </label>
                    <label className="block pl-3 mb-5">
                       <span className="text-gray-400 text-xs">
                           <strong>Select Unit (Optional)</strong>
                       </span>
                       <select
                           id="units"
                           onChange={handleChange}
                           value={unitID}
                           className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                           placeholder="Choose"
                       >
                           <option value={-1}>This Parking Does Not Belong To a Core Unit</option>
                           {units.map((unit:any) => 
                             <option value={unit?.id}>{unit?.suite_name}</option>
                           )}
                       </select>
                    </label>
                    {
                     unitID == -1 &&
                     <label className="block pl-3 mb-5">
                         <strong>Please note: By Selecting No Unit, the Parking Spot will be defaulted to the building you have selected.</strong>
                     </label>
                    }
                     <label className="block pl-3 mb-5 text-gray-500 text-s">
                         Selected Unit: <u>{unitID == -1 ? 'No Unit Selected' : unitName}</u>
                     </label>
   
                    <label className="block pl-3 mb-5">
                       <span className="text-gray-400 text-xs">
                           <strong>Parking Level Location*</strong>
                       </span>
                       <input
                           id="parking_level"
                           onChange={handleChange}
                           defaultValue={parkingSpotInfo.parking_spot_level}
                           type="text"
                           className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                           placeholder="Ex. Level A2 - 130"
                       />
                    </label>
                    <label className="block pl-3 mb-5 mt-2">
                       <strong className="text-gray-400 text-xs">Additional Parking Spot Notes</strong>
                       <textarea
                           id="notes"
                           defaultValue={parkingSpotInfo.parking_spot_notes}
                           className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                           rows={3}
                           onChange={handleChange}
                       ></textarea>
                   </label>
               </form>
           )
       }
   
       return (
          <div className={
            props.toggle ?  "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + 
            "z-50 opacity-100 translate-x-0 lg:max-w-lg w-full" : "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide"
          }> 
           {/* Top Title Bar */}
           <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
             <button
               onClick={props.handleEditBarToggle}
               className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
             >
               <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
             </button>
   
             <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
               <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
             </button>
           </div>
           <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
             <span className=" font-black text-xl text-gray-700">
               Edit Parking Spot
             </span>
             <span className="italic font-sofia text-gray-400 text-lg font-thin">
             </span>
           </div>
           {contentCreation()}
   
           {/* Submit Logic Below */}
           <div className="h-24 flex flex-row ">
             <button
               onClick={() => handleSubmit()}
               className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
             >
               Submit
             </button>
           </div>
          </div>
       )
}

export default ParkingSpotEditModal