import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import { withHooksHOC } from "../../components/Notifications/NotificationProvider";

type Props = {
  toggle: any;
  handleEditBarToggle: any;
  ErrorNote: any;
  history: any;
};

type EditUnitState = {
  buildings: string [];
  owners: string [];
  suite: string;
  suite_number: string;
  suite_address: string;
  suite_building: string;
  suite_model: string;
  suite_type: string;
  min_stay: string;
  heat_building: boolean;
  suite_size: number;
  bath_count: number;
  bed_count: number;
  fob_id: number;
  owner_email: string;
  management_start_date: string;
  // isp: string;
  // isp_account: number;
  rent_due: number;
  rent_increase_date: string;
  lease_start: string;
  lease_end: string;
  // last_month_rent_hold: number;
  // deposit_hold: number;
  // password: string;
  // login: string;
  // unit_phone: string;
  // tvp: string;
  // hydro_provider: string;
  notes: string;
  percent_managed: number;
  // tv_account: string;
  managed_model: boolean;
  balcony: boolean;
  sofa_bed: boolean;
  bunk_bed: boolean;
  owner_list: string[];
  building_list: string[];
  bed_config: string;
  isQuadable:boolean;
  quad_status:boolean;
  super_quad:boolean;
  super_quad_bunk:boolean;
  super_quad_notes:string;
};

class NewUnit extends Component<Props, EditUnitState> {
  constructor(props: any) {
    super(props);

    this.state = {
      buildings: [],
      owners: [],
      suite: "",
      suite_number: "",
      suite_address: "",
      suite_building: "",
      suite_model: "",
      suite_type: "Studio",
      min_stay: "",
      heat_building: false,
      suite_size: 0,
      bath_count: 0,
      bed_count: 0,
      fob_id: 0,
      owner_email: "",
      management_start_date: "",
      // isp: "",
      // isp_account: 0,
      rent_due: 0,
      rent_increase_date: new Date().toString(),
      lease_start: new Date().toString(),
      lease_end: new Date().toString(),
      // last_month_rent_hold: 0,
      // deposit_hold: 0,
      // password: "",
      // login: "",
      // unit_phone: "",
      // tvp: "",
      // hydro_provider: "",
      notes: "",
      percent_managed: 0,
      // tv_account: "",
      managed_model: false,
      balcony: false,
      sofa_bed: false,
      bunk_bed: false,
      owner_list: [],
      building_list: [],
      bed_config: "",

      isQuadable:false,
      quad_status: false,
      super_quad: false,
      super_quad_notes: '',
      super_quad_bunk: false,
    };
  }

  componentDidMount = async () => {
    this.getBuildings();
    this.getOwners();
  }

  getBuildings = async () => {
    let allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);
    let buildingList = [];
    for (let i = 0; i < allBuildings.data.length; i++) {
      buildingList.push(allBuildings.data[i].building_name);
    }
    this.setState({ buildings: buildingList });
  }

  getOwners = async () => {
    let allOwners = await fetchGetJSON(Constants.API_PATH_OWNER_GET_ALL);
    let ownersList = [];
    for (let i = 0; i < allOwners.data.length; i++) {
      ownersList.push(allOwners.data[i].email);
    }
    this.setState({ owners: ownersList });
  }

  restrictNumbers = (event: any) => {
    let keyCode = event.charCode;
    if (48 <= keyCode && keyCode <= 57) {return;}
    event.preventDefault();
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let returnVal = this.target.value;
      if (this.target.id == "percent_managed" && returnVal != "") {
        returnVal = Math.min(100, Math.max(0, returnVal));
      }
      returnObj[this.target.id] = returnVal;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
    // console.log(this.state)
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    if(stateObject?.super_quad) {
      this.setState({
        super_quad_bunk: false
      })
    } else if (stateObject?.super_quad_bunk) {
      this.setState({
        super_quad: false
      })
    }
    this.setState(stateObject);
  };
  
  handleClick = async () => {
    this.props.handleEditBarToggle();
    let NotificationHandler = this.props.ErrorNote;

    let unitOwners = await fetchGetJSON(Constants.API_PATH_OWNER_GET_ALL);
    let owner = unitOwners.data.find(
      (ownerItem: any) =>
        ownerItem.email.trim() === this.state.owner_email.trim()
    );

    let allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);
    let building = allBuildings.data.find(
      (buildingItem: any) =>
        buildingItem.building_name.trim() === this.state.suite_building.trim()
    );

    if (
      owner &&
      building &&
      this.state.suite_address &&
      this.state.suite_number != "" &&
      this.state.suite_type &&
      this.state.suite_size &&
      this.state.suite
    ) {
      let body = {
        location: this.state.suite_address,
        suite_number: this.state.suite_number,
        suite_name: this.state.suite.trim(),
        suite_type: this.state.suite_type,
        square_feet: +this.state.suite_size,
        bedrooms: +this.state.bed_count,
        balcony: this.state.balcony,
        bathrooms: +this.state.bath_count,
        bunk_bed: this.state.bunk_bed,
        unit_owner_id: owner.id,

        fob_id: this.state.fob_id,
        notes: this.state.notes,

        sofa_bed: this.state.sofa_bed,
        building_id: building.id,

        type: (this.state.managed_model)? "Managed" : "Rental",

        active: true,

        bed_configuration: this.state.bed_config,
        isQuadable: this.state.isQuadable,
        quad_status: this.state.isQuadable ? this.state.quad_status : false, 
        super_quad: this.state.super_quad,
        super_quad_bunk: this.state.super_quad_bunk,
        super_quad_notes: this.state.super_quad_notes,
      };

      let a = await fetchRequestJSON("POST", Constants.API_PATH_UNIT_CREATE, body);
      console.log(a);
      let update = store.getState().unitReducer.listUpdate;
      update();
      NotificationHandler("Success", `Successfully Created ${body.suite_name}`, "");
    } else {
      let missing = [];
      if (!owner) missing.push("owner email");
      if (!building) missing.push("building name");
      if (!this.state.suite_address) missing.push("address");
      if (!this.state.suite_number) missing.push("suite number");
      if (!this.state.suite_type) missing.push("suite type");
      if (!this.state.suite_size) missing.push("square feet");
      if (!this.state.bed_count) missing.push("bedrooms");
      if (!this.state.bath_count) missing.push("bathroom");
      if (!this.state.suite) missing.push("suite name");
      if (!this.state.bed_config) missing.push("bedding configuration");
      let message = "Please fill out the following fields: ";
      let first = true;
      for (let i = 0; i < missing.length; i++) {
        if (!first) { message += ", "}
        if (i == missing.length - 1 && !first) {message += "and "}
        message += missing[i];
        first = false;
      }
      NotificationHandler(
        "Warning",
        `Unit Creation Unsuccesful`,
        message
        //"Please fill out the following fields: owner email, building name, address, suite name, suite number, suite type, square feet, bedrooms, bathrooms, and balcony"
      );
    }
  };

  createSelectOwners() {
    let owners = [];
    // console.log(this.state.unit_list);
    for (let i = 0; i < this.state.owner_list.length; i++) {
      owners.push(
        <option key={i} value={this.state.owner_list[i]}>
          {this.state.owner_list[i]}
        </option>
      );
    }
    return owners;
  }

  createSelectBuildings() {
    let buildings = [];
    // console.log(this.state.unit_list);
    for (let i = 0; i < this.state.building_list.length; i++) {
      buildings.push(
        <option key={i} value={this.state.building_list[i]}>
          {this.state.building_list[i]}
        </option>
      );
    }
    return buildings;
  }

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "New Unit";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry: any = [];

    if (this.state.managed_model !== true) {
      ManagedModel = "text-gray-300 ";
      RentalModel = "font-bold text-gray-600";
    } else {
      ManagedModel = "font-medium text-gray-600 ";
      RentalModel = "text-gray-300 ";
    }

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <b className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </b>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">
            <b>Address*</b>
          </span>
          <input
            id="suite_address"
            onChange={this.handleInputChange}
            value={this.state.suite_address}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">
            <b>Suite Number*</b>
          </span>
          <input
            id="suite_number"
            onChange={this.handleInputChange}
            value={this.state.suite_number}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">
            <b>Suite Name*</b>
          </span>
          <input
            id="suite"
            onChange={this.handleInputChange}
            value={this.state.suite}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">
            <b>Building*</b>
          </span>
          <select
            id="suite_building"
            onChange={this.handleInputChange}
            value={this.state.suite_building}
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="Choose"
          >
            <option selected disabled hidden></option>
            {this.state.buildings.map((name) => 
              <option value={name}>{name}</option>
            )}
          </select>
        </label>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">
            <b>Suite Type*</b>
          </span>
          <select
            id="suite_type"
            onChange={this.handleInputChange}
            value={this.state.suite_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Studio</option>
            <option>1 Bedroom</option>
            <option>Junior 2 Bedroom</option>
            <option>2 Bedroom</option>
            <option>3 Bedroom</option>
            <option>Penthouse</option>
          </select>
        </div>

        <div className=" pl-3 flex flex-row w-full justify-between">
          <label className=" mb-5 w-1/4 ">
            <span className="text-gray-400 text-xs">
              {" "}
              <b>Square Feet*</b>
            </span>
            <input
              id="suite_size"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.suite_size}
              type="number"
              className="mt-0 py-1  w-full   px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <label className="  mb-5 w-1/4">
            <span className="text-gray-400 text-xs">
              <b>Bedrooms*</b>
            </span>
            <input
              id="bed_count"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.bed_count}
              type="number"
              className="mt-0 py-1  w-full  px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <label className="  mb-5 w-1/4">
            <span className="text-gray-400 text-xs">
              <b>Bathrooms*</b>
            </span>
            <input
              id="bath_count"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.bath_count}
              type="number"
              className="mt-0 py-1  w-full  px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>
        </div>

        <label className="block pl-3 mb-5">
          <b className="text-gray-400 text-xs">Bedroom Configuration*</b>
          <input
            id="bed_config"
            onChange={this.handleInputChange}
            value={this.state.bed_config}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          Rental / Managed
        </span>

        <div className=" flex flex-row pl-3 my-5 ">
          <span
            className={
              RentalModel + " transition-all duration-300 text-sm pr-3 "
            }
          >
            Rental Model
          </span>
          <ToggleSwitch
            id="managed_model"
            handleStateToggle={this.handleToggleChange}
            size="lg"
            onoff={this.state.managed_model}
          />
          <span
            className={
              ManagedModel + "transition-all duration-300 text-sm pl-3 "
            }
          >
            Managed
          </span>
        </div>

        <div className=" flex flex-row pl-3 my-5 ">
          <span
            className={
              "font-bold transition-all duration-300 text-sm pr-3 "
            }
          >
            Is this Unit Quadable?
          </span>
          <ToggleSwitch
            id="isQuadable"
            handleStateToggle={this.handleToggleChange}
            size="lg"
            onoff={this.state.isQuadable}
          />
        </div>
        {
          this.state.isQuadable &&
          <>
            <div className=" flex flex-row pl-3 mb-3">
              <span
                className={
                  "transition-all duration-300 text-sm pr-3 "
                }
              >
                Is the Unit Quaded (Quad Status)?
              </span>
              <ToggleSwitch
                id="quad_status"
                handleStateToggle={this.handleToggleChange}
                size="lg"
                onoff={this.state.quad_status}
              />
            </div>
            <div className=" flex flex-row pl-3 mb-3">
              <span
                  className={
                    "transition-all duration-300 text-sm pr-3 "
                  }
                >
                  Is this Unit a Super Quad?
                </span>
                <ToggleSwitch
                  id="super_quad"
                  handleStateToggle={this.handleToggleChange}
                  size="lg"
                  onoff={this.state.super_quad}
                />
            </div>
            <div className=" flex flex-row pl-3 mb-3">
              <span
                  className={
                    "transition-all duration-300 text-sm pr-3 "
                  }
                >
                  Is this Unit a Super Quad Bunk?
                </span>
                <ToggleSwitch
                  id="super_quad_bunk"
                  handleStateToggle={this.handleToggleChange}
                  size="lg"
                  onoff={this.state.super_quad_bunk}
                />
            </div>
            <label className="block pl-3 mb-5 mt-2">
              <b className="text-gray-400 text-xs">Super Quad Notes</b>
              <textarea
                id="super_quad_notes"
                onChange={this.handleInputChange}
                value={this.state.super_quad_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>
          </>
        }

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">
            <b>Owner Email*</b>
          </span>
          <select
            id="owner_email"
            onChange={this.handleInputChange}
            value={this.state.owner_email}
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          >
            <option selected disabled hidden></option>
            {this.state.owners.map((email) => 
              <option value={email}>{email}</option>
            )}
          </select>
        </label>

        {/* <label className="block pl-3 mb-5">
          <b className="text-gray-400 text-xs">Management Start Date</b>
          <input
            id="management_start_date"
            onChange={this.handleInputChange}
            value={this.state.management_start_date}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <b className="text-gray-400 text-xs">Rent Due</b>

          <input
            id="rent_due"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            min="0.01"
            step="any"
            value={this.state.rent_due}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        {modelEntry}

        {/* <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          Service Providers
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Internet Provider</span>
          <input
            id="isp"
            onChange={this.handleInputChange}
            value={this.state.isp}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Internet Account #</span>
          <input
            id="isp_account"
            onChange={this.handleInputChange}
            value={this.state.isp_account}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Login</span>
          <input
            id="login"
            onChange={this.handleInputChange}
            value={this.state.login}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Password</span>
          <input
            id="password"
            onChange={this.handleInputChange}
            value={this.state.password}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">TV Provider</span>
          <input
            id="tvp"
            onChange={this.handleInputChange}
            value={this.state.tvp}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">TV Account #</span>
          <input
            id="tv_account"
            onChange={this.handleInputChange}
            value={this.state.tv_account}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}
{/* 
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Hydro Provider</span>
          <input
            id="hydro_provider"
            onChange={this.handleInputChange}
            value={this.state.hydro_provider}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        <div className="ml-3 mb-5 px-3 border rounded-sm border-solid py-4 shadow-sm flex flex-col">
          <div className=" flex flex-row mb-5">
            <div className={" block mr-10 w-32  "}>
              <div className="flex flex-row items-center justify-between mb-3">
                <b
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Balcony
                </b>

                <ToggleSwitch
                  id="balcony"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.balcony}
                />
              </div>

              <div className="flex flex-row items-center justify-between mb-3">
                <b
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Sofa Bed
                </b>

                <ToggleSwitch
                  id="sofa_bed"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.sofa_bed}
                />
              </div>

              <div className="flex flex-row items-center justify-between mb-3">
                <b
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Bunk Bed
                </b>

                <ToggleSwitch
                  id="bunk_bed"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.bunk_bed}
                />
              </div>

            </div>

            {/* <div className={" block w-32  "}>
         <div  className="flex flex-row items-center justify-between ">
         <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>Parking?</span>
         
        <ToggleSwitch id="parking" handleStateToggle={this.handleToggleChange} className="justify-center align-center" size="lg" onoff={this.state.parking} />
          
         </div>
         </div> */}
          </div>

          {/* 
         <div className=" flex flex-row ">
         <label className={" block w-32  "}>
         <div  className="flex flex-row items-center justify-between ">
         <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>First Rent</span>
        
        <ToggleSwitch id="first_rent" handleStateToggle={this.handleToggleChange} className="justify-center align-center" size="lg" onoff={this.state.first_rent} />
          
         </div>
         </label>
   
         </div> */}
        </div>

        <label className="block pl-3 mb-5 mt-2">
          <b className="text-gray-400 text-xs">Additional Notes</b>
          <textarea
            id="notes"
            onChange={this.handleInputChange}
            value={this.state.notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.state.suite}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => this.handleClick()}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewUnit);

export function inputISO(date: Date) {
  let year = date.getFullYear().toString();
  let month = date.getMonth();
  let dt = date.getDate();
  let day = "";
  let month2 = "";

  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt.toString();
  }
  if (month < 10) {
    month2 = "0" + month;
  } else {
    month2 = month.toString();
  }

  return year + "-" + month2 + "-" + day;
}
