import { Component } from "react";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { LogOut } from "../logout/LogoutButton";
import { GetData } from "../api-requests/api-gateway";

interface Props {
  SettingsToggle: any;
  onoff: boolean;
}

interface GlobalSettingsState {
  onoff: boolean;
  corporate: boolean;
  booking_id: number;
  corporate_id: number;
  corporate_name: string;
  tenant_name: string;
  check_in: Date;
  check_out: Date;
  suite: string;
  editToggle: boolean;
}

class GlobalSettings extends Component<Props, GlobalSettingsState> {
  constructor(props: any) {
    super(props);

    this.state = {
      onoff: this.props.onoff,
      corporate: true,
      booking_id: -1,
      corporate_id: -1,
      corporate_name: "",
      tenant_name: "",
      check_in: new Date(),
      check_out: new Date(),
      suite: "",
      editToggle: false,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.SettingsToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={"fade-in fixed w-full h-full bg-gray-600 bg-opacity-0 z-40 "}
      >
        <div className="  pullUp absolute font-sofia bg-white shadow-xl rounded-lg h-64 w-full max-w-sm mt-auto bottom-0 mb-14 ml-6 py-4 px-4  ">
          <div className="flex flex-row justify-between mb-4">
            <span className="font-display text-3xl text-gray-700 font-bold">
              Settings
            </span>

            <Link
              to={`/`}
              className={
                "text-gray-700 hover:text-gray-400 flex flex-row items-center ml-2  "
              }
              onClick={() => LogOut()}
            >
              <RiLogoutBoxLine className="mr-1" />
              <span className="mb-1">Logout</span>
            </Link>
          </div>
          <div className="flex flex-col">
            <a 
              className="mb-2 text-gray-600"
              target="_blank"
              href="https://form.asana.com/?k=0aIe-63w0DhgfiPq8jnzjg&d=512476923931765"
            >
              Report a bug
            </a>
            <a 
              className="mb-2 text-gray-600"
              target="_blank"
              href="https://form.asana.com/?hash=4c206c96f3f88b1bd839b4300180cea6a75ea7267b0b178fb8c389ff196386e1&id=1157667343383271"
            >
              Request a feature
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalSettings;
