import { Component } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { IoIosArrowBack } from "react-icons/io";
import { FaBuilding } from "react-icons/fa";
import ToggleSwitch from "../ToggleSwitch";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
};

type ModalState = {
  title: string;
  onoff: boolean;
  building_name: string;
  address: string;
  min_stay: number;
  min_type: string;
  heat: boolean;
  concierge_number: string;
  PM_number: string;
  building_notes: string;
  special_instructions: string;
  amenities: string;
  district: string;
  units: Array<string>;
  suite_add: string;
};

class NewBuilding extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      building_name: "",
      address: "",
      min_stay: 0,
      min_type: "",
      heat: false,
      concierge_number: "",
      PM_number: "",
      building_notes: "",
      special_instructions: "",
      amenities: "",
      district: "",
      suite_add: "",
      units: [],
    };
  }

  // Create building button functionality
  handleClick = async (event: any) => {
    event.preventDefault();
    let NotificationHandler = this.props.ErrorNote;
    const buildingCreateBody = {
      building_name: this.state.building_name,
      address: this.state.address,
      svs_notes: this.state.building_notes,
      heat: this.state.heat,
      minimum_stay: this.state.min_stay,
      concierge_number: this.state.concierge_number,
      property_manager_number: this.state.PM_number,
      special_instructions: this.state.special_instructions,
      amenities: this.state.amenities,
      district: this.state.district,
    };

    // console.log(buildingCreate)

    if (
      buildingCreateBody.building_name &&
      buildingCreateBody.address &&
      buildingCreateBody.concierge_number &&
      buildingCreateBody.property_manager_number &&
      buildingCreateBody.district
    ) {
      this.props.handleModalToggle();
      this.updatePage(buildingCreateBody);
      NotificationHandler("Success", `Succesfully create ${buildingCreateBody.building_name}`, "")
    } else {
      let missing = [];
      if (!buildingCreateBody.building_name) missing.push("building name");
      if (!buildingCreateBody.address) missing.push("address");
      if (!buildingCreateBody.concierge_number) missing.push("concierge number");
      if (!buildingCreateBody.property_manager_number) missing.push("property manager number");
      if (!buildingCreateBody.district) missing.push("district");
      let message = "Please fill out the following fields: ";
      let first = true;
      for (let i = 0; i < missing.length; i++) {
        if (!first) { message += ", "}
        if (i == missing.length - 1 && !first) {message += "and "}
        message += missing[i];
        first = false;
      }
      NotificationHandler(
        "Warning",
        "Required Fields Missing",
        message
        //"Please fill out the following fields: building name, address, concierge number, heat, property manager number, and district "
      ); //Types : SUCCESS, ERROR, INFO, WARNING
    }
  };

  updatePage = async (buildingCreateBody: any) => {
    const createBuildingStatus = await this.createBuilding(buildingCreateBody);
    if (createBuildingStatus.status == 'Success') {
      let update = store.getState().buildingReducer.listUpdate;
      update();
    }
  };

  createBuilding = async (buildingCreateBody: any) => {
    return await fetchRequestJSON(
      "POST",
      Constants.API_PATH_BUILDING_CREATE,
      buildingCreateBody
    );
  };

  addSuite = (event: any) => {
    event.preventDefault();
    let suites = this.state.units;
    let newSuites = suites.push(this.state.suite_add);

    // this.setState({units : newSuites})
    this.setState({ suite_add: "" });
  };

  removeSuite = (i: number) => {
    // event.preventDefault();

    let arSuites = this.state.units;
    arSuites.splice(i, 1);
    this.setState({ units: arSuites });
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    // let inactive = "";

    // if (this.state.building === ''){
    //   inactive = "opacity-50 pointer-events-none "
    // }

    const unitList = this.state.units.map((unit, index) => (
      <div className="fade-in flex flex-row bg-gray-200 pl-4 pr-2 py-1 mr-3 rounded-full items-center">
        <span className="text-gray-600 text-sm mr-4">{unit}</span>{" "}
        <TiDelete
          onClick={() => this.removeSuite(index)}
          className="text-gray-500 text-lg hover:text-red-400 cursor-pointer"
        />
      </div>
    ));

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        {/* <IoIosArrowBack className="text-blue-500 text-3xl absolute transform -translate-x-48 z-50  " /> */}
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4 overflow-y-scroll  scroll-hide my-20  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <FaBuilding className="text-gray-600 text-3xl mb-3 fixed" />

          <span className="text-xl font-bold text-gray-800 text-left mt-12">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form>
            <div className="grid grid-cols-3 gap-6  w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Building Name:</span>
                <input
                  id="building_name"
                  onChange={this.handleInputChange}
                  value={this.state.building_name}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="eg. Adelaide"
                />
              </label>

              <label className="block col-span-2 ">
                <span className="text-gray-400 text-xs">Address:</span>
                <input
                  id="address"
                  onChange={this.handleInputChange}
                  value={this.state.address}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <div className="grid grid-cols-4 gap-6  w-full my-5">
              {/* <label className="block ">
                <span className="text-gray-400 text-xs">Minimum Stay</span>
                <input
                  id="min_stay"
                  onChange={this.handleInputChange}
                  value={this.state.min_stay}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="eg. smiles.davis@gmail.com"
                />
                <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span>
              </label>

              <div className="block   ">
                <span className="text-gray-400 text-xs">Suite Type</span>
                <select
                  id="min_type"
                  onChange={this.handleInputChange}
                  value={this.state.min_type}
                  className="  block w-full mt-0 py-1 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                >
                  <option>Days</option>
                  <option>Weeks</option>
                  <option>Months</option>
                  <option>Year(s)</option>
                  <option>None</option>
                </select>
              </div> */}
            </div>

            <div className={" block  w-36 my-5 col-start-4"}>
              <div className="flex flex-row items-center justify-between ">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Heat Building
                </span>

                <ToggleSwitch
                  id="heat"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.heat}
                />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-6  w-full my-5">
              <label className="block col-span-2 ">
                <span className="text-gray-400 text-xs">Concierge Number:</span>
                <input
                  id="concierge_number"
                  onChange={this.handleInputChange}
                  value={this.state.concierge_number}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="###"
                />
              </label>

              <label className="block col-span-2 ">
                <span className="text-gray-400 text-xs">
                  Property Managment Number:
                </span>
                <input
                  id="PM_number"
                  onChange={this.handleInputChange}
                  value={this.state.PM_number}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="###"
                />
              </label>
            </div>

            <label className="block ">
              <span className="text-gray-400 text-xs">District</span>
              <input
                id="district"
                onChange={this.handleInputChange}
                value={this.state.district}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder="--"
              />
            </label>

            <label className="block  mb-5 mt-2">
              <span className="text-gray-400 text-xs">Building Notes</span>
              <textarea
                id="building_notes"
                onChange={this.handleInputChange}
                value={this.state.building_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>

            <label className="block  mb-5 mt-2">
              <span className="text-gray-400 text-xs">
                Special Instructions
              </span>
              <textarea
                id="special_instructions"
                onChange={this.handleInputChange}
                value={this.state.special_instructions}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>

            <label className="block  mb-5 mt-2">
              <span className="text-gray-400 text-xs">Amenities</span>
              <textarea
                id="amenities"
                onChange={this.handleInputChange}
                value={this.state.amenities}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>

            {/* <div className="flex flex-row items-center">
              <div className="block  ">
                <span className="text-gray-400 text-xs">Suites</span>
                <input
                  id="suite_add"
                  onChange={this.handleInputChange}
                  value={this.state.suite_add}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
                <span className="text-2xs pt-1 text-gray-300 ">
                  Auto Searches Building
                </span>
              </div>

              <button
                onClick={this.addSuite}
                className="transition  duration-200 w-32 h-10 hover:bg-green-400  py-1 bg-gray-500  text-sm rounded-md text-white  shadow-sm mx-5 "
              >
                Add Building
              </button>
            </div> */}

            <div className="flex flex-row items-center mt-4">{unitList}</div>

            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={this.handleClick}
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Add Building
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewBuilding);
