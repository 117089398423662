import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import Modal from "../Modal";

type Props = {
  toggle: any;
  toggleEdit: any;
  ErrorNote: any;
};

type EditTenantState = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  allTenants: any[];
  deleteModalToggle: boolean;
};

class EditTenant extends Component<Props, EditTenantState> {
  constructor(props: any) {
    super(props);

    this.state = {
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      allTenants: [],
      deleteModalToggle: false,
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let returnVal = this.target.value;
      returnObj[this.target.id] = returnVal;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // Fetch all info
  componentDidMount = async () => {
    store.dispatch({ type: "updateEditTenant", editUpdate: this.fetchAll });
    this.fetchAll();
  };

  fetchAll = async () => {
    const allTenants = await fetchGetJSON(
      Constants.API_PATH_TENANT_GET_ALL
    );
    this.setState({ allTenants: allTenants.data });
  }

  componentDidUpdate = async (previousProps: any) => {
    const curId = store.getState().tenantReducer.curId;
    //console.log(curId)
    if (curId != -1 && previousProps !== this.props && this.state.allTenants.length != 0) {
      const tenantData = this.state.allTenants.find(
        (tenant) => tenant.id == curId);
      if (!tenantData) { return; }
      this.setState({
        id: tenantData.id,
        first_name: tenantData.first_name,
        last_name: tenantData.last_name,
        email: tenantData.email,
        phone_number: tenantData.phone_number,
      })
    }
  }

  handleClick = async () => {
    const NotificationHandler = this.props.ErrorNote;
    const tenantEditBody = {
      id: this.state.id,
      fields: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_number: this.state.phone_number
      }
    }
    console.log(tenantEditBody);
    let res = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_TENANT_EDIT,
      tenantEditBody
    );
    if (res.status !== "Success") {
      NotificationHandler(res.status, res.status, res.data);
      console.log(res);
    } else {
      //window.location.reload(); 
      let update = store.getState().tenantReducer.updateList;
      update();
      this.props.toggleEdit("editTenant", true);
    }
    this.fetchAll();
  };

  handleDeleteModal = () => {
    this.setState({ deleteModalToggle: !this.state.deleteModalToggle })
  }

  handleDelete = async () => {
    let NotificationHandler = this.props.ErrorNote;
    let res = await fetchRequestJSON("DELETE", `${Constants.API_PATH_TENANT_CANCEL}/${this.state.id}`,)
    if (res.status) {
      let update = store.getState().tenantReducer.updateList;
      update();
      if (res.status !== "Success") {
        NotificationHandler("Warning", res.status);
      }
      else {
        this.props.toggleEdit("editTenant", true);
        NotificationHandler("Success", 'Successfully Deleted Tenant')
      }
    }
  }

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {

    let deleteTenantModal = <></>;
    let deleteTenantMessage =
      <span>
        Would you like to delete tenant:
        <span className="font-bold"> {this.state.first_name} {this.state.last_name}</span> ?
      </span>

    if (this.state.deleteModalToggle) {
      deleteTenantModal = (
        <Modal
          title="Delete Tenant"
          content={deleteTenantMessage}
          onoff={this.state.deleteModalToggle}
          handleModalToggle={this.handleDeleteModal}
          confirmButton={this.handleDelete}
        ></Modal>
      );
    }

    if (!this.props.toggle) { return null; }
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Tenant";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let content;

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form
        className="  flex flex-col pl-6 pr-12 py-4"

      >
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">First Name</span>
          <input
            id="first_name"
            onChange={this.handleInputChange}
            value={this.state.first_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Last Name</span>
          <input
            id="last_name"
            onChange={this.handleInputChange}
            value={this.state.last_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Email</span>
          <input
            id="email"
            onChange={this.handleInputChange}
            value={this.state.email}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Phone Number</span>
          <input
            id="phone_number"
            onChange={this.handleInputChange}
            value={this.state.phone_number}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>
      </form>
    );

    return (
      <>
        <div
          id="outsideDiv"
          onClick={() => this.props.toggleEdit("editTenant", true)}
          className={
            "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
          }
        >
        </div>
        {deleteTenantModal}
        <div
          className={
            editclassName +
            " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
          }
        >
          {/* Top Title Bar */}
          <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
            <button
              onClick={() => this.props.toggleEdit("editTenant", true)}
              className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
            >
              <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
            </button>

            <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
              <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
            </button>
          </div>
          <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
            <span className=" font-black text-xl text-gray-700">
              {ProfileType}{" "}
            </span>
            <span className="italic font-sofia text-gray-400 text-lg font-thin">
              {this.state.id}
            </span>
          </div>

          {content}

          {/* Submit Logic Below */}
          <div className="h-24 flex flex-row ">
            <button
              type="button"
              onClick={this.handleClick}
              className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
            >
              Submit
            </button>

            <button
              type="button"
              onClick={this.handleDeleteModal}
              className="transition duration-200 w-48 hover:bg-red-400 px-10 py-2 bg-red-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
            >
              DELETE
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withHooksHOC(EditTenant);
