import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../BreadCrumb";
import TabList from "../../../TabList";
import EditOptionPanel from "../../../EditOptionPanel";
import UnitInfo from "../../../Units/ProfileComponents/UnitInfo";
import Label from "../../../LabelWrap";
import ViewSheet from "../../../Units/InfoSheetComponents/ViewSheet";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider";
import store from "../../../../redux/store";
import CancelModal from "../../../Bookings/CancelModal";
import ParkingSpotBuildingInfo from "./ParkingSpotBuildingInfo";
import ParkingSpotEditModal from "../Update/ParkingSpotEditModal";
import ParkingSpotOwnerTab from "./ParkingSpotOwnerTab";
import ParkingProfileCard from "./ParkingProfileCard";

type Props = {
    history: any;
    location: any;
};

//Dayjs Logic, implement this as a helper function when we refactor
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)


//This component will grab the id from the url
const ParkingSpotProfile = (props:Props) => {
    const [parkingSpot, setParkingSpot] = useState(null) //Need to go add a type for this
    const [editToggle, setEditToggle] = useState(false);
    const [deleteToggle, setDeleteToggle] = useState(false);

    useEffect(() => {
        loadData();

        //Store Load Data in reducer:
        store.dispatch({
            type: "parkingSpotProfileUpdate",
            profileUpdate: loadData,
        });
    }, [])

    //Notification Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    const loadData = async () => {
        let parkingSpotID = new URLSearchParams(window.location.search).get("id");

        //Grab full information of parking spot
        const parkingSpotFullInfo = await fetchGetJSON(`${Constants.API_PATH_PARKING_SPOT_GET_FULL_INFO}${parkingSpotID}`)
        if(parkingSpotFullInfo.status == "Success") {
            setParkingSpot(parkingSpotFullInfo.data)
            //NotificationHandler('Success', 'Success', `Parking Spot Information Succesfully Retrived`)
        } else {
            NotificationHandler('Error', 'Error', `Parking Spot Information Retrieval was unsuccessful. Please check that the parking spot ID is correct.`)
        }
    }

    const handleEditBarToggle = () => {
        if(parkingSpot?.parkingSpot.active) {
            const toggle = editToggle;
            if (toggle === true) {
                setEditToggle(false)
            } else {
                setEditToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking spot is deactivated. No edits can be made.')
        }
    }

    const handleCancelToggle = () => {
        if(parkingSpot?.parkingSpot.active) {
            const toggle = deleteToggle
            if (toggle === true) {
                setDeleteToggle(false)
            } else {
                setDeleteToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking spot is deactivated. No edits can be made.')
        }
    }

    return (
        <div>
            <div className=" bg-white font-display lg:rounded-tl-lg  ">
                <CancelModal
                    handleModalToggle={() =>
                        handleCancelToggle()
                    }
                    onoff={deleteToggle}
                    title={"Delete Parking Spot"}
                />
                {
                    parkingSpot?.parkingSpot &&
                    <ParkingSpotEditModal
                        toggle={editToggle}
                        handleEditBarToggle={handleEditBarToggle}
                        parkingSpotInfo={parkingSpot.parkingSpot}
                    />
                } 
                {
                    editToggle &&
                    <div
                        onClick={handleEditBarToggle}
                        className=" fade-bg w-full h-screen bg-black z-40  fixed"
                    ></div>
                }
                <BreadCrumb
                    rootPage={"Parking > Parking Spot Profile"}
                    subPage={parkingSpot?.parkingSpot.parking_spot_location}
                    sideButton=""
                    buttonAction={""}
                />
                <div className="  px-4 pb-20 lg:pb-10 lg:px-10 max-w-screen-xl mx-auto border rounded-lg pt-14 lg:pt-10 bg-white">
                    {/* Heading Info Area  */}
                    <div className="flex flex-col lg:flex-row mb-0">
                        {/* Left Side  */}
                        <div className="flex flex-col w-1/2">
                            <h1 className="text-2xl px-2 pt-2 text-gray-700 font-black font-display ">
                                {parkingSpot?.parkingSpot.parking_spot_location}
                            </h1>

                            <h2 className="px-2 -mt-0 text-md font-extralight text-gray-500 font-europe">
                                Location: {parkingSpot?.buildingInfo.building_name} | {parkingSpot?.buildingInfo.address}  
                            </h2>
                            <h2 className="px-2 pt-0 text-sm font-extralight text-gray-400 font-europe">
                                Parking Level: {parkingSpot?.parkingSpot.parking_spot_level}
                            </h2>
                            <div className=" slide-in-blurred-top font-display flex flex-col border py-4 px-5 mt-5">
                            <span className="text-m mb-3">Parking Notes:</span>
                            <span className="text-indigo-500 text-sm">
                                {parkingSpot?.parkingSpot.parking_spot_notes}
                            </span>
                            {
                                parkingSpot?.parkingSpot.deactivation_date &&
                                <span className="text-red-500 text-sm mt-3">
                                    Parking Spot Deactivated on {dayjs(parkingSpot?.parkingSpot.deactivation_date).format('LL')}
                                </span>
                            }
                            </div>
                        </div> 


                        {/* Right Side */}
                        <div className="flex flex-col lg:flex-row w-full lg:w-1/2 items-start justify-end">
                        {
                            !parkingSpot?.parkingSpot?.active &&
                            <div className="flex flex-row mb-4">
                            {/* Placeholder for another tag if we want to put it next to the tag below: */}
                                <div
                                className=" cursor-default h-6 mt-5 mr-2 bg-red-200 text-red-500  text-s rounded-full flex flex-row items-center text-center px-5"
                                >
                                    {" "}
                                    {"DEACTIVATED Parking Spot"}{""}
                                </div>
                            </div>
                        }
                        
                        <EditOptionPanel
                            handleEditBarToggle={handleEditBarToggle}
                            parentData={""}
                            panelType="Parking Spot"
                            handleFlag={""}
                            handleState={handleCancelToggle}
                        />
                        </div>
                    </div>


                    <TabList warning={false}>
                        <Label label="Building Info"> 
                            {
                                parkingSpot?.buildingInfo &&
                                <ParkingSpotBuildingInfo buildingInfo={parkingSpot?.buildingInfo}/>
                            }

                        </Label>
                        <Label label="Owner Info">
                            <ParkingSpotOwnerTab
                                ownerInfo={parkingSpot?.ownerInfo}
                            />
                        </Label>
                        <Label label="Unit Info">
                            {/* <ViewSheet id={this.state.unit_id} url={this.props}/> */}
                            {
                                parkingSpot?.unitInfo ?  
                                    <UnitInfo UnitData={parkingSpot?.unitInfo} /> :
                                    <div className="pt-2">
                                            <div className=" slide-in-blurred-top font-display border flex flex-row px-4 py-4 mb-4">
                                                <div className="w-full flex justify-center">
                                                    This parking spot does not belong to a core unit. Please refer to the building information for more details on the parking spot.
                                                </div>
                                            </div>
                                    </div>
                            }
                        </Label>     
                        <Label label="Parkings">
                            <ParkingProfileCard
                                bookingView={false}
                            />
                        </Label>
                    </TabList>
                </div>
            </div>
        </div>
    )
}

export default ParkingSpotProfile