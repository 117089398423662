export type State = {
  curCleaning: number;
  updateCalendar: any;
}

const initialState: State = {
  curCleaning: -1,
  updateCalendar: undefined,
}

type Action =
  | {type: "setCurCleaning"; id: number}
  | {type: "setUpdateCalendar"; function: any};

export const cleaningReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "setCurCleaning":
      state.curCleaning = action.id;
      return state;
    case "setUpdateCalendar":
      state.curCleaning = action.function;
      return state;
    default:
      return state;
  }
}

export default cleaningReducer;