export type State = {
  invoices: any[];
  curId: number;
  listUpdate: any;
  quickViewUpdate: any;
  update: any;
  ARUpdate: any;
};

const initialState: State = {
  invoices: [],
  curId: -1,
  listUpdate: undefined,
  quickViewUpdate: undefined,
  update: undefined,
  ARUpdate: undefined,
};

type Action =
  | { type: "addInvoice"; newInvoice: { id: number } }
  | { type: "removeInvoice"; id: number }
  | { type: "invoiceIdTransfer"; id: number }
  | { type: "invoiceListUpdate"; listUpdate: any }
  | { type: "invoiceQuickViewUpdate"; quickViewUpdate: any }
  | { type: "updateInvoiceList"; update: any }
  | { type: "updateARList"; ARUpdate: any};

export const invoiceReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "addInvoice":
      state.invoices.push(action.newInvoice);
      return state;
    case "invoiceIdTransfer":
      state.curId = action.id;
      return state;
    case "invoiceListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    case "invoiceQuickViewUpdate":
      state.quickViewUpdate = action.quickViewUpdate;
      return state;
    case "updateInvoiceList":
      state.update = action.update;
      return state;
    case "updateARList":
      state.ARUpdate = action.ARUpdate;
      return state;
    default:
      return state;
  }
};

export default invoiceReducer;
