import React, { Component } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { regRequest, fetchRequestJSON, fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { formatFullDate } from "../../utils/DateFormat";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  checkinData: any;
  checkIn: any;
  ErrorNote: any;
  confirmCheckInModal: any;
};

type State = {
  booking_id: string;  // TODO: Consider making this attribute type number.
  check_in: Date;
  check_out: Date;
  corporate_id: string;  // TODO: Consider making this attribute type number.
  tenant_name: string;
  editToggle: boolean;
  key_count: number;
  fob_count: number;
  park_fob_count: number;
  mail_key: number;
  outsanding_invoice: number;  /* TODO: Rename this attribute "outstanding_invoices". */
  monthly_rate: number;
  toggleAgreement: boolean;
  suite_name: string;
};

class CheckInProfileCard extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      booking_id: this.props.checkinData.booking_id,
      check_in: this.props.checkinData.check_in,
      check_out: this.props.checkinData.check_out,
      corporate_id: this.props.checkinData.corporate_id,
      tenant_name: this.props.checkinData.tenant_name,
      editToggle: false,
      key_count: this.props.checkinData.keys,
      fob_count: this.props.checkinData.fobs,
      park_fob_count: this.props.checkinData.parking_fob,
      mail_key: this.props.checkinData.mail_keys,
      outsanding_invoice: 0,
      monthly_rate: this.props.checkinData.monthly_rate,
      toggleAgreement: false,
      suite_name: this.props.checkinData.unit_name,
    }
  }

  toCheckInForm = async (event: any) => {
    event.preventDefault();
    const { history } = this.props;
    let booking_id = new URLSearchParams(window.location.search).get("id")
    if (history) {
      history.push({
        pathname: "/User/Check Ins/BookingId",
        search: `id=${booking_id}`
      })
    }
  };

  toUnitInfoAndKeysPage = async (event: any) => {
    event.preventDefault();
    const { history } = this.props;
    let booking_id = new URLSearchParams(window.location.search).get("id")
    if (history) {
      history.push({
        pathname: "/User/Check Ins/BookingId/unit info & keys",
        search: `id=${booking_id}`
      })
    }
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    console.log(event.target.value)
    this.setState({
      check_in: event.target.value,
    })
  };


  handleChangeCheckIn = async (isEarlyCheckin: boolean) => {
    let NotificationHandler = this.props.ErrorNote

    if (this.props.checkinData.is_check_in_changed) {
      NotificationHandler("Warning", "Please refresh your screen to see the new check in change.")
    }
    else if (typeof (this.state.check_in) !== "string" || this.state.check_in == "") {
      NotificationHandler("Error", "No date entered to change check in.")
    }
    else if (this.state.check_in == this.props.checkinData.check_in) {
      NotificationHandler("Warning", "New check in date is the same as the current check in date.")
    }
    else if (new Date(this.state.check_in) < new Date(this.props.checkinData.check_in) && !isEarlyCheckin) {
      NotificationHandler("Warning", "Late check in date is earlier than the current check in date.")
    } else if (new Date(this.state.check_in) > new Date(this.props.checkinData.check_in) && isEarlyCheckin) {
      NotificationHandler("Warning", "Early check in date is later than the current check in date.")
    }
    else {
      console.log("state", this.props.confirmCheckInModal(this.state.check_in, isEarlyCheckin));
    }
  }

  keyCafeUpdate = async() => {
    let NotificationHandler = this.props.ErrorNote;
    const booking_id = this.props.checkinData.booking_id;
    const suiteName = this.props.checkinData.unit_name

    NotificationHandler(
      "Processing",
      `Updating KeyCode`,
      "Please wait while we update this booking's keycode to KeyCafe",
    );

    const forceKeyCafe = await fetchRequestJSON("PUT",
      Constants.API_PATH_FORCE_KEYCAFE, {
      bookingId: booking_id,
      suite_name: suiteName
    })

    if(forceKeyCafe?.status == "Success") {
     NotificationHandler("Success",
      `Updated KeyCode`,
       `${forceKeyCafe.data}`,)
    } else {
      NotificationHandler("Error",
      `Unsuccesful KeyCode Update`,
       `${forceKeyCafe?.status}`,)
    }
  }

  sendBookingConfirmation = async() => {
    let NotificationHandler = this.props.ErrorNote;
    const booking_id = this.props.checkinData.booking_id;

    const sendBookingConfirmation = await fetchGetJSON(`${Constants.API_PATH_SEND_BOOKING_CONFIRMATIONFORM}${booking_id}`)
  
    if(sendBookingConfirmation?.status == "Success") {
      console.log(sendBookingConfirmation.data.length)

     NotificationHandler("Success",
      `Success`,
      `Booking Confirmation Email sent to ${Array.isArray(sendBookingConfirmation.data) ? `${sendBookingConfirmation?.data[0]} and ${sendBookingConfirmation?.data[1]}` : sendBookingConfirmation.data}`
      )
    } else {
      NotificationHandler("Error",
      `Unsuccesful in Sending Booking Confirmation`,
       `${sendBookingConfirmation?.status}`,)
    }
  }

  render() {
    let Tags = [];
    if (this.props.checkinData.confirmed) {
      Tags[0] = (
        <div
          id="confirmed"
          className="mb-2  whitespace-no-wrap h-6 mr-2 bg-gray-300 text-gray-700 font-medium text-xs rounded-full py-1 w-auto flex items-center justify-center  text-center px-4"
          key = {String(0)}
        >
          {" "}
          Confirmed{" "}
        </div>
      );
    }

    if (this.props.checkinData.parking_required) {
      Tags[1] = (
        <div
          id="parkingRequired"
          className="mb-2  whitespace-no-wrap h-6 mr-2 bg-yellow-300 text-yellow-700 font-medium text-xs rounded-full py-1 w-auto flex items-center justify-center  text-center px-4"
          key = {String(1)}
        >
          {" "}
          Parking Required{" "}
        </div>
      );
    }

    if (this.props.checkinData.first_rent_required) {
      Tags[2] = (
        <div
          id="FirstRent"
          className="mb-2 whitespace-no-wrap h-6 mr-2 bg-red-300 text-red-800  font-medium text-xs rounded-full py-1 w-auto flex items-center align-center text-center px-4"
          key = {String(2)}
        >
          {" "}
          First Rent Required{" "}
        </div>
      );
    }

    return (
      <div>
        <div className=" slide-in-blurred-top font-display border px-3 py-4 mt-2 ">
          <div className="flex-wrap flex justify-start align-center items-center flex-col lg:flex-row">
            {/* CheckIn Info */}
            <h2 className=" lg:mr-4 pt-1 inline-block align-middle font-regular text-xl lg:text-base">
              Check In <span className="text-gray-400">|</span>{" "}
              <span className="font-bold">{this.props.checkIn}</span>{" "}
            </h2>

            {/* Tag divs */}
            <div className=" flex flex-row flex-wrap mt-2 justify-center align-center lg:mt-0">
              {Tags}
            </div>

            {/* Unit Info */}
            <div className=" w-auto py-5 flex flex-col lg:ml-auto lg:py-0 ">
              <h3 className="text-gray-500 text-sm text-center lg:text-right font-semibold w-auto  justify-center lg:justify-end ">
                {this.props.checkinData.suite}
              </h3>
              <h3 className="text-gray-500 text-xs text-center lg:text-right font-thin w-auto text-center lg:justify-end ">
                Suite #{this.props.checkinData.suite_number}
              </h3>
            </div>
          </div>

          <div className="flex-wrap flex justify-between mt-2 flex-row">
            <div className="w-auto flex flex-col">
              <h2 className=" text-gray-500 mr-4 pt-1 inline-block align-middle font-hairline text-sm">
                Check In Notes:
              </h2>
              <p className=" max-w-sm text-gray-700 mr-4 pt-1 inline-block align-middle font-regular text-xs">
                <span className="text-red-300">
                  {(this.props.checkinData.check_in_notes) ? this.props.checkinData.check_in_notes : "No Check In Notes"}
                </span>
              </p>
              {/* <div>
                <button
                  onClick={this.keyCafeUpdate}
                  className="transition duration-200 hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded text-white font-bold shadow-sm mr-3 mt-5"
                >
                  KeyCode Update
                </button>
                <button
                  onClick={this.sendBookingConfirmation}
                  className="transition duration-200 border-2 border-green-500 bg-white hover:bg-green-100 px-8 lg:px-10 py-2 rounded text-green-500 font-bold  mr-3"
                >
                  Send Booking Confirmation
                </button>
              </div> */}
            </div>
            {/* <div className="w-auto  flex flex-col justify-end ">
              <button className=" mt-3 lg:mt-0 transition-all duration-200 ease-in-out hover:bg-gray-200  flex flex-row justify-between rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row items-center  text-xs">
                <AiOutlineClockCircle className="text-lg " />
                <span className="ml-2">Check In Time Change</span>
              </button>
            </div> */}
          </div>
        </div>

        {/* <section className="mt-3 mb-3 py-5 border-2 border-gray-100 border-solid">

          <span className="ml-3">
            Early Check In
            <span className="flex flex-row">
              <input
                id="check_in"
                onChange={this.handleDateChange}
                type="date"
                className="mt-0 ml-3 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              />
              <button
                className="ml-6 text-green-500"
                onClick={() => {
                  this.handleChangeCheckIn(true)
                }}
              >
                Confirm
              </button>
            </span>
          </span>
          <br className="spacerY"></br>

          <span className="ml-3">
            Late Check In
            <span className="flex flex-row">
              <input
                id="check_out"
                onChange={this.handleDateChange}
                type="date"
                className="mt-0 ml-3 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              />
              <button
                className="ml-6 text-green-500"
                onClick={() => {
                  this.handleChangeCheckIn(false);
                }}
              >
                Confirm
              </button>
            </span>
          </span>

        </section> */}

        <div className=" scale-in-ver-top mt-5 flex flex-row justify-left ">
          {/* <button
            onClick={this.toCheckInForm}
            className="transition duration-200 hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded text-white font-bold shadow-sm mr-3"
          >
            Check In
          </button>
          <button
            onClick={this.toUnitInfoAndKeysPage}
            className="transition duration-200 border-2 border-green-500 bg-white hover:bg-green-100 px-8 lg:px-10 py-2 rounded text-green-500 font-bold  mr-3"
          >
            Unit Info & Keys
          </button> */}
        </div>
      </div>
    );
  }
}

export default withRouter(withHooksHOC(CheckInProfileCard))