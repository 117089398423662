import { Component } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { RiUser3Fill } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";
import { formatFullDate } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import EditOptionPanel from "../EditOptionPanel";
import EditLead from "./EditLead";
import CancelModal from "../Bookings/CancelModal";
import { DayCellContentProps } from "@fullcalendar/common";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";

// Takes Booking ID Prop and pull all info

interface Props {
  handleModalToggle: any;
  onoff: boolean;
  BookingId: number;
}

interface Tenant {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  id: number;
}

interface QuickViewModalState {
  onoff: boolean;

  lead_id: number;
  contact_name: string;
  lead_email: string;
  lead_phone: number;
  lead_type: string;
  contact_date: Date;
  requested_check_in?: any;
  requested_check_out?: any;
  flexible_dates: boolean;
  corporate: boolean;
  company_name: string;
  suite_type: string;
  purpose_of_stay: string;
  parking_requested: boolean;
  number_of_adults: number;
  number_of_children: number;
  country_of_origin: string;
  how_they_heard: string;
  budget_per_night: number;
  unit_of_interest: string;
  lead_notes: string;

  editToggle: boolean;
  toggleDelete: boolean;
  flagged: boolean;
  tenant: Tenant;

  booked: any;
}

class LeadsQuickView extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      lead_id: -1,
      contact_name: "",
      lead_email: "",
      lead_phone: 0,
      lead_type: "",
      contact_date: new Date(),
      requested_check_in: new Date(),
      requested_check_out: new Date(),
      flexible_dates: true,
      corporate: true,
      company_name: "",
      suite_type: "",
      purpose_of_stay: "",
      parking_requested: false,
      number_of_adults: 0,
      number_of_children: 0,
      country_of_origin: "",
      how_they_heard: "",
      budget_per_night: 0,
      unit_of_interest: "",
      lead_notes: "",

      onoff: this.props.onoff,
      editToggle: false,
      toggleDelete: false,
      flagged: false,
      tenant: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        id: -1,
      },
      booked: <></>
    };
  }

  componentDidUpdate = async (previousProps: any) => {
    if (
      store.getState().tenantReducer.curId !== -1 &&
      previousProps !== this.props
    ) {
      const allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
      const tenantData = allTenants.data.find(
        (tenantItem: any) =>
          tenantItem.id === store.getState().tenantReducer.curId
      );
      this.setState({
        lead_id: tenantData.id,
        contact_name: `${tenantData.first_name} ${tenantData.last_name}`,
        lead_email: tenantData.email,
        lead_phone: tenantData.phone_number,
        tenant: {
          first_name: tenantData.first_name,
          last_name: tenantData.last_name,
          phone_number: tenantData.phone_number,
          email: tenantData.email,
          id: tenantData.id,
        }
      });
      this.bookedMarker();
    }
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle == true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  toggleFlag = (event: any) => {
    if (this.state.flagged === false) {
      this.setState({ flagged: true });
    } else {
      this.setState({ flagged: false });
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  bookedMarker = async () => {
    
    let allBookings = await fetchGetJSON(
      `${Constants.API_PATH_BOOKING_GET_ALL}`
    );
    let bookingList = allBookings.data
    bookingList = bookingList.filter((booking: any) => 
      !booking.cancelled && (booking.tenant_id == this.state.lead_id)
      );
    let tag = (
      <div className="text-yellow-500 shadow-sm self-end border-4  rounded-sm  border-yellow-500 font-bold px-4 pb-1">
        <span className="flex flex-row items-center ">
          Not Booked
        </span>
      </div>
    );
    if (bookingList.length != 0) {
      tag = (
        <div className="text-green-500 shadow-sm self-end border-4  rounded-sm  border-green-500 font-bold px-4 pb-1">
          <span className="flex flex-row items-center ">
            Booked
          </span>
        </div>
      );
    }
    this.setState({booked: tag});
  }

  render() {
    let corporateIcon = <></>;

    if (!this.props.onoff) {
      return null;
    }

    if (this.state.corporate !== false) {
      corporateIcon = <FaUserTie className="text-4xl text-gray-500" />;
    } else {
      corporateIcon = <RiUser3Fill className="text-3xl text-gray-500" />;
    }

    let editFocus = "opacity-100";
    let bgFocus;

    if (this.state.editToggle === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    let FlexibleDate = "";

    if (this.state.flexible_dates === true) {
      FlexibleDate = "Dates Are Flexible";
    }

    let LeadDetails = (
      <div className=" slide-in-blurred-top font-display  border px-3 py-4 ">
        <div className="flex-wrap flex justify-start  items-center flex-row">
          {/* CheckIn Info */}
          <h2 className=" mr-4 pt-1 inline-block align-middle font-regular text-base">
            Email<span className="text-gray-400"> |</span>{" "}
            <span className="font-medium">{this.state.lead_email}</span>{" "}
          </h2>
        </div>

        <div className="flex-wrap flex justify-start  items-center flex-row">
          <h2 className=" mr-4 pt-5 inline-block align-middle  font-regular text-sm">
            Requested Check In:<span className="text-gray-400"> |</span>{" "}
            <span className="font-medium text-gray-500">
              {dateCheck(this.state.requested_check_in)}
            </span>{" "}
          </h2>
          <h2 className=" mr-4 pt-5 inline-block align-middle  font-regular text-sm">
            Requested Check In:<span className="text-gray-400"> |</span>{" "}
            <span className="font-medium text-gray-500">
              {dateCheck(this.state.requested_check_out)}
            </span>{" "}
          </h2>
        </div>
        <h2 className=" mr-4 pt-2 text-green-500  font-regular text-xs">
          {FlexibleDate}
        </h2>

        <h2 className=" mr-5 pt-6 inline-block align-middle  font-regular text-sm">
          Suite Type:
          <span className=" text-gray-500"> {this.state.suite_type}</span>{" "}
        </h2>
        <h2 className=" mr-5 pt-6 inline-block align-middle  font-regular text-sm">
          Type of Lead:
          <span className=" text-gray-500"> {this.state.lead_type}</span>{" "}
        </h2>
        <h2 className=" mr-5 pt-6 inline-block align-middle  font-regular text-sm">
          Phone:<span className=" text-gray-500"> {this.state.lead_phone}</span>{" "}
        </h2>

        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Purpose of Stay:{" "}
          <span className=" text-gray-500">{this.state.purpose_of_stay}</span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Parking:{" "}
          <span className=" capitalize text-gray-500">
            {this.state.parking_requested.toString()}
          </span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Number of Adults:{" "}
          <span className=" text-gray-500">{this.state.number_of_adults}</span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Number of Children:{" "}
          <span className=" text-gray-500">
            {this.state.number_of_children}
          </span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Country:{" "}
          <span className=" text-gray-500">{this.state.country_of_origin}</span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          How They Heard:{" "}
          <span className=" text-gray-500">{this.state.how_they_heard}</span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Budget Per Night:{" "}
          <span className=" text-gray-500">
            {formatAmountForDisplay(this.state.budget_per_night, "CAD", true)}
          </span>
        </h2>
        <h2 className=" mr-5 pt-3 align-middle  font-regular text-sm">
          Unit{" "}
          <span className=" text-gray-500">{this.state.unit_of_interest}</span>
        </h2>

        <div className="flex-wrap flex justify-between mt-3 flex-row">
          <div className="w-auto flex flex-col">
            <h2 className=" text-gray-500 mr-4 pt-1 inline-block align-middle font-hairline text-sm">
              Notes:
            </h2>
            <p className=" max-w-sm text-gray-700 mr-4 pt-1 inline-block align-middle font-regular text-xs">
              {this.state.lead_notes}
            </p>
          </div>
        </div>
      </div>
    );

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed flex flex-row align-center justify-center w-full h-full bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <EditLead
          leadData={this.state}
          handleInputChange={this.handleInputChange}
          handleToggleChange={this.handleToggleChange}
          handleDateChange={this.handleDateChange}
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
        />
        <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("toggleDelete", this.state.toggleDelete)
          }
          onoff={this.state.toggleDelete}
          title={"Delete Lead"}
        />
        {bgFocus}
        <div className=" appear font-sofia bg-white shadow-lg rounded-lg h-auto  max-w-screen-md self-center align-center overflow-y-scroll scroll-hide  ">
          <div className="w-full bg-gray-100 pt-8 pb-3 px-8 rounded-t-lg">
            {corporateIcon}
            <div className="flex flex-row my-1 justify-between items-center">
              <span className="text-2xl font-bold text-gray-800">
                Lead ID # {this.state.lead_id}
              </span>
              {this.state.booked}
              <MakeBookingLink tenant={this.state.tenant}/>
            </div>

            <div className="flex flex-row my-1 justify-between items-end pt-1">
              <div className="flex flex-col text-xs font-medium text-gray-600">
                <span className=" pb-1">
                  Corporate: {this.state.company_name}
                </span>
                <span className=" pb-2">Name: {this.state.contact_name}</span>
                <span className="opacity-70 text-2xs pb-1">
                  Contact Date: {formatFullDate(this.state.contact_date)}
                </span>
              </div>

              <EditOptionPanel
                handleEditBarToggle={this.handleEditBarToggle}
                panelType="Lead"
                parentData={this.state}
                handleFlag={this.toggleFlag}
                handleState={this.handleToggleChange}
              />
            </div>
          </div>

          <div className="w-full pt-6 pb-8 px-4 lg:px-8">{LeadDetails}</div>
        </div>
      </div>
    );
  }
}

export default LeadsQuickView;

export function dateCheck(date: any) {
  if (date === "") {
    return "-";
  } else {
    return formatFullDate(date);
  }
}

function MakeBookingLink(props : any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let history = useHistory();

  const handleClick = (event : any) => {
    event.preventDefault();
    store.dispatch({ type: "setBookingTenant", tenant: props.tenant });
    store.dispatch({ type: "setBooking", booking: undefined });
    history.push(`${path}/manual-booking`);
  }

  return (
    <button
    className=" cursor-pointer h-8 mr-2 bg-green-700 hover:bg-green-500 text-white font-medium text-xs rounded-md pb-1  w-auto  flex items-center text-center px-5"
    onClick={handleClick}>
        Make Booking
    </button>
  );
}
