import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { IoIosArrowBack } from "react-icons/io";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import ToggleSwitch from "../ToggleSwitch";

type Props = {
  handleToggleChange: any;
  handleInputChange: any;
  corpContactData: any;
  toggle: any;
  handleEditBarToggle: any;
  ErrorNote: any;
};

type EditCorpContactState = {
  contact_id: number;
  contact_fname: string;
  contact_lname: string;
  contact_email: string;
  hasCountryCode: boolean;
  country_code: string;
  contact_phone: string;
  hasPhoneExtension: boolean;
  phone_extension: string;
  admin: boolean;
  finance: boolean;
  cleaning: boolean;
  corp_name: string;
  corp_id: number;
  corporates: []
};

class EditContacts extends Component<Props, EditCorpContactState> {
  constructor(props: any) {
    super(props);
    
    // parsing phone numbers, this can likely be changed/removed once phone numbers are standardized in our database
    const phoneReg: RegExp = new RegExp(/^(\+(\d+) )?(\d+)(x(\d+))?$/);
    let country_code: string = '';
    let phone_extension: string = '';
    let contact_phone: string = this.props.corpContactData.contact_phone;

    if (phoneReg.test(contact_phone)) {
      const spaceIndex: number = contact_phone.indexOf(' ');
      const extensionIndex: number = contact_phone.indexOf('x');
      country_code = contact_phone.includes('+') ? contact_phone.substring(0, spaceIndex) : '';
      phone_extension = contact_phone.includes('x') ? contact_phone.substring(extensionIndex + 1) : '';
      contact_phone = contact_phone.substring(
        country_code ? spaceIndex + 1 : 0, // start
        phone_extension ? extensionIndex : contact_phone.length // end
      );
    }

    this.state = {
      contact_id: this.props.corpContactData.contact_id,
      contact_fname: this.props.corpContactData.contact_fname,
      contact_lname: this.props.corpContactData.contact_lname,
      contact_email: this.props.corpContactData.contact_email,
      hasCountryCode: country_code ? true : false,
      country_code: country_code,
      contact_phone: contact_phone,
      hasPhoneExtension: phone_extension ? true : false,
      phone_extension: phone_extension,
      admin: this.props.corpContactData.admin,
      finance: this.props.corpContactData.finance,
      cleaning: this.props.corpContactData.cleaning,
      corp_name: this.props.corpContactData.corp_name,
      corp_id: -1,
      corporates: []
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};

      if (this.target.id === 'country_code') {
        const countryCodeReg: RegExp = new RegExp(/^\+?(\d+)?$/);
        
        if (!countryCodeReg.test(this.target.value)) {
          return;
        }

        if (!this.target.value.includes('+') && this.target.value) {
          this.target.value = '+' + this.target.value;
        }
        else if (this.target.value.length === 1) {
          this.target.value = '';
        }
      }
      else if (this.target.id === 'contact_phone' || this.target.id === 'phone_extension') {
        const numberReg: RegExp = new RegExp(/^(\d+)?$/);

        if (!numberReg.test(this.target.value)) {
          return;
        }
      }

      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  handleSubmit = async () => {
    let NotificationHandler = this.props.ErrorNote;
    // event.preventDefault();
    const newCorp: any = this.state.corporates.find((corp: any) => corp.corporate_name === this.state.corp_name);
    console.log(newCorp);

    // Make phone number only number values 
    let phone_num = "";

    const phoneReg: RegExp = new RegExp(/^(\+(\d+) )?(\d+)(x(\d+))?$/);
    if (this.state.hasCountryCode && this.state.country_code) {
      phone_num += `${this.state.country_code} `;
    }
    phone_num += this.state.contact_phone;
    if (this.state.hasPhoneExtension && this.state.phone_extension) {
      phone_num += `x${this.state.phone_extension}`;
    }

    if (!phoneReg.test(phone_num)) {
      NotificationHandler("Error", 
      "Phone numbers cannot contain characters or symbols", 
      "Please enter a valid phone number");
      return;
    } else {
      let editContactBody = {
        id: this.state.contact_id,
        fields: {
          first_name: this.state.contact_fname.trim(),
          last_name: this.state.contact_lname.trim(),
          email: this.state.contact_email.trim(),
          phone_number: phone_num,
          corporate_id: newCorp.id,
          admin: this.state.admin,
          finance: this.state.finance,
          cleaning: this.state.cleaning
        }
      };

      const editContact = await fetchRequestJSON("PUT", Constants.API_PATH_CORP_CONTACT_EDIT, editContactBody);
      console.log(editContact);
      if (editContact.status === "Success") {
        NotificationHandler("Success", 
        "Successfully edited corporate contact", 
        "Please refresh page to see your changes");
      } else {
        NotificationHandler("Error", 
        "Something went wrong", 
        "");
        return;
      }
    }
  };

  componentDidMount = async () => {
    const corpContactId = new URLSearchParams(window.location.search).get("contact_id");
    const contact = await fetchGetJSON(
      `${Constants.API_PATH_CORP_CONTACT_GET_CORPORATE_CONTACT}${corpContactId}`
    );
    console.log(contact);

    if (contact.status === "Success" && contact.data) {
      // parsing phone numbers, this can likely be changed/removed once phone numbers are standardized in our database
      const phoneReg: RegExp = new RegExp(/^(\+(\d+) )?(\d+)(x(\d+))?$/);
      let country_code: string = '';
      let phone_extension: string = '';
      let contact_phone: string = contact.data.phone_number;

      if (phoneReg.test(contact_phone)) {
        const spaceIndex: number = contact_phone.indexOf(' ');
        const extensionIndex: number = contact_phone.indexOf('x');
        country_code = contact_phone.includes('+') ? contact_phone.substring(0, spaceIndex) : '';
        phone_extension = contact_phone.includes('x') ? contact_phone.substring(extensionIndex + 1) : '';
        contact_phone = contact_phone.substring(
          country_code ? spaceIndex + 1 : 0, // start
          phone_extension ? extensionIndex : contact_phone.length // end
        );
      }

      console.log('country_code: ' + country_code)
      console.log('contact_phone: ' + contact_phone)
      console.log('phone_extension: ' + phone_extension)

      this.setState({
        contact_id: contact.data.id,
        contact_fname: contact.data.first_name,
        contact_lname: contact.data.last_name,
        contact_email: contact.data.email,
        hasCountryCode: country_code ? true : false,
        country_code: country_code,
        contact_phone: contact_phone,
        hasPhoneExtension: phone_extension ? true : false,
        phone_extension: phone_extension,
        corp_id: contact.data.corporate_id, 
        admin: contact.data.admin,
        finance: contact.data.finance,
        cleaning: contact.data.cleaning
      });

      const allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
      const currCorpInfo = allCorps.data.find((corpItem: any) => corpItem.id === this.state.corp_id);
      this.setState({
        corp_name: currCorpInfo.corporate_name,
        corporates: allCorps.data
      });
    }
  }

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Corporate Contact";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let content;

    if (toggleEdit === true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          Corporate Contact Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact First Name</span>
          <input
            id="contact_fname"
            onChange={this.handleInputChange}
            value={this.state.contact_fname}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Last Name</span>
          <input
            id="contact_lname"
            onChange={this.handleInputChange}
            value={this.state.contact_lname}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Email</span>
          <input
            id="contact_email"
            onChange={this.handleInputChange}
            value={this.state.contact_email}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className="flex w-full pl-3 mt-0 mb-0 items-center justify-between">
          <div className="flex flex-col items-start">
            <span className={"transition-all duration-600 text-gray-600 text-sm mb-1 whitespace-nowrap"}>Country Code</span>
            <ToggleSwitch
              id="hasCountryCode"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.hasCountryCode}
            />
          </div>

          <div className="flex flex-col items-start">
            <span className={"transition-all duration-600 text-gray-600 text-sm mb-1 whitespace-nowrap"}>Extension</span>
            <ToggleSwitch
              id="hasPhoneExtension"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.hasPhoneExtension}
            />
          </div>
        </div>

        <div className="flex flex-row w-full pl-3 mt-0 mb-5">
          <label className={`transition-all duration-500 ease-in-out transform ${this.state.hasCountryCode ? 'w-3/12 pr-2' : 'w-0'} overflow-hidden`}>
            <span className="text-gray-400 text-xs whitespace-nowrap overflow-hidden text-ellipsis">Country Code</span>
            <input
              id="country_code"
              onChange={this.handleInputChange}
              value={this.state.country_code}
              type="text"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder="+"
            />
          </label>
          <label className={`transition-all duration-500 ease-in-out transform flex-grow overflow-hidden`}>
            <span className="text-gray-400 text-xs">Phone</span>
            <input
              id="contact_phone"
              onChange={this.handleInputChange}
              value={this.state.contact_phone}
              type="text"
              className={`mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black`}
              placeholder=""
            />
          </label>
          <div className={`transition-all duration-500 ease-in-out transform ${this.state.hasPhoneExtension ? 'scale-100 opacity-100 w-1/12' : 'scale-0 opacity-0 w-0'} justify-center align-center flex items-end`}>
            <span className="text-gray-400 pb-1.5">x</span>
          </div>
          <label className={`transition-all duration-500 ease-in-out transform ${this.state.hasPhoneExtension ? 'w-2/12' : 'w-0'} overflow-hidden`}>
            <span className="text-gray-400 text-xs whitespace-nowrap overflow-hidden text-ellipsis">Extension</span>
            <input
              id="phone_extension"
              onChange={this.handleInputChange}
              value={this.state.phone_extension}
              type="text"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>
        </div>

        <span className="text-gray-600 text-md font-bold mb-4 mt-2"> Corporation Info </span>
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Corporation Name</span>
            <select
              id="corp_name"
              onChange={this.handleInputChange}
              value={this.state.corp_name}
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            >
              <option value={this.state.corp_name} selected>{this.state.corp_name}</option>
              {/* Only show corporate names that are not the current corporate name */}
              {this.state.corporates.filter((corp: any) => corp.corporate_name !== this.state.corp_name)
              .map((corp: any) => <option value={corp.corporate_name} key={corp.corporate_id}>{corp.corporate_name}</option>)}
            </select>
        </label>

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Corporate Roles</span>
          <select
            id="corp_role"
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            onChange={this.handleInputChange}
            value={this.state.corp_role}
          >
            <option value={this.state.corp_role} selected>{this.state.corp_role}</option>
            {roleOptionsLeft.map(role => <option value={role} key={role}>{role}</option>)}
          </select>
        </label> */}

        <div className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Corporate Roles</span>
          <div className="grid grid-cols-1 gap-6 w-full mt-2 mb-5">
            <div className={" block "}>
              <div className="flex flex-row items-center justify-between ">
                <span className={"transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"}>Admin</span>
                <ToggleSwitch
                  id="admin"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.admin}
                />
              </div>
            </div>

            <div className={" block  "}>
              <div className="flex flex-row items-center justify-between ">
                <span className={"transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"}>Finance</span>
                <ToggleSwitch
                  id="finance"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.finance}
                />
              </div>
            </div>

            <div className={" block "}>
              <div className="flex flex-row items-center justify-between ">
                <span className={" transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"}>Cleaning</span>
                <ToggleSwitch
                  id="cleaning"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.cleaning}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.handleSubmit}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.state.contact_id}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => {
              this.handleSubmit(); 
              this.props.handleEditBarToggle();
            }}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(EditContacts);
