import { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import { RiUser3Fill } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import {
  addDays,
  formatFullDate,
  formatStringDate,
} from "../../../utils/DateFormat";
import CancelModal from "../../Bookings/CancelModal";
import EditOptionPanel from "../../EditOptionPanel";
import SendInvoice from "../../Invoices/SendInvoice";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import store from "../../../redux/store";
import { LoadingInvoiceQuickView } from "../../../utils/LoadingComps/LoadingInvoice";
import { Invoice } from "../../../utils/Types";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";

// Takes Booking ID Prop and pull all info

interface Props {
  handleModalToggle: any;
  onoff: boolean;
  info: any;
}

interface QuickViewModalState {
  onoff: boolean;
  loaded: boolean;

  corporates: any[];
  booking_ids: number[];
  corporate_ids: number[];
  tenant_names: string[];
  suites: string[];
  editToggle: boolean;
  tenant_ids: number[];
  amount: number;
  consolidatedId: number;

  line_items: any[];

  toggleTransfer: boolean;
  toggleSend: boolean;
  toggleCancel: boolean;

  invoices: any[];
  bookings: any[];
  tenants: any[];
  corps: any[];
  these_invoices: Invoice[];
  consolidated_invoice: any;
  paid: boolean;
  flagged: boolean;
}

class ConsolidatedQuickView extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      onoff: this.props.onoff,
      loaded: false,
      suites: [],
      tenant_ids: [],
      consolidated_invoice: undefined,

      corporates: [],
      booking_ids: [],
      corporate_ids: [],
      tenant_names: [],
      editToggle: false,

      line_items: [],

      toggleTransfer: false,
      toggleSend: false,
      toggleCancel: false,
      amount: -1,

      invoices: [],
      bookings: [],
      tenants: [],
      corps: [],
      these_invoices: [],
      paid: false,
      flagged: false,
      consolidatedId: -1
    };
  }

  handleModal = async (event: any) => {
    console.log("in handle modal");
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ loaded: false });
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  componentDidMount = async () => {
    store.dispatch({
      type: "consolidatedInvoiceQuickViewUpdate",
      quickViewUpdate: this.fetchAll,
    });
    this.fetchAll();
  };

  fetchAll = async () => {
    console.log(this.props)
    let allInvoices = await fetchGetJSON(Constants.API_PATH_INVOICE_GET_ALL);
    let allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_GET_ALL);
    let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    let allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
    this.setState({
      invoices: allInvoices.data,
      tenants: allTenants.data,
      corps: allCorps.data,
      bookings: allBookings.data
    });
  };


  // Update on different list entry clicks from list of invoices
  componentDidUpdate = async (previousProps: any) => {
    const curId = store.getState().consolidatedInvoiceReducer.curId;
    if (
      curId !== -1 &&
      previousProps !== this.props &&
      this.state.invoices.length != 0
    ) {
      const thisConsolidated = await fetchGetJSON(`${Constants.API_PATH_CONSOLIDATED_GET}${curId}`)
      let theseInvoices: Invoice[] = [];

      let allInvoices = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_CONSOLIDATED_LINKED,
        { consolidated_id: curId }
      );
      if (allInvoices.data) {
        theseInvoices = allInvoices.data;
      }


      let allInvoiceLines: any[] = [];
      let paid = true;

      for (let i = 0; i < theseInvoices.length; i++) {
        if(theseInvoices[i]) {
          const currLines = await fetchGetJSON(
            `${Constants.API_PATH_INVOICE_LINE_GET_ALL}${theseInvoices[i].id}`
          );
          if (!theseInvoices[i].paid) {
            paid = false;
          }
  
          let invoiceFor: string = "";
  
          if(theseInvoices[i].booking_id) {
            let thisBooking = this.state.bookings.find((booking: any) => booking.id === theseInvoices[i].booking_id);
            if(thisBooking.corporate_id) {
              let thisCorp = this.state.corps.find((corp: any) => corp.id === thisBooking.corporate_id);
              invoiceFor = thisCorp.corporate_name;
  
            }else {
              let thisTenant = this.state.tenants.find((tenant: any) => tenant.id === thisBooking.tenant_id);
              invoiceFor = thisTenant.first_name + " " + thisTenant.last_name;
            } 
          }
          const thisObject = {
            lines: currLines.data,
            invoiceId: theseInvoices[i].id,
            invoiceFor: invoiceFor
          };
  
          allInvoiceLines.push(thisObject);
        }
      }
      // const allConsolidated = await fetchGetJSON(
      //   Constants.API_PATH_CONSOLIDATED_GET_ALL
      // );
      // const thisConsolidated = allConsolidated.data.find(
      //   (consolidated: any) => consolidated.id === curId
      // );

      let amount = 0;
      const consolAmount = await fetchGetJSON(`${Constants.API_PATH_CONSOLIDATED_AMOUNT}${curId}`);
      if(consolAmount.data) {
        amount = consolAmount.data; 
      }

      // Get this invoices booking info
      let bookingData: any;

      // Get this invoices tenant info
      let tenants: any;
      let curTenant: any;
      if (bookingData) {
        tenants = this.state.tenants;
        curTenant = tenants.find(
          (tenantItem: any) => tenantItem.id === bookingData.tenant_id
        );
      }

      // Get this invoices corporate info
      let allCorps: any;
      let corpData: any;
      if (bookingData) {
        allCorps = this.state.corps;
        corpData = allCorps.find(
          (corpItem: any) => corpItem.id === bookingData.corporate_id
        );
      }

      if(thisConsolidated.data) {
        paid = thisConsolidated.data.paid; 
      }

      this.setState({
        corporate_ids: [],
        tenant_names: [],

        corporates: [],
        line_items: allInvoiceLines,
        amount: amount,
        paid: paid,
        consolidatedId: curId
      });

      if (this.props.onoff) {
        this.setState({ loaded: true });
      }
      // console.log(this.props.info)
    } else {
      console.log("Did not fill");
    }
  };

  toggleFlag = (event: any) => {
    if (this.state.flagged === false) {
      this.setState({ flagged: true });
    } else {
      this.setState({ flagged: false });
    }
  };

  togglePay = (event: any) => {
    let toggle = this.state.paid;
    this.setState({ paid: !toggle });
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  generateInvoices = (): JSX.Element => {
    let invoices = [];
    for (let i = 0; i < this.state.line_items.length; i++) {
      let thisItem = this.state.line_items[i];
      let invoiceLines = InvoiceLineFormatting(thisItem.lines);
      let thisInvoiceData = this.state.invoices.find(
        (invoiceItem: any) => invoiceItem.id === thisItem.invoiceId
      );
      let bookingData: { tenant_id: number };
      if (thisInvoiceData.booking_id) {
        bookingData = this.state.bookings.find(
          (bookingId) => bookingId === thisInvoiceData.booking_id
        );
      }

      let tenantData;
      if (bookingData) {
        tenantData = this.state.tenants.find(
          (tenantId) => tenantId === bookingData.tenant_id
        );
      }
      let tenantName = "";
      if (tenantData) {
        tenantName = tenantData.first_name + " " + tenantData.last_name;
      }

      let paidStamp = <> </>;
      if(thisInvoiceData.paid) {
        paidStamp = (
          <div className="text-green-500 shadow-sm self-end border-4  rounded-sm  border-green-500 font-bold px-4 pb-1 ml-auto">
            <span className="flex flex-row items-center ">
              This Invoice Has Been Paid
            </span>
          </div>
        );
      }
      

      let thisInvoice = (
        <div id="invoice" className="border-4 px-4 pb-4 mb-5">
          <div className="flex flex-row justify-between w-full">
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between">
                  <span className="text-lg font-base text-gray-400 uppercase mb-2 mt-6">
                    Invoice # {thisItem.invoiceId}
                    
                  </span>
                  {paidStamp}
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col mr-6">
                    <span className="flex flex-col mr-5 text-sm text-gray-400">
                      Issued on
                    </span>
                    <span className=" mr-5 font-bold text-md text-gray-700">
                      {formatStringDate(thisInvoiceData.created_at)}
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className=" mr-5 text-sm text-gray-400">
                      Due Date
                    </span>
                    <span className=" mr-5 font-bold text-md text-gray-700">
                      {formatStringDate(thisInvoiceData.due)}
                    </span>
                  </div>
                  <div className="flex flex-col ml-5">
                    <span className="mr-5 text-sm text-gray-400 justify-end">
                      Invoice For
                    </span>
                    <span className=" text-lg font-bold text-gray-600 mtf-1">
                      {thisItem.invoiceFor}
                    </span>
                    <span className=" text-medium text-gray-600 mt-1">
                      {tenantName}
                    </span>
                    <span className=" text-xs text-gray-300 mt-1">
                      HST No.: 79222 4677 RT0001
                    </span>
                  </div>
                </div>
                
              </div>
            
          </div>
          

          <table
            style={{ borderSpacing: "0" }}
            className="border-separate border rounded-sm mt-6 relative w-full table-auto bg-white     "
          >
            <thead className="border-b border-gray-400 ">
              <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                <th className="sticky top-0  py-3 pl-5  font-thin border-b border-gray-400  ">
                  Item
                </th>

                <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                  Payment Method
                </th>
                <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 text-right px-4">
                  Price
                </th>
              </tr>
            </thead>

            <tbody className="divide-y">
              <>{invoiceLines}</>
            </tbody>
          </table>
        </div>
      );
      console.log("invoices length");
      console.log(invoices.length);
      invoices.push(thisInvoice);
    }

    return <div>{invoices}</div>;
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }
    if (this.props.onoff && this.state.invoices.length == 0) {
      this.handleModal({ target: { id: "cancel" } });
    }
    // this.display()

    let corporateIcon = <></>;

    if (this.state.corporates) {
      corporateIcon = <FaUserTie className="text-4xl text-gray-500" />;
    } else {
      corporateIcon = <RiUser3Fill className="text-3xl text-gray-500" />;
    }

    // let editFocus = "opacity-100"
    let bgFocus;
    let paidStamp = <></>;
    let editFocus = "opacity-100";

    if (this.state.editToggle === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    if (this.state.paid === true) {
      paidStamp = (
        <div className="text-green-500 shadow-sm self-end border-4  rounded-sm  border-green-500 font-bold px-4 pb-1 mb-2">
          <span className="flex flex-row items-center ">
            This Consolidated Invoice Has Been Paid
          </span>
        </div>
      );
    }

    //let day_after = addDays(this.state.end_date, 1);

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed  flex flex-col items-center w-full h-full bg-gray-900 bg-opacity-40 z-50   "
        }
      >
        {/* <EditInvoice
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
          invoiceData={this.state}
          handleToggleChange={this.handleToggleChange}
          handleDateChange={this.handleDateChange}
          handleInputChange={this.handleInputChange}
        /> */}

        <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("toggleCancel", this.state.toggleCancel)
          }
          onoff={this.state.toggleCancel}
          title={"Delete Consolidated"}
          // invoiceOff={this.handleModal}
        />
        {/* <SendInvoice
          handleModalToggle={() =>
            this.handleToggleChange("toggleSend", this.state.toggleSend)
          }
          onoff={this.state.toggleSend}
          title={"Send Invoice"}
          invoice_id={this.state.invoice_id}
          tenant_id={this.state.tenant_id}
        /> */}
        {bgFocus}

        <div className="appear bg-white sm:my-10 w-full overflow-y-auto shadow-md sm:rounded-lg max-w-3xl  scroll-hide ">
          {!this.state.loaded ? (
            <LoadingInvoiceQuickView />
          ) : (
            <>
              <div className="w-full bg-gray-100 pt-4 pb-2  px-4 sm:px-8 rounded-t-lg ">
                <div className="flex flex-col sm:flex-row my-1  justify-between sm:items-center">
                  <IoIosArrowBack
                    id="cancel"
                    onClick={this.handleModal}
                    className=" lg:hidden  text-2xl transform -translate-x-2 -translate-y-1 mb-3 hover:text-gray-300"
                  />
                  <span className="text-xl font-bold text-gray-800">
                    Consolidated Invoice ID #
                    {store.getState().consolidatedInvoiceReducer.curId}
                  </span>
                </div>

                <div className="flex flex-col mt-2 sm:mt-0 justify-start sm:flex-row my-1 sm:justify-between sm:items-end pt-1">
                  <div className="flex flex-col text-m mb-6 font-medium text-gray-600">
                    SVSRELO.{" "}
                  </div>
                  <EditOptionPanel
                    handleEditBarToggle={this.handleEditBarToggle}
                    parentData={this.state}
                    panelType="Consolidated"
                    handleFlag={this.toggleFlag}
                    handleState={this.handleToggleChange}
                    handlePay={this.togglePay}
                  />
                </div>

                
              </div>

              <div className="w-full  pt-6 px-4 pb-10">
                <div className=" hidden sm:flex flex-col h-auto justify-between ">
                  {/* <img className="w-24" src={process.env.REACT_APP_SVSRELO_COMPNAY_LOGO} /> */}

                  {paidStamp}
                </div>
                {this.generateInvoices()}
                <div className="w-full flex flex-row justify-between py-5 ">
                  <div className="flex flex-col text-sm">
                    <span className="text-base mb-1">
                      Payment Types Accepted:{" "}
                    </span>
                    <span className=" text-gray-500">
                      Credit Card (subject to 3% fee), Debit, Certified Cheque,
                      Wire
                    </span>
                    <span className="text-xs text-gray-400">
                      All Amounts In CAD
                    </span>
                  </div>
                  <span className="text-right text-2xl font-bold text-gray-600">
                    <span className="font-thin text-lg">
                      Total: ${this.state.amount}
                    </span>{" "}
                  </span>
                </div>
                <div className="flex flex-col text-xs text-gray-400 justify-center items-start mt-5">
                  <span className="text-gray-700">SVSRELO</span>
                  <span>142 Simcoe St. </span>
                  <span>Toronto ON Canada, M5H 3G4</span>
                  <span className="text-green-500">info@{process.env.REACT_APP_COMPNAY_URL}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ConsolidatedQuickView;

export function InvoiceLineFormatting(invoiceLines: any) {
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = invoiceLines.length;

  for (var i = 0; i < length; i++) {
    let lineItem = invoiceLines[i];
    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    //when linking to back end: remember to switch the key to the unique invoice id from the backend
    Payments = (
      <tr
        key={i.toString()}
        className={altBg + " w-full   h-12 border-b border-gray-400  "}
      >
        <td className="py-4 pl-5 text-gray-700 font-thin text-sm border-b">
          {lineItem.entry.split(">")[0]}
        </td>
        <td className="py-3 font-thin text-sm  border-b">TBD</td>
        <td className="py-3 text-green-500 font-thin text-right px-4 border-b">
          {formatAmountForDisplay(lineItem.cost, "CAD", false)}{" "}
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}
