import { Component } from "react";
import { FaRegCalendarPlus } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO, formatFullDate } from "../../../utils/DateFormat";
import { FiCornerUpRight } from "react-icons/fi";
import ToggleSwitch from "../../ToggleSwitch";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import BuildingList from "../../Units/BuildingList";
import {
  getGroupList,
  joinGroup,
  leaveGroup,
  getUserList,
} from "../../../api-requests/api-gateway";
import { ConsoleLogger } from "@aws-amplify/core";
import { withHooksHOC } from "../../Notifications/NotificationProvider";
import dayjs from "../../../utils/Dayjs";

const apiPath = "/cleanings/add-cleaner-cleaning";

type Props = {
  chosenDate: string;
  handleModalToggle: any;
  onoff: boolean;
  update: any;
  ErrorNote: any;
};

type ModalState = {
  onoff: boolean;
  transfer_date: any;
  building: string;
  unit_id: number;
  unit_name: string;
  cleaner: string;
  non_booking_type: boolean;
  non_booking_label: string;
  cleaning_notes: string;
  touch_up: boolean;
  cleaning_type: string;

  buildingList: any[];
  unitList: any[];
  unitNameList: any[];
  cleanerList: any[];
  internalCleanerList: any[];

  labelDefault: string;
};

const labels: Map<string, string> = new Map([
  ['deep clean part one', "DC1"],
  ['deep clean part two', "DC2"],
  ['touch up', "Touch Up"],
  ['inspection and fix', "Insp & Fix"],
]);

class NewCleaning extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      onoff: this.props.onoff,
      transfer_date: "",
      building: "",
      unit_id: -1 /* TODO: make this a number instead of a string? */,
      unit_name: "",
      cleaner: "",
      non_booking_type: false,
      cleaning_type: "",
      non_booking_label: "",
      cleaning_notes: "",
      touch_up: false,
      buildingList: [],
      unitList: [],
      unitNameList: [],
      cleanerList: [],
      internalCleanerList: [],
      labelDefault: "",
    };
  }


  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = async (event: any) => {
    event.preventDefault();
    console.log(event)
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    await this.setState(stateObject);
  };

  defaultLabel = () => {

    if (labels.has(this.state.cleaning_type.toLowerCase())) {
      // const msg = labels.get(this.state.cleaning_type.toLowerCase())
      // return msg;
      this.setState({ non_booking_label: labels.get(this.state.cleaning_type.toLowerCase()) })
    } else {
      return ""
    }
  }

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  createCleaning = async (event: any) => {
    event.preventDefault();
    // Create Cleaning
    if (!this.state.cleaning_type) {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler("Error", "A Cleaning must have a cleaning type, please select a cleaning type")
      return;
    }
    this.props.handleModalToggle();
    let newCleaning;
    if (this.state.non_booking_type) {
      newCleaning = {
        cleaning_type: this.state.cleaning_type.toLowerCase(),
        cleaning_date: dayjs(this.props.chosenDate).format('YYYY-MM-DD'),
        title: this.state.non_booking_label,
        notes: this.state.cleaning_notes,
      }
    } 
    else {
      if (this.state.unit_id === -1) {
        alert("Please Choose unit for Cleaning")
        return;
      }

      newCleaning = {
        cleaning_type: this.state.cleaning_type.toLowerCase(),
        unit_id: this.state.unit_id,
        notes: this.state.cleaning_notes,
        cleaning_date: dayjs(this.props.chosenDate).format('YYYY-MM-DD'),
      };
    }

    let res = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_CLEANING_CREATE,
      newCleaning
    );
    if (res.status === 'Success') {
      console.log(res);
    } else {
      console.log(res);
      console.log("an error occurred");
    }

    // TODO: I noticed that there isn't a frontend API endpoint for creating recurring cleanings.
    // TODO: Should I create a new frontend API endpoint for creating recurring cleanings?
    // Assign cleaner
    if (this.state.cleaner) {
      let curCleaner = this.state.cleaner
      let internalCleaners = this.state.internalCleanerList;
      for (let i in internalCleaners) {
        if (internalCleaners[i].user_id == curCleaner) {
          curCleaner = internalCleaners[i].id;
          break;
        }
      }
      let assignCleaner = {
        cleaner_id: curCleaner,
        cleaning_id: res.data.id
      };
      let res2 = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_CLEANING_ASSIGN_CLEANER,
        assignCleaner
      );
      if (res2.status === 200) {
        console.log(res2);
      } else {
        console.log(res2);
        console.log("an error occurred");
      }
    }

    this.setState({
      cleaning_type: "",
      unit_id: null,
      cleaner: "",
      cleaning_notes: "",
    })
    console.log("ressy re", res)
    
    const updateType = {
      event: res.status == "Success" ? "Creation" : "Error",
      message: res.status == "Success" ? res.data.title : res.status
    }
    this.props.update(updateType);
    // window.location.reload();
  };

  componentDidMount = async () => {
    let allBuilding = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);
    let allUnit = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
    let allCleaners = await fetchGetJSON(`${Constants.API_PATH_CLEANER_GET_ALL}?active=true`);
    let allUsers = await getUserList();

    //Filter all units to only display active units
    const units = allUnit.data.map((obj: { id: Number, active: Boolean, suite_name: String }) => [obj.id, obj.active, obj.suite_name]);
    const activeUnits = units.filter((x: any[]) => x[1] === true);

    let test = allCleaners.data.map((x: { user_id: any; }) => x.user_id)
    const cleaners = allUsers.Users.filter((x:any) => test.includes(x.Username))

    this.setState({
      buildingList: allBuilding.data,
      unitNameList: activeUnits,
      cleanerList: cleaners,
      internalCleanerList: allCleaners.data
    });
  };

  handleDropdownChange = async (event: any) => {
    event.preventDefault();
    console.log(event)
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    await this.setState(stateObject);

    this.defaultLabel();
  }

  render() {
    if (!this.props.onoff) {
      return null;
    }

    let inactive = "";

    if (this.state.building === "") {
      inactive = "opacity-50 pointer-events-none ";
    }

    let toDate = Date.parse(this.props.chosenDate);
    let dateState = new Date(toDate);
    dateState.setTime(
      dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
    );

    let cleaningTypeInput = <></>;

    if (this.state.non_booking_type === false) {
      cleaningTypeInput = (
        <div className="grid grid-cols-1 gap-1 w-full my-5">
          <label className={" block  "}>
            <span className="text-gray-400 text-xs">Cleaning Types</span>
            <select
              id="cleaning_type"
              onChange={this.handleInputChange}
              // value={this.state.cleaning_type}
              className={
                "mt-0 mb-3 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              }
            >
              <option selected disabled className="text-gray-400">Please Select a Cleaning Type</option>
              <option>Turnover</option>
              <option>Regular</option>
              <option>Touch Up</option>
              <option>New Unit</option>
              <option>Deep Clean Part One</option>
              <option>Deep Clean Part Two</option>
              <option>Inspection and Fix</option>
              <option>Other</option>
            </select>
          </label>

          {/* <label className={" block  "}>
            <span className="text-gray-400 text-xs">Building</span>
            <select
              id="building"
              onChange={this.handleInputChange}
              value={this.state.building}
              className={
                "mt-0 mb-3 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              }
              placeholder=""
            >
              <option selected disabled hidden></option>
              {this.state.buildingList.map((building: any) => (
                <option value={building.id}>{building.building_name}</option>
              ))}
            </select>
          </label> */}

          <label className={" block  "}>
            <span className="text-gray-400 text-xs">Unit</span>
            <select
              id="unit_id"
              onChange={this.handleInputChange}
              value={this.state.unit_id}
              className={
                "mt-0 mb-3 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              }
              placeholder=""
            >
              <option selected disabled hidden value="-1"></option>
              {this.state.unitNameList.map((unit_name: any[]) => (
                <option value={unit_name[0]}>{unit_name[2]}</option>
              ))}
            </select>
          </label>

          <label className={" block  "}>
            <span className="text-gray-400 text-xs">Cleaner</span>
            <select
              id="cleaner"
              onChange={this.handleInputChange}
              value={this.state.cleaner}
              className={
                "mt-0 mb-3 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              }
              placeholder=""
            >
              <option selected disabled hidden></option>
              {this.state.cleanerList.map((cleaner: any) => (
                <option value={cleaner.Username}>
                  {
                    // decode user_id to account names
                    cleaner.Attributes[2].Value
                  }
                </option>
              ))}
            </select>
          </label>

          <label className="block  mb-5 mt-3">
            <span className="text-gray-400 text-xs">Additional Notes</span>
            <textarea
              id="cleaning_notes"
              onChange={this.handleInputChange}
              value={this.state.cleaning_notes}
              className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={3}
            ></textarea>
          </label>
        </div>
      );
    } else {
      cleaningTypeInput = (
        <div className="grid grid-cols-1 gap-1 w-full my-5">
          <div className={" block mb-3 w-90"}>
            <label>
              <span className="text-gray-400 text-xs">Cleaning Types</span>
              <select
                id="cleaning_type"
                onChange={this.handleDropdownChange}
                // value={this.state.cleaning_type}
                className={
                  "mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                }
              >
                <option selected disabled>Please Select a Cleaning Type</option>
                <option>Other</option>
                <option>Touch Up</option>
                <option>Deep Clean Part One</option>
                <option>Deep Clean Part Two</option>
                <option>Inspection and Fix</option>
              </select>
            </label>
          </div>

          <div className="mb-3">
            <label className={" block  "}>
              <span className="text-gray-400 text-xs">Label</span>
              <input
                id="non_booking_label"
                onChange={this.handleInputChange}
                value={this.state.non_booking_label}
                type="text"
                className={
                  "mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                }
                placeholder=""
              />
            </label>
          </div>

          <label className={" block  "}>
            <span className="text-gray-400 text-xs">Cleaner</span>
            <select
              id="cleaner"
              onChange={this.handleInputChange}
              value={this.state.cleaner}
              className={
                "mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              }
              placeholder=""
            >
              <option selected disabled hidden></option>
              {this.state.cleanerList.map((cleaner: any) => (
                <option value={cleaner.Username}>
                  {
                    // decode user_id to account names
                    cleaner.Attributes[2].Value
                  }
                </option>
              ))}
            </select>

            <label className="block  mb-5 mt-6">
              <span className="text-gray-400 text-xs">Additional Notes</span>
              <textarea
                id="cleaning_notes"
                onChange={this.handleInputChange}
                value={this.state.cleaning_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>
          </label>
        </div>
      );
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-sm py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <FaRegCalendarPlus className="text-gray-600 text-2xl mb-3" />
          <span className="text-xl py-1 font-bold text-gray-800 text-left">
            New Cleaning For {formatFullDate(dateState)}
          </span>
          <span className="text-sm text-center text-gray-400">
            Remember to notify{" "}
            <strong className="text-gray-500">Payments</strong> if they need to
            add extra fees{" "}
          </span>
          <form className="w-full">
            <div className={" block mt-8 w-52  "}>
              <div className="flex flex-row items-center justify-between ">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Non-Booking Cleaning
                </span>

                <ToggleSwitch
                  id="non_booking_type"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.non_booking_type}
                />
              </div>
            </div>

            {cleaningTypeInput}

            <div className="flex flex-row justify-center w-full mt-6">
              <button
                onClick={this.createCleaning}
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewCleaning);
