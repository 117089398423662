import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Gerrard/Gerrard Parking.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Gerrard/Gerrard_Parking_Lot_77.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Gerrard/Gerrard_Parking_Space_SkyView.png";
import ParkingLogo from "../../../client-facing/img/ParkingPhotos/Gerrard/Gerrard_Parking_Spot_SkyView.png";
import ThirdParkingPhoto from "../../../client-facing/img/ParkingPhotos/Gerrard/99_Gerrard_Parking_Lot.png";
import FourthParkingPhoto from "../../../client-facing/img/ParkingPhotos/Gerrard/Gerrard_Elevator.png";

function GerrardParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-1/2">
          <p className="mt-3">
            99 and 77 Gerrard Street are two different buildings with a
            connected parking garage. There are 5 spots on P5 for our guests.
            Both towers are accessible from the same lot, and the spaces are
            right between the two banks of elevators.
          </p>
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3 mb-1">
            To access from the street, find the garage on Gerrard, just west of
            Elizabeth Street.
          </p>
          <div className="bg-green-400 text-white px-5 py-5">
            <p className="text-2xl mb-2">77 Gerrard Street</p>
            <div>
              <img
                src={FirstStreetView}
                alt="Google Maps Street View"
                className="h-96 mx-auto my-0"
              />
            </div>
            <p>
              The elevators to access 77 Gerrard are labelled 700 Bay St. The
              elevators go right up to the residential levels.
            </p>
          </div>
          <p className="mt-3 mb-1">
            You will not require a parking remote or an access card. All you
            need to do is park in the assigned Sky View Suites spots.
          </p>
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-1/2">
          <div className="bg-green-400 text-white px-5 py-5">
            <img
              src={SecondStreetView}
              alt="Google Maps Street View"
              className="w-full mb-5"
            />
            <div className="flex flex-row">
              <p className="basis-4/5">
                Once you enter, follow the ramps down to P5, and our 5 spaces
                are labeled with signs. There is a red fire hose box that acts
                as a nice visual reference point as well.
              </p>
              <img
                src={ParkingLogo}
                alt="Google Maps Street View"
                className="h-40"
              />
            </div>
          </div>
          <p className="text-2xl mb-2 mt-2 md:mt-0">99 Gerrard Street</p>
          <p className="mt-3 mb-1">
            The elevators to access 99 Gerrard Street are not labelled, but are
            about 50 paces away from the 77 Gerrard elevators. This elevator is
            fob specific, so if you are using the wrong elevator, you won’t be
            able to get in.
          </p>
          <div className="flex flex-col md:flex-row">
            <img
              src={ThirdParkingPhoto}
              alt="Google Maps Street View"
              className="h-52 pr-5 "
            />
            <img
              src={FourthParkingPhoto}
              alt="Google Maps Street View"
              className="h-52 md:mt-0 mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GerrardParking;
