import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import {useNotification} from "../Notifications/NotificationProvider"
import { MdCancel, MdPlaylistAdd } from "react-icons/md";
import { AiOutlineCheckSquare, AiOutlineDownload } from "react-icons/ai";
import Label from "../LabelWrap";

import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import Modal from "../Modal";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import ToolTipAlt from "../ToolTipAlt";
import { RiChatForwardLine } from "react-icons/ri";
import { formatStringDate } from "../../utils/DateFormat";

// import NewLead from "./NewLead";
// import CorporateLeads from "./CorporateLeads";
// import NewLead from './NewLead'

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  toggleLead: boolean;
  editToggle: boolean;
  newCorporate: boolean;
  modalOn: boolean; 
  modalID: number; 
  modalContent: string;
  extensions: any[];
  //this is for whether an extension is being rejected or confirmed
  modalConfirm: boolean;  
  rerender: boolean; 
};

class Extensions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "1234",
      check_in: "February 12th 2021",
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      toggleLead: false,
      editToggle: false,
      newCorporate: false,
      modalOn: false,
      modalID: -1, 
      modalContent: "",
      modalConfirm: true,
      rerender: false,
      extensions: [],
      };
  }

  setModalInfo = (extensionID: number, confirm: boolean) => {
    let content = ""; 
    if(confirm) {
      content = "Are you sure you would like to confirm this extension?"
    } else {
      content = "Are you sure you would like to reject this extension?"
    }
    this.setState({
      modalContent: content, 
      modalID: extensionID,
      modalOn: true,
      modalConfirm: confirm
    }); 

    //this.modalToggle(); 
  }

  modalToggle = () => {
    if(this.state.modalOn) {
      this.setState({
        modalOn: false
      });
    } else {
      let a = !this.state.rerender; 
      this.setState({
        modalOn: true,
        rerender: a
      }); 
    }
  }

  async confirmExtension(id: number) {
    console.log("ID " + id); 
    let res = await fetchRequestJSON("PUT", Constants.API_PATH_EXTENSION_CONFIRM, {id: id}); 
    console.log(res); 
    this.modalToggle(); 
  }

  async rejectExtension(id: number) {
    //this route will communicate with the back-end in order to reject the current extension
    let res = await fetchRequestJSON("DELETE", `${Constants.API_PATH_EXTENSION_REJECT}/${id}`, {id: id}); 

    this.modalToggle(); 
    window.location.reload();
  }

  handleLeadView = (state?: any) => {
    if (state && this.state.toggleLead === false) {
      this.setState({ toggleLead: true });
    } else {
      this.setState({ toggleLead: false });
    }
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };


  updateList = async () => {
    let extensionList = await fetchGetJSON(
      Constants.API_PATH_EXTENSION_GET_ALL_UNCONFIRMED
    );
    this.setState({ extensions: extensionList });
  };

  async acceptExtension(id: number) {
    //this route will communicate with the back-end in order to accept the current extension
    let res = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_EXTENSION_CONFIRM,
      { id: id }
    );
    console.log(res);
    this.updateList();
  }

  async declineExtension(id: number) {
    let res = await fetchRequestJSON(
      "DELETE",
      `${Constants.API_PATH_EXTENSION_REJECT}/${id}`,
      { id: id }
    );
    console.log(res);
    this.updateList();
  }
  componentDidMount = async () => {
    this.updateList();
  }

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let editFocus = "opacity-100";
    let bgFocus;

    let modal = <Modal yes={true} onoff={this.state.modalOn} title={"Extension Confirm"} content={this.state.modalContent} handleModalToggle={this.modalToggle} confirmButton={() => this.confirmExtension(this.state.modalID)}></Modal>;

    if(!this.state.modalConfirm) {
      modal = <Modal yes={true} onoff={this.state.modalOn} title={"Extension Confirm"} content={this.state.modalContent} handleModalToggle={this.modalToggle} confirmButton={() => this.rejectExtension(this.state.modalID)}></Modal>;
    }
    return (
    <div className=" font-display bg-f9 h-full lg:h-screen  lg:rounded-tl-lg pt-3 lg:pt-0 ">
      <div className="fade-in px-4 lg:px-10  ">
        <div className="  w-full">
          <div className="grid grid-cols-1 gap-4 w-full">
            <div className=" grid grid-cols-1 gap-4  px-1 py-0    ">
                <div className="m-5">
                  <div className="flex flex-row justify-between w-full  items-end">
                    <span className=" pl-1 text-gray-700 font-medium text-xl">
                      Pending Extensions:
                    </span>
                  </div>

                  <div className=" my-3 h-300 overflow-y-auto shadow-md rounded-lg  w-full scroll-hide ">
                    <table
                      style={{ borderSpacing: "0" }}
                      className="border-separate  relative w-full table-auto bg-white     "
                    >
                      <thead className="border-b border-gray-400 ">
                        <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Booking ID
                          </th>
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Tenant
                          </th>
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Unit
                          </th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Check Out
                          </th>
                          <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 "></th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Extension To{" "}
                          </th>
                          <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 bg-white z-50 "></th>
                        </tr>
                      </thead>

                      <tbody className="divide-y">
                        <ExtensionList
                          extensionList={this.state.extensions}
                          accept={this.acceptExtension}
                          decline={this.declineExtension}
                          updateList={this.updateList}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Extensions;

export function ExtensionList(props: any) {
  let rows = [];
  let Extension_List = <></>;
  let altBg = "";
  console.log(props.extensionList)
  for (let i = 0; i < props.extensionList.length; i++) {
    if (i % 2 != 1) {
      altBg = "bg-white";
    } else {
      altBg = "bg-white";
    }

    let index = i;

    //when linking to back end: remember to switch the key to the unique invoice id from the backend

    Extension_List = (
      <tr
        key={i.toString()}
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="border-b  px-5 py-10 font-thin w-28 text-green-600">
          {props.extensionList[i].booking_id}
        </td>
        <td className="border-b px-5 py-3 font-thin  w-80">
          <div className="grid grid-cols-1   ">
            <span className="truncate ">
              {props.extensionList[i].booking.tenant?.first_name} {props.extensionList[i].booking.tenant?.last_name}
            </span>
            <span className="text-gray-400 truncate ">
              {props.extensionList[i].booking.tenant?.email}
            </span>
          </div>
        </td>
        <td className="border-b  py-3 text-gray-400 font-thin  w-64">
          <span
            id="bookingConfirmed"
            className="cursor-pointer mr-2 bg-gray-700 text-white font-medium text-xs rounded-full py-1 inline-flex items-center text-center px-5"
          >
            {" "}
            {props.extensionList[i].booking.unit.suite_name}{" "}
          </span>
        </td>
        <td className="border-b  py-3  font-thin text-gray-500 w-40 ">
          {formatStringDate(props.extensionList[i].start)} 
        </td>
        <td className="border-b  py-3 w-20  font-thin text-green-300 text-3xl ">
          <RiChatForwardLine className="block m-auto" />
        </td>
        <td className=" border-b py-3  font-thin  text-blue-500 w-64 ">
          {formatStringDate(props.extensionList[i].extend_until)}
        </td>
        <td className="border-b px-8 py-4 w-32">
          <div className=" flex flex-row justify-between">
            <ToolTipAlt message="Deny Request" position="bottom">
              <MdCancel
                onClick={() => {
                  props.decline(props.extensionList[i].id);
                  props.updateList();
                }}
                className="text-3xl hover:opacity-50 cursor-pointer text-red-400 "
              />
            </ToolTipAlt>
            <ToolTipAlt message="Approve Extension" position="bottom">
              <AiOutlineCheckSquare
                onClick={() => {
                  props.accept(props.extensionList[i].id);
                  props.updateList();
                }}
                className="text-3xl hover:opacity-50 cursor-pointer text-green-600"
              />
            </ToolTipAlt>
          </div>
        </td>
      </tr>
    );

    rows.push(Extension_List);
  }

  return <>{rows}</>;
}


