import { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import { RiUser3Fill } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import {
  addDays,
  formatFullDate,
  formatStringDate,
} from "../../../utils/DateFormat";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";
import CancelModal from "../../Bookings/CancelModal";
import EditOptionPanel from "../../EditOptionPanel";
import EditInvoice from "../../Invoices/EditInvoice";
import SendInvoice from "../../Invoices/SendInvoice";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
// import { Auth } from "aws-amplify";
import { getGroupList, getUserList, } from "../../../api-requests/api-gateway";
import store from "../../../redux/store";
import { TiDelete } from "react-icons/ti";
import cleaningReducer from "../../../redux/cleaningReducer";
import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";
import { withHooksHOC } from "../../Notifications/NotificationProvider";
import Cleaners from "./Cleaners";
import dayjs from "../../../utils/Dayjs";

interface Props {
  handleModalToggle: any;
  onoff: boolean;
  update: any;
  cleanings: any;
  ErrorNote: any;
}

interface QuickViewModalState {
  onoff: boolean;
  cleaning_id: number;
  booking_id: number;
  title: string;
  cleaner: number;
  assigned: any[];
  // authId: string;

  cleanings: any[];
  unassigned: any[];
  allCleaners: any[];
  cleaning_notes: string;
  unit_id: number;
  cleaning_type: string;
}

class CleaningQuickView extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      onoff: this.props.onoff,
      // authId: "",
      cleaning_id: -1,
      booking_id: -1,
      unit_id: -1,
      title: "",
      cleaning_type: "",
      cleaner: -1,
      cleaning_notes: "",
      assigned: [],

      cleanings: [],
      unassigned: [],
      allCleaners: [],
    };
  }

  handleModal = async (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle();

      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  componentDidMount = async () => {
    this.fetchAll();
  };

  fetchAll = async () => {
    try {
      const allCleaners = await fetchGetJSON(`${Constants.API_PATH_CLEANER_GET_ALL}?active=true`);

      if (allCleaners.status === 'Success') {
        this.setState({ allCleaners: allCleaners.data });
      }
    } catch (e) {
      console.log(e);
      console.log(this.state.unassigned);
    }
  };

  // Update on different list entry clicks from list of invoices
  componentDidUpdate = async (previousProps: any) => {
    console.log("Clicking on different component update: ")
    const curId = store.getState().cleaningReducer.curCleaning;

    if (
      curId !== -1 &&
      previousProps !== this.props &&
      this.props.cleanings.length !== 0
    ) {
      let cleaning = this.props.cleanings.find((cleaning: any) => {
        return cleaning.id === curId;
      });

      if (cleaning) {
        let assignedCleanerIds: number[] = cleaning.cleaners?.map((cleaner: any) => cleaner.id);
        let unassignedCleaners = this.state.allCleaners?.filter((cleaner: any) => !assignedCleanerIds.includes(cleaner.id));

        this.setState({
          cleaning_id: curId,
          title: cleaning.title ? cleaning.title : "No Title Given",
          cleaning_type: cleaning.data.cleaning_type,
          booking_id: cleaning.data.booking_id,
          cleaning_notes: cleaning.data.notes ? cleaning.data.notes : "N/A",
          unit_id: cleaning.unit_id,
          assigned: cleaning.cleaners ?? [],
          unassigned: unassignedCleaners ?? []
        });
      } 
      else {
        console.log("Did not fill");
      }
    }
  };

  handleInputChange = async (event: any) => {
    event.preventDefault();

    const targetId = event.target.id; // 'cleaner'
    const targetValue: number = parseInt(event.target.value);
    const cleaner = this.state.unassigned.find((cleaner: any) => cleaner.id === targetValue);

    if (cleaner) {
      const stateObject: any = {
        [targetId]: cleaner.id
      };

      await this.setState(stateObject);
    }
  };

  handleDropdownChange = async (event: any) => {
    event.preventDefault();
    console.log(event);
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    await this.setState(stateObject);
  };

  handleInputChangeText = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDelete = async () => {
    let res = await fetchRequestJSON(
      "DELETE",
      Constants.API_PATH_CLEANING_DELETE + this.state.cleaning_id,
      {}
    );

    // const updateType = {
    //   event: res.status == "Success" ? "Deletion" : "Error",
    //   message: res.status == "Success" ? this.state.title : res.status,
    // };
    this.props.update("Deletion");
  };

  save = async () => {
    console.log("in the save");
    const curId = store.getState().cleaningReducer.curCleaning;

    if (this.state.cleaner !== -1) {
      let res = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_CLEANING_ASSIGN_CLEANER,
        {
          cleaner_id: this.state.cleaner,
          cleaning_id: curId,
        }
      );
    }

    let res2 = await fetchRequestJSON(
      "PUT",
      `${Constants.API_PATH_CLEANING_EDIT}`,
      {
        id: curId,
        fields: {
          notes: this.state.cleaning_notes,
          title: this.state.title,
          cleaning_type: this.state.cleaning_type
        }
      }
    );
    console.log(res2);
    // this.setState({ assigned: [...this.state.assigned, curCleaner[0]] });

    const NotificationHandler = this.props.ErrorNote;
    NotificationHandler(
      "Success",
      "A Cleaning has been edited, please wait to see changes or refresh the page"
    );

    this.props.update();

    //When the prop gets updated, we should set all types to default
    this.setState({
      cleaner: -1,
    })
  };

  handleDeleteCleaner = async (cleaner: string) => {
    const curId = store.getState().cleaningReducer.curCleaning;
    console.log(curId);
    console.log(cleaner);
    let res = await fetchRequestJSON(
      "DELETE",
      `${Constants.API_PATH_CLEANING_UNASSIGN_CLEANER}${curId}/${cleaner}`,
      {}
    );
    this.props.update();
    const NotificationHandler = this.props.ErrorNote;
    NotificationHandler("Success", `Deleted Cleaner from Cleaning ${curId}`);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }
    if (this.props.onoff && this.props.cleanings.length == 0) {
      this.handleModal({ target: { id: "cancel" } });
    }

    // let editFocus = "opacity-100"
    let bgFocus;
    let paidStamp = <></>;
    let editFocus = "opacity-100";

    // if (this.state.editToggle === true) {
    //   editFocus = "opacity-100";
    //   bgFocus = (
    //     <div
    //       onClick={this.handleEditBarToggle}
    //       className=" fade-bg w-full h-screen bg-black z-40  fixed"
    //     ></div>
    //   );
    // }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed  flex flex-col items-center w-full h-full bg-gray-600 bg-opacity-40 z-50   "
        }
      >
        {/*
        <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("toggleCancel", this.state.toggleCancel)
          }
          onoff={this.state.toggleCancel}
          title={"Cancel Invoice"}
        />
        */}
        {bgFocus}

        <div className="appear bg-white sm:my-10 w-full overflow-y-auto shadow-md sm:rounded-lg pb-10  max-w-3xl  scroll-hide ">
          <div className="w-full bg-gray-100 pt-4 pb-2  px-4 sm:px-8 rounded-t-lg ">
            <div className="flex flex-col sm:flex-row my-1  justify-between sm:items-center">
              <IoIosArrowBack
                id="cancel"
                onClick={this.handleModal}
                className=" lg:hidden  text-2xl transform -translate-x-2 -translate-y-1 mb-3 hover:text-gray-300"
              />
              <span className="text-xl font-bold text-gray-800">
                Cleaning ID #{this.state.cleaning_id}
              </span>
            </div>
            <div className="flex flex-col sm:flex-row my-1  justify-between sm:items-center">
              {this.state.booking_id != -1 && (
                <span className="text-xs font-bold text-gray-400 mr-3">
                  Booking ID #{this.state.booking_id}
                  <span className="ml-3">Unit ID #{this.state.unit_id}</span>
                </span>
              )}
            </div>
          </div>

          <div className="w-full  pt-6 px-4 sm:px-8">
            <div className="w-full flex flex-row font-sofia justify-between">
              <div className=" flex flex-col w-full">
                <span className="text-4xl font-extrabold text-gray-600 ">
                  {this.state.title}
                </span>
                <span className="text-lg font-base text-gray-400 uppercase mb-2 mt-6">
                  Type:{" "}
                </span>
                {this.state.cleaning_type}
                <span className="text-lg font-base text-gray-400 mb-2 mt-6 w-1/2">
                  Edit Type:
                </span>
                <div className="">
                  <select
                    id="cleaning_type"
                    onChange={this.handleDropdownChange}
                    // value={this.state.cleaning_type}
                    className={
                      "mt-0 py-1 block w-1/2 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    }
                  >
                    <option selected disabled className="text-gray-400">
                      Please Select a Cleaning Type
                    </option>
                    <option>Turnover</option>
                    <option>Regular</option>
                    <option>Touch Up</option>
                    <option>New Unit</option>
                    <option>Deep Clean Part One</option>
                    <option>Deep Clean Part Two</option>
                    <option>Inspection and Fix</option>
                    <option>Other</option>
                  </select>
                </div>

                <div className="flex flex-col mt-4 w-full">
                  <span className="text-lg font-base text-gray-400 mb-2 mt-6 w-full">
                    Assigned Cleaner IDs:
                  </span>
                  <span className="text-md font-base text-gray-800 mb-2 w-full">
                    {this.state.assigned.map((assign: any) => {
                      return (
                        <div className="flex content-center">
                          <TiDelete
                            onClick={() => {
                              this.handleDeleteCleaner(assign.id);
                            }}
                            className=" text-red-500 hover:text-red-400 text-2xl mr-1 self-center"
                          />
                          {assign.first_name} {assign.last_name}
                          <br />
                        </div>
                      );
                    })}
                  </span>

                  <span className="text-lg font-base text-gray-400 mb-2 mt-6 w-full">
                    Edit Title:
                  </span>
                  <div className="">
                    <input
                      id="title"
                      onChange={this.handleInputChangeText}
                      defaultValue={this.state.title}
                      key={this.state.cleaning_id}
                      className={
                        "mt-0 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black w-96"
                      }
                      // placeholder={this.state.title}
                    ></input>
                  </div>

                  <span className="text-lg font-base text-gray-400 mb-2 mt-6 w-full">
                    Add Cleaner:
                  </span>
                  <div className="">
                    <select
                      id="cleaner"
                      onChange={this.handleInputChange}
                      value={this.state.cleaner}
                      className={
                        "mt-0 py-1 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black w-96"
                      }
                      placeholder=""
                    >
                      <option selected disabled value={-1}></option>
                      {this.state.unassigned.map((user: any) => (
                        <option value={user.id}>{`${user.first_name} ${user.last_name}`}</option>
                      ))}
                    </select>

                    <label className="block mb-5 mt-3">
                      <span className="text-lg font-base text-gray-400 mb-2 mt-6 w-full">
                        Additional Notes:
                      </span>
                      <textarea
                        id="cleaning_notes"
                        onChange={this.handleInputChangeText}
                        value={this.state.cleaning_notes}
                        className="mt-4 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows={3}
                      ></textarea>
                    </label>
                  </div>
                </div>
                <div className="flex justify-end mt-4 w-full">
                  <button
                    id="cancel"
                    onClick={this.handleDelete}
                    className="transition duration-200 w-48 hover:text-green-400 rounded-sm text-green "
                  >
                    Delete Cleaning
                  </button>

                  <button
                    id="addCleaner"
                    onClick={this.save}
                    className=" bg-green-500 hover:bg-green-700 text-white font-bold rounded ml-4 h-auto p-2 pl-4 pr-4 w-max"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(CleaningQuickView);
