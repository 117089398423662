import { Component } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { FiRepeat } from "react-icons/fi";
import {
    fetchGetJSON,
    fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import Modal from "../Modal";

type SwapData = {
    first_booking_id: number | string;
    first_check_in: Date;
    first_check_out: Date,
    first_unit_name: string;
    second_booking_id: number;
    second_unit_name: string;
    second_check_in: Date;
    second_check_out: Date;
    swap_start_date: any;
};

interface Props {
    title: string;
    handleModalToggle: any;
    onoff: boolean;
    ErrorNote: any;
    swapData: SwapData
    multipleData?: Array<any>;
    errorMessage: string;
    handleMultipleToggle: any;
}
interface ModalState {
    title: string;
    onoff: boolean;
    swapOnOff: boolean;
    transfer_date: any;
    building: string;
    building_id: string;
    unit: string;
    buildingsList: any;

    new_rate: number;
}

type Building = {
    id: string;
    name: string;
    units: string[];
}

class SwapModal extends Component<Props, ModalState> {

    constructor(props: any) {
        super(props);
        this.state = {
            swapOnOff: false,
            title: this.props.title,
            onoff: this.props.onoff,
            transfer_date: "",
            building: "",
            building_id: "",
            unit: "",
            buildingsList: [],
            new_rate: 0,
        };
    }

    componentDidMount = () => {
    }

    handleModal = (event: any) => {
        if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
            this.props.handleModalToggle(this.props.onoff);
            this.setState({ onoff: !this.state.onoff })
        }
    };

    handleSwapModal = (event: any) => {
        this.setState({ swapOnOff: !this.state.swapOnOff })

    };

    confirmSwapModal = (event: any) => {
        console.log("click!")
    };

    transferBooking = async (transferBody: any) => {
        return await fetchRequestJSON(
            "PUT",
            Constants.API_PATH_BOOKING_TRANSFER,
            transferBody
        );
    };

    // Find proper unit and building to transfer booking
    handleClick = async () => {
        const data: SwapData = this.props.swapData
        let NotificationHandler = this.props.ErrorNote
        const bookingId = Number(new URLSearchParams(window.location.search).get("id"));
        const allUnits = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
        const allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);

        const unitData = allUnits.data.find((unitItem: any) => unitItem.suite_name === this.state.unit);
        const buildingData = allBuildings.data.find((buildingItem: any) => buildingItem.building_name === this.state.building);

        if (!data) {
            NotificationHandler("Error", "Bookings data was not found");
            return;
        }

        const swapStatus = await fetchRequestJSON(
            "PUT",
            Constants.API_PATH_BOOKING_SWAP, {
            first_id: data.first_booking_id,
            second_id: data.second_booking_id,
        }
        );

        if (swapStatus.status) {
            if (swapStatus.status == "Success") {
                NotificationHandler("Success", `Successfully swapped Booking ${data.first_booking_id} and ${data.second_booking_id}`);
            }
            else if (swapStatus.status == "Multiple Bookings Error") {
                const swapData = swapStatus.data
                const message = "The current booking has conflicts with more than one booking. Swapping bookings is not eligible with more than one booking conflict"
                NotificationHandler("Info", swapStatus.status);
                this.props.handleMultipleToggle(swapData.firstUnitBookings, swapData.firstUnitName, message, swapData.secondUnitName)
                this.setState({ swapOnOff: false })
            }
            else {
                NotificationHandler("Error", swapStatus.status);
                this.setState({ swapOnOff: true })
            }
        }
    };

    // TODO: Refactor this function so that it doesn't use bind().
    handleInputChange = (event: any) => {
        event.preventDefault();
        let stateObject = function (this: typeof event) {
            let returnObj: any = {};
            returnObj[this.target.id] = this.target.value;
            return returnObj;
        }.bind(event)();
        this.setState(stateObject);
        if (event.target.id == "building") {
            this.setState({
                building_id: this.state.buildingsList.find((building: Building) => building.name == event.target.value)?.id
            });
        }
    };

    // TODO: Refactor this function so that it doesn't use bind().
    handleDateChange = (event: any) => {
        event.preventDefault();
        let stateObject = function (this: typeof event) {
            let returnObj: any = {};
            let dateState;
            if (this.target.value === "") {
                dateState = "";
            } else {
                let toDate = Date.parse(this.target.value);
                dateState = new Date(toDate);
                dateState.setTime(
                    dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
                );
            }
            returnObj[this.target.id] = dateState;
            return returnObj;
        }.bind(event)();
        this.setState(stateObject);
    };

    render() {
        const isMultiple = this.props.multipleData.length > 0
        if (!this.props.onoff) {
            return null;
        }
        const data: SwapData = this.props.swapData

        let swapModal = <></>;
        if (this.state.swapOnOff) {
            swapModal = (
                <Modal
                    title="Delete Booking"
                    content="Would you like to delete this booking?"
                    onoff={this.state.swapOnOff}
                    handleModalToggle={this.handleSwapModal}
                    confirmButton={this.confirmSwapModal}
                ></Modal>
            );
        }

        let inactive = "";
        let availableUnits = [];
        let buildingList = {};
        if (this.state.building === "") {
            inactive = "opacity-50 pointer-events-none ";
        } else {
            availableUnits = this.state.buildingsList.find((building: any) => building.id == this.state.building_id)?.units
        }

        return (
            <div
                id="outsideDiv"
                onClick={this.handleModal}
                className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
            >
                {swapModal}
                <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
                    {!isMultiple && <FiRepeat className="text-gray-600 text-3xl mb-3" />}
                    <span className="text-xl font-bold text-gray-800 text-left">{isMultiple ? "Booking conflicts" : this.state.title}</span>
                    <span className="text-xs text-gray-800">{this.props.errorMessage}</span>
                    <form>
                        {!isMultiple ?
                            // If it's swap eligible
                            <div className="flex flex-row gap-40 w-full my-5 ml-10">
                            <label className="flex flex-col space-y-5 ">
                                <span className="text-gray-800 text-m font-bold">First Booking Id: {data.first_booking_id}</span>
                                <div className="text-gray-400 text-xs">Check in: <span className="text-gray-800 text-xs">{data.first_check_in}</span></div>
                                <div className="text-gray-400 text-xs">Check out: <span className="text-gray-800 text-xs">{data.first_check_out}</span></div>
                                <div className="text-gray-400 text-xs">Unit Name: <span className="text-gray-800 text-xs">{data.first_unit_name}</span></div>
                            </label>

                            <label className="flex flex-col space-y-5 ">
                                <span className="text-gray-800 text-m font-bold">Second Booking Id: {data.second_booking_id}</span>
                                <div className="text-gray-400 text-xs">Check in: <span className="text-gray-800 text-xs">{data.second_check_in}</span></div>
                                <div className="text-gray-400 text-xs">Check out: <span className="text-gray-800 text-xs">{data.second_check_out}</span></div>
                                <div className="text-gray-400 text-xs">Unit Name: <span className="text-gray-800 text-xs">{data.second_unit_name}</span></div>
                            </label>
                            </div > :
                            // If it's conflicitng multiple bookings
                            <div className="flex flex-row gap-10 w-full my-5 justify-center">
                                {this.props.multipleData.map((booking, index) => {
                                    return (
                                        <label className="flex flex-col space-y-5 ">
                                            <span className="text-gray-800 text-m font-bold">{index == 0 ? "Current " : ""}Booking Id: {booking.id}</span>
                                            <div className="text-gray-400 text-xs">Check in: <span className="text-gray-800 text-xs">{booking.check_in}</span></div>
                                            <div className="text-gray-400 text-xs">Check out: <span className="text-gray-800 text-xs">{booking.check_out}</span></div>
                                            <div className="text-gray-400 text-xs">Unit Name: <span className="text-gray-800 text-xs">{booking.unit_name}</span></div>
                                        </label>
                                    )
                                })}
                            </div >
                        }
                        <div className="flex flex-row justify-start w-full mt-6">
                            <button
                                onClick={() => {
                                    this.setState({ swapOnOff: true })
                                    this.props.handleModalToggle();
                                    if (!isMultiple) {
                                        this.handleClick();
                                    }
                                }}
                                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                            >
                                {isMultiple ? "Ok" : "Submit"}
                            </button>
                        </div>
                    </form >
                </div >
            </div >
        );
    }
}

export default withHooksHOC(SwapModal);
