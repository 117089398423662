import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { SetStateAction, useEffect, useState } from "react";
import store from "../../../../redux/store";
import { useNotification } from "../../../Notifications/NotificationProvider";

type Props = {
    title: string;
    handleModalToggle: any;
    onoff: boolean;
    id: number;
}

const ParkingSpotActiveModal = (props: Props) => {
    const [parkingSpotID, setParkingSpotID] = useState(props.id);
    const [parking, setParking] = useState([]); //This is only used to hold overlapping parking spots if deactivation fails
    const [onOff, setOnOff] = useState(props.onoff)

    //Notificatoin Handler Logic:
    const dispatch = useNotification();

    const HandleError = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------


    const handleModal = (event: any) => {
        if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
          props.handleModalToggle(props.onoff);
          setOnOff(!onOff);
          setParking([]);
        }
    };

    const handleClick = async(event:any) => {
        event.preventDefault();
        HandleError('Loading', 'Pending', `Deactivating Parking Spot ${props.id}`)
        const response = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_SPOT_DEACTIVATION,{
            parking_spot_id: props.id
        })

        if(response.status == "Success") {
            //Indicate notification handler than close the modal 
            HandleError('Success', 'Success', `Parking ID ${props.id} succesfully deactivated`)
            //Redux below:
            let update = store.getState().parkingSpotReducer.listUpdate;
            update()
            props.handleModalToggle();
        } else {
            if(response.status == "Error") {
              setParking(response.data)
            }
            HandleError('Error', 'Error', 'There are active or upcoming parkings occupying the parking spot. Please remove them in order for deactivation to proceed.')
        }
    }


    return(
        <div
        id="outsideDiv"
        onClick={handleModal}
        className={`fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 
        ${ !props.onoff ? "hidden" : "" }`
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          {/* <IoIosArrowBack className="text-white text-3xl absolute transform -translate-x-28 -translate-y-6" /> */}
          {/* <FcCancel className="text-red-400 text-3xl mb-3" /> */}

          <span className="text-xl font-bold text-gray-800 text-left">
            {props.title}
          </span>
          <span className="text-xl text-red-400 my-3">PLEASE NOTE: This Parking Spot will not be able to be reactivated after deactivation. If you need to reactivate, you must recreate a new parking spot.</span>
          {/* The below is to return which Parking Spots Overlap with Deactivation, for now it says bookings since it was taken from the previous ActiveModal in Units: */}
          {
            <>
              <span className="text-xl text-green-400">{parking.length ? `Upcoming or Ongoing Bookings that are preventing deactivation: ` : ''}</span>
              <div className="flex flex-row md:flex-col w-full h-full mb-3 justify-around sm:flex-col flex-wrap">
                {parking.map((parking) => (
                  <>
                    <span><strong>Parking #ID: {parking.id}</strong></span>
                    <span>Start Date: {parking.start_date}</span>
                    <span>End Date: {parking.end_date}</span>
                    <br/>
                  </>
                ))}
              </div>
            </>
          }
          <button className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded" onClick={(event) => handleClick(event)}> 
            Toggle Inactivation
          </button>
        </div>
      </div>
    )
}

export default ParkingSpotActiveModal