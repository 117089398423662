import { Component } from "react";
import { formatFullDateString } from "../utils/DateFormat";

type Props = {
  data: any;
  handleInputChange: any;
  dayCount: number;
};

interface DatePickerState {
  date_focus: boolean;
  checkin: string;
  checkout: string;
}

class DatePicker extends Component<Props, DatePickerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      date_focus: false,
      checkin: this.props.data.check_in,
      checkout: this.props.data.check_out,
    };
  }

  handleFocusChange = (event: any) => {
    event.preventDefault();
    let hasFocus = document.getElementById("checkout_btn");
    var isFocused = document.activeElement === hasFocus;

    if (isFocused === true) {
      this.setState({
        date_focus: true,
      });
    } else {
      this.setState({
        date_focus: false,
      });
    }
  };

  render() {
    let hasFocus = this.state.date_focus;
    let checkOutZindex = "z-20 ";

    if (hasFocus === true) {
      checkOutZindex = "z-50 ";
    } else {
      checkOutZindex = "z-20 ";
    }

    let DaysHeader;
    let today = new Date();
    let formatted_date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let Days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    let DayRow = [];

    for (let i = 0; i < 7; i++) {
      DaysHeader = (
        <span className="text-gray-300 text-sm text-normal"> {Days[i]}</span>
      );
      DayRow.push(DaysHeader);
    }

    let dayCount = this.props.dayCount;
    let dayCountStyle = "";
    let nights = "Nights";

    if (Number.isNaN(dayCount)) {
      dayCount = -1;
    }

    if (dayCount < 0) {
      dayCountStyle = "opacity-0";
      nights = "-";
    }

    if (dayCount == 1) {
      nights = "Night";
    }

    return (
      <div
        key={this.props.data}
        className="flex flex-col border   border-solid border-gray-200  w-7/12 h-auto rounded-md py-4 pl-4 pr-14"
      >
        <div className="flex flex-row ">
          <div className="flex flex-row items-center ">
            <label className="w-36 z-30 ">
              <span className="font-display absolute  font-medium text-gray-600 text-sm py-1 px-3 text-left z-50">
                Check In
              </span>
              <input
                onFocus={this.handleFocusChange}
                onChange={this.props.handleInputChange}
                id="check_in"
                value={this.props.data.check_in}
                type="date"
                className={
                  " transition-all duration-300 pt-6  font-display font-thin text-gray-500 text-sm shadow-sm  border focus:border-2  focus:bg-gray-50  border-solid border-gray-300 focus:border-gray-500 w-36 bg-white   rounded-md py-1 px-3"
                }
              />
            </label>

            <label className={checkOutZindex + " w-36 "}>
              <span className="font-display absolute  font-medium text-gray-600 text-sm   py-1 px-2 text-left z-50">
                Check Out
              </span>
              <input
                onFocus={this.handleFocusChange}
                onChange={this.props.handleInputChange}
                type="date"
                value={this.props.data.check_out}
                id="check_out"
                className={
                  "  transition-all duration-300 pt-6  font-display font-thin text-gray-500 text-sm shadow-sm border focus:border-2 focus:bg-gray-50 border-solid border-gray-300 focus:border-gray-500 w-36 bg-white transform -translate-x-2  rounded-md py-1 px-4"
                }
              />
            </label>

            <div className={" flex flex-col px-4 "}>
              <span className={" font-medium text-lg text-gray-700  "}>
                {" "}
                <span className={dayCountStyle}>{dayCount}</span> {nights}
              </span>
              <span className={" font-thin text-sm text-gray-300  "}>
                {" "}
                {formatFullDateString(this.props.data.check_in, "short")} -{" "}
                {formatFullDateString(this.props.data.check_out, "short")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DatePicker;
