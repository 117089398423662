import { Component } from "react";
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";
import { fetchGetJSON } from "../api-requests/apiCallHelper";
import { Constants } from "../api-requests/apiLinkConstants";
import { addDays } from "../utils/DateFormat";
import { StatsSquare } from "./StatsSquare";

type Props = {
  StatsType: string;
};

type StatsBarState = {
  booking_id: string;
  corporate_id: string;
  tenant_name: string;
  booking_total: string;

  // check ins page
  this_week_check_ins: number;
  last_week_check_ins: number;
  this_week_check_outs: number;
  last_week_check_outs: number;
  unconfirmed_check_ins: number;
  this_month_check_ins: number;

  // bookings page
  this_month_bookings: number;
  last_month_bookings: number;
  active_bookings: number;
};

class StatsBar extends Component<Props, StatsBarState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "",
      corporate_id: "",
      tenant_name: "",
      booking_total: "",

      // check ins page
      this_week_check_ins: 0,
      last_week_check_ins: 0,
      this_week_check_outs: 0,
      last_week_check_outs: 0,
      unconfirmed_check_ins: 0,
      this_month_check_ins: 0,

      // bookings page
      this_month_bookings: 0,
      last_month_bookings: 0,
      active_bookings: 0,
    };
  }

  getFirstDayOfLastMonth(today: Date) {
    let last_month: number = today.getMonth();
    let last_month_year = today.getFullYear();
    if (last_month === 0) {
      last_month = 12;
      last_month_year = last_month_year - 1;
    }
    let last_month_string: string = last_month.toString();
    if (last_month_string.length < 2) {
      last_month_string = "0" + last_month_string;
    }
    return new Date(last_month_year + "-" + last_month_string + "-" + "02");
  }

  getFirstDayOfThisMonth(today: Date) {
    const this_month = today.getMonth();
    let this_month_year = today.getFullYear();
    let this_month_string: string = (this_month + 1).toString();
    if (this_month_string.length < 2) {
      this_month_string = "0" + this_month_string;
    }
    return new Date(this_month_year + "-" + this_month_string + "-" + "02");
  }

  isThisWeekCheckIn(today: Date, check_in_date: Date) {
    return addDays(today, -7) < check_in_date && check_in_date <= today;
  }

  isLastWeekCheckIn(today: Date, check_in_date: Date) {
    return addDays(today, -14) < check_in_date && check_in_date <= addDays(today, -7);
  }

  isThisWeekCheckOut(today: Date, check_out_date: Date) {
    return addDays(today, -7) < check_out_date && check_out_date <= today;
  }

  isLastWeekCheckOut(today: Date, check_out_date: Date) {
    return addDays(today, -14) < check_out_date && check_out_date <= addDays(today, -7);
  }

  isUnconfirmedCheckIn(booking: any, today: Date, this_check_in_date: Date) {
    return today <= this_check_in_date && !booking.confirmed;
  }

  isThisMonthCheckIn(this_check_in_date: Date, first_day_of_this_month: Date) {
    return this_check_in_date.getMonth() === first_day_of_this_month.getMonth() &&
      this_check_in_date.getFullYear() === first_day_of_this_month.getFullYear()
  }

  isThisMonthBooking(this_check_in_date: Date, this_check_out_date: Date, first_day_of_this_month: Date) {
    return (this_check_in_date <= first_day_of_this_month && first_day_of_this_month <= this_check_out_date) ||
      (this_check_in_date.getMonth() === first_day_of_this_month.getMonth() && this_check_in_date.getFullYear() === first_day_of_this_month.getFullYear());
  }

  isLastMonthBooking(this_check_in_date: Date, this_check_out_date: Date, first_day_of_last_month: Date) {
    return (this_check_in_date <= first_day_of_last_month && first_day_of_last_month <= this_check_out_date) ||
      (this_check_in_date.getMonth() === first_day_of_last_month.getMonth() && this_check_in_date.getFullYear() === first_day_of_last_month.getFullYear())
  }

  componentDidMount = async () => {
    const allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_STATBAR);
    if (allBookings.status !== "Success") {
      return;
    }

    let thisWeekCheckIns = 0;
    let lastWeekCheckIns = 0;
    let thisWeekCheckOuts = 0;
    let lastWeekCheckOuts = 0;
    let unconfirmedCheckIns = 0;
    let thisMonthCheckIns = 0;
    let thisMonthBookings = 0;
    let lastMonthBookings = 0;
    let activeBookings = 0;

    const today = new Date();
    let firstDayOfThisMonth = this.getFirstDayOfThisMonth(today);
    let firstDayOfLastMonth = this.getFirstDayOfLastMonth(today);

    for (let booking of allBookings.data) {
      if (booking.cancelled) {
        continue;
      }

      //have to add one day to the dates because of the way typescript creates dates from strings
      // it has been creating a date one day before the actual date in the string
      let checkInDate = new Date(booking.check_in);
      checkInDate = addDays(checkInDate, 1);

      let checkOutDate = new Date(booking.check_out);
      checkOutDate = addDays(checkOutDate, 1);

      activeBookings++;

      if (this.isThisWeekCheckIn(today, checkInDate)) {
        thisWeekCheckIns++;
      }

      if (this.isLastWeekCheckIn(today, checkInDate)) {
        lastWeekCheckIns++;
      }

      if (this.isThisWeekCheckOut(today, checkOutDate)) {
        thisWeekCheckOuts++;
      }

      if (this.isLastWeekCheckOut(today, checkOutDate)) {
        lastWeekCheckOuts++;
      }

      if (this.isUnconfirmedCheckIn(booking, today, checkInDate)) {
        unconfirmedCheckIns++;
      }

      if (this.isThisMonthCheckIn(checkInDate, firstDayOfThisMonth)) {
        thisMonthCheckIns++;
      }

      if (this.isThisMonthBooking(checkInDate, checkOutDate, firstDayOfThisMonth)) {
        thisMonthBookings++;
      }

      if (this.isLastMonthBooking(checkInDate, checkOutDate, firstDayOfLastMonth)) {
        lastMonthBookings++;
      }
    }

    this.setState({
      this_week_check_ins: thisWeekCheckIns,
      last_week_check_ins: lastWeekCheckIns,
      this_week_check_outs: thisWeekCheckOuts,
      last_week_check_outs: lastWeekCheckOuts,
      unconfirmed_check_ins: unconfirmedCheckIns,
      this_month_check_ins: thisMonthCheckIns,
      this_month_bookings: thisMonthBookings,
      last_month_bookings: lastMonthBookings,
      active_bookings: activeBookings,
    });
  };

  displayStatTrajectoryArrow = (thisWeekStat: Number, lastWeekStat: Number) => {
    if (thisWeekStat >= lastWeekStat) {
      return <AiOutlineRise className="text-green-500 text-lg mr-1" />;
    } else {
      return <AiOutlineFall className="text-red-500 text-lg mr-1" />;
    }
  }

  render() {
    let StatType = this.props.StatsType;

    let Stats;
    const thisMonth = new Date().toLocaleString('default', {month: 'long'});
    let today = new Date();
    const lastMonth = new Date(today.setMonth(today.getMonth() - 1)).toLocaleString('default', {month: 'long'});

    if (StatType === "CheckIns") {
      Stats = [
        <StatsSquare
          data={this.state.this_week_check_ins}
          title="Check Ins This Week"
          subInfo={
            <span className="text-gray-400 text-sm font-thin font-europe flex flex-row items-center">
              {this.displayStatTrajectoryArrow(this.state.this_week_check_ins, this.state.last_week_check_ins)}
              Last Week: {this.state.last_week_check_ins}
            </span>
          }
        />,

        <StatsSquare
          data={this.state.this_week_check_outs}
          title="Check Outs This Week"
          subInfo={
            <span className="text-gray-400 text-sm font-thin font-europe flex flex-row items-center">
              {this.displayStatTrajectoryArrow(this.state.this_week_check_outs, this.state.last_week_check_outs)}
              Last Week: {this.state.last_week_check_outs}
            </span>
          }
        />,

        <StatsSquare
          data={this.state.unconfirmed_check_ins}
          title="Unconfirmed Check Ins"
          subInfo = {
            <a className="text-gray-700 text-sm font-thin font-europe flex flex-row items-center">
              For Bookings from {lastMonth} to {thisMonth}
            </a>
          }
        />,

        <StatsSquare
          data={this.state.this_month_check_ins}
          title="Check Ins This Month"
          subInfo={
            <a className="text-gray-700 underline cursor-pointer hover:opacity-70 text-sm font-thin font-europe flex flex-row items-center">
              View All Active Bookings
            </a>
          }
        />
      ];
    }

    // if (StatType === "Bookings") {
    //   Stats = [
    //     <StatsSquare
    //       data={this.state.this_month_bookings}
    //       title="Bookings This Month"
    //       subInfo={
    //         <span className="text-gray-400 text-sm font-thin font-europe flex flex-row items-center">
    //           {this.displayStatTrajectoryArrow(this.state.this_month_bookings, this.state.last_month_bookings)}
    //           Last Month: {this.state.last_month_bookings}
    //         </span>
    //       }
    //     />,

    //     <StatsSquare
    //       data={this.state.active_bookings}
    //       title="Active Bookings"
    //       subInfo={
    //         <a className="text-gray-400 underline cursor-pointer hover:opacity-70 text-sm font-thin font-europe flex flex-row items-center">
    //           View All Active Bookings
    //         </a>
    //       }
    //     />,

    //     <StatsSquare
    //       data={this.state.unconfirmed_check_ins}
    //       title="Unconfirmed Check Ins"
    //     />,

    //     <StatsSquare
    //       data={this.state.this_month_check_ins}
    //       title="Check Ins This Month"
    //       subInfo={
    //         <a className="text-gray-400 underline cursor-pointer hover:opacity-70 text-sm font-thin font-europe flex flex-row items-center">
    //           View All Active Bookings
    //         </a>
    //       }
    //     />
    //   ];
    // }

    return (
      <div className=" font-display lg:contents hidden ">
        <div className="grid grid-cols-4 mt-8 mb-0 gap-6 mx-auto max-w-6xl">
          {Stats}
        </div>
      </div>
    );
  }
}

export default StatsBar;
