import { Component } from "react";
import { VscCollapseAll } from "react-icons/vsc";
import { PaymentsToApprove } from "../Sales/SalesDash";

// Takes Booking ID Prop and pull all info

interface Props {
  handleModalToggle: any;
  InvoiceQuickView: any;
  onoff: boolean;
}

interface QuickViewModalState {
  onoff: boolean;

  corporate: boolean;
  booking_id: number;
  corporate_id: number;
  corporate_name: string;
  tenant_name: string;
  check_in: Date;
  check_out: Date;
  suite: string;
  editToggle: boolean;
}

class PaymentsQuickView extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      onoff: this.props.onoff,
      corporate: true,
      booking_id: 1234,
      corporate_id: 12342,
      corporate_name: "Sky View Suites",
      tenant_name: "Riley Densmore",
      check_in: new Date(2020, 3, 8, 0, 0, 0, 0),
      check_out: new Date(2020, 4, 5, 0, 0, 0, 0),
      suite: "University Plaza A",
      editToggle: false,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    let bgFocus;

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed  grid grid-cols-12 gap-10 w-full h-full bg-gray-600 bg-opacity-40 z-40   "
        }
      >
        {bgFocus}

        {/* <div className=" appear font-sofia bg-white shadow-lg rounded-lg h-auto   ml-36  flex flex-col "> */}

        <div className="appear my-10 ml-12  w-auto overflow-y-auto shadow-md rounded-lg col-span-8  scroll-hide ">
          <table
            style={{ borderSpacing: "0" }}
            className="  border-separate  relative w-full table-auto bg-white     "
          >
            <thead className="border-b border-gray-400 ">
              <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                  ID
                </th>
                <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                  Unit
                </th>
                <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                  Tenant
                </th>
                <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                  Marked By
                </th>
                <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                  Payment Method
                </th>
                <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 "></th>
                <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 "></th>
                <th className="sticky top-0 text-right   py-3 font-thin border-b border-gray-400  ">
                  <button className="mr-3" onClick={this.handleModal}>
                    <VscCollapseAll
                      id="cancel"
                      className="text-xl text-right"
                    />
                  </button>{" "}
                </th>
              </tr>
            </thead>

            <tbody className="divide-y">
              <PaymentsToApprove
                length={24}
                viewInvoice={this.props.InvoiceQuickView}
              />
            </tbody>
          </table>
        </div>

        <div className=" appear font-sofia bg-white shadow-lg rounded-lg h-auto col-span-3  flex flex-col my-10 overflow-y-auto scroll-hide">
          <div className="w-full  pt-4 pb-3 px-8 rounded-t-lg">
            <div className="flex flex-col text-gray-700">
              <span className="text-xl mb-3">Booking Number #0000</span>
              <span className="text-md font-display mb-1">Corporate: None</span>
              <span className="text-md font-display mb-1">
                Tenant Name: None
              </span>
              <span className="text-sm font-display text-gray-400 mb-1">
                Check In:{" "}
              </span>
              <span className="text-sm font-display text-gray-400 mb-1">
                Check Out:{" "}
              </span>
              <button className="text-sm font-display text-gray-400 mb-1 h-10 text-gray-700 hover:bg-gray-100 my-3 rounded-sm border-2 border-gray-400">
                View Full Booking
              </button>
              <InvoiceList length={12} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentsQuickView;

function InvoiceList(props: any) {
  let rows = [];
  let Invoices = <></>;
  let altBg = "";
  let length = props.length;

  for (var i = 0; i < length; i++) {
    let payStatus = "Paid";
    let payStatusStyle = "text-gray-500 text-sm";

    if (payStatus === "Pending") {
      payStatusStyle = "text-yellow-500 text-sm";
    }

    if (payStatus === "Not Paid") {
      payStatusStyle = "text-red-400 text-sm";
    }

    if (payStatus === "Paid") {
      payStatusStyle = "text-green-500 text-sm";
    }

    Invoices = (
      <div
        className={
          altBg +
          " h-16 w-auto flex flex-col bg-gray-100  my-2 justify-center px-4 rounded-sm   "
        }
      >
        <div className="flex flex-row justify-between mb-1">
          <span className="text-gray-500 text-sm">Invoice #0000</span>
          <span className="text-gray-700 text-sm">$1,000.00</span>
        </div>

        <div className="flex flex-row justify-between">
          <span className="text-gray-400 text-xs ">
            July 8th - August 20th 2020
          </span>
          <span className={payStatusStyle + " font-display"}>{payStatus}</span>
        </div>
      </div>
    );

    rows.push(Invoices);
  }

  return <>{rows}</>;
}
