import React, { Component } from "react";
import { BiCheck } from "react-icons/bi";

interface ToggleSwitchState {
  toggle: boolean;
  size: string;
}

class ToggleSwitch extends Component<any, ToggleSwitchState> {
  constructor(props: any) {
    super(props);

    this.state = {
      toggle: this.props.onoff,
      size: this.props.size,
    };
  }

  handleToggleSwitch = (event: any) => {
    event.preventDefault();
    this.props.handleStateToggle(this.props.id, this.props.onoff);

    if (this.state.toggle === false) {
      this.setState({ toggle: true });
    } else {
      this.setState({ toggle: false });
    }
  };

  render() {
    let Toggle = this.props.onoff;
    let ToggleStyle = "bg-gray-300";
    let ToggleStyleBG = "bg-gray-100";
    let CheckMark = "opacity-0 ";
    let ToggleSize = this.state.size;
    let Circ = "";
    let Rect = "";
    let Dist = "";
    let checkSize = "";

    if (ToggleSize === "sm") {
      Circ = " w-3 h-3 ";
      Rect = " w-8 ";
      Dist = " translate-x-3 ";
      checkSize = "text-xs";
    } else {
      Circ = " w-4 h-4 ";
      Rect = " w-10 ";
      Dist = " translate-x-4 ";
      checkSize = "";
    }

    if (Toggle === true) {
      ToggleStyle = Dist + " transform  bg-white ";
      ToggleStyleBG = " bg-green-500";
      CheckMark = "check text-green-500 ";
    }

    return (
      <button
        id={this.props.id}
        onClick={this.handleToggleSwitch}
        className={
          ToggleStyleBG +
          Rect +
          " transition-all duration-300  rounded-full   px-1 py-1 flex flex-row items-center shadow-sm "
        }
      >
        <div
          className={
            ToggleStyle + Circ + " transition-all duration-300  rounded-full  "
          }
        >
          <BiCheck className={CheckMark + checkSize} />
        </div>
      </button>
    );
  }
}

export default ToggleSwitch;
