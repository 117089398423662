import { returnJWTToken } from "../login/LoginForm";

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id":
        process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    "federationTarget": "COGNITO_USER_POOLS",
    "API": {
      endpoints: [
        {
          name: "Admin System",
          endpoint: process.env.REACT_APP_AWS_API_GATEWAY_LINK,
          // headers: async () => {
          //   return { Authorization: await returnJWTToken };
          // }
        },
      ],
    },
    // "API": {
    //     REST: {
    //       'Admin System': {
    //         endpoint: process.env.REACT_APP_AWS_API_GATEWAY_LINK,
    //       }
    //     }
    // }
};

export default awsmobile;