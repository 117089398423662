import { Component, useState } from "react";
import {Box, Modal, TextField, Typography} from '@mui/material'; 
import { DatePicker } from '@mui/lab';
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { ImCross } from "react-icons/im";
import { AiOutlineDownload } from "react-icons/ai";


type Props = {
    open: boolean; 
    closeModal: any; 
};

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function InvoiceCSVModal(props: Props) {
    const classes = useStyles();
    const [start_date, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [end_date, setEndDate] = useState(new Date().toISOString().split('T')[0]); 
    const [open, setOpen] = useState(props.open);
    const [url, setURL] = useState("");

    function onDateChange(event: any): void {
        event.preventDefault();
        console.log(event.target.id)
        if (event.target.id == "start_date") {
            setStartDate(event.target.value); 
        } else {
            setEndDate(event.target.value);
        }
    }

    async function generateCSV() {
        console.log (start_date)
        console.log(end_date)
        let res = await fetchRequestJSON(
            'GET', 
            Constants.API_PATH_INVOICE_GET_CSV, 
            {
                start_date: start_date,
                end_date: end_date,
            });
            console.log(res);
        if (res.status == "Success") {
            let data = new Blob([res.data], {type: 'text/plain'});
            setURL(window.URL.createObjectURL(data));
        } else {
            alert("There was an error creating the CSV")
        }
    }

    return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                open={props.open}
                onClose={() => props.closeModal()}
            >
                <div className=" appear bg-white shadow-lg rounded-lg h-2/5 w-1/2 border-4  border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center text-xl font-bold font-SF-Pro">
                    {/* <button className="" onClick={() => {
                        setOpen(false); 
                    }}>
                        <ImCross className="text-gray-600 text-s mb-3" />
                    </button> */}
                    <span className="text-xl font-bold text-gray-800 text-left pb-4">Download Invoice CSV</span>
                    {/* <DatePicker value={date} onChange={(newDate) => onDateChange(newDate)} renderInput={(params) => <TextField {...params} />}></DatePicker> */}
                    <div className="mx-auto flex justify-center">
                        <label className="pl-3 mb-5 mx-auto flex flex-col">
                            <span className="text-gray-400 text-xs">Start Search Date</span>
                            <input
                                id="start_date"
                                onChange={(e) => onDateChange(e)}
                                value={start_date}
                                type="date"
                                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                placeholder=""
                            />
                        </label>
                        <label className="pl-3 mb-5 mx-auto flex flex-col">
                            <span className="text-gray-400 text-xs">End Search Date</span>
                            <input
                                id="end_date"
                                onChange={(e) => onDateChange(e)}
                                value={end_date}
                                type="date"
                                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                placeholder=""
                            />
                        </label>
                    </div>
                    <div className="h-24 flex justify-around">
                        <button
                            onClick={() => generateCSV()}
                            className="transition duration-100 border-2 border-solid w-36 bg-gray-800 hover:bg-green-400 text-white  font-medium py-1 rounded pr-1 flex flex-row justify-center align-center h-12"
                        >
                            <span className=" text-xs self-center ">Generate CSV</span>
                        </button>
                        {(url !== "") && <a 
                            className="transition duration-100 border-2 border-solid w-36 border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center h-12"
                            // href={url}
                            download={start_date + "_" + end_date + "_invoices.csv"}
                        >
                            <AiOutlineDownload className="  text-xl mr-1 self-center" />
                            <span className=" text-xs self-center ">Download CSV</span>
                        </a>}
                    </div>
                    </div>
            </Modal>
    ); 
}



export {InvoiceCSVModal}