import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { formatFullDate } from "../../utils/DateFormat";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { formatAmountForDisplay } from "../../utils/DollarFormat";

// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  toggleLead: any;
};

type State = {
  blackout: boolean;
};

class LeadList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
    };
  }

  openSettings = () => {
    alert("Settings");
  };

  render() {
    // const { history } = this.props;
    let Blackout = this.state.blackout;

    let blackoutStyles = "transition-all duration-200";
    let Warnings = "";
    let blackoutToggle = (
      <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"} />
    );

    if (Blackout === true) {
      blackoutStyles = "transition-all duration-200 opacity-10 ";
      Warnings = "Do Not Book";
      blackoutToggle = (
        <FaPowerOff
          className={"text-2xl text-green-500 hover:text-green-300"}
        />
      );
    }

    let TableRows = <></>;
    var rows = [];
    let today = new Date();
    let testDueDate = new Date(2021, 7, 8, 4, 20);
    let altBg = "";
    let OverDueStyle = "text-gray-700";
    let OverDueStyle2 = "text-gray-700";
    let OverDue = <></>;

    for (var i = 0; i < 12; i++) {
      if (testDueDate > today) {
        OverDueStyle = "text-white text-sm  w-72";
        OverDueStyle2 = "text-red-400 mr-10";
        OverDue = (
          <span className="bg-red-500 py-1 px-4 rounded-full text-white">
            Overdue
          </span>
        );
      }

      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }

      TableRows = (
        <tr
          key={i.toString()}
          className={
            altBg +
            " cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"
          }
        >
          {/* Suite Name */}

          <td
            onClick={this.props.toggleLead}
            className={" text-purple-500 w-20 py-4 pl-4"}
          >
            {i}
          </td>

          {/* Suite Number */}

          <td
            onClick={this.props.toggleLead}
            className={
              "  w-auto font-light text-sm text-gray-500 px-1 py-4 grid grid-cols-1"
            }
          >
            <span className="text-gray-700">Robert Davidson</span>
            <span className="text-gray-400">bobby.redrock@gmail.com</span>
          </td>

          {/* Min. Stay */}

          <td
            onClick={this.props.toggleLead}
            className={"  font-thin  text-gray-600 px-1  py-4"}
          >
            {formatFullDate(new Date())}
          </td>

          {/* Bedrooms */}

          <td
            onClick={this.props.toggleLead}
            className={"font-medium  text-gray-700 px-1  py-4"}
          >
            {formatFullDate(new Date())}{" "}
          </td>

          {/* Type */}

          <td
            onClick={this.props.toggleLead}
            className={"  font-normal  text-gray-400 px-1  py-4"}
          >
            {formatAmountForDisplay(2500, "CAD", false)}
          </td>

          {/* Booking Warnings */}

          <td
            onClick={this.props.toggleLead}
            className="  text-gray-400 px-1 py-4 text-sm "
          >
            Looking for something near waterfront
          </td>

          {/* Booking Warnings */}

          <td className=" w-32 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                onClick={this.openSettings}
                className={"text-gray-700 text-2xl mr-2 hover:text-gray-500"}
              />
            </div>
          </td>
        </tr>
      );

      rows.push(TableRows);
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-1/4  py-1">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                className="text-sm focus:border-0 focus:outline-none "
                placeholder="Search..."
              ></input>
            </div>
            <span className="py-1 px-4 font-medium margin text-gray-500 ">
              Filters:
            </span>

            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Recent
            </button>
            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Corporate
            </button>
            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Budget
            </button>
          </div>
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="text-left font-normal text-gray-500 ">
              <th className="py-2 pl-4">ID</th>
              <th className="py-2  ">Contact</th>
              <th className="py-2  ">Inquire Date</th>
              <th className="py-2  ">Requested Check In Date</th>
              <th className="py-2  ">Budget</th>
              <th className="py-2  ">Notes:</th>
              {/* Book Warnings */}
              <th className="py-2  "></th>
              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(LeadList);
