import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/University Plaza/UP Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/University Plaza/UP Parking.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/University Plaza/UP View 1.png";
import ThirdStreetView from "../../../client-facing/img/ParkingPhotos/University Plaza/UP View 2.png";

function UniversityPlaza() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-2/3">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            The parking garage for University plaza is located at the back of
            the building, via Twilight lane, which is accessible from Richmond
            St. in the north and Nelson St. in the south.
          </p>
          <p className="mt-3">
            Enter the parking garage and proceed down the ramp, past the paid
            parking spaces until you reach a large white garage door. Pull up
            close, then press and hold the button on your parking remote for 5
            seconds in order to open the garage door to the residential parking.
            From there, proceed your designated parking space.
          </p>
          <p className="mt-3 mb-5 md:mb-2">
            Note that the parking remote is required to exit the garage each
            time, please keep the remote in your vehicle at all times.
          </p>
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className=""
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-2/5">
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-1/4 w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point Form Summary:
            <p className="my-3">
              <strong>
                Entrance accessible via Twilight lane, from Richmond or Nelson
                St.
              </strong>
            </p>
            <ul className="list-disc ml-5">
              <li>
                Proceed down ramp at entrance past paid public parking until you
                reach the resident parking garage door.
              </li>
              <li>
                Pull up close to the garage door, then press and hold the button
                on your parking remote for 5 seconds to open the door.
              </li>
              <li>Proceed down to designated parking space.</li>
            </ul>
            <p className="mt-2">
              Note that you require the remote to leave the parking garage,
              please keep it in your vehicle at all times.
            </p>
          </p>
          <img
            src={ThirdStreetView}
            alt="Google Maps Street View"
            className="h-1/4 w-full mt-5"
          />
        </div>
      </div>
    </div>
  );
}

export default UniversityPlaza;
