export type State = {
  curId: number;
  quickViewUpdate: any;
  listUpdate: any; 
};

const initialState: State = {
  curId: -1,
  quickViewUpdate: undefined,
  listUpdate: undefined
};

type Action =
    { type: "consolidatedInvoiceIdTransfer"; id: number }
  | { type: "consolidatedInvoiceQuickViewUpdate"; quickViewUpdate: any }
  | { type: "consolidatedInvoiceListUpdate"; listUpdate: any };

export const consolidatedInvoiceReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "consolidatedInvoiceIdTransfer":
      state.curId = action.id;
      return state;
    case "consolidatedInvoiceQuickViewUpdate":
      state.quickViewUpdate = action.quickViewUpdate;
      return state;
    case "consolidatedInvoiceListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    default:
      return state;
  }
};

export default consolidatedInvoiceReducer;
