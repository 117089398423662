import React, { useEffect, useState } from "react";
import { FiChevronsRight, FiCornerUpRight } from "react-icons/fi";
import { useNotification } from "../../../Notifications/NotificationProvider";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import store from "../../../../redux/store";

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

type Props = {
    handleModal:any
    title: string;
    toggle: boolean;
    parkingInfo: any;
}

//This Modal does three things:
// --- Before 4 pm of the parking's start date --- 
// 1. Pre-transfer
// 2. Closes Modal to open the Swap Modal instead
// --- After 4 pm of the parking's start date ---
// 3. Parking Transfer 

function TransferParking(props:Props) {
    const [isTransfer, setIsTransfer] = useState(false)
    const [parkingID, setParkingID] = useState(props.parkingInfo?.id)
    const [activeParkingSpots, setActiveParkingSpots] = useState(null)
    const [currentParkingSpot, setCurrentParkingSpot] = useState('');

    
    //Pre Transfer and Transfer Payload
    const [newParkingSpot, setNewParkingSpot] = useState(props.parkingInfo?.parking_spot_id) //Used in Pre Transfer Parking and Transfer Parking, default is set to the current parking spot id
    const [transferDate, setTransferDate] = useState(null) //Used in Transfer Parking


    //Swap Toggle and Info:
    const [swap, setSwap] = useState(false)
    const [swapInfo, setSwapInfo] = useState(null)
    const [swapParkingSpot, setSwapParkingSpot] = useState('')
 
    //Handle Errors:
    const [warning, setWarning] = useState(false)
    const [warningInfo, setWarningInfo] = useState(null) // If we get errors, we can store it in here 
    const [warningMessage, setWarningMessage] = useState('');
    
    //Notification Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async() => {
        if(props.title == "Pre Check-In Parking Transfer") {
            setIsTransfer(false)
        } else {
            setIsTransfer(true)
        }

        //Set Active Parking Spots
        const allActiveParkingSpots = await fetchGetJSON(Constants.API_PATH_PARKING_SPOT_GET_ALL_ACTIVE);
        setActiveParkingSpots(allActiveParkingSpots.data.sort((parkingSpotOne:any, parkingSpotTwo:any) => parkingSpotOne.parking_spot_location.localeCompare(parkingSpotTwo.parking_spot_location)))

        const parkingSpotData = allActiveParkingSpots.data.filter((parkingSpot:any) => {
          return parkingSpot.id == newParkingSpot
        })

        setCurrentParkingSpot(`${parkingSpotData[0].parking_spot_location} | ${parkingSpotData[0].parking_spot_level}`)
    }

    const handleModal = (event: any) => {
        if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
           props.handleModal() //Toggles it on and off
        }
    };

    const handleSubmit = async(event:any) => {
        event.preventDefault()

        //Three States for Handle Submit:

        //1. Transfer and Pre Transfer 

        //2. Swap is true but warning is false --> Swap 

        //3. Swap is true but warning is true --> warning, should close modal 

        if(!swap && !warning) {
          //If it isn't a transfer, then pretransfer
          const transferParking = isTransfer ? await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_TRANSFER, {
            id: parkingID,
            new_parking_spot: newParkingSpot,
            transferDate: transferDate
          })
          :
          await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_PRETRANSFER, {
              id: parkingID,
              new_parking_spot: newParkingSpot
          }) 

          const parkingSpotData = activeParkingSpots.filter((parkingSpot:any) => {
              return parkingSpot.id == newParkingSpot
          })

          if (transferParking.status) {
              if (transferParking.status == "Success") {
                  const succesfulTransferDate = isTransfer ? transferDate.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
                  NotificationHandler("Success", 'Success', `Successfully Transferred Parking #${parkingID} to ${parkingSpotData[0].parking_spot_location} | ${parkingSpotData[0].parking_spot_level} ${isTransfer ? `on ${succesfulTransferDate}` : ""}`);

                  //Update our parking profile with the new parking
                  let update = store.getState().parkingReducer.profileUpdate;
                  update()

                  props.handleModal() 
              }
              else if (transferParking.status == "Swap") {
                const swapParking = transferParking.data.swapParking[0]
                NotificationHandler("Loading", 'Swap', "One conflicting booking found, Swap Eligible");

                console.log(swapParking)
                setSwapInfo(swapParking); //Second parking that we waant to swap and display to user as to what parking we are swapping with the original
                setSwapParkingSpot(transferParking.data.parkingSpotName)

                //Set swap toggle to true and set warning to false and set swap payload: 
                setSwap(true)
                setWarning(false)
              }
              else if (transferParking.status == "Error: multiple parkings") {
                const multipleParkings = transferParking.data.parkingSpotCheck
                NotificationHandler("Warning", 'Warning', "Multiple Conflicting Bookings Found, Swap Not Eligible");
                
                setWarningInfo(multipleParkings)
                setWarningMessage(`Error: Multiple Parkings Detected in ${transferParking.data.parkingSpotName}. \nSwapping is not eligible for more than one conflicting parking.`)

                setWarning(true)
                setSwap(true) //only set swap to true so we can enter our warning state, check FE below for conditional rendering
              }
              else if (transferParking.status == "Error: Currently Occupied Parking Spot") {
                const currentOccupiedParkings = transferParking.data.currentOccupiedParkings
                NotificationHandler("Warning", 'Warning', `Conflicting Occupied Bookings Found, Swap Not Eligible`);

                console.log(currentOccupiedParkings)
                setWarningInfo(currentOccupiedParkings)
                setWarningMessage(`Error: Currently Occupied Parking Spot in ${transferParking.data.parkingSpotName}. \n`)

                setWarning(true)
                setSwap(true) //only set swap to true so we can enter our warning state, check FE below for conditional rendering
                setSwapParkingSpot(transferParking.data.parkingSpotName)
              }
              else {
                NotificationHandler("Error", 'Error', transferParking.status);
                // this.props.handleSwapToggle(bookingId, this.state.unit, this.state.transfer_date, this.state.transfer_date, this.state.transfer_date);
              }
          }
        } else if (swap && !warning) {
          //Swap the parkings:
          const transferParking =  await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_SWAP, {
            first_parking_id: parkingID,
            second_parking_id: swapInfo.id
          })

          if (transferParking.status) {
            if(transferParking.status == "Success") {
              NotificationHandler("Success", 'Success', `Successfully swapped Parking ${transferParking.data.firstParking.updatedFirstParking.id} and ${transferParking.data.secondParking.updatedSecondParking.id}`);
              
              //Update our parking profile with the new parking
              let update = store.getState().parkingReducer.profileUpdate;
              update()

              props.handleModal() 
            } else if (transferParking.status == "Multiple Parkings Error") {
              NotificationHandler("Error", "Error", "Multiple Parkings Detected")

              //-------
              setWarningMessage(`Multiple Parking Errors Detected. Swap is not eligible: `)
              setWarningInfo(transferParking.swapEligibility)

              setSwap(true)
              setWarning(true)
            } else {
              NotificationHandler("Error", "Error", "Error detected during Swap. Please contact dev team for assistance.")
              
              //------
              setWarningMessage(`Error detected during Swap`)

              setSwap(true)
              setWarning(true)
            }
          } else {
            //This is if status 500
            NotificationHandler('Error', 'Error', 'Contact Dev Team.')
          }
        } else if (swap && warning) {
          //Close the Modal as the button will default to saying "Ok"
          props.handleModal()
        }

        return
    }

    return (
        <div
        id="outsideDiv"
        onClick={handleModal}
        className={`fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 
        ${ !props.toggle ? "hidden" : "" }`}
      >
        {/* {swapModal} */}
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-800 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          {isTransfer ? <FiCornerUpRight className="text-gray-600 text-3xl mb-3" /> : <FiChevronsRight className="text-gray-600 text-3xl mb-3" />}
          <span className="text-xl font-bold text-gray-800 text-left">{!warning ? props.title : warningMessage}</span>
          <span className="text-xs text-center text-gray-400"></span>
          <form>
            {
              !swap  ? 
                //Pre Transfer and Transfer Code: 
                <div className="grid grid-cols-3 gap-6 w-full my-5">
                  {isTransfer && <label className="block  ">
                    <span className="text-gray-400 text-xs">Date of Transfer:</span>
                    <input
                      id="transfer_date"
                      onChange={(input) => setTransferDate(dayjs(input.target.value))}
                      type="date"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder="Select the Transfer Date"
                    />
                  </label>}

                  <label className="block w-96">
                    <span className="text-gray-400 text-xs">Parking Spot:</span>
                    <select
                      id="parking spot"
                      onChange={(input) => setNewParkingSpot(+input.target.value)}
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder="Choose"
                      value={newParkingSpot}
                    >
                      {activeParkingSpots?.map((parkingSpot:any) =>
                        <option value={parkingSpot.id}>{parkingSpot.parking_spot_location} | {parkingSpot.parking_spot_level}</option>
                      )}
                    </select>
                  </label>
                </div>
              
              :
                
              //Swap code and warning: 
              warning ?
                //If Warning is true and swap is true, then that means that no swap should happen
                <div className="flex flex-row gap-10 w-full my-5 justify-center">
                  {warningInfo.map((parking:any, index:number) => {
                      return (
                          <label className="flex flex-col space-y-5 ">
                              <span className="text-gray-800 text-m font-bold">{index == 0 ? "Current Occupied Parking: " : ""}Parking Id: {parking.id}</span>
                              <div className="text-gray-400 text-xs">Check in: <span className="text-gray-800 text-xs">{parking.start_date}</span></div>
                              <div className="text-gray-400 text-xs">Check out: <span className="text-gray-800 text-xs">{parking.end_date}</span></div>
                              <div className="text-gray-400 text-xs">Unit Name: <span className="text-gray-800 text-xs">{swapParkingSpot}</span></div>
                          </label>
                      )
                  })}
                </div >
              :
                //Swap Content goes here: 
                 // If it's swap eligible
                 <div className="flex flex-row gap-40 w-full my-5 ml-10 ">
                  <label className="flex flex-col space-y-5 ">
                      <span className="text-gray-800 text-m font-bold">First Parking Id: {parkingID}</span>
                      <div className="text-gray-400 text-xs">Start Date: <span className="text-gray-800 text-xs">{props?.parkingInfo?.start_date}</span></div>
                      <div className="text-gray-400 text-xs">End Date: <span className="text-gray-800 text-xs">{props?.parkingInfo?.end_date}</span></div>
                      <div className="text-gray-400 text-xs">Parking Spot: <span className="text-gray-800 text-xs">{currentParkingSpot}</span></div>
                  </label>
              
                  <label className="flex flex-col space-y-5 mr-5">
                      <span className="text-gray-800 text-m font-bold">Second Parking Id: {swapInfo.id}</span>
                        <div className="text-gray-400 text-xs">Start Date: <span className="text-gray-800 text-xs">{swapInfo.start_date}</span></div>
                        <div className="text-gray-400 text-xs">End Date: <span className="text-gray-800 text-xs">{swapInfo.end_date}</span></div>
                        <div className="text-gray-400 text-xs">Parking Spot: <span className="text-gray-800 text-xs">{swapParkingSpot}</span></div>
                  </label>
                </div >
            }

            <div className="flex flex-row justify-start w-full mt-6">
              <button
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                onClick={(event) => handleSubmit(event)}
              >
                {!swap && !warning ? 'Submit' : warning ? 'Ok' : 'Swap'}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
}

export default TransferParking;
