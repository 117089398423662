import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { BiCheck } from "react-icons/bi";

type State = {
  navOpen: boolean;
};

class StepperBar extends Component<RouteComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      navOpen: false,
    };
  }

  render() {
    let arSteps = [
      "",
      "Data Collection",
      "Payment",
      "Unit Info & Keys",
      "Security & Help Desk",
      "Smoking Policy",
      "Cleaning",
      "Check Out Policy",
      "Transportation",
      "Check In Summary",
    ];
    let path = this.props.location.pathname;
    let stepProgress = 0;
    // let activeStep = null;

    for (let n = 0; n < arSteps.length; n++) {
      if (path.includes("/" + arSteps[n])) {
        // console.log(arSteps[n])
        stepProgress = arSteps.indexOf(arSteps[n]);

        //   activeStep = document.getElementById(arSteps[n]);
        //   activeStep!.classList.remove("opacity-50");
        //   activeStep!.classList.add("opacity-100");
      }
    }

    let stepList = [];
    let stepItem = [];
    let navToggleButton;

    let navItems;
    let navText;

    let navSettings;
    let checkIcon;

    for (let i = 0; i < 9; i++) {
      if (i < stepProgress) {
        stepItem[i] = true;
      }

      if (stepItem[i] === true) {
        checkIcon = (
          <div className=" transition-all duration-300 fade-in h-5 w-5 bg-white flex flex-row justify-center items-center  rounded-full border-2 border-solid ">
            <BiCheck className="  text-gray-900 opacity-100  " />
          </div>
        );
      } else {
        checkIcon = (
          <div className=" transition-all duration-300 bg-none  fade-in h-5 w-5  rounded-full border-2 border-solid ">
            <BiCheck className="  text-gray-900 opacity-0 " />
          </div>
        );
      }

      let divider = <div className="h-12 border border-solid bg-white"></div>;
      stepList.push(checkIcon);
      if (i < 8) {
        stepList.push(divider);
      }
    }

    navToggleButton = (
      <span className=" fade-in transition duration-200 ml-8 w-44 text-white font-SF-Pro font-semibold text-xl">
        Check In Form
      </span>
    );
    navItems =
      " group h-16 w-full mt-3 align-center justify-center flex flex-col ";
    navText =
      "font-display text-blue-100 pb-1 group-hover:text-white text-xs self-center";

    navSettings = (
      <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
        <FiSettings className="text-white text-xl mx-auto " />
      </button>
    );

    checkIcon = (
      <div className=" fade-in h-5 w-5  rounded-full border-2 border-solid "></div>
    );

    return (
      <div
        id="navBar"
        className="fade-in transition-all z-50 duration-300 fixed bg-gray-900 w-80 h-screen left-0  flex flex-col items-center shadow-inner shadow-lg"
      >
        <div className="  transition duration-200 flex flex-row items-center w-full h-20 mx-auto">
          {navToggleButton}
        </div>

        <div className=" flex flex-col text-white pl-8 w-full h-auto overflow-y-scroll font-europe  font-bold ">
          <div className=" absolute w-auto h-auto bg-gradient-to-b from-transparent to-gray-800 "></div>
          <span className=" self-start opacity-40 text-2xs uppercase ">
            Make Sure Each Step Is Carefully Completed
          </span>
          <div className="flex flex-row mt-6 font-sofia">
            <div className="flex flex-col items-center mt-1  ">{stepList}</div>

            <div className="flex flex-col items-center pl-2 ">
              <div className="flex flex-col items-start pl-2 mb-6 opacity-50">
                <span className="font-regular ">Intro</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>
              <div
                id="Data Collection"
                className="flex flex-col items-start pl-2 mb-6 opacity-50 "
              >
                <span className="font-regular ">Data Collection</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2 mb-6 opacity-50 ">
                <span className="font-regular ">Payment</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2 mt-1 mb-6 opacity-50 ">
                <span className="font-regular ">Unit Info & Keys</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2 mb-6 opacity-50 ">
                <span className="font-regular ">Security & Help Desk</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2  mb-6 opacity-50 ">
                <span className="font-regular ">Smoking Policy 🚬</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2  mb-6 opacity-50 ">
                <span className="font-regular ">Cleaning</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2 mt-1 mb-6 opacity-50 ">
                <span className="font-regular ">Check Out Policy</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>

              <div className="flex flex-col items-start pl-2 mt-1 mb-6 opacity-50 ">
                <span className="font-regular ">Transportation + Files</span>
                <span className="font-thin text-xs opacity-70">
                  Intro To Orientation - Check Info
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-auto">{navSettings}</div>
      </div>
    );
  }
}

export default withRouter(StepperBar);
