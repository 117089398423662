import { Divider } from "@mui/material";
import React from "react";

import MapPicture from "../../../client-facing/img/ParkingPhotos/Atmosphere/300 Front Street W.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Atmosphere/300 Front Street View.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Atmosphere/300 Front Street View 2.png";

function AtmosphereParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            Parking for 300 Front St. W is accessible via Front St. in the south
            and John St. in the east. The two driveways meet at a 90 turn, at
            the corner of which you will see a large, double-wide roll up door,
            along with a yellow lift gate. Pull your vehicle up nice and close
            to the roll up door and hold your parking transponder up around your
            rear-view mirror. If the door does not open, you may need to hold
            the remote out the window for the signal to reach the sensor.
          </p>
          <p className="mt-3 mb-5 md:mb-0">
            Once the ground level door opens, proceed down the ramp and straight
            through the visitor parking area to the second roll up door. Pull
            your vehicle up nice and close to the second roll up door and hold
            the remote up the same way as with the first door. Once this door
            opens, proceed down into the resident parking to your assigned
            parking spot.
          </p>
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className=""
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0">
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="h-full w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point form summary: Entrance accessible via Front St and John Street
            <ul className="list-disc ml-5">
              <li>
                Approach roll up garage door, key landmarks are the yellow posts
                with the gate arms
              </li>
              <li>
                Hold up parking remote towards the gates, best to hold it out
                the window to improve signal
              </li>
              <li>
                When first gate opens, proceed down the ramp, straight ahead
                through visitor parking, to second roll up garage door
              </li>
              <li>
                Hold remote up again the same way as the first door until it
                opens
              </li>
              <li>Proceed down to your designated parking space</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AtmosphereParking;
