import React, { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { TiFlag } from "react-icons/ti";
import { fetchGetJSON, fetchRequestJSON, regGet } from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { Constants } from "../../api-requests/apiLinkConstants";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../utils/PageSelector";
import { listview } from "../../models/modelList";

// Notes:
type Props = {
  handleState: any;
};

type State = {
  blackouts: Blackout[];
  allUnits: Unit[];
  search: string;
  loaded: boolean;

  current_page_number: number;
  rows_per_page: number;
  total_pages: number;

  authorization: boolean;
};

type Blackout = {
    id: number; 
    suite_name: string; 
    unit_id: number; 
    start_date: string; 
    end_date: string; 
    reason: string; 
}; 

type Unit = {
  id: number; 
  suite_name: string; 
}; 

class BlackoutList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackouts: [],
      allUnits: [],
      search: "",
      loaded: false,

      current_page_number: 1,
      rows_per_page: 10,
      total_pages: 0,

      authorization: true,
    };
  }

  handleNext = (id: number) => {
    store.dispatch({ type: "ownerIdTransfer", id: id });
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: location.pathname + `/blackoutprofile/`,
        search: `id=${id}`,
      });
  };

  handleModal = (id: string) => {
    store.dispatch({ type: "unitIdTransfer", id: -1 });
    
  };

  componentDidMount = async () => {
    const allUnits = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
    this.setState({ allUnits: allUnits.data});
    this.updateList();
    store.dispatch({ type: "blackoutListUpdate", listUpdate: this.updateList });
  };

  // Update the list of blackouts
  updateList = async () => {
    let filters = {
      search: this.state.search.trim()
    }
    const blackouts = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_BLACKOUTS}?search${this.state.search ? `=${this.state.search.trim()}`: ''}&page=${this.state.current_page_number}&perpage=${this.state.rows_per_page}`, 
    // {filters: filters, page: this.state.current_page_number, perpage: this.state.rows_per_page}
    )
    this.setState({ total_pages: blackouts.data.pages });
    console.log(blackouts);
   
    let blackoutList = [];
    if (blackouts.status != "Success") {
      this.setState({
        loaded: true,
        authorization: blackouts.status == "Error Unauthorized" ? false : true
      })
      return;
    }
    for (let i = 0; i < blackouts.data.rows.length; i++) {
        let this_blackout = blackouts.data.rows[i]; 
        let blackoutObj: Blackout = {
            id: this_blackout.id, 
            suite_name: this_blackout.unit.suite_name, 
            unit_id: this_blackout.unit_id, 
            reason: this_blackout.reason, 
            start_date: this_blackout.start_date, 
            end_date: this_blackout.end_date
        };
      
        blackoutList.push(blackoutObj); 
    }
    this.setState({ blackouts: blackoutList, loaded: true });
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);

    // this.updateList();
  };

  pageUp = async () => {
    let page = this.state.current_page_number + 1;
    if (page > this.state.total_pages) {
      page -= 1;
    }
    this.setState({
      current_page_number: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  
  }

  pageDown = async () => {
    let page = this.state.current_page_number - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      current_page_number: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  setPerPage = async (val: number) => {
    await this.setState({rows_per_page: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.current_page_number > this.state.total_pages) {
        this.setState({
          current_page_number: this.state.total_pages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    let Blackout = false;

    let blackoutStyles = "";
    let Warnings = "";
    let blackoutToggle = (
      <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"} />
    );

    if (Blackout) {
      blackoutStyles = " opacity-10 ";
      Warnings = "Do Not Book";
      blackoutToggle = (
        <FaPowerOff className={"text-xl text-green-500 hover:text-green-300"} />
      );
    }

    let TableRows = <></>;
    var rows = [];
    for (let i = 0; i < this.state.blackouts.length; i++) {
      // if (!(this.state.search == "" || this.state.blackouts[i].suite_name.toLowerCase().includes(this.state.search.toLowerCase()))) { continue; }
        //creating the rows for the table
      TableRows = (
        <tr className="border-t border-gray-400 w-full hover:bg-gray-100">
          <td
            className={" cursor-pointer w-auto lg:w-16 px-1 py-4"}
          >
            <span className="text-indigo-400">
           {this.state.blackouts[i].id}
            </span>
          </td>
      

          <td
            onClick={() => this.handleNext(this.state.blackouts[i].id)}
            className={" cursor-pointer lg:pl-4 px-1 py-4"}
          >
            <span>
              {this.state.blackouts[i].suite_name}{" "}
          
            </span>
          </td>


          <td
            onClick={() => this.handleNext(this.state.blackouts[i].id)}
            className={
              " cursor-pointer lg:pl-4 px-1 py-4"
            }
          >
            <span>{this.state.blackouts[i].unit_id}</span>
          </td>

          <td
            onClick={() => this.handleNext(this.state.blackouts[i].id)}
            className={" cursor-pointer font-thin text-gray-500 px-1  py-4"}
          >
            {this.state.blackouts[i].reason}
          </td>

          <td
            onClick={() => this.handleNext(this.state.blackouts[i].id)}
            className={" cursor-pointer font-thin text-gray-500 px-1  py-4"}
          >
            {this.state.blackouts[i].start_date.substr(0, 10)}
          </td>

          <td
            onClick={() => this.handleNext(this.state.blackouts[i].id)}
            className=" cursor-pointer font-thin text-gray-500 px-1  py-4"
          >
            {this.state.blackouts[i].end_date.substr(0, 10)}
          </td>

          {/* Booking Warnings */}

          {/* <td className=" w-32 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                className={
                  blackoutStyles +
                  "text-gray-700 text-2xl mr-2 hover:text-gray-500"
                }
              />
              {blackoutToggle}
            </div>
          </td> */}
        </tr>
      );
      rows.push(TableRows);
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col w-1/3 sm:flex-row items-center align-center">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-3/4 mb-6 py-1 sm:mb-0 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                className="text-sm searchBox w-full"
                placeholder="Search by unit name or blackout id"
                id="search"
                value={this.state.search}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>
          </div>
          <PageSelector
              curpage={this.state.current_page_number}
              totalpages={this.state.total_pages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
          />
        </div>

        {/* Blackout Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="text-left font-normal text-gray-500 ">
              <th className="py-2  ">Blackout ID</th>
              <th className="py-2 lg:pl-4 ">Suite Name</th>
              <th className="py-2  ">Unit ID</th>
              <th className="py-2  ">Reason</th>
              <th className="py-2  ">Start Date</th>
              <th className="py-2  ">End Date</th>
              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={5} empty={this.state.loaded}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(BlackoutList);
