import { Component } from "react";
import { ImCancelCircle } from "react-icons/im";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { withRouter } from "react-router-dom";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

interface Props {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
  history: any;
  handleFlag: any;
  parentData: any;
  panelType: string;
  handleEditBarToggle: any;
  handleState: any;
  invoiceOff: any;
}

interface ModalState {
  title: string;
  onoff: boolean;
  rate_change_date: any;
  rate: number;
  unit: string;
  checked: boolean;
}

class CancelModal extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      rate_change_date: "",
      rate: 0,
      unit: "",
      checked: false,
    };
  }

  getConfirmationMessage() {
    if (this.props.title === "Cancel Invoice") {
      return "I'm Sure About Cancelling This Invoice";
    } 
    else if (this.props.title === "Delete Lead") {
      return "I'm Sure About Deleting This Lead";
    } 
    else if (this.props.title === "Delete Building") {
      return "I'm Sure About Deleting This Building";
    } 
    // else if (this.props.title === "Delete Owner") {
    //   return "I'm Sure About Deleting This Owner";
    // } 
    else if (this.props.title === "Delete Corporate Contact") {
      return "I'm Sure About Deleting This Corporate Contact";
    } 
    else if (this.props.title === "Cancel Parking") {
        return "I'm Sure About Cancelling This Parking";
    } 
    else {
      return "I'm Sure About Cancelling This";
    }
  }
  //Need to refactor the below route, our delete routes now don't use payloads and all routes use DELETE as a method
  cancelModel = async (link: string, id: number, method:string="DELETE") => {
    return await fetchRequestJSON(method, link, { id: id, });
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff });
    }
  };

  handleClick = async () => {
    this.props.handleModalToggle();
    let NotificationHandler = this.props.ErrorNote;
    const { history } = this.props;
    let path = window.location.pathname.split("/");
    if (this.props.title === "Cancel Invoice") {
      const invoiceId = store.getState().invoiceReducer.curId;
      let invoice = await fetchGetJSON(`${Constants.API_PATH_INVOICE_GET}${invoiceId}`)

      if(invoice.data.paid)
      {
        NotificationHandler("Error", "Invoice has been paid", "You cannot delete invoices that are paid")
      }
      else if(invoice.data.marked_by && !invoice.data.confirmed_by) {
        NotificationHandler("Error", "Invoice is currently pending approval", "You cannot delete invoices that are pending approval")
      }
      else
      {
        const cancelInvoiceStatus = await fetchRequestJSON(
          "DELETE",
          `${Constants.API_PATH_INVOICE_CANCEL}${invoiceId}`,
          { invoice_id: invoiceId, }
        );
        // this.props.invoiceOff(event)
        if (cancelInvoiceStatus) {
          let update = store.getState().invoiceReducer.update;
          update();
          NotificationHandler("Success", "Successfully Deleted Invoice", "");
        } else {
          NotificationHandler("Warning", "Error Deleting Invoice", "");
        }
      }
    } 
    else if (this.props.title === "Delete Building") {
      const cancelBuildingStatus = await this.cancelModel(
        `${Constants.API_PATH_BUILDING_CANCEL}/${Number(new URLSearchParams(window.location.search).get("id"))}`,
        Number(new URLSearchParams(window.location.search).get("id"))    
      );
      if (cancelBuildingStatus.status === "Success") {
        NotificationHandler("Success", `Successfully Deleted Building`, "");
        history.push(`/${path[1]}/units`);
      } else {
        NotificationHandler(
          "Warning",
          `Error Deleting Building`,
          "Please make sure all building units are deleted first"
        );
      }
    } 
    // else if (this.props.title === "Delete Owner") {
    //   const cancelOwnerStatus = await this.cancelModel(
    //     `${Constants.API_PATH_OWNER_CANCEL}/${Number(new URLSearchParams(window.location.search).get("id"))}`,
    //     Number(new URLSearchParams(window.location.search).get("id"))
    //   );
    //   if (cancelOwnerStatus.status === "Success") {
    //     NotificationHandler("Success", `Successfully Deleted Owner`, "");
    //     history.push(`/${path[1]}/units`);
    //     // alert(cancelOwnerStatus.status);
    //   } else {
    //     NotificationHandler(
    //       "Warning",
    //       `Owner Deletion Unsuccessful`,
    //       "Already deleted owner"
    //     );
    //     history.push(`/${path[1]}/units`);
    //   }
    // } 
    else if (this.props.title === "Delete Lead") {
      const cancelTenantStatus = await this.cancelModel(
        `${Constants.API_PATH_TENANT_CANCEL}/${store.getState().tenantReducer.curId}`,
        store.getState().tenantReducer.curId
      );
      if (cancelTenantStatus.status === "Success") {
        NotificationHandler("Success", `Successfully Deleted Lead`, "");
      } else {
        NotificationHandler("Warning", `Error Deleting Lead`, "");
      }
      // window.location.reload();
    } 
    else if (this.props.title === "Delete Consolidated") {
      const consolidatedId = store.getState().consolidatedInvoiceReducer.curId;
      let consolidatedInvoice = await fetchGetJSON(`${Constants.API_PATH_CONSOLIDATED_GET}${consolidatedId}`)

      if(consolidatedInvoice.data.paid)
      {
        NotificationHandler("Error", "Consolidated Invoice has been paid", "You cannot delete consolidated invoices that are paid")
      }
      else if(consolidatedInvoice.data.marked_by && !consolidatedInvoice.data.confirmed_by) {
        NotificationHandler("Error", "Consolidated Invoice is currently pending approval", "You cannot delete consolidated invoices that are pending approval")
      }
      else
      {
        const cancelConsolidated = await fetchRequestJSON(
          "DELETE",
          `${Constants.API_PATH_CONSOLIDATED_DELETE}${store.getState().consolidatedInvoiceReducer.curId}`
        );
        if (cancelConsolidated.status === "Success") {
          let update = store.getState().consolidatedInvoiceReducer.listUpdate;
          update();
          NotificationHandler("Success", "Successfully Deleted Consolidated Invoice", "");
        } else {
          NotificationHandler("Warning", "Error Deleting Consolidated Invoice", ""); 
        }
      }
    } 
    else if (this.props.title === "Delete Corporate Contact") {
      const cancelCorpContactStatus = await this.cancelModel(
        `${Constants.API_PATH_CORP_CONTACT_CANCEL}/${Number(new URLSearchParams(window.location.search).get("contact_id"))}`, 
        Number(new URLSearchParams(window.location.search).get("contact_id"))
      );
      if (cancelCorpContactStatus.status === "Success") {
        NotificationHandler("Success", `Successfully Deleted Corporate Contact`, "");
        history.push(`/${path[1]}/contacts`);
      } else {
        NotificationHandler(
          "Warning",
          `Corporate Contact Deletion Unsuccessful`,
          "Already deleted corporate contact"
        );
      }
    } 
    else if (this.props.title === "Delete Parking Spot") {
      const id =  Number(new URLSearchParams(window.location.search).get("id"));

      const res = await fetchRequestJSON("PUT", Constants.API_PATH_PARKING_SPOT_DEACTIVATION, {
        parking_spot_id: id,
      });

      if(res.status == "Success") {
        //Indicate notification handler than close the modal 
        NotificationHandler('Success', 'Success', `Parking Spot ID ${id} succesfully deactivated`)
        //Redux below:
        let update = store.getState().parkingSpotReducer.profileUpdate;
        update()
        //Need to close the modal 
        //this.props.handleModalToggle();
        this.setState({ onoff: false });
      } else {
          NotificationHandler('Error', 'Error', 'There are active or upcoming parkings occupying the parking spot. Please remove them in order for deactivation to proceed.')
          //Need to close the modal 
          //this.props.handleModalToggle();
          this.setState({ onoff: false });
      }
    } 
    else if (this.props.title === "Cancel Parking") {
      const id =  Number(new URLSearchParams(window.location.search).get("id"));
      const res = await fetchRequestJSON("DELETE", `${Constants.API_PATH_PARKING_CANCEL}/${id}`);

      if(res.status == "Success") {
        //Indicate notification handler than close the modal 
        NotificationHandler('Success', 'Success', `Parking ID ${id} succesfully deactivated`)
        //Redux below:
        let update = store.getState().parkingReducer.profileUpdate;
        update()
        //Need to close the modal 
        //this.props.handleModalToggle();
        this.setState({ onoff: false });
      } else {
        NotificationHandler('Error', 'Error', `Contact Dev Team Immediately.`)
      }
    } 
    else {
      const cancelBookingStatus = await this.cancelModel(
        Constants.API_PATH_BOOKING_CANCEL,
        Number(new URLSearchParams(window.location.search).get("id")), "PUT"
      );
      if (cancelBookingStatus.status) {
        alert(cancelBookingStatus.status);
      }
    }
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-red-400 max-w-md py-8 px-10 mx-auto flex flex-col items-center justify-center">
          <ImCancelCircle className="text-red-600 text-3xl mb-3" />

          <span className="text-xl font-bold text-gray-800 text-center">{this.state.title}</span>
          <span className="text-xs text-center text-gray-400"></span>
          <form>
            <div className="grid grid-cols-1 gap-6 w-full my-3">
              <label className="inline-flex items-center">
                <span className="mr-4">{this.getConfirmationMessage()}</span>
                {/* <input
                  id="checked"
                  type="checkbox"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                /> */}
              </label>
            </div>
            <div className="flex flex-row justify-center w-full mt-6">
              <button
                id="cancel"
                onClick={() => this.handleClick()}
                className="transition duration-200 w-auto hover:bg-red-400 px-10 py-2 bg-red-500  rounded-sm text-white  shadow-sm  "
              >
                {this.state.title}{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(withHooksHOC(CancelModal));
