import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import { MdPlaylistAdd } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import Label from "../LabelWrap";
import LeadList from "../Leads/LeadList";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import LeadsQuickView from "./LeadQuickView";
import NewLead from "./NewLead";
import CorporateLeads from "./CorporateLeads";
// import NewLead from './NewLead'

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  toggleLead: boolean;
  editToggle: boolean;
  createLead: boolean;
};

class Leads extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "",
      check_in: "",
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      toggleLead: false,
      editToggle: false,
      createLead: false,
    };
  }

  handleLeadView = (state?: any) => {
    if (state && this.state.toggleLead === false) {
      this.setState({ toggleLead: true });
    } else {
      this.setState({ toggleLead: false });
    }
  };

  handleCreateLeadToggle = () => {
    let toggle = this.state.createLead;
    if (toggle === true) {
      this.setState({ createLead: false });
    } else {
      this.setState({ createLead: true });
    }
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let editFocus = "opacity-100";
    let bgFocus;

    if (this.state.editToggle === true || this.state.createLead === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={this.handleCreateLeadToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    return (
      <div className=" font-display bg-white lg:rounded-tl-lg  ">
        <LeadsQuickView
          handleModalToggle={this.handleLeadView}
          onoff={this.state.toggleLead}
          BookingId={1234}
        />
        <NewLead
          toggle={this.state.createLead}
          handleEditBarToggle={this.handleCreateLeadToggle}
        />
        {bgFocus}
        <BreadCrumb
          rootPage="Leads"
          subPage=""
          sideButton=""
          buttonAction={""}
        />

        <div className="fade-in px-3 mt-24 lg:mt-0 lg:px-10  ">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2">
            {/* Left Side  */}
            <div className="flex flex-col w-1/2 px-2">
              <button
                onClick={this.handleCreateLeadToggle}
                className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
              >
                <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                <span className=" text-md ">New Lead</span>
              </button>
            </div>

            {/* Right Side */}
            <div className="flex flex-row w-1/2 justify-end px-3">
              <button className="transition duration-100 border-2 border-solid w-36 border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center">
                <AiOutlineDownload className="  text-xl mr-1 self-center" />
                <span className=" text-xs self-center ">Download CSV</span>
              </button>
            </div>
          </div>

          <TabList warning={false}>
            <Label label="All Leads">
              <LeadList toggleLead={this.handleLeadView} />
            </Label>

            <Label label="Corporate">
              <CorporateLeads toggleLead={this.handleLeadView} />
            </Label>
          </TabList>
        </div>
      </div>
    );
  }
}

export default Leads;
