import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { formatFullDate, formatStringDate } from "../../utils/DateFormat";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { IdentityStore } from "aws-sdk";
import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";
import { ConsoleLogger } from "@aws-amplify/core";
import { getUserList } from "../../api-requests/api-gateway";
import { LoadingGeneral, LoadingInvoice } from "../../utils/LoadingComps/LoadingInvoice"
import {invoiceType, listview} from '../../models/modelList';
import PageSelector from "../../utils/PageSelector";

// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  toggleInvoice: any;
  showOnlyUnpaid: boolean;
};

type State = {
  blackout: boolean;
  invoices: Invoice[]; // This is the list for all the invoice objects so I can use them to populate the list
  search: string;
  filter_overdue: boolean;
  filter_booking: number;
  filter_issues: boolean;
  refresh_quick_view: boolean;
  users: any[];
  loaded: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  authorization: boolean;
};

type Invoice = {
  id: number;
  booking_id: number;
  due_date: string;
  payment: string;
  cost: number;
  marked_by: string;
  paid: boolean;
  confirmed_by: string;
};

class InvoiceList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      invoices: [],
      search: "",
      filter_overdue: false,
      filter_booking: 0,
      filter_issues: false,
      refresh_quick_view: false,
      users: [],
      loaded: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      authorization: true,
    };
  }

  // Toggle popup function
  handleClick = (id: number) => {
    // console.log(id)
    store.dispatch({ type: "invoiceIdTransfer", id: id });
    this.props.toggleInvoice();
  };

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
    
  }

  setPerPage = async (val: number) => {
    await this.setState({perpage: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState({
          curpage: this.state.totalpages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }
  
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // This is where all the information is retrieved from the api and loaded into the state
  componentDidMount = async () => {
    store.dispatch({ type: "invoiceListUpdate", listUpdate: this.updateList });
    let userList = await getUserList();
    this.setState({
      users: userList.Users
    })
    this.updateList();
  };

  updateList = async (bookingId?: string) => {
    let filters:any = {}
    if (this.state.search != "") {
      filters["id"] = this.state.search;
      await this.setState({curpage: 1})
    }
    if (this.state.filter_overdue) {
      filters["overdue"] = true;
    }
    if (this.state.filter_booking != 0) {
      filters["booking"] = this.state.filter_booking;
    }

    let fetchInvoiceListURL = `${Constants.API_PATH_LISTVIEW_INVOICES}?perpage=${this.state.perpage}&page=${this.state.curpage}
    &overdue${this.state.filter_overdue ? '=true': ''}&id${this.state.search ? `=${this.state.search}`: ''}&booking${this.state.filter_booking != 0 ? `=${this.state.filter_booking}`: ''}`

    if(this.props.showOnlyUnpaid) {
      fetchInvoiceListURL += `&paid=${!this.props.showOnlyUnpaid}`
    }
    
    const allInvoices: listview<invoiceType[]> = await fetchGetJSON(fetchInvoiceListURL)
    
    let invoiceList = []; // Make a copy of the state invoices list so I can reset the state and rerender the page

    if (allInvoices.status != "Success") {
      this.setState({
        loaded: true,
        authorization: allInvoices.status == "Error Unauthorized" ? false : true
      })
      return;
    }
    this.setState({totalpages: allInvoices.data.pages})

    if(allInvoices.data.rows) {
      for (let invoice of allInvoices.data.rows) {
        // Set the objects attributes to the proper values according to info retrieved from api
        let invoiceObj = {
          id: invoice.id,
          due_date: invoice.due,
          cost: invoice.amount,
          marked_by: invoice.marked_by,
          payment: invoice.payment_method_invoice,
          booking_id: invoice.booking_id,
          paid: invoice.paid,
          confirmed_by: invoice.confirmed_by,
        };
        // Push finished object onto list copy
        invoiceList.push(invoiceObj);
      }
    }

    console.log("Success, loading Invoice List: ")
    console.log(invoiceList)
    // Loop through all the invoices retrieved from the api call and make invoice objects for the list of invoices
    // Set state from copy to the invoice list within the state so the list is rerendered
    this.setState({ invoices: invoiceList, loaded: true });
  };

  openSettings = () => {
    // alert("Settings");
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    // const { history } = this.props;
    let Blackout = this.state.blackout;

    let blackoutStyles = "transition-all duration-200";
    let Warnings = "";
    let blackoutToggle = (
      <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"} />
    );

    if (Blackout === true) {
      blackoutStyles = "transition-all duration-200 opacity-10 ";
      Warnings = "Do Not Book";
      blackoutToggle = (
        <FaPowerOff
          className={"text-2xl text-green-500 hover:text-green-300"}
        />
      );
    }

    let TableRows = <></>;
    var rows = [];
    let today = new Date();
    // let testDueDate = new Date(2021, 12, 8, 4, 20);
    var loading: any = [];
    for (let i = 0; i < 10; i++) {loading.push(<LoadingInvoice/>)}
    let altBg = "";
    
    let sorted = this.state.invoices;
    sorted.sort((a,b) => b.id - a.id);
    // Loops through the state invoice list (after it is loaded) and displays them
    for (var i = 0; i < sorted.length; i++) {
      // if (this.props.showOnlyUnpaid && sorted[i].paid) { continue; }
      let SpecialStyle = "text-gray-700";
      let SpecialStyle2 = "text-gray-700";
      let Special = <></>;
      let invoiceId = sorted[i].id;
      if (new Date(sorted[i].due_date) < today) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-red-400 mr-10";
        Special = (
          <span className="bg-red-500 py-1 px-4 rounded-full text-white">
            Overdue
          </span>
        );
      }

      if (sorted[i].paid && !sorted[i].confirmed_by) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-yellow-400 mr-10";
        Special = (
          <div className="bg-yellow-500 py-1 px-2 rounded-full text-white text-center">
            Pending Approval
          </div>
        );
      }

      if (sorted[i].paid && sorted[i].confirmed_by) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-green-400 mr-10";
        Special = (
          <span className="bg-green-400 py-1 px-4 rounded-full text-white">
            Confirmed
          </span>
        );
      }

      let marked_by = "TBD"
      if (sorted[i].marked_by && sorted[i].marked_by != "") {
        if (sorted[i].marked_by == "0") {
          marked_by = "Auto Marked"
        } else {
          marked_by = sorted[i].marked_by;
          let user = this.state.users.find((user) => user.Username == sorted[i].marked_by)
          if (user) {
            let name = user.Attributes.find((attr: any) => attr.Name == "name")
            if (name) {
              marked_by = name.Value;
            }
          }
        }
      }

      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }
      let day = sorted[i].due_date.split('T')[0]

      TableRows = (
        <tr
          key={i.toString()}
          className={
            altBg +
            " grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"
          }
        >
          {/* Suite Name */}

          {/* IMPORTANT */}
          {/* These onclick properties are set to a function passed from parent and are rendered at all times */}
          {/* This means I cant send it an id from the invoice object it will always call all of them and end up displaying the first one */}
          <td
            onClick={() => this.handleClick(invoiceId)}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-16 py-4 sm:pl-4"
            }
          >
            <span className="sm:hidden">ID: </span>
            {sorted[i].id}
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.handleClick(invoiceId)}
            className={
              blackoutStyles +
              "w-full sm:w-36 font-light text-sm text-gray-500 sm:px-1 sm:py-4"
            }
          >
            <span className="sm:hidden">Due Date: </span>
            <span className={SpecialStyle2}>{formatStringDate(day)}</span>{" "}
          </td>

          {/* Min. Stay */}

          <td
            onClick={() => this.handleClick(invoiceId)}
            className={
              blackoutStyles +
              "w-full sm:w-32 font-thin  text-gray-600 sm:px-1  py-2 sm:py-4"
            }
          >
            <span className="sm:hidden text-sm">Cost: </span>
            {formatAmountForDisplay(sorted[i].cost, "CAD", false)}{" "}
          </td>

          {/* Bedrooms */}

          <td
            onClick={() => this.handleClick(invoiceId)}
            className={
              blackoutStyles +
              "w-full sm:w-32 font-medium  text-gray-600 sm:px-1  py-0 sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">Marked By: </span>{" "}
            {marked_by}
          </td>

          {/* Type */}

          <td
            onClick={() => this.handleClick(invoiceId)}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-28 font-normal  text-gray-400 sm:px-1 py-2  sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">
              Payment Method:{" "}
            </span>
            {sorted[i].payment}
          </td>

          {/* Booking Warnings */}

          <td
            onClick={() => this.handleClick(invoiceId)}
            className="hidden sm:table-cell sm:w-32 text-red-400 px-1 py-4 text-sm font-semibold"
          >
            {Special}
          </td>

          {/* Booking Warnings */}

          <td className=" hidden sm:table-cell sm:w-28 px-1 py-2 sm:py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                onClick={this.openSettings}
                className={
                  blackoutStyles +
                  "text-gray-700 text-2xl mr-2 hover:text-gray-500"
                }
              />
            </div>
          </td>
          
        </tr>
      );

      // if(sorted[i].exists){
      rows.push(TableRows);
      // }
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col sm:flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full sm:w-1/4  py-1 mb-3 sm:mb-0 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                onChange={this.handleInputChange}
                className="text-sm searchBox"
                placeholder="Search by invoice "
                id="search"
                value={this.state.search}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>

            <span className="py-1 sm:px-4 font-medium margin text-gray-500">
              Filters:
            </span>
            <div className="flex flex-row flex-wrap">
              <button 
                className={
                  (this.state.filter_overdue ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_overdue: !this.state.filter_overdue,
                  }, this.updateList)
                }}
              >
                Overdue
              </button>
              <button 
                className={
                  (this.state.filter_booking == 0 ? "bg-white hover:bg-gray-100 text-gray-500" : 
                  (this.state.filter_booking == 1 ? "text-green-500 bg-green-200 hover:bg-green-100" : "text-red-500 bg-red-200 hover:bg-red-100")) +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  let num = this.state.filter_booking;
                  if (num === 2) {
                    num = 0;
                  } else {
                    num++;
                  }
                  this.setState({
                    filter_booking: num,
                  }, this.updateList)
                }}
              >
                Booking
              </button>
              <button 
                className={
                  (this.state.filter_issues ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_issues: !this.state.filter_issues,
                  }, this.updateList)
                }}
              >
                Issues
              </button>
            </div>
            <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10,20,30,40,50]}
            />
          </div>
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="hidden sm:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 pl-4">ID</th>
              <th className="py-2  ">Due Date</th>
              <th className="py-2  ">Cost</th>
              <th className="py-2  ">Marked By</th>
              <th className="py-2  ">Payment Method</th>
              {/* Book Warnings */}
              <th className="py-2  "></th>
              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={6} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(InvoiceList);