export type State = {
  listUpdate: any;
};

const initialState: State = {
  listUpdate: undefined,
};

type Action = { type: "fileListUpdate"; listUpdate: any }

export const fileReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "fileListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    default:
      return state;
  }
};

export default fileReducer;
