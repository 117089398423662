import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDate, formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";

type Props = {
  handleToggleChange: any;
  handleDateChange: any;
  handleInputChange: any;
  leadData: any;
  toggle: any;
  handleEditBarToggle: any;
};

type EditLeadState = {};

class EditLead extends Component<Props, EditLeadState> {
  constructor(props: any) {
    super(props);
  }

  //     handleCorpToggle = (event) => {
  //       event.preventDefault();
  //       if ( this.state.corporate === false ) {
  //          this.setState({corporate: true});
  //       }

  //       else {
  //          this.setState({corporate: false});
  //      }

  //     }

  handleSubmit = (event: any) => {
    event.preventDefault();
    alert("Submits Form / State of Parent");
    this.props.handleEditBarToggle();
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Lead";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry = [];
    let poop = false;

    if (this.props.leadData.corporate === true) {
      ManagedModel = "text-gray-300 ";
      RentalModel = "font-bold text-gray-600";
      modelEntry = [
        <label className=" appear block pl-3 mb-8">
          <span className="text-gray-400 text-xs">Company Name</span>
          <input
            id="company_name"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.company_name}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>,
      ];
    } else {
      ManagedModel = "font-medium text-gray-600 ";
      RentalModel = "text-gray-300 ";
      modelEntry = [<></>];
    }

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Name</span>
          <input
            id="contact_name"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.contact_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Email</span>
          <input
            id="lead_email"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.lead_email}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Phone</span>
          <input
            id="lead_phone"
            onChange={this.props.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.props.leadData.lead_phone}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Lead Type</span>
          <select
            id="lead_type"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.lead_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Email</option>
            <option>Phone Call</option>
            <option>Walk-In</option>
            <option>Other</option>
          </select>
        </div>

        <label className="opacity-10 pointer-event-none block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Date</span>
          <input
            id="contact_date"
            onChange={this.props.handleInputChange}
            value={formatFullDate(this.props.leadData.contact_date)}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Requested Check In</span>
          <input
            id="requested_check_in"
            onChange={this.props.handleDateChange}
            value={inputISO(this.props.leadData.requested_check_in)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Requested Check out</span>
          <input
            id="requested_check_out"
            onChange={this.props.handleDateChange}
            value={inputISO(this.props.leadData.requested_check_out)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className=" pl-3 flex flex-row items-center justify-start mb-5  ">
          <span
            className={
              " transition-all duration-600 text-gray-600 text-sm mr-3"
            }
          >
            Flexible Dates
          </span>

          <ToggleSwitch
            id="flexible_dates"
            handleStateToggle={this.props.handleToggleChange}
            className="justify-center align-center"
            size="lg"
            onoff={this.props.leadData.flexible_dates}
          />
        </div>

        <div className="  pl-3 flex flex-row items-center justify-start mb-5 ">
          <span
            className={
              " transition-all duration-600 text-gray-600 text-sm mr-3"
            }
          >
            Corporate
          </span>

          <ToggleSwitch
            id="corporate"
            handleStateToggle={this.props.handleToggleChange}
            className="justify-center align-center"
            size="lg"
            onoff={this.props.leadData.corporate}
          />
        </div>

        {modelEntry}

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Suite Type</span>
          <select
            id="suite_type"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.suite_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Studio</option>
            <option>1 Bedroom</option>
            <option>1 Bed + Den</option>
            <option>2 Bedrooms</option>
            <option>2 Bed + Den</option>
            <option>3 Bedrooms</option>
            <option>Penthouse</option>
          </select>
        </div>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Suite Type</span>
          <select
            id="purpose_of_stay"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.purpose_of_stay}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Relocation</option>
            <option>Pleasure</option>
            <option>Option (Specify In Notes)</option>
          </select>
        </div>

        <div className="flex flex-row items-center justify-start pl-3 mb-5 ">
          <span
            className={
              " transition-all duration-600 text-gray-600 text-sm mr-3"
            }
          >
            Parking Requested
          </span>

          <ToggleSwitch
            id="parking_requested"
            handleStateToggle={this.props.handleToggleChange}
            className="justify-center align-center"
            size="lg"
            onoff={this.props.leadData.parking_requested}
          />
        </div>

        <div className=" pl-3 flex flex-row w-full justify-around">
          <label className=" mb-5 w-1/3 ">
            <span className="text-gray-400 text-xs">Number Of Adults</span>
            <input
              id="number_of_adults"
              onChange={this.props.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.props.leadData.number_of_adults}
              type="number"
              className="mt-0 py-1  w-full   px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <label className="  mb-5 w-1/3">
            <span className="text-gray-400 text-xs">Number Of Children</span>
            <input
              id="number_of_children"
              onChange={this.props.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.props.leadData.number_of_children}
              type="number"
              className="mt-0 py-1  w-full  px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Country Of Origin</span>
          <input
            id="country_of_origin"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.country_of_origin}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">How They Heard</span>
          <select
            id="how_they_heard"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.how_they_heard}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Corporate</option>
            <option>Friends/Colleqgues</option>
            <option>Search Engine</option>
            <option>Referral</option>
            <option>Rent Board</option>
            <option>Repeat Client</option>
            <option>Repeat Corporate</option>
            <option>Option (Specify In Notes)</option>
          </select>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Budget Per Night</span>
          <input
            id="budget_per_night"
            onChange={this.props.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.props.leadData.budget_per_night}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Unit Of Interest</span>
          <input
            id="unit_of_interest"
            onChange={this.props.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.props.leadData.unit_of_interest}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Additional Notes</span>
          <textarea
            id="lead_notes"
            onChange={this.props.handleInputChange}
            value={this.props.leadData.lead_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.handleSubmit}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.props.leadData.lead_id}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={this.handleSubmit}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default EditLead;

export function inputISO(date: any) {
  if (date === "") {
    date = new Date();
    return date;
  } else {
    date = new Date(date);
    // date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 );
  }

  let year = date.getFullYear().toString();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let day = "";
  let month2 = "";

  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt.toString();
  }
  if (month < 10) {
    month2 = "0" + month;
  } else {
    month2 = month.toString();
  }

  return year + "-" + month2 + "-" + day;
}
