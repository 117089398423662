import { Component } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { RiExchangeDollarLine } from "react-icons/ri";
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import ToggleSwitch from "../ToggleSwitch";
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

interface Props {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  bookingId: number;
  ErrorNote: any;
  checkIn: string;
}

interface ModalState {
  title: string;
  onoff: boolean;
  rate_change_date: any;
  rate: number;
  monthlyRate: number;
  unit: string;
  toggleDate: boolean;
  changeRateDate: Date;
}

class RateModal extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      rate_change_date: "",
      rate: 0,
      monthlyRate: 0,
      unit: "",
      toggleDate: false,
      changeRateDate: new Date(),
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff })
    }
  };

  handleConfirm = async() => {
    let check = !this.state.toggleDate
    await this.setState({
      toggleDate: check,
    })
    this.setState({ changeRateDate: check == true ? new Date(this.props.checkIn) : new Date() })
  }

  // TODO: Refactor this function so that it doesn't use bind().
  handleRateChange = (event: any) => {
    event.preventDefault();
    const monthlyRate = event.target.value * 30;
    console.log(monthlyRate)
    this.setState({
      rate: event.target.value,
      monthlyRate: monthlyRate
    });
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000);
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleSubmit = async (event: any) => {
    let NotificationHandler = this.props.ErrorNote;
    event.preventDefault();

    const convertToUTC = dayjs.utc(this.state.changeRateDate, 'YYYY-MM-DD')
    console.log(convertToUTC.format('YYYY-MM-DDTHH:mm:ss'))
    const rateChangeDate = await fetchRequestJSON("PUT", Constants.API_PATH_BOOKING_EDIT_RATE, {
      bookingId: this.props.bookingId,
      rateChange: this.state.monthlyRate,
      timeToChange: convertToUTC.format('YYYY-MM-DDTHH:mm:ss')
    })

    if (rateChangeDate.status === "Success") {
      NotificationHandler(
      "Success",
      `Successfully Changed Booking Rate`,
      "Refresh Booking Profile to see when rate change will take into effect"
      );
    } else {
      NotificationHandler(
        "Error",
        `Error Changing Rate contact Dev Team`,
        `${rateChangeDate.status}`
        );
    }
  }

  render() {
    if (!this.props.onoff) {
      return null;
    }

    // let inactive = "";
    // if (this.state.building === ''){
    //   inactive = "opacity-50 pointer-events-none "
    // }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600
          bg-opacity-40 z-40 "
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <RiExchangeDollarLine className="text-gray-600 text-3xl mb-3" />

          <span className="text-xl font-bold text-gray-800 text-left">{this.state.title}</span>
          <span className="text-xs text-center text-gray-400"></span>
          <form>
            <div className="grid grid-cols-3 gap-6 w-full my-5">
              <label className="block ">
                <span className="text-gray-400 text-xs">New <strong>Nightly</strong> Rate:</span>
                <input
                  id="rate"
                  onChange={this.handleRateChange}
                  onWheel={(e) => {e.currentTarget.blur()}}
                  value={this.state.rate}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="Enter new nightly rate"
                />
                {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
              </label>
              <label className="block ">
                <span className="text-gray-400 text-xs">Monthly Rate:</span>
                <input
                  id="rate"
                  onChange={this.handleRateChange}
                  onWheel={(e) => {e.currentTarget.blur()}}
                  value={this.state.monthlyRate}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="Please enter the new rate here"
                  disabled
                />
                {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
              </label>
            </div>
            <div className="flex flex-row mb-2 sm:pr-2">
              <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>Toggle to change rate for full booking</span>
              <ToggleSwitch
                id="selectDate"
                handleStateToggle={this.handleConfirm}
                className="justify-center align-center"
                size="lg"
                onoff={this.state.toggleDate}
              />
            </div>
 
            {/* {
              this.state.toggleDate && */}
                <div className="grid grid-cols-3 gap-6 w-full my-5">
                <label className="block ">
                  <span className="text-gray-400 text-xs">Select <strong>Date</strong> for Rate Change</span>
                  <input
                      id="start date"
                      onChange={(input) => {
                        this.setState({
                          changeRateDate: new Date(input.target.value)
                        });
                      }}
                      type="date"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                  />
                  {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
                </label>
                {/* <label className="block ">
                  <span className="text-gray-400 text-xs">Date Selected (in EST):</span>
                  <input
                    id="rate"
                    onChange={this.handleRateChange}

                    value={dayjs(this.state.changeRateDate).tz('America/Toronto').format('LLL')}
                    type="string"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder="Please enter the new rate here"
                    disabled
                  /> 
                  <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> 
                </label> */}
              </div>
            {/* } */}
            <div className="flex flex-row justify-start w-full mt-6">
              <button
                // onClick={this.props.handleModalToggle}
                onClick={this.handleSubmit}
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Change Rate
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(RateModal);
