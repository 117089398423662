import React, { Component } from "react";
import EditOptionPanel from "../../EditOptionPanel";
import { MdPlaylistAdd } from "react-icons/md";
import {
  Link,
  useHistory,
  withRouter,
  RouteComponentProps,
  useRouteMatch,
} from "react-router-dom";
import Label from "../../LabelWrap";
import StatsBar from "../../StatsBar";
import AgreementModal from "../../AgreementModal";
import { formatFullDate, formatDay } from "../../../utils/DateFormat";
import { RiChatForwardLine, RiCalendarCheckLine } from "react-icons/ri";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import UnitBubble from "../../UnitBubble";
import Tooltip from "../../Tooltip";
import ToolTipAlt from "../../ToolTipAlt";
import { GoSignIn } from "react-icons/go";
import { BiBookAdd, BiCheck } from "react-icons/bi";
import OccupancyRadial from "./Radial";
import OccupancyChart from "./BarChart";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import DashBar from "../DashBar";
import { bookingType, unitType, tenantType } from "../../../models/modelList";
import dayjs from "../../../utils/Dayjs"

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {
  userData: any;
  history: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
  empty_units: number;
  occupancy: number;
  today_check_ins: number;
  today_check_outs: number;
  checkins: bookingType[];
  units: unitType[];
  tenants: tenantType[];
};

class OfficeDash extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",
      empty_units: 8,
      occupancy: 78,
      today_check_ins: 0,
      today_check_outs: 0,
      checkins: [],
      units: [],
      tenants: [],
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  componentDidMount = async () => {
    let checkins = await fetchGetJSON(Constants.API_PATH_TODAY_GET_CHECK_IN);
    let checkouts = await fetchGetJSON(Constants.API_PATH_TODAY_GET_CHECK_OUT);
    let vacants = await fetchGetJSON(Constants.API_PATH_TODAY_GET_VACANT);
    let units = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
    let tenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);

    console.log(checkins);

    this.setState({
      checkins: checkins.data.check_ins,
      today_check_ins: checkins.data.count,
      today_check_outs: checkouts.data.count,
      empty_units: vacants.data.count,
      units: units.data,
      tenants: tenants.data,
    });
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-full lg:h-screen  lg:rounded-tl-lg pt-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1 w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""} />

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Office Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          <div className="  w-full">
            <div className="pt-0  grid  grid-cols-1 sm:grid-cols-6 ">
              <div className=" col-start-1 col-end-5 flex flex-col">
                <span className=" pl-1 text-4xl leading-tight font-medium text-gray-500">
                  Good {dayTime},{" "}
                  <span className="font-thin text-gray-700">
                    {this.props.userData.user_f_name}
                  </span>
                </span>

                <span className=" ml-1 font-thin text-gray-600 font-sofia">
                  Let&apos;s Get To Work Today
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 w-full  my-5  ">
              <div className="grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-4 gap-4  px-1 py-0 mb-4">
                {/* <StatBubbles data={this.state.empty_units}title={"Vacant Units"} subtitle={"As of Today"} button={"New Booking"} linkEnd={'Bookings/New'} />
          <StatBubbles data={this.state.occupancy}  title={"Occupancy"} subtitle={"As of Today"} button={"Graph"} /> */}
                <StatBubbles
                  data={this.state.today_check_ins}
                  title={"Check Ins"}
                  subtitle={"Today"}
                  button={"View Check Ins"}
                  linkEnd={"Check Ins"}
                />
                <StatBubbles
                  data={this.state.today_check_outs}
                  title={"Check Outs"}
                  subtitle={"Today"}
                  button={""}
                  linkEnd={""}
                />
              </div>

              <div className=" grid grid-cols-1 gap-4  px-1 py-0    ">
                <div className="  ">
                  <div className="flex flex-row justify-between w-full  items-end">
                    <span className=" pl-1 text-gray-700 font-medium text-xl">
                      Today's Check Ins:
                    </span>
                  </div>

                  <div className=" my-3 h-96 w-auto overflow-y-auto shadow-md rounded-lg  w-full scroll-hide ">
                    <table
                      style={{ borderSpacing: "0" }}
                      className="border-separate  relative w-full table-auto bg-white     "
                    >
                      <thead className="border-b border-gray-400 ">
                        <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                          <th className="sticky top-0  py-6 pl-5  font-thin border-b border-gray-400  ">
                            ID
                          </th>
                          <th className="sticky top-0  py-4   font-thin border-b border-gray-400  ">
                            Tenant
                          </th>
                          <th className="sticky top-0  py-4   font-thin border-b border-gray-400  ">
                            Unit
                          </th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Check In Date
                          </th>
                          {/*
                          <th className="sticky top-0  py-3 font-thin border-b border-gray-400 ">
                            Confirmed Time
                          </th>
                          */}
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            KeyCode{" "}
                          </th>
                          {/*
                          <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                            Cleaned
                          </th>
                          <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 w-32 ">
                            Turnover Form Reviewed
                          </th>
                          <th className="sticky top-0  py-3 font-thin border-b border-gray-400 bg-white w-32  ">
                            Ops Manager Sign Off
                          </th>
                          */}
                          <th className="sticky top-0 py-3 font-thin border-b border-gray-400 bg-white w-48 ">
                            Notes
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y">
                        <CheckIns data={this.state} />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Heading Info Area  */}
        </div>
      </div>
    );
  }
}

export default withRouter(OfficeDash);

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let currentPage = props.linkEnd;
  // path.endsWith("Dashboard")

  if (path.includes("/Dashboard/" + currentPage)) {
    currentPageStyle = "border-b border-green-500 text-gray-800";
  } else if (path.endsWith("Dashboard") && props.text == "Overview") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={`${path}/${props.linkEnd}`}>{props.text}</Link>
    </div>
  );
}

function StatBubbles(props: any) {
  let Button = <></>;
  let Data = props.data;

  let { path, url } = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;
  path = path.replace("dashboard", linkEnd);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    history.push(path);
  };

  if (props.button === "New Booking") {
    Button = (
      <button
        onClick={handleSubmit}
        className=" mr-4 whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center"
      >
        <MdPlaylistAdd className=" text-white text-xl mr-1 self-center" />
        <span className=" text-sm ">{props.button}</span>
      </button>
    );
  }

  if (props.button === "View Check Ins") {
    Button = (
      <button
        onClick={handleSubmit}
        className=" mr-4 whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center"
      >
        <GoSignIn className=" text-white text-lg mr-2 self-center" />
        <span className=" text-sm ">{props.button}</span>
      </button>
    );
  }

  if (props.button === "Graph") {
    Button = <OccupancyRadial data={props.data} />;
    Data = Data + "%";
  }

  return (
    <div className="grid grid-cols-2 gap-3 appear inline  bg-white shadow-md   py-5 px-5 rounded-lg">
      <div className="flex flex-col ">
        <span className="text-green-500 text-4xl leading-none font-bold  ">
          {Data}
        </span>

        <span className="text-green-700  text-base pt-1">{props.title}</span>
        <span className=" text-sm font-thin text-gray-400   ">
          {props.subtitle}
        </span>
      </div>

      <div className="flex flex-col items-center justify-center">{Button}</div>
    </div>
  );
}

function LeadAdd(props: any) {
  // Takes Information from the current rows ID
  // then fills out information in the manual booking form flow
  // essentially same flow, except fields are automatically filled

  let { path, url } = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;

  const handleLeadAdd = (event: any) => {
    event.preventDefault();
    alert("Auto Pulls Data Based On Row ID");
    let newPath = path.replace("dashboard", "bookings/new/" + linkEnd);
    history.push(newPath);
  };

  return (
    <BiBookAdd
      onClick={handleLeadAdd}
      className={
        "text-green-500 text-2xl mr-2 cursor-pointer hover:text-green-400"
      }
    />
  );
}

export function CheckIns(props: any) {
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;

  let { path, url } = useRouteMatch();
  console.log('path: ' + path)
  console.log('url: ' + url)
  let history = useHistory();

  const toBooking = (booking_id: number) => {
    // event.preventDefault();
    // alert("Auto Pulls Data Based On Row ID");
    let newPath = path.replace("dashboard", "bookings/bookingprofile");
    newPath = newPath.replace('/office-dash', `?id=${booking_id}`);
    history.push(newPath);
  };

  console.log(props.data.checkins);

  for (var i = 0; i < props.data.checkins.length; i++) {
    if (i % 2 != 1) {
      altBg = "bg-white";
    } else {
      altBg = "bg-white";
    }

    let bookings: bookingType = props.data.checkins[i];

    let curtenant: tenantType = props.data.tenants.find(
      (tenant: tenantType) => tenant.id == bookings.tenant_id
    );
    let curunit: unitType = props.data.units.find(
      (unit: unitType) => unit.id == bookings.unit_id
    );

    //when linking to back end: remember to switch the key to the unique invoice id from the backend

    Payments = (
      <tr
        key={i.toString()}
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
        onClick={() => toBooking(bookings.id)}
      >
        <td
          className=" cursor-pointer border-b  px-5 py-10 font-thin w-12 text-green-600"
        >
          {props.data.checkins[i].id}
        </td>
        <td
          className="cursor-pointer border-b  py-3 font-thin w-56"
        >
          <div className="grid grid-cols-1   ">
            <span className="truncate ">
              {curtenant.first_name + " " + curtenant.last_name}
            </span>
            <span className="text-gray-400 truncate ">{curtenant.email}</span>
          </div>
        </td>
        <td
          className=" cursor-pointerborder-b  py-3 text-gray-400 font-thin  "
        >
          <span className=" truncate cursor-pointer h-6 mr-2 bg-gray-700 text-white font-medium text-xs rounded-full py-1  inline-flex items-center text-center px-5">
            {" "}
            {curunit.suite_name}{" "}
          </span>
        </td>

        <td
          className=" cursor-pointer border-b  py-3  font-thin text-gray-500  "
        >
          {dayjs(bookings.check_in).format('LL')}
        </td>
        {/*
        <td
          className="cursor-pointer border-b  py-3  font-thin text-gray-500 text-lg "
        >
          5 pm
        </td>
        */}
        <td
          className="cursor-pointer  border-b py-3  font-thin  text-blue-500  "
        >
          {props.data.checkins[i].keyCode}
        </td>
        {/*
        <td
          className="cursor-pointer  border-b py-3  font-thin text-center  text-3xl text-green-500  "
        >
          {" "}
          <BiCheck />{" "}
        </td>
        <td
          className=" cursor-pointer border-b py-3  font-thin text-center  text-3xl text-green-500  "
        >
          {" "}
          <BiCheck />{" "}
        </td>
        <td
          onClick={toBooking}
          className=" cursor-pointer border-b py-3  font-thin text-center  text-3xl text-green-500  "
        >
          {" "}
          <BiCheck />{" "}
        </td>
        */}
        <td
          className=" cursor-pointer border-b py-3  font-thin  text-sm text-gray-500  "
        >
          {" "}
          {bookings.booking_notes}
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}
