export type State = {
  expenseUpdate: any;
  transactionUpdate: any;
};

const initialState: State = {
  expenseUpdate: undefined,
  transactionUpdate: undefined,
};

type Action =
  | { type: "expenseListUpdate"; listUpdate: any }
  | { type: "transactionListUpdate"; listUpdate: any }

export const financeReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "expenseListUpdate":
      state.expenseUpdate = action.listUpdate;
      return state;
    case "transactionListUpdate":
      state.transactionUpdate = action.listUpdate;
      return state;
    default:
      return state;
  }
};

export default financeReducer;
