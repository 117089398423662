import { Component } from "react";

export function LoadingBar(props: any) {
    if (props.replace) {
        return <div className="bg-gray-400 animate-pulse rounded-full w-max"  style={{color: "#0000"}}>{props.replace}</div>
    }
    return <div className="bg-gray-400 animate-pulse h-2 w-full rounded-full"></div>
}

// export function LoadingCircle(props: any) {

// }