export type State = {
  fname: string;
  lname: string;
  email: string;
  password: string;
  isRegisterPending: boolean;
  isRegisterSuccess: boolean;
  registerError: boolean;
  helperText: string;
};

const initialState: State = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  isRegisterPending: false,
  isRegisterSuccess: false,
  registerError: false,
  helperText: "",
};

type Action =
  | {
      type: "registerSuccess";
      message: "Successfully created the accunt";
      user: any;
    }
  | { type: "registerError"; message: "Error creating the account" };

export const registerReducer = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "registerSuccess":
      return {
        ...state,
        isRegisterSuccess: true,
        registerError: false,
        helperText: action.message,
        fname: action.user.fname,
        lname: action.user.lname,
        email: action.user.email,
        password: action.user.password,
      };
    case "registerError":
      return {
        ...state,
        isRegisterPending: false,
        registerError: true,
        helperText: action.message,
      };
    default:
      return state;
  }
};

export default registerReducer;
