import React, { useEffect, useState } from "react";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import dayjs from "../../utils/Dayjs";

type Props = {
    bookingId: number;
    historyModalToggle:any;
}

const radioInline = {
    insetInlineStart: '-0.375rem' // Note the use of camelCase here
};


function BookingHistory(props: Props) {
    const [bookingHistory, setBookingHistory] = useState([]);
    
    useEffect(() => {
        loadData()
    }, [])
    
    const loadData = async () => {
        //Grab booking ID from props
        const bookingHistory = await fetchGetJSON(`${Constants.API_PATH_LOGGING_BOOKING_HISTORY_FULLINFO}?bookingID=${props.bookingId}`)

        setBookingHistory(bookingHistory.data)
    }

    return (
        <div className="slide-in-blurred-top  font-display border px-8 py-4">
            {
                bookingHistory.length ? 
                    <ol className="relative border-s border-green-300 mt-5">        
                        {
                            bookingHistory.map((historyLog) => {
                                return(
                                    <li className="mb-10 ms-4">
                                        <div className="absolute w-3 h-3 bg-green-500 rounded-full mt-1.5 border historyLoggingInlineStart border-white" 
                                        style={radioInline}></div>
                                        <time className="mb-1 text-sm font-normal leading-none text-green-400">{dayjs(historyLog.created_at).format('LL h:mm:ss.SSS A')}</time>
                                        <h3 className="text-lg font-semibold text-gray-900 ">Route Called: {historyLog.endpoint} by {historyLog.changedBy}</h3>
                                        <p className="mb-4 text-base font-normal text-gray-500">Details: {JSON.stringify(historyLog.changeDetails.status)}</p>
                                        {
                                            historyLog.changeDetails.data && 
                                            <button onClick={() => props.historyModalToggle(historyLog.changeDetails.data, `Route Called: ${historyLog.endpoint} by ${historyLog.changedBy}`)} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-green-400 focus:z-10 focus:ring-4 focus:outline-none 
                                                focus:ring-gray-100 focus:text-green-700">More Details
                                                <svg className="w-3 h-3 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                                </svg>
                                            </button>
                                        }
                                    </li>
                                )
                            }) 
                        }          
                    </ol>
                :
                <>
                    <h2 className="text-center text-green-500 text-lg font-extrabold">
                        No History exists for this Booking.
                    </h2>
                </>
            }

        </div>

    )
}

export default BookingHistory;
