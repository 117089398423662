export type State = {
  username: string;
  password: string;
  isLoginSuccess: boolean;
  loginError: boolean;
  helperText: string;
  // formSubmmited: boolean
};

const initialState: State = {
  username: "",
  password: "",
  isLoginSuccess: false,
  loginError: false,
  helperText: "",
};

type Action =
  | { type: "loginSuccess"; message: string; user: any }
  | { type: "loginError"; message: "Invalid email or password" }
  | { type: "logOut"; message: string };

export const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "loginSuccess":
      return {
        ...state,
        isLoginSuccess: true,
        loginError: false,
        helperText: action.message,
        username: action.user.username,
        password: action.user.password,
      };
    case "loginError":
      return {
        ...state,
        loginError: true,
        helperText: action.message,
      };
    case "logOut":
      return {
        ...state,
        isLoginSuccess: false,
      };
    default:
      return state;
  }
};

export default reducer;
