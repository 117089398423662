import { Component } from "react";
import Chart from "react-apexcharts";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";

type Props = {
  data: any;
};

type State = {
  options: object;
  series: Array<any>;
};

class OccupancyRadial extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: {
        colors: ["#10b981"],
        dataLabels: {
          enabled: false,
        },

        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: true,
                offsetY: -2,
                fontSize: "20px",
                color: "#047857",

                fontFamily: "Circular Std, sans-serif",
              },
            },
          },
        },
      },

      series: [this.props.data],
    };
  }

  render() {
    return (
      <div className=" w-full h-full transform -translate-x-5 -translate-y-5">
        <div className="absolute">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="radialBar"
            height="225px"
          />
        </div>
      </div>
    );
  }
}

export default OccupancyRadial;
