import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import ToggleSwitch from "../ToggleSwitch";
import { Link, withRouter } from "react-router-dom";
import KeysInfo from "../Bookings/KeysInfo";
import Modal from "../Modal";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
// import store from "../../redux/store";
import ViewSheet from "../Units/InfoSheetComponents/ViewSheet";
import { withHooksHOC } from "../Notifications/NotificationProvider";


type Props = {
  history: any;
  ErrorNote: any;
};

interface InfoKeysState {
  confirmed: boolean;
  // first_rent: boolean;
  unit_id: number;
  suite_number: string;
  suite_type: string;

  // fobs and keys information
  booking_id: number;
  fob_count: number;
  key_count: number;
  mailkey_count: number;
  park_fob_count: number;
  // vehicle_count: number;
  vehicle_fobs: number;
  parking_id: Array<string>;

  // toggle state
  parking: boolean;
  toggleModal: boolean;

  // info key components information
  suite: string;
  num_outstanding_invoices: number;
  monthly_rate: number;
}

class InfoKeys extends Component<any, InfoKeysState> {
  constructor(props: any) {
    super(props);
    this.state = {
      confirmed: true,
      // first_rent: true,
      unit_id: -1,
      suite_number: "",
      suite_type: "",

      // fobs and keys information
      booking_id: -1,
      fob_count: 0,
      key_count: 0,
      mailkey_count: 0,
      park_fob_count: 0,
      // vehicle_count: 0,
      vehicle_fobs: 0,
      parking_id: ["", "", ""],

      // toggle state
      parking: false,
      toggleModal: false,

      // info key components information
      suite: "",
      num_outstanding_invoices: 0,
      monthly_rate: -1,
    };
  }

  // TODO: Refactor this function.
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // TODO: Refactor this function.
  handleModal = (state?: any) => {
    //  alert(this.state.toggleModal)
    if (state && this.state.toggleModal === false) {
      this.setState({ toggleModal: true });
    } else {
      this.setState({ toggleModal: false });
    }
  };

  handleNext = async () => {
    // Maybe missing vehicle count
    // store.dispatch({
    //   type: "fillKeyFob",
    //   infoKeyFob: {
    //     fob_count: this.state.fob_count,
    //     key_count: this.state.key_count,
    //     mailkey_count: this.state.mailkey_count,
    //     park_fob_count: this.state.park_fob_count,
    //     plate_number: this.state.plate_number,
    //     vehicle_model: this.state.vehicle_model,
    //     parking_id: this.state.parking_id,
    //     parking: this.state.parking
    //   }
    // })

    const { location, history } = this.props;
    let booking_id = new URLSearchParams(window.location.search).get("id")
    let nextLink = location.pathname.replace(
      "/unit info & keys",
      "/security & help desk?id=" + booking_id
    );
    if (history) {
      history.push(nextLink);
    }
  };

  handleSubmit = async () => {
    let NotificationHandler = this.props.ErrorNote;
    const { history } = this.props;

    // Let's see what the booking data fields looks like,
    // so we can figure out which fields to include in our body...
    // let bookingID = new URLSearchParams(window.location.search).get("id")
    // const thisBooking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${bookingID}`)
    // let bookingByID = thisBooking ? thisBooking.data[0] : null;  // TODO: see what information bookings store, delete later.

    const updatedUnitInfoBody = {
      id: this.state.booking_id,
      fields: {
        fobs: this.state.fob_count,
        keys: this.state.key_count,
        mail_keys: this.state.mailkey_count,
        parking_fobs: this.state.park_fob_count,
      }
    }

    let updatedUnitInfo = await fetchRequestJSON("PUT", Constants.API_PATH_BOOKING_EDIT, updatedUnitInfoBody);
    if (updatedUnitInfo.status === "Success") {
      NotificationHandler("Success", "Data saved successfully!");
      // this.setState({
      //   fob_count: this.state.fob_count,
      //   key_count: this.state.key_count,
      //   mailkey_count: this.state.mailkey_count,
      //   park_fob_count: this.state.park_fob_count
      // });

    //   store.dispatch({
    //     type: "fillKeyFob",
    //     infoKeyFob: {
    //       fob_count: this.state.fob_count,
    //       key_count: this.state.key_count,
    //       mailkey_count: this.state.mailkey_count,
    //       park_fob_count: this.state.park_fob_count,
    //       plate_number: this.state.plate_number,
    //       vehicle_model: this.state.vehicle_model,
    //       parking_id: this.state.parking_id,
    //       parking: this.state.parking
    //     }
    //   });
    // } else {
    //   alert("Error saving data!");
    }

    if (history) {
      history.push({
        pathname: "/User/bookings/bookingprofile",
        search: `id=${this.state.booking_id}`
      })
    }
  };

  // TODO: Refactor this function.
  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    }();
    this.setState(stateObject);
  };

  // Vehicle count is not stored anywhere and the sales team does not keep track of number of vehicles in general. It might be useful to remove altogether because it is not used.
  // handleVehicleAdd = (event: any) => {
  //   event.preventDefault();
  //   if (this.state.vehicle_count < 4) {
  //     this.setState({ vehicle_count: this.state.vehicle_count + 1 });
  //   }
  // }

  // handleVehicleRemove = (event: any) => {
  //   event.preventDefault();
  //   if (this.state.vehicle_count > 0) {
  //     this.setState({ vehicle_count:  this.state.vehicle_count - 1 });
  //   }
  // }

  handleCountChange = (event: any) => {
    event.preventDefault();

    if (event.target.id === "VehicleFobAdd" && this.state.park_fob_count < 3) {
      let add = this.state.park_fob_count + 1;
      this.setState({ park_fob_count: add });
    } else if (event.target.id === "VehicleFobRemove" && this.state.park_fob_count !== 0) {
      let remove = this.state.park_fob_count - 1;
      this.setState({ park_fob_count: remove });
    } else if (event.target.id === "FobAdd" && this.state.fob_count < 4) {
      const addFob = this.state.fob_count + 1;
      this.setState({ fob_count: addFob });
    } else if (event.target.id === "FobRemove" && this.state.fob_count > 0) {
      const removeFob = this.state.fob_count - 1;
      this.setState({ fob_count: removeFob });
    } else if (event.target.id === "KeyAdd" && this.state.key_count < 4) {
      const addKey = this.state.key_count + 1;
      this.setState({ key_count: addKey });
    } else if (event.target.id === "KeyRemove" && this.state.key_count > 0) {
      const keyRemove = this.state.key_count - 1;
      this.setState({ key_count: keyRemove });
    } else if (event.target.id === "MailKeyAdd" && this.state.mailkey_count < 4) {
      const mailkeyAdd = this.state.mailkey_count + 1;
      this.setState({ mailkey_count: mailkeyAdd });
    } else if (event.target.id === "MailKeyRemove" && this.state.mailkey_count > 0) {
      const mailkeyRemove = this.state.mailkey_count - 1;
      this.setState({ mailkey_count: mailkeyRemove });
    }
  };

  // TODO: Refactor this function.
  handleArrayChange = (event: any) => {
    let ar: any = [...this.state.parking_id];
    for (let i = 0; i < this.state.park_fob_count && i < 4; i++) {
      if (event.target.id === "fob_id" + i) {
        let arIndex = { ...ar[i] };
        arIndex[i] = event.target.value;
        ar[i] = arIndex[i];
        this.setState({ parking_id: ar });
      }
    }
  };

  componentDidMount = async () => {
    let booking_id = new URLSearchParams(window.location.search).get("id")
    let booking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${booking_id}`);
    let bookingData = booking.data[0];
    let unit = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${bookingData.unit_id}`);
    let unitData = unit.data;
    let allInvoices = await fetchGetJSON(`${Constants.API_PATH_INVOICE_BY_BOOKING}${booking_id}`);

    if (allInvoices.status === "Success") {
      for (let i = 0; i < allInvoices.data.length; i++) {
        let this_invoice = allInvoices.data[i];
        if (this_invoice.booking_id === booking_id && this_invoice.paid === false) {
          this.setState({ num_outstanding_invoices: this.state.num_outstanding_invoices + 1 })
        }
      }
    }
    console.log(this.state.num_outstanding_invoices);

    if (unitData) {
      this.setState({
        unit_id: unitData.id,
        suite: unitData.suite_name,
        suite_number: unitData.suite_number,
        suite_type: unitData.suite_type,
      })
    }

    // let reduxData = store.getState().checkInReducer;
    if (bookingData) {
      this.setState({
        booking_id: bookingData.id,
        confirmed: bookingData.confirmed,
        fob_count: bookingData.fobs ? bookingData.fobs : 0,
        key_count: bookingData.keys ? bookingData.keys : 0,
        mailkey_count: bookingData.mail_keys ? bookingData.mail_keys : 0,
        // num_outstanding_invoices: reduxData.num_outstanding_invoices || -1,
        park_fob_count: bookingData.parking_fobs ? bookingData.parking_fobs : 0,
        parking: bookingData.parking_requested ? bookingData.parking_requested : false,
        parking_id: bookingData.parking_id || ["", "", ""],
        monthly_rate: bookingData.monthly_rate ? bookingData.monthly_rate : 0
        // plate_number: reduxData.plate_number || "",
        // vehicle_model: reduxData.vehicle_model || "",
      });
    }
  }

  // TODO: Inline all of the variables.
  /* TODO: Extract the info keys component into its own component file. */
  render() {
    // TODO: Variables creditFee, firstRent. and confirmed are unused. Can I remove them?
    // let creditFee = this.state.first_rent_due * 0.03 + this.state.first_rent_due;

    // It was never used in the UI
    // let firstRent = <div></div>;
    // if (this.state.first_rent === true) {
    //   firstRent = (
    //     <div
    //       id="FirstRent"
    //       className=" mt-3 whitespace-no-wrap h-6 ml-3 bg-red-300 text-red-800  font-medium text-xs
    //         rounded-full py-1 w-36 flex items-center justify-center align-center text-center px-4"
    //     >
    //       {" "}
    //       First Rent Required{" "}
    //     </div>
    //   );
    // }

    let confirmed = <div></div>;
    if (this.state.confirmed === true) {
      confirmed = (
        <div className=" whitespace-no-wrap h-6 mr-2 bg-green-400 text-green-800  font-medium text-xs
          rounded-full py-1 w-auto flex items-center align-center text-center px-4"
        >
          {" "}
          Confirmed{" "}
        </div>
      );
    }

    let parkingFobs = [];
    for (let i = 0; i < this.state.park_fob_count && i < 4; i++) {
      let FobInput = (
        <label className="flex flex-col fade-in ">
          <span className="text-sm w-auto mr-3  font-bold text-gray-600">
            Fob ID #{i + 1}
          </span>
          <input
            id={"fob_id" + i}
            type="number"
            className={
              " text-sm transition-all duration-600 font-normal text-gray-600 mt-0 py-1 block  px-0.5 " +
              "border-0 border-b-2 border-gray-300 placeholder-gray-300 focus:ring-0 focus:border-gray-500"
            }
            value={this.state.parking_id[i]}
            onChange={this.handleArrayChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            placeholder="ie. #1234"
          />
        </label>
      );

      parkingFobs.push(FobInput);
    }

    return (
      <div className="  font-display lg:rounded-tl-lg  bg-white ">
        <Modal
          handleModalToggle={this.handleModal}
          onoff={this.state.toggleModal}
          title="Confirm Remote Check In?"
          content="Remote check ins must be arranged ahead of time.
            Only continue if this has been approved."
          confirmButton={this.handleNext}
        />
        <BreadCrumb
          rootPage="Check Ins"
          subPage={`Booking #${this.state.booking_id} > Unit Info & Keys`}
          sideButton="bomb"
          buttonAction=""
        />
        <div className="fade-in px-10 max-w-5xl pb-24">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2">
            <div className="flex flex-col w-full">
              <h1 className="text-3xl px-2 pt-3 text-gray-700 font-black font-display ">
                Unit Info
              </h1>
              <h2 className="px-2 font-normal font-display text-sm text-gray-500">
                Go through every single point on the unit info sheet.
              </h2>
              <h2 className="px-2 font-normal font-display text-sm text-gray-500">
                Do not bring up parking if it hasn't been pre-arranged.
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-1 mx-2 ">
            <div className="flex flex-row items-center mt-1 mb-1">
              <Tooltip content={<UnitBubble suite_number="" blackout={true} heat={true}/>} delay="" direction="">
                <div className=" cursor-pointer  mr-2 bg-gray-700 text-white font-medium text-sm rounded-full
                  py-1 w-auto flex flex-col items-center text-center px-6">
                  {" "}
                  {this.state.suite}{" "}
                </div>
              </Tooltip>
              <span className="flex flex-row ml-4"><ViewSheet booking_id={null} unit_id={this.state.unit_id} name="View Unit Info Sheet" /></span>
            </div>

            <div>
              <h1 className="text-2xl px-2 pt-3 text-gray-700 font-black font-display ">
                Fobs & Keys
              </h1>
              <h2 className="px-2 font-normal font-display text-sm text-gray-500">
                Only 1 set of keys to be released per tenant;
              </h2>
              <h2 className="px-2 font-normal font-display text-sm text-gray-500">
                <strong>max 2 sets to be released</strong> per booking.
              </h2>
              <h2 className="px-2 font-bold font-display text-sm text-yellow-500 my-8 underline">
                Missing keys or fobs will be charged based on the terms agreed
                upon in the lease agreement.
              </h2>
            </div>

            <form>
              <div className=" flex flex-col self-start w-80 mb-2 mx-2 ">
                <div className="flex flex-row items-center mb-12 ">
                  <span className="font-bold text-gray-700 ">Fobs Needed:</span>
                  <button
                    id="FobRemove"
                    onClick={this.handleCountChange}
                    className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                        items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                  >
                    -
                  </button>
                  <span className="font-bold flex flex-row text-blue-600 w-8 justify-center  ">
                    {this.state.fob_count}
                  </span>
                  <button
                    id="FobAdd"
                    onClick={this.handleCountChange}
                    className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                  >
                    +
                  </button>
                </div>

                <div className="flex flex-row items-center mb-12 ">
                  <span className="font-bold text-gray-700 ">Keys Needed:</span>
                  <button
                    id="KeyRemove"
                    onClick={this.handleCountChange}
                    className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                        items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                  >
                    -
                  </button>
                  <span className="font-bold flex flex-row text-blue-600 w-8 justify-center  ">
                    {this.state.key_count}
                  </span>
                  <button
                    id="KeyAdd"
                    onClick={this.handleCountChange}
                    className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                  >
                    +
                  </button>
                </div>

                <div className="flex flex-row items-center mb-12 ">
                  <span className="font-bold text-gray-700 ">Mail Keys Needed:</span>
                  <button
                    id="MailKeyRemove"
                    onClick={this.handleCountChange}
                    className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                        items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                  >
                    -
                  </button>
                  <span className="font-bold flex flex-row text-blue-600 w-8 justify-center  ">
                    {this.state.mailkey_count}
                  </span>
                  <button
                    id="MailKeyAdd"
                    onClick={this.handleCountChange}
                    className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="mt-5">
                <label className=" block w-40  ">
                  <div className="flex flex-row items-center justify-between ">
                    <h1 className="text-2xl px-2   text-gray-700 font-black font-display ">Parking</h1>
                    <ToggleSwitch
                      id="parking"
                      className="justify-center align-center"
                      size="lg"
                      onoff={this.state.parking}
                      handleStateToggle={this.handleToggleChange}
                    />
                  </div>
                </label>

                <h2 className="px-2 mt-2 font-normal font-display text-sm text-gray-500">
                  Skip this section if parking has not been previously requested
                </h2>
              </div>

              {(this.state.parking) && (
                <div className=" fade-in ml-2 py-2">

                  {/* Vehicle count is not stored anywhere and the sales team does not keep track of number of vehicles in general. It might be useful to remove altogether because it is not used. */}
                  {/* <div className="flex flex-row items-center mb-12 ">
                    <span className="font-bold text-gray-700 ">Number Of Vehicles</span>
                    <button
                      id="VehicleRemove"
                      onClick={this.handleVehicleRemove}
                      className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                        items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                    >
                      -
                    </button>
                    <span className="font-bold flex flex-row text-blue-600 w-8 justify-center  ">
                      {this.state.vehicle_count}
                    </span>
                    <button
                      id="VehicleAdd"
                      onClick={this.handleVehicleAdd}
                      className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                    >
                      +
                    </button>
                  </div> */}

                  <div className="flex flex-row items-center mt-2 mb-4">
                    <span className="font-bold text-gray-700 ">Parking Fobs Needed</span>
                    <button
                      id="VehicleFobRemove"
                      onClick={this.handleCountChange}
                      className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                        items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                    >
                      -
                    </button>
                    <span className="font-bold flex flex-row text-blue-600 w-8 justify-center  ">
                      {this.state.park_fob_count}
                    </span>
                    <button
                      id="VehicleFobAdd"
                      onClick={this.handleCountChange}
                      className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4 items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-blue-700 "
                    >
                      +
                    </button>
                  </div>

                  <div className="grid grid-cols-3 gap-8">{parkingFobs}</div>

                  <div className="max-w-md mt-10">
                    <span className="text-sm text-gray-400">
                      Assign spot from google sheets, provide that spot information to
                      tenants and provide special instruction from building to tenant nd
                      also ask for car plate and vehicle type.
                    </span>
                  </div>
                </div>
              )}
            </form>
          </div>

          <div className="flex flex-row justify-start mb-16 ml-4">
            <button
              onClick={this.handleSubmit}
              className=" font-SF-Pro shadow-sm transition text-md duration-100 w-44 bg-green-500
              hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center
              align-center mr-3"
            >
              Submit
            </button>

            {/* <button
              onClick={this.handleNext}
              className=" font-SF-Pro shadow-sm transition text-md duration-100 w-44 bg-green-500
                hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center
                align-center mr-3"
            >
              Next
            </button> */}
          </div>
        </div>

        <KeysInfo data={this.state} />
      </div>
    );
  }
}

export default withRouter(withHooksHOC(InfoKeys));
