import React, { useState } from "react";
import { FiCornerUpRight } from "react-icons/fi";
import { useNotification } from "../../../Notifications/NotificationProvider";
import { fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import store from "../../../../redux/store";

type Props = {
    onOff: boolean
    parkingInfo: any
    handleStartDateToggle:any
    state: string //This will either be "Early" or "Late"
}

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

function EditStartDateModal(props:Props) {
    const [onOff, setOnOff] = useState(props.onOff)
    const [editStartDate, setEditStartDate] = useState(dayjs())

    //Notification Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------


    const  handleModal = (event: any) => {
        if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
           setOnOff(!onOff)
           props.handleStartDateToggle() //Toggles it on and off
        }
    };

    const handleSubmit = async() => {

      if(editStartDate.format('YYYY-MM-DD') >= dayjs(props.parkingInfo?.end_date).format('YYYY-MM-DD')) {
        NotificationHandler('Error', 'Error', 'Selected Date cannot be greater than or equal to a parking end date')
        return
      }

      if(props.state == "Early") {
        //Check that the editStartDate variable is earlier than the original start date
        if(editStartDate.format('YYYY-MM-DD') >= dayjs(props.parkingInfo?.start_date).format('YYYY-MM-DD')) {
          NotificationHandler('Error', 'Error', 'Selected Date cannot be greater than or equal for an early check in')
          return
        }

        const earlyCheckIn = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_EARLY_CHECK_IN, {
          id: props.parkingInfo.id,
          dateToChange: editStartDate.format('YYYY-MM-DD')
        })

        if(earlyCheckIn.status == "Success") {
          NotificationHandler('Success', 'Success', 'Parking Start Date has been modified to the early check in selected date.')
          let update = store.getState().parkingReducer.profileUpdate;
          update()
          props.handleStartDateToggle()
          return
        } else {
          NotificationHandler('Error', 'Error', earlyCheckIn.data)
          return
        }

      } else if (props.state == "Late") {
        //Check that the editStartDate variable is earlier than the original start date
        if(editStartDate.format('YYYY-MM-DD') <= dayjs(props.parkingInfo?.start_date).format('YYYY-MM-DD')) {
          NotificationHandler('Error', 'Error', 'Selected Date cannot be less than or equal for a late check in')
          return
        }

        const lateCheckIn = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_LATE_CHECK_IN, {
          id: props.parkingInfo.id,
          dateToChange: editStartDate.format('YYYY-MM-DD')
        })

        if(lateCheckIn.status == "Success") {
          NotificationHandler('Success', 'Success', 'Parking Start Date has been modified to the late check in selected date.')
          let update = store.getState().parkingReducer.profileUpdate;
          update()
          props.handleStartDateToggle()
          return
        } else {
          NotificationHandler('Error', 'Error', lateCheckIn.data)
          return
        }
      }
    }

    return(
        <div
        id="outsideDiv"
        onClick={handleModal}
        className={`fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 
        ${ !props.onOff ? "hidden" : "" }`}
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <FiCornerUpRight className="text-gray-600 text-3xl mb-3" />
          <span className="text-xl font-bold text-gray-800 text-center">
            Change Parking Start Date: {props.state} Check In
          </span>
          <span className="text-xs text-center text-gray-400 text-center">
            Changing from Original Date: <u>{dayjs(props.parkingInfo?.start_date).format('LL')}</u> to New Check-In Date: <u>{editStartDate.format('LL')}</u>
          </span>
          <form>
            <span className="flex flex-row mt-3">
              <input
                id="check_out"
                onChange={(input) => setEditStartDate(dayjs(input.target.value))}
                type="date"
                className="mt-0 ml-3 py-1 block px-0.5 border-0 border-b-2 border-green-200 focus:ring-0 focus:border-black  text-black-500"
              />
            </span>
            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={() => {
                  handleSubmit()
                }}
                type="button"
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500  rounded-sm text-white  shadow-sm  "
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    )
}

export default EditStartDateModal;
