import { Component } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";

// props
interface Props {
  title: string;
  content: string;
  handleModalToggle: any;
  confirmButton: any;
  onoff: boolean;
}

interface ModalState {
  title: string;
  content: string;
  onoff: boolean;
  deposit: boolean;
  pets: boolean;
  cleaning: boolean;
}

class AgreementModal extends Component<Props, ModalState> {
  constructor(props: Props & ModalState) {
    super(props);

    this.state = {
      title: this.props.title,
      content: this.props.content,
      onoff: this.props.onoff,
      deposit: false,
      pets: false,
      cleaning: false,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleConfirm = () => {
    this.props.confirmButton();
  };

  handleDeposit = (e: any) => {
    if (this.state.deposit === false) {
      this.setState({ deposit: true });
    } else {
      this.setState({ deposit: false });
    }
  };

  handlePets = (e: any) => {
    if (this.state.pets === false) {
      this.setState({ pets: true });
    } else {
      this.setState({ pets: false });
    }
  };

  handleCleaning = (e: any) => {
    if (this.state.cleaning === false) {
      this.setState({ cleaning: true });
    } else {
      this.setState({ cleaning: false });
    }
  };

  render() {
    let depositTile = "";
    let petTile = "";
    let cleaningTile = "";
    let agreeType = "";

    if (!this.props.onoff) {
      return null;
    }

    if (this.state.deposit !== false) {
      depositTile = "bg-green-500";
      agreeType = agreeType.concat("Deposit ");
    }

    if (this.state.pets !== false) {
      petTile = "bg-green-500";
      agreeType = agreeType.concat(" Pet");
    }

    if (this.state.cleaning !== false) {
      cleaningTile = "bg-green-500";
      agreeType = agreeType.concat(" Cleaning");
    }

    let spaceCount = agreeType.split(" ").length - 1;

    if (spaceCount >= 2) {
      agreeType = agreeType.replace(/\s+/g, " + ");
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-96 py-5 px-8 mx-auto flex flex-col items-center justify-center">
          <span className="text-xl font-bold text-gray-800 text-center">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400">
            {this.state.content}
          </span>
          <div className="flex flex-row justify-evenly w-full my-5">
            <div className="flex flex-col items-center">
              <button
                id="deposit"
                onClick={this.handleDeposit}
                className={
                  " active:bg-green-700 shadow-sm transition text-sm duration-100  bg-gray-400 hover:bg-green-400 text-white  w-12 h-12 rounded-sm flex flex-row justify-center items-center align-center " +
                  depositTile
                }
              >
                <FaMoneyCheckAlt className="text-3xl" />
              </button>
              <span className="text-xs text-gray-400 pt-1">Deposit</span>
            </div>
            <div className="flex flex-col items-center">
              <button
                id="pets"
                onClick={this.handlePets}
                className={
                  "  shadow-sm transition text-sm duration-100  bg-gray-400 hover:bg-green-400 text-white  w-12 h-12 rounded-sm flex flex-row justify-center items-center align-center " +
                  petTile
                }
              >
                <MdPets className="text-3xl" />
              </button>
              <span className="text-xs text-gray-400 pt-1">Pets</span>
            </div>
            <div className="flex flex-col items-center">
              <button
                id="cleaning"
                onClick={this.handleCleaning}
                className={
                  "  shadow-sm transition text-sm duration-100  bg-gray-400 hover:bg-green-400 text-white  w-12 h-12 rounded-sm flex flex-row justify-center items-center align-center " +
                  cleaningTile
                }
              >
                <AiOutlineClear className="text-3xl" />
              </button>
              <span className="text-xs text-gray-400 pt-1">Clean</span>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center w-full  border-t border-b  py-2">
            <span className="text-sm text-gray-500">{agreeType}</span>
            <button className="transition h-8 duration-100 border-2 border-gray-100 border-solid text-xs w-28 bg-white hover:bg-gray-100 text-gray-500 font-medium py-1 rounded pr-1 flex flex-row  justify-center items-center">
              Get Link
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AgreementModal;
