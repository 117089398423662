export type State = {
  curId: number;
  contactType: any[];
  listUpdate: any;
  corpListUpdate: any;
  corpViewUpdate: any;
};

const initialState: State = {
  curId: -1,
  contactType: [],
  listUpdate: undefined,
  corpListUpdate: undefined,
  corpViewUpdate: undefined,
};

type Action =
  | { type: "contactIdTransfer"; id: number }
  | { type: "contactTypeUpdate"; contact: { id: number; type: string } }
  | { type: "contactListUpdate"; listUpdate: any }
  | { type: "corpListUpdate"; corpListUpdate: any }
  | { type: "corpViewUpdate"; corpViewUpdate: any };

export const corpContactReducer = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "contactIdTransfer":
      state.curId = action.id;
      return state;
    case "contactTypeUpdate":
      state.contactType.push(action.contact);
      return state;
    case "contactListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    case "corpListUpdate":
      state.corpListUpdate = action.corpListUpdate;
      return state;
    case "corpViewUpdate":
      state.corpViewUpdate = action.corpViewUpdate;
      return state;
    default:
      return state;
  }
};

export default corpContactReducer;
