import React from "react";
import { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { useNotification } from "../../../Notifications/NotificationProvider"
import store from "../../../../redux/store";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import ToggleSwitch from "../../../ToggleSwitch";
import { MdSearch } from "react-icons/md";
import { BiBookAdd } from "react-icons/bi";

type Props = {
    toggle: boolean;
    handleCreateBarToggle:any;
}
function ParkingCreate(props:Props) {
    const [activeParkingSpot, setActiveParkingSpot] = useState([]);

    //Parking Payload
    const [parkingSpotID, setParkingSpotID] = useState(-1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [bookingID, setBookingID] = useState(null);
    const [parkingNotes, setParkingNotes] = useState('');

    const[parkingName, setParkingName] = useState('');

    const[nonBookingIDToggle, setNonBookingIDToggle] = useState(false);

    //Notification Handler Logic:
    const dispatch = useNotification();

    //Tenant Search State Variables:
    const [search, setSearch] = useState('') //This variable is used when we want to search for a tenant
    const [tenantList, setTenantList] = useState(null); //This is what is received when a user searches for a tenant 
    const [selectedTenant, setSelectedTenant] = useState(null); //This is what stores the selected tenant info selected when a user selects a tenant 
    const [tenantLoaded, setTenantLoaded] = useState(false); //This is what is used to show tenant result, if false then no tenant exists or was searched

    const HandleError = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async() => {
        //1. Need to load the parking spot data, only include active parking spots
        const allActiveParkingSpots = await fetchGetJSON(Constants.API_PATH_PARKING_SPOT_GET_ALL_ACTIVE);
        setActiveParkingSpot(allActiveParkingSpots.data.sort((parkingSpotOne:any, parkingSpotTwo:any) => parkingSpotOne.parking_spot_location.localeCompare(parkingSpotTwo.parking_spot_location)))
    }

    const handleSubmit = async() => {
        //Couple things:
        //1. When creating a parking, if NonBookingToggle is true, they must include a startDate, endDate, and a selected Tenant
        //2. If nonBooking is set to false, they only are required to have a bookingID 
        //3. Both options must include a selected parking spot, must return an error message if not
        
        if((!startDate || !endDate || !selectedTenant) && nonBookingIDToggle) {
            HandleError('Error', 'Error', 'Start Date, End Date, and a Selected Tenant are required for a Non Booking Parking')
            return
        }

        if(!nonBookingIDToggle && !bookingID) {
            HandleError('Error', 'Error', 'No Booking ID selected for a Booking Associated Parking')
            return
        }

        if((startDate >= endDate) && nonBookingIDToggle) {
            HandleError('Error', 'Error', 'Start Date greater than or equal to End Date')
            return
        }

        if(!parkingSpotID) {
            HandleError('Error', 'Error', 'Must selected a parking spot when creating a parking')
            return
        }

        const createParkingPayload = {
            start_date: nonBookingIDToggle ? startDate : null,
            end_date: nonBookingIDToggle ? endDate : null,
            parking_spot_id: parkingSpotID,
            booking_id: nonBookingIDToggle ? null : bookingID, //If non booking toggle is selected, then booking_id is null as this is a non-booking related parking
            tenant_id: nonBookingIDToggle ? selectedTenant.id : null,
            parking_notes: parkingNotes,
        }
        
        const newParkingStatus = await fetchRequestJSON('POST', Constants.API_PATH_PARKING_CREATE, createParkingPayload)

        if(newParkingStatus.status == "Success") {
            HandleError('Success', 'Success', 'Parking Created Succesfully')

            //Redux below:
            let update = store.getState().parkingReducer.listUpdate;
            update()

            return
        } else {
            HandleError('Error', 'Error', newParkingStatus.data)
            return
        }
    }

    const searchTenant = async(input: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        input.preventDefault();
        setTenantLoaded(false)
        setSelectedTenant(null)
        setTenantList(null)

        const tenantResult = await  await fetchGetJSON(`${Constants.API_PATH_TENANT_SEARCH}?q=${search}`,);

        if(tenantResult.status == "Success") {
            HandleError('Success', 'Success: Tenant Found', search);
            setTenantList(tenantResult.data)
            setTenantLoaded(true)
            return
        } else {
            HandleError(tenantResult.status, `${tenantResult.status}`, `${tenantResult.data}`);
            return
        }
    }

    const addTenant = (input: React.MouseEvent<SVGElement, MouseEvent>, tenantID: number,) => {
        input.preventDefault();

        const tenant = tenantList.filter((tenant:any) => {
            return tenant.id == tenantID
        })
        if(tenant.length) {
            setSelectedTenant(tenant[0])
            HandleError("Success", 'Success: Tenant Selected', `${tenant[0].first_name} ${tenant[0].last_name}`)
        } else {
            setSelectedTenant(null)
            HandleError("Error", 'Error: ', `Tenant Found`)
        }

        return 
    }

    const tableRow = () => {
        return (
            <>
                {
                    tenantLoaded && 
                    tenantList.map((tenant: any) => {
                        return (
                            <tr className="cursor-pointer border-t border-gray-400 w-full" key={tenant.id}>                        
                                {/* Full Name */}
                                <td className="w-16 font-light text-sm text-gray-500 px-1 py-4">
                                    <div className="flex flex-col">
                                        <span className="text-base text-gray-600 font-semibold truncate w-32">
                                            {tenant.first_name} {tenant.last_name}
                                        </span>
                                    </div>
                                </td>
    
                                {/* Email */}
                                <td className="hidden lg:table-cell w-24 px-1 py-4 text-sm">
                                    {tenant.email}
                                </td>
                                
                                <td className="w-16 px-1 py-4">
                                    <div className="flex flex-row justify-end">
                                        <BiBookAdd
                                            onClick={(e) => {addTenant(e, tenant.id)}}
                                            className={"text-green-500 text-2xl mr-2 hover:text-green-400"}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })
                } 
            </>
        )
    }
    

    const contentCreation = () => {
        return (
            <form className="flex flex-col pl-6 pr-12 py-4">
                <strong className="text-gray-600 text-md font-bold mb-4 mt-2">
                    General Info
                </strong>
                <label className="block pl-3">
                    <strong className="text-gray-400 text-xs"></strong>
                </label>
                <div className="flex flex-row mb-2 sm:pr-2">
                    <span className={" transition-all duration-600 text-gray-600 text-sm mr-3 pl-3"}>Is this a Non-Booking Associated Parking?</span>
                    <ToggleSwitch
                        id="selectDate"
                        handleStateToggle={() => setNonBookingIDToggle(!nonBookingIDToggle)}
                        className="justify-center align-center"
                        size="lg"
                        onoff={nonBookingIDToggle}
                    />
                </div>
                <label className="block pl-3 mb-5 mt-2">
                    {
                        !nonBookingIDToggle && 
                        <div>
                            <h4 className="mb-3 text-xs">PLEASE NOTE: Parkings associated with Bookings should be added in the <u>New Booking Page</u> unless importing from the past Parking Calendar or an error has occurred during Booking Creation.</h4>
                            <strong className="transition-all duration-600 text-gray-400 text-xs">Booking ID: </strong>
                            <br/>
                            <input
                                id="notes"
                                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-4"
                                onChange={(input) => {setBookingID(+input.target.value)}}
                                type="number"
                                placeholder="Only input numbers for Booking ID"
                            ></input>
                            <strong className="transition-all duration-600 text-gray-400 text-xs mt-4"><u>Please Note: </u></strong> 
                            <p className="transition-all duration-600 text-gray-400 text-xs">Start Date and End Date will be assigned to a Booking's Check In and Check Out</p>
                        </div>
                    }
                    {
                        nonBookingIDToggle && 
                        <div>
                            <label className="block pl-3 mb-5">
                            <span className="text-gray-400 text-xs">Tenant</span>
                            
                            {/* Changed */}
                            <section className="flex flex-row">
                                <span className="flex flex-row border rounded-sm w-full lg:w-2/3 h-8 py-1">
                                    <MdSearch className="text-xl text-gray-400 mx-1" />
                                    <input
                                        className="text-sm searchBox w-full"
                                        onChange={(input) => setSearch(input.target.value)}
                                        placeholder= "Search Tenant by Email or Name"
                                        id="search"
                                        // value={this.state.search}
                                    />
                                </span>
                                <button className="ml-2 bg-green-500 hover:bg-green-400 rounded-sm text-sm py-1 px-2 text-white" 
                                    onClick={(input) => {searchTenant(input)}}
                                >
                                    Search
                                </button>
                            </section>
                            </label>{" "}
                        </div>
                    }
                    {
                        (nonBookingIDToggle && tenantLoaded) &&
                        <div className="">
                            <div className="text-green-500 font-bold text-lg underline">Tenant Search Results</div>
                            <div className="flex flex-row mt-3">
                                <table className="table-auto w-full mb-2">
                                    <thead>
                                    <tr className="text-left font-normal text-gray-700 " key={0}>
                                        <th className="py-2  ">Full Name</th>
                                        <th className="py-2 hidden lg:table-cell">Email</th>
                                        {/* Options */}
                                        <th className="py-2  "></th>
                                    </tr>
                                    </thead>
                                        <tbody>
                                            {tableRow()}
                                        </tbody>
                                </table>
                            </div>
                            {
                                selectedTenant &&
                                <>
                                    <p className="text-gray-700 font-bold">Selected Tenant: </p>
                                    <p>Full Name: <u><span className="text-green-400">{selectedTenant?.first_name} {selectedTenant?.last_name}</span></u>, Email: <u><span className="text-green-400">{selectedTenant?.email}</span></u></p>
                                </>
                            }
                        </div>
                    }
                </label>
                <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select Parking Spot*</strong>
                    </span>
                    <select
                        id="parking_spots"
                        onChange={(input) => {setParkingSpotID(+input.target.value)}}
                        // value={this.state.suite_building}
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        placeholder="Choose a Parking Spot"
                    >
                        <option selected disabled>Please Select a Parking Spot (Required)</option>
                        {activeParkingSpot.map((parking_spot:any) => 
                          <option key={parking_spot.id} value={parking_spot.id}>{parking_spot.parking_spot_location} {parking_spot.parking_spot_level}</option>
                        )}
                    </select>
                 </label>
                 {
                    nonBookingIDToggle &&
                    <>
                        <label className="block pl-3 mb-5">
                            <span className="text-gray-400 text-xs">
                                <strong>Select the Start Date of the Parking Period</strong>
                            </span>
                            <input
                            id="start date"
                            onChange={(input) => {
                                setStartDate(input.target.value);
                            }}
                            type="date"
                            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder=""
                            />
                        </label>
                        <label className="block pl-3 mb-5">
                            <span className="text-gray-400 text-xs">
                                <strong>Select the End Date of the Parking Period</strong>
                            </span>
                            <input
                            id="end date"
                            onChange={(input) => {
                                setEndDate(input.target.value);
                            }}
                            type="date"
                            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder=""
                            />
                        </label>
                    </>
                 }
                 <label className="block pl-3 mb-5 mt-2">
                    <strong className="text-gray-400 text-xs">Parking Notes: </strong>
                    <textarea
                        id="notes"
                        className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows={3}
                        onChange={(input) => setParkingNotes(input.target.value)}
                    ></textarea>
                </label>
            </form>
        )
    }

    
    return (
        <div className={
            props.toggle ?  "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + 
            "z-50 opacity-100 translate-x-0 lg:max-w-lg w-full" : "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide"
          }>  
           {/* Top Title Bar */}
           <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
             <button
               className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
             >
               <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
             </button>
   
             <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
               <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
             </button>
           </div>
           <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
             <span className=" font-black text-xl text-gray-700">
               New Parking
             </span>
             <span className="italic font-sofia text-gray-400 text-lg font-thin">
             </span>
           </div>
           {contentCreation()}
   
           {/* Submit Logic Below */}
           <div className="h-24 flex flex-row ">
             <button
               onClick={() => handleSubmit()}
               className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
             >
               Submit
             </button>
           </div>
         </div>
    );
}

export default ParkingCreate;

