import MapPicture from "../../../client-facing/img/ParkingPhotos/Ice/Ice Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Ice/Ice View 1.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Ice/Ice View 2.png";
import ThirdStreetView from "../../../client-facing/img/ParkingPhotos/Ice/Ice View 3.png";
import FourthStreetView from "../../../client-facing/img/ParkingPhotos/Ice/Ice View 4.png";

function IceParking() {
  return (
    <div>
      <div className="flex flex-col-reverse md:flex-row md:mb-5">
        <div className="w-full flex flex-row md:flex-col md:mt-0 basis-2/3">
          <div className="bg-green-400 flex flex-row text-white text-lg px-5 py-5 flex-grow">
            <div className="flex flex-col justify-center flex-grow">
              <p>
                The parking lot for 14 York Street should appear right
                behind the building's main entrance.
                <ol className="list-decimal list-outside mt-5 pl-5" >
                  <li>
                    Going South on York St. turn right onto Bremner Blvd.
                  </li>
                  <li> 
                    Drive about 100m until you see 'Hoops Sports Bar
                    and Grill' and make a left onto  Grand Trunk Crescent 
                  </li>
                  <li>
                    Drive past the first parking lot on your left
                  </li>
                  <li>
                    Parking for 14 York Street should be the second parking
                    lot on your left
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:ml-5 flex flex-row md:flex-col mb-5 md:mb-0 basis-1/3 justify-center">
          <img
            src={MapPicture}
            alt="Google Maps Street View"
            className="object-cover"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:mb-5 w-full h-full">
        <div className="w-full flex flex-col mt-5 md:mt-0 basis-1/2 h-full flex-grow">
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="h-full object-cover"
          />
          <p className="mt-1">1. Going South on York St. turn right into Bremner Blvd.</p>
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-1/2 h-full flex-grow">
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-full object-cover"
          />
          <p className="mt-1">2. Drive about 100m until you see 'Hoops Sports Bar and Grill' and make a left onto Grand Trunk Crescent</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mb-5 w-full h-full">
        <div className="w-full flex flex-col mt-5 md:mt-0 basis-1/2 h-full flex-grow">
          <img
            src={ThirdStreetView}
            alt="Google Maps Street View"
            className="h-full object-cover"
          />
          <p className="mt-1">3. Drive past the first parking lot on your left</p>
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-1/2 h-full flex-grow">
          <img
            src={FourthStreetView}
            alt="Google Maps Street View"
            className="h-full object-cover"
          />
          <p className="mt-1">4. Parking for 14 York Street should be the second parking lot on your left</p>
        </div>
      </div>
    </div>
  );
}

export default IceParking;