import { LoadingBar } from "../LoadingComp"

export function LoadingInvoice(props: any) {
    return <tr className={" grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"}>
        {/* Suite Name */}

        {/* IMPORTANT */}
        {/* These onclick properties are set to a function passed from parent and are rendered at all times */}
        {/* This means I cant send it an id from the invoice object it will always call all of them and end up displaying the first one */}
        <td className={"w-full text-sm sm:text-base sm:w-16 py-4 sm:px-4"}>
            <span className="sm:hidden">ID: </span>
            <LoadingBar />
        </td>

        {/* Suite Number */}

        <td className={"w-full sm:w-36 font-light text-sm text-gray-500 sm:px-1 sm:py-4"}>
            <span className="sm:hidden">Due Date: </span>
            <LoadingBar/>
        </td>

        {/* Min. Stay */}

        <td className={"w-full sm:w-32 font-thin  text-gray-600 sm:px-1  py-2 sm:py-4"}>
            <span className="sm:hidden text-sm">Cost: </span>
            <LoadingBar />
        </td>

        {/* Bedrooms */}

        <td className={"w-full sm:w-32 font-medium  text-gray-600 sm:px-1  py-0 sm:py-4"}>
            <span className="sm:hidden font-thin text-sm">Marked By: </span>{" "}
            <LoadingBar />
        </td>

        {/* Type */}

        <td className={"w-full text-sm sm:text-base sm:w-28 font-normal  text-gray-400 sm:px-1 py-2  sm:py-4"}>
        <span className="sm:hidden font-thin text-sm">Payment Method:{" "}</span>
            <LoadingBar />
        </td>

        {/* Booking Warnings */}

        <td className="hidden sm:table-cell sm:w-32 text-red-400 px-1 py-4 text-sm font-semibold">
            <LoadingBar />
        </td>

        {/* Booking Warnings */}

        <td className=" hidden sm:table-cell sm:w-28 px-1 py-2 sm:py-4  ">
            <div className="flex flex-row justify-end">
            <LoadingBar />
            </div>
        </td>
        
    </tr>
}

export function LoadingGeneral(props: {count: number, cols: number, empty: boolean, authorization?:boolean}) {
    let rows: any = [];
    let altBg = "";
    if (props.empty) {
      return <>
        <tr className={"bg-white grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"}>
          <td
            className={"w-full text-sm sm:text-base sm:w-16 py-4 sm:px-2"}
            colSpan={props.cols + 1}
          >
            {" "}
          </td>
        </tr>
        <tr className={"bg-white grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"}>
          <td
            className={"w-full text-sm sm:text-base sm:w-16 py-4 sm:px-2 text-center"}
            colSpan={props.cols + 1}
          >
            {props.authorization ? 'There is no Data!' : 'You do not have permission to view.'}
          </td>
        </tr>
        <tr className={"bg-white grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"}>
          <td
            className={"w-full text-sm sm:text-base sm:w-16 py-4 sm:px-2"}
            colSpan={props.cols + 1}
          >
            {" "}
          </td>
        </tr>
      </>
    }
    for (let i = 0; i < props.count; i++) {
        if (i % 2 != 1) {
            altBg = "bg-gray-50";
        } else {
            altBg = "bg-white";
        }
        rows.push(
            <tr className={altBg + " grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"} key = {i.toString()}>
                <td className={"w-full text-sm sm:text-base sm:w-16 py-4 sm:px-2"}>
                    <LoadingBar />
                </td>
                <td colSpan={props.cols} className={"font-light text-sm text-gray-500 sm:px-1 sm:py-4 relative"}>
                    <LoadingBar />
                </td>
            </tr>
        );
    }
    return rows;
}

export function LoadingInvoiceQuickView(props: any) {
    return <div className="appear bg-white w-full overflow-y-auto shadow-md sm:rounded-lg pb-10 max-w-3xl  scroll-hide">
        <div className="w-full bg-gray-100 pt-4 pb-2  px-4 sm:px-8 rounded-t-lg ">
        <div className="flex flex-col sm:flex-row my-1  justify-between sm:items-center">
            <span className="text-xl font-bold text-gray-800">
                <LoadingBar replace="Booking ID #00" />
            </span>
            <span className=" cursor-pointer h-8 mr-2 bg-gray-700 text-white font-medium text-xs rounded-full  w-auto hidden sm:flex flex-row items-center text-center px-5">
            </span>
        </div>

        <div className="flex flex-col mt-2 sm:mt-0 justify-start sm:flex-row my-1 sm:justify-between sm:items-end pt-1">
            <div className="flex flex-col text-xs font-medium text-gray-600">
            <span className=" pb-2">
                <LoadingBar replace="Corporate: N/A" />
            </span>
            <span className=" pb-2">
                <LoadingBar replace="Tenant Name: Filler Name" />
            </span>
            <div className="flex flex-row">
                <span className="opacity-70 text-2xs pb-1 mr-4">
                    <LoadingBar replace="Check In: January 1st 2022" />
                </span>
                <span className="opacity-70 text-2xs ">
                    <LoadingBar replace="Check Out: January 1st 2022" />
                </span>
            </div>
            </div>
        </div>
        </div>

        <div className="w-full  pt-6 px-4 sm:px-8 h-full">
        <div className="w-full flex flex-row font-sofia justify-between">
            <div className=" flex flex-col ">
            <span className="text-4xl font-extrabold text-gray-600 ">
                <LoadingBar replace="ID #000" />
            </span>
            <span className="text-lg font-base text-gray-400 uppercase mb-2 mt-6">
                <LoadingBar replace="FAKE INVOICE" />
            </span>
            <LoadingBar replace="From January 1st 2022 - January 1st 2022" />

            <div className="flex flex-row mt-2">
                <div className="flex flex-col mr-6">
                <span className="flex flex-col mr-5 text-sm text-gray-400 mb-2">
                    <LoadingBar replace="Issued On:" />
                </span>
                <span className=" mr-5 font-bold text-md text-gray-700">
                    <LoadingBar replace="January 1st 2022" />
                </span>
                </div>

                <div className="flex flex-col">
                <span className=" mr-5 text-sm text-gray-400 mb-2">
                    <LoadingBar replace="Due Date:" />
                </span>
                <span className=" mr-5 font-bold text-md text-gray-700">
                    <LoadingBar replace="January 1st 2022" />
                </span>
                </div>
            </div>

            <div className="flex flex-col mt-8">
                <span className="mr-5 text-sm text-gray-400">
                    <LoadingBar replace="Invoice For" />
                </span>
                <span className=" text-lg font-bold text-gray-600 mt-1">
                    <LoadingBar replace="Fake Name" />
                </span>
                <span className=" text-medium text-gray-600 mt-1">
                    <LoadingBar replace="123 Sesame Street" />
                </span>

                <span className=" text-xs text-gray-300 mt-1">
                    <LoadingBar replace="HST No. 1231231231234" />
                </span>
            </div>
            </div>

            <div className=" hidden sm:flex flex-col h-auto justify-between ">
            <span className="text-lg font-regular text-gray-400 uppercase self-end ">
                
            </span>
            </div>
        </div>
        </div>
    </div>
}

