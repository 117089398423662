export type State = {
  // Unit Info & Keys Page
  fob_count: number;
  key_count: number;
  mailkey_count: number;
  park_fob_count: number;
  plate_number: string;
  vehicle_model: string;
  parking_id: string[];
  parking: boolean;
  monthly_rate: number,
  num_outstanding_invoices: number,

  // Data Collection Page
  work_trip: boolean;
  work_company: string;
  book_by_company: boolean;
  colleagues: boolean;
  post_stay_accomm: boolean;
  data_notes: string;
};

const initialState: State = {
  // Unit Info & Keys Page
  fob_count: null,
  key_count: null,
  mailkey_count: null,
  park_fob_count: null,
  plate_number: null,
  vehicle_model: null,
  parking_id: null,
  parking: null,
  monthly_rate: null,
  num_outstanding_invoices: null,

  // Data Collection Page
  work_trip: null,
  work_company: null,
  book_by_company: null,
  colleagues: null,
  post_stay_accomm: null,
  data_notes: null,
};
  
type Action =
  | { type: "fillKeyFob"; infoKeyFob: { 
      fob_count: number,
      key_count: number,
      mailkey_count: number,
      park_fob_count: number,
      plate_number: string,
      vehicle_model: string,
      parking_id: string[],
      parking: boolean,
      monthly_rate: number,
      num_outstanding_invoices: number,
  } }
  | { type: "fillDataCollect"; infoData: { 
      work_trip: boolean,
      work_company: string,
      book_by_company: boolean,
      colleagues: boolean,
      post_stay_accomm: boolean,
      data_notes: string,
  } }
  | { type: "resetData" };

export const checkInReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "fillKeyFob":
      state.fob_count = action.infoKeyFob.fob_count;
      state.key_count = action.infoKeyFob.key_count;
      state.mailkey_count = action.infoKeyFob.mailkey_count;
      state.park_fob_count = action.infoKeyFob.park_fob_count;
      state.plate_number = action.infoKeyFob.plate_number;
      state.vehicle_model = action.infoKeyFob.vehicle_model;
      state.parking_id = action.infoKeyFob.parking_id;
      state.parking = action.infoKeyFob.parking;
      state.monthly_rate = action.infoKeyFob.monthly_rate;
      state.num_outstanding_invoices = action.infoKeyFob.num_outstanding_invoices;
      return state;
    case "fillDataCollect":
      state.work_trip = action.infoData.work_trip;
      state.work_company = action.infoData.work_company;
      state.book_by_company = action.infoData.book_by_company;
      state.colleagues = action.infoData.colleagues;
      state.post_stay_accomm = action.infoData.post_stay_accomm;
      state.data_notes = action.infoData.data_notes;
      return state;
  case "resetData":
      state.fob_count = null;
      state.key_count = null;
      state.mailkey_count = null;
      state.park_fob_count = null;
      state.plate_number = null;
      state.vehicle_model = null;
      state.parking_id = null;
      state.parking = null;
      state.monthly_rate = null;
      state.num_outstanding_invoices = null;
      state.work_trip = null;
      state.work_company = null;
      state.book_by_company = null;
      state.colleagues = null;
      state.post_stay_accomm = null;
      state.data_notes = null;
      return state;
    default:
      return state;
  }
};
  
export default checkInReducer;
  