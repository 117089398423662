import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Element/Element Map.png"
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Element/Element View.png"
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Element/Element View 2.png"

function ElementParking() {
    return (
        <div>
          <div className="flex flex-col md:flex-row mb-5 w-full">
            <div className="flex flex-col w-full">
              <img
                src={MapPicture}
                alt="Google Maps View for Parking Location"
                className="h-1/2"
              />
              <p className="mt-3">
                Entrance to the Element parking garage is accessed via Blue Jays
                Way, just north of Front St.
              </p>
              <p className="mt-3">
                Use the right lane of the entrance and push the grey button on
                your parking remote to open the first liftgate you encounter.
                Proceed straight and slightly to the left where you will see a
                double-wide roll up garage door. Use the grey button again to
                open this door.
              </p>
              <p className="mt-3 mb-5 md:mb-0">
                Proceed down the ramp and through the visitor parking on P1 -
                when you reach another double-wide roll up garage door, use the
                grey button once more to access the resident parking area.
                Proceed down to your assigned parking space and you will have
                arrived.
              </p>
              <img
                src={SecondStreetView}
                alt="Google Maps Street View"
                className=""
              />
            </div>
            <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0">
              <img
                src={FirstStreetView}
                alt="Google Maps Street View"
                className="h-1/2 w-full mb-5"
              />
              <p className="bg-green-400 text-white text-2xl px-5 py-5 h-full py-5">
                Point form summary: <br/>
                <strong>
                  Entrance accessible via Blue Jays Way
                </strong>
                <ul className="list-disc ml-5">
                  <li>
                    Pull up to the gates under the
                    archway and press the grey button
                    on the parking remote to open first
                    lift gate
                  </li>
                  <li>
                    Proceed forward and slightly to the
                    left, use same grey button to open
                    the large double-wide roll up garage
                    door
                  </li>
                  <li>
                    Proceed down the ramp through
                    visitor parking to the last roll up
                    garage door, and use grey button on
                    fob to access the resident parking
                    lot
                  </li>
                  <li>
                    Proceed down to your designated
                    parking space
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
    );
}

export default ElementParking;
