import { useEffect, useState } from "react";
import { LoadingGeneral } from "../../../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../../../utils/PageSelector";
import { MdSearch } from "react-icons/md";
import store from "../../../../redux/store"
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { FiPauseCircle, FiPlus } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { FcCancel } from "react-icons/fc"
import { useNotification } from "../../../Notifications/NotificationProvider";
import { RouteComponentProps, withRouter } from "react-router";


//Dayjs Logic, implement this as a helper function when we refactor
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

type Props = {
    handleStateActive: any;
    handleModal: any;
}

const ParkingSpotList = (props: Props & RouteComponentProps) => {
    //Table State Variables 
    const [rows , setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false)
    const [settingId, setSettingId] = useState(-1);

    //Page Selector State Variables
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
  
    //Filters
    const [searchByParkingName, setSearchByParkingName] = useState('');
    

    //Parking Spot Blackouts
    const [parkingSpotBlackouts, setParkingSpotBlackouts] = useState([]);

    const [authorization, setAuthorization] = useState(true);

    //Notificatoin Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    //Load Initial Page:
    useEffect(() => {
        updateList();

        //Store update list in redux later
        store.dispatch({
            type: "parkingSpotListUpdate",
            listUpdate: updateList,
        });
    }, [])

    const updateList = async (pageNumber?:number, totalPagesChange?:number) => {
        setLoaded(false);
        let filters = {
            name: searchByParkingName,
        }
        const all_blackouts = await fetchGetJSON(
            Constants.API_PATH_PARKING_SPOT_BLACKOUTS_GET_ALL
          );

        setParkingSpotBlackouts(all_blackouts.data);

        const allParkingSpots = await fetchGetJSON(`${Constants.API_PATH_PARKING_SPOT_LIST}?name${searchByParkingName ? `=${searchByParkingName}` : ''}&page=${pageNumber ? pageNumber : currentPageNumber}&perpage=${totalPagesChange ? totalPagesChange : rowsPerPage}`)
        
        if(allParkingSpots.status !== "Success") {
            setLoaded(true);
            setAuthorization(allParkingSpots.status == "Error Unauthorized" ? false : true)
            return;
        }

        setTotalPages(allParkingSpots.data.pages)
        setRows(allParkingSpots.data.rows)
        // tableRows();
        setIsLoading(false);
        setLoaded(true);
    }

    //Table Filters:
    const pageUp = async () => {
        let page = currentPageNumber + 1;
        if(page > totalPages) {
            page -= 1;
        }

        await setCurrentPageNumber(page)
        setLoaded(false)
        await updateList(page);
    }

    const pageDown = async () => {
        let page = currentPageNumber - 1;
        if(page == 0) {
            page += 1;
        }

        await setCurrentPageNumber(page)
        setLoaded(false)
        await updateList(page);
    }

    const setPerPage = async(val:number) => {
        setRowsPerPage(val)
        setLoaded(false)
        await updateList(currentPageNumber, val)

        if(currentPageNumber > totalPages) {
            setCurrentPageNumber(totalPages)
            updateList(totalPages, val)
        }
    }

    const handleActiveModal = (id: string, parkingSpotId: number) => {
        store.dispatch({ type: "parkingSpotIdTransfer", id: parkingSpotId });
        props.handleStateActive(id, parkingSpotId);
    };

    const handleBlackoutModal = (id: string, parkingSpotId: number) => {
        store.dispatch({ type: "parkingSpotIdTransfer", id: parkingSpotId });
        props.handleModal(id, parkingSpotId);
    }

    const cancelBlackout = async(parkingSpotID:number) => {
        let specificBlackouts = parkingSpotBlackouts.filter((bo) => bo.parking_spot_id == parkingSpotID)
        let findSpecificBlackout = null; //Used to find specific blackout, need to assign type for this in the future and it should be null
        if(specificBlackouts.length) {
            findSpecificBlackout = specificBlackouts.find((bo) => {
                let today = dayjs();
                return dayjs(bo.start_date).format('YYYY-MM-DD') <= today.format('YYYY-MM-DD') && today.format('YYYY-MM-DD') < dayjs(bo.end_date).format('YYYY-MM-DD');
            })
        }

        if(findSpecificBlackout) {
            //Shorten the specific blackout now:
            const shortenedBlackout = await fetchRequestJSON(
                'PUT',
                Constants.API_PATH_PARKING_SPOT_BLACKOUTS_DEACTIVATE,
                {
                    parking_spot_id: findSpecificBlackout.id,
                }
            )

            if(shortenedBlackout.status == "Success") {
                NotificationHandler('Success', 'Success', `Parking Spot ID: ${parkingSpotID} can be used for parkings again and its previous blackout has been modified.`)
                updateList()
                return 
            } else {
                NotificationHandler('Error', 'Error', shortenedBlackout.data)
                return
            }
                
        } else {
            NotificationHandler('Error', 'Error', `Could not find any ongoing Blackouts for Parking Spot ID:${parkingSpotID}. Please contact Dev team.`)
            return 
        }
    }

    const handleRowClick = async(parkingSpotID:number) => {
        store.dispatch({ type: "parkingSpotIdTransfer", id: parkingSpotID});
        const { location, history } = props;
        console.log(props)
        if (history)
          history.push({
            pathname: `${history.location.pathname}/parkingspotprofile/`,
            search: `id=${parkingSpotID}`,
          });
    }

    const handleKeyDown = (event:any) => {
        if(event.key == 'Enter') {
          updateList()
        }
    }

    const tableCreation = () => {
        return (
            <tbody>
                {
                    rows.length != 0 &&  //Weird how rows.length doesn't work but this works, as rows.length by itself indicates if the array is empty or not
                    rows.map((parking_spot) => {
                        let blackoutStyles = "transition-all duration-200"
                        let warnings = ""
                        let blackoutToggle = (
                            <button
                              onClick={() => {
                                handleBlackoutModal(parking_spot.parking_spot_location, parking_spot.id);
                              }}
                            >
                                <FiPlus className={"text-3xl text-gray-700 hover:text-green-500"} />
                            </button>
                        );

                        const blackout = parkingSpotBlackouts.filter(bo => bo.parking_spot_id == parking_spot.id)
                        let target = null

                        if(blackout.length != 0) {
                            target = blackout.find((bo) => {
                                let today = dayjs();
                                return dayjs(bo.start_date).format('YYYY-MM-DD') <= today.format('YYYY-MM-DD') && today.format('YYYY-MM-DD') < dayjs(bo.end_date).format('YYYY-MM-DD');
                            })
                        }
                        if(target || !parking_spot.active) {
                            blackoutStyles = "transition-all duration-200 opacity-10"
                            warnings = !parking_spot.active ? "PARKING DEACTIVATED": "Do Not Add Parking, Blackout in Effect"
                            blackoutToggle = (
                                <button
                                  onClick={() => {
                                    cancelBlackout(parking_spot.id);
                                  }}
                                > 
                                    <FaPowerOff className={"text-2xl text-green-500 hover:text-green-300"} /> 
                                </button>
                            );
                        }

                        return (
                            <tr className=" cursor-pointer flex flex-col lg:table-row hover:bg-gray-100 border-t border-gray-400 w-full">
                                <td
                                    className={
                                    blackoutStyles +
                                    " flex flex-row justify-center lg:table-cell  w-auto lg:w-16 px-1 py-4"
                                    }
                                >
                                    <h3 className="text-indigo-500"> {parking_spot.id}</h3>
                                </td>
                                <td
                                    className={
                                    blackoutStyles +
                                    " flex flex-row justify-center lg:table-cell  w-full lg:w-40 px-1 py-4"
                                    }
                                    onClick={() => handleRowClick(parking_spot.id)}
                                >
                                    <h3> {parking_spot.parking_spot_location}</h3>
                                </td>
                                <td
                                      onClick={() => handleRowClick(parking_spot.id)}
                                    className={
                                    blackoutStyles +
                                    " w-36 font-light text-sm text-gray-500 px-10 py-4"
                                    }
                                >
                                    <h3>{parking_spot.parking_spot_level}</h3>
                                </td>
                                <td
                                    className={
                                    blackoutStyles +
                                    " flex flex-row justify-center lg:table-cell  w-full lg:w-40 px-1 py-4"
                                    }
                                    onClick={() => handleRowClick(parking_spot.id)}
                                >
                                    <h3 className="text-indigo-500"> {parking_spot.parking_spot_notes}</h3>
                                </td>
                                {/* Parking Warnings */}
                                <td
                                    onClick={() => handleRowClick(parking_spot.id)}
                                    className=" w-28 text-red-400 px-1 py-4 text-sm font-semibold"
                                >
                                    {warnings}
                                </td>
                                <td className=" hidden  lg:table-cell lg:w-32 px-1 py-4  ">
                                    <div className="flex flex-row justify-center"> 
                                        {
                                            !parking_spot.active ? 
                                            <FcCancel  className={"text-4xl text-red-500 hover:text-green-300"}></FcCancel> : 
                                            <button
                                                onClick={() => {
                                                    handleActiveModal("activeState", parking_spot.id);
                                                }}
                                            >
                                                <FiPauseCircle className={"text-4xl text-gray-700 hover:text-red-500"} />
                                            </button>   
                                        }
                                    </div>
                                </td>
                                <td className=" hidden  lg:table-cell lg:w-32 px-1 py-4  ">
                                    <div className="flex flex-row justify-center">
                                        {
                                            parking_spot.active ? blackoutToggle :  <FcCancel  className={"text-4xl text-red-500 hover:text-green-300"}></FcCancel>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )

                    })
                }
                {
                    !rows.length && 
                    <LoadingGeneral count={10} cols={7} empty={loaded} authorization={authorization}/> 
                }
            </tbody>
        )
    }

    const handleInputChange = (event: { target: { id: string; value: string; }; }) => {
        const { id, value} = event.target;
        if(id == 'search') {
            setSearchByParkingName(value);
        }
    }

    return (
        <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col lg:flex-row align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full mb-3 lg:mb-0 py-1 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                id={"search"}
                onChange={handleInputChange}
                className="text-sm w-96 searchBox"
                placeholder="Search by Parking Spot Location"
                onKeyDown={handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" 
                onClick={() => {updateList()}}
            >Search
            </button>

            {/* <span className="py-1 lg:px-4 font-medium margin text-gray-500 self-start ">
              Filters:
            </span>
            <div className="flex flex-row flex-wrap self-start">
              <button 
                className={
                  (searchByParkingName ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                // onClick={() => {
                //   this.setState({
                //     filter_blackout: !this.state.filter_blackout,
                //   })
                // }}
              >
                Search by Parking Name
              </button>
            </div> */}
          </div>
          <PageSelector
              curpage={currentPageNumber}
              totalpages={totalPages}
              increase={pageUp} //Page Up
              decrease={pageDown} //Page Down
              setPerPage={setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
            />
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 ">ID</th>
              <th className="py-2 ">Parking Spot Location</th>
              <th className="py-2  pl-5">Parking Spot Level</th>
              <th className="py-2  ">Parking Notes</th>
              {/* <th className="py-2  ">Bed Configuration</th>
              <th className="py-2  ">Sofa Bed</th> */}
              {/* <th className="py-2  ">Type</th> */}
              {/* Book Warnings */}
              <th className="py-2  "></th>
              {/* Active Toggle */}
              <th className="py-2 content-center">Deactivate Parking Spot</th>
              {/* Create Blackout  */}
               <th className="py-2  ">Create Blackout</th>
            </tr>
          </thead> 

            { tableCreation() }


          {/* <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={7} empty={loading}/> : rows}</tbody> */}
          {/* <tbody>
          </tbody> */}
        </table>
      </div>
    )
}

export default ParkingSpotList