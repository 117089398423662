import React, { useEffect, useState } from "react";
import { fetchGetJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";

type Props = {
    bookingView: boolean
}
function ParkingProfileCard(props: Props) {
    const [parking, setParking] = useState([])
    
    useEffect(() => {
        loadData()
    }, [])

    const loadData = async() => {
        const id = Number(new URLSearchParams(window.location.search).get("id"));

        const parkingData = props.bookingView ? 
        await fetchGetJSON(Constants.API_PATH_PARKING_BOOKING_ID + id) :
        await fetchGetJSON(Constants.API_PATH_PARKING_SPOT_GET_PARKING + id)  

        setParking(parkingData.data)
    }

    const handleRowClick = async (parkingID: number) => {
        const url = `${window.location.origin}/user/parkings/parkingprofile/?id=${parkingID}`
        window.open(url, "_blank")
    }

    const loadTable = () => {
        console.log(parking)
        return (
            <tbody>
                {
                    parking.map((parkingInfo)=> {
                        return(
                            <tr className=" w-full  hover:bg-gray-100 h-12 border-b border-gray-400 ">
                                <td
                                    className={
                                    "pl-2 text-purple-400 font-thin"
                                    }
                                    onClick={() => handleRowClick(parkingInfo.id)}
                                >
                                    <h3 className="text-indigo-500"> {parkingInfo.id}</h3>
                                </td>
                                <td
                                    className={
                                        "pl-5 text-purple-400 font-thin"
                                    }
                                    onClick={() => handleRowClick(parkingInfo.id)}
                                >
                                    <h3> {
                                        props.bookingView ? 
                                        `${parkingInfo.parking_spots.parking_spot_location} | ${parkingInfo.parking_spots.parking_spot_level}` : 
                                        `${parkingInfo.tenant.first_name} ${parkingInfo.tenant.last_name}`
                                    }</h3>
                                </td>
                                <td
                                    className={
                                        "pl-5 text-purple-400 font-thin"
                                    }
                                    onClick={() => handleRowClick(parkingInfo.id)}
                                >
                                    <h3> {parkingInfo?.start_date}</h3>
                                </td>
                                <td
                                    className={
                                        "pl-5 text-purple-400 font-thin"
                                    }
                                    onClick={() => handleRowClick(parkingInfo.id)}
                                >
                                    <h3> {parkingInfo?.end_date}</h3>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }
 
    return (
        <div className="w-full border  my-4">
            <table className="table-auto w-full mb-8">
            <thead>
                <tr className=" table-row text-left font-normal text-gray-500 ">
                    <th className="py-5 pl-3  ">Parking ID</th>
                    <th className="py-2  ">{props.bookingView ? 'Parking Spot' : 'Parking Resident'}</th>
                    <th className="py-2 hidden lg:table-cell  ">Start Date</th>
                    <th className="py-2 hidden lg:table-cell ">End Date</th>
                </tr>
            </thead>
                {loadTable()}
            </table>
        </div>
    )
}

export default ParkingProfileCard;
