import { Component } from "react";
import store from "../../redux/store";
import { Link, useRouteMatch, RouteComponentProps } from "react-router-dom";
import { cleaningCalendarPermissionsView } from "../../utils/PermissionViewForDashboard";

//NOT USED
// enum UserTypes {
//   Fin = "Fin",
//   Ops = "Ops",
//   Sales = "Sales",
//   Clean = "Cleaning",
//   CleanerAdmin = "CleanerAdmin",
//   Office = "Office",
//   Admin = "Admin",
// }

type Props = {
  placeholder: string;
};

type State = {
  userType: string;
};

class DashBar extends Component<Props, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      userType: "",
    };
  }

  componentDidMount = async () => {
    let type = await store.getState().userReducer.userType;
    console.log("TYPE DASHAPP:", type);

    this.setState({
      userType: type,
    });
  };

  render() {
    const types = this.state.userType;

    let links = <></>;
    for(const type of types) {
      if (type == "Finance" || type == "Fin") {
        links = (
          <>
            <FinDashLinks text={"Overview"} linkEnd={""} />
            <FinDashLinks text={"All Invoices"} linkEnd={"invoices"} />
            <FinDashLinks
              text={"Invoice Functions"}
              linkEnd={"Invoice-Functions"}
            />
            <FinDashLinks text={"Reports"} linkEnd={"finance-reports"} />
          </>
        );
      } else if (type == "Ops") {
        links = <></>;
      } else if (type == "Sales") {
        links = (
          <>
            <FinDashLinks text={"Overview"} linkEnd={""} />
            <FinDashLinks text={"Rolodex"} linkEnd={"rolodex"} />
            <CleanDashLinks text={"Cleaners"} linkEnd={"cleaners"} />
          </>
        );
      } else if (type == "CleanerAdmin" || type == "QAM_L4") {
        links = (
          <>
            <CleanDashLinks text={"Overview"} linkEnd={""} />
            <CleanDashLinks text={"Analytics"} linkEnd={"cleaning-analytics"} />
            <CleanDashLinks text={"Calendar"} linkEnd={"cleaning-calendar"} />
            {/* <CleanDashLinks
                  text={"Archive Forms"}
                  linkEnd={"Archive-Forms"}
                  /> */}
            {/* <CleanDashLinks text={"Payroll"} linkEnd={"payroll"} /> */}
            <CleanDashLinks text={"Cleaners"} linkEnd={"cleaners"} />
          </>
        );
      } else if (type == "Cleaning" || type == "CleanersL1L3") {
        links = (
          <>
            <CleanDashLinks text={"Overview"} linkEnd={""} />
          </>
        );
      } else if (type == "Office") {
        links = (
          <>
            <FinDashLinks text={"Overview"} linkEnd={""} />
          </>
        );
      } else if (cleaningCalendarPermissionsView.includes(type)) {
        links = (
          <>
            <FinDashLinks text={"Overview"} linkEnd={""} />
            {/* <FinDashLinks text={"Rolodex"} linkEnd={"rolodex"} />
            <CleanDashLinks text={"Analytics"} linkEnd={"cleaning-analytics"} /> */}
            <CleanDashLinks text={"Cleaners"} linkEnd={"cleaners"} />
            <CleanDashLinks text={"Finance Dashboard"} linkEnd={"finance-dash"} />
            <CleanDashLinks text={"Office Dashboard"} linkEnd={"office-dash"} />
            <CleanDashLinks text={"Sales Dashboard"} linkEnd={"sales-dash"} />
          </>
        );
      } else {
          <FinDashLinks text={"Overview"} linkEnd={""} />
      }
    }
    
    return (
      <div className="flex flex-row h-6 max-w-md justify-start lg:w-full  overflow-y-none scroll-hide ">
        {links}
      </div>
    );
  }
}

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let currentPage = props.linkEnd;
  // path.endsWith("Dashboard")

  if (path == "/User/Dashboard/" + currentPage) {
    currentPageStyle = "border-b border-green-500 text-gray-800";
  } else if (path.endsWith("Dashboard") && props.text == "Overview") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link
        to={`/user/dashboard/${props.linkEnd}`}
        className="whitespace-nowrap"
      >
        {props.text}
      </Link>
    </div>
  );
}

export function CleanDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let currentPage = props.linkEnd;
  // path.endsWith("Dashboard")
  if (path == "/user/dashboard/" + currentPage) {
    currentPageStyle = "border-b border-green-500 text-gray-800";
  } else if (path.endsWith("dashboard") && props.text == "Overview") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
  }

  return (
    <div className={currentPageStyle + " mr-8 lg:mr-5 whitespace-nowrap   "}>
      <Link to={`/user/dashboard/${props.linkEnd}`}>{props.text}</Link>
    </div>
  );
}

export default DashBar;

function async() {
  throw new Error("Function not implemented.");
}
