import { Component } from "react";
import { FaRegCalendarPlus } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO, formatFullDate } from "../../../utils/DateFormat";
import { FiCornerUpRight } from "react-icons/fi";
import ToggleSwitch from "../../ToggleSwitch";
import { fetchRequestJSON, fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import dayjs from "../../../utils/Dayjs";

const apiPath = "/cleanings/add-cleaner-cleaning";

type Props = {
  handleModalToggle: any;
  onoff: boolean;
};

type ModalState = {
  onoff: boolean;
  cleaningList: any[];

  start_date: string;
  end_date: string;
};

class ConfirmCleaningView extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      onoff: this.props.onoff,
      cleaningList: [],

      start_date: "",
      end_date: "",
    };
  }

  componentDidMount = async () => {
    // let start_date = dayjs();
    // let end_date = dayjs();
    // start_date = start_date.subtract(1, 'month');
    // end_date = end_date.add(2, 'month');
    // let allCleanings = await fetchGetJSON(`${Constants.API_PATH_CLEANING_GET_ALL}?start_date=${start_date.format('YYYY-MM-DD')}&end_date=${end_date.format('YYYY-MM-DD')}`);
    // let cleanings: any[];
    // console.log(allCleanings);
    // if (allCleanings.status === "Success") {
    //   cleanings = allCleanings.data.map((cleaning: any) => {
    //     return {
    //       id: "" + cleaning.id,
    //       title: cleaning.title || "No Title Given",
    //       date: cleaning.cleaning_date.split('T')[0],
    //       unit_id: cleaning.unit_id
    //     }
    //   })
      
    //   this.setState({
    //     cleaningList: cleanings,
    //   }, () => console.log(this.state.cleaningList))
    // }
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    console.log(event.target.value)
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleClick = async () => {
    let res = await fetchRequestJSON("PUT", Constants.API_PATH_CLEANING_CONFIRM, {
      start_date: this.state.start_date,
      end_date: this.state.end_date
    })
    console.log(res);
  }

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-sm py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <span className="text-xl py-1 font-bold text-gray-800 text-left">
            Confirm Cleanings
          </span>
          <form className="w-full">
            <div className={" block mt-4 w-full"}>
              <div className="flex flex-row items-center justify-between mb-4 grid grid-cols-6">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3 col-start-1 col-span-1"
                  }
                >
                  Start Date:
                </span>
                <input
                  onChange={this.handleDateChange}
                  id="start_date"
                  type="date"
                  className="col-start-2 col-span-1 mt-0 py-1 block px-0.5 border-0 w-auto border-b-2 border-gray-200 focus:ring-0 focus:border-black mr-auto"
                >
                </input>
              </div>
              <div className="flex flex-row items-center justify-between mb-4 grid grid-cols-6">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3 col-start-1 col-span-1"
                  }
                >
                  End Date:
                </span>
                <input
                  onChange={this.handleDateChange}
                  id="end_date"
                  type="date"
                  className="col-start-2 col-span-1 mt-0 py-1 block px-0.5 border-0 w-auto border-b-2 border-gray-200 focus:ring-0 focus:border-black mr-auto"
                >
                </input>
              </div>
            </div>

            <div className="flex flex-row justify-center w-full mt-6">
              <button
                id="cancel"
                onClick={this.handleClick}
                className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500  rounded-sm text-white  shadow-sm  "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ConfirmCleaningView;