import { ImPhone } from "react-icons/im";


type Prop = {
    buildingInfo:any; //NEED TO CREATE A MODEL FOR BUILDINGS
}

const ParkingSpotBuildingInfo =(props: Prop) => {
    
    return (
        <div className="pt-2">
        <div className=" slide-in-blurred-top font-display border flex flex-row px-4 py-4 mb-4">
          <div className="w-1/2 flex flex-col">
            Building Information
            <span className="text-s text-gray-400 font-europe">
              {props.buildingInfo.building_name}
            </span>
            <hr className="mt-3"/>
            <span className="text-xs mt-2 text-gray-600 font-europe">
              Building Address: {props.buildingInfo.address}
            </span>
            <span className="text-xs text-gray-600 font-europe">
              District: {props.buildingInfo.district}
            </span>
            <span className="text-xs text-gray-600 font-europe">
              Amenities: {props.buildingInfo.amenities}
            </span>
          </div>

          <div className="w-1/2 flex flex-col items-end mt-5">
            <span className="text-s text-gray-400 font-europe flex flex-row">
              Concierge: <ImPhone className="mt-1 mx-1 " />{" "}{props.buildingInfo.concierge_number}
            </span>
            <span className="text-s text-gray-400 font-europe flex flex-row">
              Property Management: <ImPhone className="mt-1 mx-1 " />{" "}{props.buildingInfo.property_manager_number}
            </span>
          </div>
        </div>

        <div className=" slide-in-blurred-top grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* <div className="  font-display border px-5 pt-3 pb-5  flex flex-col ">
            <span className="text-lg mb-3">Services:</span>

            <span className="text-sm text-gray-400  mb-3 mt-1">
              <span className="text-gray-600">ISP Provider: </span>
              {this.props.UnitData.isp}{" "}
            </span>
            <span className="text-sm text-gray-400 mb-3  ">
              <span className="text-gray-600">Internet Account #: </span>
              {this.props.UnitData.isp_account}{" "}
            </span>
            <span className="text-sm text-gray-400  mb-3 ">
              <span className="text-gray-600">Login: </span>
              {this.props.UnitData.login}{" "}
            </span>
            <span className="text-sm text-gray-400 mb-3">
              <span className="text-gray-600">Password: </span>
              {this.props.UnitData.password}{" "}
            </span>
            <div className=" text-gray-800  mb-3 border-b "> </div>
            <span className="text-sm text-gray-400 mb-3 ">
              <span className="text-gray-600">Unit Phone: </span>
              {this.props.UnitData.unit_phone}{" "}
            </span>
            <div className=" text-gray-800  mb-3 border-b "> </div>
            <span className="text-sm text-gray-400  mb-3 ">
              <span className="text-gray-600">TV Provider: </span>
              {this.props.UnitData.tvp}{" "}
            </span>
            <span className="text-sm text-gray-400 mb-3  ">
              <span className="text-gray-600">TV Account #: </span>
              {this.props.UnitData.isp_account}{" "}
            </span>
          </div> */}

          <div
            className=" slide-in-blurred-top font-display border py-4 px-5
           flex flex-col"
          >
            <span className="text-lg mb-2">Internal SVS Building Notes: </span>
            <div className="flex flex-row">
                <div className="flex flex-row items-center justify-start">
                  <span className=" text-gray-500 text-xs pt-1">
                    {props.buildingInfo.svs_notes}
                  </span>
              </div>

              {/* This is commented out as Rent increase due date does not exist anymore in the system and this is what model type is*/}
              {/* {modelType[0]} */}
            </div>

            {/* {modelType[1]}
            <div className=" text-gray-800  my-4 border-b "> </div>
            <span className="text-sm text-gray-400 mt-1">
              Hydro Provider-{" "}
              <span className="text-gray-600">
                {this.props.UnitData.hydro_provider}
              </span>
            </span> */}
          </div>
          <div className=" slide-in-blurred-top font-display flex flex-col border py-4 px-5">
            <span className="text-lg mb-3">Special Instructions:</span>
            <span className="text-gray-500 text-xs">
                {props.buildingInfo.special_instructions}
            </span>
          </div>
        </div>
      </div>
    )
}
export default ParkingSpotBuildingInfo 