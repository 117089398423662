import { useState } from "react";
import { Modal } from "@mui/material";
import {
    fetchGetJSON,
    fetchRequestJSON,
  } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { useNotification } from "../Notifications/NotificationProvider";

interface Props {
    open: boolean;
    closeModal: any;
    bookingId: number;
}

function ReleaseModal (props: Props) {
    const [expiryReason, setExpiryReason] = useState(null);
    const [warning, setWarning] = useState(false)
    const dispatch = useNotification();

    function onFlagReasonChange(event: any) {
        event.preventDefault();
        console.log(event.target.value);
        setExpiryReason(event.target.value);
    }

    function HandleError(type : string, title : string, message : string, ){
        dispatch({
            type: type.toUpperCase(),
            title: title,
            message: message
        })
    }

    async function submitFlag() {
        // props.flagBooking();
        const booking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${props.bookingId}`);

        if (booking.status === "Success") {
            if(!expiryReason) {
                // HandleError("Error", "No Release Reason", "Booking must contain a release reason before a release of a booking can be issued");
                setWarning(true)
                return
            }
            const editBooking = await fetchRequestJSON("PUT", Constants.API_PATH_BOOKING_EDIT,
            {
                id: props.bookingId,
                fields: { 
                    release: true,
                    expiry_notes: expiryReason
                }
            });
            console.log(editBooking);
            setWarning(false)
        } else {
            HandleError("Error", "Booking ID does not exist", "This booking cannot be flagged");
        }
        window.location.reload();
    }

    return(
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            open={props.open}
            onClose={() => props.closeModal()}
        >
            <div className=" appear bg-white shadow-lg rounded-lg h-1/2 w-1/2 border-4  border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center">
                <span className="text-xl font-bold text-gray-800 text-left pb-4">Release Booking</span>
                <label className="block pl-3 mb-5 mt-2">
                    <span className="text-gray-400 text-xs">Release Reason</span>
                <textarea
                    id="booking_notes"
                    onChange={(event) => onFlagReasonChange(event)}
                    value={expiryReason}
                    className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    rows={3}
                ></textarea>
                </label>
                {
                    warning &&
                    <p className="text-xl text-red-500 pl-3 mb-5">IMPORTANT: Release Reason cannot be empty</p>
                }
                <div className="h-24 mx-auto flex flex-col">
                    <button
                        onClick={() => submitFlag()}
                        className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm"
                    >
                        Release Booking
                    </button>
                </div>
            </div>
        </Modal>
    );
}
export {ReleaseModal}