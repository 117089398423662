import { Component } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { RiUser3Fill } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";
import { formatFullDate } from "../../utils/DateFormat";
import EditOptionPanel from "../EditOptionPanel";
import EditBar from "../EditBar";
import EditBooking from "./EditBooking";
import CancelModal from "./CancelModal";
import RateModal from "./RateModal";
import TransferModal from "./TransferModal";
import { fetchGetJSON, fetchRequestJSON, } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";

// Takes Booking ID Prop and pull all info

interface Props {
  handleModalToggle: any;
  onoff: boolean;
  BookingId: number;
}

interface QuickViewModalState {
  onoff: boolean;

  corporate: boolean;
  booking_id: number;
  corporate_id: number;
  corporate_name: string;
  tenant_name: string;
  check_in: Date;
  check_out: Date;
  suite: string;
  suite_number: string;
  booking_notes: string;
  flagged: boolean;

  editToggle: boolean;
  toggleTransfer: boolean;
  toggleRate: boolean;
  toggleCancel: boolean;
}

class BookingQuickView extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      onoff: this.props.onoff,
      corporate: true,
      booking_id: -1,
      corporate_id: -1,
      corporate_name: "N/A",
      tenant_name: "",
      check_in: new Date(),
      check_out: new Date(),
      suite_number: "",
      booking_notes: "",
      suite: "",
      flagged: false,
      editToggle: false,

      toggleTransfer: false,
      toggleRate: false,
      toggleCancel: false,
    };
  }

  componentDidUpdate = async (previousProps: any) => {
    if (store.getState().bookingReducer.curId !== -1 && previousProps !== this.props) {
      const allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_GET_ALL);
      let bookingData = allBookings.data.find((bookingItem: any) => bookingItem.id === store.getState().bookingReducer.curId);

      let corpData = null;
      if (bookingData.corporate_id) {
        const allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
        corpData = allCorps.data.find((corpItem: any) => corpItem.id === bookingData.corporate_id);
      }

      const allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
      const tenantData = allTenants.data.find((tenantItem: any) => tenantItem.id === bookingData.tenant_id);

      const allUnits = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
      const unitData = allUnits.data.find((unitItem: any) => unitItem.id === bookingData.unit_id);

      this.setState({
        booking_id: bookingData.id,
        corporate_id: corpData ? corpData.id : -1,
        corporate_name: corpData ? corpData.corporate_name : "N/A",
        booking_notes: bookingData.booking_notes,
        suite_number: unitData.suite_number,
        tenant_name: `${tenantData.first_name} ${tenantData.last_name}`,
        check_in: new Date(bookingData.check_in.split("T")[0]),
        check_out: new Date(bookingData.check_out.split("T")[0]),
        suite: unitData.suite_name,
      });
    }
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff });
    }
  };

  handleModalDelete = () => {
    this.props.handleModalToggle(this.props.onoff);
    this.setState({ onoff: !this.state.onoff });
  };

  // TODO: Refactor this function.
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // TODO: Refactor this function.
  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000);
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    }();
    this.setState(stateObject);
  };

  fetchBookingByID = async (booking_id: string) => {
    const response = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${booking_id}`)
    return response.data[0];
  }

  toggleFlag = async (event: any) => {
    const bookingWithID = await this.fetchBookingByID(this.state.booking_id.toString());
    const updatedBooking = { ...bookingWithID, flagged: !bookingWithID.flagged }
    const putRequestResponse = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BOOKING_EDIT,
      {
        id: this.state.booking_id,
        fields: {
          flagged: !bookingWithID.flagged
        }
      }
    )
    this.setState({ flagged: !bookingWithID.flagged })
  };

  handleEditBarToggle = () => {
    this.setState({ editToggle: !this.state.editToggle });
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    let bgFocus;
    if (this.state.editToggle) {
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    let CheckIn = (
      <div className=" slide-in-blurred-top font-display  border px-3 py-4 ">
        <div className="flex-wrap flex justify-start  items-center flex-row">
          {/* CheckIn Info */}
          <h2 className=" mr-4 pt-1 inline-block align-middle font-regular text-base">
            Check In <span className="text-gray-400">|</span>{" "}
            <span className="font-bold">{formatFullDate(this.state.check_in)}</span>{" "}
          </h2>

          {/* <div
            id="parkingRequired"
            className=" whitespace-no-wrap h-6 mr-2 bg-yellow-300 text-yellow-700 font-medium text-xs rounded-full py-1 w-auto flex items-center justify-center  text-center px-4"
          >
            {" "}
            Parking Required{" "}
          </div>
          <div
            id="FirstRent"
            className=" whitespace-no-wrap h-6 mr-2 bg-red-300 text-red-800  font-medium text-xs rounded-full py-1 w-auto flex items-center align-center text-center px-4"
          >
            {" "}
            First Rent Required{" "}
          </div> */}

          {/* Unit Info */}
          <div style={{ marginLeft: "auto" }} className=" w-auto flex-col  ">
            <h3 className="text-gray-500 text-sm font-semibold w-auto  justify-end ">{this.state.suite}</h3>
            <h3 className="text-gray-500 text-xs font-thin w-auto text-right justify-end ">{this.state.suite_number}</h3>
          </div>
        </div>

        <div className="flex-wrap flex justify-between mt-2 flex-row">
          <div className="w-auto flex flex-col">
            <h2 className=" text-gray-500 mr-4 pt-1 inline-block align-middle font-hairline text-sm">Notes:</h2>
            <p className=" max-w-sm text-gray-700 mr-4 pt-1 inline-block align-middle font-regular text-xs">{this.state.booking_notes}</p>
          </div>

          <div className="w-auto  flex flex-col justify-end ">
            <button className=" transition-all duration-200 ease-in-out hover:bg-gray-200  flex flex-row justify-between rounded-sm py-2 px-4 whitespace-no-wrap text-gray-800 border mr-0 pt-2 flex flex-row items-center  text-xs">
              <AiOutlineClockCircle className="text-lg " />
              <span className="ml-2">Check In Time Change</span>
            </button>
          </div>
        </div>
      </div>
    );

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed flex flex-row align-center justify-center w-full h-full bg-gray-600 bg-opacity-40 z-40 "
      >
        <EditBooking
          stateChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
          bookingData={this.state}
          handleToggleChange={this.handleToggleChange}
          ErrorNote={null}
        />
        {bgFocus}
        <TransferModal
          handleModalToggle={() => this.handleToggleChange("toggleTransfer", this.state.toggleTransfer)}
          onoff={this.state.toggleTransfer}
          title="Transfer Booking"
        />
        <RateModal
          handleModalToggle={() => this.handleToggleChange("toggleRate", this.state.toggleRate)}
          onoff={this.state.toggleRate}
          title="Rate Change"
          bookingId = {this.props.BookingId}
        />
        {/* <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("toggleCancel", this.state.toggleCancel)
          }
          onoff={this.state.toggleCancel}
          title={"Cancel Booking"}
        /> */}

        <div className=" appear font-sofia bg-white shadow-lg rounded-lg h-auto max-w-screen-md self-center align-center  ">
          <div className="w-full bg-gray-100 pt-8 pb-3 px-8 rounded-t-lg">
            {this.state.corporate ? <RiUser3Fill className="text-3xl text-gray-500" /> : <FaUserTie className="text-4xl text-gray-500" />}
            <div className="flex flex-row my-1 justify-between items-center">
              <span className="text-2xl font-bold text-gray-800">Booking ID #{this.state.booking_id}</span>
              <span className=" cursor-pointer h-8 mr-2 bg-gray-700 text-white font-medium text-xs rounded-full pb-1  w-auto  flex items-center text-center px-5">{this.state.suite}</span>
            </div>

            <div className="flex flex-row my-1 justify-between items-end pt-1">
              <div className="flex flex-col text-xs font-medium text-gray-600">
                <span className=" pb-1">Corporate: {this.state.corporate_name}</span>
                <span className=" pb-2">Tenant Name: {this.state.tenant_name}</span>
                <span className="opacity-70 text-2xs pb-1">Check In: {formatFullDate(this.state.check_in)}</span>
                <span className="opacity-70 text-2xs ">Check Out: {formatFullDate(this.state.check_out)}</span>
              </div>

              <EditOptionPanel
                handleEditBarToggle={this.handleEditBarToggle}
                parentData={this.state}
                handleFlag={this.toggleFlag}
                handlePopup={this.handleModalDelete}
                handleState={this.handleToggleChange}
                panelType="Booking"
              />
            </div>
          </div>

          <div className="w-full pt-6 pb-8 px-8">{CheckIn}</div>
        </div>
      </div>
    );
  }
}

export default BookingQuickView;
