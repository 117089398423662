import store from "../../../../redux/store"
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../BreadCrumb";
import { ImCancelCircle } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { FiMoreHorizontal } from "react-icons/fi";

type Prop = {
    parkingSpotBlackoutInfo:any;
    toggle: boolean;
    handleBlackoutsToggle: any; //This is a function, need to rename this when we refactor
}

const ParkingSpotBlackoutEditModal = (props:Prop) => {
    const [activeParkingSpots, setActiveParkingSpot] = useState([])

    //Payload for Creating Parking Spot:
    const [parkingSpotId, setParkingSpotId] = useState(props.parkingSpotBlackoutInfo?.parking_spot_id);
    const [startDate, setStartDate] = useState(props.parkingSpotBlackoutInfo?.start_date);
    const [endDate, setEndDate] = useState(props.parkingSpotBlackoutInfo?.end_date);
    const [reason, setReason] = useState(props.parkingSpotBlackoutInfo?.reason)

    //Notificatoin Handler Logic:
    const dispatch = useNotification();

    const HandleError = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    useEffect(() => {
        loadActiveParkingSpots();
    }, []);

    const loadActiveParkingSpots = async() => {
        const allActiveParkingSpots = await fetchGetJSON(Constants.API_PATH_PARKING_SPOT_GET_ALL_ACTIVE);
        setActiveParkingSpot(allActiveParkingSpots.data.sort((parkingSpotOne:any, parkingSpotTwo:any) => parkingSpotOne.parking_spot_location.localeCompare(parkingSpotTwo.parking_spot_location)))
    }  


    const contentCreation = () => {
        return (
            <form className="flex flex-col pl-6 pr-12 py-4">
                <strong className="text-gray-600 text-md font-bold mb-4 mt-2">
                    General Info
                </strong>
                <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select Parking Spot*</strong>
                    </span>
                    <select
                        id="parking_spots"
                        onChange={(input) => {setParkingSpotId(input.target.value)}}
                        value={props.parkingSpotBlackoutInfo.parking_spot_id}
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                        placeholder="Choose a Parking Spot"
                    >
                        <option selected disabled>Please Select a Parking Spot (Required)</option>
                        {activeParkingSpots.map((parking_spot:any) => 
                          <option value={parking_spot.id}>{parking_spot.parking_spot_location} {parking_spot.parking_spot_level}</option>
                        )}
                    </select>
                 </label>
                 <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select the Start Date of the Blackout Period</strong>
                    </span>
                    <input
                      id="start date"
                      onChange={(input) => {
                        setStartDate(input.target.value);
                      }}
                      type="date"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                      defaultValue={props.parkingSpotBlackoutInfo.start_date}
                    />
                 </label>
                 <label className="block pl-3 mb-5">
                    <span className="text-gray-400 text-xs">
                        <strong>Select the End Date of the Blackout Period</strong>
                    </span>
                    <input
                      id="end date"
                      onChange={(input) => {
                        setEndDate(input.target.value);
                      }}
                      type="date"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                      defaultValue={props.parkingSpotBlackoutInfo.end_date}
                    />
                 </label>

                 <label className="block pl-3 mb-5 mt-2">
                    <strong className="text-gray-400 text-xs">Reason for Blackout</strong>
                    <textarea
                        id="notes"
                        className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows={3}
                        onChange={(input) => {setReason(input.target.value)}}
                        defaultValue={props.parkingSpotBlackoutInfo.reason}
                    ></textarea>
                </label>
            </form>
        )
    }

    const handleSubmit = async() => {
        if(!parkingSpotId || !startDate || !endDate){
            HandleError('Error', 'Error', 'Parking Spot Blackout needs to have a parking spot, start date, and end date.')
            return
        }

        if(startDate > endDate) {
            HandleError('Warning', 'Error', 'Start Date is greater than End Date')
            return
        }

        if(startDate == endDate) {
            HandleError('Error', 'Error', 'Start Date CANNOT be equal to End Date. Blackout days are counted as nightly.')
            return
        }

        const parkingSpotBlackoutEditPayload = {
            start_date: startDate,
            end_date: endDate,
            parking_spot_id: parkingSpotId,
            reason: reason,
        }


        const editParking = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_SPOT_BLACKOUTS_EDIT, {
            id: props.parkingSpotBlackoutInfo.id,
            fields: parkingSpotBlackoutEditPayload
        })

        if(editParking.status == 'Success') {
            HandleError('Success', 'Success', 'Succesfully Edited Parking Spot Blackout')
            //Redux below:
            let update = store.getState().parkingSpotBlackoutReducer.profileUpdate;
            update()
            props.handleBlackoutsToggle()
            return
        } else {
            HandleError('Error', 'Error', 'Please check that all fields are valid')
            return
        }
    }

    return (
        <div className={
            props.toggle ?  "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + 
            "z-50 opacity-100 translate-x-0 lg:max-w-lg w-full" : "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide"
           }>  
           {/* Top Title Bar */}
           <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
             <button
               className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
             >
               <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
             </button>
   
             <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
               <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
             </button>
           </div>
           <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
             <span className=" font-black text-xl text-gray-700">
               New Parking Blackout Spot
             </span>
             <span className="italic font-sofia text-gray-400 text-lg font-thin">
             </span>
           </div>
           {contentCreation()}
   
           {/* Submit Logic Below */}
           <div className="h-24 flex flex-row ">
             <button
               onClick={() => handleSubmit()}
               className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
             >
               Submit
             </button>
           </div>
         </div>
    )
}
export default ParkingSpotBlackoutEditModal