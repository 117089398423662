import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import { ListUsersResponse, UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

async function listUsers(): Promise<UserType[]> {

    const res = await fetchAuthSession()

    AWS.config.region = 'us-east-1'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        Logins: {
            'cognito-idp.us-east-1.amazonaws.com/us-east-1_pwG6SHGYo': res.tokens.idToken.toString()
        }
    });

    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

    let identities = await cognitoidentityserviceprovider.listUsers({
        UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID
    })
        .promise()
        .then((result: ListUsersResponse) => result);
    console.log(identities.Users)
    return identities.Users
}

async function addToGroup() {
    const res = await fetchAuthSession()

    AWS.config.region = 'us-east-1'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        Logins: {
            'cognito-idp.us-east-1.amazonaws.com/us-east-1_pwG6SHGYo': res.tokens.idToken.toString()
        }
    });

    var params = {
        GroupName: 'Admin', /* required */
        UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID, /* required */
        Username: '85f0f1ee-8f52-4120-ba31-4418f5e7d468' /* required */
      };

    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
    await cognitoidentityserviceprovider.adminAddUserToGroup(params).promise().then((res)=>{
        console.log(res)
    })
}

async function disableUser() {
    const res = await fetchAuthSession()

    AWS.config.region = 'us-east-1'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        Logins: {
            'cognito-idp.us-east-1.amazonaws.com/us-east-1_pwG6SHGYo': res.tokens.idToken.toString()
        }
    });

    var params = {
        UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID, /* required */
        Username: '85f0f1ee-8f52-4120-ba31-4418f5e7d468' /* required */
      };

    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
    await cognitoidentityserviceprovider.adminDisableUser(params).promise().then((res)=>{
        console.log(res)
    })
}

async function createUser() {
    const res = await fetchAuthSession();

    AWS.config.region = 'us-east-1'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        Logins: {
            'cognito-idp.us-east-1.amazonaws.com/us-east-1_pwG6SHGYo': res.tokens.idToken.toString()
        }
    });

    var params = {
        UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID, /* required */
        Username: 'newAccount@skyviewsuites.com', /* required */
        TemporaryPassword: 'SVSDev1234',
      };

    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
    await cognitoidentityserviceprovider.adminCreateUser(params).promise().then((res)=>{
        console.log(res)
    })
}


const ManageUsers = () => {
    return (
        <div className="manage-users">
            <button onClick={addToGroup}>Add to Group</button>
            <button onClick={listUsers}>List User</button>
            <button onClick={disableUser}>Delete User</button>
            <button onClick={createUser}>Create User</button>
        </div>
    );
}

export default ManageUsers;
