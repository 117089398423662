import store from "../../../../redux/store"
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../BreadCrumb";
import { ImCancelCircle } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import ParkingSpotBlackoutEditModal from "../Update/ParkingSpotBlackoutEditModal";

//Dayjs Logic, implement this as a helper function when we refactor
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

const ParkingSpotBlackoutProfile =() => {
    const [parkingSpotBlackoutID, setParkingSpotBlackoutID] = useState(-1)
    const [parkingSpotBlackoutInfo, setParkingSpotBlackoutInfo] = useState(null)

    //Blackout Edit 
    const [editToggle, setEditToggle] = useState(false);

    useEffect(() => {
        loadData();
    
        //Store Load Data in reducer:
        store.dispatch({
            type: "parkingSpotBlackoutProfileUpdate",
            profileUpdate: loadData,
        });
    }, [])

    const handleEditBarToggle = () => {
        console.log("In Handle Edit Bar Toggle")
        const toggle = editToggle
        setEditToggle(!toggle)
    }

    const loadData = async () => {
        const blackoutID: number = +new URLSearchParams(window.location.search).get("id");
        setParkingSpotBlackoutID(blackoutID)

        const parkingSpotBlackoutInfo = await fetchGetJSON(`${Constants.API_PATH_PARKING_SPOT_BLACKOUTS_GET_SINGLE}${blackoutID}`)
        setParkingSpotBlackoutInfo(parkingSpotBlackoutInfo.data)
        
    }

    return (
        <div className=" bg-white font-display lg:rounded-tl-lg  ">
        {
            editToggle &&
            <ParkingSpotBlackoutEditModal
                toggle={editToggle}
                parkingSpotBlackoutInfo={parkingSpotBlackoutInfo}
                handleBlackoutsToggle={handleEditBarToggle}
            />
        }

        {/* {bgFocus}
        {editBlackout} */}

        {
            editToggle && 
            <div
                onClick={handleEditBarToggle}
                className=" fade-bg w-full h-screen bg-black z-40  fixed"
            >

            </div>
        }

        <BreadCrumb
          rootPage="Parking > Parking Spot Blackout Profile"
          subPage={"Parking Spot Blackout (ID: " + parkingSpotBlackoutID.toString() + ")"}
          sideButton=""
          buttonAction={""}
        />

        <div className="  px-4 lg:px-10 mx-auto border rounded-lg py-10 bg-white">
          <div className="flex flex-row mb-0">
            <div className="flex flex-col w-1/2">
              <h2 className="px-2 -mt-1 mb-1 font-bold">
                Parking Spot Blackout ID: {parkingSpotBlackoutInfo?.id}
              </h2>
              <div className="mx-2 mt-4 rounded-sm py-2 px-3 border grid grid-cols-2 gap-1 mb-1">
                <div className="flex flex-col">
                    <span className="text-sm pb-1">Start Date:</span>
                    <span className="text-sm text-gray-500">
                    {parkingSpotBlackoutInfo?.start_date}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm pb-1">End Date:</span>
                    <span className="text-sm text-gray-500">
                    {parkingSpotBlackoutInfo?.end_date}
                    </span>
                </div>
              </div>
              <div className="mx-2 mt-4 rounded-sm py-2 px-3 border grid grid-cols-2 gap-1 mb-1">
                <div className="flex flex-col">
                    <span className="text-sm pb-1">Parking Spot:</span>
                    <span className="text-sm text-gray-500">
                    {parkingSpotBlackoutInfo?.parking_spots.parking_spot_location} | {parkingSpotBlackoutInfo?.parking_spots.parking_spot_level}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm pb-1">Reason:</span>
                    <span className="text-sm text-indigo-500">
                    {parkingSpotBlackoutInfo?.reason}
                    </span>
                </div>
              </div>
            </div>

            {/* Right Side */}

            <div className="flex flex-row w-1/2 items-start justify-end">
              <button
                key={"Edit"}
                onClick={() => handleEditBarToggle()}
                className="mx-2 group pb-1"
              >
                <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
                  Edit
                </span>
                <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
              </button>
              <button
                // onClick={() => this.deleteBlackout()}
                key={"Delete"}
                className="mx-2 group pb-1"
              >
                <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
                  Delete
                </span>
                <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}
export default ParkingSpotBlackoutProfile