import { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import { RiUser3Fill } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import { addDays, formatFullDate, formatStringDate } from "../../../utils/DateFormat";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";
import CancelModal from "../../Bookings/CancelModal";
import EditOptionPanel from "../../EditOptionPanel";
import EditInvoice from "../../Invoices/EditInvoice";
import SendInvoice from "../../Invoices/SendInvoice";
import { fetchGetJSON, fetchRequestJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import store from "../../../redux/store";
import { LoadingInvoiceQuickView } from "../../../utils/LoadingComps/LoadingInvoice";
import { invoiceType, bookingType, billingType, corporateType, tenantType, request, invoiceLineType } from "../../../models/modelList";
import { GetAppStage } from "../../../api-requests/api-gateway";
import { withHooksHOC } from "../../Notifications/NotificationProvider";
import ToggleSwitch from "../../ToggleSwitch";
import InvoiceHistory from "./InvoiceHistory";
import Modal from "../../Modal";

// Takes Booking ID Prop and pull all info

interface Props {
  handleModalToggle: any;
  onoff: boolean;
  info: any;
  ErrorNote: any;
}

interface QuickViewModalState {
  onoff: boolean;
  loaded: boolean;

  corporate: boolean;
  booking_id: number;
  corporate_id: number;
  corporate_name: string;
  email_contact: string;
  email_contact_name: string,
  check_in: string;
  check_out: string;
  suite: string;
  editToggle: boolean;
  tenant_id: number;
  tenant_name: string;
  tenant_email: string;
  tenant_phone: string;

  invoice_id: number;
  invoice_type: string;
  invoice_notes: string;
  rent_invoice: boolean;
  marked_by: string;
  confirmed_by: string;
  start_date: string;
  issue_date: string;
  due_date: string;
  end_date: string;
  invoice_total: number;
  invoice_paid: boolean;
  payment_method: string;
  flagged: boolean;
  line_items: any[]

  toggleTransfer: boolean;
  toggleSend: boolean;
  toggleCancel: boolean;
  corporate_address: string;

  invoices: any;
  bookings: any;
  tenants: any;
  corps: any;
  billings: any;

  toggleHistory: boolean;
  toggleHistoryModal: boolean;

  historyTitle: string;
  historyContent: string;
}

class InvoiceQuickView extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      onoff: this.props.onoff,
      loaded: false,

      corporate: true,
      booking_id: -1,
      corporate_id: -1,
      corporate_name: "",
      email_contact: "",
      email_contact_name: "",
      tenant_name: "",
      check_in: "",
      check_out: "",
      suite: "",
      editToggle: false,
      tenant_id: -1,
      tenant_email: "",
      tenant_phone: "",
      marked_by: "",
      confirmed_by: "",

      invoice_id: -1,
      invoice_type: "",
      rent_invoice: false,
      issue_date: "",
      start_date: "",
      due_date: "",
      end_date: "",
      invoice_total: 0,
      invoice_paid: false,
      payment_method: "TBD",
      flagged: false,
      line_items: [],
      invoice_notes: "",

      toggleTransfer: false,
      toggleSend: false,
      toggleCancel: false,
      corporate_address: "",

      invoices: [],
      bookings: [],
      tenants: [],
      corps: [],
      billings: [],

      toggleHistory: false,
      toggleHistoryModal: false,
      historyTitle: '',
      historyContent: '',
    };
  }

  handleModal = async (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ loaded: false })
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  componentDidMount = async () => {
    store.dispatch({ type: "invoiceQuickViewUpdate", quickViewUpdate: this.fetchAll });
    this.fetchAll();
  };

  fetchAll = async () => {
    const curId = store.getState().invoiceReducer.curId;
    console.log(curId);

    await this.setState({
      invoices: null,
    })
    if (!(curId === -1)) {
      this.dataChange(curId);
    }

  }

  // Update on different list entry clicks from list of invoices
  componentDidUpdate = async (previousProps: any) => {
    const curId = store.getState().invoiceReducer.curId;
    console.log(curId)
    if (
      curId !== -1 &&
      previousProps !== this.props &&
      this.state.invoices?.length != 0
    ) {
      this.dataChange(curId);
      // console.log(this.props.info)
    } else {
      console.log("Did not fill")
    }
  };

  toggleHistory = () => {
    this.setState({toggleHistory: !this.state.toggleHistory})
  }

  toggleHistoryModal = (historyLog?:any, historyTitle?:string) => {
    console.log(historyLog)
    console.log(historyTitle)
    this.setState(
      {
        toggleHistoryModal: !this.state.toggleHistoryModal,
        historyTitle: historyTitle,
        historyContent: historyLog,
      }
    )
  }

  dataChange = async (curId: any) => {
    const potentialInvoiceData = await fetchGetJSON(`${Constants.API_PATH_INVOICE_FULLINFO}${curId}`)
    const invoiceData = potentialInvoiceData?.data?.invoice;
    const allInvoiceLines = potentialInvoiceData?.data?.invoiceLines;
    const invoiceAmount = potentialInvoiceData?.data?.invoiceAmont; //rip my spelling, I'll fix this later when I go back to BE
    const billing = potentialInvoiceData?.data?.billingContact;

    // Get this invoices tenant, booking, and billing info
    const potentialBooking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_FULLINFO}${invoiceData?.booking_id}`);
    const bookingData = potentialBooking?.data?.booking;
    // allBilling = this.state.billings;
    const curTenant = billing ? billing : potentialBooking?.data?.tenant;

    let corpData: any;

    this.setState({
      booking_id: invoiceData.booking_id,
      check_in: "",
      check_out: "",
      corporate_id: -1,
      tenant_name: "",
      tenant_id: -1,

      corporate: false,
      corporate_name: "N/A",
      corporate_address: "",
      email_contact: "",
      email_contact_name: "",
      suite: "",
      line_items: allInvoiceLines,

      invoice_id: invoiceData.id,
      marked_by: invoiceData.marked_by,
      confirmed_by: invoiceData.confirmed_by,
      invoice_type: invoiceData.invoice_types,
      rent_invoice: invoiceData.invoice_types === "Rent",
      start_date: invoiceData.start_date ? invoiceData.start_date.split('T')[0] : "",
      due_date: invoiceData.due ? invoiceData.due.split('T')[0] : "",
      end_date: invoiceData.end ? invoiceData.end.split('T')[0] : "",
      invoice_paid: invoiceData.paid,
      invoice_total: invoiceAmount,
      payment_method: invoiceData.payment_method_invoice,
      invoice_notes: invoiceData.invoice_notes,
      issue_date: invoiceData.created_at,
    });

    if (bookingData) {
      this.setState({
        // booking_id: invoiceData.booking_id,
        check_in: bookingData.check_in.split('T')[0],
        check_out: bookingData.check_out.split('T')[0],
        corporate_id: bookingData.corporate_id,
      });
    }
    if (curTenant) {
      this.setState({
        tenant_name: `${curTenant.first_name} ${curTenant.last_name}`,
        tenant_id: curTenant.id,
        tenant_email: curTenant.email,
        tenant_phone: curTenant.phone_number
      })
    }
    console.log(corpData);

    console.log(bookingData)
    if (bookingData?.unit_id) {
      let unitData: any;
      unitData = potentialBooking?.data?.unit
      this.setState({
        suite: unitData.suite_name
      })
    }

    if (bookingData?.corporate_id) {
      let corp = potentialBooking?.data?.corporate
      corpData = corp;

      this.setState({
        corporate: true,
        corporate_name: corpData.corporate_name,
        corporate_address: corpData.address
      });

      this.setState({
        email_contact: `Placeholder Email, will still send to approriate contact if clicking on "Send"`,
        email_contact_name: `Placeholder Contact Name for now`
      })
    }
    else {
      // this.setState({
      //   email_contact: curTenant.data.email,
      //   email_contact_name: `${curTenant.data.first_name} ${curTenant.data.last_name}`

      // })
      this.setState({
        email_contact: `Placeholder Email, will still send to approriate contact if clicking on "Send"`,
        email_contact_name: `Placeholder Contact Name for now`
      })
    }

    if (this.props.onoff) {
      this.setState({ loaded: true });
    }
  }

  toggleFlag = (event: any) => {
    if (this.state.flagged === false) {
      this.setState({ flagged: true });
    } else {
      this.setState({ flagged: false });
    }
  };

  togglePay = (event: any) => {
    // let toggle = this.state.invoice_paid;
    // this.setState({ invoice_paid: !toggle });
    let NotificationHandler = this.props.ErrorNote;
    NotificationHandler("Success", "Invoice is pending approval", "Please refresh the page to see changes")
  }

  handleEditBarToggle = () => {
    let NotificationHandler = this.props.ErrorNote;
    let toggle = this.state.editToggle;

    if (this.state.invoice_paid) {
      NotificationHandler("Warning", "Invoice has been paid", "You cannot edit invoices that are paid")
    }
    else if (this.state.marked_by && !this.state.confirmed_by) {
      NotificationHandler("Warning", "Invoice is currently pending approval", "You cannot edit invoices that are pending approval")
    }
    else {
      if (toggle === true) {
        this.setState({ editToggle: false });
      } else {
        this.setState({ editToggle: true });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }
    if (this.props.onoff && this.state.invoices?.length == 0) {
      // this.handleModal({target:{id:"cancel"}})
    }
    // this.display()

    let corporateIcon = <></>;

    if (this.state.corporate !== false) {
      corporateIcon = <FaUserTie className="text-4xl text-gray-500" />;
    } else {
      corporateIcon = <RiUser3Fill className="text-3xl text-gray-500" />;
    }

    // let editFocus = "opacity-100"
    let bgFocus;
    let paidStamp = <></>;
    let editFocus = "opacity-100";

    if (this.state.editToggle === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    if (this.state.invoice_paid === true) {
      paidStamp = (
        <div className="text-green-500 shadow-sm self-end border-4  rounded-sm  border-green-500 font-bold px-4 pb-1">
          <span className="flex flex-row items-center ">
            This Invoice Has Been Paid
          </span>
        </div>
      );
    } else if (this.state.marked_by && !this.state.confirmed_by) {
      paidStamp = (
        <div className="text-yellow-400 shadow-sm self-end border-4  rounded-sm  border-yellow-400 font-bold px-4 pb-1">
          <span className="flex flex-row items-center ">
            This Invoice is Pending Approval
          </span>
        </div>
      );
    }


    let dueDate;
    if (this.state.due_date == "") {
      dueDate = "-";
    } else {
      dueDate = formatStringDate(this.state.due_date);
    }

    //let day_after = addDays(this.state.end_date, 1);
    let rentLine = <></>;
    if (this.state.invoice_type === "Rent") {
      const startInvoice = this.state.start_date ? this.state.start_date : this.state.due_date;
      rentLine = <span className="text-m font-base text-gray-400 mb-2 mt-1">
        From {formatStringDate(startInvoice)} - {formatStringDate(this.state.end_date)}
      </span>
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed  flex flex-col items-center w-full h-full bg-gray-600 bg-opacity-40 z-50   "
        }
      >
        {
          this.state.toggleHistoryModal &&
          <Modal
            onoff={this.state.toggleHistory}
            title={this.state.historyTitle}
            handleModalToggle={this.toggleHistoryModal}
            content={JSON.stringify(this.state.historyContent, null, 2)}
          />
        }
        {
          this.state.editToggle &&
          <EditInvoice
            toggle={this.state.editToggle}
            handleEditBarToggle={this.handleEditBarToggle}
            invoiceData={this.state}
            handleToggleChange={this.handleToggleChange}
            handleDateChange={this.handleDateChange}
            handleInputChange={this.handleInputChange}
          />
        }

        <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("toggleCancel", this.state.toggleCancel)
          }
          onoff={this.state.toggleCancel}
          title={"Cancel Invoice"}
        // invoiceOff={this.handleModal}
        />
        {
          this.state.toggleSend &&
          <SendInvoice
            handleModalToggle={() =>
              this.handleToggleChange("toggleSend", this.state.toggleSend)
            }
            onoff={this.state.toggleSend}
            title={"Send Invoice"}
            invoice_id={this.state.invoice_id}
            tenant_id={this.state.tenant_id}
            email={this.state.email_contact}
            name={this.state.email_contact_name}
          />
        }

        {bgFocus}

        <div className="appear bg-white sm:my-10 w-full overflow-y-auto shadow-md sm:rounded-lg max-w-3xl  scroll-hide ">
          {!this.state.loaded ? <LoadingInvoiceQuickView /> : 
            <>
            <div className="w-full bg-gray-100 pt-4 pb-2  px-4 sm:px-8 rounded-t-lg ">
              {this.state.booking_id != -1 && <div key={1} className="flex flex-col sm:flex-row my-1  justify-between sm:items-center">
                <IoIosArrowBack
                  id="cancel"
                  onClick={this.handleModal}
                  className=" lg:hidden  text-2xl transform -translate-x-2 -translate-y-1 mb-3 hover:text-gray-300"
                />
                <span className="text-xl font-bold text-gray-800">
                  <a href={`/user/bookings/bookingprofile?id=${this.state.booking_id}`} target="_blank">
                    Booking ID #{this.state.booking_id}
                  </a>
                </span>
                <span className=" cursor-pointer h-8 mr-2 bg-gray-700 text-white font-medium text-xs rounded-full  w-auto hidden sm:flex flex-row items-center text-center px-5">
                  {this.state.suite}
                </span>
              </div>}

              <div className="flex flex-col mt-2 sm:mt-0 justify-start sm:flex-row my-1 sm:justify-between sm:items-end pt-1">
                <div className="flex flex-col text-xs font-medium text-gray-600">
                  {this.state.booking_id != -1 ? <>
                    <span className=" pb-1">
                      Corporate: {this.state.corporate_name}
                    </span>
                    <span className=" pb-2">
                      Tenant Name: {this.state.tenant_name}
                    </span>
                    <div className="flex flex-row">
                      <span className="opacity-70 text-2xs pb-1 mr-4">
                        Check In: {formatStringDate(this.state.check_in)}
                      </span>
                      <span className="opacity-70 text-2xs ">
                        Check Out: {formatStringDate(this.state.check_out)}
                      </span>
                    </div>
                  </> :
                    (true ? <>
                      <span className="text-xl font-bold text-gray-800">
                        Name: {this.state.tenant_name}
                      </span>
                      <span className="">
                        Email: {this.state.tenant_email}
                      </span>
                      <span className=" pb-2">
                        Phone Number: {this.state.tenant_phone}
                      </span>
                      <span className="font-bold text-gray-800">
                        Independent Invoice
                      </span>
                    </> : ""
                    )}
                </div>
                {
                  this.handleEditBarToggle &&
                  <EditOptionPanel
                    handleEditBarToggle={this.handleEditBarToggle}
                    parentData={this.state}
                    panelType="Invoice"
                    handleFlag={this.toggleFlag}
                    handleState={this.handleToggleChange}
                    handlePay={this.togglePay}
                  />
                }
              </div>
              <div>
                  <div className={" block w-1/2"}>
                    <div className="flex flex-row items-center">
                      <span
                        className={
                          " text-gray-400  transition-all duration-600 text-sm mr-3"
                        }
                      >
                        View Invoice history
                      </span>
                      <ToggleSwitch
                          id="credit_fee"
                          handleStateToggle={() => this.toggleHistory()}
                          className="justify-center align-center"
                          size="lg"
                          onoff={this.state.toggleHistory}
                        />
                    </div>
                  </div>
              </div>
            </div>
            {
              this.state.toggleHistory ? 
              <>
                <InvoiceHistory
                  historyModalToggle={this.toggleHistoryModal}
                  invoiceId={this.state.invoice_id}
                />
              </> :
              (
                <div className="w-full  pt-6 px-4 sm:px-8 pb-10">
                  <div className="w-full flex flex-row font-sofia justify-between">
                    <div className=" flex flex-col ">
                      <span className="text-4xl font-extrabold text-gray-600 ">
                        {" "}
                        <span className="text-gray-300">Invoice # </span>
                        {this.state.invoice_id}
                      </span>
                      <span className="text-lg font-base text-gray-400 uppercase mb-2 mt-6">
                        {this.state.invoice_type} Invoice{" "}
                        {this.state.invoice_notes && this.state.invoice_notes.includes("Extension Invoice") ? <span className="text-green-500 ml-2 p-1">Extension</span> : <></>}
                      </span>
                      {rentLine}
    
                      <div className="flex flex-row">
                        <div className="flex flex-col mr-6">
                          <span className="flex flex-col mr-5 text-sm text-gray-400">
                            Invoice Date
                          </span>
                          <span className=" mr-5 font-bold text-md text-gray-700">
                            {this.state.start_date ? formatStringDate(this.state.due_date) : formatStringDate(this.state.issue_date)}
                          </span>
                          <span className="flex flex-col mr-5 text-sm text-gray-400">
                            Due Upon Receipt
                          </span>
                        </div>
                      </div>
    
                      <div className="flex flex-col mt-8">
                        <span className="mr-5 text-sm text-gray-400">
                          Invoice For
                        </span>
                        <span className=" text-lg font-bold text-gray-600 mt-1">
                          {this.state.tenant_name}
    
                        </span>
                        <span className=" text-medium text-gray-600 mt-1">
                          {this.state.corporate_address}
                        </span>
    
                        <span className=" text-xs text-gray-500 mt-1">
                          {/* HST No.: {GetAppStage().hstNumber} */}
                          HST No.: {process.env.REACT_APP_HST_NO}
                        </span>
                      </div>
                    </div>
    
                    <div className=" hidden sm:flex flex-col h-auto justify-between ">
                      {/* <img className="w-24" src=REACT_APP_SVSRELO_COMPANY_LOGO /> */}
                      <span className="text-lg font-regular text-gray-400 uppercase self-end ">
                        {/* {GetAppStage().name1}{" "} */}
                        {process.env.REACT_APP_COMPANY_NAME}
                      </span>
    
                      {paidStamp}
                    </div>
                  </div>
    
                  <table
                    style={{ borderSpacing: "0" }}
                    className="border-separate border rounded-sm mt-6 relative w-full table-auto bg-white     "
                  >
                    <thead className="border-b border-gray-400 ">
                      <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                        <th className="sticky top-0  py-3 pl-5  font-thin border-b border-gray-400  ">
                          Item
                        </th>
    
                        <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                          Payment Method
                        </th>
                        <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 text-right px-4">
                          Price
                        </th>
                      </tr>
                    </thead>
    
                    <tbody className="divide-y">
                      <PaymentsToApprove length={this.state.line_items.length} items={this.state.line_items} payment_method={this.state.payment_method} />
                    </tbody>
                  </table>
    
                  <div className="w-full flex flex-row justify-between py-5 ">
                    <div className="flex flex-col text-sm">
                      <span className="text-base mb-1">Payment Types Accepted: </span>
                      <span className=" text-gray-500">
                        Credit Card, Debit, Certified Cheque, Wire
                      </span>
                      <span className="text-xs text-gray-400">
                        All Amounts In CAD
                      </span>
                    </div>
                    <span className="text-right text-2xl font-bold text-gray-600">
                      <span className="font-thin text-lg">Total:</span>{" "}
                      {formatAmountForDisplay(this.state.invoice_total, "CAD", false)}{" "}
                    </span>
                  </div>
                  <div className="flex flex-col text-xs text-gray-400 justify-center items-start mt-5">
                    {/* <span className="text-gray-700">{GetAppStage().name1}</span> */}
                    <span className="text-gray-700">{process.env.REACT_APP_COMPANY_NAME}</span>
                    <span>{process.env.REACT_APP_COMPANY_ADDRESS}</span>
                    <span>{process.env.REACT_APP_ADDITIONAL_ADDRESS}</span>
                    {/* <span className="text-green-500">{GetAppStage().email1}</span> */}
                    <span className="text-green-500">{process.env.REACT_APP_INFO_EMAIL}</span>
                  </div>
                </div>
              )
            }
          </>}
        </div>
      </div>
    );
  }
}

export default withHooksHOC(InvoiceQuickView);

export function PaymentsToApprove(props: any) {
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;

  for (var i = 0; i < length; i++) {
    let lineItem = props.items[i]
    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    //when linking to back end: remember to switch the key to the unique invoice id from the backend
    Payments = (
      <tr
        key={i.toString()}
        className={altBg + " w-full   h-12 border-b border-gray-400  "}
      >
        <td className="py-4 pl-5 text-gray-700 font-thin text-sm border-b">
          {lineItem.entry.split('>')[0]}
        </td>
        <td className="py-3 font-thin text-sm  border-b">{props.payment_method}</td>
        <td className="py-3 text-green-500 font-thin text-right px-4 border-b">
          {formatAmountForDisplay(lineItem.cost, "CAD", false)}{" "}
        </td>
      </tr>

    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}