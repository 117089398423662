export type State = {
    parkingSpots: any[];
    curId: number;
    listUpdate: any;
    profileUpdate: any;
};
const initialState: State = {
    parkingSpots: [],
    curId: -1,
    listUpdate: undefined,
    profileUpdate: undefined,
};

type Action = 
 | {type: "parkingSpotIdBlackoutTransfer"; id: number}
 | {type: "parkingSpotBlackoutListUpdate"; listUpdate: any}
 | {type: "parkingSpotBlackoutProfileUpdate"; profileUpdate: any}

export const parkingSpotBlackoutReducer = (state = initialState, action:Action): State => {
    switch (action.type) {
        case "parkingSpotBlackoutProfileUpdate":
            state.profileUpdate = action.profileUpdate
            return state;
        case "parkingSpotIdBlackoutTransfer":
            state.curId = action.id;
            return state;
        case "parkingSpotBlackoutListUpdate":
            console.log("Updating Parking Spot List Update: ")
            console.log(action)
            state.listUpdate = action.listUpdate;
            return state;
        default:
            return state;
    }
}