import React, {useState, useEffect} from "react";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

type Props = {
    bookingId: number;
}

function CleaningsProfileCard(props: Props) {
    const [cleanings, setCleanings] = useState([]);
    const [bookingID, setBookingID] = useState(-1);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        //Grab booking ID from props
        setBookingID(props.bookingId);

        const cleanings = await fetchGetJSON(`${Constants.API_PATH_CLEANING_BY_BOOKING_ID}${props.bookingId}`)
        console.log(cleanings.data)
        if (cleanings.status === 'Success') {
            setCleanings(cleanings.data);
        }
    }

    const generateTable = () => {
        console.log(cleanings)
        return (
            <tbody>
                {
                    cleanings.map((cleaning) => {
                        return(
                        <tr className=" border-t-2 w-full">
                            <td className=" font-thin text-xs px-1 py-4">
                                {cleaning.id}
                            </td>
                            <td className="text-green-500">
                                {cleaning.title}
                            </td>
                            <td className="text-indigo-600">
                                {cleaning.cleaning_type}
                            </td>
                            <td className="text-indigo-600">
                                {cleaning?.cleaning_date}
                            </td>
                            <td className="text-indigo-600">
                                {cleaning.confirmed ? <FaCheck className="text-green-400 text-xl"/> : <MdCancel  className="text-red-400 text-xl"/>}
                            </td>
                            <td>
                                {cleaning.cleaners?.length ? <FaCheck className="text-green-400 text-xl"/> : <MdCancel  className="text-red-400 text-xl"/>}
                            </td>
                        </tr>
                        )
                    })
                }
            </tbody>
        )
    }

    return (
        <div className=" slide-in-blurred-top  font-display border px-8 py-4">
        <h1 className="text-xl font-bold">Cleanings for Booking #{props.bookingId}</h1>
        <table className="table-auto w-full mb-8">
            <thead>
                <tr className="text-left text-gray-500 ">
                    <th className="py-2 font-thin ">ID</th>
                    <th className="py-2 font-thin ">Cleaning Title</th>
                    <th className="py-2 font-thin ">Cleaning Type</th>
                    <th className="py-2 font-thin ">Cleaning Date</th>
                    <th className="py-2 font-thin ">Confirmation Status</th>
                    <th className="py-2 font-thin ">Cleaner Associated</th>
                </tr>
            </thead>
            {generateTable()}
            </table>
        </div>
    )
}

export default CleaningsProfileCard;
