export type State = {
    curId: number;
    blackoutListUpdate: any;
    blackoutProfileUpdate: any; 
  };
  
  const initialState: State = {
    curId: -1,
    blackoutListUpdate: undefined,
    blackoutProfileUpdate: undefined
  };
  
  type Action =
    | { type: "blackoutListUpdate"; listUpdate: any }
    | {type: "blackoutProfileUpdate"; profileUpdate: any}; 
  
  export const blackoutReducer = (
    state = initialState,
    action: Action
  ): State => {
    switch (action.type) {
      case "blackoutListUpdate":
        state.blackoutListUpdate = action.listUpdate;
        return state;
      case "blackoutProfileUpdate":
        state.blackoutProfileUpdate = action.profileUpdate; 
        return state; 
      default:
        return state;
    }
  };
  
  export default blackoutReducer;
  