import { Component, useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { formatFullDate, inputISO } from "../../../utils/DateFormat";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import NewCleaning from "./NewCleaning";
import ConfirmCleaningView from "./ConfirmCleaningView";
import { fetchRequestJSON, fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import DashBar from "../DashBar";
import store from "../../../redux/store";
import CleaningQuickView from "./CleaningQuickView";
import { getUserList, localRoute, } from "../../../api-requests/api-gateway";
import { useNotification } from "../../Notifications/NotificationProvider";
import dayjs from "../../../utils/Dayjs";
import axios from "axios";
import { DatesSetArg } from "@fullcalendar/core";

type Props = {
  userData: any;
  //ErrorNote: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;

  newCleaning: boolean;
  chosenDate: string;

  openView: boolean;
  openQuickView: boolean;

  cleanings: Cleaning[];
  allCleanersData: Cleaners[];
};

type Cleaning = {
  id: string;
  title: string;
  date: string;
  unit_id: number;
}

type Cleaners = {
  id: number;
  calendar_colour: string;
  name: string;
}

type UpdateType = {
  event: string;
  message: string;
}

const CleaningCalendar = ({userData}: Props) => {

  const [openQuickView, setOpenQuickView] = useState(false);
  const [newCleaning, setNewCleaning] = useState(false); 
  const [chosenDate, setChosenDate] = useState('');
  const [calendarStartDate, setCalendarStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [calendarEndDate, setCalendarEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
  const [openConfirmCleaningView, setOpenConfirmCleaningView] = useState(false);
  const [cleanings, setCleanings] = useState([]);
  const [cleanersLegendData, setCleanersLegendData] = useState([]);

  useEffect(() => {
    console.log("INSIDE CLEANING CALENDAR")
    // TODO: Add Redux, whenever an edit is made in cleanings, the cleaning calendar route needs to be called again
    // This is the hook for that
    updateCalendar();
  }, []);

  useEffect(() => {
    const updateType: UpdateType = {
      event: "DateSet",
      message: ""
    }
    updateCalendar(updateType);
  }, [calendarEndDate])

  const dispatch = useNotification();

  function HandleError(type: string, title: string, message: string) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  }

  const handleOpenQuickView = (event?: any) => {
    console.log(event)
    if (event) {
      console.log(event.event._def.extendedProps.data.id)
      store.dispatch({ type: "setCurCleaning", id: event.event._def.extendedProps.data.id })
    }
    const curId = store.getState().cleaningReducer.curCleaning;
    console.log("curId: " + curId)
    setOpenQuickView(!openQuickView);
  }

  const handleDateClick = (arg: any) => {
    setChosenDate(arg.dateStr);
    handleModal();
    // alert(this.state.chosenDate)
  };

  const handleDateRangeChange = async (arg: DatesSetArg) => {
    setCalendarStartDate(arg.startStr);
    setCalendarEndDate(arg.endStr);
  }

  const handleModal = () => {
    setNewCleaning(!newCleaning);
  };

  const handleDrop = async (event: any) => {
    let body = {
      id: event.event._def.publicId,
      fields: {
        unit_id: event.event._def.extendedProps.unit_id,
        cleaning_date: event.event._instance.range.start.toISOString().split("T")[0],
      }
    }
    
    let res = await fetchRequestJSON("PUT", Constants.API_PATH_CLEANING_MOVE, body);
    console.log(res)
  }

  const handleConfirmCleaningView = () => {
    setOpenConfirmCleaningView(!openConfirmCleaningView);
  }

  const updateCalendar = async (updateType?: UpdateType) => {
    let cleaningCalendarData = await fetchGetJSON(`${Constants.API_PATH_CLEANING_CALENDAR}?start_date=${calendarStartDate}&end_date=${calendarEndDate}`)
    
    // console.log(cleaningCalendarData);

    if (cleaningCalendarData?.status === "Success") {
      setCleanersLegendData(cleaningCalendarData.data.cleaners);

      console.log(cleanersLegendData)

      setCleanings(cleaningCalendarData.data.cleanings?.map((cleaning: any) => {
        let colour = "";
        if (cleaning.cleaners.length > 1) {
          colour = "#35b82c";
        }
        else if (cleaning.cleaners.length === 1) {
          colour = "#" + cleaning.cleaners[0].calendar_colour;
        }

        return {
          id: cleaning.id ?? "",
          title: cleaning.title || "No Title Given",
          date: cleaning.cleaning_date.split('T')[0],
          unit_id: cleaning.unit_id,
          data: cleaning,
          color: colour,
          cleaners: cleaning.cleaners ?? []
        }
      }));

      if (updateType?.event === "Creation") {
        HandleError("Success", `Successfully Created Cleaning ${updateType?.message}`, "")
      }
      else if (updateType?.event === "Deletion") {
        HandleError("Success", `Successfully Deleted Cleaning ${updateType?.message}`, "")
      }
      else if (updateType?.event === "Error") {
        HandleError("Error", `${updateType?.message}`, "")
      }
      else if (updateType?.event !== "DateSet") {
        HandleError("Success", "Successfully Updated Calendar", "")
      }
    }
  }

  return (
    <div className=" font-display bg-f9 h-auto lg:rounded-tl-lg py-3 lg:pt-0 ">
      {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}
      
      { 
        newCleaning && <NewCleaning
        chosenDate={ chosenDate }
        handleModalToggle={ handleModal }
        onoff={newCleaning}
        update={ updateCalendar }
        />
      }
      {
        openConfirmCleaningView && <ConfirmCleaningView
          onoff={ openConfirmCleaningView }
          handleModalToggle={ handleConfirmCleaningView }
        />
      }
      {
        <CleaningQuickView
          cleanings={ cleanings }
          onoff={ openQuickView }
          handleModalToggle={ handleOpenQuickView }
          update={ updateCalendar }
        />
      }
      
      {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
      <div className="fade-in px-4 lg:px-14  ">
        <div className=" flex flex-row justify-between font-circular pl-1 w-full pt-16 lg:pt-6 pb-4">
          <DashBar placeholder={""} />

          <div className="lg:flex flex-col hidden ">
            <span className="text-gray-900 font-medium">
              Cleaning Dashboard
            </span>
            <span className=" text-right text-sm text-gray-400 font-sofia">
              { formatFullDate(dayjs().toDate()) }
            </span>
          </div>
        </div>

        <h2 className="mb-8">Legend</h2>
        <div className="flex flex-row w-auto overflow-x-auto space-x-3 mb-8">
          {
            cleanersLegendData.map((cleaner) => {
              return (
                <span className="flex flex-row flex-start w-44 h-6 rounded" style={{ backgroundColor: `#${cleaner.calendar_colour}` }} key={cleaner.id}>
                  <span className="text-white pl-3">
                    { cleaner.first_name[0] + ". " + cleaner.last_name }
                  </span>
                </span>
              )
            })
          }
        </div>

        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          customButtons={{
            confirm: {
              text: 'confirm',
              click: () => { setOpenConfirmCleaningView(true) }
            }
          }}
          selectable={true}
          dateClick={ handleDateClick }
          events={ cleanings }
          headerToolbar={{
            left: "title",
            center: "",
            right: "confirm today dayGridMonth,dayGridWeek prev,next",
          }}
          editable={true}
          eventStartEditable={true}
          eventDrop={ handleDrop }
          eventClick={ handleOpenQuickView }
          datesSet={ handleDateRangeChange }
        />

        {/* Heading Info Area  */}
      </div>
    </div>
  );
}

export default CleaningCalendar

function CleanDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}

function CleaningModal(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div
      id="outsideDiv"
      onClick={props.handleModal}
      className={
        "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
      }
    >
      <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
        <span className="text-xl font-bold text-gray-800 text-left">
          {props.state.title}
        </span>
        <span className="text-xs text-center text-gray-400"></span>
        <form>
          <div className="grid grid-cols-3 gap-6 w-full my-5">
            <label className="block  ">
              <span className="text-gray-400 text-xs">Date of Transfer:</span>
              <input
                id="transfer_date"
                onChange={props.handleDateChange}
                value={inputISO(props.state.transfer_date)}
                type="date"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              />
            </label>

            <label className="block ">
              <span className="text-gray-400 text-xs">Building:</span>
              <input
                id="building"
                onChange={props.handleInputChange}
                value={props.state.building}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder="eg. Opus"
              />
              <span className="text-2xs pt-1 text-gray-300 ">
                Auto Searches Building
              </span>
            </label>

            <label className={" block  "}>
              <span className="text-gray-400 text-xs">Unit</span>
              <input
                id="unit"
                onChange={props.handleInputChange}
                value={props.state.unit}
                type="text"
                className={
                  "mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                }
                placeholder=""
              />
              <span className="text-2xs pt-1 text-gray-300 ">
                Auto Searches Unit
              </span>
            </label>
          </div>
          <div className="flex flex-row justify-start w-full mt-6">
            <button
              onClick={props.props.handleModalToggle}
              className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}