import { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import { getCurrentUser } from "aws-amplify/auth";
import { RiUser3Fill } from "react-icons/ri";
import { formatFullDate, formatStringDate } from "../utils/DateFormat";
import { formatAmountForDisplay } from "../utils/DollarFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
  regGet,
  regRequest,
} from "../api-requests/apiCallHelper";
import { Constants } from "../api-requests/apiLinkConstants";
import store from "../redux/store";
import { InvoiceLineFormatting } from "../components/Dashboards/Finance/ConsolidatedQuickView";

// Takes Booking ID Prop and pull all info

interface Props {}

interface InvoiceLine {
  id: number;
  created_at: string;
  updated_at: string;
  entry: string;
  cost: number;
  invoice_id: number;
  line_type: string;
  start_date: string;
  end_date: string;
}

type ConsolidatedIndividualInvoice = {
  invoiceLines: InvoiceLine[];
  invoiceFor?: string;
  invoiceID: number;
  paid: boolean;
  amount: number;
  createdAt?: string;
  due?: string;
};

type ConsolidatedData = {
  due: string;
  paid: boolean;
  date_paid: string;
  end: string;
  amount: number;
  payment_method_invoice: string;
  id: number;
  created_at?: string;
};

interface QuickViewModalState {
  corporate: boolean;

  consolidatedData: ConsolidatedData;

  invoices: ConsolidatedIndividualInvoice[];

  toggleTransfer: boolean;
  toggleRate: boolean;
  toggleCancel: boolean;

  invoiceFor: string;
  amount: number;
  paymentLink: string;
  actuallyPaid: boolean;
  verified: boolean;
  dueDate: string; 
}

class ConsolidatedClientInvoice extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      corporate: false,
      consolidatedData: {
        due: "",
        paid: false,
        date_paid: "",
        end: "",
        amount: 0,
        payment_method_invoice: "",
        id: -1,
        created_at: "",
      },
      invoices: [],

      toggleTransfer: false,
      toggleRate: false,
      toggleCancel: false,
      invoiceFor: "",
      amount: 0,
      paymentLink: "",
      actuallyPaid: false,
      verified: false,
      dueDate: ""
    };
  }

  verifiedUser = async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  };

  loggedInGetData = async () => {
    let corpData: any;

    // Public access with signature verification
    if (new URLSearchParams(window.location.search).get("signature")) {
      const paymentData = await regRequest(
        "POST",
        `${Constants.API_PATH_CONSOLIDATED_PAYMENT_DATA}${new URLSearchParams(
          window.location.search
        ).get("id")}?signature=${new URLSearchParams(
          window.location.search
        ).get("signature")}`
      );
      console.log("payment data");
      console.log(paymentData);

      let amount = 0;
      const consolAmount = await regGet(
        `${Constants.API_PATH_CONSOLIDATED_AMOUNT}${new URLSearchParams(
          window.location.search
        ).get("id")}`
      );

      let dueDate = ""; 
      const consolDue = await regGet(
        `${Constants.API_PATH_CONSOLIDATED_DUE}${new URLSearchParams(
          window.location.search
        ).get("id")}`
      );
      console.log(consolDue); 
      if(consolDue.data) {
        dueDate = consolDue.data;
      }

      if (consolAmount.data) {
        amount = consolAmount.data;
      }

      if (
        paymentData.status ===
          "Error, this consolidated invoice does not exist." ||
        paymentData.statusCode
      ) {
        console.log("Error getting payment data");
        return;
      } else {
        this.setState({
          paymentLink: paymentData.data.paymentLink,
          invoiceFor: paymentData.data.invoiceFor,
          consolidatedData: paymentData.data.consolidatedInvoice,
          amount: amount,
          invoices: paymentData.data.invoices,
          actuallyPaid: paymentData.data.paid,
          verified: true,
          dueDate: dueDate
        });
      }
    }
    // Private access
    else {
      // So API routes don't get called if url typed in manually while logged out
      if (!(await this.verifiedUser())) {
        return;
      }
      this.setState({
        verified: true,
      });
      //get required data
    }

    if (corpData) {
      this.setState({
        corporate: true,
      });
    }
  };

  componentDidMount = async () => {
    this.loggedInGetData();
    console.log(
      "TRNID: " + new URLSearchParams(window.location.search).get("trnId")
    );
    if (new URLSearchParams(window.location.search).get("trnId")) {
      this.redirectedGetData();
    }
  };

  // Fill out info upon redirect from Bambora transaction completion
  redirectedGetData = async () => {
    let consolidatedID = Number(
      new URLSearchParams(window.location.search).get("trnOrderNumber")
    );

    //confirm consolidated payment

    const paidConsolidated = await fetchRequestJSON(
      "POST",
      `${Constants.API_PATH_CONSOLIDATED_PAID_ONLINE}${consolidatedID}`
    );

    this.setState({
      consolidatedData: {
        date_paid: new Date().toISOString(),
        paid: true,
        ...this.state.consolidatedData,
      },
      actuallyPaid: true,
      verified: true,
    });

    const paymentData = await fetchGetJSON(
      `${Constants.API_PATH_CONSOLIDATED_DATA}${consolidatedID}`
    );

    console.log("payment data");
    console.log(paymentData.data);

    let amount = 0;
      const consolAmount = await fetchGetJSON(
        `${Constants.API_PATH_CONSOLIDATED_AMOUNT}${new URLSearchParams(
          window.location.search
        ).get("id")}`
      );

      let dueDate = ""; 
      const consolDue = await fetchGetJSON(
        `${Constants.API_PATH_CONSOLIDATED_DUE}${new URLSearchParams(
          window.location.search
        ).get("id")}`
      );
      console.log(consolDue); 
      if(consolDue.data) {
        dueDate = consolDue.data;
      }

      if (consolAmount.data) {
        amount = consolAmount.data;
      }

    if (
      paymentData.status ===
        "Error, this consolidated invoice does not exist." ||
      paymentData.statusCode
    ) {
      console.log("Error getting payment data");
      return;
    } else {
      this.setState({
        invoiceFor: paymentData.data.invoiceFor,
        consolidatedData: paymentData.data.consolidatedInvoice,
        invoices: paymentData.data.invoices,
        verified: true,
        dueDate: dueDate, 
        amount: amount
      });
    }
  };

  handleEditBarToggle = () => {};

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  handleClick = async () => {
    let bamboraUrl: string;
    bamboraUrl = this.state.paymentLink;
    window.location.href = bamboraUrl;
  };

  render() {
    let corporateIcon = <></>;

    let corporateData = <></>;

    if (this.state.corporate !== false) {
      corporateData = (
        <div className="flex flex-col mt-8">
          <span className="mr-5 text-sm text-gray-400">Corporate Data</span>
          <span className=" text-lg font-bold text-gray-600 mt-1">
            Corporate Name
          </span>

          <span className=" text-xs text-gray-300 mt-1">Corporate Address</span>
        </div>
      );
      corporateIcon = <FaUserTie className="text-4xl text-gray-500" />;
    } else {
      corporateIcon = <RiUser3Fill className="text-3xl text-gray-500" />;
    }

    // let editFocus = "opacity-100"
    let bgFocus;
    let paidStamp = <></>;
    let editFocus = "opacity-100";

    let payButton = (
      <button
        onClick={() => this.handleClick()}
        className={
          "no-print mt-12 align-center px-8 py-2 bg-green-500 hover:bg-green-400 text-white"
        }
        disabled={this.state.actuallyPaid}
      >
        Pay
      </button>
    );
    if (this.state.actuallyPaid === true) {
      payButton = <></>;
      paidStamp = (
        <div className="text-green-500 shadow-sm self-end border-4  rounded-sm  border-green-500 font-bold px-4 pb-1">
          <span className="flex flex-row items-center ">
            This Consolidated Invoice Has Been Paid
          </span>
        </div>
      );
    }

    let topLineInfo = (
      <span className="text-4xl font-extrabold text-gray-600 ">
        {" "}
        <span className="text-gray-300">Consolidated ID #</span>
        {this.state.consolidatedData.id}
      </span>
    );

    if (this.state.verified === false) {
      payButton = <></>;
      topLineInfo = (
        <span className="text-4xl font-extrabold text-gray-600 ">
          {" "}
          <span className="text-gray-300">
            Permissions restricted for this invoice
          </span>
        </span>
      );
    }

    let dateDisplay = (
      <div className="flex flex-row">
        <div className="flex flex-col mr-6">
          <span className="flex flex-col mr-5 text-sm text-gray-400">
            Created on
          </span>
          <span className=" mr-5 font-bold text-md text-gray-700">
            {formatStringDate(this.state.consolidatedData.created_at)}
          </span>
        </div>

        <div className="flex flex-col">
          <span className=" mr-5 text-sm text-gray-400">Due Date</span>
          <span className=" mr-5 font-bold text-md text-gray-700">
            {formatStringDate(this.state.dueDate)}
          </span>
        </div>
      </div>
    );
    if (this.state.actuallyPaid) {
      dateDisplay = (
        <div className="flex flex-row">
          <div className="flex flex-col">
            <span className=" mr-5 text-sm text-gray-400">Paid Date</span>
            <span className=" mr-5 font-bold text-md text-gray-700">
              {formatStringDate(this.state.consolidatedData.date_paid)}
            </span>
          </div>
        </div>
      );
    }

    let backgrounds = [];
    for (let i = 0; i < 12; i++) {
      backgrounds[i] = "/img/backgrounds/back-" + (i + 1) + ".jpg";
    }

    return (
      <div
        id="outsideDiv"
        className={
          "fade-in fixed  grid grid-cols-1  w-full h-full bg-gray-900  z-50   "
        }
      >
        <img
          className=" fade-in object-cover bg-gray-100 absolute inset-0  w-full h-full"
          src={backgrounds[0]}
        />

        <div className="fade-in font-display  bg-white md:my-10  mx-auto w-full  overflow-y-auto shadow-lg md:rounded-lg h-screen md:h-auto  max-w-3xl  scroll-hide ">
          <div className="w-full pt-3 pb-10 px-5 sm:pt-6 sm:px-8">
            <div className="w-full flex flex-row font-sofia justify-between pb-5">
              <div className=" flex flex-col ">
                {topLineInfo}

                {dateDisplay}

                <div className="flex flex-col mt-8">
                  <span className="mr-5 text-sm text-gray-400">
                    Invoice For
                  </span>
                  <span className=" text-lg font-bold text-gray-600 mt-1">
                    {this.state.invoiceFor}
                  </span>

                  <span className=" text-xs text-gray-300 mt-1">
                    HST No.: {process.env.REACT_APP_HST_NO}
                  </span>
                </div>

                {corporateData}
              </div>

              <div className=" hidden sm:flex flex-col h-auto justify-between ">
                {/* <img className="w-24" src={process.env.REACT_APP_COMPNAY_LOGO} /> */}
                <span className="text-lg font-regular text-gray-400 uppercase self-end ">
                  {process.env.REACT_APP_COMPANY_NAME}
                </span>

                {paidStamp}
              </div>
            </div>

            {generateInvoices(this.state.invoices)}

            <div className="w-full flex flex-row justify-between py-5 ">
              <div className="flex flex-col text-sm">
                <span className="text-base mb-1">Payment Types Accepted: </span>
                <span className=" text-gray-500">
                  Credit Card (subject to 3% fee), Debit, Certified Cheque, Wire
                </span>
                <span className="text-xs text-gray-400">
                  All Amounts In CAD
                </span>
              </div>
              <span className="text-right text-2xl font-bold text-gray-600">
                <span className="font-thin text-lg">Total:</span>{" "}
                {formatAmountForDisplay(this.state.amount, "CAD", false)}{" "}
              </span>
            </div>
            <div className="flex flex-col text-xs text-gray-400 justify-center items-start mt-5">
              {/* <span className="text-gray-700">{GetAppStage().name1}</span> */}
              <span className="text-gray-700">{process.env.REACT_APP_COMPANY_NAME}</span>
              <span>{process.env.REACT_APP_COMPANY_ADDRESS}</span>
              <span>{process.env.REACT_APP_ADDITIONAL_ADDRESS}</span>
              {/* <span className="text-green-500">{GetAppStage().email1}</span> */}
              <span className="text-green-500">{process.env.REACT_APP_INFO_EMAIL}</span>
              {/* {process.env.REACT_APP_SVSRELO_COMPANY_URL} */}
            </div>
            <div className="w-full grid grid-cols-2 gap-5 px-6">
              {payButton}
              <button
                onClick={() => window.print()}
                className=" no-print mt-12 align-center px-8 py-2 bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-700"
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsolidatedClientInvoice;

function generateInvoices(
  consolInvoices: ConsolidatedIndividualInvoice[]
): JSX.Element {
  let invoices = [];
  for (let i = 0; i < consolInvoices.length; i++) {
    let thisItem = consolInvoices[i];
    let invoiceLines = InvoiceLineFormatting(thisItem.invoiceLines);
    let paidStamp = <></>;
    if (thisItem.paid) {
      paidStamp = (
        <div className="text-green-500 shadow-sm self-end border-4  rounded-sm  border-green-500 font-bold px-4 pb-1 top-0 right-0 ml-auto">
          <span className="flex flex-row items-center ">
            This Invoice Has Been Paid
          </span>
        </div>
      );
    }

    let thisInvoice = (
      <div id="invoice" className="border-4 px-4 pb-4 mb-5">
        <div className="w-full flex flex-row font-sofia justify-between">
          <div className=" flex flex-row justify-between w-full">
            <div className="flex flex-col">
              <span className="text-lg font-base text-gray-400 uppercase mb-2 mt-6">
                Invoice # {thisItem.invoiceID}
              </span>

              <div className="flex flex-row">
                <div className="flex flex-col ml-5">
                  <span className=" text-xs text-gray-300 mt-1">
                    HST No.: {process.env.REACT_APP_HST_NO}
                  </span>
                </div>
              </div>
            </div>
            {paidStamp}
          </div>
        </div>

        <table
          style={{ borderSpacing: "0" }}
          className="border-separate border rounded-sm mt-6 relative w-full table-auto bg-white     "
        >
          <thead className="border-b border-gray-400 ">
            <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
              <th className="sticky top-0  py-3 pl-5  font-thin border-b border-gray-400  ">
                Item
              </th>

              <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                Payment Method
              </th>
              <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 text-right px-4">
                Price
              </th>
            </tr>
          </thead>

          <tbody className="divide-y">
            <>{invoiceLines}</>
          </tbody>
        </table>
      </div>
    );
    invoices.push(thisInvoice);
  }

  return <div>{invoices}</div>;
}
