import { Component } from "react";
import { AiOutlinePaperClip, AiOutlineFileText } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";

import { IoIosArrowBack } from "react-icons/io";
import { BsFillPersonPlusFill } from "react-icons/bs";
import ToggleSwitch from "../ToggleSwitch";
import { TiDelete } from "react-icons/ti";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
// import { truncate } from "fs";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
};

type ModalState = {
  title: string;
  onoff: boolean;
  corporation: string;
  address: string;
  admin_contact: string;
  admin_contact_email: string;
  admin_contact_phone: string;
  finance_contact: string;
  finance_contact_email: string;
  finance_contact_phone: string;
  cleaning_contact: string;
  cleaning_contact_email: string;
  cleaning_contact_phone: string;
  client_manager: string;
  payment_type: string;
  flagged: boolean;
  fin_com_pol: string;
  clean_com_pol: string;
  admin_com_pol: string;
  var_com_pol: boolean;
  invoice_method: string;
  ntv: number;
  payment_terms: string;
  first_last: string;

  charge_deposit: boolean;
  charge_cleaning: boolean;
  charge_credit_fee: boolean;

  //* Documents
  files: Array<File>;
  file_names: Array<string>;
  web_URL: string;
  final_notes: string;

  corporate_notes: string;
  expiry: number;
};

class NewCorporate extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      corporation: "",
      address: "",
      admin_contact: "",
      admin_contact_email: "",
      admin_contact_phone: "",
      finance_contact: "",
      finance_contact_email: "",
      finance_contact_phone: "",
      cleaning_contact: "",
      cleaning_contact_email: "",
      cleaning_contact_phone: "",
      client_manager: "",
      payment_type: "Credit",
      flagged: false,
      fin_com_pol: "None",
      clean_com_pol: "None",
      admin_com_pol: "None",
      var_com_pol: false,
      invoice_method: "Consolidated",
      ntv: 0,
      payment_terms: "Net 30",
      first_last: "None",

      charge_deposit: false,
      charge_cleaning: false,
      charge_credit_fee: false,
      corporate_notes: "",

      //* Documents
      files: [],
      file_names: [],
      web_URL: "",
      final_notes: "",
      expiry: -1,
    };

    this.props.ErrorNote.bind(this);
  }

  determinePaymentTerms = () => {
    switch (this.state.payment_terms) {
      case "Net 60":
        return 60;
      case "Net 30":
        return 30;
      case "Net 15":
        return 15;
      case "Immediate":
        return 0;
    }
  };

  makeCorporate = async () => {
    // Make corporate with form info
    let newCorporate = {
      corporate_name: this.state.corporation,
      client_manager: this.state.client_manager,
      method_of_payment: this.state.payment_type,
      first_last_policy: this.state.first_last,
      deposit_policy: this.state.charge_deposit,
      cleaning_fee: this.state.charge_cleaning,
      credit_fee: this.state.charge_credit_fee,
      communication_type: "online",
      active: true,
      invoicing_policy: this.state.invoice_method,
      notice_to_vacate: this.state.ntv,
      payment_terms: this.determinePaymentTerms(),
      notes: this.state.corporate_notes,
      address: this.state.address,
      finance_communication_policy: this.state.fin_com_pol,
      cleaning_communication_policy: this.state.clean_com_pol,
      admin_communication_policy: this.state.admin_com_pol,
      variable_communication_policy: this.state.var_com_pol,
      expiry: +this.state.expiry,
      // city: this.state.city
    };
    console.log(newCorporate)
    if (
      newCorporate.corporate_name &&
      newCorporate.method_of_payment &&
      newCorporate.first_last_policy &&
      newCorporate.communication_type &&
      newCorporate.active &&
      newCorporate.invoicing_policy &&
      newCorporate.client_manager
    ) {
      console.log("Met criteria")
      let res = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_CORP_CREATE,
        newCorporate
      );
      return res
    } 
    return null
    // else {
    //   NotificationHandler(
    //     "Warning",
    //     "Required Fields Missing",
    //     "Please fill out the following fields: corporate name, client manager, method of payment, first last policy, deposit policy, cleaning fee, credit fee, communication type, invoicing policy, notice to vacate, payment terms, and the 3 contact forms"
    //   ); //Types : SUCCESS, ERROR, INFO, WARNING
    // }
  };

  checkContacts = () => {
    if (
      this.state.admin_contact.split(" ")[0] &&
      this.state.admin_contact.split(" ")[1] &&
      this.state.admin_contact_phone &&
      this.state.admin_contact_email &&
      this.state.finance_contact.split(" ")[0] &&
      this.state.finance_contact.split(" ")[1] &&
      this.state.finance_contact_phone &&
      this.state.finance_contact_email &&
      this.state.cleaning_contact.split(" ")[0] &&
      this.state.cleaning_contact.split(" ")[1] &&
      this.state.cleaning_contact_phone &&
      this.state.cleaning_contact_email
    ) {
      return true;
    }
    return false;
  };

  resetFields = () =>{
    this.setState(
      {
        title: this.props.title,
        onoff: this.props.onoff,
        corporation: "",
        address: "",
        admin_contact: "",
        admin_contact_email: "",
        admin_contact_phone: "",
        finance_contact: "",
        finance_contact_email: "",
        finance_contact_phone: "",
        cleaning_contact: "",
        cleaning_contact_email: "",
        cleaning_contact_phone: "",
        client_manager: "",
        payment_type: "Credit",
        flagged: false,
        fin_com_pol: "None",
        clean_com_pol: "None",
        admin_com_pol: "None",
        var_com_pol: false,
        invoice_method: "Consolidated",
        ntv: 0,
        payment_terms: "Net 60",
        first_last: "None",

        charge_deposit: false,
        charge_cleaning: false,
        charge_credit_fee: false,
        corporate_notes: "",

        //* Documents
        files: [],
        file_names: [],
        web_URL: "",
        final_notes: "",
        expiry: -1,
      }
    )
  }
  handleClick = async () => {
    // this.props.handleModalToggle();
    let NotificationHandler = this.props.ErrorNote;

    const corp = await this.makeCorporate();
    if (!corp || corp.status === 'Error'){
      NotificationHandler(
        corp.status,
        corp.status,
        corp.data
      ); //Types : SUCCESS, ERROR, INFO, WARNING
      return
    }
    console.log(corp)
    let recentCorp = corp.data.id;
    if (recentCorp) {
      // Make admin contact
      let adminContact = {
        first_name: this.state.admin_contact.split(" ")[0],
        last_name: this.state.admin_contact.split(" ")[1],
        phone_number: this.state.admin_contact_phone,
        email: this.state.admin_contact_email,
        corporate_id: recentCorp,
        admin: true,
        primary: true,
      };

      // Make finance contact
      let financeContact = {
        first_name: this.state.finance_contact.split(" ")[0],
        last_name: this.state.finance_contact.split(" ")[1],
        phone_number: this.state.finance_contact_phone,
        email: this.state.finance_contact_email,
        corporate_id: recentCorp,
        finance: true,
        primary: true,
      };

      // Make cleaning contact
      let cleaningContact = {
        first_name: this.state.cleaning_contact.split(" ")[0],
        last_name: this.state.cleaning_contact.split(" ")[1],
        phone_number: this.state.cleaning_contact_phone,
        email: this.state.cleaning_contact_email,
        corporate_id: recentCorp,
        cleaning: true,
        primary: true,
      };

      console.log(adminContact)
      console.log(financeContact)
      console.log(cleaningContact)
      
      const createAdminStatus = adminContact.email ? await this.createModel(
        Constants.API_PATH_CORP_CONTACT_CREATE,
        adminContact
      ) : null;

      const createFinanceStatus = financeContact.email ? await this.createModel(
        Constants.API_PATH_CORP_CONTACT_CREATE,
        financeContact
      ): null;

      const createCleaningStatus = cleaningContact.email ? await this.createModel(
        Constants.API_PATH_CORP_CONTACT_CREATE,
        cleaningContact
      ) : null;
      
      NotificationHandler(
        "Success",
        "Success",
        this.state.corporation + " has been created."
      ); //Types : SUCCESS, ERROR, INFO, WARNING

      let update1 = store.getState().corpContactReducer.listUpdate;
      update1();
      let update2 = store.getState().corpContactReducer.corpListUpdate;
      update2();
      this.resetFields();
      this.props.handleModalToggle(this.props.onoff);
    }
  };

  createModel = async (link: string, createBody: any) => {
    return await fetchRequestJSON("POST", link, createBody);
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  handleRemoveFile = (i: number) => {
    let arNames = this.state.file_names;
    let arFiles = this.state.files;
    arNames.splice(i, 1);
    arFiles.splice(i, 1);

    this.setState({
      files: arFiles,
      file_names: arNames,
    });
  };

  handleFileChange = (event: any) => {
    let fileNames = this.state.file_names;
    fileNames.push(event.target.files[0].name);
    let Files = this.state.files;
    Files.push(event.target.files[0]);

    this.setState({
      files: Files,
      file_names: fileNames,
    });
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    let Files: any = <></>;

    if (this.state.file_names.length) {
      Files = this.state.file_names.map((name: any, index: any) => (
        <span className="px-2 fade-in text-gray-600 py-2 flex flex-row items-center hover:bg-gray-100 cursor-default border-b ">
          <AiOutlineFileText className="text-lg mr-1" />
          {name}{" "}
          <TiDelete
            onClick={() => this.handleRemoveFile(index)}
            className="ml-auto text-2xl  hover:text-gray-400 cursor-pointer"
          />{" "}
        </span>
        // <div className="fade-in flex flex-row bg-gray-200 pl-4 pr-2 py-1 mr-3 rounded-full items-center">
        //   <span className="text-gray-600 text-sm mr-4">{unit}</span>{" "}
        //   <TiDelete  className="text-gray-500 text-lg hover:text-red-400 cursor-pointer" />
        // </div>
      ));
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4 overflow-y-scroll scroll-hide my-10 border-gray-600 max-w-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <BsFillPersonPlusFill className="text-gray-600 text-3xl mb-3 fixed" />

          <span className="text-xl font-bold text-gray-800 text-left mt-10">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form className="w-full">
            <span className="text-green-400 text-xs font-semibold">Please Add First and Last Name</span>
            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Corporation Name:</span>
                <input
                  id="corporation"
                  onChange={this.handleInputChange}
                  value={this.state.corporation}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 bordxer-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Address:</span>
                <input
                  id="address"
                  onChange={this.handleInputChange}
                  value={this.state.address}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Client Manager:</span>
                <input
                  id="client_manager"
                  onChange={this.handleInputChange}
                  value={this.state.client_manager}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <span>Contacts:</span>

            <div className="grid grid-cols-1 gap-4 w-full my-5">
              <ContactCard
                data={this.state}
                contactType={"Admin"}
                stateChange={this.handleInputChange}
              />
              <ContactCard
                data={this.state}
                contactType={"Finance"}
                stateChange={this.handleInputChange}
              />
              <ContactCard
                data={this.state}
                contactType={"Cleaning"}
                stateChange={this.handleInputChange}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Payment Method:</span>
                <select
                  id="payment_type"
                  value={this.state.payment_type}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Credit</option>
                  <option>Cheque</option>
                  <option>Certified Cheque</option>
                  <option>Wire </option>
                  <option>Cash</option>
                  <option>Direct Deposit</option>
                  <option>PAD</option>
                  <option>Terminal</option>
                  <option>Rent Deductions</option>
                  <option>Other</option>
                  <option>TBD</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  {" "}
                  Admin Communication Policy:
                </span>
                <select
                  id="admin_com_pol"
                  value={this.state.admin_com_pol}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Admin </option>
                  <option>Tenant</option>
                  <option>Both</option>
                  <option>None</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  Finance Communication Policy:
                </span>
                <select
                  id="fin_com_pol"
                  value={this.state.fin_com_pol}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Finance </option>
                  <option>Tenant</option>
                  <option>Both</option>
                  <option>None</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  Cleaning Communication Policy:
                </span>
                <select
                  id="clean_com_pol"
                  value={this.state.clean_com_pol}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Cleaning </option>
                  <option>Tenant</option>
                  <option>Both</option>
                  <option>None</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Invoicing Method:</span>
                <select
                  id="invoice_method"
                  value={this.state.invoice_method}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Consolidated </option>
                  <option>Individual</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Payment Terms:</span>
                <select
                  id="payment_terms"
                  value={this.state.payment_terms}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Net 30</option>
                  <option>Immediate</option>
                  {/*No Longer used Net options: */}
                  {/* <option>Net 60 </option> */}
                  {/* <option>Net 15</option> */}
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Notice To Vacate:</span>
                <input
                  id="ntv"
                  onChange={this.handleInputChange}
                  onWheel={(e) => {e.currentTarget.blur()}}
                  value={this.state.ntv}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>


              <label className="block  ">
                <span className="text-gray-400 text-xs">Expiry Period (Hours):</span>
                <input
                  id="expiry"
                  onChange={this.handleInputChange}
                  onWheel={(e) => {e.currentTarget.blur()}}
                  value={this.state.expiry}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="-1"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  First/Last Policy:
                </span>
                <select
                  id="first_last"
                  value={this.state.first_last}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>None </option>
                  <option>First/Last </option>
                  <option>First Only</option>
                  <option>Last Only</option>
                </select>
              </label>
            </div>

            {/* <label className="block  mb-5 mt-2">
              <span className="text-gray-400 text-xs">Additional Notes</span>
              <textarea id="owner_notes" onChange={this.handleInputChange} value={this.state.owner_notes} className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows={3} ></textarea>
            </label> */}

            <div className="grid grid-cols-1 gap-1 w-1/2">
            <div className={" block   my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace-nowrap"
                    }
                  >
                    Variable Communication Policy
                  </span>

                  <ToggleSwitch
                    id="var_com_pol"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.var_com_pol}
                  />
                </div>
              </div>
              <div className={" block   my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace-nowrap"
                    }
                  >
                    Charge Deposit
                  </span>

                  <ToggleSwitch
                    id="charge_deposit"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.charge_deposit}
                  />
                </div>
              </div>

              <div className={" block  my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Charge Cleaning
                  </span>

                  <ToggleSwitch
                    id="charge_cleaning"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.charge_cleaning}
                  />
                </div>
              </div>

              <div className={" block  my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Charge Credit Fee
                  </span>

                  <ToggleSwitch
                    id="charge_credit_fee"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.charge_credit_fee}
                  />
                </div>
              </div>
            </div>

            <label className="block  w-full mt-2   ">
              <span className="text-gray-500 text-base ">Files</span>
              <div
                // onDragOver={dragOver}
                // onDragEnter={dragEnter}
                // onDragLeave={dragLeave}
                // onDrop={fileDrop}
                className="form-input border-2 mt-3 cursor-pointer hover:bg-gray-100"
              >
                <span className="text-gray-600 flex flex-row w-full items-center align-center justify-center py-6 ">
                  {" "}
                  <AiOutlinePaperClip className="text-xl text-gray-600 mr-1" />{" "}
                  <span className="text-blue-400 mr-1"> Add file </span>
                </span>
              </div>
              <input
                id="file"
                name="file"
                type="file"
                multiple
                onChange={this.handleFileChange}
                className="hidden mt-1  w-full"
              />
            </label>
            {Files}

            <label className="block  mb-5 mt-3">
              <span className="text-gray-400 text-xs">Additional Notes</span>
              <textarea
                id="corporate_notes"
                onChange={this.handleInputChange}
                value={this.state.corporate_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>

            <div className="flex flex-row justify-start w-full mt-6">
              <button
                type="button"
                onClick={() => this.handleClick()}
                className="transition  duration-200 w-full hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Add Corporate
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewCorporate);

export function ContactCard(props: any) {
  var id = props.contactType.toLowerCase();
  let name;
  let email;
  let phone;

  if (props.contactType === "Admin") {
    name = props.data.admin_contact;
    email = props.data.admin_contact_email;
    phone = props.data.admin_contact_phone;
  }
  if (props.contactType === "Finance") {
    name = props.data.finance_contact;
    email = props.data.finance_contact_email;
    phone = props.data.finance_contact_phone;
  }
  if (props.contactType === "Cleaning") {
    name = props.data.cleaning_contact;
    email = props.data.cleaning_contact_email;
    phone = props.data.cleaning_contact_phone;
  }

  return (
    <div className="flex flex-col px-4 py-3 h-auto border-2 rounded-sm border-solid border-gray-100 ">
      <div className="flex flex-row w-full  items-center">
        <span className="font-medium text-gray-700 text-md pb-1 ">
          {props.contactType} -
          <input
            id={id + "_contact"}
            type="text"
            className=" transition-all duration-200 font-medium text-gray border-0 p-0  pl-1 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
            value={name}
            onChange={props.stateChange}
            placeholder="Click To Edit Name"
          />
        </span>
      </div>

      <span className="font-regular text-gray-400 text-2xs pb-4">
        Corporate - {props.data.corporation}
      </span>
      <input
        id={id + "_contact_email"}
        type="text"
        className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
        value={email}
        onChange={props.stateChange}
        placeholder="Click To Edit Email"
      />
      <span className="flex flex-row items-center font-regular text-gray-400 text-xs ">
        <i className="text-gray-500 mr-1 ">
          <FaPhoneAlt />
        </i>
        <input
          id={id + "_contact_phone"}
          type="text"
          className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
          value={phone}
          onChange={props.stateChange}
          placeholder="Click To Edit Phone"
        />
      </span>
    </div>
  );
}
