import { Component } from "react";

interface Props {
  title: string;
  content: any;
  handleModalToggle?: any;
  confirmButton?: any;
  onoff: boolean;
  yes?: boolean;
}

interface ModalState {
  title: string;
  content: any;
  onoff: boolean;
}

class Modal extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      content: this.props.content,
      onoff: this.props.onoff,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleConfirm = () => {
    this.props.confirmButton();
  };



  render() {
    if (!this.props.onoff) {
      return null;
    }

    console.log(this.props.content);

    let confirm = "Confirm";
    let cancel = "Cancel";
    if (this.props.yes) {
      confirm = "Yes";
      cancel = "No";
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-50"
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto max-w-screen-md py-8 px-10 mx-auto flex flex-col">
          <span className="text-xl font-bold text-gray-800 text-center">
            {this.state.title}
          </span>
          {
            this.props.confirmButton ?
            <span className="text-xs text-center text-gray-400">
              {this.props.content}
            </span> :
            <>
              <pre className="bg-gray-100 text-red-500 text-sm p-4 rounded-lg shadow overflow-auto max-h-96 mt-3">
                {this.props.content}
              </pre>
            </>
          }

          <div className="flex flex-row justify-around w-full mt-5 mr-5">
            {
              this.props.confirmButton ?
              <>
                <button
                  onClick={this.handleConfirm}
                  className=" font-SF-Pro shadow-sm transition text-sm duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center "
                >
                  {confirm}
                </button>
                <button
                  id="cancel"
                  onClick={this.handleModal}
                  className=" font-SF-Pro shadow-sm transition text-sm duration-100 w-36 bg-gray-200 hover:bg-red-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center "
                >
                  {cancel}
                </button>
              </> :
              <>
                <button
                  onClick={() => this.props.handleModalToggle()}
                  className=" font-SF-Pro shadow-sm transition text-sm duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center "
                >
                  OK
                </button>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
