import { Component, useState } from "react";
import {Box, Modal, TextField, Typography} from '@mui/material'; 
import { DatePicker } from '@mui/lab';
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { ImCross } from "react-icons/im";
import { AiOutlineDownload } from "react-icons/ai";

type Props = {
    open: boolean; 
    closeModal: any; 
    building_name: string;
};

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function BuildingCSVModal(props: Props) {
    const classes = useStyles();
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [end_date, setEndDate] = useState(new Date().toISOString().split('T')[0]); 
    const [url, setURL] = useState("");

    function onDateChange(event: any): void {
        event.preventDefault();
        setDate(event.target.value);
    }

    async function generateCSV() {
        let res = await fetchRequestJSON(
            'POST', 
            Constants.API_PATH_BUILDING_BOOKING_GET_CSV, 
            {
                building_name: props.building_name,
                month: date,
            });
        console.log(res);
        if (res.status == "Success") {
            let data = new Blob([res.data], {type: 'text/plain'});
            setURL(window.URL.createObjectURL(data));
        } else {
            alert("There was an error creating the CSV")
        }
    }

    return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                open={props.open}
                onClose={() => props.closeModal()}
            >
                <div className=" appear bg-white shadow-lg rounded-lg h-2/5 w-1/2 border-4  border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center text-xl font-bold font-SF-Pro">
                    {/* <button className="" onClick={() => {
                        setOpen(false); 
                    }}>
                        <ImCross className="text-gray-600 text-s mb-3" />
                    </button> */}
                    <span className="text-xl font-bold text-gray-800 text-left pb-4">Download {props.building_name} bookings CSV</span>
                    {/* <DatePicker value={date} onChange={(newDate) => onDateChange(newDate)} renderInput={(params) => <TextField {...params} />}></DatePicker> */}
                    <div className="mx-auto flex justify-center">
                        <label className="pl-3 mb-5 mx-auto flex flex-col">
                            <span className="text-gray-400 text-xs">Select Search Month</span>
                            <input
                                id="date"
                                onChange={(e) => onDateChange(e)}
                                value={date}
                                type="date"
                                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                placeholder=""
                            />
                        </label>
                    </div>
                    <div className="h-24 flex justify-around">
                        <button
                            // onClick={() => generateCSV()}
                            className="transition duration-100 border-2 border-solid w-36 bg-gray-800 hover:bg-green-400 text-white  font-medium py-1 rounded pr-1 flex flex-row justify-center align-center h-12"
                        >
                            <span className=" text-xs self-center ">Generate CSV</span>
                        </button>
                        {(url !== "") && <a 
                            className="transition duration-100 border-2 border-solid w-36 border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center h-12"
                            href={url}
                            download={props.building_name + "-" + date.split('-')[0]+ "-" + date.split('-')[1] + ".csv"}
                        >
                            <AiOutlineDownload className="  text-xl mr-1 self-center" />
                            <span className=" text-xs self-center ">Download CSV</span>
                        </a>}
                    </div>
                    </div>
            </Modal>
    ); 
}



export {BuildingCSVModal}