export function formatFullDate(date: Date) {
  // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const d = date;
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
  const day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  // let hour = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(d);

  var nDay = parseInt(day, 10);

  return month + " " + day + nth(nDay) + " " + year;
}

// Accepts a date formatted as: "YYYY-MM-DD"
export function formatStringDate(date: string) {
  if (date == "") {return ""}
  let comp = date.split('-');
  const monthNames = [ "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December" ];
  let suffix = "th ";
  if (comp[2][1] == "1") {
    if (comp[2][0] != "1") {
      suffix = "st ";
    }
  } else if (comp[2][1] == "2"){
    suffix = "nd ";
  } else if (comp[2][1] == "3") {
    suffix = "rd ";
  }
  return monthNames[parseInt(comp[1]) - 1] + " " + parseInt(comp[2]) + suffix + comp[0];
}

export function formatStringDay(date: string) {
  if (date == "") {return ""}
  let comp = date.split('-');
  const monthNames = [ "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December" ];
  let suffix = "th ";
  if (comp[2][1] == "1") {
    if (comp[2][0] != "1") {
      suffix = "st ";
    }
  } else if (comp[2][1] == "2"){
    suffix = "nd ";
  } else if (comp[2][1] == "3") {
    suffix = "rd ";
  }
  return monthNames[parseInt(comp[1]) - 1] + " " + parseInt(comp[2]) + suffix;
}

const nth = function (day: number) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function formatDay(day: string) {
  var nDay = parseInt(day, 10);

  const nth = function (day: number) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return day + nth(nDay);
}

export function daysBetween(date1: string, date2: string) {
  const nDay1 = Date.parse(date1);
  const nDay2 = Date.parse(date2);
  var minutes = 1000 * 60;
  var hours = minutes * 60;
  var days = hours * 24;
  // var years = days * 365;

  return Math.round((nDay2 - nDay1) / days);
}

export function formatFullDateString(date: string, DateLength: string) {
  // console.log(date)

  if (date === "") {
    let reset = new Date();
    date = reset.toLocaleDateString();
  }

  let dateUTC = new Date(date);
  dateUTC.setTime(dateUTC.getTime() + dateUTC.getTimezoneOffset() * 60 * 1000);
  let stringdateUTC = dateUTC.toUTCString();

  // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const d = Date.parse(stringdateUTC);
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
  // let hour = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(d);

  var nDay = parseInt(day, 10);

  if (DateLength === "short") {
    return month + " " + day + nth(nDay);
  } else {
    return month + " " + day + nth(nDay) + " " + year;
  }
}

export function inputISO(date: any) {
  if (date === "") {
    date = new Date();
    return date;
  } else {
    date = new Date(date);
    // date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 );
  }

  let year = date.getFullYear().toString();
  let month = date.getMonth() + 1;
  let dt = date.getUTCDate()
  let day = "";
  let month2 = "";

  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt.toString();
  }
  if (month < 10) {
    month2 = "0" + month;
  } else {
    month2 = month.toString();
  }

  return year + "-" + month2 + "-" + day;
}

export function addDays(date: Date, days: number): Date {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}