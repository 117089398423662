import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { formatFullDate } from "../../utils/DateFormat";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { AiOutlineUserAdd } from "react-icons/ai";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../utils/PageSelector";
import {listview} from "../../models/modelList";

// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  toggleLead: any;
  handleState: any;
};

type State = {
  blackout: boolean;
  contacts: CorporateContact[];
  search: string;
  loaded: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  authorization: boolean;
};

type CorporateContact = {
  id: number;
  first_name: string;
  last_name: string;
  corporate: {
    corporate_name: string;
    method_of_payment: string;
  };
  email: string;
};

class LeadList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      contacts: [],
      search: "",
      loaded: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      authorization: true,
    };
  }

  openSettings = () => {
    alert("Settings");
  };

  handleNext = (id: number) => {
    store.dispatch({ type: "contactIdTransfer", id: id });
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: location.pathname + "/corpcontactprofile",
        search: `contact_id=${id}`,
      });
  };

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
    
  }

  setPerPage = async (val: number) => {
    await this.setState({perpage: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState({
          curpage: this.state.totalpages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  componentDidMount = async () => {
    this.updateList();
    store.dispatch({ type: "contactListUpdate", listUpdate: this.updateList });
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // Populate list entries with corporate contact relation from database
  updateList = async () => {
    let filters = {
      search: ""
    }
    filters.search = this.state.search.trim();

    console.log({filters: filters, page: this.state.curpage, perpage: this.state.perpage});
    const allContacts: listview<CorporateContact[]> = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_CONTACTS}?${this.state.search ? `search=${this.state.search.trim()}&`: ''}page=${this.state.curpage}&perpage=${this.state.perpage}`)

    console.log(allContacts)
    if (allContacts.status !== "Success") {
      this.setState({
        loaded: true,
        authorization: allContacts.status == "Error Unauthorized" ? false : true
      })
      return;
    }

    this.setState({totalpages: allContacts.data.pages})
    
    // Make a copy of the state invoices list so I can reset the state and rerender the page
    let contactsList: CorporateContact[] =  allContacts.data.rows.map((corporateContact: CorporateContact) => corporateContact)
    this.setState({ contacts: contactsList, loaded: true });
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    // const { history } = this.props;

    let TableRows = <></>;
    var rows = [];
    let altBg = "";

    for (var i = 0; i < this.state.contacts.length; i++) {
      let contactId = this.state.contacts[i].id;

      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }

      TableRows = (
        <tr
          key={i.toString()}
          className={
            altBg +
            " cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"
          }
        >
          {/* Suite Name */}

          <td
            onClick={() => this.handleNext(contactId)}
            className={" text-purple-500 w-20 py-4 pl-4"}
          >
            {contactId}
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.handleNext(contactId)}
            className={
              "  w-auto font-light text-sm text-gray-500 px-1 py-4 grid grid-cols-1"
            }
          >
            <span className="text-gray-700">
              {this.state.contacts[i].first_name + " " + this.state.contacts[i].last_name}
            </span>
            <span className="text-gray-400">
              {this.state.contacts[i].email}
            </span>
          </td>

          {/* Min. Stay */}

          <td
            onClick={() => this.handleNext(contactId)}
            className={"  font-thin  text-gray-600 px-1  py-4 break-words max-w-xs"}
          >
            {this.state.contacts[i].corporate.corporate_name}
          </td>

          {/* Bedrooms */}

          {/* <td
            onClick={() => this.handleNext(contactId)}
            className={"font-medium  text-gray-700 px-1  py-4"}
          >
            {this.state.contacts[i].client_manager}{" "}
          </td> */}

          {/* Type */}
  
          <td
            onClick={() => this.handleNext(contactId)}
            className={"  font-normal  text-gray-400 px-1  py-4"}
          >
            {this.state.contacts[i].corporate.method_of_payment}
          </td>

          {/* Booking Warnings */}

          <td className=" w-32 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                onClick={this.openSettings}
                className={"text-gray-700 text-2xl mr-2 hover:text-gray-500"}
              />
            </div>
          </td>
        </tr>
      );

      rows.push(TableRows);
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full lg:w-2/4 py-1 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1 " />
              <input
                className="text-sm searchBox w-full"
                placeholder="Search contact name/id, or corporate name"
                id="search"
                value={this.state.search}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>
            {/* <span className="py-1 px-4 font-medium margin text-gray-500 ">
              Filters:
            </span>

            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Recent
            </button>
            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Corporate
            </button>
            <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
              Budget
            </button> */}
          </div>

          <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10,20,30,40,50]}
            />

          
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="text-left font-normal text-gray-500 ">
              <th className="py-2 pl-4">ID</th>
              <th className="py-2  ">Contact</th>
              <th className="py-2 w-64 break-words">Corporation</th>
              <th className="py-2  ">Payment Methods</th>

              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={5} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(LeadList);
