import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import { useNotification } from "../Notifications/NotificationProvider"
import { MdPlaylistAdd } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import Label from "../LabelWrap";

import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import ContactList from "./ContactList";
import NewCorporate from "./NewCorporate";
import OtherContactList from "./OtherContactList";
import CorporateList from "./CorporateList";
import EditTenant from "./EditTenant"
import NewTenant from "./NewTenant";
import { TenantProfile } from "./TenantProfile";
import store from "../../redux/store";
import NewContact from "./NewContact";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import AppStage from "../../utils/Types";

// import NewLead from "./NewLead";
// import CorporateLeads from "./CorporateLeads";
// import NewLead from './NewLead'

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  toggleLead: boolean;
  editToggle: boolean;
  newCorporate: boolean;
  editTenant: boolean;
  newTenant: boolean;
  tenantProfile: boolean;
  newContact: boolean;
  csv_url: string;
  selected_button: string;
};

//Create Map for our csv constants:
//TODO: once the apis are working, replace the values with Constants....
const CVSAPIS: Map<string, string> = new Map([
  ['corporate', Constants.API_PATH_CORP_CSV],
  ['corporate contacts', Constants.API_PATH_CORP_CONTACT_CSV],
  ['tenants', Constants.API_PATH_TENANT_CSV],
]);


class Contacts extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "1234",
      check_in: "February 12th 2021",
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      toggleLead: false,
      editToggle: false,
      newCorporate: false,
      editTenant: false,
      newTenant: false,
      tenantProfile: false,
      newContact: false,
      csv_url: "#",
      selected_button: "corporate contacts"
    };
  }

  componentDidMount = async () => {
    let url = await this.handleDownloadCSV("corporate contacts");
    this.setState({ csv_url: url });
  }

  handleButtonSelect = async (name: string) => {
    this.setState({ selected_button: name })
    const url = await this.handleDownloadCSV(name);
    this.setState({ csv_url: url });
  }

  handleDownloadCSV = async (name: string) => {
    const request = await fetchGetJSON(CVSAPIS.get(name));

    if (request.status == "Success") {
      let data = new Blob([request.data], { type: 'text/plain' });
      return window.URL.createObjectURL(data);
    }
    return "#";
  }

  handleLeadView = (state?: any) => {
    if (state && this.state.toggleLead === false) {
      this.setState({ toggleLead: true });
    } else {
      this.setState({ toggleLead: false });
    }
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  // componentDidMount = async () => {

  // }

  render() {
    let bgFocus;

    return (
      <div className=" font-display bg-white lg:rounded-tl-lg  ">
        <TenantProfile
          toggle={this.state.tenantProfile}
          toggleProfile={this.handleToggleChange}
        />
        <NewContact
          handleModalToggle={() =>
            this.handleToggleChange("newContact", this.state.newContact)
          }
          onoff={this.state.newContact}
          title={"Add New Contact"}
        />
        <NewCorporate
          handleModalToggle={() =>
            this.handleToggleChange("newCorporate", this.state.newCorporate)
          }
          onoff={this.state.newCorporate}
          title={"Add New Corporate"}
        />
        <NewTenant
          handleModalToggle={() =>
            this.handleToggleChange("newTenant", this.state.newTenant)
          }
          onoff={this.state.newTenant}
          title={"Add New Tenant"}
        />
        <EditTenant
          toggle={this.state.editTenant}
          toggleEdit={this.handleToggleChange}
        />
        {bgFocus}
        <BreadCrumb
          rootPage="Contacts"
          subPage=""
          sideButton=""
          buttonAction={""}
        />
        <div className="fade-in px-3 mt-24 lg:mt-0 lg:px-10  ">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2">
            {/* Left Side  */}
            <div className="flex gap-4  px-2">
              {(this.state.selected_button == "corporate contacts") &&
                <button
                  onClick={() => { this.handleToggleChange("newContact", this.state.newContact) }}
                  className="  shadow-md transition duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Contact</span>
                </button>
              }

              {(this.state.selected_button == "corporate") &&
                <button
                  onClick={() => { this.handleToggleChange("newCorporate", this.state.newCorporate) }}
                  className="  shadow-md transition duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Corporate</span>
                </button>
              }

              {(this.state.selected_button == "tenants") &&
                <button
                  onClick={() => { this.handleToggleChange("newTenant", this.state.newTenant) }}
                  className="  shadow-md transition duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Tenant</span>
                </button>
              }
            </div>

            {/* Right Side */}
            <div className="flex flex-row w-1/2 justify-start px-3">
              {
                AppStage == "staywell" &&
                <a
                className="transition duration-100 border-2 border-solid w-36 bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                href={this.state.csv_url} 
                download={`${this.state.selected_button}.csv`}
              >
                <AiOutlineDownload className="  text-xl mr-1 self-center" />
                <span className=" text-xs self-center ">Download CSV</span>
              </a>
              }

            </div>
          </div>

          <TabList warning={false} tabSelectButton={this.handleButtonSelect}>
            <Label label="Corporate Contacts">
              <ContactList
                toggleLead={this.handleLeadView}
                handleState={this.handleToggleChange}
              />
            </Label>

            <Label label="Corporate">
              <CorporateList handleState={this.handleToggleChange} />
            </Label>

            <Label label="Tenants">
              {/* <CorporateLeads toggleLead={this.handleLeadView} /> */}
              <OtherContactList toggleEdit={this.handleToggleChange} handleState={this.handleToggleChange}></OtherContactList>
            </Label>
          </TabList>
        </div>
      </div>
    );
  }
}

export default Contacts;


