import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AiOutlineDownload, AiOutlineFileDone } from "react-icons/ai";
import { BiBookOpen } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { useNotification } from "../Notifications/NotificationProvider";
import store from "../../redux/store";
import { gridDensityValueSelector } from "@mui/x-data-grid";

type Props = {
  handleModalToggle?: any;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2d3748",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const ARReport = (props: Props): JSX.Element => {
  const [theseInvoices, setTheseInvoices] = useState([]);
  const [originalInvoices, setOriginalInvoices] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noData, setNoData] = useState(false);
  const [corp, setCorp] = useState([]);
  const [dropdown, setDropdown] = useState(null);
  const [allInvoices, setallInvoices] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalSum, setTotalSum] = useState(0);
  const [urlCsv, setUrlCsv] = useState('');

  //These are for the button colors:
  const [is30, set30] = useState(false);
  const [is60, set60] = useState(false);
  const [is90, set90] = useState(false);
  const [greater90, setGreater90] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(-1);

  const dispatch = useNotification();

  useEffect(() => {
    HandleError(
      "Processing",
      `Fetching Collections Report`,
      "Please wait while we process the information",
    );

    const fetchData = async () => {
      let corporateIDs: any[] = [];
      const allOutstandingAR = await fetchGetJSON(`${Constants.API_PATH_OUTSTANDING_AR}?corporate_id`
      ).then((response) => {
        let totalCost = 0;
        if (response.data) {
          response.data = response.data.sort((invoice1: any, invoice2: any) => { return invoice2.days_late - invoice1.days_late })
          setTheseInvoices(response.data);
          setOriginalInvoices(response.data);
          response.data.forEach((element: any) => {
            totalCost += element.cost;
          })
          setTotalSum(totalCost);
          setNoData(false)
          corporateIDs = response.corporateIDs;
          let data = new Blob([response.csv], { type: 'text/plain' });
          setUrlCsv(window.URL.createObjectURL(data));
        } else {
          setNoData(true)
        }
      });

      let corporateIdQueryString = corporateIDs.length ? '' : `corporate_id`

      for(const corporateID of corporateIDs) {
        if(corporateIdQueryString.length) {
          corporateIdQueryString+=`&corporate_id=${corporateID}`
        } else {
          corporateIdQueryString+=`corporate_id=${corporateID}`
        }
      }

      const outstandingCorps = await fetchGetJSON(`${Constants.API_PATH_CORP_GET_ALL}?${corporateIdQueryString}`
      ).then((response) => {
        if (response.data) {
          response.data = response.data.sort((corp1: any, corp2: any) => (corp1.corporate_name > corp2.corporate_name) ? 1 : (corp1.corporate_name < corp2.corporate_name) ? -1 : 0)
          setCorp(response.data);
        } else {
          setNoData(true)
        }
        setLoading(false);
      });
      // const oustandingCSV = await fetchGetJSON(Constants.API_PATH_OUSTANDING_AR_CSV).then((response) => {
      //   if (response.status == "Success") {
      //     let data = new Blob([response.data], { type: 'text/plain' });
      //     setUrlCsv(window.URL.createObjectURL(data));
      //   }
      //   else {
      //     setUrlCsv("#");
      //   }
      // });
    }
    fetchData();
    
  }, []);

  function HandleError(type: string, title: string, message: string,) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message
    })
  }

  // Toggle popup function
  const handleClick = (id: number) => {
    // console.log(id)
    store.dispatch({ type: "invoiceIdTransfer", id: id });
    store.dispatch({ type: "updateARList", ARUpdate: fetchDataRedux });
    props.handleModalToggle();
  };

  const fetchDataRedux = async () => {

    let corporateIDs: any[] = [];
    const allOutstandingAR = await fetchGetJSON(`${Constants.API_PATH_OUTSTANDING_AR}?corporate_id=${dropdown ? dropdown : ''}&startDate${startDate.length ? `=${startDate}`: ''}&endDate=${endDate.length ? `=${endDate}` : ''}`)
    .then((response) => {
      let totalCost = 0;
      if (response.data) {
        response.data = response.data.sort((invoice1: any, invoice2: any) => { return invoice2.days_late - invoice1.days_late })
        setOriginalInvoices(response.data); //we use this for filter backup
        //Apply filter into here for the response.data

        if(currentFilter != -1) {
          response.data = response.data.filter((invoice:any) => {
            if(currentFilter > 90)
            {
              return invoice.days_late > 90
            } else {
              return invoice.days_late <= currentFilter
            }
          })
        }

        setTheseInvoices(response.data);
        response.data.forEach((element: any) => {
          totalCost += element.cost;
        })
        setTotalSum(totalCost);
        setNoData(false)
        corporateIDs = response.corporateIDs;
      } else {
        setNoData(true)
      }
    });
  }

  //Button Filter Logic:
  const filter30 = async() => {
    if(!is30) {
      HandleError(
        "Success",
        `Filtering AR By 30 Days and less`,
        "Please wait while we process the information",
      );
      setCurrentFilter(30);
      await set30(true)
      await set60(false)
      await set90(false)
      await setGreater90(false)
      setTheseInvoices(originalInvoices.filter(value => value.days_late <= 30))

      //Set Total Sum:
      let sum = 0;
      for(const invoices of originalInvoices.filter(value => value.days_late <= 30)) {
        sum += invoices.cost;
      }
      setTotalSum(sum)
    } else {
      await set30(false);
      setCurrentFilter(-1);
      setTheseInvoices(originalInvoices)
    }
  }

  const filter60 = async() => {
    if(!is60) {
      HandleError(
        "Success",
        `Filtering AR From 31 Days to 60 Days`,
        "Please wait while we process the information",
      );
      setCurrentFilter(60);
      await set60(true)
      await set30(false)
      await set90(false)
      await setGreater90(false)
      setTheseInvoices(originalInvoices.filter(value => value.days_late <= 60 && value.days_late > 30))

      //Set Total Sum:
      let sum = 0;
      for(const invoices of originalInvoices.filter(value => value.days_late <= 60 && value.days_late > 30)) {
        sum += invoices.cost;
      }
      setTotalSum(sum)
    } else {
      await set60(false);
      setCurrentFilter(-1);
      setTheseInvoices(originalInvoices)
    }
  }

  const filter90 = async() => {
    if(!is90) {
      HandleError(
        "Success",
        `Filtering AR From 61 Days to 90 Days`,
        "Please wait while we process the information",
      );
      setCurrentFilter(90);
      await set90(true)
      await set30(false)
      await set60(false)
      await setGreater90(false)
      setTheseInvoices(originalInvoices.filter(value => value.days_late <= 90 && value.days_late > 60))

      //Set Total Sum:
      let sum = 0;
      for(const invoices of originalInvoices.filter(value => value.days_late <= 90 && value.days_late > 60)) {
        sum += invoices.cost;
      }
      setTotalSum(sum)
    } else {
      await set90(false);
      setCurrentFilter(-1);
      setTheseInvoices(originalInvoices)
    }
  }

  const filterGreater90 = async() => {
    if(!greater90) {
      HandleError(
        "Success",
        `Filtering AR By 90 Days and More`,
        "Please wait while we process the information",
      );
      setCurrentFilter(91);
      await setGreater90(true)
      await set30(false)
      await set60(false)
      await set90(false)
      setTheseInvoices(originalInvoices.filter(value => value.days_late > 90))

      //Set Total Sum:
      let sum = 0;
      for(const invoices of originalInvoices.filter(value => value.days_late > 90)) {
        sum += invoices.cost;
      }
      setTotalSum(sum)
    } else {
      await setGreater90(false);
      setCurrentFilter(-1);
      setTheseInvoices(originalInvoices)
    }
  }

  const tableCreation = (): JSX.Element => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Invoice ID</StyledTableCell>
              <StyledTableCell>Booking ID</StyledTableCell>
              <StyledTableCell>Corporate Name</StyledTableCell>
              {/* <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Unit</StyledTableCell> */}
              <StyledTableCell>Invoice Notes</StyledTableCell>
              <StyledTableCell>Cost</StyledTableCell>
              {/* <StyledTableCell>Corporate</StyledTableCell> */}
              <StyledTableCell>Days Late</StyledTableCell>
              <StyledTableCell>Booking & Invoice Link</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {theseInvoices?.map((invoice) => (
              <TableRow key={invoice.invoice_id}>
                <TableCell align="left" onClick={() => handleClick(invoice.invoice_id)}>{invoice.invoice_id}</TableCell>
                <TableCell align="left" onClick={() => handleClick(invoice.invoice_id)}>{invoice.booking_id != -1 ? invoice.booking_id : "Non Booking Invoice"}</TableCell>
                <TableCell align="left" onClick={() => handleClick(invoice.invoice_id)}>
                  {invoice.corporate_name}
                </TableCell>
                {/* <TableCell align="left">{invoice.name}</TableCell>
                  <TableCell align="left">{invoice.unit_name}</TableCell> */}
                <TableCell align="left" onClick={() => handleClick(invoice.invoice_id)}>{invoice.invoice_notes}</TableCell>
                <TableCell align="left" onClick={() => handleClick(invoice.invoice_id)}>${invoice?.cost ? invoice?.cost?.toLocaleString('en-US', { maximumFractionDigits: 2 }) : 0.00}{ invoice?.cost ? !(invoice?.cost?.toLocaleString('en-US', { maximumFractionDigits: 2 })).includes('.') ? '.00' : '' : ''}</TableCell>
                {/* <TableCell align="left">{invoice.corporate_name}</TableCell> */}
                <TableCell align="left" onClick={() => handleClick(invoice.invoice_id)}>
                  {Math.trunc(invoice.days_late)}
                </TableCell>
                <TableCell align="left">
                  {
                    invoice.booking_id != -1 ?
                      <button
                        onClick={() => {
                          // window.location.href = invoice.invoice_link;
                          window.open(`/user/bookings/bookingprofile?id=${invoice.booking_id}`, '_blank');
                        }}
                        className="mx-2 group pb-1"
                        key={"Client"}
                      >
                        <BiBookOpen className="text-green-500 text-xl mx-aut" />
                      </button> : ''
                  }
                  {
                    <button
                      onClick={() => {
                        // window.location.href = invoice.invoice_link;
                        window.open(invoice.invoice_link, '_blank');
                      }}
                      className="mx-2 group pb-1"
                      key={"Client"}
                    >
                      <AiOutlineFileDone className="  text-xl mx-auto" />
                    </button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleDropdown = (event: any) => {
    if (event.target.value == -5) {
      setDropdown(null)
    }
    setDropdown(event.target.value)
  }

  const handleUpdate = async (event: any) => {
    HandleError(
      "Processing",
      `Fetching Collections`,
      "Please wait while we process the information",
    );

    if (startDate > endDate) {
      HandleError(
        "Error",
        `Start Date is greater than End Date`, `Please reenter your start date and end date and click Update`
      );
    }
    else {
      HandleError(
        "Success",
        `Fetching Collections`,
        "Please wait while we process the information",
      );
      set30(false)
      set60(false)
      set90(false)
      setGreater90(false)
      setTheseInvoices(null);
      setLoading(true);

      const allOutstandingAR = await fetchGetJSON(`${Constants.API_PATH_OUTSTANDING_AR}?corporate_id=${dropdown ? dropdown : ''}&startDate${startDate.length ? `=${startDate}`: ''}&endDate=${endDate.length ? `=${endDate}` : ''}`)
        .then((response) => {
          let totalCost = 0;
          if (response.data) {
            response.data = response.data.sort((invoice1: any, invoice2: any) => { return invoice2.days_late - invoice1.days_late })
            setTheseInvoices(response.data);
            setOriginalInvoices(response.data);
            response.data.forEach((element: any) => {
              totalCost += element.cost;
            })
            setTotalSum(totalCost);
            setallInvoices(response.data)
            setNoData(false)
          } else {
            setNoData(true)
          }
      });
      setLoading(false);
      setStartDate('');
      setEndDate('');
    }
  }

  let loadingTable = <></>;
  if (loading) {
    loadingTable = <table className="table-auto w-full mb-8">
      <tbody>
        {<LoadingGeneral count={100} cols={5} empty={noData} />}
      </tbody>
    </table>
  }

  return (
    <div>
      <div className="font-display bg-white lg:rounded-tl-lg">
        <div className="mt-10 text-center text-lg">Collections Report</div>
        <div className="mt-10 text-center text-lg">{`Note: Collections Report only contains outstanding invoices that have not been paid/pending based on if the invoice's due date is before or equal to the selected date: ${new Date().toISOString().substring(0, 10)}`}</div>
        <div className="fade-in px-10 mt-16 lg:mt-0 max-w-5xl pb-6 m-auto w-auto flex flex-row justify-center">
          <div className="flex flex-row mt-10 ">
            <select
              onChange={handleDropdown}
              className="  block w-64 mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            >
              <option value={-5}>All</option>
              <option value={-1}>Non Booking</option>
              <option value={-2}>{`Retail (Net 0 Only)`}</option>
              {corp.map((corporation) => (
                <option value={corporation.id}>{corporation.corporate_name}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-row mt-10 ">
            <div className="block">
              Total Cost: <span className="text-green-500">${totalSum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
            </div>
          </div>
          <button className="mx-5 mt-10 border px-5 rounded hover:bg-gray-300"
              onClick={handleUpdate}>
              Update
            </button>
          <div className="flex flex-row mt-10 ">
            <div className="hidden lg:flex w-3/4 px-3 ">
              <a
                className="transition duration-100 border-2 border-solid w-36 bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                href={urlCsv}
                download={`Collections Report for ${new Date().toISOString().substring(0, 10)}.csv`}
              >
                <AiOutlineDownload className="text-xl mr-1 self-center" />
                <span className="text-xs self-center ">Download CSV</span>
              </a>
            </div>
          </div>
        </div>

        <span className="py-1 font-medium margin text-gray-500">
              Filters:
        </span>
        <div className="flex flex-row flex-wrap mb-5">
          <button 
            className={
              (is30 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
              " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
            }
            onClick={() => {
              filter30()
            }}
          >
            {`≤30 Days`}
          </button>
          <button 
            className={
              (is60 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
              " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
            }
            onClick={() => {
              filter60()
            }}
          >
            {`31 to 60 Days`}
          </button>
          <button 
            className={
              (is90 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
              " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
            }
            onClick={() => {
             filter90()
            }}
          >
            {`61 to 90 Days`}
          </button>
          <button 
            className={
              (greater90 ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
              " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
            }
            onClick={() => {
              filterGreater90()
            }}
          >
            {`Greater than 90 Days`}
          </button>
        </div>

        {tableCreation()}
        {loadingTable}
      </div>
    </div >
  );
};
