import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Autocomplete from '@mui/material/Autocomplete';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
// import Select from 'react-select'
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { useNotification } from "../Notifications/NotificationProvider";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Chart from "react-apexcharts";
import TextField from "@mui/material/TextField";
import { startOfDay } from "@fullcalendar/common";

import Loading from "../LoadingWidget"
import ToggleSwitch from "../ToggleSwitch";
import { RetrospectiveInvoiceBreakdown } from "../../utils/Types";


type dailyReport = {
    checkInDay: string,
    occupiedRooms: number,
    activeUnits: number,
    blackOutNumber: number,
    availableRooms: number,
    occupancyRate: number,
    dailyADR: number,
    revPar: number,
    dailyCost: number,
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#2d3748",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          backgroundColor: '#10B981', // Change to your desired color
          color: 'white',          // Change to your desired text color
          '& .MuiChip-deleteIcon': {
            color: 'white',        // Change to your desired delete icon color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#10B981',  // Change to your desired label color
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#10B981',  // Change to your desired hover color
          },
        },
      },
    },
  },
});

const buildingTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          backgroundColor: '#057DCD', // Change to your desired color
          color: 'white',          // Change to your desired text color
          '& .MuiChip-deleteIcon': {
            color: 'white',        // Change to your desired delete icon color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#057DCD',  // Change to your desired label color
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#057DCD',  // Change to your desired hover color
          },
        },
      },
    },
  },
});

const dayjs = require('dayjs');
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export const RetrospectiveGeneralReporting = React.memo(() => {
    //Selected Payload Values:
    const [selectDate, setSelectDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'))


    //Daily Report
    const [monthlyReporting, setMonthlyReporting] = useState([]);

    //Unit Suite Type --> Can change this all to one state variable --> look into this
    const [bedroomOne, setBedroomOne] = useState(false);
    const [bedroomTwo, setBedroomTwo] = useState(false);
    const [bedroomThree, setBedroomThree] = useState(false);
    const [bedroomJuniorTwo, setBedroomJuniorTwo] = useState(false);
    const [studio, setStudio] = useState(false);

    //Holds current suite type when changing the date
    const [currentSuiteType, setCurrentSuiteType] = useState(null);


    //Omit Corporate
    const [omitCorporate, setOmitCorporate] = useState([]);
    const [omitCorporateData, setOmitCorporateData] = useState([]);

    //Filter by building id
    const [includeBuilding, setIncludeBuilding] = useState([]);
    const [includeBuildingData, setBuildingData] = useState([]);

    const [loading, setLoading] = useState(false);

    //Toggle for Definition
    const [toggleCalculationSummary, setToggleCalculationSummary] = useState(false);

    const dispatch = useNotification();

    useEffect(() => {
       searchDate();
        //Fetch General Report
      //  searchDate(dayjs().format('YYYY-MM-DD'));
    }, []);

  //   useEffect(() => {
  //      //Fetch General Report
  //     searchDate();
  //  }, [omitCorporate, includeBuilding]);

    const HandleError = (type: string, title: string, message: string,) => {
      dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
      })
    }

    const updateCorporates = async() => {
      //Call the Get All Route ofr Corporates:
      const allCorporates = await fetchGetJSON(`${Constants.API_PATH_CORP_GET_ALL}?startDate=${selectDate}&endDate=${endDate}`)

      if (allCorporates.status === 'Success') {
        //Create an array from allCorporates that stores the id as a value and the label as the corporate name:
        const formatCorporateData = allCorporates.data.map((corporate:any) => {
          return {
            value: corporate.id,
            label: corporate.corporate_name,
          }
        })

        //Sort formatCorporateData alphabetically:
        formatCorporateData.sort((firstCorp:any, secondCorp:any) => firstCorp.label.localeCompare(secondCorp.label))

        setOmitCorporateData(Array.from(new Set(formatCorporateData)));
      }
    }

    const updateBuildings = async() => {
      //Call the Get All Route ofr Corporates:
      const allBuildings = await fetchGetJSON(`${Constants.API_PATH_BUILDING_GET_ALL}?startDate=${selectDate}&endDate=${endDate}`)

      if (allBuildings.status === 'Success') {
        //Create an array from allCorporates that stores the id as a value and the label as the corporate name:
        const formatBuilding = allBuildings.data.map((building:any) => {
          return {
            value: building.id,
            label: building.building_name,
          }
        })

        //Sort formatCorporateData alphabetically:
        formatBuilding.sort((firstBuilding:any, secondBuilding:any) => firstBuilding.label.localeCompare(secondBuilding.label))
        
        setBuildingData(Array.from(new Set(formatBuilding)));
      }
    }

    const handleSelectChange = (event:any, selectedOptions: any) => {
      const selectedValues = selectedOptions.map((option:any) => option.value);
      setOmitCorporate(selectedValues);
    };

    const handleBuildingChange = (event:any, selectedOptions: any) => {
      const selectedValues = selectedOptions.map((option:any) => option.value);
      setIncludeBuilding(selectedValues);
    };

    const searchDate = async(suiteType?:string) => {
        if(endDate < selectDate) {
          HandleError('Error', 'Error', 'End Month must be a greater date than the Start Month')
          return
        }
        updateCorporates();
        updateBuildings();

        setLoading(false);

        HandleError('Loading', 
          'Loading Please Wait',
          `Fetching Reporting from ${selectDate} to ${endDate} ${suiteType ? `for ${suiteType}` : currentSuiteType ? `for ${currentSuiteType}` : 'for all suite types'}`
        );
        setSelectDate(selectDate);

        let queryStringCorporateIDs =  omitCorporate ? '' : '&building_id'
        for(const corporateID of omitCorporate) {
          queryStringCorporateIDs+=`&omitCorporate=${corporateID}`
        }

        let queryStringBuildingIDs = includeBuilding ? '' : '&building_id'
  
        for(const buildingID of includeBuilding) {
          queryStringBuildingIDs+=`&building_id=${buildingID}`
        }

        let querySuiteType = ''
        if(suiteType) {
          querySuiteType = suiteType != 'all suite types' ? `?suiteType=${suiteType}`:`?suiteType=${currentSuiteType}`
        } else {
          querySuiteType = `?suiteType`
        }

        const reportingData = await fetchGetJSON(`${Constants.API_PATH_MONTHLY_REPORTING}${querySuiteType}&selectedDate=${selectDate}&endDate${endDate ? `=${endDate}` : ''}${queryStringCorporateIDs}${queryStringBuildingIDs}`
            // Constants.API_PATH_MONTHLY_REPORTING, {
            //     selectedDate: selectDate,
            //     endDate: endDate,
            //     suiteType: suiteType ? suiteType != 'all suite types' ? suiteType : null : currentSuiteType,
            //     omitCorporate: omitCorporate,
            //     building_id: includeBuilding,
            // }
        );
        setLoading(true);
        HandleError('Success', 'Success',`Fetched Reporting from ${selectDate} to ${endDate} ${suiteType ? `for ${suiteType}` : currentSuiteType ? `for ${currentSuiteType}` : 'for all suite types'}`);
        // //Set Monthly:
        setMonthlyReporting(reportingData);
    }


    const handleButtonClick = (selectedVariable:any, selectedVariableSetter:any, suiteType:string) => {
      setBedroomOne(false);
      setBedroomTwo(false);
      setBedroomThree(false);
      setBedroomJuniorTwo(false);
      setStudio(false);
      
      //Assign State Variables:
      selectedVariableSetter(!selectedVariable);
      // setCurrentSuiteType(suiteType);

      //Fetch Data
      //Conditional is if the selected button is false 
      console.log(!selectedVariable);
      if(!selectedVariable == false) {
        setCurrentSuiteType(null);
        searchDate('all suite types');
      } else {
        setCurrentSuiteType(suiteType);
        searchDate(suiteType);
      }
    };
    
    return(
        <div>
            <div className="font-display bg-white lg:rounded-tl-lg">
                <div className="mt-10 text-center text-lg"><h1 className="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-1xl">Retrospective Reporting</h1></div>
                <div className="mt-10 text-center text-lg"><h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl">{`Monthly Reporting for Occupancy, ADR, and RevPar for ${dayjs(selectDate).startOf('month').format('MMMM DD YYYY')} to ${dayjs(endDate).endOf('month').format('MMMM DD YYYY')}`}</h1></div>
                <div className="text-center text-lg"><p className="mb-4 text-m leading-none tracking-tight text-gray-900 md:text-m lg:text-m">Only <strong>confirmed</strong> bookings are included in this report which may lead to <strong>discrepancies</strong> between retrospective and prospective reports.</p></div>
                <hr/>
                <p className="mt-5 text-center text-lg"><strong>Please Note:</strong> Any reporting from the current month of <strong>{dayjs(selectDate).format('MMMM')}</strong> and <strong>onwards</strong> should not be viewed as accurate. <br/>Please refer to the <u>Prospective Reporting</u> page for accurate numbers if observing from <strong>{dayjs(selectDate).format('MMMM')}</strong> and <strong>onwards.</strong></p>
                <p className="mt-5 text-center text-lg"><strong>IMPORTANT: </strong>Invoices used in the retrospective report to calculate costs are <strong>not limited</strong> by confirmed or tentative bookings.<br/> Each invoice is calculated based on their start date/due date and their end date only.<br/><strong>For example:</strong> This includes invoices for <u>tentative bookings</u> or paid invoices of <u>cancelled bookings.</u></p>
            </div>
            <div className="flex flex-row mb-2 sm:pr-2 justify-center mt-5">
                <span className={" transition-all duration-600 text-green-500 text-s mr-3 pl-3"}><u>Toggle for Calculation Explanation</u></span>
                <ToggleSwitch
                    id="selectDate"
                    handleStateToggle={() => setToggleCalculationSummary(!toggleCalculationSummary)}
                    className="justify-center align-center"
                    size="lg"
                    onoff={toggleCalculationSummary}
                />
            </div>
            {
              toggleCalculationSummary &&
              <div className="font-display bg-white lg:rounded-tl-lg">
                <p className="mt-5 text-center text-lg"><strong>Total Rent Calculation: </strong>Invoices are calculated by finding their rate and adding that amount for the requested month.<br/><strong>For example:</strong> Given an invoice that is from November 15th to December 15th that has a total amount of <span className="text-green-500">$3000</span> and our selected month is <strong>December</strong></p>
                <p className="mt-5 text-center text-lg">The <strong>invoice's rate</strong> would first be calculated: <span className="text-green-500">$3000</span> / (Day difference between Nov 15th to Dec 15th).<br/> Once that is calculated (<span className="text-green-500">$100/day</span>), the invoice's rate would be added for every day that is in December: <span className="text-green-500">$100</span> on Dec 1st, <span className="text-green-500">$100</span> on Dec 2nd, ..etc. up to Dec 15th.</p>
              </div>
            }

            <label className="block mt-5 flex items-center justify-center">
              <div className="flex">
                <span className="text-gray-600 text-lg mr-3">Start Month: </span>
                <div className="w-1/7">
                  <input
                      id="start date"
                      onChange={(input) => {
                        setSelectDate(input.target.value);
                      }}
                      type="month"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                      defaultValue={dayjs().startOf('month').format('YYYY-MM')}
                  />
                </div>
              </div>
              <div className="flex ml-3">
                <span className="text-gray-600 text-lg mr-3">End Month: </span>
                <div className="w-1/7">
                  <input
                      id="end date"
                      onChange={(input) => {
                        setEndDate(input.target.value);
                      }}
                      type="month"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                      defaultValue={dayjs().endOf('month').format('YYYY-MM')}
                  />
                </div>
              </div>
              <button
                className="ml-3 border px-3 hover:bg-green-400 bg-green-500 text-white my-3"
                onClick={() => searchDate()}
                >
                Update Report
              </button>
            </label>
            <span className="py-1 font-medium margin text-gray-500">
              Filter by Suite Type:
            </span>
            <div className="flex flex-row flex-wrap mb-5">
              <button 
                  key={'Studio'}
                  className={
                    (studio ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                    " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                  }
                  onClick={(event) => handleButtonClick(studio, setStudio, 'Studio')}
                >
                  {`Studio`}
              </button>
              <button 
                key={'1 Bedroom'}
                className={
                  (bedroomOne ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomOne, setBedroomOne, '1 Bedroom')}
              >
                {`1 Bedroom`}
              </button>
              <button 
                key={'2 Bedroom'}
                className={
                  (bedroomTwo ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomTwo, setBedroomTwo, '2 Bedroom')}
              >
                {`2 Bedroom`}
              </button>
              <button 
                key={'Junior 2 Bedroom'}
                className={
                  (bedroomJuniorTwo ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomJuniorTwo, setBedroomJuniorTwo, 'Junior 2 Bedroom')}
              >
                {`Junior 2 Bedroom`}
              </button>
              <button 
                key={'3 Bedroom'}
                className={
                  (bedroomThree ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={(event) => handleButtonClick(bedroomThree, setBedroomThree, '3 Bedroom')}
              >
                {`3 Bedroom`}
              </button>
            </div>
            <div className="flex">
              <span className="py-1 font-medium margin text-gray-500 mr-3">
                Filter Out Corporates:
              </span>
            </div>       
            <div className="w-6/12 mb-6">
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={omitCorporateData}
                    getOptionLabel={(option) => option.label}
                    onChange={handleSelectChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Corporate to Omit"
                      />
                    )}/>
                </ThemeProvider>
            </div>

            <div className="flex">
              <span className="py-1 font-medium margin text-gray-500 mr-3">
                Filter By Building:
              </span>
            </div>       

            <div className="w-6/12 mb-6">
                <ThemeProvider theme={buildingTheme}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={includeBuildingData}
                    getOptionLabel={(option) => option.label}
                    onChange={handleBuildingChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Buildings to Search"
                      />
                    )}/>
                </ThemeProvider>
            </div>

            {
              !loading ?             
              <div className="flex bg-white mt-20 px-3 py-5 pb-1 justify-center">
                <Loading size="3x-large" type="big-spiral" />
              </div > :
            <>
            {/* Occupancy Report */}
            <div className="mt-10 text-lg"><h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl  ">Occupancy Report:</h1></div>
            <div className="flex md:flex-row w-full h-full mb-3 justify-around sm:flex-col flex-wrap">
              {
                monthlyReporting.map((reportingData) => (
                  <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5 mb-3">
                    <StatBubbles
                        key={reportingData.metrics.occupancy}
                        data={+reportingData.metrics.occupancy.toFixed(2)}
                        title={`Occupancy Report for ${reportingData.month}`}
                        subtitle={`Total Occupied Nights for ${reportingData.month}: ${reportingData.metrics.totalOccupiedRooms.toLocaleString('en-US')}`} 
                        subtitle2={`Total Available Rooms for ${reportingData.month}: ${reportingData.metrics.totalAvailableRooms.toLocaleString('en-US')}`}
                        button={"Graph"}
                    />
                  </div>
                ))
              }
            </div > 

            {/* ADR Report */}
            <div className="mt-10 text-lg"><h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl  ">ADR Report:</h1></div>
            <div className="flex md:flex-row w-full h-full mb-3 justify-around sm:flex-col flex-wrap">
              {
                monthlyReporting.map((reportingData) => (
                  <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5 mb-3">
                    <div className="flex flex-col appear bg-white shadow-md py-5 px-5 rounded-lg h-full">
                        <div className="flex flex-col ">
                                <span className="text-green-500 text-4xl leading-none font-bold  ">
                                ${reportingData.metrics.adr.toFixed(2).toLocaleString('en-US')}
                                </span>

                                <span className="text-green-700  text-base pt-1">Total Average Daily Rate</span>
                                <span className=" text-sm font-thin text-gray-400 ">
                                    {`ADR for ${reportingData.month}`} <br/> 
                                    Total Rent Revenue for {reportingData.month}<br/> (This value includes implicit costs): <span className="text-green-700">${Math.trunc(reportingData.metrics?.totalRevenue).toLocaleString('en-US')}</span><br/>
                                    <hr className="my-3"/>
                                    Explicit Cleaning Fee for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData?.explicitCosts[0]).toLocaleString('en-US')}</span><br/>
                                    Explicit Parking Fee for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData?.explicitCosts[1]).toLocaleString('en-US')}</span><br/>
                                    {/* Explicit Other Fee for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData?.explicitCosts[2]).toLocaleString('en-US')}</span> */}
                                </span>
                        </div>
                        <div className="flex flex-row w-full h-full mb-3 my-10 justify-around">
                            <div className="text-green-700">
                                ADR calculated by taking total monthly revenue divided by Occupied Rooms
                            </div>
                        </div >
                    </div>
                  </div>
                ))
              }
            </div >

            {/* RevPar Report */}
              <div className="mt-10 text-lg"><h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl  ">RevPar Report:</h1></div>
              <div className="flex md:flex-row w-full h-full mb-3 justify-around sm:flex-col flex-wrap">
                {
                  monthlyReporting.map((reportingData) => (
                    <div className="mr-3 2xl:w-1/5 xl:w-1/4 md:mt-0 sm:mt-5 mb-6">
                      <div className="flex flex-col appear bg-white shadow-md py-5 px-5 rounded-lg h-full">
                          <div className="flex flex-col ">
                                  <span className="text-green-500 text-4xl leading-none font-bold  ">
                                  ${reportingData.metrics.revpar.toFixed(2).toLocaleString('en-US')}
                                  </span>

                                  <span className="text-green-700  text-base pt-1">Total RevPar</span>
                                  <span className=" text-sm font-thin text-gray-400 ">
                                      {`RevPar for ${reportingData.month}`}<br/> 
                                      Total Rent Revenue for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData.metrics?.totalRevenue).toLocaleString('en-US')}</span><br/>
                                      <hr className="my-3"/>
                                      Explicit Cleaning Fee for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData?.explicitCosts[0]).toLocaleString('en-US')}</span><br/>
                                      Explicit Parking Fee for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData?.explicitCosts[1]).toLocaleString('en-US')}</span><br/>
                                      {/* Explicit Other Fee for {reportingData.month}: <span className="text-green-700">${Math.trunc(reportingData?.explicitCosts[2]).toLocaleString('en-US')}</span> */}
                                  </span>
                          </div>
                          <div className="flex flex-row w-full h-full mb-3 my-10 justify-around">
                              <div className="text-green-700">
                                RevPar calculated by taking Monthly ADR multiplied by Monthly Occupancy
                              </div>
                          </div >
                      </div>
                    </div>
                  ))
                }
              </div > 
              </>
            }
            {/* Invoice Reporting */}
            <div className="mt-10 text-lg">
              <h1 className="text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl  ">Invoice Reporting:</h1>
            </div> 
            {
                  monthlyReporting.map((reportingData) => (
                    <div>
                      <h1 className="my-3 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl ">{reportingData.month}</h1>
                      {tableCreation(reportingData.invoiceReporting)}
                    </div>
                  ))
                }
        </div>
    )
})

const StatBubbles = React.memo((props: any)  => {
    let series = [props.data];
    const radialOptions = {
        colors: ["#10b981"],
        dataLabels: {
          enabled: false,
        },

        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: true,
                offsetY: -2,
                fontSize: "20px",
                color: "#047857",

                fontFamily: "Circular Std, sans-serif",
              },
            },
          },
        },
    }
  
    let regularStyling = "flex flex-col items-left justify-left mt-5";
    if (props.button === "Graph") {
      regularStyling = "flex flex-col items-left justify-right";
    }
  
    return (
      <div className="flex flex-col appear  bg-white shadow-md   py-5 px-5 rounded-lg h-full">
        <div className="flex flex-col ">
          <span className="text-green-500 text-4xl leading-none font-bold  ">
            {props.data}%
          </span>
  
          <span className="text-green-700  text-base pt-1">{props.title}</span>
          <span className=" text-sm font-thin text-gray-400">
            {props.subtitle}
          </span>
          <span className=" text-sm font-thin text-gray-400">
            {props.subtitle2}
          </span>
        </div>
  
        <div className={regularStyling}>
            <div className=" w-full h-full transform -translate-x-5 -translate-y-5">
                <div className="relative ml-10">
                    <Chart
                        options={radialOptions}
                        series={series}
                        type="radialBar"
                        height="225px"
                    />
                </div>
            </div>
        </div>
      </div>
    );
  })

//Table Creation:
const tableCreation = (
  invoiceReporting: RetrospectiveInvoiceBreakdown[]
): JSX.Element => {
  invoiceReporting.sort((invoiceReport1, invoiceReport2) => {
    let startDateA = dayjs(invoiceReport1.start_date);
    let startDateB = dayjs(invoiceReport2.start_date);
  
    // Check for invalid dates
    if (isNaN(startDateA) && isNaN(startDateB)) {
      return 0; // Both dates are invalid
    } else if (isNaN(startDateA)) {
      return 1; // a is invalid, so it should come after b
    } else if (isNaN(startDateB)) {
      return -1; // b is invalid, so it should come after a
    } else {
      return startDateB - startDateA; // Both dates are valid, sort in descending order
    }
  })
  return (
    <TableContainer sx={{ overflow: "visible" }}>
      <Table
        sx={{ minWidth: 700, height: "auto" }}
        aria-label="customized table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Invoice ID</StyledTableCell>
            <StyledTableCell>Invoice Lines ID</StyledTableCell>
            <StyledTableCell>Start Date</StyledTableCell>
            <StyledTableCell>End Date</StyledTableCell>
            <StyledTableCell>Due Date</StyledTableCell>
            <StyledTableCell>Cost</StyledTableCell>
            <StyledTableCell>Entry</StyledTableCell>
            <StyledTableCell>Invoice Daily Rate</StyledTableCell>
            <StyledTableCell>Invoice Length</StyledTableCell>
            <StyledTableCell>Line Type</StyledTableCell>
            <StyledTableCell>Invoice Type</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
              invoiceReporting.map((invoiceReport:RetrospectiveInvoiceBreakdown) => {
                return (
                <TableRow>
                  <TableCell>
                  {invoiceReport.invoice_id}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.invoice_Lines_id}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.start_date}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.end}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.due}
                  </TableCell>
                  <TableCell>
                    ${invoiceReport.cost.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.entry}
                  </TableCell>
                  <TableCell>
                    ${invoiceReport.invoice_daily_rate.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.invoice_length}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.line_type}
                  </TableCell>
                  <TableCell>
                    {invoiceReport.invoice_types}
                  </TableCell>
                </TableRow>)
              })
            }
        </TableBody>
      </Table>
    </TableContainer>
  );
};