import React, { Component } from "react";

export class StatsSquare extends Component<any, { data: any, title: any, subInfo: any }> {

  constructor(props: any) {
    super(props);
    this.state = {
      data: this.props.data,
      title: this.props.title,
      subInfo: this.props.subInfo,
    }
  }

  render() {
    return (
      <div className="flex flex-col py-4  items-center rounded-md bg-gray-50 border shadow-md  ">
        <span className=" appear text-indigo-400 text-4xl font-black font-sofia leading-none">{this.props.data}</span>
        <span className="text-gray-700  font-semibold font-europe mt-2">{this.props.title}</span>
        {this.props.subInfo}
      </div>
    );
  }
}
