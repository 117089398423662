import { Component } from "react";
import Chart from "react-apexcharts";
import { OccupancyPercentage } from "../../../utils/Types";
import Loading from "../../LoadingWidget";
type Props = {
  occupancyData: OccupancyPercentage[];
};

type State = {
  options: object;
  series: Array<any>;
};

class CleaningPie extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let currentDate: Date = new Date();
    let lastMonth = new Date().setMonth(currentDate.getMonth() - 1);
    let TwoMonths = new Date().setMonth(currentDate.getMonth() - 2);
    let ThreeMonths = new Date().setMonth(currentDate.getMonth() - 3);
    let FourMonths = new Date().setMonth(currentDate.getMonth() - 4);

    const DateToString = (date: any) => {
      let month = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
      return month;
    };

    this.state = {
      // cleaners: ['Evelyn J.', 'Ely A.', 'Sara S.', 'Ben W. ', 'Flavia B.', 'Alexandre A.', 'Ada V.'],

      options: {
        chart: {
          id: "basic-bar",
          type: "bar",
          stacked: true,
          fontFamily: "Sofia-Pro , Arial, sans-serif",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [
            DateToString(FourMonths),
            DateToString(ThreeMonths),
            DateToString(TwoMonths),
            DateToString(lastMonth),
            DateToString(currentDate),
          ],
        },

        yaxis: {
          title: {
            text: "Occupancy",
          },
          labels: {
            formatter: function (y: any) {
              return y.toFixed(0) + "%";
            },
          },
        },

        colors: ["#10b981", "#6770d7"],

        plotOptions: {
          bar: {
            columnWidth: "50%",
            borderRadius: 12,
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return val + "%";
          },
        },

        grid: {
          show: true,
          borderColor: "#f6f8f9",
        },

        tooltip: {
          enabled: true,
        },
      },

      series: [
        {
          name: "Retail",
          data: [],
        },
        {
          name: "Corporate",
          data: [],
        },
      ],
    };
  }

  componentDidUpdate = async (previousProps: Props) => {
    if (this.props !== previousProps) {
      let retailList: number[] = [];
      let corporateList: number[] = [];

      for (let i = 0; i < this.props.occupancyData.length; i++) {
        let thisOccupancy = this.props.occupancyData[i];
        retailList.push(Math.round(thisOccupancy.retail * 100));
        corporateList.push(Math.round(thisOccupancy.corporate * 100));
      }

      let newSeries = [
        {
          name: "Retail",
          data: retailList.reverse(),
        },
        {
          name: "Corporate",
          data: corporateList.reverse(),
        },
      ];

      this.setState({
        series: newSeries,
      });
    }
  };

  render() {
    return (
      this.state.series[0].data.length > 0 ?
        <div className=" bg-white shadow-md rounded-lg h-96  mt-3 px-3 py-5 pb-1">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            width="100%"
            height="100%"
          />
        </div> :
        <div className="flex bg-white shadow-md rounded-lg h-96  mt-3 px-3 py-5 pb-1 justify-center">
            <Loading size="large" type="pacman" />
          </div >

          );
  }
}

          export default CleaningPie;
