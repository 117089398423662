import React, { Component, forwardRef, useImperativeHandle } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { formatFullDate, formatStringDate } from "../../utils/DateFormat";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { IdentityStore } from "aws-sdk";
import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";
import { ConsoleLogger } from "@aws-amplify/core";
import { getUserList } from "../../api-requests/api-gateway";
import { LoadingGeneral, LoadingInvoice } from "../../utils/LoadingComps/LoadingInvoice"
import { transactionType, listview } from "../../models/modelList";
import PageSelector from "../../utils/PageSelector";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import Modal from "../Modal";
const axios = require("axios");
// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  ErrorNote: any;
  handleCancelModal: any;
  deletedTransactionId: Number;
};

type State = {
  blackout: boolean;
  transactions: transactionType[]; // This is the list for all the invoice objects so I can use them to populate the list
  search: string;
  filter_expense: boolean;
  filter_rental: boolean;
  filter_managed: boolean;
  refresh_quick_view: boolean;
  users: any[];
  loaded: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  settingId: number;
  file: boolean;
  selectedTransactionId: number;
};

const fileMap = new Map<Number, any>();

class TransactionList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      transactions: [],
      search: "",
      filter_expense: false,
      filter_rental: false,
      filter_managed: false,
      refresh_quick_view: false,
      users: [],
      loaded: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      settingId: -1,
      file: true,
      selectedTransactionId: -2,
    };
  }

  // Toggle popup function
  handleClick = (id: number) => {
  };

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })

  }

  setPerPage = async (val: number) => {
    await this.setState({ perpage: val, loaded: false, }, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState({
          curpage: this.state.totalpages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // This is where all the information is retrieved from the api and loaded into the state
  componentDidMount = async () => {
    let userList = await getUserList();
    this.setState({
      users: userList.Users
    })
    await store.dispatch({ type: "transactionListUpdate", listUpdate: this.updateList });
    await this.updateList();
  };

  updateList = async (bookingId?: string) => {
    // let allTransactions = await fetchGetJSON(Constants.API_PATH_TRANSACTION_GET_ALL);
    let filter: any = {}
    filter['search'] = this.state.search;
    const allTransactions: listview<transactionType[]> = await fetchRequestJSON("GET", Constants.API_PATH_LISTVIEW_TRANSACTIONS, { filters: filter, page: this.state.curpage, perpage: this.state.perpage })
    if (allTransactions.status != "Success") {
      return;
    }

    //Go through each transaction from the listView to see if the file exist 
    for (const transactions of allTransactions.data.rows) {
      await this.fileList(transactions.id)
    }

    this.setState({ totalpages: allTransactions.data.pages })
    this.setState({
      transactions: allTransactions.data.rows,
      loaded: true,
    })
  };



  handleChange = async (event: any) => {
    let NotificationProvider = this.props.ErrorNote
    const form = new FormData();
    form.append("id", event.target.id);
    form.append("category", "expenses");
    form.append("file", event.target.files[0]);
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_AWS_API_GATEWAY_LINK}${Constants.API_PATH_FILES_UPLOAD}`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      })
    }
    catch (err) {
      //handle error
      console.log(err);
      NotificationProvider("Error", err)
    }
    NotificationProvider("Success", "Successfully Updated File")
    await this.updateList();
  };

  checkFile = async (id: any) => {
    const msg = await this.fileList(id);
    return msg;
  };

  fileList = async (id: any) => {
    const fileInvoice = await fetchGetJSON(`${Constants.API_PATH_FILES_LIST}?id=${id}&category=invoice`);
    if (fileInvoice.status === 'Success' && fileInvoice?.data.Contents.length > 0) {

      let content: string = fileInvoice.data.Contents[0].Key;

      if (
        fileInvoice.data.Contents.length > 1 &&
        fileInvoice.data.Contents[1].Key.length > content.length
      ) {
        content = fileInvoice.data.Contents[1].Key;
      }

      //update our fileList
      fileMap.set(id, content);
    } else {
      fileMap.set(id, false);
    }
  };

  handleDownload = async (key: string) => {
    let NotificationHandler = this.props.ErrorNote;
    console.log(key)
    const request = await fetchGetJSON(
      `${Constants.API_PATH_FILES_DOWNLOAD}?key=${key}`,
      // { key: key }
    );
    window.open(request.data);
    NotificationHandler("Success", 'Successfully Downloaded')
    this.updateList();
  };

  // Delete the file, calls the API route for it
  handleDelete = async (key: string) => {
    // console.log("delete");
    let NotificationHandler = this.props.ErrorNote;
    if (window.confirm("Are you sure you wish to delete this file?")) {
      await fetchRequestJSON("POST", Constants.API_PATH_FILES_DELETE, {
        key: key,
      });
      this.updateList();
    }
    NotificationHandler("Success", 'Succesfully Deleted', 'Please wait a moment until uploading a file')
  };


  render() {
    if (this.state.selectedTransactionId == this.props.deletedTransactionId) {
      this.updateList()
      this.setState({ selectedTransactionId: -2 })
    }
    // const { history } = this.props;
    let Blackout = this.state.blackout;

    let blackoutStyles = "transition-all duration-200";
    let Warnings = "";
    let blackoutToggle = (
      <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"} />
    );

    if (Blackout === true) {
      blackoutStyles = "transition-all duration-200 opacity-10 ";
      Warnings = "Do Not Book";
      blackoutToggle = (
        <FaPowerOff
          className={"text-2xl text-green-500 hover:text-green-300"}
        />
      );
    }

    let TableRows = <></>;
    var rows = [];
    let today = new Date();
    // let testDueDate = new Date(2021, 12, 8, 4, 20);
    var loading: any = [];
    for (let i = 0; i < 10; i++) { loading.push(<LoadingInvoice />) }
    let altBg = "";

    // let mobileItems = ["ID", "Due Date", "Cost", "Marked By", "Payment Method"];
    let sorted: transactionType[] = this.state.transactions;
    console.log(sorted)
    sorted.sort((a, b) => b.id - a.id);
    // Loops through the state invoice list (after it is loaded) and displays them
    for (var i = 0; i < sorted.length; i++) {
      // if (!((this.state.search == "") || Number(this.state.search) === Number(sorted[i].id))) { continue; }
      let SpecialStyle = "text-gray-700";
      let SpecialStyle2 = "text-gray-700";
      let Special = <></>;
      let transactionId = sorted[i].id;
      let submitted = sorted[i].submitted_by;
      let user = this.state.users.find((userObj) => userObj.Username == submitted)
      if (user) {
        let name = user.Attributes.find((attr: any) => attr.Name == "name")
        if (name) {
          console.log(name)
          submitted = name.Value
        }
      }

      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }

      //This will check if we receive back a file or not based on id
      const file: any = fileMap.get(sorted[i].id);
      const fileName = fileMap.get(sorted[i].id);

      TableRows = (
        <tr
          key={i.toString()}
          className={
            altBg +
            " grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"
          }
        >
          {/* Suite Name */}

          {/* IMPORTANT */}
          {/* These onclick properties are set to a function passed from parent and are rendered at all times */}
          {/* This means I cant send it an id from the invoice object it will always call all of them and end up displaying the first one */}
          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-16 py-4 sm:pl-4"
            }
          >
            <span className="sm:hidden">ID: </span>
            {sorted[i].id}
          </td>

          {/* Suite Number */}

          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full sm:w-36 font-light text-sm text-gray-500 sm:px-1 sm:py-4"
            }
          >
            <span className="sm:hidden">Account: </span>
            <span className={SpecialStyle2}>{sorted[i].account}</span>{" "}
          </td>

          {/* Min. Stay */}

          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full sm:w-32 font-thin  text-gray-600 sm:px-1  py-2 sm:py-4"
            }
          >
            <span className="sm:hidden text-sm">Amount: </span>
            {formatAmountForDisplay(sorted[i].amount, "CAD", false)}{" "}
          </td>

          {/* Bedrooms */}

          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full sm:w-32 font-medium  text-gray-600 sm:px-1  py-0 sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">Submitted By: </span>{" "}
            {submitted}
          </td>

          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-28 font-normal  text-gray-400 sm:px-1 py-2  sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">
              Department:{" "}
            </span>
            {sorted[i].department}
          </td>

          {/* Type */}

          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-28 font-normal text-gray-400 sm:px-1 py-2  sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">
              Transaction Date:{" "}
            </span>
            {sorted[i].transaction_date.split("T")[0]}
          </td>

          {/* Booking Warnings */}

          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-28 font-normal text-gray-400 sm:px-1 py-2  sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">
              Unit ID:{" "}
            </span>
            {sorted[i].unit_id}
          </td>

          {/* Booking Warnings */}
          <td
            onClick={() => { }}
            className={
              blackoutStyles +
              "w-full text-sm sm:text-base sm:w-28 font-normal text-gray-400 sm:px-1 py-2  sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">
              Download:{" "}
            </span>
            {
              !file &&
              <input
                type="file"
                name="file"
                id={sorted[i].id.toString()}
                onChange={this.handleChange}
              />
            }
            {
              file &&
              (
                <div className="flex flex-col">
                  <div className="text-xs items-left mb-1">
                    {fileName.split("/").pop()}
                  </div>
                  <div className="flex flex-row">
                    <button onClick={() => this.handleDownload(fileName)} className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white ">
                      Download
                    </button>
                    <button onClick={() => this.handleDelete(fileName)} className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white ">
                      Delete
                    </button>
                  </div>
                </div>
              )
            }

          </td>

          <td className=" hidden  lg:table-cell lg:w-8 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              {
                (this.state.settingId == transactionId) && <button
                  className="w-32 rounded absolute ml-auto right-0 -mt-10 py-2 text-center shadow-xl bg-gray-100"
                  onClick={() => {
                    this.props.handleCancelModal(transactionId)
                    this.setState({ selectedTransactionId: transactionId })
                    this.updateList();
                  }}
                >
                  Delete
                </button>
              }
              <FiSettings
                onClick={() => {
                  if (this.state.settingId != transactionId) {
                    this.setState({ settingId: transactionId })
                  } else {
                    this.setState({ settingId: -1 })
                  }
                }}
                className={
                  blackoutStyles +
                  "text-gray-700 text-2xl mr-2 hover:text-gray-500"
                }
              />
            </div>
          </td>

        </tr >
      );

      // if(sorted[i].exists){
      rows.push(TableRows);
      // }
    }

    return (
      < div className=" slide-in-blurred-top  font-display py-4" >
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col sm:flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full sm:w-1/4  py-1 mb-3 sm:mb-0 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                onChange={this.handleInputChange}
                className="text-sm searchBox"
                placeholder="Search"
                id="search"
                value={this.state.search}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => { this.updateList() }}>Search</button>

            <span className="py-1 sm:px-4 font-medium margin text-gray-500 self-start ">
              Filters:
            </span>
            <div className="flex flex-row flex-wrap self-start">
              <button
                className={
                  (this.state.filter_expense ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_expense: !this.state.filter_expense,
                  })
                }}
              >
                Expenses
              </button>
              <button
                className={
                  (this.state.filter_rental ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_rental: !this.state.filter_rental,
                  })
                }}
              >
                Rentals
              </button>
              <button
                className={
                  (this.state.filter_managed ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_managed: !this.state.filter_managed,
                  })
                }}
              >
                Managed
              </button>
            </div>
            <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="hidden sm:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 pl-4">ID</th>
              <th className="py-2  ">Account</th>
              <th className="py-2  ">Amount</th>
              <th className="py-2  ">Submitted By</th>
              <th className="py-2  ">Department</th>
              <th className="py-2  ">Transaction Date</th>
              {/* Book Warnings */}
              <th className="py-2  ">Unit ID</th>
              <th className="py-2"> Download</th>
              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 || !this.state.loaded ? <LoadingGeneral count={10} cols={7} empty={this.state.loaded} /> : rows}</tbody>
        </table>
      </div >
    );
  }
}

export default withRouter(withHooksHOC(TransactionList));
