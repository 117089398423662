import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DashBar from "../DashBar";


type Props = {
  userData: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
};

class ArchiveForms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-auto lg:rounded-tl-lg py-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-14  ">
          <div className=" flex flex-row justify-between font-circular pr-2  w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""}/>

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Cleaning Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          {/* Heading Info Area  */}

          <div className="px-2 my-8 ">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between w-full mb-2  items-end">
                <span className=" pl-1 text-gray-700 font-medium text-2xl">
                  Form Archive
                </span>
                <div className="flex flex-row items-center text-lg  px-5 py-1  ">
                  <button className="bg-white rounded-full shadow-md h-6 w-6 ">
                    <IoIosArrowBack className="pl-1" />
                  </button>

                  <span className="px-4 text-gray-500">{currentMonth}</span>
                  <button className="bg-white rounded-full shadow-md h-6 w-6 flex flex-row items-center justify-center align-center">
                    <IoIosArrowForward className="text-sm" />
                  </button>
                </div>
              </div>

              <div className="flex flex-row w-full align-center items-center my-2">
                {/* Search Bar */}
                <div className="flex flex-row border bg-white rounded-sm w-1/4  py-1">
                  <MdSearch className="text-xl text-gray-400 mx-1" />
                  <input
                    className="text-sm focus:border-0 focus:outline-none "
                    placeholder="Search..."
                  ></input>
                </div>
                <span className="py-1 px-4 font-medium margin text-gray-500 ">
                  Filters:
                </span>

                <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
                  All
                </button>
                <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
                  Regular
                </button>
                <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
                  Turnover
                </button>
              </div>
            </div>

            <div className=" mt-3 max-h-screen w-auto overflow-y-auto shadow-md rounded-xl  scroll-hide ">
              <table
                style={{ borderSpacing: "0" }}
                className="border-separate  relative w-full table-auto bg-white overflow-x-scroll     "
              >
                <thead className="border-b border-gray-400 ">
                  <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                    <th className="sticky top-0 w-24 py-4 pl-5  font-thin border-b border-gray-300 ">
                      Form ID
                    </th>
                    <th className="sticky top-0  w-48  py-3 font-thin border-b border-gray-300 ">
                      Name
                    </th>
                    <th className=" sticky top-0 w-48 py-3 font-thin border-b border-gray-300">
                      Date
                    </th>
                    <th className=" sticky top-0   py-3 font-thin border-b border-gray-300 ">
                      Suite
                    </th>
                    <th className=" sticky top-0  py-3 font-thin border-b border-gray-300 ">
                      Type
                    </th>
                    <th className=" sticky top-0  py-3 font-thin border-b border-gray-300 "></th>
                    <th className=" sticky top-0   py-3 font-thin border-b border-gray-300 "></th>
                    <th className="sticky top-0  text-right   py-3 font-thin border-b border-gray-300 ">
                      {" "}
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y">
                  <InvoiceList
                    length={24}
                    viewInvoice={this.handleInvoiceView}
                    todaysDate={today}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArchiveForms;

export function InvoiceList(props: any) {
  let currentDate = props.todaysDate;
  let testDueDate = new Date(2021, 7, 8, 4, 20);
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;
  let OverDueStyle = "text-gray-700";
  let OverDueStyle2 = "text-gray-700";
  let OverDue = <></>;
  let { path, url } = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;

  const handleNext = (event: any) => {
    event.preventDefault();
    history.push(`${path}/formid`);
  };

  for (var i = 0; i < length; i++) {
    if (testDueDate > currentDate) {
      OverDueStyle = "text-white text-sm  w-72";
      OverDueStyle2 = "text-red-400 mr-10";
      OverDue = (
        <span className="bg-red-500 py-1 px-4 rounded-full">Overdue</span>
      );
    }

    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    Payments = (
      <tr
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="py-4 pl-5 text-purple-400 font-thin ">{i}</td>
        <td className={" py-3 font-thin  "}>Riley Densmore</td>
        <td className="py-3 text-gray-400 font-thin ">
          {formatFullDate(testDueDate)}
        </td>
        <td className="py-3  font-thin ">Adelaide C</td>
        <td className=" text-green-600  ">Turn Over</td>
        <td className=" "></td>
        <td className=" "></td>
        <td className=" text-right ">
          <button
            onClick={handleNext}
            className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
          >
            View Form
          </button>
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}

function CleanDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}
