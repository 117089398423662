export type State = {
  username: string;
  isLoggedIn: boolean;
  helperText: string;
};

const initialState: State = {
  username: "",
  isLoggedIn: false,
  helperText: "not authenticated",
};

type Action =
  | { type: "authSuccess"; message: "Successfully authenticated" }
  | { type: "authError"; message: "Error authenticating" }
  | { type: "logOut"; message: string };

export const authReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case "authSuccess":
      return {
        ...state,
        isLoggedIn: true,
        helperText: action.message,
      };
    case "authError":
      return {
        ...state,
        isLoggedIn: false,
        helperText: action.message,
      };
    case "logOut":
      return {
        ...state,
        isLoginSuccess: false,
      };
    default:
      return state;
  }
};
