import React, { Component } from "react";
import { MdSearch } from "react-icons/md";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { TiFlag } from "react-icons/ti";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { Constants } from "../../api-requests/apiLinkConstants";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../utils/PageSelector";

// Notes:
type Props = {
  handleState: any;
};

type State = {
  owners: UnitOwner[];
  search: string;
  loaded: boolean;
  curpage: number;
  rows_per_page: number;
  total_pages: number;
  authorization: boolean;
};

type UnitOwner = {
  first_name: string;
  last_name: string;
  phone_number: number;
  email: string;
  id: number;
  do_not_disturb: boolean;
};

class OwnerList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      owners: [],
      search: "",
      loaded: false,
      curpage: 1,
      rows_per_page: 10,
      total_pages: 0,
      authorization: true
    };
  }

  handleNext = (id: number) => {
    store.dispatch({ type: "ownerIdTransfer", id: id });
    console.log(id);
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: location.pathname + `/ownerprofile/`,
        search: `id=${id}`,
      });
  };

  handleModal = (id: string) => {
    this.props.handleState(id, false);
  };

  componentDidMount = async () => {
    store.dispatch({ type: "ownerListUpdate", listUpdate: this.updateList });
    this.updateList();
  };

  // Update the list of owners
  updateList = async () => {
    const filters = {
      search: this.state.search.trim()
    };
    const allOwners = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_UNIT_OWNERS}?page=${this.state.curpage}&perpage=${this.state.rows_per_page}&search${`${this.state.search}` ? `=${this.state.search.trim()}` : ''}`);
    this.setState({ total_pages: allOwners?.data?.pages ? allOwners.data.pages : []});
  
    let ownerList = [];
    if(allOwners.data.pages) {
      for (let i = 0; i < allOwners.data.rows.length; i++) {
        let ownerObj = {
          first_name: "",
          last_name: "",
          phone_number: 0,
          email: "_@gmail.com",
          id: -1,
          do_not_disturb: false,
        };
        ownerObj.first_name = allOwners.data.rows[i].first_name;
        ownerObj.last_name = allOwners.data.rows[i].last_name;
        ownerObj.email = allOwners.data.rows[i].email;
        ownerObj.phone_number = allOwners.data.rows[i].phone_number;
        ownerObj.id = allOwners.data.rows[i].id;
        ownerObj.do_not_disturb = allOwners.data.rows[i].do_not_disturb;
        ownerList.push(ownerObj);
      }
      this.setState({ owners: ownerList, loaded: true });
    } else {
      this.setState({
        loaded: true,
        authorization: allOwners.status == "Error Unauthorized" ? false : true
      })
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
    // this.updateList(this.state.search);
  };

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.total_pages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  };

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  };

  setPerPage = async (val: number) => {
    await this.setState({rows_per_page: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.total_pages) {
        this.setState({
          curpage: this.state.total_pages
        }, async () => {
          await this.updateList();
        })
      }
    })
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    // console.log("render msg")
    let Blackout = false;

    let blackoutStyles = "";
    let Warnings = "";
    let blackoutToggle = (
      <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"} />
    );

    if (Blackout) {
      blackoutStyles = " opacity-10 ";
      Warnings = "Do Not Book";
      blackoutToggle = (
        <FaPowerOff className={"text-xl text-green-500 hover:text-green-300"} />
      );
    }

    let TableRows = <></>;
    var rows = [];
    for (let i = 0; i < this.state.owners.length; i++) {
      // let name = this.state.owners[i].first_name + " " + this.state.owners[i].last_name;
      // if (!(this.state.search == "" || name.toLowerCase().includes(this.state.search.toLowerCase()))) { continue; }
      TableRows = (
        <tr className="border-t border-gray-400 w-full hover:bg-gray-100">
          <td
            className={" cursor-pointer w-auto lg:w-16 px-1 py-4"}
          >
            <span className="text-indigo-400">
           {this.state.owners[i].id}
            </span>
          </td>
      

          <td
            onClick={() => this.handleNext(this.state.owners[i].id)}
            className={" cursor-pointer lg:pl-4 px-1 py-4"}
          >
            <span>
              {this.state.owners[i].first_name} {this.state.owners[i].last_name}{" "}
          
            </span>
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.handleNext(this.state.owners[i].id)}
            className={
              " cursor-pointer  font-light text-sm text-gray-500 px-1 py-4 flex flex-row items-center"
            }
          >
            <MdLocalPhone className="mx-1 text-lg " />
            <h3 className="">{this.state.owners[i].phone_number}</h3>
          </td>

          {/* Min. Stay */}

          <td
            onClick={() => this.handleNext(this.state.owners[i].id)}
            className={" cursor-pointer font-thin text-gray-500 px-1  py-4"}
          >
            {this.state.owners[i].email}
          </td>

          {/* Bedrooms */}

          <td
            onClick={() => this.handleNext(this.state.owners[i].id)}
            className={"  cursor-pointer font-medium px-1  py-4"}
          >
            {this.state.owners[i].do_not_disturb ? <span className="text-green-500">True</span> : <span className="text-red-500">False</span>}
          </td>

          {/* Type */}

          {/* Booking Warnings */}

          <td
            onClick={() => this.handleNext(this.state.owners[i].id)}
            className=" cursor-pointer w-28 text-red-400 px-1 py-4 text-sm font-semibold"
          >
            {Warnings}
          </td>

          {/* Booking Warnings */}

          <td className=" w-32 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                className={
                  blackoutStyles +
                  "text-gray-700 text-2xl mr-2 hover:text-gray-500"
                }
              />
              {blackoutToggle}
            </div>
          </td>
        </tr>
      );
      // if(this.state.owners[i].exists){
      rows.push(TableRows);
      // console.log(i)
      // }
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row">
          {/* Left Side */}
          <div className="flex flex-col sm:flex-row w-1/3 align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full mb-6 py-1 sm:mb-0 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                className="text-sm searchBox w-full"
                placeholder="Search by owner name or id"
                id="search"
                value={this.state.search}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>
          </div>
          <PageSelector
            curpage={this.state.curpage}
            totalpages={this.state.total_pages}
            increase={this.pageUp}
            decrease={this.pageDown}
            setPerPage={this.setPerPage}
            perPageOptions={[10, 20, 30, 40, 50,]}
          />
        </div>

        {/* Owner Info Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="text-left font-normal text-gray-500 ">
              <th className="py-2  ">ID</th>
              <th className="py-2 lg:pl-4 ">Name</th>
              <th className="py-2  ">Phone Number</th>
              <th className="py-2  ">Email</th>
              <th className="py-2  ">Do Not Disturb</th>
              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={4} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(OwnerList);
