import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, withRouter } from "react-router-dom";
import KeysInfo from "../Bookings/KeysInfo";
import ToggleSwitch from "../ToggleSwitch";
import Modal from "../Modal";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { formatStringDate } from "../../utils/DateFormat";
// import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  history: any;
  location: any;
  ErrorNote: any;
};

interface ManualBookingState {
  // tenant section
  booking_id: number;
  tenant_name: string;
  tenant_email: string;
  tenant_phone: number;  /* TODO: Make phone_number a string instead to avoid formatting issues. */

  // notes section
  check_in_notes: string;

  // check in section
  check_in: string;  /* TODO: Rename this attribute to check_in_date. */
  suite: string;
  suite_number: string;
  suite_type: string;

  // corporate info section
  corporate_id: string;
  corporate_name: string;

  // light status section
  keys_confirm: boolean;
  payment_confirm: boolean;
  cleaning_confirm: boolean;

  // miscellaneous
  parking: boolean;
  fob_id: Array<string>;
  // vehicle_count: number;
  first_rent: boolean;
  first_rent_due: number;

  // toggle state
  toggleModal: boolean;
  confirmed: boolean;

  // fobs and keys section
  fob_count: number;
  key_count: number;
  mailkey_count: number;
  park_fob_count: number;
  monthly_rate: number;
  num_outstanding_invoices: number,

  keyCode: string,
}

class CheckInForm extends Component<any, ManualBookingState> {
  constructor(props: any) {
    super(props);

    this.state = {
      // tenant section
      booking_id: -1,
      tenant_name: "",
      tenant_email: "",
      tenant_phone: -1,  /* TODO: Make phone_number a string instead to avoid formatting issues. */

      // notes section
      check_in_notes: "",

      // check in section
      check_in: "",  /* TODO: Rename this attribute to check_in_date. */
      suite: "",
      suite_number: "",
      suite_type: "",

      // corporate info section
      corporate_id: "",
      corporate_name: "",

      // light status
      keys_confirm: false,
      payment_confirm: false,
      cleaning_confirm: false,

      // miscellaneous
      parking: false,
      fob_id: ["", "", ""],
      // vehicle_count: 0,
      first_rent: false,
      first_rent_due: -1,

      // toggle state
      toggleModal: false,
      confirmed: false,

      // fobs and keys section
      fob_count: 0,
      key_count: 0,
      mailkey_count: 0,
      park_fob_count: 0,
      monthly_rate: 0,
      num_outstanding_invoices: 0,

      keyCode: '',
    };
  }

  /* TODO: Make phone_number a string instead to avoid formatting issues. */
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  /* TODO: Make phone_number a string instead to avoid formatting issues. */
  handleModal = (state?: any) => {
    if (state && this.state.toggleModal === false) {
      this.setState({ toggleModal: true });
    } else {
      this.setState({ toggleModal: false });
    }
  };

  handleNext = async () => {
    const { history } = this.props;
    let NotificationHandler = this.props.ErrorNote;
    let booking_id = new URLSearchParams(window.location.search).get("id");

    const bookingEditBody = {
      id: this.state.booking_id,
      fields: {
        fobs: this.state.fob_count,
        keys: this.state.key_count,
        mail_keys: this.state.mailkey_count,
        parking_fobs: this.state.park_fob_count,
        keys_confirm: this.state.keys_confirm,
        cleaning_confirm: this.state.cleaning_confirm,
        payment_confirm: this.state.payment_confirm,
        parking_requested: this.state.parking
      }
    }

    let bookingLightUnitInfo = await fetchRequestJSON("PUT", Constants.API_PATH_BOOKING_EDIT, bookingEditBody);
    if (bookingLightUnitInfo.status === "Success") {
      NotificationHandler("Success", "Check in completed and data saved successfully", "");
      console.log(bookingLightUnitInfo);
      
      // store.dispatch({
      //   type: "fillKeyFob",
      //   infoKeyFob: {
      //     fob_count: this.state.fob_count,
      //     key_count: this.state.key_count,
      //     mailkey_count: this.state.mailkey_count,
      //     park_fob_count: this.state.park_fob_count,
      //     // plate_number: this.state.plate_number,
      //     // vehicle_model: this.state.vehicle_model,
      //     parking_id: this.state.parking_id,
      //     parking: this.state.parking
      //   }
      // });
    } else {
      NotificationHandler("Error", "Error checking in!");
    }

    if (history) {
      history.push({
        pathname: "/User/Check Ins/BookingId/check in summary",
        search: `id=${booking_id}`
      })
    }
  };

  handleBack = () => {
    const { history } = this.props;
    let booking_id = new URLSearchParams(window.location.search).get("id");
    if (history) {
      history.push({
        pathname: "/User/bookings/bookingprofile",
        search: `id=${booking_id}`
      })
    }
  };

  handleToggleChange = async (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    }();
    this.setState(stateObject);
  };

  handleCountChange = (event: any) => {
    event.preventDefault();

    if (event.target.id === "VehicleFobAdd" && this.state.park_fob_count < 3) {
      let add = this.state.park_fob_count + 1;
      this.setState({ park_fob_count: add });
    } else if (event.target.id === "VehicleFobRemove" && this.state.park_fob_count > 0) {
      let remove = this.state.park_fob_count - 1;
      this.setState({ park_fob_count: remove });
    } else if (event.target.id === "fobAdd" && this.state.fob_count < 4) {
      const addFob = this.state.fob_count + 1;
      this.setState({ fob_count: addFob });
    } else if (event.target.id === "fobRemove" && this.state.fob_count > 0) {
      const removeFob = this.state.fob_count - 1;
      this.setState({ fob_count: removeFob });
    } else if (event.target.id === "keyAdd" && this.state.key_count < 4) {
      const addKey = this.state.key_count + 1;
      this.setState({ key_count: addKey });
    } else if (event.target.id === "keyRemove" && this.state.key_count > 0) {
      const keyRemove = this.state.key_count - 1;
      this.setState({ key_count: keyRemove });
    } else if (event.target.id === "mailKeyAdd" && this.state.mailkey_count < 4) {
      const mailkeyAdd = this.state.mailkey_count + 1;
      this.setState({ mailkey_count: mailkeyAdd });
    } else if (event.target.id === "mailKeyRemove" && this.state.mailkey_count > 0) {
      const mailkeyRemove = this.state.mailkey_count - 1;
      this.setState({ mailkey_count: mailkeyRemove });
    }
  };

  handleArrayChange = (event: any) => {
    // console.log(this.state.parking_id);
    // console.log(event.target.id);
    let ar: any = [...this.state.fob_id];

    for (let i = 0; i < this.state.park_fob_count && i < 3; i++) {
      if (event.target.id === "fob_id" + i) {
        let arIndex = { ...ar[i] };
        arIndex[i] = event.target.value;
        ar[i] = arIndex[i];
        this.setState({ fob_id: ar });
      }
    }
    console.log(this.state.fob_id);
  };

  componentDidMount = async () => {
    let booking_id = new URLSearchParams(window.location.search).get("id")
    let booking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${booking_id}`);
    let bookingData = booking.data[0];
    // let reduxData = store.getState().checkInReducer;
    if (bookingData) {
      this.setState({
        booking_id: bookingData.id,
        check_in: bookingData.check_in.split('T')[0],
        check_in_notes: bookingData.check_in_notes,
        confirmed: bookingData.confirmed,
        fob_count: bookingData.fobs ? bookingData.fobs : 0,
        key_count: bookingData.keys ? bookingData.keys : 0,
        mailkey_count: bookingData.mail_keys ? bookingData.mail_keys : 0,
        monthly_rate: bookingData.monthly_rate ? bookingData.monthly_rate : 0,
        // num_outstanding_invoices: reduxData.num_outstanding_invoices || -1,
        park_fob_count: bookingData.parking_fobs ? bookingData.parking_fobs : 0,
        parking: bookingData.parking_requested,
        keys_confirm: bookingData.keys_confirm,
        payment_confirm: bookingData.payment_confirm,
        cleaning_confirm: bookingData.cleaning_confirm,
        keyCode: bookingData.keyCode,
      });
    }

    // Get this invoices tenant info
    const tenant = await fetchGetJSON(`${Constants.API_PATH_TENANT_GET}${bookingData.tenant_id}`);
    const tenantData = tenant.data;
    if (tenantData) {
      this.setState({
        tenant_name: `${tenantData.first_name} ${tenantData.last_name}`,
        tenant_email: tenantData.email,
        tenant_phone: tenantData.phone_number,
      });
    }

    // Get this invoices corporate info
    const corp = await fetchGetJSON(`${Constants.API_PATH_CORP_GET}${bookingData.corporate_id}`);
    const corpData = corp.data;
    if (corpData) {
      this.setState({
        corporate_id: corpData.id
      });
    }

    let unit = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${bookingData.unit_id}`);
    let unitData = unit.data;
    if (unit.data) {
      this.setState({
        suite: unitData.suite_name,
        suite_number: unitData.suite_number,
        suite_type: unitData.suite_type,
      })
    }

    // Not sure where these are from
    // this.state = {
    //   first_rent: true,
    //   first_rent_due: 12345678,
    //   toggleModal: false,
    // };
  }

  keyCafeUpdate = async() => {
    let NotificationHandler = this.props.ErrorNote;
    const booking_id = this.state.booking_id;
    const suiteName = this.state.suite;

    NotificationHandler(
      "Processing",
      `Updating KeyCode`,
      "Please wait while we update this booking's keycode to KeyCafe",
    );

    const forceKeyCafe = await fetchRequestJSON("PUT",
      Constants.API_PATH_FORCE_KEYCAFE, {
      bookingId: booking_id,
      suite_name: suiteName
    })

    if(forceKeyCafe?.status == "Success") {
     NotificationHandler("Success",
      `Updated KeyCode`,
       `${forceKeyCafe.data}`,)
    } else {
      NotificationHandler("Error",
      `Unsuccesful KeyCode Update`,
       `${forceKeyCafe?.status}`,)
    }
  }

  /* TODO: Extract the check in form component into its own component file. */
  render() {
    let parkingInfo = <></>;
    let parkingFobs = [];

    for (let i = 0; i < this.state.park_fob_count && i < 3; i++) {
      let fobInput = <></>;
      fobInput = (
        <label className={"flex flex-col fade-in mt-2"}>
          <span className="text-xs w-auto mr-3 text-gray-600">
            Fob ID #{i + 1}
          </span>
          <input
            id={"fob_id" + i}
            type="number"
            className={
              " text-sm transition-all duration-600 text-xs text-gray-600 mt-0 py-1 block  px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-300 focus:ring-0 focus:border-gray-500"
            }
            value={this.state.fob_id[i]}
            onChange={this.handleArrayChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            placeholder="ie. #1234"
          />
        </label>
      );

      parkingFobs.push(fobInput);
    }

    if (this.state.parking) {
      parkingInfo = 
        <div className=" fade-in ml-2 py-2">
          {/* Vehicle Count is not stored or tracked by the sales team */}
        {/* <div className="flex flex-row items-center ">
          <span className="text-xs text-gray-600 ">Number Of Vehicles:</span>
          <button
            id="VehicleRemove"
            onClick={this.handleCountChange}
            className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4 items-center justify-center text-xs rounded-full w-5 h-5 bg-gray-200 text-green-500 "
          >
            -
          </button>

          <span className="font-bold flex flex-row text-green-500 w-2 justify-center  ">
            {this.state.vehicle_count}
          </span>
          <button
            id="VehicleAdd"
            onClick={this.handleCountChange}
            className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4 items-center justify-center text-xs rounded-full w-5 h-5 bg-gray-200 text-green-500 "
          >
            +
          </button>
        </div> */}

        <div className="flex flex-row items-center">
          <span className="text-xs text-gray-600 ">
            Parking Fobs Needed:
          </span>
          <button
            id="VehicleFobRemove"
            onClick={this.handleCountChange}
            className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-2 items-center justify-center text-xs rounded-full w-5 h-5 bg-gray-200 text-green-500 "
          >
            -
          </button>

          <span className="font-bold flex flex-row text-green-500 w-8 justify-center  ">
            {this.state.park_fob_count}
          </span>

          <button
            id="VehicleFobAdd"
            onClick={this.handleCountChange}
            className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-2 items-center justify-center text-xs rounded-full w-5 h-5 bg-gray-200 text-green-500 "
          >
            +
          </button>
        </div>

        <div className="grid grid-cols-3 gap-8">{parkingFobs}</div>

        <div className="max-w-md mt-2">
          <span className="text-xs text-gray-400">
            Assign spot from google sheets, provide that spot information to
            tenants and provide special instruction from building to tenant nd
            also ask for car plate and vehicle type.
          </span>
        </div>
      </div>
    }

    return (
      <div className="  font-display lg:rounded-tl-lg  bg-white ">
        <Modal
          handleModalToggle={this.handleModal}
          onoff={this.state.toggleModal}
          title="Confirm Remote Check In?"
          content="Remote check ins must be arranged ahead of time.
            Only continue if this has been approved."
          confirmButton={this.handleModal}
        />
        <BreadCrumb
          rootPage="Check Ins"
          subPage={`Booking #${this.state.booking_id}`}
          sideButton="bomb"
          buttonAction=""
        />
        <div className="fade-in px-10 max-w-5xl pb-24">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-6">
            <div className="flex flex-col w-full">
              <h1 className="text-3xl px-2 pt-3 text-gray-700 font-black font-display ">Check In Form</h1>
              <h2 className="px-2 font-normal font-display text-sm text-gray-500">
                Please confirm that all of the following information is correct. Edit the booking light statuses and keys information as needed.
              </h2>
            </div>

            <button
              onClick={this.handleModal}
              className=" self-center opacity-80 text-indigo-500 whitespace-nowrap rounded border-2 border-indigo-500 hover:bg-gray-100 border-solid w-38 px-3 h-8 text-sm font-bold"
            >
              Remote Check In
            </button>
          </div>

          <div className="flex flex-row mb-4">
            <div className="slide-in-blurred-top font-display w-1/3 mr-6 rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <div>
                <span className="font-medium text-gray-800 text-base pb-0 ">Tenant -{" "}</span>
                <span className="font-medium text-gray-700 text-base pb-0 ">{this.state.tenant_name}</span>
              </div>

              <span className="font-thin text-gray-400 text-xs pt-1 ">Corporate: {this.state.corporate_id}</span>
              <span className="font-thin text-gray-400 text-xs pt-1 ">{this.state.tenant_email}</span>

              <div className="flex flex-row mt-2 text-sm items-center">
                <i className="text-gray-500 mr-2  "><FaPhoneAlt /></i>
                <span className=" font-regular text-gray-400  transition-all duration-200 border-0 p-0 w-3/4 focus:ring-0  ">
                  {" "}
                  {this.state.tenant_phone}{" "}
                </span>
              </div>
            </div>
            <div className="slide-in-blurred-top font-display w-2/3 rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <span className="font-medium text-gray-800 text-base pb-0 ">Check In Notes:</span>
              <span className="font-thin text-red-400 text-xs pt-1 ">
                {(this.state.check_in_notes) ? this.state.check_in_notes : "No Check In Notes"}
              </span>
            </div>
          </div>

          <div className="flex flex-row mb-4">
            <div className="slide-in-blurred-top font-display w-1/3 mr-6 rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <span className="text-gray-800 font-medium pb-2 text-base">Booking Light Status</span>
              <div className="grid grid-cols-1 px-2 bg-gray-50 border rounded-sm">
                <div className={" block py-3 border-b   "}>
                  <div className="flex flex-row items-center justify-between ">
                    <span className={"transition-all duration-600 text-gray-600 text-xs whitespace"}>
                      Key
                    </span>
                    <ToggleSwitch
                      id="keys_confirm"
                      handleStateToggle={this.handleToggleChange}
                      className="justify-center align-center"
                      size="sm"
                      onoff={this.state.keys_confirm}
                    />
                  </div>
                </div>

                <div className={" block  py-3 border-b   "}>
                  <div className="flex flex-row items-center justify-between ">
                    <span className={" transition-all duration-600 text-gray-600 text-xs whitespace"}>
                      Cleaning
                    </span>
                    <ToggleSwitch
                      id="cleaning_confirm"
                      handleStateToggle={this.handleToggleChange}
                      className="justify-center align-center"
                      size="sm"
                      onoff={this.state.cleaning_confirm}
                    />
                  </div>
                </div>

                <div className="py-3">
                  <div className="flex flex-row items-center justify-between ">
                    <span className={"transition-all duration-600 text-gray-600 text-xs whitespace"}>
                      Payment Confirmed
                    </span>
                    <ToggleSwitch
                      id="payment_confirm"
                      handleStateToggle={this.handleToggleChange}
                      className="justify-center align-center"
                      size="sm"
                      onoff={this.state.payment_confirm}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-in-blurred-top font-display w-1/3 mr-6 rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <span className="text-gray-800 font-medium pb-2 text-base">Fob & Keys Info</span>
              <div className="flex flex-row items-center py-2 ">
                <span className="text-xs text-gray-600 ">Fobs Needed:</span>
                <button
                  id="fobRemove"
                  onClick={this.handleCountChange}
                  className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4 ml-8
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-green-500 "
                >
                  -
                </button>
                <span className="font-bold flex flex-row text-green-500 w-4 justify-center  ">
                  {this.state.fob_count}
                </span>
                <button
                  id="fobAdd"
                  onClick={this.handleCountChange}
                  className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                    items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-green-500 "
                >
                  +
                </button>
              </div>
              <div className="flex flex-row items-center py-2 ">
                <span className="text-xs text-gray-600 ">Keys Needed:</span>
                <button
                  id="keyRemove"
                  onClick={this.handleCountChange}
                  className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4 ml-8
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-green-500 "
                >
                  -
                </button>
                <span className="font-bold flex flex-row text-green-500 w-4 justify-center  ">
                  {this.state.key_count}
                </span>
                <button
                  id="keyAdd"
                  onClick={this.handleCountChange}
                  className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                    items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-green-500 "
                >
                  +
                </button>
              </div>
              <div className="flex flex-row items-center py-2 ">
                <span className="text-xs text-gray-600 ">Mail Keys Needed:</span>
                <button
                  id="mailKeyRemove"
                  onClick={this.handleCountChange}
                  className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                      items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-green-500 "
                >
                  -
                </button>
                <span className="font-bold flex flex-row text-green-500 w-4 justify-center  ">
                  {this.state.mailkey_count}
                </span>
                <button
                  id="mailKeyAdd"
                  onClick={this.handleCountChange}
                  className="flex flex-row hover:shadow-sm hover:opacity-70 items-center mx-4
                    items-center justify-center text-sm rounded-full w-5 h-5 bg-gray-200 text-green-500 "
                >
                  +
                </button>
              </div>
            </div>

            <div className="slide-in-blurred-top font-display w-1/3 rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <span className="text-gray-800 font-medium pb-1 text-base">Parking Info</span>
              <div className="flex flex-row items-center py-1 ">
                <span className="text-xs text-gray-600 mr-4">Parking Required: </span>
                <ToggleSwitch
                  id="parking"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="sm"
                  onoff={this.state.parking}
                />
              </div>
              {parkingInfo}
            </div>
          </div>

          <div className=" slide-in-blurred-top font-display  border px-3 py-5 mt-2">
            <div className="flex-wrap flex justify-start  items-center flex-row">
              {/* CheckIn Info */}
              <h2 className=" mr-4 pt-1 inline-block align-middle font-regular text-base">
                Check In <span className="text-gray-400">|</span>{" "}
                <span className="font-bold">{formatStringDate(this.state.check_in)}</span>{" "}<span className="text-gray-400"> | </span>KeyCode: {this.state.keyCode} {" "}
              </h2>

              {/* Tag divs */}
              {(this.state.first_rent) && (
                <div
                  id="FirstRent"
                  className=" whitespace-no-wrap h-6 mr-2 bg-red-300 text-red-800  font-medium text-xs rounded-full py-1 w-auto flex items-center align-center text-center px-4"
                >
                  {" "}
                  First Rent Required{" "}
                </div>
              )}

              {(this.state.confirmed) && (
                <div className=" whitespace-no-wrap h-6 mr-2 bg-green-400 text-green-800  font-medium text-xs rounded-full py-1 w-auto flex items-center align-center text-center px-4">
                  {" "}
                  Confirmed{" "}
                </div>
              )}

              {(this.state.parking) && (
                <div
                  id="parkingRequired"
                  className=" whitespace-no-wrap h-6 mr-2 bg-yellow-300 text-yellow-700 font-medium text-xs rounded-full py-1 w-auto flex items-center justify-center  text-center px-4"
                >
                  {" "}
                  Parking Required{" "}
                </div>
              )}
            </div>

            <div className="flex-wrap flex justify-between mt-1 flex-row">
              {/* Unit Info */}
              <div className="flex-col flex  ">
                <h3 className="text-gray-500 text-sm font-semibold w-auto  justify-start mt-1 ">{this.state.suite}</h3>
                <h3 className="text-gray-500 text-sm font-thin w-auto  justify-start ">{this.state.suite_number}</h3>
                <h3 className="text-gray-300 text-xs font-thin w-auto  justify-start ">{this.state.suite_type}</h3>

                <button
                onClick={this.keyCafeUpdate}
                className="transition duration-200 hover:bg-green-400 px-8 lg:px-10 py-2 bg-green-500 rounded text-white font-bold shadow-sm mr-3 mt-5"
                >
                  KeyCode Update
                </button>
              </div>

              <a 
                href={window.location.origin + "/User/Bookings/BookingProfile?id=" + this.state.booking_id}
                target="_blank"
                className="transition h-10 duration-100 border-2 border-gray-100 border-solid text-sm w-36 bg-white hover:bg-gray-100 text-gray-500 font-medium py-1 rounded pr-1 flex flex-row self-end justify-center items-center">
                View Booking
              </a>
            </div>
          </div>

          <br />

          <div className="flex flex-row justify-center mb-16">
            <button
              onClick={this.handleBack}
              className=" font-SF-Pro shadow-sm transition text-md duration-100 w-44 bg-gray-100 hover:bg-gray-200 text-gray-500 font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center mr-3"
            >
              Back
            </button>

            <button
              onClick={this.handleNext}
              className=" font-SF-Pro shadow-sm transition text-md duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center mr-3"
            >
              Next
            </button>
          </div>
        </div>

        <KeysInfo data={this.state} />
      </div>
    );
  }
}

export default withRouter(withHooksHOC(CheckInForm));
