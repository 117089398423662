import { useState } from "react";

type Props = {
  delay: any;
  children: any;
  direction: string;
  content: any;
  checkList?: boolean;
};

const Tooltip = (props: Props) => {
  let timeout: any;
  const [active, setActive] = useState(false);
  const [checkIn, setCheckIn] = useState(props?.checkList ? props.checkList : false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div>
      {
        checkIn ? 
        <div
          className="z-50 transition-all bg-gray-700 rounded-full text-center"
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          {/* Wrapping */}
          {props.children}
          {active && (
            <div
              className={` z-50 font-display border border-gray-600 rounded-lg w-64 -mt-0  py-4 px-5 bg-white text-xs whitespace-no-wrap shadow-lg Tooltip-Tip  ${
                props.direction || "top"
              } `}
            >
              {/* Content */}
              {props.content}
            </div>
          )}
        </div>
        :
        <div
          className="inline-block z-50 relative transition-all "
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        > 
          {/* Wrapping */}
          {props.children}
          {active && (
            <div
              className={` z-50 font-display border border-gray-600 rounded-lg w-64 -mt-0  py-4 px-5 bg-white text-xs whitespace-no-wrap shadow-lg Tooltip-Tip  ${
                props.direction || "top"
              } `}
            >
              {/* Content */}
              {props.content}
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default Tooltip;
