export type State = {
  tenants: any[];
  curId: number;
  updateList: any;
  updateEdit: any;
};

const initialState: State = {
  tenants: [],
  curId: -1,
  updateList: undefined,
  updateEdit: undefined,
};

type Action =
  | { type: "addInvoice"; newInvoice: { id: number } }
  | { type: "removeInvoice"; id: number }
  | { type: "tenantIdTransfer"; id: number }
  | { type: "updateTenantList"; listUpdate: any }
  | { type: "updateEditTenant"; editUpdate: any };

export const tenantReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "addInvoice":
      state.tenants.push(action.newInvoice);
      return state;
    case "tenantIdTransfer":
      state.curId = action.id;
      return state;
    case "updateTenantList":
      state.updateList = action.listUpdate;
      return state;
    case "updateEditTenant":
      state.updateEdit = action.editUpdate;
      return state;
    default:
      return state;
  }
};

export default tenantReducer;
