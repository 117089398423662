import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDate, formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { inputISO } from "../../utils/DateFormat";

type Props = {
  toggle: any;
  handleEditBarToggle: any;
};

type EditLeadState = {
  lead_id: number;
  contact_name: string;
  lead_email: string;
  lead_phone: number;
  lead_type: string;
  contact_date: Date;
  requested_check_in?: any;
  requested_check_out?: any;
  flexible_dates: boolean;
  corporate: boolean;
  company_name: string;
  suite_type: string;
  purpose_of_stay: string;
  parking_requested: boolean;
  number_of_adults: number;
  number_of_children: number;
  country_of_origin: string;
  how_they_heard: string;
  budget_per_night: number;
  unit_of_interest: string;
  lead_notes: string;
};

class NewLead extends Component<Props, EditLeadState> {
  constructor(props: any) {
    super(props);

    this.state = {
      lead_id: 2012,
      contact_name: "",
      lead_email: "",
      lead_phone: 0,
      lead_type: "",
      contact_date: new Date(),
      requested_check_in: new Date(),
      requested_check_out: new Date(),
      flexible_dates: true,
      corporate: true,
      company_name: "",
      suite_type: "",
      purpose_of_stay: "",
      parking_requested: false,
      number_of_adults: 0,
      number_of_children: 0,
      country_of_origin: "",
      how_they_heard: "",
      budget_per_night: 0,
      unit_of_interest: "",
      lead_notes: "",
    };
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    alert("Submits Form / State of Parent");
    this.props.handleEditBarToggle();
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "New Lead";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry = [];
    let poop = false;

    if (this.state.corporate === true) {
      ManagedModel = "text-gray-300 ";
      RentalModel = "font-bold text-gray-600";
      modelEntry = [
        <label className=" appear block pl-3 mb-8">
          <span className="text-gray-400 text-xs">Company Name</span>
          <input
            id="company_name"
            onChange={this.handleInputChange}
            value={this.state.company_name}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>,
      ];
    } else {
      ManagedModel = "font-medium text-gray-600 ";
      RentalModel = "text-gray-300 ";
      modelEntry = [<></>];
    }

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Name</span>
          <input
            id="contact_name"
            onChange={this.handleInputChange}
            value={this.state.contact_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Email</span>
          <input
            id="lead_email"
            onChange={this.handleInputChange}
            value={this.state.lead_email}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Phone</span>
          <input
            id="lead_phone"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.lead_phone}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Lead Type</span>
          <select
            id="lead_type"
            onChange={this.handleInputChange}
            value={this.state.lead_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Email</option>
            <option>Phone Call</option>
            <option>Walk-In</option>
            <option>Other</option>
          </select>
        </div>

        <label className="opacity-10 pointer-event-none block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Contact Date</span>
          <input
            id="contact_date"
            onChange={this.handleInputChange}
            value={formatFullDate(this.state.contact_date)}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Requested Check In</span>
          <input
            id="requested_check_in"
            onChange={this.handleDateChange}
            value={inputISO(this.state.requested_check_in)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Requested Check out</span>
          <input
            id="requested_check_out"
            onChange={this.handleDateChange}
            value={inputISO(this.state.requested_check_out)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className=" pl-3 flex flex-row items-center justify-start mb-5  ">
          <span
            className={
              " transition-all duration-600 text-gray-600 text-sm mr-3"
            }
          >
            Flexible Dates
          </span>

          <ToggleSwitch
            id="flexible_dates"
            handleStateToggle={this.handleToggleChange}
            className="justify-center align-center"
            size="lg"
            onoff={this.state.flexible_dates}
          />
        </div>

        <div className="  pl-3 flex flex-row items-center justify-start mb-5 ">
          <span
            className={
              " transition-all duration-600 text-gray-600 text-sm mr-3"
            }
          >
            Corporate
          </span>

          <ToggleSwitch
            id="corporate"
            handleStateToggle={this.handleToggleChange}
            className="justify-center align-center"
            size="lg"
            onoff={this.state.corporate}
          />
        </div>

        {modelEntry}

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Suite Type</span>
          <select
            id="suite_type"
            onChange={this.handleInputChange}
            value={this.state.suite_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Studio</option>
            <option>1 Bedroom</option>
            <option>1 Bed + Den</option>
            <option>2 Bedrooms</option>
            <option>2 Bed + Den</option>
            <option>3 Bedrooms</option>
            <option>Penthouse</option>
          </select>
        </div>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Suite Type</span>
          <select
            id="purpose_of_stay"
            onChange={this.handleInputChange}
            value={this.state.purpose_of_stay}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Relocation</option>
            <option>Pleasure</option>
            <option>Option (Specify In Notes)</option>
          </select>
        </div>

        <div className="flex flex-row items-center justify-start pl-3 mb-5 ">
          <span
            className={
              " transition-all duration-600 text-gray-600 text-sm mr-3"
            }
          >
            Parking Requested
          </span>

          <ToggleSwitch
            id="parking_requested"
            handleStateToggle={this.handleToggleChange}
            className="justify-center align-center"
            size="lg"
            onoff={this.state.parking_requested}
          />
        </div>

        <div className=" pl-3 flex flex-row w-full justify-around">
          <label className=" mb-5 w-1/3 ">
            <span className="text-gray-400 text-xs">Number Of Adults</span>
            <input
              id="number_of_adults"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.number_of_adults}
              type="number"
              className="mt-0 py-1  w-full   px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <label className="  mb-5 w-1/3">
            <span className="text-gray-400 text-xs">Number Of Children</span>
            <input
              id="number_of_children"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.number_of_children}
              type="number"
              className="mt-0 py-1  w-full  px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Country Of Origin</span>
          <input
            id="country_of_origin"
            onChange={this.handleInputChange}
            value={this.state.country_of_origin}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">How They Heard</span>
          <select
            id="how_they_heard"
            onChange={this.handleInputChange}
            value={this.state.how_they_heard}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Corporate</option>
            <option>Friends/Colleqgues</option>
            <option>Search Engine</option>
            <option>Referral</option>
            <option>Rent Board</option>
            <option>Repeat Client</option>
            <option>Repeat Corporate</option>
            <option>Option (Specify In Notes)</option>
          </select>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Budget Per Night</span>
          <input
            id="budget_per_night"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.budget_per_night}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Unit Of Interest</span>
          <input
            id="unit_of_interest"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.unit_of_interest}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Additional Notes</span>
          <textarea
            id="lead_notes"
            onChange={this.handleInputChange}
            value={this.state.lead_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.handleSubmit}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.state.lead_id}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={this.handleSubmit}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default NewLead;
