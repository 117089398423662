import React, {useEffect, useState} from 'react';
import { get } from 'react-hook-form';
import { fetchGetJSON, regGet } from '../../../api-requests/apiCallHelper';
import ConsolidatedClientInvoice from '../../../client-facing/ConsolidatedClientInvoice';
import logo from "./logo.png";
import { useHistory, useParams } from "react-router-dom";
import { Constants } from '../../../api-requests/apiLinkConstants';

const Header = (props:any) => {
  const { unitId } = useParams<{ unitId: string }>();
  const [units, setUnit] = useState<any>();
  const [count, setCount] = useState(1);
  let id = count;
  const testingJsonFunction = async () => {
    let signature = new URLSearchParams(window.location.search).get("signature");
    let unit = await regGet(`${Constants.API_PATH_GET_UNIT_DATA_PUBLIC}${unitId}?signature=${signature}`);
    setUnit(unit);
    }
  useEffect(() => { 
    console.log(`Props: ${unitId}`)
    testingJsonFunction();
  }, []);

  return (
    <>
      <div className='bg-white'>
        <div className='px-4 md:px-8 lg:px-24 xl:px-56'>
          <div className='flex justify-between pt-5'>
            <div className='justify-start'>
              <h1 className='h1'>{units?.suite_name}</h1>
              <h2 className='h2'>{units?.building.address}</h2>
              <h2 className='h2'>Unit #{units?.suite_number}</h2>
            </div>
            <div className='justify-end pt-4'>
              <img className='h-3' src={logo} alt="" />
              <h2 className='h2'>Unit Info Sheet</h2>
            </div>
          </div>
          <div className='line'/> 
        </div>
      </div>
    </>
    )
   }

export default Header;