import { Component } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { formatFullDate, formatDay } from "../../../utils/DateFormat";
import Chart from "react-apexcharts";
import CleaningPie from "./PieChart";
import ToggleSwitch from "../../ToggleSwitch";
import { AiOutlineFileDone } from "react-icons/ai";
import DashBar from "../DashBar";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { getUserList } from "../../../api-requests/api-gateway";
import dayjs from "../../../utils/Dayjs";

type Props = {
  userData: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  options: object;
  series: Array<any>;
  cleaners: Array<string>;
  allCleanersChartData: Array<any>;
  performance_time_period: boolean;
};

type Cleaners = {
  id: number;
  created_at: string;
  updated_at: string;
  user_id: string;
  calendar_colour: string;
  monday_availability_start: number;
  monday_availability_end: number;
  name: string;
  cleanings: number;
};

type Cleaning = {
  cleaner_id: number;
  cleaning_id: number;
  created_at: string;
  id: number;
  updated_at: string;
};
class CleaningDash extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      tenant_email: "",
      booking_total: "",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "",
      confirmed: false,
      performance_time_period: false,
      allCleanersChartData: [],
      cleaners: [],

      options: {
        chart: {
          id: "basic-bar",
          fontFamily: "Sofia-Pro , Arial, sans-serif",
        },
        xaxis: {
          categories: [
            "Studio",
            "1 Bed",
            "1 + Den",
            "2 Bed",
            "2 + Den",
            "3 Bed",
            "Penthouse",
          ],
        },

        colors: ["#546E7A", "#10b981"],

        plotOptions: {
          bar: {
            columnWidth: "80%",
            borderRadius: 12,
          },
        },

        grid: {
          show: true,
          borderColor: "#f6f8f9",
        },
      },

      series: [
        {
          name: "Regular",
          data: [20, 40, 45, 50, 49, 60, 70],
        },
        {
          name: "Turnovers",
          data: [50, 70, 15, 55, 49, 80, 20],
        },
      ],
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  componentDidMount = async () => {
    this.getCleanersData();
  };

  getCleanersData = async () => {
    try {
      let start_date = dayjs();
      let end_date = dayjs();
      start_date = start_date.subtract(1, 'month');
      end_date = end_date.add(2, 'month');
      let allCleanings = await fetchGetJSON(`${Constants.API_PATH_CLEANING_GET_ALL}?start_date=${start_date.format('YYYY-MM-DD')}&end_date=${end_date.format('YYYY-MM-DD')}&include_cleaners=true`);
      let allCleaners = await fetchGetJSON(`${Constants.API_PATH_CLEANER_GET_ALL}?active=true`);
      
      // THIS ROUTE DOESN'T EXIST ANYMORE, this page needs to be changed if it is to be used
      let allCleanerCleanings = await fetchGetJSON(
        // Constants.API_PATH_CLEANER_CLEANING_GET_ALL,
        ''
      );
      let userList = await getUserList();
      if (allCleanings.status === "Success") {
        let allCleanersChartData: Cleaners[];
        allCleanersChartData = allCleaners.data.map((cleaner: any) => {
          let cleanerData = userList.Users.find(
            (user:any) => user.Username === cleaner.user_id
          ).Attributes;
          let name: string = cleanerData.find(
            (user:any) => user.Name === "name"
          ).Value;
          let cleaningNumber = allCleanerCleanings.data.filter(
            (cleaning: Cleaning) => cleaning.cleaner_id === cleaner.id
          ).length;
          return {
            calendar_colour: cleaner.calendar_colour,
            name,
            cleanings: cleaningNumber,
          };
        });
        this.setState({ allCleanersChartData: allCleanersChartData });
      }
    } catch (err) {
      console.log("Failed to laod cleanings data");
    }
  };
  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";
    let HeadcellStyle =
      "pl-6 font-thin border-b border-t border-gray-300 py-3   ";
    let periodAll = "";
    let periodPayroll = "";

    if (this.state.performance_time_period !== false) {
      periodPayroll = "text-gray-300 ";
      periodAll = "font-bold text-gray-600 ";
    } else {
      periodPayroll = "font-medium text-gray-600 ";
      periodAll = "text-gray-300 ";
    }

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-screen lg:rounded-tl-lg pt-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}
        {/* <InvoiceQuickView handleModalToggle={this.handleInvoiceView} onoff={this.state.toggleInvoice} /> */}
        {/* <PaymentsQuickView handleModalToggle={this.handleQuickView} onoff={this.state.toggleQuickView} InvoiceQuickView={this.handleInvoiceView} /> */}
        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-14  ">
          <div className=" flex flex-row justify-between font-circular pl-1  pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""} />
            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Cleaning Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          <div className="  w-full">
            <div className="pt-0 pb-5 grid grid-cols-6 ">
              <div className=" col-start-1 col-end-5 flex flex-col">
                <span className=" pl-1 text-3xl leading-tight lg:text-4xl font-medium text-gray-700">
                  Good {dayTime}, {this.props.userData.user_f_name}
                </span>
                <span className=" ml-1 pt-2 font-thin text-gray-600 font-sofia">
                  Let&apos;s Get To Work Today
                </span>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 gap-5 lg:w-full  ">
              <div className="bg-white pt-5 pb-1 pr-1 lg:px-4 lg:col-start-1 lg:col-end-10 rounded-xl shadow-md ">
                <div className="w-full">
                  <span className="pl-5 text-xl font-medium pb-2 text-gray-600 ">
                    Cleaning Time Averages:
                  </span>
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    width="100%"
                    height="300"
                  />
                </div>
              </div>

              <div className="lg:col-start-10 lg:col-end-13 py-4 bg-white  rounded-xl shadow-md h-80 flex flex-col ">
                <span className="text-gray-600 text-lg font-medium px-4">
                  Cleaner Percentages:
                </span>
                <div className="flex flex-col h-full justify-center">
                  <CleaningPie cleaningData={this.state.allCleanersChartData} />
                </div>
              </div>
            </div>

            <div className="mt-5 pb-20  h-auto grid grid-cols-12 gap-4 overflow-x-auto">
              <div className="col-start-1 col-end-13   bg-white  rounded-xl shadow-md flex flex-col overflow-y-auto  scroll-hide">
                <div className="px-5 mt-4 pb-4 flex flex-row w-full justify-between items-center">
                  <span className=" font-medium text-xl text-gray-600  ">
                    Cleaner Performance List:
                  </span>
                  <div className="flex flex-row items-center">
                    <span
                      className={
                        periodPayroll +
                        " transistion-all duration-300 text-sm pr-3 "
                      }
                    >
                      Last Payroll
                    </span>
                    <ToggleSwitch
                      id="performance_time_period"
                      handleStateToggle={this.handleToggleChange}
                      size="lg"
                      onoff={this.state.performance_time_period}
                    />
                    <span
                      className={
                        periodAll + "transistion-all duration-300 text-sm pl-3 "
                      }
                    >
                      All Time
                    </span>
                  </div>
                </div>

                <table
                  style={{ borderSpacing: "0" }}
                  className="border-separate  relative w-full table-auto bg-white     "
                >
                  <thead className="  ">
                    <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2   ">
                      <th className={HeadcellStyle + "sticky top-0 "}>Name</th>
                      <th className={HeadcellStyle + "sticky top-0 "}>
                        Regular
                      </th>
                      <th className={HeadcellStyle + "sticky top-0 "}>
                        Turnover
                      </th>
                      <th
                        className={
                          HeadcellStyle + "pr-6 sticky top-0 border-r "
                        }
                      >
                        Total
                      </th>
                      <th className={HeadcellStyle + "sticky top-0 "}>
                        Regular Forms Missed
                      </th>
                      <th className={HeadcellStyle + "sticky top-0  "}>
                        Turnover Forms Missed
                      </th>
                      <th className={HeadcellStyle + " sticky top-0  "}>
                        Total
                      </th>
                      <th
                        className={HeadcellStyle + " sticky top-0 border-r "}
                      ></th>
                      <th className={HeadcellStyle + "sticky top-0 "}>
                        Form Completion Rate
                      </th>
                      <th className={HeadcellStyle + "sticky top-0 "}></th>
                    </tr>
                  </thead>

                  <tbody className="divide-y">
                    <CleaningList
                      length={this.state.cleaners.length}
                      cleaners={this.state.cleaners}
                      viewInvoice={this.handleInvoiceView}
                      todaysDate={today}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Heading Info Area  */}
        </div>
      </div>
    );
  }
}

export default CleaningDash;

export function CleaningList(props: any) {
  let currentDate = props.todaysDate;
  let testDueDate = new Date(2021, 7, 8, 4, 20);
  let rows = [];
  let CleanerRow = <></>;
  let altBg = "";
  let length = props.length;
  let cellStyle = "py-3 pl-6 font-thin  ";
  let OverDueStyle = "text-gray-700";
  let OverDueStyle2 = "text-gray-700";
  let OverDue = <></>;

  for (var i = 0; i < length; i++) {
    if (testDueDate > currentDate) {
      OverDueStyle = "text-white text-sm  w-72";
      OverDueStyle2 = "text-red-400 mr-10";
      OverDue = (
        <span className="bg-red-500 py-1 px-4 rounded-full">Overdue</span>
      );
    }

    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    CleanerRow = (
      <tr
        className={
          altBg +
          " w-full fade-in  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className={cellStyle + " text-purple-400 text-sm  "}>
          {props.cleaners[i]}
        </td>
        <td className={cellStyle}> 2 </td>
        <td className={cellStyle + "text-gray-400  "}>5</td>
        <td
          className={
            cellStyle + "font-bold text-gray-700 border-r border-gray-300"
          }
        >
          7
        </td>
        <td className={cellStyle + " text-red-300"}>3</td>
        <td className={cellStyle + " text-red-300 "}>3</td>
        <td className={cellStyle + "font-bold text-gray-700 "}>5</td>
        <td className={cellStyle + "border-r border-gray-300"}>
          <button
            onClick={props.viewInvoice}
            className=" h-8 w-auto mr-3 px-3 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white whitespace-nowrap "
          >
            View Forms
          </button>
        </td>
        <td className={cellStyle}>22.5%</td>
        <td className={cellStyle + "text-right "}>
          <button
            onClick={props.viewInvoice}
            className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white whitespace-nowrap "
          >
            View Profile
          </button>
        </td>
      </tr>
    );

    rows.push(CleanerRow);
  }

  return <>{rows}</>;
}

export function CleanDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let currentPage = props.linkEnd;
  // path.endsWith("Dashboard")

  if (path.includes("/Dashboard/" + currentPage)) {
    currentPageStyle = "border-b border-green-500 text-gray-800";
  } else if (path.endsWith("Dashboard") && props.text == "Overview") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
  }

  return (
    <div className={currentPageStyle + " mr-8 lg:mr-5 whitespace-nowrap   "}>
      <Link to={`${path}/${props.linkEnd}`}>{props.text}</Link>
    </div>
  );
}
