import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch } from "react-router-dom";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants"
import DashBar from "../DashBar";


type Props = {
  userData: any;
};

type Contact = {
  id: number;
  name: string;
  phone: string;
  email: string;
  type: string;
  corporation: string;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;

  rolodex: Contact[];
  filter_type: string;
};

class Rolodex extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",

      rolodex: [],
      filter_type: "",
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  componentDidMount = async () => {
    let tenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    let contacts = await fetchGetJSON(Constants.API_PATH_CORP_CONTACT_GET_ALL);
    let allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);

    let people: any[] = [];

    tenants.data.forEach((tenant: any) => {
      let info = {
        id: tenant.id,
        name: tenant.first_name + " " + tenant.last_name,
        phone: tenant.phone_number,
        email: tenant.email,
        type: "Tenant",
        corporation: "",
      }
      people.push(info);
    })

    contacts.data.forEach((contact: any) => {
      let corp = allCorps.data.find(
        (corpItem: any) => contact.corporate_id === corpItem.id
      );
      let info = {
        id: contact.id,
        name: contact.first_name + " " + contact.last_name,
        phone: contact.phone_number,
        email: contact.email,
        type: "Corporate",
        corporation: corp.corporate_name,
      }
      people.push(info);
    })
    people.sort((a,b) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    this.setState({
      rolodex: people,
    })
  }

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-auto lg:rounded-tl-lg py-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1 w-full pt-16 lg:pt-6 pb-4">
          <DashBar placeholder={""}/>

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">Sales Dashboard</span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          {/* Heading Info Area  */}

          <div className="px-2 my-8 ">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between w-full mb-2 items-end">
                <span className=" pl-1 text-gray-700 font-medium text-xl">
                  Contacts
                </span>
                <div className="flex flex-row items-center text-lg  py-1  ">
                  <button className="bg-white rounded-full shadow-md h-6 w-6 ">
                    <IoIosArrowBack className="pl-1" />
                  </button>

                  <span className="px-4 text-gray-500">{currentMonth}</span>
                  <button className="bg-white rounded-full shadow-md h-6 w-6 flex flex-row items-center justify-center align-center">
                    <IoIosArrowForward className="text-sm" />
                  </button>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row w-full align-center items-center mb-6">
                {/* Search Bar */}
                <div className="flex flex-row border  bg-white rounded-sm w-full sm:w-1/4  py-1 mb-3 sm:mb-0">
                  <MdSearch className="text-xl text-gray-400 mx-1" />
                  <input
                    className="text-sm focus:border-0 focus:outline-none "
                    placeholder="Search..."
                  ></input>
                </div>

                <span className="py-1 sm:px-4 font-medium margin text-gray-500 self-start ">
                  Filters:
                </span>
                <div className="flex flex-row flex-wrap self-start">
                  <button 
                    className={
                      (this.state.filter_type == "Corporate" ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                      " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                    }
                    onClick={() => {
                      this.setState({
                        filter_type: "Corporate"
                      })
                    }}
                  >
                    Corporate
                  </button>
                  <button 
                    className={
                      (this.state.filter_type == "Tenant" ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                      " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                    }
                    onClick={() => {
                      this.setState({
                        filter_type: "Tenant"
                      })
                    }}
                  >
                    Retail
                  </button>
                  <button 
                    className={
                      (this.state.filter_type == "" ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                      " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                    }
                    onClick={() => {
                      this.setState({
                        filter_type: ""
                      })
                    }}
                  >
                    All
                  </button>
                </div>
              </div>
            </div>

            <div className=" mt-3 max-h-screen w-auto overflow-y-auto shadow-md rounded-xl scroll-hide ">
              <table
                style={{ borderSpacing: "0" }}
                className="border-separate  relative w-full table-auto bg-white     "
              >
                <thead className="border-b border-gray-400 ">
                  <tr className=" hidden lg:table-row sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                    <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                      ID
                    </th>
                    <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                      Type
                    </th>
                    <th className="sticky top-0    py-3 font-thin border-b border-gray-400 ">
                      Name
                    </th>
                    <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                      Email
                    </th>
                    <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                      Phone Number
                    </th>
                    <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                      Corporation
                    </th>
                    <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 "></th>
                    <th className="sticky top-0  text-right   py-3 font-thin border-b border-gray-400  ">
                      {" "}
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y">
                  <ContactList
                    people={this.state.rolodex}
                    filter={this.state.filter_type}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rolodex;

export function ContactList(props: any) {
  let currentDate = props.todaysDate;
  let testDueDate = new Date(2021, 7, 8, 4, 20);
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let OverDueStyle = "text-gray-700";
  let OverDueStyle2 = "text-gray-700";
  let OverDue = <></>;

  for (var i = 0; i < props.people.length; i++) {
    if (props.filter != "") {
      if (props.filter != props.people[i].type) { continue; }
    }
    if (testDueDate > currentDate) {
      OverDueStyle = "text-white text-sm  w-72";
      OverDueStyle2 = "text-red-400 mr-10";
      OverDue = (
        <span className="bg-red-500 py-1 px-4 rounded-full">Overdue</span>
      );
    }

    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    Payments = (
      <tr
        className={
          altBg +
          "  grid grid-cols-1 gap-2 lg:table-row  hover:bg-gray-100 lg:h-12 border-b border-gray-400  "
        }
      >
        <td className="  py-4 pl-5 text-purple-400 font-thin ">{props.people[i].id}</td>
        <td className=" py-3 text-gray-400 font-thin ">{props.people[i].type}</td>
        <td className={OverDueStyle + " py-3 font-thin  "}>
          <span className={OverDueStyle2}>{props.people[i].name}</span>{" "}
          {OverDue}
        </td>
        <td className="py-3  font-thin ">{props.people[i].email}</td>
        <td className=" ">{props.people[i].phone}</td>
        <td className=" ">{props.people[i].corporation}</td>
        <td className=" "></td>
        <td className=" text-right ">
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}
