import { Component } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import EditOptionPanel from "../EditOptionPanel";
import { BsFillFlagFill } from "react-icons/bs";
import UnitInfo from "./ProfileComponents/UnitInfo";
import UnitFiles from "./ProfileComponents/UnitFiles";
import Label from "../LabelWrap";
import EditOwner from "./EditOwner";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import CancelModal from "../Bookings/CancelModal";


type Props = {
  // id: number;
};

type State = {
  owner_name: string;
  owner_email: string;
  owner_phone: number;
  owner_notes: string;
  do_not_disturb: boolean;
  chargeback: number;
  editToggle: boolean;
  deleteToggle: boolean;
  units:any[];
};

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

class OwnerProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      owner_name: "default name",
      owner_email: "defaultemail@gmail.com",
      owner_phone: 519440892,
      owner_notes: '',
      do_not_disturb: false,
      chargeback: 0,
      editToggle: false,
      deleteToggle: false,
      units: null,
    };
  }

  componentDidMount = async () => {
    await this.loadData();
    store.dispatch({ type: "ownerProfileUpdate", profileUpdate: this.loadData})
  };

  loadData = async() => {
    store.dispatch({ type: "ownerCurEmail", email: this.state.owner_email });
    // let unitOwnerId = store.getState().unitOwnerReducer.curId
    let unitOwnerId = new URLSearchParams(window.location.search).get("id");
    let owner = await fetchGetJSON(
      `${Constants.API_PATH_OWNER_GET}${unitOwnerId}`
    );
    let ownerData = owner.data;
    this.setState({
      owner_name: `${ownerData.first_name} ${ownerData.last_name} (ID: ${ownerData.id})`,
      owner_email: ownerData.email,
      owner_phone: ownerData.phone_number,
      owner_notes: ownerData.owner_notes,
      do_not_disturb: ownerData.do_not_disturb,
      chargeback: ownerData.chargeback,
    });

    let units = await fetchGetJSON(
      `${Constants.API_PATH_OWNER_GET_UNITS}${unitOwnerId}`
    )
    console.log(units)
    this.setState({
      units: units.data.sort((unitsOne:any, unitsTwo:any) => unitsOne.suite_name.localeCompare(unitsTwo.suite_name))
    })
  }

  handleEdit = () => {
    alert("opens edit bar for Units");
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
      // alert("Edit options for owner should not here right now ")
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleDeleteToggle = () => {
    let toggle = this.state.deleteToggle;
    this.setState({ deleteToggle: !toggle });
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };


  render() {
    let HeatBuildingTag: any = "";
    let bgFocus;
    let ModelType = "";

    if (this.state.editToggle === true) {
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }


    return (
      <div className=" bg-white font-display lg:rounded-tl-lg  ">
        {/* <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("deleteToggle", this.state.deleteToggle)
          }
          onoff={this.state.deleteToggle}
          title={"Delete Owner"}
        /> */}
        <EditOwner
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
          ownerData={this.state}
          handleInputChange={this.handleInputChange}
          handleToggleChange={this.handleToggleChange}
        />

        {bgFocus}
        <BreadCrumb
          rootPage="Units > Owners"
          subPage={this.state.owner_name}
          sideButton=""
          buttonAction={""}
        />

        <div className="  px-4 lg:px-10 max-w-screen-xl mx-auto border rounded-lg py-10 bg-white">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-0">
            {/* Left Side  */}
            <div className="flex flex-col w-1/2">
              <h1 className="text-2xl px-2 pt-2 text-gray-700 font-black font-display flex flex-row  ">
                <span className="pr-3">{this.state.owner_name} </span>
              </h1>

              <h2 className="px-2 -mt-1 mb-1 text-md font-extralight text-gray-500 font-europe">
                Owner Email: {this.state.owner_email}
              </h2>
              <h2 className="px-2 pb-2 text-sm font-extralight text-gray-400 font-europe">
                Owner Phone Number: {this.state.owner_phone}
              </h2>
              <hr className="pt-2"/>
              <h2 className="px-2 text-sm font-extralight text-gray-600 font-europe">
                Owner Notes: <span className="text-indigo-500 text-sm">{this.state.owner_notes}</span> 
              </h2>
              <h2 className="px-2 text-sm font-extralight text-gray-600 font-europe">
                Do Not Disturb Status: {this.state.do_not_disturb ? <span className="text-green-500">True</span> : <span className="text-red-500">False</span>}
              </h2>
              <h2 className="px-2 text-sm font-extralight text-gray-600 font-europe">
                Chargeback Deductible Amount: ${this.state.chargeback}
              </h2>
            </div>

            {/* Right Side */}
            <div className="flex flex-row w-1/2 items-start justify-end">
              <EditOptionPanel
                handleEditBarToggle={this.handleEditBarToggle}
                parentData={this.state}
                panelType="Owner"
                handleFlag={false}
                handleState={this.handleToggleChange}
              />
            </div>
          </div>
          <div className="w-full border  my-4">
            <table className="table-auto w-full mb-8">
              <thead>
                <tr className=" table-row text-left font-normal text-gray-500 ">
                  <th className="py-5 pl-3  ">Unit ID</th>
                  <th className="py-2  ">Suite Name </th>
                  <th className="py-2 hidden lg:table-cell  ">Unit Number</th>
                  <th className="py-2 hidden lg:table-cell ">Location</th>
                  <th className="py-2  ">Type</th>
                  {/* Options */}
                  <th className="py-2  "></th>
                </tr>
              </thead>
                <OwnedUnitList
                  unitsData={this.state?.units}
                />
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default OwnerProfile;

export function OwnedUnitList(props:any) {
  console.log(props.unitsData)

  const handleRowClick = (id:number) => {
    const url = `${window.location.origin}/user/units/unitprofile/?id=${id}`
    window.open(url, "_blank")
  }


  return (
    <tbody>
      {
        props?.unitsData?.map((unit:any) => {
          return(<tr className=" w-full  hover:bg-gray-100 h-12 border-b border-gray-400 ">
            <td
                className={
                "pl-5 font-thin"
                }
                onClick={() => handleRowClick(unit.id)}
            >
              <h3>{unit.id}</h3>
            </td>
            <td
                className={
                    "font-thin"
                }
                onClick={() => handleRowClick(unit.id)}
            >
              <h3>{unit.suite_name}</h3>
            </td>
            <td
                className={
                    "font-thin"
                }
                onClick={() => handleRowClick(unit.id)}
            >
              <h3>{unit.suite_number}</h3>
            </td>
            <td
                className={
                    "text-purple-400 font-thin"
                }
                onClick={() => handleRowClick(unit.id)}
            >
              <h3>{unit.location}</h3>
            </td>
            <td
                className={
                    "text-purple-400 font-thin"
                }
                onClick={() => handleRowClick(unit.id)}
            >
              <h3>{unit.type}</h3>
            </td>
          </tr>)
        })
      }
    </tbody>
  )
}
