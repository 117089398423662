import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Opus/Opus.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Opus/Opus View 1.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Opus/Opus View 2.png";
import ThirdStreetView from "../../../client-facing/img/ParkingPhotos/Opus/Opus View 3.png";

function OpusParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-2/3">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-2/5"
          />
          <p className="mt-3">
            When entering the parking, please note that the garage is located to
            the right of the building entrance at 220 Victoria St. To enter the
            garage, press the number one on the remote.
          </p>
          <p className="mt-3">
            Once inside the garage, you will find two pathways. There is a lower
            pathway and an upper pathway on the left. Residents should take the
            left upper pathway. Follow the ramps that go upwards with a one-way
            sign all the way to P5. Around P2, there is another garage door that
            can be opened with the remote. Press number 1 or 2 on the remote to
            open it.
          </p>
          <p className="mt-3 mb-5 md:mb-0">
            You will know that you are in the correct spot when you are unable
            to go up to another level. This parking spot is located on the
            highest level.
          </p>
          <img
            src={ThirdStreetView}
            alt="Google Maps Street View"
            className=""
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-2/5">
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-2/5 w-full mb-5 p-5"
          />
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="h-2/5 w-full mb-5 p-5"
          />
        </div>
      </div>
    </div>
  );
}

export default OpusParking;
