export type State = {
  bookings: any[];
  curId: number;
  invoiceId: number;
  newBooking: any;
  bookingTenant: {};
  corpName: string;
  adminEmail: string;
  cleanEmail: string;
  finEmail: string;
  listUpdate: any;
  corporate_deduction: number;
  deduction_percent: boolean;
  tenantId: number;
  profileUpdate: undefined
};

const initialState: State = {
  bookings: [],
  curId: -1,
  invoiceId: -1,
  newBooking: undefined,
  bookingTenant: {},
  corpName: "",
  adminEmail: "",
  cleanEmail: "",
  finEmail: "",
  listUpdate: undefined,
  corporate_deduction: 0,
  deduction_percent: true,
  tenantId: -1,
  profileUpdate: undefined
};

type Action =
  | { type: "addBooking"; newBooking: { id: number } }
  | { type: "removeBooking"; id: number }
  | { type: "setBooking"; booking: {} }
  | { type: "bookingIdTransfer"; id: number }
  | { type: "setBookingTenant"; tenant: {} }
  | { type: "setCorpName"; corpName: string }
  | { type: "setAdmin"; adminEmail: string }
  | { type: "setClean"; cleanEmail: string }
  | { type: "setFinance"; finEmail: string }
  | { type: "bookingListUpdate"; listUpdate: any }
  | { type: "setBookingCorpDeductionType"; deduction_type: boolean }
  | { type: "setBookingCorpDeduction"; deduction: number }
  | { type: "bookingProfileUpdate"; profileUpdate: any };

export const bookingReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "addBooking":
      state.bookings.push(action.newBooking);
      return state;
    case "bookingIdTransfer":
      state.curId = action.id;
      return state;
    case "setBooking":
      state.newBooking = action.booking;
      return state;
    case "setBookingTenant":
      state.bookingTenant = action.tenant;
      return state;
    case "setCorpName":
      state.corpName = action.corpName;
      return state;
    case "setAdmin":
      state.adminEmail = action.adminEmail;
      return state;
    case "setClean":
      state.cleanEmail = action.cleanEmail;
      return state;
    case "setFinance":
      state.finEmail = action.finEmail;
      return state;
    case "bookingListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    case "setBookingCorpDeduction":
      state.corporate_deduction = action.deduction;
      return state;
    case "setBookingCorpDeductionType":
      state.deduction_percent = action.deduction_type;
      return state;
    case "bookingProfileUpdate": 
      state.profileUpdate = action.profileUpdate;
      return state;
    default:
      return state;
  }
};

export default bookingReducer;
