
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../BreadCrumb";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import TabList from "../../../TabList";
import Label from "../../../LabelWrap";
import { Button } from "@mui/material";
import { MdEdit, MdOpenInNew, MdCheck, MdCheckBoxOutlineBlank, MdCheckCircle } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import { useNotification } from "../../../Notifications/NotificationProvider";
import CancelModal from "../../../Bookings/CancelModal";
import store from "../../../../redux/store";
import EditParking from "../Update/EditParking";
import ModifyParking from "./ModifyParking";
import EditStartDateModal from "../Update/EditStartDateModal";
import ShortenParking from "../Update/ShortenParking";
import ExtendParking from "../Update/ExtendParking";
import { FiCornerUpRight } from "react-icons/fi";
import TransferParking from "../Update/TransferParking";

type Props = {
    history: any;
    location: any;
}

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

function ParkingProfile(props:Props) {
    const [parkingID, setParkingID] = useState(-1)
    const [parkingInfo, setParkingInfo] = useState(null)

    //Toggles:
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [editToggle, setEditToggle] = useState(false)

    const [startDateToggle, setStartDateToggle] = useState(false)

    const [shortenToggle, setShortenToggle] = useState(false)
    const [extendToggle, setExtendToggle] = useState(false) //Different from extending from booking, will automatically extend the parking and will not wait for a parking extension to be made
    const [startState, setStartState] = useState('') // This variable will either be start or late depending on field

    const [transferToggle, setTransferToggle] = useState(false)
    //-------------

    const [transferState, setTransferState] = useState(false); //False means that it is a pre-transfer parking, true is a standard transfer parking

    //Notification Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    useEffect(() => {
        loadData()

        //Store Load Data in reducer:
        store.dispatch({
            type: "parkingProfileUpdate",
            profileUpdate: loadData,
        });
    }, [])

    const loadData = async() => {
        const getParkingID = +new URLSearchParams(window.location.search).get("id")
        setParkingID(getParkingID)
        const getParkingInfo = await fetchGetJSON(`${Constants.API_PATH_PARKING_FULLINFO}${getParkingID}`)
        //Need to load in full info of the parking
        setParkingInfo(getParkingInfo.data)


        //Transfer Logic:
        const checkInDate = dayjs.tz(getParkingInfo.data?.parkingInfo.start_date, 'America/Toronto')
        const todaysDate = dayjs.tz(new Date(), 'America/Toronto')
        const isTransfer = todaysDate.isSame(checkInDate, 'day') && todaysDate.hour() >= 18 || todaysDate.isAfter(checkInDate, 'day')
        setTransferState(isTransfer)
    }

    const captureID = async(booking_id:number) => {
        const url = `${window.location.origin}/user/bookings/bookingprofile?id=${booking_id}`
        window.open(url, "_blank")
    }

    const confirmParking = async () => {
        if(!parkingInfo.parkingInfo.cancelled) {
            const confirmStatus = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_CONFIRM, {
                parking_id: parkingID
            })
            if(confirmStatus.status == "Success") {
                NotificationHandler('Success', 'Success', 'Succesfully Confirmed Parking')
                loadData()
                return
            } else {
                NotificationHandler('Error', 'Error', confirmStatus.data)
                return
            }
        } else {
            NotificationHandler('Error', 'Error', 'You cannot confirm a cancelled parking')
            return
        }
    }

    //Modal Functions to turn on Modals
    //Future Considerations: Need to make this all into one function 

    const handleStartDate = async (editState:string) =>{
        //Edit State will either be a string saying "Early" or "Late" 
        //Turn on Handle Start Date 
        if(!parkingInfo?.parkingInfo.cancelled) {
            const toggle = startDateToggle;
            setStartState(editState)
            if (toggle === true) {
                setStartDateToggle(false)
            } else {
                setStartDateToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking is cancelled. No edits can be made.')
        }
    }

    const handleCancelToggle = () => {
        if(!parkingInfo?.parkingInfo.cancelled) {
            const toggle = deleteToggle
            if (toggle === true) {
                setDeleteToggle(false)
            } else {
                setDeleteToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking is cancelled. No edits can be made.')
        }
    }

    const handleEditToggle = () => {
        if(!parkingInfo?.parkingInfo.cancelled) {
            const toggle = editToggle
            if (toggle === true) {
                setEditToggle(false)
            } else {
                setEditToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking is cancelled. No edits can be made.')
        }
    }

    const handleShortenToggle = () => {
        if(!parkingInfo?.parkingInfo.cancelled) {
            const toggle = shortenToggle
            if (toggle === true) {
                setShortenToggle(false)
            } else {
                setShortenToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking is cancelled. No edits can be made.')
        }
    }

    const handleExtendToggle = () => {
        if(!parkingInfo?.parkingInfo.cancelled) {
            const toggle = extendToggle
            if (toggle === true) {
                setExtendToggle(false)
            } else {
                setExtendToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking is cancelled. No edits can be made.')
        }
    }

    const handleTransferToggle = () => {
        if(!parkingInfo?.parkingInfo.cancelled) {
            const toggle = transferToggle
            if (toggle === true) {
                setTransferToggle(false)
            } else {
                setTransferToggle(true)
            }
        } else {
            NotificationHandler('Error', 'Error', 'This parking is cancelled. No edits can be made.')
        }
    }
    //----------------------------------------

    return (
        <div>
            <div className=" bg-white font-display lg:rounded-tl-lg  ">
                {/* Turns off our edit modal as it sets the whole background as a clickable zone to toggle edit */}
                {
                    editToggle &&
                    <div
                        onClick={handleEditToggle}
                        className=" fade-bg w-full h-screen bg-black z-40  fixed"
                    ></div>
                }
                <EditStartDateModal
                    onOff={startDateToggle}
                    parkingInfo={parkingInfo?.parkingInfo}
                    handleStartDateToggle={() => 
                        handleStartDate(startState)
                    }
                    state={startState}
                />
                <ShortenParking
                    parkingData={parkingInfo?.parkingInfo}
                    open={shortenToggle}
                    closeModal={handleShortenToggle}
                />  
                <ExtendParking
                    parkingData={parkingInfo?.parkingInfo}
                    open={extendToggle}
                    closeModal={handleExtendToggle}
                />
                <CancelModal
                    handleModalToggle={() =>
                        handleCancelToggle()
                    }
                    onoff={deleteToggle}
                    title={"Cancel Parking"}
                />
                <EditParking
                    handleEditBarToggle={() => 
                        handleEditToggle()
                    }
                    toggle={editToggle}
                    parkingInfo={parkingInfo?.parkingInfo}
                />
                {
                    transferToggle && 
                    <TransferParking
                        title={!transferState ? 'Pre Check-In Parking Transfer' : 'Post Check-In Parking Transfer'}
                        toggle={transferToggle}
                        parkingInfo={parkingInfo?.parkingInfo}
                        handleModal={handleTransferToggle}
                    />
                }
                <BreadCrumb
                    rootPage={"Parking > Parking Profile"}
                    subPage={`Profile #${parkingID}`}
                    sideButton=""
                    buttonAction={""}
                />
                <div className={`opacity-100 px-4 lg:px-10 max-w-screen-xl mx-auto border rounded-lg pt-10 pb-32 bg-white`}>
                    {/* Heading Info Area  */}
                    <div className="flex flex-col lg:flex-row lg:pb-0">
                        {/* Left Side  */}
                        <div className="flex flex-col lg:w-2/3 mb-4">
                            <div className="flex flex-row items-center space-x-3">
                                <h1 className=" text-3xl lg:text-2xl px-2 pt-3 text-gray-700 font-black font-display">Parking ID #{parkingInfo?.parkingInfo?.id} </h1>
                            </div>

                            <div className="flex flex-col lg:flex-row text-sm text-gray-600">
                                <h2 className="px-2 pt-1  font-normal font-display ">Created At (EST): {""}
                                    {new Date(parkingInfo?.parkingInfo?.created_at).toLocaleString('en-US', {
                                        timeZone: 'America/New_York',
                                    })}
                                </h2>
                                <h2 className=" px-2 pt-1  font-normal font-display ">Updated At (EST): {""}
                                    {new Date(parkingInfo?.parkingInfo?.updated_at).toLocaleString('en-US', {
                                        timeZone: 'America/New_York',
                                    })}
                                </h2>
                            </div>
                            <div className="flex flex-col lg:flex-row text-sm text-gray-600">
                                <h2 className="px-2 pt-1  font-normal font-display ">Start Date: {parkingInfo?.parkingInfo?.start_date}</h2>
                                <h2 className=" px-2 pt-1  font-normal font-display ">End Date: {parkingInfo?.parkingInfo?.end_date}</h2>
                            </div>
                            {/* Below div shows tags such as Confirmed, Cancelled, etc. */}
                            <div className="flex flex-row w-full justify-start mt-3 pl-3 flex-wrap">
                                {
                                    parkingInfo?.parkingInfo?.confirmed &&  
                                    <span className="px-3 mr-2 border border-green-200 rounded-full bg-green-100 text-green-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0" key={'confirmedParking'}>
                                        Confirmed
                                    </span>
                                }
                                {
                                    parkingInfo?.parkingInfo?.cancelled &&  
                                    <span className="px-3 mr-2 border border-red-200 rounded-full bg-red-100 text-red-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0" key={'cancelledParking'}>
                                        Cancelled
                                    </span>
                                }
                                {
                                    (!parkingInfo?.parkingInfo?.cancelled && !parkingInfo?.parkingInfo?.confirmed) &&
                                    <span className="px-3 mr-2 border border-indigo-200 rounded-full bg-indigo-100 text-indigo-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0" key={'tentativeParking'}>
                                        Tentative Parking
                                    </span>
                                }
                                {
                                    parkingInfo?.parkingInfo.booking_id != -1 &&
                                    <span className="px-3 mr-2 border border-blue-200 rounded-full bg-blue-100 text-blue-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0" key={'bookingParking'}>
                                        Booking Associated Parking
                                    </span>
                                }
                                {
                                    parkingInfo?.parkingInfo.booking_id == -1 &&
                                    <span className="px-3 mr-2 border border-amber-200 rounded-full bg-amber-100 text-amber-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0" key={'bookingParking'}>
                                        Non Booking Associated Parking
                                    </span>
                                }
                            </div>

                            {
                                parkingInfo?.parkingInfo?.booking_id != -1 &&
                                <button 
                                    onClick={()=> captureID(parkingInfo?.parkingInfo?.booking_id)}
                                    type="button" 
                                    className="mt-3 w-1/3 
                                        text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:opacity-75 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                >                           
                                    Navigate to Booking Profile
                                </button>
                            }
                            <span className=" text-sm px-2 pt-4 font-normal font-display ">
                                Parking Recipient:{" "}
                                <span className="text-gray-500">{parkingInfo?.parkingInfo?.tenant.first_name} {parkingInfo?.parkingInfo?.tenant.last_name}</span>
                            </span>
                            {
                                parkingInfo?.parkingInfo?.tenant.email &&
                                <span className=" text-sm px-2 pt-1 font-normal font-display ">
                                Parking Recipient Email:{" "}
                                <span className="text-gray-500">{parkingInfo?.parkingInfo?.tenant.email}</span>
                            </span>
                            }
                            <span className=" text-sm px-2 pt-1 font-normal font-display ">
                                Parking Spot Location:{" "}
                                <span className="text-gray-500">{parkingInfo?.parkingSpotInfo?.parking_spot_location}</span>
                            </span>
                            <span className=" text-sm px-2 pt-1 font-normal font-display ">
                                Parking Spot Level:{" "}
                                <span className="text-gray-500">{parkingInfo?.parkingSpotInfo?.parking_spot_level}</span>
                            </span>
                            <span className=" mr-4 pt-1 align-middle font-hairline text-sm px-2">
                                Parking Notes:
                                <div className="text-indigo-300">{parkingInfo?.parkingInfo?.parking_notes}</div>
                            </span>
                            <button type="button" className="mt-3 w-2/5 text-white bg-gradient-to-br from-green-400 to-blue-600 hover:opacity-75 focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                                <a target="_blank" href={`/user/parkings/parkingspotprofile/?id=${parkingInfo?.parkingInfo?.parking_spot_id}`}>
                                    Navigate to Parking Spot Profile
                                </a>
                            </button>
                        </div>
                        {/* Right Side */}
                        <div className="flex flex-row  w-1/2 items-start justify-end">
                            <button
                                onClick={() => handleTransferToggle()}
                                key={"Transfer"}
                                className="group pb-1"
                            >
                                <span className="font-display text-gray-500 group-hover:text-indigo-400 text-xs mx-auto ">
                                Transfer
                                </span>
                                <FiCornerUpRight className="text-indigo-500 group-hover:text-indigo-400 text-xl  mx-auto" />
                            </button>
                            <button
                                key={"Confirm"}
                                onClick={() => confirmParking()}
                                className="mx-2 group pb-1"
                            >
                                <span className="font-display text-gray-500 group-hover:text-green-400 text-xs mx-auto ">
                                Confirm
                                </span>
                                <MdCheckCircle className="text-green-500 group-hover:text-green-400 text-xl  mx-auto" />
                            </button>
                            <button
                                key={"Edit"}
                                onClick={() => handleEditToggle()}
                                className="mx-2 group pb-1"
                            >
                                <span className="font-display text-gray-500 group-hover:text-blue-400 text-xs mx-auto ">
                                Edit
                                </span>
                                <MdEdit className="text-gray-500 group-hover:text-blue-400 text-xl  mx-auto" />
                            </button>
                            <button
                                onClick={() => handleCancelToggle()}
                                key={"Delete"}
                                className="mx-2 group pb-1"
                            >
                                <span className="font-display text-gray-500 group-hover:text-red-400 text-xs mx-auto ">
                                Delete
                                </span>
                                <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
                            </button>
                        </div>
                    </div>

                    {/* Tab List Area below Heading Area */}
                    <TabList warning={false}>
                        <Label label="Modify Parking">
                            <ModifyParking
                                parkingInfo={parkingInfo?.parkingInfo}
                                handleStartDateModal={handleStartDate}
                                handleShortenModal={handleShortenToggle}
                                handleExtendModal={handleExtendToggle}
                            />
                        </Label>
                        <Label label="Invoices (Future Integration)">
        
                        </Label>
                    </TabList>
                </div>
            </div>
    </div>
    )
}

export default ParkingProfile;
