import { Component } from "react";

type Props = {
  message: string;
  position: string;
};

interface ToolTipState {
  displayTooltip: boolean;
}

class ToolTipAlt extends Component<Props, ToolTipState> {
  constructor(props: any) {
    super(props);

    this.state = {
      displayTooltip: false,
    };
    this.hideTooltip = this.hideTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
  }

  hideTooltip() {
    this.setState({ displayTooltip: false });
  }
  showTooltip() {
    this.setState({ displayTooltip: true });
  }

  render() {
    let message = this.props.message;
    let position = " bottom-full left-1/2 pb-5 transform -translate-x-1/2";

    if (this.props.position === "bottom") {
      position = "top-full left-1/2 pt-2 transform -translate-x-1/2";
    }

    return (
      <span className="" onMouseLeave={this.hideTooltip}>
        {this.state.displayTooltip && (
          <div className={`fade-in absolute z-50  ` + position}>
            <div className=" text-center px-4 rounded-sm text-light bg-gray-800 py-1 opacity-60 text-xs max-w-lg text-white">
              {message}
            </div>
          </div>
        )}
        <span className=" inline-block " onMouseOver={this.showTooltip}>
          {this.props.children}
        </span>
      </span>
    );
  }
}

export default ToolTipAlt;
