import { Component } from "react";
import { AiOutlinePaperClip, AiOutlineFileText } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { IoIosArrowBack } from "react-icons/io";
import { BsFillPersonPlusFill } from "react-icons/bs";
import ToggleSwitch from "../ToggleSwitch";
import { TiDelete } from "react-icons/ti";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
// import { truncate } from "fs";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
};

type ModalState = {
  title: string;
  onoff: boolean;

  fname: string;
  lname: string;
  email: string;
  hasCountryCode: boolean;
  country_code: string;
  phone: string;
  hasPhoneExtension: boolean;
  phone_extension: string;
  finance: boolean;
  cleaning: boolean;
  admin: boolean;
  corporates: string [];
  corporate_name: string;
};

class NewContact extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      
      fname: "",
      lname: "",
      email: "",
      hasCountryCode: false,
      country_code: "",
      phone: "",
      hasPhoneExtension: false,
      phone_extension: "",
      finance: false,
      cleaning: false,
      admin: false,
      corporates: [],
      corporate_name: ""
    };

    this.props.ErrorNote.bind(this);
  }

  resetFields = () => {
    this.setState({
      fname: "",
      lname: "",
      email: "",
      hasCountryCode: false,
      country_code: "",
      phone: "",
      hasPhoneExtension: false,
      phone_extension: "",
      corporate_name: "",
      finance: false,
      cleaning: false,
      admin: false,
    })
  }

  componentDidMount = async () => {
    //get all corporate names
    const allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
    if (allCorps.status === "Success") {
      this.setState({corporates: allCorps.data});
    }
  }

  handleClick = async () => {
    let NotificationHandler = this.props.ErrorNote;
    const corpChosen: any = this.state.corporates.find((corp: any) => corp.corporate_name === this.state.corporate_name);

    let phone_number: string = '';

    if (this.state.hasCountryCode) {
      phone_number += `${this.state.country_code} `;
    }
    phone_number += this.state.phone;
    if (this.state.hasPhoneExtension) {
      phone_number += `x${this.state.phone_extension}`;
    }

    const phoneReg: RegExp = new RegExp(/^(\+(\d+) )?(\d+)(x(\d+))?$/);
    if (phone_number && !phoneReg.test(phone_number)) {
      NotificationHandler(
        "Error",
        "Error",
        "Please only enter numbers for a phone number as well as its country code and extension fields"
      );
      return;
    }

    let body = {
      first_name: this.state.fname,
      last_name: this.state.lname,
      email: this.state.email,
      phone_number: phone_number ?? null,
      corporate_id: corpChosen.id
    }

    let contact:any = {...body}
    if (this.state.admin) {
      contact.admin = this.state.admin
    } else if (this.state.finance) {
      contact.finance = this.state.finance
    } else if (this.state.cleaning) {
      contact.cleaning = this.state.cleaning
    }

    if (this.state.fname.trim() === "" || this.state.lname.trim() === "" || this.state.email.trim() === "" || this.state.corporate_name === "") {
      NotificationHandler(
        "Error",
        "Missing information",
        ""
      );
      return;
    }
    else if ((this.state.country_code || this.state.phone_extension) && !this.state.phone) {
      NotificationHandler(
        "Error",
        "Missing information",
        "Phone number must be filled out if there is a country code or phone extension"
      );
      return;
    }

    let res1 = await this.createModel(
      Constants.API_PATH_CORP_CONTACT_CREATE,
      contact
    );
    console.log(res1.status)
    if (res1.status === "Success") {
      NotificationHandler(
        "Success",
        "Success",
        "Contact has been created."
      ); //Types : SUCCESS, ERROR, INFO, WARNING

      // let updateList = store.getState().corpContactReducer.listUpdate;
      // updateList();
      // let updateEdit = store.getState().corpContactReducer.corpListUpdate;
      // updateEdit();
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }

      this.resetFields();
      this.props.handleModalToggle(this.props.onoff);
    }
    else {
      NotificationHandler(
        "Error",
        "Warning",
        `${res1.status}`
      )
    }
  }; 

  createModel = async (link: string, createBody: any) => {
    return await fetchRequestJSON("POST", link, createBody);
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};

      if (this.target.id === 'country_code') {
        const countryCodeReg: RegExp = new RegExp(/^\+?(\d+)?$/);
        
        if (!countryCodeReg.test(this.target.value)) {
          return;
        }

        if (!this.target.value.includes('+') && this.target.value) {
          this.target.value = '+' + this.target.value;
        }
        else if (this.target.value.length === 1) {
          this.target.value = '';
        }
      }
      else if (this.target.id === 'phone' || this.target.id === 'phone_extension') {
        const numberReg: RegExp = new RegExp(/^(\d+)?$/);

        if (!numberReg.test(this.target.value)) {
          return;
        }
      }

      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4 overflow-y-scroll scroll-hide my-10 border-gray-600 max-w-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <BsFillPersonPlusFill className="text-gray-600 text-3xl mb-3 fixed" />

          <span className="text-xl font-bold text-gray-800 text-left mt-10">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form className="w-full">
            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">First Name<span className="text-red-500">*</span></span>
                <input
                  id="fname"
                  onChange={this.handleInputChange}
                  value={this.state.fname}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Last Name<span className="text-red-500">*</span></span>
                <input
                  id="lname"
                  onChange={this.handleInputChange}
                  value={this.state.lname}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Email<span className="text-red-500">*</span></span>
                <input
                  id="email"
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <div className="flex w-full mt-5 mb-0 items-center justify-between">
              <div className="flex flex-col items-start">
                <span className={"transition-all duration-600 text-gray-600 text-sm mb-1 whitespace-nowrap"}>Country Code</span>
                <ToggleSwitch
                  id="hasCountryCode"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.hasCountryCode}
                />
              </div>

              <div className="flex flex-col items-start">
                <span className={"transition-all duration-600 text-gray-600 text-sm mb-1 whitespace-nowrap"}>Extension</span>
                <ToggleSwitch
                  id="hasPhoneExtension"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.hasPhoneExtension}
                />
              </div>
            </div>

            <div className="flex flex-row w-full mt-0 mb-5">
              <label className={`transition-all duration-500 ease-in-out transform ${this.state.hasCountryCode ? 'w-3/12 pr-2' : 'w-0'} overflow-hidden`}>
                <span className="text-gray-400 text-xs whitespace-nowrap overflow-hidden text-ellipsis">Country Code</span>
                <input
                  id="country_code"
                  onChange={this.handleInputChange}
                  value={this.state.country_code}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="+"
                />
              </label>
              <label className={`transition-all duration-500 ease-in-out transform flex-grow overflow-hidden`}>
                <span className="text-gray-400 text-xs">Phone</span>
                <input
                  id="phone"
                  onChange={this.handleInputChange}
                  value={this.state.phone}
                  type="text"
                  className={`mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black`}
                  placeholder=""
                />
              </label>
              <div className={`transition-all duration-500 ease-in-out transform ${this.state.hasPhoneExtension ? 'scale-100 opacity-100 w-1/12' : 'scale-0 opacity-0 w-0'} justify-center align-center flex items-end`}>
                <span className="text-gray-400 pb-1.5">x</span>
              </div>
              <label className={`transition-all duration-500 ease-in-out transform ${this.state.hasPhoneExtension ? 'w-2/12' : 'w-0'} overflow-hidden`}>
                <span className="text-gray-400 text-xs whitespace-nowrap overflow-hidden text-ellipsis">Extension</span>
                <input
                  id="phone_extension"
                  onChange={this.handleInputChange}
                  value={this.state.phone_extension}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              </label>
            </div>

            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Corporate Name<span className="text-red-500">*</span></span>
                <select
                  id="corporate_name"
                  onChange={this.handleInputChange}
                  value={this.state.corporate_name}
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                >
                  <option value="" disabled selected>Choose a corporation</option>
                  {this.state.corporates.map((corp: any) => <option value={corp.corporate_name} key={corp.corporate_id}>{corp.corporate_name}</option>)}
                </select>
              </label>

              <div className={" block  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Finance
                  </span>

                  <ToggleSwitch
                    id="finance"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.finance}
                  />
                </div>
              </div>

              <div className={" block  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Cleaning
                  </span>

                  <ToggleSwitch
                    id="cleaning"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.cleaning}
                  />
                </div>
              </div>

              <div className={" block "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Admin
                  </span>

                  <ToggleSwitch
                    id="admin"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.admin}
                  />
                </div>
              </div>

            </div>

            {/* {toggles} */}

            <div className="flex flex-row justify-start w-full mt-6">
              <button
                type="button"
                onClick={() => this.handleClick()}
                className="transition  duration-200 w-full hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Add Contact
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewContact);

export function ContactCard(props: any) {
  var id = props.contactType.toLowerCase();
  let name;
  let email;
  let phone;
  let corporate_id;

  if (props.contactType === "Admin") {
    name = props.data.admin_contact;
    email = props.data.admin_contact_email;
    phone = props.data.admin_contact_phone;
    corporate_id = props.data.admin_contact_corporate_id
  }
  if (props.contactType === "Finance") {
    name = props.data.finance_contact;
    email = props.data.finance_contact_email;
    phone = props.data.finance_contact_phone;
    corporate_id = props.data.finance_contact_corporate_id;
  }
  if (props.contactType === "Cleaning") {
    name = props.data.cleaning_contact;
    email = props.data.cleaning_contact_email;
    phone = props.data.cleaning_contact_phone;
    corporate_id = props.data.cleaning_contact_corporate_id;
  }

  return (
    <div className="flex flex-col px-4 py-3 h-auto border-2 rounded-sm border-solid border-gray-100 ">
      <div className="flex flex-row w-full  items-center">
        <span className="font-medium text-gray-700 text-md pb-1 ">
          {props.contactType} -
          <input
            id={id + "_contact"}
            type="text"
            className=" transition-all duration-200 font-medium text-gray border-0 p-0  pl-1 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
            value={name}
            onChange={props.stateChange}
            placeholder="Click To Edit Name"
          />
        </span>
      </div>

      <span className="font-regular text-gray-400 text-2xs pb-4">
        Corporate - {props.data.corporation}
      </span>
      <input
        id={id + "_contact_email"}
        type="text"
        className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
        value={email}
        onChange={props.stateChange}
        placeholder="Click To Edit Email"
      />
      <span className="flex flex-row items-center font-regular text-gray-400 text-xs ">
        <i className="text-gray-500 mr-1 ">
          <FaPhoneAlt />
        </i>
        <input
          id={id + "_contact_phone"}
          type="text"
          className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
          value={phone}
          onChange={props.stateChange}
          placeholder="Click To Edit Phone"
        />
      </span>
    </div>
  );
}