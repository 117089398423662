import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { fetchGetJSON, regGet } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { useParams } from "react-router";

const Body = (props: any) => {
  const { unitId } = useParams<{ unitId: string }>();
  const [unit, setUnit] = useState<any>();
  const [count, setCount] = useState(1);
  let id = count;
  const testingJsonFunction = async () => {
    let signature = new URLSearchParams(window.location.search).get(
      "signature"
    );
    let unit = await regGet(
      `${Constants.API_PATH_GET_UNIT_DATA_PUBLIC}${unitId}?signature=${signature}`
    );
    setUnit(unit);
  };
  useEffect(() => {
    console.log(unitId)
    testingJsonFunction();
  }, []);

  return (
    <div className="bg-white">
      <div className="space-y-5 px-4 md:px-8 lg:px-24 xl:px-56">
        <div>
          <div className="flex flex-col justify-between space-y-3">
            <h3 className="h3 mt-4">Parking</h3>
            <p className="p">
              Subject to availability. Please contact{" "}
              <b>reservations@skyviewsuites.com</b> to arrange parking permits.
            </p>
            <h3 className="h3">Your Parking Spot</h3>
            <div className="box">
              <div className="text-center uppercase">
                <b className="p">N/A</b>
              </div>
            </div>
            <Divider />
          </div>
        </div>
        <div className="space-y-3">
          <h3 className="h3">Amenities</h3>
          <p className="p">{unit?.building.amenities}</p>
          <Divider />
        </div>
        <div className="space-y-3">
          <h3 className="h3">Special Instructions</h3>
          <p className="p">{unit?.building.special_instructions}</p>
          <Divider />
        </div>
        <div className="flex flex-col justify-between">
          <div className="space-y-3">
            <h3 className="h3">Internet</h3>
            <div>
              <p className="p">Wireless Network Name: SVS</p>
              <p className="p">Password: skyviewsuites</p>
            </div>
            <Divider />
          </div>
        </div>
        <div className="space-y-3">
          <div>
            <h3 className="h3">Need Help?</h3>
          </div>
          <div>
            <b className="p">
              Our help desk is open Monday - Saturday 9am to 2am
            </b>
            <p className="p">Any Questions: www.help.skyviewsuites.com</p>
            <p className="p">
              Can't find what you are looking for? Submit a request by clicking
              Submit a request in the top right!{" "}
            </p>
            <p className="p pb-3">
              Internet/TV/Phone Questions:{" "}
              <b>Frontline: (647) 793-8570 Rogers: 1 (888) 764-3771</b>
            </p>
            <Divider />
          </div>
        </div>
        <div className="space-y-3 pb-6">
          <h3 className="h3">1 (888) 787-7829</h3>
          <h3 className="h3">www.help.skyviewsuites.com</h3>
        </div>
      </div>
    </div>
  );
};

export default Body;
