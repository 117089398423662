import { useEffect, useState } from "react";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import Box from "@mui/material/Box";
import DashBar from "../DashBar";
import { fetchGetJSON, fetchRequestJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Cleaning } from "../../../utils/Types";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const CleaningPortal = () => {
  const [schedule, setSchedule] = useState<Cleaning[]>([]);
  const [displayDate, setDisplayDate] = useState<any>(dayjs().startOf("date"));
  const [userId, setUserId] = useState<string>("");
  const [userFirstName, setUserFirstName] = useState<string>("");
  const currentTime = dayjs().format("HH");
  const greeting =
    currentTime >= 17 ? "Evening" : currentTime >= 12 ? "Afternoon" : "Morning";

  const getCleaningSchedule = async () => {
    const cleaningSchedule = await fetchGetJSON(`${Constants.API_PATH_CLEANER_SCHEDULE}${userId}?date=${displayDate.format("YYYY-MM-DD")}`,
      // { date: displayDate.format("YYYY-MM-DD") }
    );

    if (cleaningSchedule.status.includes("Success")) {
      setSchedule(cleaningSchedule.data);
    } else {
      console.log(cleaningSchedule);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      const user = await getCurrentUser();
      const currentUser = await fetchUserAttributes();
      setUserId(user.username);
      setUserFirstName(
        currentUser.name ? currentUser.name.split(" ")[0] : "N/A"
      );
      console.log(user.username);
    };
    getUserData();

    if (userId.length) {
      getCleaningSchedule();
    }
  }, [displayDate, userId]);

  const nextDay = () => {
    const nextDay = displayDate.add(1, "day");
    setDisplayDate(nextDay);
  };

  const previousDay = () => {
    const previousDay = displayDate.subtract(1, "day");
    setDisplayDate(previousDay);
  };

  return (
    <div className="font-display bg-f9 h-screen lg:rounded-tl-lg pt-3 lg:pt-0">
      <div className="fade-in px-4 lg:px-14">
        <div className=" flex flex-row justify-between font-circular pl-1  pt-16 lg:pt-6 pb-4">
          <DashBar placeholder={""} />
          <div className="lg:flex flex-col hidden ">
            <span className="text-gray-900 font-medium">Cleaner Portal</span>
            <span className=" text-right text-sm text-gray-400 font-sofia">
              {dayjs().format("dddd, MMMM DD, YYYY")}
            </span>
          </div>
        </div>

        <div className="w-full">
          <div className="pt-0 pb-5">
            <div className=" col-start-1 col-end-5 flex flex-col">
              <span className=" pl-1 text-3xl lg:text-4xl font-medium text-gray-700">
                Good {greeting}, {userFirstName}
              </span>
              <span className=" ml-1 pt-2 font-thin text-gray-600 font-sofia">
                Let&apos;s Get To Work Today
              </span>
            </div>
          </div>
          <div className="bg-white py-7 mt-3 mb-36 rounded-xl shadow-md">
            <div className="w-full mb-3 pr-5">
              <div className="mb-5 px-7 text-xl font-medium text-gray-800 ">
                My Cleaning Schedule
              </div>
            </div>
            <div className="w-full ">
              <div className="w-full text-white text-center border-b border-t border-gray-800">
                <Box
                  sx={{
                    bgcolor: "#10B981",
                    height: 60,
                    fontWeight: "medium",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    fontSize: 18,
                  }}
                >
                  <button onClick={previousDay} className="mr-3">
                    <FaArrowLeft className="text-white text-2xl hover:text-gray-200" />
                  </button>
                  {displayDate.format("dddd, MMMM DD, YYYY")}
                  <button onClick={nextDay} className="ml-3">
                    <FaArrowRight className="text-white text-2xl hover:text-gray-200" />
                  </button>
                </Box>
              </div>
            </div>
            {!schedule.length ? (
              <div className="w-full px-12 py-5 mx-auto text-lg text-gray-800 ">
                You have no upcoming cleanings on&nbsp;
                {displayDate.format("dddd, MMMM DD, YYYY")}.
              </div>
            ) : (
              <div className="w-full">
                {schedule.map((cleaning) => (
                  <div
                    className="w-full mx-auto border-t border-gray-400"
                    key={cleaning.id}
                  >
                    <div className="my-7 block text-lg leading-9 px-12">
                      {cleaning.cleaning_type
                        .split(" ")
                        .map(
                          (word) => word[0].toUpperCase() + word.substring(1)
                        )
                        .join(" ")}
                      : {cleaning.title} {cleaning.unit?.suite_name} <br />
                      Address: {cleaning.unit?.location} <br />
                      Unit Number: {
                        cleaning.unit?.suite_number
                      }{" "}
                      <br /> <br />
                      Notes: {cleaning.notes}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Empty Div for margin bottom to work */}
          <div className="flex"></div>
        </div>
      </div>
    </div>
  );
};

export default CleaningPortal;
