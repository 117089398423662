import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import { FaPhoneAlt } from "react-icons/fa";
import ToggleSwitch from "../ToggleSwitch";
import { Link, withRouter } from "react-router-dom";
import KeysInfo from "../Bookings/KeysInfo";
import Modal from "../Modal";
import { ImKey } from "react-icons/im";
import { MdNfc } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { RiParkingBoxFill } from "react-icons/ri";
import { FiCheckCircle } from "react-icons/fi";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import store from "../../redux/store";

type Props = {
  history: any;
};

interface CheckInSummaryState {
  booking_id: number;
  confirmed: boolean;
  // parking: boolean;
  tenant_name: string;
  tenant_email: string;
  tenant_phone: number;
  check_in_notes: string;
  monthly_rate: number;
  // check_in: Date;  /* TODO: Rename this attribute to check_in_date. */
  suite: string;
  suite_address: string;
  suite_number: string;
  suite_type: string;

  fob_count: number;
  key_count: number;
  corporate_id: string;
  mailkey_count: number;
  park_fob_count: number;
  num_outstanding_invoices: number;
  // toggleModal: boolean;
  // checkin_file: string;
}

class CheckInSummary extends Component<any, CheckInSummaryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      booking_id: -1,
      confirmed: false,
      // parking: false,
      suite: "",
      suite_address: "",
      suite_number: "",
      suite_type: "",
      fob_count: 0,
      key_count: 0,
      mailkey_count: 0,
      park_fob_count: 0,
      monthly_rate: 0,
      // toggleModal: false,
      // checkin_file: "",
      tenant_name: "",
      corporate_id: "",
      tenant_email: "",
      tenant_phone: 0,
      check_in_notes: "",
      num_outstanding_invoices: 0
      // check_in: new Date()
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    alert("Submit Logic Here");
    const { history } = this.props;
    if (history) history.push("/user/bookings/new/manual-booking/info");
  };

  // handleModal = (state?: any) => {
  //   //  alert(this.state.toggleModal)
  //   if (state && this.state.toggleModal === false) {
  //     this.setState({ toggleModal: true });
  //   } else {
  //     this.setState({ toggleModal: false });
  //   }
  // };

  handleNext = () => {
    const { history } = this.props;
    if (history) {
      history.push({
        pathname: "/user/check ins"
      })
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  componentDidMount = async () => {
    // let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    // let allCorporations = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);

    const bookingId = new URLSearchParams(window.location.search).get("id");
    const bookingByID = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${bookingId}`);
    const bookingData = bookingByID.data[0];

    const unitByID = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${bookingData.unit_id}`);
    const unitData = unitByID.data;
    const tenant = await fetchGetJSON(`${Constants.API_PATH_TENANT_GET}${bookingData.tenant_id}`);
    const tenantData = tenant.data;
    const corp = await fetchGetJSON(`${Constants.API_PATH_CORP_GET}${bookingData.corporate_id}`);
    const corpData = corp.data;

    // let reduxData = store.getState().checkInReducer;

    if (bookingData) {
      this.setState({
        booking_id: bookingData.id,
        confirmed: bookingData.confirmed,
        // check_in: bookingData.check_in.split('T')[0],
        check_in_notes: bookingData.checkin_notes,
        monthly_rate: bookingData.monthly_rate,
        park_fob_count: bookingData.parking_fobs ? bookingData.parking_fobs : 0,
        // parking: bookingData.parking_requested,
        fob_count: bookingData.fobs ? bookingData.fobs : 0,
        key_count: bookingData.keys ? bookingData.keys : 0,
        mailkey_count: bookingData.mail_keys ? bookingData.mail_keys : 0
      });
    }

    if (unitByID.data) {
      this.setState({
        suite: unitData.suite_name,
        suite_number: unitData.suite_number,
        suite_type: unitData.suite_type,
        suite_address: unitData.location
      })
    }

    if (tenantData) {
      this.setState({
        tenant_name: tenantData.first_name + " " + tenantData.last_name,
        tenant_email: tenantData.email,
        tenant_phone: tenantData.phone_number
      });
    }

    if (corpData) {
      this.setState({
        corporate_id: corpData.id
      });
    }

    const allInvoices = await fetchGetJSON(`${Constants.API_PATH_INVOICE_BY_BOOKING}${bookingId}`);

    if (allInvoices.status === "Success") {
      for (let i = 0; i < allInvoices.data.length; i++) {
        let this_invoice = allInvoices.data[i];
        if (this_invoice.booking_id === bookingId && this_invoice.paid === false) {
          this.setState({ num_outstanding_invoices: this.state.num_outstanding_invoices + 1 })
        }
      }
    }

    // Not sure where these are from
    // this.state = {
    //   first_rent: true,
    //   first_rent_due: 12345678,
    //   toggleModal: false,
    // };
  }

  render() {
    // let title = "Confirm Remote Check In?";
    // let modalContent =
    //   "Remote check ins must be arranged ahead of time. Only continue if this has been approved.";
    // let firstRent = <div></div>;
    // let confirmed = <div></div>;
    // let workTrip = <div></div>;
    // if (this.state.first_rent === true) {
    //   firstRent = (
    //     <div
    //       id="FirstRent"
    //       className=" whitespace-no-wrap h-6 mr-2 bg-red-300 text-red-800  font-medium text-xs rounded-full py-1 w-auto flex items-center align-center text-center px-4"
    //     >
    //       {" "}
    //       First Rent Required{" "}
    //     </div>
    //   );
    // }

    return (
      <div className={"  font-display lg:rounded-tl-lg  bg-white "}>
        {/* <Modal
          handleModalToggle={this.handleModal}
          onoff={this.state.toggleModal}
          title={title}
          content={modalContent}
          confirmButton={this.handleModal}
        /> */}
        <BreadCrumb
          rootPage="Check Ins"
          subPage={"Booking # " + this.state.booking_id + " > Check In Summary"}
          sideButton="bomb"
          buttonAction={""}
        />
        <div className={"fade-in px-10 max-w-5xl pb-24"}>
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-6 ">
            <div className="flex flex-col w-full justify-end">
              <FiCheckCircle className=" ml-2 text-6xl mb-1 text-green-500 font-bold" />
              <h1 className="text-3xl px-2 pt-2 text-gray-700 font-black font-display ">
                Check In Summary
              </h1>
              <div className="flex flex-row my-2">
                <span className="text-md px-2 pt-3 font-thin text-gray-700  font-display ">
                  Your Address Is:
                </span>
                <span className="text-md pt-3 font-semibold text-indigo-500  font-display ">
                  {this.state.suite_address}
                </span>
              </div>
              <h2 className="px-2 font-normal font-display text-sm text-gray-500 max-w-lg">
                Check in completed!
              </h2>
            </div>

            <img src="/img/movin.png" className=" h-56 mx-auto" />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="slide-in-blurred-top font-display   rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <div>
                <span className="font-medium text-gray-800 text-base pb-0 ">
                  Tenant -{" "}
                </span>
                <span className="font-medium text-gray-700 text-base pb-0 ">
                  {this.state.tenant_name}
                </span>
              </div>

              <span className="font-thin text-gray-400 text-xs pt-1 ">
                Corporate: {this.state.corporate_id}
              </span>
              <span className="font-thin text-gray-400 text-xs pt-1 ">
                {this.state.tenant_email}
              </span>

              <div className="flex flex-row mt-2 text-sm items-center">
                <i className="text-gray-500 mr-2  ">
                  <FaPhoneAlt />
                </i>
                <span className=" font-regular text-gray-400  transition-all duration-200 border-0 p-0 w-3/4 focus:ring-0  ">
                  {" "}
                  {this.state.tenant_phone}{" "}
                </span>
              </div>
            </div>

            <div className="slide-in-blurred-top font-display rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <div>
                <span className="font-medium text-gray-800 text-base pb-0 ">
                  {this.state.suite}
                </span>
              </div>

              <span className="font-thin text-gray-400 text-xs pt-1 ">
                Suite Number: {this.state.suite_number}
              </span>
              <span className="font-thin text-gray-400 text-xs pt-1 ">
                ( {this.state.suite_type} )
              </span>

              <div className="flex flex-row mt-2 text-sm items-center">
                <i className="text-gray-500 mr-2  ">
                  <FaPhoneAlt />
                </i>
                <span className=" font-regular text-gray-400  transition-all duration-200 border-0 p-0 w-3/4 focus:ring-0  ">
                  Suite Phone Number: {this.state.tenant_phone}{" "}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="slide-in-blurred-top font-display   rounded-sm border px-4 py-4 mt-2 flex flex-col">
              <span className="font-medium text-gray-800 text-base pb-0 ">
                Fobs & Keys
              </span>
              <div className=" grid grid-cols-4 gap-3 my-2">
                <div className="flex flex-col items-center shadow-sm bg-gray-200 rounded-sm py-2">
                  <ImKey className="text-gray-500 text-xl" />
                  <span className="text-gray-400 text-sm py-1 ">
                    Suite Keys
                  </span>
                  {this.state.key_count}
                </div>

                <div className="flex flex-col items-center  shadow-sm bg-gray-200 rounded-sm py-2">
                  <MdNfc className="text-gray-500 text-xl" />
                  <span className="text-gray-400 text-sm py-1 ">
                    Fobs
                  </span>
                  {this.state.fob_count}
                </div>

                <div className="flex flex-col items-center  shadow-sm bg-gray-200 rounded-sm py-2">
                  <GrMail className="text-gray-500 text-xl" />
                  <span className="text-gray-400 text-sm py-1 ">
                    Mail Keys
                  </span>
                  {this.state.mailkey_count}
                </div>

                <div className="flex flex-col items-center  shadow-sm bg-gray-200 rounded-sm py-2">
                  <RiParkingBoxFill className="text-gray-500 text-xl" />
                  <span className="text-gray-400 text-sm py-1 ">
                    Parking Fobs
                  </span>
                  {this.state.park_fob_count}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center mb-16 mt-6">
            <button
              onClick={this.handleNext}
              className=" font-SF-Pro shadow-sm transition text-md duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center mr-3"
            >
              Back To Check Ins
            </button>
          </div>
        </div>

        <KeysInfo data={this.state} />
      </div>
    );
  }
}

export default withRouter(CheckInSummary);
