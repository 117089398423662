import { Component, useEffect } from "react";
import Chart from "react-apexcharts";
import Loading from "../../LoadingWidget";
type Props = {
  cleaningData: Array<any>;
}
type State = {
  options: object;
  series: Array<any>;
};

class CleaningPie extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeout',
            speed: 700,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 1500
          }
        },
        labels: [
          "Evelyn J.",
          "Ely A.",
          "Sara S.",
          "Ben W. ",
          "Flavia B.",
          "Alexandre A.",
          "Ada V.",
        ],
        colors: [
          "#42bc7f",
          "#3e83d1",
          "#854cbf",
          "#ffaa6e",
          "#27d3cb",
          "#ff6eaf",
          "#f29faa",
        ],
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'Loading...'
        },

        legend: {
          show: true,
          position: "bottom",
          fontSize: "11px",
          fontFamily: "Circular Std",
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "12px",
                  fontFamily: "Circular Std, sans-serif",
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: "Total",
                  fontSize: "0.8rem",
                  fontFamily: "Circular Std, sans-serif",
                },
              },
            },
          },
        },
      },

      series: [],
    };
  }

  componentDidUpdate = async (prevProps: any) => {
    console.log("pevpropssss", prevProps.cleaningData)
    console.log("propsss", this.props.cleaningData)
    if (prevProps.cleaningData !== this.props.cleaningData) {
      this.getCleanersData();
    }
  }

  getCleanersData = () => {
    const cleanerNames: Array<String> = []
    const cleanerColors: Array<String> = []
    const cleanerNumbers: Array<Number> = []
    this.props.cleaningData.forEach((cleaner) => {
      let nameParts = cleaner.name.split(" ")
      let nameInitials = nameParts[0][0] + ". " + nameParts[1]
      cleanerNames.push(nameInitials)
      cleanerColors.push(`#${cleaner.calendar_colour}`)
      cleanerNumbers.push(cleaner.cleanings)
    })
    this.setState(prevState => ({ options: { ...prevState.options, labels: cleanerNames, colors: cleanerColors }, series: cleanerNumbers, cleaningData: this.props.cleaningData }))
  }

  render() {
    return (
      this.state.series.length > 0 ?
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="100%"
          height="300px"
        />
        :
        <Loading size="large" type="spiral" />)
  }
}

export default CleaningPie;
