import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import { MdPlaylistAdd } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import Label from "../LabelWrap";
import InvoiceList from "../Invoices/InvoiceList";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import NewInvoice from "./NewInvoice";
import store from "../../redux/store";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { idText } from "typescript";
import { InvoiceCSVModal } from "./InvoiceCSVModal";
import ConsolidatedList from "./ConsolidatedList";
import ConsolidatedQuickView from "../Dashboards/Finance/ConsolidatedQuickView";
import AppStage from "../../utils/Types";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  toggleInvoice: boolean;
  toggleConsolidated: boolean; 
  editToggle: boolean;
  info: any;
  csv_open: boolean;
  newExpense: boolean;
};

class Invoices extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "",
      check_in: "",
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      toggleInvoice: false,
      editToggle: false,
      info: undefined,
      csv_open: false,
      toggleConsolidated: false,
      newExpense: false,
    };
  }

  handleInvoiceView = async (state?: any) => {
    if (this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  handleConsolidatedView = async (state?: any) => {
    if (this.state.toggleConsolidated === false) {
      this.setState({ toggleConsolidated: true });
    } else {
      this.setState({ toggleConsolidated: false });
    }
  };
  handleNewExpense = async () => {
    this.setState({ newExpense: !this.state.newExpense });
  }

  toggleCSVOpen = async () => {
    if(AppStage == "staywell") {
      this.setState({ csv_open: !this.state.csv_open });
    }
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let editFocus = "opacity-100";
    let bgFocus;

    if (this.state.editToggle === true || this.state.newExpense === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={() => {
            if (this.state.editToggle) {
              this.handleEditBarToggle()
            }
            if (this.state.newExpense) {
              this.handleNewExpense()
            }
          }}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    return (
      <div className=" font-display bg-white  lg:rounded-tl-lg  ">
        <InvoiceQuickView
          handleModalToggle={this.handleInvoiceView}
          onoff={this.state.toggleInvoice}
          info={this.state.info}
        />
        {/* <ConsolidatedQuickView
          handleModalToggle={this.handleConsolidatedView}
          onoff={this.state.toggleConsolidated}
          info={this.state.info}
        /> */}
        <NewInvoice
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
          updateList={true}
        />
        {bgFocus}
        <BreadCrumb
          rootPage="Invoices"
          subPage=""
          sideButton=""
          buttonAction={""}
        />
        <InvoiceCSVModal
          open={this.state.csv_open}
          closeModal={this.toggleCSVOpen}
        />

        <div className="fade-in px-3 pt-24 lg:pt-0 lg:px-10  ">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2">
            {/* Left Side  */}
            <div className="flex gap-4 w-1/2 px-2">
              <button
                onClick={this.handleEditBarToggle}
                className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
              >
                <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                <span className=" text-md ">New Invoice</span>
              </button>
            </div>

            {/* Right Side */}
            <div className="flex flex-row w-1/2 justify-end px-3">
              <button
                className="transition duration-100 border-2 border-solid w-36 bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                onClick={this.toggleCSVOpen}
              >
                <AiOutlineDownload className="  text-xl mr-1 self-center" />
                <span className=" text-xs self-center ">Download CSV</span>
              </button>
            </div>
          </div>

          <TabList warning={false}>
            <Label label=" All Invoices">
              <InvoiceList
                toggleInvoice={this.handleInvoiceView}
                showOnlyUnpaid={false}
              />
            </Label>

            <Label label="Unpaid">
              <InvoiceList
                toggleInvoice={this.handleInvoiceView}
                showOnlyUnpaid={true}
              />
            </Label>
            {/* <Label label="Consolidated">
              <ConsolidatedList
                toggleInvoice={this.handleConsolidatedView}
              />
            </Label> */}
          </TabList>
        </div>
      </div>
    );
  }
}

export default Invoices;
