import { Component } from "react";
import Chart from "react-apexcharts";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";

type Props = {
  ar: number;
  ap: number;
};

type State = {
  options: object;
  series: Array<any>;
};

class FinancePie extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: {
        labels: ["Accounts Receivable", "Accounts Payable"],
        colors: ["#8b62cb", "#42bc7f"],
        dataLabels: {
          enabled: false,
        },

        chart: {
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 10,
            opacity: 0.05,
          },
        },

        stroke: {
          curve: "smooth",
          lineCap: "butt",
        },

        legend: {
          show: true,
          horizontalAlign: "center",
          colors: "#000",
          position: "bottom",
          fontSize: "11px",
          fontFamily: "Circular Std",
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "12px",
                  fontFamily: "Circular Std, sans-serif",
                },

                value: {
                  show: true,
                  fontSize: "12px",
                  fontFamily: "Circular Std, sans-serif",
                  formatter: function (val: any) {
                    val = formatAmountForDisplay(val, "CAD", false);
                    return val;
                  },
                },

                total: {
                  show: false,
                  showAlways: false,
                  label: "Total",
                  fontSize: "12px",
                  fontFamily: "Circular Std, sans-serif",
                },
              },
            },
          },
        },
      },

      series: [Math.round(this.props.ar * 100) / 100, Math.round(this.props.ap * 100) / 100],
    };
  }

  componentWillReceiveProps = (props: Props) => {
    this.setState({
      series: [Math.round(props.ar * 100) / 100, Math.round(props.ap * 100) / 100]
    })
  }

  render() {
    return (
      <div key={this.props.ap}>
        <Chart
        options={this.state.options}
        series={this.state.series}
        type="donut"
        width="100%"
        height="350px"
        />
      </div>
    );
  }
}

export default FinancePie;
