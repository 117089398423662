import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { useNotification } from "../../../Notifications/NotificationProvider"
import store from "../../../../redux/store";

type Props = {
    toggle: boolean;
    handleEditBarToggle: any; //This is a function, need to rename this when we refactor
    parkingInfo: any;
}

function EditParking(props:Props ) {
    //Notification Handler Logic:
    const dispatch = useNotification();

    const HandleError = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    //Payload:
    const [parkingNotes, setParkingNotes] = useState('')

    const handleSubmit = async() => {
        
        const parkingEdit = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_EDIT, {
            id: props.parkingInfo.id,
            fields: {
                parking_notes: parkingNotes
            }
        })

        if(parkingEdit.status == "Success") {
            HandleError('Success', 'Success', `Parking ID ${props.parkingInfo.id} succesfully edited`)
            //Redux below:
            let update = store.getState().parkingReducer.profileUpdate;
            update()
            return
        } else {
            HandleError('Error', 'Error', 'Edit unsuccesful, contact dev team.')
            return
        }
    }

    return (
        <div className={
            props.toggle ?  "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + 
            "z-50 opacity-100 translate-x-0 lg:max-w-lg w-full" : "-mr-8 bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300 " + "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide"
          }> 
           {/* Top Title Bar */}
           <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
             <button
               onClick={props.handleEditBarToggle}
               className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
             >
               <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
             </button>
   
             <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
               <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
             </button>
           </div>
           <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
             <span className=" font-black text-xl text-gray-700">
               Edit Parking 
             </span>
             <span className="italic font-sofia text-gray-400 text-lg font-thin">
             </span>
           </div>
            <form className="flex flex-col pl-6 pr-12 py-4">
                <label className="block pl-3 mb-5 mt-2">
                    <strong className="text-black-400 text-s">Please Note: At the moment, the Edit Parking is only used to Edit Parking Notes.</strong>
                    <br/><br/>
                    <p className="text-black-400 text-s">
                    Editing the other parts of the parking are found with their own respective editing menus in the parking profile.</p>
                </label>
                <label className="block pl-3 mb-5 mt-2">
                       <strong className="text-gray-400 text-xs">Additional Parking Spot Notes</strong>
                       <textarea
                           id="notes"
                           defaultValue={props?.parkingInfo?.parking_notes}
                           className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                           rows={3}
                           onChange={(input) => setParkingNotes(input.target.value)}
                       ></textarea>
                </label>
            </form>
   
           {/* Submit Logic Below */}
           <div className="h-24 flex flex-row ">
             <button
               onClick={() => handleSubmit()}
               className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
             >
               Submit
             </button>
           </div>
         </div>
    );
}

export default EditParking;
