import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Calendar } from "@fullcalendar/core";
import { useEffect, useState } from "react";
import { fetchGetJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import Loading from "../../../LoadingWidget";

const ParkingCalendar = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [parkingResources, setParkingResources] = useState([]);

    useEffect(() => {
        fetchGetJSON(`${Constants.API_PATH_PARKING_CALENDAR}`).then(
          (resp) => {
            console.log(resp.data);
            setCalendarEvents(resp.data.events);
            setParkingResources(resp.data.resources[0]);
          }
        );
    }, []);

    if(parkingResources.length) {
        return (
            <div>
              <style>
                {`
                .fc-scrollgrid thead {
                  position: sticky;
                  z-index: 99;
                  top: 0;
                  background: white;
                }
              `}
              </style>
              <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[resourceTimelinePlugin, interactionPlugin]}
                initialView="resourceTimelineMonth"
                views={{resourceTimelineMonth: {
                  type: "resourceTimelineMonth",
                  duration: {days: 30}
                }}}
                selectable={true}
                events={calendarEvents}
                resources={parkingResources}
                resourceOrder={"title"}
                headerToolbar={{
                  left: "title",
                  right: "today prev,next",
                }}
                eventClick={function (info) {
                  info.jsEvent.preventDefault();
                  if (info.event.url) {
                    window.open(info.event.url);
                  }
                }}
              />
            </div>
        )
    } else {
        return (
            <div>    
                <div className="flex bg-white mt-20 px-3 py-5 pb-1 justify-center">
                    <Loading size="3x-large" type="big-spiral" />
                </div>
            </div>
        )
    }
}

export default ParkingCalendar