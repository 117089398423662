import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import EditOptionPanel from "../EditOptionPanel";
import { BsFillFlagFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import UnitInfo from "./ProfileComponents/UnitInfo";
import UnitFiles from "./ProfileComponents/UnitFiles";
import Label from "../LabelWrap";
import EditOwner from "./EditOwner";
import { HiFire } from "react-icons/hi";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import CancelModal from "../Bookings/CancelModal";
import EditBuilding from "./EditBuilding";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import { AiOutlineDownload } from "react-icons/ai";
import { BuildingCSVModal } from "./BuildingCSVModal";
import AppStage from "../../utils/Types";

type Props = {};

type State = {
  building_name: string;
  address: string;
  id: number;
  building_notes: string;
  flagged: boolean;
  min_stay: number;
  min_type: string;
  concierge_number: number;
  property_manager_number: number;
  special_instructions: string;
  amenities: string;
  district: string;
  unitList: any[];

  editToggle: boolean;
  toggleCancel: boolean;
  csv_open: boolean;
};

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

class BuildingProfile extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);

    this.state = {
      building_name: "Adelaide",
      address: "203 Wedgewood Dr North York, ON M2M 2H8",
      id: 1212,
      building_notes: "Notes go here",
      flagged: false,
      min_stay: 31,
      min_type: "Days",
      concierge_number: 5195603443,
      property_manager_number: 12323453,
      special_instructions: "--",
      amenities: "--",
      district: "--",
      unitList: [],

      editToggle: false,
      toggleCancel: false,
      csv_open: false,
    };
  }

  componentDidMount = async () => {
    let buildingId = new URLSearchParams(window.location.search).get("id");
    let buildingProfileData = await fetchGetJSON(
      `${Constants.API_PATH_PROFILE}?type=building&id=${buildingId}`
    );

    if (buildingProfileData.status === "Success") {
      let buildingData = buildingProfileData.data;
      let unitsData = buildingData.unit;

      this.setState({
        building_name: buildingData.building_name,
        address: buildingData.address,
        id: buildingData.id,
        building_notes: buildingData.svs_notes,
        flagged: false,
        min_stay: buildingData.minimum_stay,
        min_type: "Days",
        concierge_number: buildingData.concierge_number,
        property_manager_number: buildingData.property_manager_number,
        special_instructions: buildingData.special_instructions,
        amenities: buildingData.amenities,
        district: buildingData.district,
        unitList: unitsData,
      });
    }
  };

  handleEdit = () => {
    alert("opens edit bar for Units");
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  toProfile = (id: number) => {
    const { location, history } = this.props;
    let path = location.pathname.replace("buildingprofile", "unitprofile");
    if (history) history.push({ pathname: path, search: `id=${id}` });
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  toggleFlag = (event: any) => {
    if (this.state.flagged === false) {
      this.setState({ flagged: true });
    } else {
      this.setState({ flagged: false });
    }
  };

  toggleCSVOpen = async () => {
    if(AppStage == "staywell") {
      this.setState({csv_open: !this.state.csv_open});
    }
  }

  render() {
    let HeatBuildingTag: any = "";
    let bgFocus;
    let ModelType = "";

    if (this.state.editToggle === true) {
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    let flag = <></>;

    if (this.state.flagged) {
      flag = (
        <div className="text-xs font-thin -mt-2 text-gray-400 flex flex-row text-thin justify-center items-center content-center justify-items-center">
          <HiFire
            className={"text-red-400 group-hover:text-gray-400 text-2xl mt-1 "}
          />{" "}
          Heat Building
        </div>
      );
    }

    return (
      <div className=" bg-white font-display lg:rounded-tl-lg  lg:mt-0 mt-20 ">
        <EditBuilding
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
          buildingData={this.state}
          handleInputChange={this.handleInputChange}
          handleToggleChange={this.handleToggleChange}
        />

        <CancelModal
          handleModalToggle={() =>
            this.handleToggleChange("toggleCancel", this.state.toggleCancel)
          }
          onoff={this.state.toggleCancel}
          title={"Delete Building"}
        />

        <BuildingCSVModal 
          open={this.state.csv_open}
          closeModal={this.toggleCSVOpen}
          building_name={this.state.building_name}
        />

        {bgFocus}
        <BreadCrumb
          rootPage="Units > Buildings"
          subPage={this.state.building_name}
          sideButton=""
          buttonAction={""}
        />

        <div className="  px-4 lg:px-10 max-w-screen-xl mx-auto border rounded-lg py-10 bg-white fade-in">
          {/* Heading Info Area  */}
          <div className="flex  flex-col-reverse lg:flex-row mb-0">
            {/* Left Side  */}
            <div className="flex flex-col w-full lg:w-1/2">
              <h1 className="text-2xl px-2 pt-2 text-gray-700 font-black font-display flex flex-row  ">
                <span className="pr-3">{this.state.building_name} </span> {flag}
              </h1>

              <h2 className="px-2 mt-0 mb-1 text-md font-extralight text-gray-500 font-europe">
                {this.state.address}
              </h2>

              <div className="flex flex-row mt-1 flex-wrap">
                {/* <h2 className="px-2 pt-1 text-sm font-extralight text-gray-400 font-europe whitespace-nowrap">
                  Minimum Stay:{" "}
                  <span className="text-gray-700">
                    {this.state.min_stay} {this.state.min_type}
                  </span>
                </h2> */}

                <h2 className="px-2 pt-1 text-sm font-extralight text-gray-400 font-europe flex flex-row">
                  Concierge:{" "}
                  <span className="text-gray-700 ml-1 flex flex-row ">
                    {" "}
                    <ImPhone className="mt-1 mx-1 text-gray-300 " />{" "}
                    {this.state.concierge_number}{" "}
                  </span>
                </h2>

                <h2 className="px-2 pt-1 text-sm font-extralight text-gray-400 font-europe flex flex-row">
                  Property Managment:{" "}
                  <span className="text-gray-700 ml-1 flex flex-row ">
                    {" "}
                    <ImPhone className="mt-1 mx-1 text-gray-300 " />{" "}
                    {this.state.property_manager_number}{" "}
                  </span>
                </h2>
              </div>

              <div className="mx-2 mt-4 rounded-sm py-2 px-3 border grid grid-cols-2 gap-1 mb-1">
                <div className="flex flex-col">
                  <span className="text-sm pb-1">Notes:</span>
                  <span className="text-xs text-gray-600">
                    {this.state.building_notes}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm pb-1">Special Instructions:</span>
                  <span className="text-xs text-gray-600">
                    {this.state.special_instructions}
                  </span>
                </div>
              </div>

              <div className="my-2 px-3">
                <span className="text-sm">Amenities: </span>
                <span className="text-sm">{this.state.amenities}</span>
              </div>

              <div className="my-2 px-3">
                <span className="text-sm">District: </span>
                <span className="text-sm">{this.state.district}</span>
              </div>
            </div>

            {/* Right Side */}
            <div className="flex flex-row  w-full lg:w-1/2 items-start mb-5 lg:mb-0 justify-center lg:justify-end">
              <button 
                className="h-16 transition duration-100 border-2 border-solid w-36 border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                onClick={this.toggleCSVOpen}
              >
                <AiOutlineDownload className="  text-xl mr-1 self-center" />
                <span className=" text-xs self-center ">Download CSV</span>
              </button>
              <EditOptionPanel
                handleEditBarToggle={this.handleEditBarToggle}
                parentData={this.state}
                panelType="Building"
                handleFlag={this.toggleFlag}
                handleState={this.handleToggleChange}
              />
            </div>
          </div>

          <div className="w-full border  my-4">
            <table className="table-auto w-full mb-8">
              <thead>
                <tr className=" table-row text-left font-normal text-gray-500 ">
                  <th className="py-5 pl-3  ">Unit ID</th>
                  <th className="py-2  ">Suite Name </th>
                  <th className="py-2 hidden lg:table-cell  ">Unit Number</th>
                  <th className="py-2 hidden lg:table-cell ">Location</th>
                  <th className="py-2  ">Managed?</th>
                  {/* Options */}
                  <th className="py-2  "></th>
                </tr>
              </thead>

              <tbody>
                <BuildingUnitList
                  viewProfile={this.toProfile}
                  unitList={this.state.unitList}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BuildingProfile);

export function BuildingUnitList(props: any) {
  let currentDate = props.todaysDate;
  let testDueDate = new Date(2021, 7, 8, 4, 20);
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;
  let OverDueStyle = "text-gray-700";
  let OverDueStyle2 = "text-gray-700";
  let OverDue = <></>;

  for (var i = 0; i < props.unitList.length; i++) {
    let unitId = props.unitList[i].id;

    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    Payments = (
      <tr
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="py-4 pl-5 text-purple-400 font-thin ">
          {props.unitList[i].id}
        </td>
        <td className={" py-3 font-thin  "}>
          {" "}
          {props.unitList[i].suite_name}{" "}
        </td>
        <td className="py-3 text-gray-400 font-thin  hidden lg:table-cell">
          {props.unitList[i].suite_number}
        </td>
        <td className="py-3  font-thin hidden lg:table-cell ">
          {props.unitList[i].location}
        </td>
        <td className=" ">{props.unitList[i].type}</td>
        <td className=" "></td>
        <td className=" "></td>
        <td className=" text-right ">
          <button
            onClick={() => {
              store.dispatch({ type: "unitIdTransfer", id: unitId });
              props.viewProfile(unitId);
            }}
            className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
          >
            View Profile
          </button>
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}
