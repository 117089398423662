import { Component } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import ToolTipAlt from "../components/ToolTipAlt";
import {
  Link,
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

interface Props {
  subPage: string;
  sideButton: string;
  rootPage: string;
  buttonAction: any;
  history: any;
  location: any;
  match: any;
}

class BreadCrumb extends Component<Props & RouteComponentProps, {}> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
  }

  // handleClick = () => {
  //     const { match, location, history } = this.props;
  //     if(history) history.push(location.pathname);
  // }

  render() {
    const { match, location, history } = this.props;

    let SubPage = this.props.subPage;
    let SideButtonType = this.props.sideButton;
    let SideButton: any = "";

    if (SubPage != "") {
      SubPage = "> " + SubPage;
    }

    if (SideButtonType == "agreement") {
      // UNCOMMENT WHEN RE ENABLING AGREEMENT
      // SideButton = (
      //   <button
      //     onClick={this.props.buttonAction}
      //     className="transition-all duration-100 ml-auto text-gray-500 rounded-sm shadow-sm hover:bg-gray-200  bg-gray-100 px-5 py-1 flex flex-row items-center"
      //   >
      //     <AiOutlineFileDone className="text-xl mr-1" />
      //     <span className="text-md">Agreement</span>
      //   </button>
      // );
    }

    // if (SideButtonType == "bomb") {
    //   SideButton = (
    //     <div className="ml-auto flex flex-col items-center justify-center">
    //       <ToolTipAlt message="Help" position="bottom">
    //         <button className="fade-in transition-all duration-200  text-xl rounded-full shadow-md hover:bg-red-400 hover:text-3xl  bg-green-500 h-12 w-12 flex flex-row items-center justify-center">
    //           <span className="">💣</span>
    //         </button>
    //       </ToolTipAlt>
    //       {/* <span className="text-xs mt-1">Help</span> */}
    //     </div>
    //   );
    // }

    return (
      <div className=" hidden font-display align-center pl-12 mb-5 relative border-b border-solid  h-20 w-full right-0 lg:flex flex-row items-center shadow-inner">
        {/* To use Router Link to change page */}
        <div className=" w-full flex flex-row items-center pr-20">
          <CompleteLink linkEnd={this.props.rootPage} />
          <span className="  text-xl font-bold text-gray-400">{SubPage}</span>
          {SideButton}
        </div>
      </div>
    );
  }
}

export default withRouter(BreadCrumb);

function CompleteLink(props:any) {

  let {path} = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;
  let PathEdit = path.split("/");
  // console.log(path.split("/"));

  return (
  
    <Link to={"/" + PathEdit[1] + "/" + PathEdit[2] }  className="cursor-pointer hover:text-gray-300 text-xl font-bold text-gray-400 mr-2">{linkEnd}</Link>
  )
}
