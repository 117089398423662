import { Component } from "react";
import StatsBar from "../StatsBar";
import BookingQuickView from "../Bookings/BookingQuickView";
import { formatFullDate, formatDay } from "../../utils/DateFormat";
import { RiUser3Fill } from "react-icons/ri";
import UnitBubble from "../UnitBubble";
import Tooltip from "../Tooltip";
import { IoMdAirplane } from "react-icons/io";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { formatStringDay } from "../../utils/DateFormat";
import { useRouteMatch, useHistory } from "react-router-dom";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {
  userData: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleAgreement: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean; 
  flight_number: string;

  today_check_ins: any[];
  check_in_count: number;
  units: any[];
  tenants: any[];
};

class OpsDash extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleAgreement: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",

      today_check_ins: [],
      check_in_count: 0,
      units: [],
      tenants: [],
    };
  }

  handleAgreement = (state?: any) => {
    //  alert(this.state.toggleModal)
    if (state && this.state.toggleAgreement === false) {
      this.setState({ toggleAgreement: true });
    } else {
      this.setState({ toggleAgreement: false });
    }
  };

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  componentDidMount = async () => {
    let checkins = await fetchGetJSON(Constants.API_PATH_TODAY_GET_CHECK_IN);
    let units = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
    let tenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);

    this.setState({
      today_check_ins: checkins.data.check_ins,
      check_in_count: checkins.data.count,
      units: units.data,
      tenants: tenants.data,
    });
  };

  render() {
    let today = new Date();
    let time = today.getHours();
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-screen lg:rounded-tl-lg pt-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}
        <BookingQuickView
          handleModalToggle={this.handleQuickView}
          onoff={this.state.toggleQuickView}
          BookingId={this.state.booking_id}
        />
        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10 flex flex-row flex-wrap 2xl:flex-nowrap  ">
          <div className="w-full">
            <div className="pt-10 pb-5 grid grid-cols-6 ">
              <div className=" col-start-1 col-end-5 flex flex-col">
                <span className=" ml-1 font-medium text-gray-500 font-sofia">
                  {formatFullDate(today)}
                </span>
                <span className="text-4xl font-medium text-gray-800">
                  Good {dayTime}, {this.props.userData.user_f_name}
                </span>
                <span className=" ml-1 font-thin text-gray-600 font-sofia">
                  Let&apos;s Get To Work Today DashOps
                </span>
              </div>

              <div className="col-end-7 col-span-2  flex flex-col justify-end items-end"></div>
            </div>

            <div>
              <StatsBar StatsType="Bookings" />
            </div>

            {/* Heading Info Area  */}
            <div className="flex flex-col mb-2 mt-8 pl-2">
              <span className="text-xl font-bold text-gray-700 font-sofia mb-4">
                Upcoming Check Ins Today: {this.state.check_in_count}
              </span>
              <NextCheckIns state={this.state} />
            </div>
          </div>
          {/*
          <div className="pt-3 w-full max-w-xs">
            <div className="bg-white h-36 w-full mt-40 mx-5 px-4 py-2 mb-1 rounded-xl shadow-md ">
              Useful STats
            </div>

            <div className="bg-white h-36 w-full mt-20 mx-5 px-4 py-2 rounded-md shadow-md ">
              Useful STats
            </div>
          </div>
          */}
        </div>
      </div>
    );
  }
}

export default OpsDash;

function NextCheckIns(props: any) {
  let Unit = "Element B";
  let confirmed = props.state.confirmed;
  const d = props.state.check_in;
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  let hour = new Intl.DateTimeFormat("en", { hour: "2-digit" }).format(d);

  let { path, url } = useRouteMatch();
  let history = useHistory();

  let checkins = props.state.today_check_ins;

  let checkins2 = {
    data: [
      {
        id: 45,
        created_at: "2022-02-04T19:33:14.655Z",
        check_in: "2045-07-24T00:00:00.000Z",
        check_out: "2045-09-09T00:00:00.000Z",
        monthly_rate: 3000,
        parking_id: false,
        notes: false,
        corporate_id: false,
        corporate_admin_id: false,
        corporate_finance_id: false,
        corporate_cleaning_id: false,
        cleaning_frequency: "none",
        confirmed: false,
        flight: false,
        next_booking_id: false,
        previous_booking_id: false,
        flagged: false,
        flag_reason: false,
        local: false,
        created_by: "an intern",
        parking_requested: false,
        pets: true,
        confirmed_check_in: false,
        checkin_notes: false,
        confirmed_check_out: false,
        checkout_notes: false,
        deposit_expiry: 96,
        fobs: false,
        corporate_deduction: 0,
        apply_hst: false,
        pet_fee: 20,
        cleaning_fee: false,
        parking_fee: false,
        deposit_amount: 1000,
        keys: false,
        mail_keys: false,
        parking_fobs: false,
        agreement_id: false,
        agreement_conf: false,
        cancelled: false,
        original_booking_id: false,
        booking_type: "thirty_day",
        updated_at: "2022-02-04T19:33:14.655Z",
        unit_id: 1,
        tenant_id: 1,
      },
    ],
  };

  let rows = [];
  let CheckIn = <></>;

  if (!props.state.units.length || !props.state.tenants.length) {
    return null;
  }
  for (var i = 0; i < checkins.length; i++) {
    let index = i;
    let tenant = props.state.tenants.find(
      (t: any) => t.id == checkins[i].tenant_id
    );
    let unit = props.state.units.find((u: any) => u.id == checkins[i].unit_id);

    let ConfirmedTime = <></>;

    if (checkins[i].confirmed !== true) {
      ConfirmedTime = (
        <div className=" flex flex-row whitespace-no-wrap h-6 ml-3  bg-yellow-200 text-yellow-800 font-medium text-xs rounded-full  w-28  items-center justify-center align-center text-center px-4">
          {" "}
          Unconfirmed{" "}
        </div>
      );
    } else {
      ConfirmedTime = (
        <div className="flex flex-col font-sofia ml-2  border-gray-400  ">
          <span className="text-sm font-bold text-gray-600">
            {month + " "} {day}
          </span>
          <span className="text-sm text-gray-400">{hour} </span>
        </div>
      );
    }

    let FlightNumber = <></>;
    if (checkins[i].flight) {
      FlightNumber = (
        <div className="flex flex-col absolute items-center  py-1  rounded-md hover:shadow-lg mx-4 hover:bg-gray-100">
          <span className="text-xs text-gray-400">{checkins[i].flight}</span>
          <IoMdAirplane className="text-xl text-gray-400" />
        </div>
      );
    }

    CheckIn = (
      <button
        onClick={() => {
          path = path.replace("dashboard", "");
          //console.log(checkins.data[index])
          history.push(
            path + "bookings/bookingprofile?id=" + checkins.data[index].id
          );
        }}
        className=" transition-all duration-200 grid grid-cols-12 gap-2 bg-white hover:bg-gray-100  rounded-md border- shadow-md px-5 py-6 mb-4 w-full"
      >
        <div className="flex flex-row items-center col-start-1 col-end-4 ">
          <div className="bg-gray-100 rounded-sm w-8 h-8 lg:w-10 lg:h-10 shadow-sm flex flex-col justify-center items-center">
            <RiUser3Fill className="text-lg lg:text-xl" />
          </div>
          <div className="px-4 flex flex-col ">
            <span className="text-sm lg:text-base text-gray-600 font-semibold truncate w-36">
              {tenant && tenant.first_name + " " + tenant.last_name}
            </span>
            <span className="text-2xs lg:text-xs text-gray-400 font-thin">
              {tenant && tenant.email}
            </span>
          </div>
        </div>

        <div className="col-start-4 col-end-6 flex flex-row items-center justify-center">
          <Tooltip
            content={
              <UnitBubble suite_number={""} blackout={true} heat={true} />
            }
            direction=""
            delay=""
          >
            <div
              id="bookingConfirmed"
              className="  cursor-pointer h-8 mr-2 bg-gray-700 text-white font-medium text-xs rounded-full w-auto  flex items-center text-center px-8"
            >
              {" "}
              {unit.suite_name}{" "}
            </div>
          </Tooltip>
        </div>

        <div className="col-start-6 col-end-8 flex flex-col items-end pr-4 font-sofia border-r-2 border-gray-400  ">
          <span className="text-sm font-bold text-gray-600">
            {formatStringDay(checkins[i].check_in.split("T")[0])}
          </span>
          <span className="text-sm text-gray-400">
            {checkins[i].check_in.split("T")[0].substr(0, 4)}
          </span>
        </div>

        <div className="col-start-8 col-end-10 flex flex-row items-center  font-sofia  border-gray-400 pl-3  ">
          {ConfirmedTime}
        </div>
        <div className="col-start-10 col-end-12 flex flex-row items-center  font-sofia  border-gray-400   mr-3">
          <span className="text-xs text-gray-400">
            {checkins[i].checkin_notes}
          </span>
        </div>
        <div>{FlightNumber}</div>
      </button>
    );

    rows.push(CheckIn);
  }

  return (
    <div className="w-full   ">
      {rows}
      <div className="w-full flex flex-row justify-center">
        <button
          className="bg-white px-6 py-3 rounded-full shadow-md hover:bg-gray-100 hover:text-gray-500"
          onClick={() => {
            path = path.replace("dashboard", "check ins");
            history.push(path);
          }}
        >
          View All Upcoming Check Ins
        </button>
      </div>
    </div>
  );
}
