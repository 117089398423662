import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import InvoiceTableRow from "./InvoiceProfile/InvoiceTableRow";
import { fetchGetJSON, fetchRequestJSON, } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { constants } from "buffer";
import S3 from "aws-sdk/clients/s3";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.
type Props = {};
type State = {
  files: string[];
};

class Files extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      files: [],
    };
  }
  componentDidMount = async () => {
    this.updateFileList();
    store.dispatch({
      type: "fileListUpdate",
      listUpdate: this.updateFileList
    });
  };

  handleDownload = async (key: string) => {
    const downloadedFileRequest = await fetchGetJSON(
      `${Constants.API_PATH_FILES_DOWNLOAD}?key=${key}`,
      // { key: key }
    );
    window.open(downloadedFileRequest.data);
  };

  updateFileList = async () => {
    let unitId = Number(new URLSearchParams(window.location.search).get("id"));
    let response = await fetchGetJSON(
      `${Constants.API_PATH_FILES_LIST}?id=${unitId}&category=booking`,
    );

    let filesList: string[] = [];
    if (response.status === 'Success') {
      let contentsInfo: S3.ListObjectsOutput = response.data;

      contentsInfo.Contents.forEach((file: S3.Object) => {
        if(file.Key.includes(".")){ // Checks for the actual file
          filesList.push(file.Key);
        }
      })
      this.setState({ files: filesList });
    }
  };

  handleDelete = async (key: string) => {
    await fetchRequestJSON("DELETE", `${Constants.API_PATH_FILES_DELETE}?key=${key}`);
    this.updateFileList();
  };

  render() {
    let rows = [];
    let Files = <></>;
    for (let i = 0; i < this.state.files.length; i++) {
      //when linking to back end: remember to switch the key to the unique invoice id from the backend
      const name = this.state.files[i];
      Files = (
        <tr
          key={i.toString()}
          className=" w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        >
          <td className="py-4 pl-3 text-purple-400 font-thin ">{name}</td>
          <td className=" text-right ">
            <button
              onClick={() => this.handleDownload(encodeURIComponent(name))}
              className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
            >
              Download
            </button>
            <button
              onClick={() => this.handleDelete(encodeURIComponent(name))}
              className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
            >
              Delete
            </button>
          </td>
        </tr>
      );
      rows.push(Files);
    }
    return <>{rows}</>;
  }
}
export default withRouter(Files);
