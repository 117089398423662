import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Icon/Icon Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Icon/Icon Parking.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Icon/Icon View.png";

function IconParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full md:basis-7/10">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            Access to the parking garage for Icon I and Icon II is located at
            the back of the building, accessible via Blue Jays Way, just north
            of Wellington St. W.
          </p>
          <p className="mt-3">
            The ramp will be immediately to the right when entering the
            alleyway, pull up nice and close to the first roll up door, and
            press the number 1 button on the remote. Once in the garage, proceed
            down the ramp to the yellow gate arm on P1, and use the number 2
            button on your remote to access resident parking. From there,
            proceed down to your designated parking space.
          </p>
          <p className="mt-3 mb-5 md:mb-0">
            The ramp will be immediately to the right when entering the
            alleyway, pull up nice and close to the first roll up door, and
            press the number 1 button on the remote. Once in the garage, proceed
            down the ramp to the yellow gate arm on P1, and use the number 2
            button on your remote to access resident parking. From there,
            proceed down to your designated parking space.
          </p>
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="mt-2"
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 md:basis-3/10">
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-1/4 w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point form summary:
            <p className="my-3">
              <strong>Entrance accessible via Front St and John Street</strong>
            </p>
            <ul className="list-disc ml-5">
              <li>
                Approach roll up garage door, key landmarks are the yellow posts
                with the gate arms
              </li>
              <li>
                Hold up parking remote towards the gates, best to hold it out
                the window to improve signal
              </li>
              <li>
                When first gate opens, proceed down the ramp, straight ahead
                through visitor parking, to second roll up garage door
              </li>
              <li>
                Hold remote up again the same way as the first door until it
                opens
              </li>
              <li>Proceed down to your designated parking space</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default IconParking;
