import Label from "../LabelWrap";
import TabList from "../TabList";
import { ARReport } from "./ARReport";
import { CorporateInvoicing } from "./CorporateInvoicing";
import { CorporateMonthlyReveue } from "./CorporateMonthlyRevenue";
import { GeneralReporting } from "./GeneralReporting";
import { ProspectiveGeneralReporting } from "./ProspectiveGeneralReporting";
import { RetrospectiveGeneralReporting } from "./RetrospectiveGeneralReporting";
import { TrueARReport } from "./TrueARReport";
import {TrueGeneralReporting} from "./TrueGeneralReporting";


export function ReportSelection(props: any): JSX.Element {
    console.log(props);
    let currentDate = props.todaysDate;
    let testDueDate = new Date(2021, 7, 8, 4, 20);
    let rows = [];
    let reportList = ["Corporate Invoicing", "AR Report", "General Reporting"];
  
    let length = reportList.length;
    let OverDueStyle2 = "text-gray-700";
    let OverDue = <></>;
    let selectedStyle = "";
  
    for (var i = 0; i < length; i++) {
      let curved = "";
      if (i === 0) {
        curved = " border-b  rounded-t-lg ";
      }
  
      if (i === length - 1) {
        curved = " rounded-b-lg border-none ";
      }
  
      if (reportList[i] === props.selectedState) {
        selectedStyle = " bg-gray-100 ";
      } else {
        selectedStyle = "  bg-white ";
      }
  
      let cell = (
        <button
          onClick={props.changeSelected}
          id={reportList[i]}
          className={
            "border-gray-400 border-b " +
            selectedStyle +
            curved +
            "px-4 py-4 text-left"
          }
        >
          {reportList[i]}
        </button>
      );
  
      rows.push(cell);
    }
  
    return (
        <div>
          <TabList warning={false}>
            <Label label="AR / PrePaid Report">
                <TrueARReport/>
            </Label>
            <Label label="Collections Report">
                <ARReport      
                   handleModalToggle={props.handleModalToggle}
                />
            </Label>
            <Label label="Corporate Monthly Revenue">
               <CorporateMonthlyReveue/>
            </Label>
            <Label label="Prospective Reporting">
              <ProspectiveGeneralReporting/>
            </Label>
            <Label label="Retrospective Reporting">
              <RetrospectiveGeneralReporting/>
            </Label>
            {/* <Label label="General Reporting">
                <GeneralReporting></GeneralReporting>
            </Label> */}
          </TabList>
        </div>
    )
  }