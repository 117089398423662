import { useEffect, useState } from "react";
import { LoadingGeneral } from "../../../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../../../utils/PageSelector";
import { MdSearch } from "react-icons/md";
import store from "../../../../redux/store"
import { fetchGetJSON, fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import { FiPauseCircle, FiPlus } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import { table } from "console";

type Props = {
    history: any;
    location: any;
}
//Dayjs Logic, implement this as a helper function when we refactor
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

const ParkingSpotBlackoutList = (props: Props) => {
    //Table State Variables 
    const [rows , setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false)
    const [settingId, setSettingId] = useState(-1);

    //Page Selector State Variables
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
  
    //Filters
    const [searchByParkingName, setSearchByParkingName] = useState('');
    
    const [authorization, setAuthorization] = useState(true)

    //Load Initial Page:
    useEffect(() => {
        updateList();

        //Store update list in redux later
        store.dispatch({
            type: "parkingSpotBlackoutListUpdate",
            listUpdate: updateList,
        });
    }, [])

    const updateList = async (pageNumber?:number, totalPagesChange?:number) => {
        let filters = {
            name: searchByParkingName,
            active: true
        }

        const allParkingSpots = await fetchGetJSON(`${Constants.API_PATH_PARKING_SPOT_BLACKOUTS_DISPLAY}?name${filters.name ? `=${filters.name}` : ''}&page=${currentPageNumber}&perpage=${totalPagesChange ? totalPagesChange : rowsPerPage}&active=${filters.active}`)
        
        if(allParkingSpots.status !== "Success") {
            setLoaded(true);
            setAuthorization(allParkingSpots.status == "Error Unauthorized" ? false : true)
            return;
        }

        setTotalPages(allParkingSpots.data.pages)
        setRows(allParkingSpots.data.rows)
        // tableRows();
        setIsLoading(false);
        setLoaded(true);
    }

    //Table Filters:
    const pageUp = async () => {
        let page = currentPageNumber + 1;
        if(page > totalPages) {
            page -= 1;
        }

        await setCurrentPageNumber(page)
        setLoaded(false)
        await updateList(page);
    }

    const pageDown = async () => {
        let page = currentPageNumber - 1;
        if(page == 0) {
            page += 1;
        }

        await setCurrentPageNumber(page)
        setLoaded(false)
        await updateList(page);
    }

    const setPerPage = async(val:number) => {
        setRowsPerPage(val)
        setLoaded(false)
        await updateList(currentPageNumber, val)

        if(currentPageNumber > totalPages) {
            setCurrentPageNumber(totalPages)
            updateList(totalPages, val)
        }
    }
    
    const handleRowClick = (blackout_id:number) => {
        if(props.history) {
            props.history.push({
                pathname: `${props.history.location.pathname}/blackoutprofile/`,
                search: `id=${blackout_id}`,
              });
        }
    }
 
    const tableCreation = () => {
        return (
            <tbody>
                {
                    rows.length != 0 &&  //Weird how rows.length doesn't work but this works, as rows.length by itself indicates if the array is empty or not
                    rows.map((blackout) => {
                        return (
                            <tr className=" cursor-pointer flex flex-col lg:table-row hover:bg-gray-100 border-t border-gray-400 w-full">
                                <td
                                    className={
                                    " flex flex-row justify-center lg:table-cell  w-auto lg:w-16 px-1 py-4"
                                    }
                                >
                                    <h3 className="text-indigo-500"> {blackout.id}</h3>
                                </td>
                                <td
                                    className={
                                    " flex flex-row justify-center lg:table-cell  w-full lg:w-40 px-1 py-4"
                                    }
                                    onClick={() => handleRowClick(blackout.id)}
                                >
                                    <h3> {blackout.parking_spots.parking_spot_location}</h3>
                                </td>
                                <td
                                    onClick={() => handleRowClick(blackout.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    <h3>{blackout.start_date}</h3>
                                </td>
                                <td
                                    onClick={() => handleRowClick(blackout.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    <h3>{blackout.end_date}</h3>
                                </td>
                                <td
                                    onClick={() => handleRowClick(blackout.id)}
                                    className={
                                    " w-36 font-light text-sm text-gray-500 py-4"
                                    }
                                >
                                    <h3 className="text-indigo-500">{blackout.reason}</h3>
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    !rows.length && 
                    <LoadingGeneral count={10} cols={7} empty={loaded} authorization={authorization}/> 
                }
            </tbody>
        )
    }

    const handleInputChange = (event: { target: { id: string; value: string; }; }) => {
        const { id, value} = event.target;
        if(id == 'search') {
            setSearchByParkingName(value);
        }
    }

    const handleKeyDown = (event:any) => {
        if(event.key == 'Enter') {
          updateList()
        }
    }

    return (
        <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col lg:flex-row align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full mb-3 lg:mb-0 py-1 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                id={"search"}
                onChange={handleInputChange}
                className="text-sm w-96 searchBox"
                placeholder="Search by Parking Spot Location"
                onKeyDown={handleKeyDown}
                // value={this.state.search}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" 
                onClick={() => {updateList()}}
            >Search
            </button>

            {/* <span className="py-1 lg:px-4 font-medium margin text-gray-500 self-start ">
              Filters:
            </span>
            <div className="flex flex-row flex-wrap self-start">
              <button 
                className={
                  (searchByParkingName ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                // onClick={() => {
                //   this.setState({
                //     filter_blackout: !this.state.filter_blackout,
                //   })
                // }}
              >
                Search by Parking Name
              </button>
            </div> */}
          </div>
          <PageSelector
              curpage={currentPageNumber}
              totalpages={totalPages}
              increase={pageUp} //Page Up
              decrease={pageDown} //Page Down
              setPerPage={setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
            />
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 ">ID</th>
              <th className="py-2 ">Parking Spot Location</th>
              <th className="py-2 ">Start Date</th>
              <th className="py-2  ">End Date</th>
              <th className="py-2  ">Reason</th>
            </tr>
          </thead> 

            { tableCreation() }


          {/* <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={7} empty={loading}/> : rows}</tbody> */}
          {/* <tbody>
          </tbody> */}
        </table>
      </div>
    )
}

export default ParkingSpotBlackoutList