import { ConsoleLogger } from "@aws-amplify/core";
import { AnyLengthString } from "aws-sdk/clients/comprehend";
import { Component, useEffect, useState } from "react";
import { FaSpaceShuttle } from "react-icons/fa";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { Booking, Tenant, Unit } from "../../utils/Types";
import dayjs from "../../utils/Dayjs";
import { DefaultRootState, useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

type Props = {
    toggle: boolean;
    toggleProfile: any;
};

export const TenantProfile = (props: Props): JSX.Element =>{

    const [bookings, setBookings] = useState<Booking[]>([]);
    const [tenant, setTenant]= useState<Tenant>(null);
    const [units, setUnits] = useState<Unit[]>([]);
    //let curId = store.getState().tenantReducer.curId

    let curId = useSelector((state: RootState) => {
        console.log(state.tenantReducer.curId)
        return state.tenantReducer.curId;
    })

    useEffect(() => {
        fetchGetJSON(
            `${Constants.API_PATH_TENANT_BOOKINGS}${curId}`
        ).then((response) =>{
            if (response.status === 'Success') {
                setBookings(response.data)
            }
        })

        fetchGetJSON(
            `${Constants.API_PATH_TENANT_GET}${curId}`
        ).then((response) => {
            if(response.status === 'Success') {
                setTenant(response.data)
            }
        })

        fetchGetJSON(
            Constants.API_PATH_UNIT_GET_ALL
        ).then((response) =>{
            if(response.status === 'Success') {
                setUnits(response.data)
            }
        })
    }, []);

    useEffect(() => {
        fetchGetJSON(
            `${Constants.API_PATH_TENANT_BOOKINGS}${curId}`
        ).then((response) =>{
            if (response.status === 'Success') {
                setBookings(response.data)
            }
        })

        fetchGetJSON(
            `${Constants.API_PATH_TENANT_GET}${curId}`
        ).then((response) => {
            if(response.status === 'Success') {
                setTenant(response.data)
            }
        })
    }, [curId])

    if (!props.toggle) { return null; }
    if (curId=== -1) { return null; }
    const unit_names: string[]  = [];
    let check_in: string[] = [];
    let check_out: string[] = [];

    if (curId) {
        bookings.forEach((booking: Booking) => {
            let unit: Unit = units.filter((unit: Unit) => unit.id === booking.unit_id)[0];
            unit_names.push(unit.suite_name)
            check_in.push(dayjs(booking.check_in).format('YYYY-MM-DD'))
            check_out.push(dayjs(booking.check_out).format('YYYY-MM-DD'))
        })
    }
    return(
        <div id="outsideDiv"
            onClick={()=> props.toggleProfile("tenantProfile", true)}
            className={
          "fade-in fixed  flex flex-col items-center w-full h-full bg-gray-600 bg-opacity-40 z-50   "
        }>
            <div className="appear bg-white sm:my-10 w-full overflow-y-auto shadow-md sm:rounded-lg max-w-3xl  scroll-hide ">
                <div className="w-full  pt-6 px-4 sm:px-8 pb-10">
                    <div className="w-full flex flex-row font-sofia justify-between">
                       
                        <span className="text-4xl font-extrabold text-gray-600 ">ID: {tenant?.id ?? -1}</span> 
                    </div>
                        <span className="text-xl font-bold text-gray-800">Name: {tenant?.first_name ?? ""} {tenant?.last_name ?? ""}</span> <br></br>
                        <span className="text-xl font-bold text-gray-800">Email: {tenant?.email ?? ""}</span> <br></br>
                        <span className="text-xl font-bold text-gray-800">Phone Number: {tenant?.phone_number ?? ""}</span>
                </div>
                
                <table
                    style={{ borderSpacing: "0" }}
                    className="border-separate border rounded-sm mt-6 relative w-full table-auto bg-white     "
                    >
                    <thead className="border-b border-gray-400 ">
                        <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                            <th className="sticky top-0  py-3 pl-5  font-thin border-b border-gray-400  ">
                                ID
                            </th>
                            <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                                Suite Name
                            </th>
                            <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                                Check In
                            </th>
                            <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                                Check Out
                            </th>
                            <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 text-right px-4">
                                Monthly Rate
                            </th>
                        </tr>
                    </thead>

                    <tbody className="divide-y">
                        {
                            bookings.map((item: any, index: any) =>(
                                <tr>
                                    <td className="py-4 pl-5 text-gray-700 font-thin text-sm border-b">
                                        {item.id}
                                    </td>
                                    <td className="py-3 font-thin text-sm  border-b">{unit_names[index]}</td>
                                    <td className="py-3 font-thin text-sm  border-b">{check_in[index]}</td>
                                    <td className="py-3 font-thin text-sm  border-b">{check_out[index]}</td>
                                    <td className="py-3 text-green-500 font-thin text-right px-4 border-b">{item.monthly_rate}</td>
                                </tr>
                                    
                            ))
                        }
                    </tbody>
                </table>

            </div>
           
        </div>
       );
    
}