import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { formatFullDate, formatStringDate } from "../../utils/DateFormat";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaPowerOff } from "react-icons/fa";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { IdentityStore } from "aws-sdk";
import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";
import { ConsoleLogger } from "@aws-amplify/core";
import { AiOutlineUserAdd } from "react-icons/ai";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../utils/PageSelector";
import {listview} from "../../models/modelList";

// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  location: any;
  handleState: any;
};

type State = {
  blackout: boolean;
  corporateList: Corporate[]; // This is the list for all the invoice objects so I can use them to populate the list
  search: string;
  filter_overdue: boolean;
  filter_unmarked: boolean;
  filter_issues: boolean;
  loaded: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  authorization: boolean;
};

type Corporate = {
  id: number;
  booking_id: number;
  corporate_name: string;
  client_manager: string;
  method_of_payment: string;
  active: boolean;
};

class CorporateList  extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      corporateList: [],
      search: "",
      filter_overdue: false,
      filter_unmarked: false,
      filter_issues: false,
      loaded: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      authorization: true,
    };
  }

  // Toggle popup function
  handleClick = (id: number) => {
    console.log("CLICKED")
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: location.pathname + "/profile",
        search: `corp_id=${id}`,
      });
  };

  handleModal = (id: string) => {
    this.props.handleState(id, false);
  };
  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
    
  }

  setPerPage = async (val: number) => {
    await this.setState({perpage: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState({
          curpage: this.state.totalpages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  toggleActive = (id: number) => {
    let res = fetchRequestJSON("PUT", Constants.API_PATH_CORP_TOGGLE_ACTIVE, {
      "id": id,
    });
    //console.log(res);
    this.updateList();
  }

  // This is where all the information is retrieved from the api and loaded into the state
  componentDidMount = async () => {
    this.updateList();
    store.dispatch({ type: "corpListUpdate", corpListUpdate: this.updateList });
  };

  updateList = async (bookingId?: string) => {
    let filters = {
      search: ""
    }
    filters.search = this.state.search.trim();

    console.log({filters: filters, page: this.state.curpage, perpage: this.state.perpage});
    const allCorps: listview<Corporate[]> = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_CORPORATES}?search${this.state.search ? `=${this.state.search.trim()}`: ''}&page=${this.state.curpage}&perpage=${this.state.perpage}`, 
      // {filters: filters, page: this.state.curpage, perpage: this.state.perpage}
    )

    let corps = []; // to reset the state and rerender the page
    console.log(allCorps)

    if (allCorps.status != "Success") {
      this.setState({
        loaded: true,
        authorization: allCorps.status == "Error Unauthorized" ? false : true
      })
      return;
    }
    this.setState({totalpages: allCorps.data.pages})
    
    if(allCorps.data) {
      for (let corp of allCorps.data.rows) {
        // Set the objects attributes to the proper values according to info retrieved from api
        let corpObj = {
          id: corp.id,
          booking_id: corp.booking_id,
          corporate_name: corp.corporate_name,
          client_manager: corp.client_manager,
          method_of_payment: corp.method_of_payment,
          active: corp.active,
        };
        // Push finished object onto list copy
        //if (!bookingId || (bookingId == "") || Number(bookingId) === Number(invoiceObj.booking_id)) {
          corps.push(corpObj);
        //}
      }
    }
    // Loop through all the invoices retrieved from the api call and make invoice objects for the list of invoices
    
    // Set state from copy to the invoice list within the state so the list is rerendered
    this.setState({ corporateList: corps, loaded: true });
    
  };

  openSettings = () => {
    // alert("Settings");
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    let TableRows = <></>;
    var rows = [];
    let today = new Date();
    // let testDueDate = new Date(2021, 12, 8, 4, 20);
    let altBg = "";
    let Warnings = "";
    
    // Loops through the state invoice list (after it is loaded) and displays them
    for (var i = 0; i < this.state.corporateList.length; i++) {
      // Replace with different filter logic
      /*
      if (this.props.showOnlyUnpaid && this.state.corporateList[i].paid) { continue; }
      if (!((this.state.search == "") || Number(this.state.search) === Number(this.state.corporateList[i].booking_id))) { continue; }
      if (this.state.filter_overdue && !(new Date(this.state.corporateList[i].due_date) < today && !this.state.corporateList[i].paid)){ continue; }
      */

      // Replace with any tags wanted
      
      let SpecialStyle = "text-gray-700";
      let SpecialStyle2 = "text-gray-700";
      let Special = <></>;
      let corporateId = this.state.corporateList[i].id;

      let blackoutStyles = "transition-all duration-200";
      let blackoutToggle: JSX.Element = <FiPauseCircle 
        onClick={() => {
          this.toggleActive(corporateId);
        }}
        className={"text-2xl text-gray-700 hover:text-gray-500"} 
      />;

      if (!this.state.corporateList[i].active) {
        blackoutStyles += " opacity-20 "

        blackoutToggle = <FaPowerOff
          onClick={() => {
            this.toggleActive(corporateId);
          }}
          className={"text-2xl text-green-500 hover:text-green-300 opacity-100"}
        />
      }

      /*
      if (new Date(this.state.corporateList[i].due_date) < today) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-red-400 mr-10";
        Special = (
          <span className="bg-red-500 py-1 px-4 rounded-full text-white">
            Overdue
          </span>
        );
      }
      
      
      if (this.state.corporateList[i].paid) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-green-400 mr-10";
        Special = (
          <span className="bg-green-500 py-1 px-4 rounded-full text-white">
            Paid
          </span>
        );
      }
      */

      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }

      TableRows = (
        <tr
          key={i.toString()}
          className={
            altBg + blackoutStyles +
            " grid grid-cols-1 sm:table-row  cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full"
          }
        >
          {/* Suite Name */}
          {/* IMPORTANT */}
          {/* These onclick properties are set to a function passed from parent and are rendered at all times */}
          {/* This means I cant send it an id from the invoice object it will always call all of them and end up displaying the first one */}
          <td
            onClick={() => this.handleClick(corporateId)}
            className={
              "w-full text-sm sm:text-base sm:w-16 py-4 sm:pl-4"
            }
          >
            <span className="sm:hidden">ID: </span>
            {this.state.corporateList[i].id}
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.handleClick(corporateId)}
            className={
              "w-full sm:w-36 font-light text-sm text-gray-500 sm:px-1 sm:py-4"
            }
          >
            <span className="sm:hidden">Name: </span>
            <span className={SpecialStyle2}>{this.state.corporateList[i].corporate_name}</span>{" "}
            {Special}
          </td>

          {/* Min. Stay */} 
          {/* <td */}

          <td
            onClick={() => this.handleClick(corporateId)}
            className={
              "w-full sm:w-32 font-thin  text-gray-600 sm:px-1  py-2 sm:py-4"
            }
          >
            <span className="sm:hidden text-sm">Client Manager: </span>
            {this.state.corporateList[i].client_manager}{" "}
          </td> 

          <td
            onClick={() => this.handleClick(corporateId)}
            className={
              "w-full sm:w-32 font-medium  text-gray-600 sm:px-1  py-0 sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">Payment Method: </span>{" "}
            {this.state.corporateList[i].method_of_payment || "TBD"}
          </td>

          {/* Type */}

          <td
            className={
              "w-full text-sm sm:text-base sm:w-28 font-normal  text-gray-400 sm:px-1 py-2  sm:py-4"
            }
          >
            <span className="sm:hidden font-thin text-sm">
              Actions:{" "}
            </span>
              {blackoutToggle}
            {/* I have no idea what to put here */}
          </td>

          {/* Booking Warnings */}

          <td
            onClick={() => this.handleClick(corporateId)}
            className="hidden sm:table-cell sm:w-28 text-red-400 px-1 py-4 text-sm font-semibold"
          >
            {Warnings}
          </td>

          {/* Booking Warnings */}

          <td className=" hidden sm:table-cell sm:w-32 px-1 py-2 sm:py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                onClick={this.openSettings}
                className={
                  "text-gray-700 text-2xl mr-2 hover:text-gray-500"
                }
              />
            </div>
          </td>
        </tr>
      );

      // if(sorted[i].exists){
      rows.push(TableRows);
      // }
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-col sm:flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-full sm:w-1/4  py-1 mb-3 sm:mb-0 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                onChange={this.handleInputChange}
                className="text-sm searchBox w-full"
                placeholder="Search corp name/id"
                id="search"
                value={this.state.search}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>
            {/* <div className="flex flex-row align-center items-center">
            <span className="py-1 sm:px-4 font-medium margin text-gray-500 self-start ">
              Filters:
            </span>
              <button 
                className={
                  (this.state.filter_overdue ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_overdue: !this.state.filter_overdue,
                  })
                }}
              >
                Overdue
              </button>
              <button 
                className={
                  (this.state.filter_unmarked ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_unmarked: !this.state.filter_unmarked,
                  })
                }}
              >
                Unmarked
              </button>
              <button 
                className={
                  (this.state.filter_issues ? "bg-gray-200 hover:bg-gray-300" : "bg-white hover:bg-gray-100") +
                  " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={() => {
                  this.setState({
                    filter_issues: !this.state.filter_issues,
                  })
                }}
              >
                Issues
              </button>
            </div> */}

          </div>
          <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10,20,30,40,50]}
            />
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className="hidden sm:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 pl-4">ID</th>
              <th className="py-2  ">Name</th>
              <th className="py-2  ">Client Manager</th>
              <th className="py-2  ">Payment Method</th>
              <th className="py-2  ">Actions</th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={6} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(CorporateList );
