import React, { Component } from "react";

type Props = {
  suite_number: string,
  blackout?: boolean,
  heat: boolean,
}

class UnitBubbles extends Component<Props> {
  render() {
    return (
      <div className="fade-in z-50">
        {/*
        <h1 className="font-dispay font-regular text-gray-600 text-sm py-1">
          February Empty Suites:{" "}
          <span className="font-bold text-purple-900">12</span>
        </h1>
        */}
        <h1 className="font-dispay font-regular text-gray-600 text-sm py-1">
          Suite Number: <span className="font-bold text-purple-900">{this.props.suite_number}</span>
        </h1>
        <h1 className="font-dispay font-regular text-gray-600 text-sm py-1">
          Black Out:{" "}
          <span className="font-bold text-purple-900">{this.props.blackout ? "Black Out" : "No Black Out"}</span>
        </h1>
        {this.props.heat && <div
          id="FirstRent"
          className=" flex flex-col justify-center whitespace-no-wrap h-6 mt-2 pt-0 text-center bg-red-300 text-red-800 font-medium text-xs rounded-full   mx-auto my-auto text-center px-2"
        >
          {" "}
          Heat Building{" "}
        </div>}
      </div>
    );
  }
}

export default UnitBubbles;
