import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { useNotification } from "../Notifications/NotificationProvider";
import { AiOutlineRise, AiOutlineFall, AiOutlineLine, AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import ToggleSwitch from "../ToggleSwitch";


type Props = {
  handleModalToggle?: any;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2d3748",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const dayjs = require('dayjs');
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export const CorporateMonthlyReveue = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [noData, setNoData] = useState(false);

  const [corporateMonthlyRev, setCorporateMonthlyRevenue] = useState([]);
  const [corporateMonthlyBooking, setCorporateMonthlyBooking] = useState([]);
  const [currentMonthHeaders, setCurrentMonthHeaders] = useState([]);

  const [dataFetched, setDataFetched] = useState(false);

  const [toggleBookingView, setToggleBookingView] = useState(false);

  const [filterCost, setFilterCost] = useState(false);
  const [arrowDirection, setArrowDirection] = useState("default");


  const dispatch = useNotification();

  useEffect(() => {
    HandleError('Loading', 
        'Loading Please Wait',
        `Fetching Prospective Report`
    );

    // Define the starting and ending dates (July 2022 to July 2023)
    const startDate = dayjs().subtract(1, 'month').subtract(1, 'year').startOf('month'); // 6 represents July (0-indexed)
    const endDate = dayjs().subtract(1, 'month');   // 6 represents July (0-indexed)

    // Array to store month names
    const monthNames = [];

    // Iterate over the months and push their names to the array
    let currentDate = startDate;
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'month')) {
        monthNames.push(currentDate.format('MMMM YYYY'));
        currentDate = currentDate.add(1, 'month');
    }
    setCurrentMonthHeaders(monthNames);

    const fetchData = async () => {
        let corporateMonthlyRevenue = await fetchGetJSON(Constants.API_PATH_BOOKING_CORPORATE_MONTHLY_REV)
        setCorporateMonthlyRevenue(corporateMonthlyRevenue)

        let corporateMonthlyBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_CORPORATE_BOOKING_REPORT)
        console.log(corporateMonthlyBookings[0])
        setCorporateMonthlyBooking(corporateMonthlyBookings)

        HandleError('Success', 'Success',`Fetched Prospective Report`);
        setDataFetched(true);
        setLoading(false);
    }
    fetchData();
   
  }, []);

  function HandleError(type: string, title: string, message: string,) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message
    })
  }


  const tableCreation = (): JSX.Element => {
    return (
      <TableContainer sx={{ overflow: 'visible' }}>
        <Table sx={{ minWidth: 700, height: 'auto'}} aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Corporate Name</StyledTableCell>
              {currentMonthHeaders.map((monthName) => (
                    <StyledTableCell key={monthName} align="left">{monthName}</StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(corporateMonthlyRev).map(([corporateName, data]) => (
                <TableRow key={corporateName}>
                <StyledTableCell>{!filterCost ? corporateName : data?.corporateName}</StyledTableCell>
                {currentMonthHeaders.map((monthName) => (
                    <StyledTableCell key={monthName}>
                    ${Math.trunc(data[monthName]?.revenue).toLocaleString('en-US')} {data[monthName]?.colorCode == "#10B981" ? <AiOutlineRise className="text-green-500 text-lg mr-1" /> : data[monthName]?.colorCode == "#ED2939" ? <AiOutlineFall className="text-red-500 text-lg mr-1" /> : <AiOutlineLine className="text-black-500 text-lg mr-1" />}
                    </StyledTableCell>
                ))}
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleButtonClick = () => {
    setFilterCost(true);
    //Need to change code below, but for now keep this
    if(arrowDirection == "default") {
      setArrowDirection("true")
      const convertToArray = Object.entries(corporateMonthlyRev).map(([corporateName, monthlyData]) => ({
        corporateName,
        ...monthlyData,
      }));
      console.log(convertToArray)
      
      setCorporateMonthlyRevenue(convertToArray.sort((a, b) => b.totalRevenue - a.totalRevenue))
      //Sort by total cost
    } else if (arrowDirection == "true") {
      setArrowDirection("false")
      const convertToArray = Object.entries(corporateMonthlyRev).map(([corporateName, monthlyData]) => ({
        corporateName,
        ...monthlyData,
      }));
      setCorporateMonthlyRevenue(convertToArray.sort((a, b) => a.totalRevenue - b.totalRevenue))
      //Sort by the opposite
    } else {
      //Set arrow direction back to blank
      setArrowDirection("default")
      const convertToArray = Object.entries(corporateMonthlyRev).map(([corporateName, monthlyData]) => ({
        corporateName,
        ...monthlyData,
      }));
      //Sort by alphabetical
      setCorporateMonthlyRevenue(convertToArray.sort((a, b) => a.corporateName.localeCompare(b.corporateName)))
    }
  }


  const tableCreationBooking = (): JSX.Element => {
    // Object.entries(corporateMonthlyBooking).map(([corporateName, data]) => {
    //   console.log(data.corporateName)
    // })
    return (
      <TableContainer sx={{ overflow: 'visible' }}>
        <Table sx={{ minWidth: 700, height: 'auto'}} aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Corporate Name</StyledTableCell>
              {currentMonthHeaders.map((monthName) => (
                    <StyledTableCell key={monthName} align="left">{monthName}</StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {
              corporateMonthlyBooking.map((corporateData) => {
                return (
                  <TableRow>
                    <StyledTableCell>{corporateData.corporateName}</StyledTableCell>
                  </TableRow>
                )
              })
            } */}
              {corporateMonthlyBooking.map((corporateData) => (
                <TableRow key={corporateData.corporateName}>
                  <StyledTableCell>{corporateData.corporateName}</StyledTableCell>
                  {corporateData.monthlyBookings.map((monthlyData:any) => (
                    <StyledTableCell key={monthlyData.month} align="left">
                      {monthlyData.count}  {monthlyData.colorCode == "#10B981" ? <AiOutlineRise className="text-green-500 text-lg mr-1" /> : monthlyData?.colorCode == "#ED2939" ? <AiOutlineFall className="text-red-500 text-lg mr-1" /> : <AiOutlineLine className="text-black-500 text-m mr-1" />}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  let loadingTable = <></>;
  if (loading) {
    loadingTable = <table className="table-auto w-full mb-8">
      <tbody>
        {<LoadingGeneral count={100} cols={13} empty={noData} />}
      </tbody>
    </table>
  }

  return (
    <div>
      <div className="font-display bg-white lg:rounded-tl-lg">
        <div className="mt-10 text-center text-lg">Corporate Monthly Revenue</div>
        <div className="mt-10 mb-10 text-center text-lg">{`Note: Corporate Monthly Revenue from ${dayjs().subtract(1, 'month').subtract(1, 'year').format('MMMM YYYY')} to ${dayjs().subtract(1, 'month').format('MMMM YYYY')}`}.<br/> <u>Please Note: May need to scroll horizontally to view the full report if viewing on smaller screens.</u></div>
        <div className="flex flex-row flex-wrap mb-5">
              <button 
                key={'FilterCost'}
                className={
                  "bg-white hover:bg-gray-100 text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
                }
                onClick={handleButtonClick}
              >
                {`Filter by Cost`}{arrowDirection =="default" && ''}{arrowDirection =="true" && <AiOutlineArrowUp className="text-green-500 2xl"></AiOutlineArrowUp>}{arrowDirection =="false" && <AiOutlineArrowDown className="text-red-500 2xl"></AiOutlineArrowDown>}
              </button>
            </div>
        <div className="flex flex-row mb-2 sm:pr-2">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Toggle to See Corporate Bookings Per Month 
            </span>
            <ToggleSwitch
              id="toggleHistorical"
              handleStateToggle={() => setToggleBookingView(!toggleBookingView)}
              className="justify-center align-center"
              size="lg"
              onoff={toggleBookingView}
            />
          </div>
        <div className={`${toggleBookingView ? 'hidden' : ''}`}>
          {setDataFetched && tableCreation()}
          {loadingTable}
        </div>
        <div className={`${!toggleBookingView ? 'hidden' : ''}`}>
          {setDataFetched && tableCreationBooking()}
          {loadingTable}
        </div>
      </div>
    </div >
  );
};
