import { Component } from "react";
import { inputISO } from "../../../utils/DateFormat";
import { FiCornerUpRight } from "react-icons/fi";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import store from "../../../redux/store";
import { parseJwt } from "../../../api-requests/api-gateway";
import { getCurrentUser } from "aws-amplify/auth";

interface Props {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  id: number;
}

interface ModalState {
  title: string;
  onoff: boolean;

  authId: string;
  bank_date: string;
  ref: string;
}

class ConfirmModal extends Component<Props, ModalState> {

  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      authId: "",
      bank_date: new Date().toISOString().split('T')[0],
      ref: "",
    };
  }
  
  componentDidMount = async () => {
    try {
      let res = await getCurrentUser();
      this.setState({authId: res.username})
    } catch {
    }
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.id);
      this.setState({ onoff: !this.state.onoff })
    }
  };

  // Find proper unit and building to transfer booking
  handleClick = async () => {
    let route: string = Constants.API_PATH_INVOICE_CONFIRM; 
    if(this.props.title === "Consolidated Confirm Payment") {
      route = Constants.API_PATH_CONSOLIDATED_PAYMENT_CONFIRM;
    }
    let res = await fetchRequestJSON("PUT", 
      route + this.props.id, 
      { 
        authId: this.state.authId,
        ref: this.state.ref,
        bank: this.state.bank_date
      });
    if (res.status === "Success") {
      alert("Invoice #" + this.props.id + " has been confirmed.")
      this.props.handleModalToggle(this.props.id)
      const update = store.getState().invoiceReducer.listUpdate;
      update();
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    console.log(event.target.value)
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <FiCornerUpRight className="text-gray-600 text-3xl mb-3" />
          <span className="text-xl font-bold text-gray-800 text-left">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400">Invoice #{this.props.id}</span>
          <form>
            <div className="grid grid-cols-3 gap-6 w-full my-5">
              <label className="block ">
                <span className="text-gray-400 text-xs">Auth Id:</span>
                <input
                  id="authId"
                  onChange={this.handleInputChange}
                  value={this.state.authId}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black text-gray-500"
                  readOnly={true}
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Bank Date:</span>
                <input
                  id="bank_date"
                  onChange={this.handleInputChange}
                  value={this.state.bank_date}
                  type="date"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              </label>

              <label className="block ">
                <span className="text-gray-400 text-xs">Confirmation Number:</span>
                <input
                  id="ref"
                  onChange={this.handleInputChange}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="ref. #"
                />
              </label>
            </div>
            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={() => {
                  this.handleClick();
                }}
                type="button"
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ConfirmModal;
