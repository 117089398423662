import React, { Component } from "react";
import LoginForm from "./LoginForm";
import { Provider } from "react-redux";
import store from "../redux/store";
import { GetAppStage } from "../api-requests/api-gateway";
import { Redirect } from "react-router-dom";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  register: boolean;
};

class LoginPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "1234",
      check_in: "February 12th 2021",
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      register: false,
    };
  }

  redirectToRegisterPage = () => {
    this.setState({
      register: true,
    });
  };

  render() {
    if (this.state.register) {
      return <Redirect to={{ pathname: "/register" }} />;
    }

    return (
      <div className={" font-display fade-in h-screen w-full flex flex-row " + GetAppStage().bg_main}>
        {/* Left Side */}
        <div className=" w-1/3 pl-8 p-5 flex flex-col ">
          <img
            alt="logo"
            src={GetAppStage().logoPath}
            className=" w-44  transition duration-200 "
          />
          <div className=" my-auto flex flex-col">
            <span className=" text-white font-europe text-lg font-bold opacity-70 ">
              News + Updates
            </span>
            <span className=" text-white font-europe text-md font-thin  mt-1  ">
              - Full list <a href="https://skyviewsuites.slite.com/app/docs/qWoRKoWvxIaoWe" target="_blank" className="text-green-300">here</a>
            </span>
            <span className=" text-white font-europe text-md font-thin  mt-1  ">
              - Issac was here
            </span>

            <span className=" text-white font-europe text-lg font-bold opacity-70 mt-5 ">
              Bug Fixes
            </span>
            <span className=" text-white font-europe text-md font-thin  mt-1  ">
              - Full list <a href="https://skyviewsuites.slite.com/app/docs/qWoRKoWvxIaoWe" target="_blank">here</a>
            </span>
          </div>
        </div>

        {/* Right Side */}
        <div className=" py-8 px-6  rounded-l w-2/3 h-screen shadow-lg z-30 border border-solid bg-white flex flex-row items-center justify-center">
          <div className="flex flex-col max-w-lg w-full">
            <span className="text-4xl font-regular h-16 text-gray-800 font-europe">
              Log In:
            </span>
            <Provider store={store}>
              <LoginForm />
              {/* <button
                type="submit"
                className=" hover:bg-gray-300 py-1 text-white text-sm  bg-gray-900 px-8 w-full rounded-md shadow-sm mt-3"
                onClick={this.redirectToRegisterPage}
              >
                Register New Account
              </button> */}
            </Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
