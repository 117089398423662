import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { FiSettings } from "react-icons/fi";
import { RiUser3Fill } from "react-icons/ri";
// import { formatFullDate } from "../../utils/DateFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { unitType, tenantType, listview } from "../../models/modelList";
import PageSelector from "../../utils/PageSelector";
import { Corporate } from "../../utils/Types";
import { constants } from "buffer";


// Notes:
// Loop through rows with data (might need to pass fetched props into new component)
// For Future Lists Possibly make an all in one component that just takes props rather than multiple components

type Props = {
  history: any;
  QuickViewButton: any;
};

type State = {
  blackout: boolean;
  bookings: Booking[];
  search: string;
  loaded: boolean;
  vacant: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;

  corporates: Corporate[];
  corporateId: number;
  authorization: boolean;
};

type Booking = {
  id: number;
  booking_id: number;
  tenant_name: string;
  tenant_email: string;
  check_in: string;
  check_out: string;
  check_out_year: string;
//   unit: string;
  unit_id: number;
  confirmed_check_out: string;
  cancelled: boolean;
  confirmed: boolean;
  keys_confirm: boolean;
  payment_confirm: boolean;
  cleaning_confirm: boolean;

  tenant: any,
  unit: any,
  corporate: any,
};

type BookingItem = {
  id: number;
  check_in: string;
  check_out: string;
  cancelled: boolean;
  confirmed: boolean;
  confirmed_check_out: string;
  unit_id: number;
  tenant_id: number;
  unit: string;
  tenant_name: string;
  tenant_email: string;
  keys_confirm: boolean;
  payment_confirm: boolean;
  cleaning_confirm: boolean;
}

class CorporateBookings extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      blackout: false,
      bookings: [],
      search: "",
      loaded: false,
      vacant: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,

      corporates: [],
      corporateId: -1,

      authorization: true,
    };
  }

//   pageUp = async () => {
//     let page = this.state.curpage + 1;
//     if (page > this.state.totalpages) {
//       page -= 1;
//     }
//     this.setState({
//       curpage: page,
//       loaded: false,
//     }, async () => {
//       await this.updateList();
//     })
//   }

//   pageDown = async () => {
//     let pageNumber = this.state.curpage - 1;
//     if (pageNumber == 0) {
//       pageNumber += 1;
//     }
//     this.setState({
//       curpage: pageNumber,
//       loaded: false,
//     }, async () => {
//       await this.updateList();
//     })
//   }

  // TODO: Refactor this function by removing the nested async calls.
//   setPerPage = async (val: number) => {
//     await this.setState({ perpage: val, loaded: false, }, async () => {
//       await this.updateList();
//       if (this.state.curpage > this.state.totalpages) {
//         this.setState({
//           curpage: this.state.totalpages
//         }, async () => {
//           await this.updateList();
//         })
//       }
//     })
//   }

  HandleBlackOut = () => {
    this.setState({ blackout: !this.state.blackout });
  };

  HandleRowClick = (id: number) => {
    // store.dispatch({ type: "bookingIdTransfer", id: id });
    // Might have to add a redux dispatch here like in corporate list
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: `${location.pathname}/bookingprofile`,
        search: `id=${id}`,
      });
  };

  componentDidMount = async () => {
    this.updateList();
    store.dispatch({
      type: "bookingListUpdate",
      listUpdate: this.updateList
    });
  };

  // TODO: Refactor this function.
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // Populate the list entries from the database bookings relation
  updateList = async () => {

    //Update corporate values with dropdown
    //Grab all corporates first and set values in corporate booking:
    const corporates = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL)
    corporates.data.sort((corp1: any, corp2: any) => (corp1.corporate_name > corp2.corporate_name) ? 1 : (corp1.corporate_name < corp2.corporate_name) ? -1 : 0)

    await this.setState({
        corporates: corporates?.data ? corporates?.data : []
    })

    if(!corporates.data) {
      await this.setState({
        authorization: false
      })
    }

    const corporateBookings = await fetchGetJSON(`${Constants.API_PATH_CORP_BOOKINGS}?corporateID=${this.state.corporateId}`)

    this.setState({
      loaded: true,
      bookings: corporateBookings?.bookings ? corporateBookings.bookings : []
    });
  };

  cogClick = (id: number) => {
    store.dispatch({
      type: "bookingIdTransfer",
      id: id
    });
    this.props.QuickViewButton()
  }


  handleDropdown = async(event: any) => {
    await this.setState({
        corporateId: event.target.value
    })
  }
  render() {
    let blackoutStyles;
    let Warnings;
    // let blackoutToggle = <FiPauseCircle className={"text-2xl text-gray-700 hover:text-gray-500"}/>
    if (this.state.blackout) {
      blackoutStyles = "transition-all duration-200 opacity-10 ";
      Warnings = "Do Not Book";
    } else {
      blackoutStyles = "transition-all duration-200";
      Warnings = "";
    }

    let TableRows = <></>;
    let rows = [];
    for (let i = 0; i < this.state.bookings.length; i++) {
      console.log(this.state.bookings[i])
      let tenantName = `${this.state.bookings[i].tenant?.first_name} ${this.state.bookings[i].tenant?.last_name}`
      let tenantEmail = this.state.bookings[i].tenant?.email ? this.state.bookings[i].tenant?.email : ""
      console.log(tenantEmail)
      let curBookingId = this.state.bookings[i].id;
      // if (!(this.state.search == "" || this.state.bookings[i].tenant_name.toLowerCase().includes(this.state.search.toLowerCase()))) { continue; }

      let SpecialStyle = "";
      let SpecialStyle2 = "";
      let Special = <></>
      if (this.state.bookings[i].confirmed) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-blue-400 mr-10";
        Special = <span className="bg-blue-500 py-2 px-2 text-center rounded-full text-white">Confirmed</span>;
      }
      /* if (this.state.bookings[i].confirmed_check_out) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-green-400 mr-10";
        Special = <div className="bg-green-500 py-2 px-2 text-center rounded-full text-white overflow text-center">Checked Out</div>;
      } */
      if (this.state.bookings[i].cancelled) {
        SpecialStyle = "text-white text-sm  w-72";
        SpecialStyle2 = "text-red-400 mr-10";
        Special = <span className="bg-red-500 py-2 px-2 text-center rounded-full text-white">Cancelled</span>;
      }


      let keyStatus = <></>;
      keyStatus = <span className={`font-bold mx-1 text-${this.state.bookings[i].keys_confirm ? "green" : "red"}-500`}>K</span>;

      let cleaningStatus = <></>;
      cleaningStatus = <span className={`font-bold mx-1 text-${this.state.bookings[i].cleaning_confirm ? "green" : "red"}-500`}>C</span>;

      let paymentStatus = <></>;
      paymentStatus = <span className={`font-bold mx-1 text-${this.state.bookings[i].payment_confirm ? "green" : "red"}-500`}>$</span>;

      TableRows = (
        <tr
          key={i.toString()}
          className=" cursor-pointer flex flex-col lg:table-row hover:bg-gray-100 border-t border-gray-400 w-full"
        >
          {/* ID*/}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles}  w-full lg:w-20 font-thin  lg:text-left text-sm text-gray-700 px-1 mb-2 lg:mb-0  pt-3 lg:py-4`}
          >
            <span className="lg:hidden text-gray-500">Booking ID: </span>
            {this.state.bookings[i].id}
          </td>

          {/* Tenant */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className=" w-full lg:w-60 font-light text-sm text-gray-500 px-1 py-3 lg:py-4"
          >
            <div className="flex flex-row items-center">
              <div className="bg-gray-100 rounded-sm  w-10 h-10 shadow-sm flex flex-col justify-center items-center">
                <RiUser3Fill className="text-xl" />
              </div>
              <div className="px-4 flex flex-col ">
                <span className="text-base text-gray-600 font-semibold truncate lg:w-36">{tenantName}</span>
                <span className="text-xs text-gray-400 font-thin">{tenantEmail}</span>
              </div>
            </div>
          </td>

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} lg:w-40 px-1 py-2 lg:py-4`}
          >
            {/* <Tooltip content={<UnitBubble />} direction="" delay="">
              <div
                id="bookingConfirmed"
                className="  cursor-pointer h-6 mr-2 bg-gray-700 text-white font-medium text-xs lg:text-2xs rounded-full py-1 w-auto  flex items-center text-center px-5"
              >
                {" "}
                Signature 2{" "}
              </div>
            </Tooltip> */}
            {this.state.bookings[i].unit.suite_name}
          </td>

          {/* Check In */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} border-b lg:border-none  lg:w-32 font-thin text-sm text-gray-700 px-1 mt-2 lg:mt-0 py-3 lg:py-4`}
          >
            <span className="lg:hidden text-gray-500">Check In: </span>
            {this.state.bookings[i].check_in}
          </td>

          {/* Check Out */}

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className={`${blackoutStyles} lg:border-none  lg:w-32 font-thin text-sm text-gray-700 lg:text-gray-400 px-1  lg:mt-0 py-3 lg:py-4`}
          >
            <span className="lg:hidden text-gray-500">Check Out: </span>
            {this.state.bookings[i].check_out}
          </td>

          {/* Booking Warnings */}
          {/* I dont think bookings can be blacked out so this will be the checked out marker */}
          <td onClick={() => this.HandleRowClick(curBookingId)} className={`${blackoutStyles} lg:border-none  lg:w-32 font-thin text-sm text-gray-700 lg:text-gray-400 px-1  lg:mt-0 py-3 lg:py-4`}>
            <span className="flex-row justify-center my-3 ml-4">
              <span className="text-sm font-bold h-6 lg:px-5 py-2 rounded-md shadow-sm">{keyStatus} {cleaningStatus} {paymentStatus}</span>
            </span>
          </td>

          <td
            onClick={() => this.HandleRowClick(curBookingId)}
            className=" w-28 text-red-400 px-2 py-4 text-sm font-semibold"
          >
            {Warnings}
            {Special}
          </td>
        </tr>
      );
      rows.push(TableRows);
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row w-full">
          {/* Left Side */}
          <div className="flex flex-row w-full align-center items-center mb-6">
            {/* Search Bar */}
            <div className="flex flex-row rounded-sm w-full lg:w-2/6 py-1">
               <select
              onChange={this.handleDropdown}
              className="  block w-64 mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            >
                <option selected disabled className="text-gray-400">--Please Select a Corporate--</option>
              {this.state.corporates?.map((corporation) => (
                <option value={corporation.id}>{corporation.corporate_name}</option>
              ))}
            </select>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => { this.updateList() }}>Search</button>
            {/* <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10, 20, 30, 40, 50]}
            /> */}
          </div>
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 ">ID</th>
              <th className="py-2  ">Tenant</th>
              <th className="py-2  ">Unit</th>
              <th className="py-2  ">Check In</th>
              <th className="py-2  ">Check Out</th>
              <th className="py-2  ">Light Status</th>
              <th className="py-2 "></th> 
            </tr>
          </thead>
          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={8} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(CorporateBookings);
