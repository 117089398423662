import { Modal } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useNotification } from "../../../Notifications/NotificationProvider";
import { Constants } from "../../../../api-requests/apiLinkConstants";
import store from "../../../../redux/store";
import { fetchRequestJSON } from "../../../../api-requests/apiCallHelper";
import zIndex from "@mui/material/styles/zIndex";

type Props = {
    parkingData: any;
    open: boolean; 
    closeModal: any; 
};

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

function ShortenParking(props:Props) {
    const [date, setDate] = useState(dayjs()); 

    //Notification Handler Logic:
    const dispatch = useNotification();

    const NotificationHandler = (type: string, title: string, message: string,) => {
        dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message
        })
    }
    //-----------------------

    const submitShortenRequest = async() => {
        //Extend the date:
        if(date.format('YYYY-MM-DD') <= props.parkingData.start_date){
            NotificationHandler('Error', 'Error', `The end date selected must be greater than the parking start date: ${props.parkingData.start_date}`)
            return
        } 

        if(date.format('YYYY-MM-DD') > props.parkingData.end_date) {
            NotificationHandler('Warning', 'Warning', `The selected end date must be less than the current end date when shortening parking`)
            return
        }

        const shortenParking = await fetchRequestJSON('PUT', Constants.API_PATH_PARKING_SHORTEN, {
            id: props.parkingData.id,
            dateToChange: date
        })

        if(shortenParking.status == "Success") {
            NotificationHandler('Success', 'Success', 'Parking End Date has been modified to the selected date.')
            let update = store.getState().parkingReducer.profileUpdate;
            update()
            props.closeModal()
            return
          } else {
            NotificationHandler('Error', 'Error', shortenParking.data)
            return
        }
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            open={props.open}
            onClose={() => props.closeModal()}
            sx={{
                zIndex: 40
            }}
        >
            <div className=" appear bg-white shadow-lg rounded-lg h-1/2 w-1/3 border-4  border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center">
                <span className="text-xl font-bold text-gray-800 text-left pb-4"><AiOutlineClockCircle className="text-3xl text-blue-500" />Shorten Request</span>
                <span className="text-xs text-gray-600 text-left pb-4">Parking Start Date: <span className="text-green-400"><u>{props?.parkingData?.start_date}</u></span></span>
                <span className="text-xs text-gray-600 text-left pb-4">Parking End Date: <span className="text-green-400"><u>{props?.parkingData?.end_date}</u></span></span>
                <label className="pl-3 mb-5 mx-auto flex flex-col">
                    <span className="text-gray-400 text-xs">New Parking End Date</span>
                    <input
                        id="date"
                        onChange={(e) => setDate(dayjs(e.target.value))}
                        // value={date}
                        type="date"
                        className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                        placeholder=""
                    />
                </label>
                <div className="h-24 mx-auto flex flex-col">
                    <button
                        onClick={() => submitShortenRequest()}
                        className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ShortenParking;
