import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import UnitList from "./UnitList";
import OwnerList from "./OwnerList";
import TabList from "../TabList";
import { MdPlaylistAdd } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import Label from "../LabelWrap";
import NewUnit from "./NewUnit";
import BuildingList from "./BuildingList";
import NewOwner from "./NewOwner";
import NewBuilding from "./NewBuilding";
import BlackoutList from "./BlackoutList";
import NewBlackout from "./NewBlackout";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import UnitActiveModal from "./UnitActiveModal";
import AppStage from "../../utils/Types";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  editToggle: boolean;
  newOwner: boolean;
  newBuilding: boolean;
  newBlackout: boolean;
  curUnitId: number; 
  csv_url: string;
  selected_button: string;
  activeState:boolean;
};

//Create Map for our csv constants:
//TODO: once the apis are working, replace the values with Constants....
const CVSAPIS: Map<string, string> = new Map([
  ['blackouts', Constants.API_PATH_BLACKOUT_CSV],
  ['buildings', Constants.API_PATH_BUILDING_GET_CSV],
  ['owners', Constants.API_PATH_OWNER_CSV],
  ['active units', Constants.API_PATH_UNIT_GET_CSV], //note: bug only issued for the above three csvs, the active and inactive users sharing same csv wasn't placed as a bug but should let MK know
  ['inactive units', `${Constants.API_PATH_UNIT_GET_CSV}?inactive=true'`],
]);

class Units extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "",
      check_in: "",
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      editToggle: false,
      newOwner: false,
      newBuilding: false,
      newBlackout: false,
      activeState: false,
      curUnitId: -1,
      csv_url: "#",
      selected_button: "active units",
    };
  }

  handleButtonSelect = async (name: string) => {
    console.log(name);
    this.setState({selected_button: name})
    const url = await this.handleDownloadCSV(name);
    this.setState({csv_url: url});
  }

  handleDownloadCSV = async (name:string) => {
    const request = await fetchGetJSON(CVSAPIS.get(name));

    if (request.status == "Success") {
      let data = new Blob([request.data], {type: 'text/plain'});
      return window.URL.createObjectURL(data);
    }
    return "#";
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  handleBlackoutChange = (id: any, unit_id: number) => {
    this.setState({ 
      newBlackout: !this.state.newBlackout,
      curUnitId: unit_id,
    });
  }

  handleActiveChange = (id: any, unit_id: number) => {
    console.log("Toggle active state")
    this.setState({ 
      activeState: !this.state.activeState,
      curUnitId: unit_id,
    });
  }

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  testFunction = () => {
    console.log("Testing change in sections")
  }
  

  componentDidMount = async () => {
    let url = await this.handleDownloadCSV("active units");
    this.setState({csv_url: url});
  }

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let editFocus = "opacity-100";
    let bgFocus;

    if (this.state.editToggle === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    let owners=<></>
    if (this.state.selected_button === "owners"){
      owners = 
      <>
        <button
            onClick={() => this.handleToggleChange("newOwner", false)}
            className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
        >
          <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
          <span className=" text-md ">New Owner</span>
        </button>
      </>
    }
  
    return (
      <div className=" bg-white font-display lg:rounded-tl-lg pt-3 pb-20 lg:pt-0 lg:pb-0  ">
        <UnitActiveModal
          handleModalToggle={() =>
            this.handleActiveChange("activeState", -1)
          }
          onoff={this.state.activeState}
          title={`Deactivate Unit ID:${this.state.curUnitId}`}
          id={this.state.curUnitId}
        />
        <NewOwner
          handleModalToggle={() =>
            this.handleToggleChange("newOwner", this.state.newOwner)
          }
          onoff={this.state.newOwner}
          title={"Add New Owner"}
        />
        <NewBlackout
          handleModalToggle={() =>
            this.handleBlackoutChange("newBlackout", -1)
          }
          onoff={this.state.newBlackout}
          title={"Add New Blackout"}
          id={this.state.curUnitId}
        />
        <NewBuilding
          handleModalToggle={() =>
            this.handleToggleChange("newBuilding", this.state.newBuilding)
          }
          onoff={this.state.newBuilding}
          title={"Add New Building"}
        />
        <NewUnit
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
        />
        {bgFocus}
        <BreadCrumb
          rootPage="Units"
          subPage=""
          sideButton=""
          buttonAction={""}
        />

        <div className="fade-in px-4 lg:px-10 ">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2 mt-14 lg:mt-0 px-2">
            {/* Left Side  */}
            <div className="flex flex-row justify-between w-full text-center items-center lg:items-start lg:flex-col lg:w-1/2">
              <h1 className="lg:hidden font-sofia text-gray-500 text-3xl font-bold ">
                Units
              </h1>
              {(this.state.selected_button == "active units" || this.state.selected_button == "inactive units") && 
                <button
                  onClick={this.handleEditBarToggle}
                  className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Unit</span>
                </button>
              }
              {/* {owners} */}
              {(this.state.selected_button == "owners") && 
                <button
                  onClick={() => this.handleToggleChange("newOwner", false)}
                  className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Owner</span>
                </button>
              }
              {(this.state.selected_button == "buildings") && 
                <button
                  onClick={() => this.handleToggleChange("newBuilding", false)}
                  className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Building</span>
                </button>
              }
              {(this.state.selected_button == "blackouts") && 
                <button
                  onClick={() => this.handleBlackoutChange("newBlackout", -1)}
                  className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Blackout</span>
                </button>
              }
            </div>

            {/* Right Side */}
            <div className="hidden lg:flex flex-row w-1/2 justify-end px-3 ">
              {
                AppStage == "staywell" &&
                <a 
                  className="transition duration-100 border-2 border-solid w-36 bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                  href={this.state.csv_url}
                  download={`${this.state.selected_button}.csv`}
                >
                  <AiOutlineDownload className="  text-xl mr-1 self-center" />
                  <span className=" text-xs self-center ">Download CSV</span>
                </a>
              }

            </div>
          </div>

          <TabList warning={false} tabSelectButton={this.handleButtonSelect}>
            <Label label="Active Units">
              <UnitList
                handleState={this.handleBlackoutChange}
                handleStateActive={this.handleActiveChange}
                active={true}
              />
            </Label>

            <Label label="Inactive Units">
              <UnitList
                handleState={this.handleBlackoutChange}
                handleStateActive={this.handleActiveChange}
                active={false}
              />
            </Label>

            <Label label="Owners">
              <OwnerList handleState={this.handleToggleChange} />
            </Label>

            <Label label="Buildings">
              <BuildingList handleState={this.handleToggleChange}/>
            </Label>

            <Label label="Blackouts">
              <BlackoutList handleState={this.handleBlackoutChange}></BlackoutList>
            </Label>
          </TabList>
        </div>
      </div>
    );
  }
}

export default Units;

