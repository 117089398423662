import { Modal } from '@mui/material'; 

type Props = {
    BookingData: any;
    open: boolean; 
    closeModal: any;
    submit: any;
};

function ZeroMonthlyFeeModal(props: Props) {
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            open={props.open}
            onClose={() => props.closeModal()}
        >

            <div className=" appear bg-white shadow-lg rounded-lg h-3/5 w-5/12 border-4 border-gray-500 py-5 px-5 flex flex-col align-self-center max-h-60 max-w-2xl">
                <span className="text-xl font-bold text-red-500 text-left pb-4 underline">Warning: No Monthly Fee Booking</span>
                <div className="items-center flex flex-col">
                    <label className="flex flex-col mt-4 mb-6">
                        <span className="text-gray-800 text-lg text-center">
                            You are about to create a booking with no monthly fee.
                            <br />
                            Would you still like to proceed with creating this booking?
                        </span>
                    </label>
                    <div className="flex flex-row">
                        <button
                            onClick={(event) => props.submit(event)}
                            className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm mr-8"
                        >
                            Create Booking
                        </button>
                        <button
                            onClick={() => props.closeModal()}
                            className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export {ZeroMonthlyFeeModal}
