import React, { Component } from "react";
// import Tooltip from "../../Tooltip";
// import UnitBubble from "../../UnitBubble";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { formatFullDateString } from "../../../utils/DateFormat";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import store from "../../../redux/store";

const unitDataApiPath = "/unit/";
const ownerDataApiPath = "/unit-owner/";

type Props = {
  UnitData: any;
};

type State = {
  suite: string;
  suite_number: string;
  suite_address: string;
  suite_model: string;
  suite_type: string;
  min_stay: string;
  heat_building: boolean;
  suite_size: string;
  bath_count: string;
  isp: string;
  isp_account: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  rent_due: number;
  rent_increase_date: Date;
  lease_start: Date;
  lease_end: Date;
  last_month_rent_hold: number;
  deposit_hold: number;
  password: string;
  login: string;
  unit_phone: string;
  tvp: string;
  hydro_provider: string;
  notes: string;
};

class UnitInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let modelType = [];

    if (this.props.UnitData.managed_model === true) {
      modelType = [
        <></>,

        <>
          <span className="text-sm text-gray-400 mt-2">
            Percent Managed -{" "}
            <span className="text-gray-600">
              {this.props.UnitData.percent_managed}%
            </span>
          </span>
        </>,
      ];
    } else {
      //This is left as empty because we don't need to increase the rent anymore
      modelType = [
        // <div key={this.props.UnitData} className="flex flex-col  text-sm">
        //   <span className="text-gray-500">Rent Increase Date:</span>
        //   <div className="flex flex-row items-center justify-start mt-1">
        //     <FaRegCalendarAlt className="text-lg text-gray-400 mr-2 " />
        //     {/* <span className=" text-gray-400 pt-1">
        //       {formatFullDateString(
        //         this.props.UnitData.rent_increase_date.toString(),
        //         ""
        //       )}
        //     </span> */}
        //   </div>
        // </div>,

        // <>
        //   <span className="text-sm text-gray-400 mt-6">
        //     Last Month Rent Held -{" "}
        //     <span className="text-gray-600">
        //       {formatAmountForDisplay(
        //         this.props.UnitData.last_month_rent_hold,
        //         "CAD",
        //         false
        //       )}
        //     </span>
        //   </span>
        //   <span className="text-sm text-gray-400 mt-2">
        //     Deposit Held -{" "}
        //     <span className="text-gray-600">
        //       {formatAmountForDisplay(
        //         this.props.UnitData.deposit_hold,
        //         "CAD",
        //         false
        //       )}
        //     </span>
        //   </span>
        //   <div className=" text-gray-800  my-4 border-b "> </div>
        //   <span className="text-sm text-gray-400 ">
        //     Lease Start -{" "}
        //     {/* <span className="text-gray-600">
        //       {formatFullDateString(
        //         this.props.UnitData.lease_start.toString(),
        //         ""
        //       )}
        //     </span> */}
        //   </span>
        //   <span className="text-sm text-gray-400 mt-2">
        //     Lease End -{" "}
        //     {/* <span className="text-gray-600">
        //       {formatFullDateString(
        //         this.props.UnitData.lease_end.toString(),
        //         ""
        //       )}
        //     </span> */}
        //   </span>
        // </>,
      ];
    }

    let balcony = (
      <span className="text-thin text-sm bg-gray-100 py-2 px-7 rounded-full text-gray-500">
        Balcony
      </span>
    );
    if (this.props.UnitData.balcony !== true) {
      balcony = <></>;
    }

    return (
      <div className="pt-2">
        <div className=" slide-in-blurred-top font-display border flex flex-row px-4 py-4 mb-4">
          <div className="w-1/2 flex flex-col">
            <span className="text-lg ">{this.props.UnitData.suite_type} {this.props.UnitData.suite_number ? `| Suite Number: ${this.props.UnitData.suite_number}`: ''}</span>
            <span className="text-sm text-gray-400 font-europe">
              Minimum {this.props.UnitData.min_stay} Stay
            </span>
            <span className="text-xs mt-2 text-gray-600 font-europe">
              {this.props.UnitData.suite_size ?  this.props.UnitData.suite_size : this.props.UnitData.square_feet} sq ft. |{" "}
              {this.props.UnitData.bath_count ? this.props.UnitData.bath_count : this.props.UnitData.bathrooms} bathrooms
            </span>
          </div>

          <div className="w-1/2 flex flex-col items-end">{balcony}</div>
        </div>

        <div className=" slide-in-blurred-top grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* <div className="  font-display border px-5 pt-3 pb-5  flex flex-col ">
            <span className="text-lg mb-3">Services:</span>

            <span className="text-sm text-gray-400  mb-3 mt-1">
              <span className="text-gray-600">ISP Provider: </span>
              {this.props.UnitData.isp}{" "}
            </span>
            <span className="text-sm text-gray-400 mb-3  ">
              <span className="text-gray-600">Internet Account #: </span>
              {this.props.UnitData.isp_account}{" "}
            </span>
            <span className="text-sm text-gray-400  mb-3 ">
              <span className="text-gray-600">Login: </span>
              {this.props.UnitData.login}{" "}
            </span>
            <span className="text-sm text-gray-400 mb-3">
              <span className="text-gray-600">Password: </span>
              {this.props.UnitData.password}{" "}
            </span>
            <div className=" text-gray-800  mb-3 border-b "> </div>
            <span className="text-sm text-gray-400 mb-3 ">
              <span className="text-gray-600">Unit Phone: </span>
              {this.props.UnitData.unit_phone}{" "}
            </span>
            <div className=" text-gray-800  mb-3 border-b "> </div>
            <span className="text-sm text-gray-400  mb-3 ">
              <span className="text-gray-600">TV Provider: </span>
              {this.props.UnitData.tvp}{" "}
            </span>
            <span className="text-sm text-gray-400 mb-3  ">
              <span className="text-gray-600">TV Account #: </span>
              {this.props.UnitData.isp_account}{" "}
            </span>
          </div> */}

          <div
            className=" slide-in-blurred-top font-display border py-4 px-5
           flex flex-col"
          >
            <span className="text-lg mb-2">Bed Configuration</span>
            <div className="flex flex-row">
                <div className="flex flex-row items-center justify-start mt-1">
                  <span className=" text-gray-500 text-base pt-1">
                    {this.props.UnitData.bed_config? this.props.UnitData.bed_config : "No information given about bed sizes"}
                  </span>
              </div>

              {/* This is commented out as Rent increase due date does not exist anymore in the system and this is what model type is*/}
              {/* {modelType[0]} */}
            </div>

            {/* {modelType[1]}
            <div className=" text-gray-800  my-4 border-b "> </div>
            <span className="text-sm text-gray-400 mt-1">
              Hydro Provider-{" "}
              <span className="text-gray-600">
                {this.props.UnitData.hydro_provider}
              </span>
            </span> */}
          </div>
          <div className=" slide-in-blurred-top font-display flex flex-col border py-4 px-5">
            <span className="text-lg mb-3">Notes:</span>
            <span className="text-gray-500 text-sm">
              {this.props.UnitData.notes}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default UnitInfo;
