import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch } from "react-router-dom";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Chart from "react-apexcharts";
import store from "../../../redux/store";
import { UserTypes } from "../../../redux/userReducer";
import { BsFillPersonPlusFill } from "react-icons/bs";
import DashBar from "../DashBar";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import {
  getGroupList,
  joinGroup,
  leaveGroup,
  getUserList,
} from "../../../api-requests/api-gateway";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { getCurrentUser } from "aws-amplify/auth";

type Props = {
  userData: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
  options: object;
  // series: Array<any>;
  authId: string; // TODO: What does authID represent?
  cleaners: any;
  cleaner_view: boolean;
  edit_cleaner: boolean;
  start_date: any;
  end_date: any;
  nonCleaner: any;
  cleanerMapping: any;
  user_id: any;
};

class Cleaners extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      confirmed: false,
      flight_number: "AC1130",
      authId: "",
      cleaners: [],
      cleaner_view: false,
      edit_cleaner: true,
      start_date: -1,
      end_date: -1,
      nonCleaner: [],
      cleanerMapping: {},
      user_id: -1,

      options: {
        yaxis: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
        },

        labels: [
          "Form Completion",
          "1 Bed",
          "2 Bed",
          "3 Bed",
          "August",
          "September",
        ],
      },

      // series: [
      //   {
      //     name: "Radar Series 1",
      //     data: [0.5, 0.4, 0.8, 0.4, 0.4, 1],
      //   },
      // ],
    };

    this.handleFormView = this.handleFormView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleFormView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  handleInputChange = (event: any) => {
    let cleaners = this.state.cleanerMapping;
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = cleaners[this.target.value];
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleCleanerInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      console.log(this.target.id + " " + this.target.value);
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.setState({ cleaner_view: !this.state.cleaner_view });
    }
  };

  updateList = async () => {
    try {
      let res = await getCurrentUser();
      this.setState({ authId: res.username });

      // TODO: Add "get all cleaners" API call
      // TODO: Set cleaners = the fetched list of cleaners
      let allCleaners = await fetchGetJSON(`${Constants.API_PATH_CLEANER_GET_ALL}?active=true`);
      console.log(allCleaners.data);
      if (allCleaners.status === "Success") {
        this.setState({ cleaners: allCleaners.data }); // from user_id --> get the user's name ... so that split() actually works
      } else {
        console.log("an error occurred: couldn't get all cleaners");
      }

      // getGroupList("Finance").catch((data) => {
      //   this.setState({cleaners: data.Users})
      // })
      let userList = await getUserList();

      console.log(allCleaners.data);
      let users = [];
      for (let i = 0; i < allCleaners.data.length; i++) {
        let user = userList.Users.filter(
          (user:any) => user.Username === allCleaners.data[i].user_id
        );
        console.log(user);
        let name = user[0].Attributes.find((attr: any) => {
          return attr.Name == "name";
        }).Value;
        users.push({
          username: allCleaners.data[i].user_id,
          name: name,
        });
      }
      console.log("users", users);

      let CleanersUsername = [];
      for (let i = 0; i < allCleaners.data.length; i++) {
        CleanersUsername.push(allCleaners.data[i].user_id);
      }

      let nonCleanerUsers = [];
      let cleanmap: any = {};
      for (let i = 0; i < userList.Users.length; i++) {
        const name = userList.Users[i].Attributes.find((attr: any) => {
          return attr.Name == "name";
        }).Value;
        if (!CleanersUsername.includes(userList.Users[i].Username)) {
          nonCleanerUsers.push(name);
          cleanmap[name] = userList.Users[i].Username;
        }
      }
      console.log(users.sort().reverse());
      this.setState({ cleanerMapping: cleanmap });
      this.setState({ cleaners: users.sort().reverse() });
      this.setState({ nonCleaner: nonCleanerUsers });
    } catch {}
  };
  componentDidMount = async () => {
    this.updateList();
  };

  joinCleaners = () => {
    this.setState({ cleaner_view: true });
    this.setState({ edit_cleaner: true });
  };

  leaveCleaners = async () => {
    this.setState({ cleaner_view: true });
    this.setState({ edit_cleaner: false });
  };

  addCleaners = async () => {
    console.log(this.state.user_id);
    if (this.state.user_id === -1) {
      alert("Please Fill In All Fields");
      return;
    }

    let name: string = this.state.cleaners.find((cleaner: any) =>  cleaner.username === this.state.user_id).name;

    let data = {
      user_id: this.state.user_id,
      first_name: name.split(" ")[0],
      last_name: name.split(" ")[1],
    };
    console.log(data);

    let res = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_CLEANER_CREATE,
      data
    );
    console.log(res);
    if (res.status !== "Success") {
      alert("Please verify you entered your data correctly.");
    } else {
      this.updateList();
      this.setState({ cleaner_view: false });
    }
  };

  removeCleaners = async () => {
    let res = await fetchRequestJSON(
      "DELETE",
      Constants.API_PATH_CLEANER_DEACTIVATE + this.state.user_id,
      {}
    );
    console.log(res);
    if (res.status !== "Success") {
      alert(res.data);
    } else {
      this.updateList();
      this.setState({ cleaner_view: false });
    }
  };

  render() {
    let today = new Date();

    /*
    if(store.getState().userReducer.userType === UserTypes.Admin || store.getState().userReducer.userType === UserTypes.Sales) {
      newCleanerButton = <div className="flex flex-col w-1/2 items-end pr-2">
        <button
          onClick={() => {
            console.log("hey"); 
          }}
          className=" group shadow-md transition-all duration-100 w-36 bg-white border-2 border-green-900 hover:bg-green-900 text-indigo-900 hover:text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
        >
          <BsFillPersonPlusFill className=" text-green-900 text-xl group-hover:text-white mr-2 self-center transition-all duration-100" />
          <span className=" text-md ">New Cleaner</span>
        </button>
      </div>
    }
    */

    return (
      <div className=" font-display bg-f9 h-screen lg:rounded-tl-lg py-3 lg:pt-0 ">
        {this.state.cleaner_view && (
          <div
            id="outsideDiv"
            onClick={this.handleModal}
            className={
              "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
            }
          >
            <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
              <span className="text-xl font-bold text-gray-800 text-left"></span>
              <span className="text-xs text-center text-gray-400"></span>
              <div className="grid grid-cols-3 gap-6 w-full my-5">
                <label className="block  ">
                  <span className="text-gray-400 text-xs">
                    <b>User</b>
                  </span>
                  {this.state.edit_cleaner ? (
                    <select
                      id="user_id"
                      onChange={this.handleInputChange}
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                    >
                      {this.state.nonCleaner.map((user: any) => (
                        <option value={user}>{user}</option>
                      ))}
                    </select>
                  ) : (
                    <select
                      id="user_id"
                      onChange={this.handleCleanerInputChange}
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder=""
                    >
                      {this.state.cleaners.map((user: any) => (
                        <option value={user.username}>{user.name}</option>
                      ))}
                    </select>
                  )}
                </label>
              </div>

              <div className="flex flex-row justify-start w-full mt-6">
                {this.state.edit_cleaner ? (
                  <button
                    id="addCleaner"
                    onClick={this.addCleaners}
                    className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                  >
                    Add Cleaner
                  </button>
                ) : (
                  <button
                    id="removeCleaner"
                    onClick={this.removeCleaners}
                    className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                  >
                    Remove Cleaner
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-14  ">
          <div className=" flex flex-row justify-between font-circular pl-1  w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""} />

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Cleaning Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          {/* Heading Info Area  */}

          <div className="px-2 my-8 ">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between w-full mb-2 items-end">
                <span className=" pl-1 text-gray-700 font-medium text-2xl">
                  Cleaners
                </span>

                {/* TODO: Right now, the cleaners page shows either the leave cleaners or join cleaners button. */}
                {/* TODO: But the old admin system shows both, and lets you do both. */}
                {/* TODO: Should I display both buttons at once? */}
                <div className="flex flex-col w-1/2 items-end pr-2">
                  <button
                    onClick={this.leaveCleaners}
                    className="mb-3 group shadow-md transition-all duration-100 w-44 bg-white border-2 border-red-700 hover:bg-red-700 text-red-700 hover:text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                  >
                    <AiOutlineUserDelete className=" text-red-700 text-2xl group-hover:text-white mr-2 self-center transition-all duration-100" />
                    <span className=" text-md ">Leave Cleaners</span>
                  </button>

                  <button
                    onClick={this.joinCleaners} // TODO: create new "add cleaner" form?
                    className=" mb-3 group shadow-md transition-all duration-100 w-44 bg-white border-2 border-green-700 hover:bg-green-700 text-green-700 hover:text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                  >
                    <AiOutlineUserAdd className=" text-green-700 text-2xl group-hover:text-white mr-2 self-center transition-all duration-100" />
                    <span className=" text-md ">Join Cleaners</span>
                  </button>
                </div>
              </div>

              <div className="flex flex-row w-full align-center items-center my-2">
                {/* Search Bar */}
                {/* <div className="flex flex-row border bg-white rounded-sm w-1/4  py-1">
                  <MdSearch className="text-xl text-gray-400 mx-1" />
                  <input
                    className="text-sm focus:border-0 focus:outline-none "
                    placeholder="Search..."
                  ></input>
                </div> */}
                {/* <span className="py-1 px-4 font-medium margin text-gray-500 ">
                  Filters:
                </span>

                <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
                  All
                </button>
                <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
                  Regular
                </button>
                <button className="transition duration-100 mr-2 border border-solid  bg-white hover:bg-gray-100 text-gray-500 text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center">
                  Turnover
                </button> */}
              </div>
            </div>

            <div className=" mt-10 max-h-screen h-80  overflow-y-auto shadow-md rounded-xl w-full  scroll-hide ">
              <table
                style={{ borderSpacing: "0" }}
                className="border-separate  relative w-full  table-auto bg-white overflow-x-scroll     "
              >
                <thead className="border-b border-gray-400 ">
                  <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                    <th className="sticky top-0 py-4 pl-5  font-thin border-b border-gray-300 ">
                      User ID
                    </th>
                    <th className=" sticky top-0 w-48 py-3 font-thin border-b border-gray-300">
                      Name
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y">
                  <CleanerList
                    viewForm={this.handleFormView}
                    data={this.state.cleaners}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cleaners;

export function CleanerList(props: any) {
  let rows = [];

  for (var i = 0; i < props.data.length; i++) {
    let altBg = "";
    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    let line = (
      <tr
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="py-4 pl-5 text-purple-400 font-thin ">
          {props.data[i].username}
        </td>
        <td className={" py-3 font-thin "}>{props.data[i].name}</td>
        <td className="py-3  font-thin "></td>
        <td className=" text-right ">
          {/* <button
            onClick={props.viewInvoice}
            className=" h-8 w-auto mr-3 px-6  border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
          >
            View Form
          </button> */}
        </td>
      </tr>
    );

    rows.push(line);
  }

  return <>{rows}</>;
}

// function CleanDashLinks(props: any) {
//   // The `path` lets us build <Route> paths that are
//   // relative to the parent route, while the `url` lets
//   // us build relative links.
//   let { path, url } = useRouteMatch();
//   let currentPageStyle = "text-gray-400";
//   let linkEnd = props.linkEnd;
//   let toPath = "";
//   let currentPage = url.substring(url.lastIndexOf("/"));

//   if (path.endsWith(linkEnd) && linkEnd != "") {
//     currentPageStyle = "border-b-2 border-green-500 text-gray-800";
//     toPath = url;
//   } else {
//     toPath = url.replace(currentPage, linkEnd);
//   }

//   return (
//     <div className={currentPageStyle + " mr-5 "}>
//       <Link to={toPath}>{props.text}</Link>
//     </div>
//   );
// }
