import React, { useEffect, useState } from "react";
import BreadCrumb from "../BreadCrumb";
import { useRouteMatch, withRouter, RouteComponentProps } from "react-router";
import { MdPlaylistAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import TabList from "../TabList";
import Label from "../LabelWrap";
import BookingList from "../Bookings/BookingList";
import BookingCalendar from "../Bookings/BookingCalendar";
import CorporateBookings from "../Bookings/CorporateBookings";
import NewParkingSpot from "./Parking Spots Components/Create/NewParkingSpot";
import ParkingSpotList from "./Parking Spots Components/Read/ParkingSpotList";
import NewParkingSpotBlackouts from "./Parking Spots Blackout Components/Create/NewParkingSpotBlackouts";
import ParkingSpotBlackoutList from "./Parking Spots Blackout Components/Read/ParkingSpotBlackoutList";
import ParkingSpotActiveModal from "./Parking Spots Components/Update/ParkingSpotActiveModal";
import NewParkingSpotBlackoutsModal from "./Parking Spots Blackout Components/Create/NewParkingSpotBlackoutsModal";
import ParkingCalendar from "./Parking Components/Read/ParkingCalendar";
import ParkingCreate from "./Parking Components/Create/ParkingCreate";
import ParkingList from "./Parking Components/Read/ParkingList";

type Props = {
  history: any;
  location: any;
}

const Parkings = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState('parking list');

    //Toggles: 
    const [toggleNewParking, setToggleNewParking] = useState(false);
    const [toggleNewParkingSpot, setToggleNewParkingSpot] = useState(false);
    const [toggleNewParkingSpotBlackout, setToggleNewParkingSpotBlackout] = useState(false);
    const [toggleNewBlackoutModal, setToggleNewBlackoutModal] = useState(false);

  
    const [activeState, setActiveState] = useState(false)
    const [currentParkingSpotID, setCurrentParkingSpotID] = useState(-1)
    const [currentParkingSpotName, setCurrentParkingSpotName] = useState('')

  

    const handleButtonSelect = async (name: string) => {
      setSelectedTab(name);
      //Whenever we change tabs, we should close our creation modals
      setToggleNewParkingSpot(false)
      setToggleNewParkingSpotBlackout(false)
    }

    const handleActiveChange = (id: any, parking_id: number) => {
      setActiveState(!activeState)
      setCurrentParkingSpotID(parking_id)
    }

    //Used for Modal on Parking Spots List
    const handleBlackoutChange = (parkingSpotName: string, parking_id: number) => {
      setToggleNewBlackoutModal(!toggleNewBlackoutModal)
      setCurrentParkingSpotID(parking_id)
      setCurrentParkingSpotName(parkingSpotName)
    };


    const NewParkingLink = () => {
      // The `path` lets us build <Route> paths that are
        // relative to the parent route, while the `url` lets
        // us build relative links.
        let { path, url } = useRouteMatch(); 
      
        return (
          <div>
            {
              selectedTab == "parking list" &&
                <button className="  shadow-md transition text-md duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center "
                  onClick={() => toggleParkingCreate()}
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  New Parking
                </button>
            }
            {
              selectedTab == "parking spots" &&
                <button className="shadow-md transition text-md duration-100 w-60 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                  onClick={() => toggleParkingSpotCreate()}
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  New Parking Spots
                </button>
            }
            {
              selectedTab == "parking spot blackouts" &&
                <button className="  shadow-md transition text-md duration-100 w-72 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center "
                  onClick={() => toggleParkingSpotBlackoutCreate()}
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  New Parking Spot Blackout
                </button>
            }
            {
              selectedTab == "parking calendar" &&
                <button className="  shadow-md transition text-md duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center "
                  onClick={() => toggleParkingCreate()}
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  New Parking
                </button>
            }
          </div>
        );
    }

    //Can make this all one function below: 
    const toggleParkingSpotCreate = () => {
      setToggleNewParkingSpot(!toggleNewParkingSpot);
    }

    const toggleParkingSpotBlackoutCreate = () => {
      setToggleNewParkingSpotBlackout(!toggleNewParkingSpotBlackout);
    }

    const toggleParkingCreate = () => {
      setToggleNewParking(!toggleNewParking)
    }

    //This is used only for bg focus when closing modal, need to really rework this in the future for now it works
    const handleCreateBarToggle = () => {
      //Handles which modal gets clicked
      const toggle = toggleNewParkingSpot;
      const toggle2 = toggleNewParkingSpotBlackout
      const toggle3 = toggleNewParking;
      if (toggle === true && toggle2 == false && toggle3 == false) {
        setToggleNewParkingSpot(false);
      } 

      if(toggle2 == true && toggle == false && toggle3 == false) {
        setToggleNewParkingSpotBlackout(false);
      }

      if(toggle3 == true && toggle2 == false && toggle == false ) {
        setToggleNewParking(false);
      }
    };

    return (
        <div className="  bg-white font-display lg:rounded-tl-lg pt-3 pb-20 lg:pt-0 lg:pb-0  ">
            <ParkingCreate
              toggle={toggleNewParking}
              handleCreateBarToggle={toggleParkingCreate}
            />
            <ParkingSpotActiveModal
              handleModalToggle={() => handleActiveChange("activeState", -1)}
              title={`Deactivate Parking ID: ${currentParkingSpotID}`}
              onoff={activeState}
              id={currentParkingSpotID}
            />
            <NewParkingSpotBlackoutsModal
              handleModalToggle={() => 
                handleBlackoutChange("newBlackout", -1)
              }
              onoff={toggleNewBlackoutModal}
              id={currentParkingSpotID}
              title={`Add New Parking Spot Blackout`}
              parkingSpotLocation={currentParkingSpotName}
            />
            <NewParkingSpot
              toggle={toggleNewParkingSpot}
              handleCreateBarToggle={handleCreateBarToggle}
            />
            <NewParkingSpotBlackouts 
              toggle={toggleNewParkingSpotBlackout} 
              handleBlackoutsToggle={handleCreateBarToggle}
            />
            {/* Modals for Creating Parking Blackout and Confirmation of DeActivation*/}
            {
              (toggleNewParkingSpot || toggleNewParkingSpotBlackout || toggleNewParking) &&
              <div
                onClick={handleCreateBarToggle}
                className=" fade-bg w-full h-screen bg-black z-40  fixed"
              ></div>
            }

            <BreadCrumb
                rootPage="Parkings"
                subPage=""
                sideButton=""
                buttonAction={""}
            />
            <div className="fade-in px-4 lg:px-10 ">
            {/* Heading Info Area  */}
            <div className="flex flex-row mb-2 mt-14 px-2">
                {/* Left Side  */}
                <div className="flex flex-row justify-between w-full text-center items-center lg:items-start lg:flex-col lg:w-1/2">
                <h1 className="lg:hidden font-sofia text-gray-500 text-3xl font-bold ">
                    Parkings
                </h1>
                <NewParkingLink/>
                </div>
            </div>

            <TabList warning={false} tabSelectButton={handleButtonSelect}>
                <Label label="Parking List">
                  <ParkingList
                    history={props.history} 
                    location={props.location} 
                  />
                </Label>

                <Label label="Parking Calendar">
                  <div className=" py-4 fade-in ">
                      <ParkingCalendar/>
                  </div>
                </Label> 
                <Label label="Parking Spots">
                    <ParkingSpotList
                        handleStateActive={handleActiveChange}
                        handleModal={handleBlackoutChange} 
                        history={props.history} 
                        location={props.location} 
                        match={undefined}                    
                    />
                </Label>
                <Label label="Parking Spot Blackouts">
                    <ParkingSpotBlackoutList
                      history={props.history}
                      location={props.location}
                    />
                </Label>
            </TabList>
            </div>
      </div>
    )
}

export default Parkings;
