import React from "react";
import { BsFillFlagFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";

type Props = {
  ownerInfo: any;
};

function ParkingSpotOwnerTab(props: Props) {

  const handleRowClick = (ownerID:number) => {
    const url = `${window.location.origin}/user/units/ownerprofile/?id=${ownerID}`
    window.open(url, "_blank")
  };

  return (
    <div className=" slide-in-blurred-top font-display  px-2 py-4">
      <h1 className=" text-2xl font-bold my-1">Owner</h1>
      <table className=" hidden lg:table table-auto w-full mb-8 ">
        <thead>
          <tr className="text-left text-gray-500 ">
            <th className="py-2 font-thin ">ID</th>
            <th className="py-2 font-thin ">Full Name</th>
            <th className="py-2 font-thin ">Email </th>
            <th className="py-2 font-thin ">Phone</th>
            <th className="py-2 font-thin ">Profile</th>
            <th className="py-2 font-thin ">Flagged?</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t-2 text-gray-800  ">
            <td className="  text-sm lg:text-md text-purple-400 px-1 py-4">
              {props.ownerInfo.id}
            </td>
            <td className="  text-sm lg:text-md  px-1 py-4 text-gray-500">
              {props.ownerInfo.first_name} {props.ownerInfo.last_name}
            </td>
            <td className=" font-thin text-xs lg:text-sm text-gray-600 px-1  py-4">
              {props.ownerInfo.email}
            </td>
            <td className="  text-xs lg:text-sm px-1  py-4">
              {props.ownerInfo.phone_number}
            </td>
            <td
              className="  text-xs lg:text-sm px-1  "
              onClick={()=> handleRowClick(props.ownerInfo?.id)}
            >
              <CgProfile className="text-2xl transition-all cursor-pointer text-gray-700 hover:text-gray-300" />{" "}
            </td>
            <td className="  text-xs lg:text-sm px-1  py-4">
              <BsFillFlagFill
                className={
                  " text-2xl transition-all cursor-pointer  hover:text-gray-300"
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ParkingSpotOwnerTab;
