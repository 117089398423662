import { Component } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import Loading from "../components/LoadingWidget";
import { fetchGetJSON, regGet } from "../api-requests/apiCallHelper";
import { Constants } from "../api-requests/apiLinkConstants";
import Logo from "./img/SKYVIEWSUITESDark.png";
interface Props {}

interface State {
    firstPayment: boolean;
    status: string;
    loading: boolean;
    //Success message
    email: string;
    tenantName: string;
    suiteName: string;
    invoiceId: number;
    amount: number;
    bookingId: number;
}

class paymentConfirm extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstPayment: false,
            bookingId: -1,
            status: "",
            loading: false,
            email: "",
            tenantName: "",
            suiteName: "",
            invoiceId: -1,
            amount: 1
        }
    }

    async componentDidMount() {
        const url = window.location.search.slice(1);
        this.setState({loading: true});

        const paymentResp = await regGet(`${Constants.API_PATH_PAYMENT_CONFIRM}?${url}`);
        // console.log(paymentResp)

        if (paymentResp.status === "Success") {
            this.setState({
                loading: false,
                status: "Success",
                bookingId: paymentResp.data?.bookingId,
                firstPayment: paymentResp.data?.firstPayment,
                invoiceId: paymentResp.data?.invoiceId,
                tenantName: paymentResp.data?.tenant_name,
                suiteName: paymentResp.data?.suite_name,
                amount: paymentResp.data?.invoice_amount
            });

            let emails = "";

            if(Array.isArray(paymentResp.data?.email)) {
                for (let i = 0; i < paymentResp.data.email.length; i++) {
                    emails += ` ${paymentResp.data.email[i]}`;
                    if ((i + 1) !== paymentResp.data.email.length) {
                        emails += " and";
                    }
                }
            } else {
                emails += ` ${paymentResp.data.email}`;
            }

            this.setState({email: emails});
        } else {
            //Grab query URL and assign invoiceID:
            const urlString = window.location.href.split('/')[3]
            const trnOrderNumberPosition = urlString.indexOf(`trnOrderNumber`);
            const trnOrderNumberLastPosition = urlString.indexOf('&', trnOrderNumberPosition + 1);

            this.setState({
                status: paymentResp.data ? paymentResp.data : "No error info",
                loading: false,
                invoiceId: +urlString.substring(trnOrderNumberPosition + 15, trnOrderNumberLastPosition)
            });
        }
    }

    render () {
        let content = <></>;
        if (this.state.loading) {
            content = (
                <div className="flex justify-center m-auto py-0">
                    <div className="flex flex-col w-2/3 py-10 px-5">
                        <Loading size="3x-large" type="big-spiral"/>
                        <h1 className="text-center mt-12 text-xl">
                            We are currently processing your payment. Thank you for your patience!
                        </h1>
                    </div>
                </div>
            );
        } else if (this.state.status === "Success") {
            const invAmount = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(this.state.amount);
            if (this.state.firstPayment) {
                content = (
                    <div className="flex justify-center">
                        <div className="flex flex-col w-1/2 py-10 px-5 rounded-lg shadow-xl">
                            <div className="flex justify-center">
                                <FiCheckCircle className="text-green-500 text-8xl font-bold items-center"/>
                            </div>
                            <h1 className="font-bold text-4xl py-5 text-gray-800 text-center">Thank you {this.state.tenantName},
                                <br />
                                for staying with
                                <span className="text-green-500 break-after"> Sky View Suites</span>!
                            </h1>
                            <div className="flex-col">
                                <h2 className="text-xl text-center text-gray-800 mb-7 font-bold">
                                    Your booking #{this.state.bookingId} has been confirmed and 
                                    <br />
                                    your payment of {invAmount} for invoice #{this.state.invoiceId} was successful!
                                </h2>
                                <div className="flex">
                                    <h3 className="text-l text-gray-800 mb-10 text-center"> A booking summary and a receipt for your first payment has been sent to 
                                        <span className="font-bold">{this.state.email}</span>.
                                        We are excited to be sharing this experience with you.
                                        <br />
                                        We hope you enjoy your stay at {this.state.suiteName}!
                                    </h3>
                                </div>
                                <h3 className="text-m text-gray-800 mb-1 text-center">
                                    If you have any questions regarding your stay,
                                    <br />
                                    please contact&nbsp;
                                    <a href="mailto:help@skyviewsuites.com" className="text-green-500 underline">help@skyviewsuites.com</a>
                                    &nbsp;for support.
                                </h3>
                            </div>
                        </div>
                    </div>
                );
            } else {
                content = (
                    <div className="flex justify-center">
                        <div className="flex flex-col w-7/12 py-10 px-0 rounded-lg shadow-xl">
                            <div className="flex justify-center">
                                <FiCheckCircle className="text-green-500 text-8xl font-bold items-center"/>
                            </div>
                            <h1 className="font-bold text-4xl py-5 text-gray-800 text-center">
                                Thank you for your payment, 
                                <br />
                                {this.state.tenantName}!
                            </h1>
                            <div className="flex-col">
                                <h2 className="text-xl text-center text-gray-800 mb-7 font-bold">
                                    Your payment of {invAmount} for invoice #{this.state.invoiceId} was successful!
                                </h2>
                                <div className="flex justify-center">
                                    <h3 className="text-l text-gray-800 mb-7 text-center"> A receipt for this payment has been sent to 
                                        <span className="font-bold">{this.state.email}</span>.
                                        <br />
                                        We hope you are enjoying your stay at {this.state.suiteName}!
                                    </h3>
                                </div>
                                <h3 className="text-m text-gray-800 mb-5 text-center">
                                    If you have any questions regarding your stay,
                                    <br />
                                    please contact&nbsp;
                                    <a href="mailto:help@skyviewsuites.com" className="text-green-500 underline">help@skyviewsuites.com</a>
                                    &nbsp;for support.
                                </h3>
                                <h3 className="text-m text-gray-800 mb-1 text-center">
                                    If you have any questions regarding your payment,
                                    <br />
                                    please contact&nbsp;
                                    <a href="mailto:payments@skyviewsuites.com" className="text-green-500 underline">payments@skyviewsuites.com</a>
                                    &nbsp;for support.
                                </h3>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            content = (
                <div className="flex justify-center my-auto">
                    <div className="flex flex-col w-1/2 py-10 px-3">
                        <div className="flex justify-center">
                            <BsExclamationCircle className="text-red-500 text-8xl font-bold items-center"/>
                        </div>
                        <h1 className="text-center mt-12 text-2xl">
                            Oops! Something went wrong with the payment. Invoice ID: {this.state.invoiceId}
                        </h1>
                        <h2 className="text-center mt-7 text-gray-800 text-lg">
                            Please contact&nbsp;
                            <a href="mailto:payments@skyviewsuites.com" className="text-green-500 underline">payments@skyviewsuites.com</a>
                            &nbsp;for support.
                        </h2>
                    </div>
                </div>
            );
        }
        return (
            <div className="flex flex-col h-screen">
                <div className="flex justify-center">
                    <div className="flex w-1/3 my-8">
                        <img src={Logo} alt="Sky View Suites Logo"/>
                    </div>
                </div>
                {content}
            </div>
        );
    }
}
export default paymentConfirm;