import { Component } from "react";
import { AiOutlinePaperClip, AiOutlineFileText } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";

import { IoIosArrowBack } from "react-icons/io";
import { BsFillPersonPlusFill } from "react-icons/bs";
import ToggleSwitch from "../ToggleSwitch";
import { TiDelete } from "react-icons/ti";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
// import { truncate } from "fs";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
};

type ModalState = {
  title: string;
  onoff: boolean;

  fname: string;
  lname: string;
  email: string;
  phone: string;
};

type postBody = {
  first_name: string,
  last_name: string,
  phone_number: string,
  email?: string
}

class NewTenant extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      
      fname: "",
      lname: "",
      email: "",
      phone: '0',
    };

    this.props.ErrorNote.bind(this);
  }

  handleClick = async () => {
    console.log("Hi you are creating a new tenant")
    let body: postBody = {
      first_name: this.state.fname.trim(),
      last_name: this.state.lname.trim(),
      phone_number: (this.state.phone.trim() && this.state.phone.trim() !== '0') ? this.state.phone.trim() : null
    }

    if(this.state.email && this.state.email.trim() !== "") {
      body.email = this.state.email.trim();
    }

    console.log(body);
    if (this.state.fname.trim() === "" || this.state.lname.trim() === "") {
      alert("Missing Info")
      return;
    }
    let res1 = await fetchRequestJSON("POST", Constants.API_PATH_TENANT_CREATE, body);
    console.log(res1);
    if (res1.status == "Success") {
      this.props.handleModalToggle(this.props.onoff);
      let updateList = store.getState().tenantReducer.updateList;
      updateList();
      let updateEdit = store.getState().tenantReducer.updateEdit;
      updateEdit();
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }

      this.setState({
        fname: "",
        lname: "",
        email: "",
        phone: '0',
      })
    }
  }; 

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4 overflow-y-scroll scroll-hide my-10 border-gray-600 max-w-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <BsFillPersonPlusFill className="text-gray-600 text-3xl mb-3 fixed" />

          <span className="text-xl font-bold text-gray-800 text-left mt-10">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form className="w-full">
            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">First Name:</span>
                <input
                  id="fname"
                  onChange={this.handleInputChange}
                  value={this.state.fname}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 bordxer-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Last Name:</span>
                <input
                  id="lname"
                  onChange={this.handleInputChange}
                  value={this.state.lname}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 bordxer-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Email:</span>
                <input
                  id="email"
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Phone</span>
                <input
                  id="phone"
                  onChange={this.handleInputChange}
                  value={this.state.phone}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <div className="flex flex-row justify-start w-full mt-6">
              <button
                type="button"
                onClick={() => this.handleClick()}
                className="transition  duration-200 w-full hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Add Tenant
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewTenant);

export function ContactCard(props: any) {
  var id = props.contactType.toLowerCase();
  let name;
  let email;
  let phone;

  if (props.contactType === "Admin") {
    name = props.data.admin_contact;
    email = props.data.admin_contact_email;
    phone = props.data.admin_contact_phone;
  }
  if (props.contactType === "Finance") {
    name = props.data.finance_contact;
    email = props.data.finance_contact_email;
    phone = props.data.finance_contact_phone;
  }
  if (props.contactType === "Cleaning") {
    name = props.data.cleaning_contact;
    email = props.data.cleaning_contact_email;
    phone = props.data.cleaning_contact_phone;
  }

  return (
    <div className="flex flex-col px-4 py-3 h-auto border-2 rounded-sm border-solid border-gray-100 ">
      <div className="flex flex-row w-full  items-center">
        <span className="font-medium text-gray-700 text-md pb-1 ">
          {props.contactType} -
          <input
            id={id + "_contact"}
            type="text"
            className=" transition-all duration-200 font-medium text-gray border-0 p-0  pl-1 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
            value={name}
            onChange={props.stateChange}
            placeholder="Click To Edit Name"
          />
        </span>
      </div>

      <span className="font-regular text-gray-400 text-2xs pb-4">
        Corporate - {props.data.corporation}
      </span>
      <input
        id={id + "_contact_email"}
        type="text"
        className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
        value={email}
        onChange={props.stateChange}
        placeholder="Click To Edit Email"
      />
      <span className="flex flex-row items-center font-regular text-gray-400 text-xs ">
        <i className="text-gray-500 mr-1 ">
          <FaPhoneAlt />
        </i>
        <input
          id={id + "_contact_phone"}
          type="text"
          className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
          value={phone}
          onChange={props.stateChange}
          placeholder="Click To Edit Phone"
        />
      </span>
    </div>
  );
}
