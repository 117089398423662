import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch } from "react-router-dom";
import PaymentsQuickView from "./PaymentsQuickView";
import InvoiceQuickView from "./InvoiceQuickView";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import InvoiceList from "../../Invoices/InvoiceList";
import DashBar from "../DashBar";


type Props = {
  userData: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
};

class DashInvoices extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = async (state?: any) => {
    if (this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-auto lg:rounded-tl-lg py-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}
        <InvoiceQuickView
          handleModalToggle={this.handleInvoiceView}
          onoff={this.state.toggleInvoice}
          info={undefined}
        />
        <PaymentsQuickView
          handleModalToggle={this.handleQuickView}
          onoff={this.state.toggleQuickView}
          InvoiceQuickView={this.handleInvoiceView}
        />

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1  w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""}/>

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Finance Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          {/* Heading Info Area  */}

          <div className="px-2 my-8 ">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between w-full mb-2  items-end">
                <span className=" pl-1 text-gray-700 font-medium text-xl">
                  Invoices
                </span>
              </div>
            </div>
            <InvoiceList
              toggleInvoice={this.handleInvoiceView}
              showOnlyUnpaid={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DashInvoices;

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}
