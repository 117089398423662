import React, { Component, Suspense } from "react";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import Label from "../LabelWrap";
import StatsBar from "../StatsBar";
import CheckInTable from "../CheckIns/CheckInTables/CheckInTable";
import CheckOutTable from "../CheckIns/CheckInTables/CheckOutTable";

const CheckOutModal = React.lazy(() => import("../Bookings/CheckOut/CheckOutModal"));
// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {};

type CheckInListState = {
  toggleCheckOut: boolean;
  // booking: any;
  booking_id: number;
  check_out: Date;
  check_out_notes: string;
  building_id: number;
  building: string;
  unit_id: number;

  //keys info
  fob_received: number;
  key_received: number;
  mailkey_received: number;
  park_fob_recieved: number;
  suite: string;
  suite_number: number;
  key_count: number;
  fob_count: number;
  mailkey_count: number;
  park_fob_count: number;
  
  //tenant info
  tenant_id: number;
  tenant_name: string;
  tenant_email: string;
  tenant_phone: string;
};

class CheckInList extends Component<Props, CheckInListState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      toggleCheckOut: false,
      // booking: null,
      booking_id: -1,
      check_out: null,
      check_out_notes: "",
      unit_id: -1,

      //keys info
      key_count: 0,
      fob_count: 0,
      mailkey_count: 0,
      park_fob_count: 0,
      fob_received: 0,
      key_received: 0,
      mailkey_received: 0,
      park_fob_recieved: 0,
      suite: "",
      suite_number: 0,
      building_id: -1,
      building: "",
      
      //tenant info
      tenant_id: -1,
      tenant_name: "",
      tenant_email: "",
      tenant_phone: ""
    };
  }

  handleToggleChange = (id: any, state: boolean, bookingData?: any) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    })();
    this.setState(stateObject);

    console.log(this.state.toggleCheckOut);
  };

  getBookingData = async (bookingIncoming: any) => {
    await this.setState({
      booking_id: bookingIncoming.id,
      check_out: new Date (bookingIncoming.check_out),
      check_out_notes: bookingIncoming.check_out_notes,
      key_count: bookingIncoming.keys,
      fob_count: bookingIncoming.fobs,
      mailkey_count: bookingIncoming.mail_keys,
      park_fob_count: bookingIncoming.parking_fobs,
      unit_id: bookingIncoming.unit_id,
      tenant_id: bookingIncoming.tenant_id
    });

    //get unit info
    const getUnitData = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${this.state.unit_id}`);
    if (getUnitData.status === "Success") {
      this.setState({
        suite: getUnitData.data?.suite_name,
        suite_number: getUnitData.data?.suite_number,
        building_id: getUnitData.data?.building_id
      });

      //get building name
      let buildingData = await fetchGetJSON(`${Constants.API_PATH_BUILDING_GET}${this.state.building_id}`);
      if (buildingData.status === "Success") {
        this.setState({building: buildingData.data?.building_name});
      }
    }
    //get tenant info
    const tenant = await fetchGetJSON(`${Constants.API_PATH_TENANT_GET}${this.state.tenant_id}`);
    if (tenant.status === "Success") {
      this.setState({
        tenant_name: `${tenant.data?.first_name} ${tenant.data?.last_name}`,
        tenant_email: tenant.data?.email,
        tenant_phone: tenant.data?.phone_number
      });
    }
    console.log(this.state);
  }

  render() {
    let checkOutModal = <></>;
    if (this.state.booking_id && this.state.booking_id !== -1) {
      checkOutModal =
        <Suspense fallback={<div>Loading...</div>}>
          <CheckOutModal
            booking_data={this.state}
            handleModalToggle={() => this.handleToggleChange("toggleCheckOut", this.state.toggleCheckOut, this.state)}
            onoff={this.state.toggleCheckOut}
            title="Check Out"
          />
      </Suspense>;
    }

    return (
      <div className=" font-display   bg-white lg:rounded-tl-lg pt-12 pb-20 lg:pt-0 lg:pb-0">
        {checkOutModal}
        <BreadCrumb
          rootPage="Upcoming Check Ins & Out"
          sideButton=""
          subPage=""
          buttonAction={""}
        />
        <div className="fade-in px-2 lg:px-14 ">
          <StatsBar StatsType="CheckIns" />

          {/* Heading Info Area  */}
          <div className="flex flex-row mb-0">
            {/* Left Side  */}
            <div className="flex flex-row justify-between w-full text-center items-center lg:items-start lg:flex-col lg:w-1/2">
              <h1 className="lg:hidden font-sofia text-gray-500 text-3xl font-bold ">
                Check Ins
              </h1>
            </div>
          </div>

          <TabList warning={false}>
            <Label label="Check In">
              <CheckInTable />
            </Label>

            <Label label="Check Out">
              <CheckOutTable 
                handleCheckoutModal={this.handleToggleChange}
                getBookingData={this.getBookingData}
              />
            </Label>
          </TabList>
        </div>
      </div>
    );
  }
}

export default CheckInList;
