import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import CancelModal from "../Bookings/CancelModal";
import EditOptionPanel from "../EditOptionPanel";
import EditContact from "./EditContact";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
    ErrorNote: any
};

type State = {
    contact_fname: string,
    contact_lname: string,
    contact_email: string,
    contact_phone: string,
    contact_id: number,
    corp_name: string,
    admin: boolean,
    finance: boolean,
    cleaning: boolean,
    deleteToggle: boolean,
    editToggle: boolean,
};

class CorpContactProfile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    
        this.state = {
          contact_fname: "First Name",
          contact_lname: "Last Name",
          contact_email: "edna.mode@badass.com",
          contact_phone: '0',
          contact_id: -1,
          corp_name: "Default Corp",
          admin: false,
          finance: false,
          cleaning: false,
          deleteToggle: false,
          editToggle: false
        };
    };

    handleEditBarToggle = () => {
        if (this.state.editToggle) {
            this.setState({ editToggle: false });
        } else {
            this.setState({ editToggle: true });
        }
    };

    handleInputChange = (event: any) => {
        event.preventDefault();
        let stateObject = function (this: typeof event) {
          let returnObj: any = {};
          returnObj[this.target.id] = this.target.value;
          return returnObj;
        }.bind(event)();
        this.setState(stateObject);
    };

    handleDeleteToggle = () => {
        if (this.state.deleteToggle) {
            this.setState({ deleteToggle: false });
        } else {
            this.setState({ deleteToggle: true });
        }
    }

    handleToggleChange = (id: any, state: boolean) => {
        let stateObject = function (this: typeof id) {
          let returnObj: any = {};
          if (state === true) {
            returnObj[id] = false;
            return returnObj;
          } else {
            returnObj[id] = true;
            return returnObj;
          }
        }();
        this.setState(stateObject);
      };

    componentDidMount = async () => {
        let NotificationHandler = this.props.ErrorNote;
        const corpContactId = new URLSearchParams(window.location.search).get("contact_id");
        console.log(corpContactId);

        const contact = await fetchGetJSON(
          `${Constants.API_PATH_CORP_CONTACT_GET_CORPORATE_CONTACT}${corpContactId}`
        );
        console.log(contact);
        
        //add notifhandler for errors for both calls
        if (contact.status === "Success" && contact.data) {
            this.setState({
                contact_fname: contact.data.first_name,
                contact_lname: contact.data.last_name,
                contact_id: contact.data.id,
                contact_email: contact.data.email,
                contact_phone: contact.data.phone_number,
                admin: contact.data.admin,
                finance: contact.data.finance,
                cleaning: contact.data.cleaning
            });

            const corpInfo = await fetchGetJSON(`${Constants.API_PATH_CORP_GET}${contact.data.corporate_id}`);
            if (corpInfo.status === "Success" && corpInfo.data) {
                this.setState({corp_name: corpInfo.data.corporate_name});
            }
        } else {
            NotificationHandler("Error", "This corporate contact does not exist", "");
        }
    };

    render() {
        let bgFocus;
        if (this.state.editToggle === true) {
            bgFocus = (
              <div
                onClick={this.handleEditBarToggle}
                className=" fade-bg w-full h-screen bg-black z-40  fixed"
              ></div>
            );
        }

        let corpRoles = ""
        if (this.state.admin) {
            corpRoles += "Admin";
        }
        if (this.state.finance) {
            corpRoles += corpRoles ? " | Finance" : "Finance";
        }
        if (this.state.cleaning) {
            corpRoles += corpRoles ? " | Cleaning" : "Cleaning";
        }

        return (
            <div className=" bg-white font-display lg:rounded-tl-lg">
                {bgFocus}
                <EditContact
                    toggle={this.state.editToggle}
                    handleEditBarToggle={this.handleEditBarToggle}
                    corpContactData={this.state}
                    handleInputChange={this.handleInputChange}
                    handleToggleChange={this.handleToggleChange}
                />
                <BreadCrumb
                    rootPage="Contacts > Corporate Contacts"
                    subPage={this.state.corp_name}
                    sideButton=""
                    buttonAction={""}
                />
                <CancelModal
                    handleModalToggle={() =>
                        this.handleDeleteToggle()
                    }
                    onoff={this.state.deleteToggle}
                    title={"Delete Corporate Contact"}
                />
                <div className="px-4 lg:px-10 max-w-screen-sm mx-auto border rounded-lg py-10 bg-white">
                    <div className="flex flex-row mb-0">
                        {/* Left side content */}
                        <div className="flex flex-col w-3/4">
                            <h1 className="text-2xl px-2 pt-2 text-gray-700 font-black font-display flex flex-row">
                                {this.state.contact_fname} {this.state.contact_lname}
                            </h1>
                            <h3 className="px-2 mt-1 mb-1 text-sm font-extralight text-gray-400">
                                {corpRoles}, {this.state.corp_name}
                            </h3>

                            <hr/>
                            <br/>

                            <h3 className="px-2 mt-1 mb-1 font-light text-med text-gray-600">
                                Corporate Contact ID: {this.state.contact_id}
                            </h3>
                            <h3 className="px-2 mt-1 mb-1 font-light text-med text-gray-600">
                                Phone number: {this.state.contact_phone}
                            </h3>
                            <h3 className="px-2 mt-1 mb-1 font-light text-med text-gray-600">
                                Email: {this.state.contact_email}
                            </h3>
                        </div>

                        <div className="flex flex-col w-1/2">
                            <EditOptionPanel
                                handleEditBarToggle={this.handleEditBarToggle}
                                parentData={this.state}
                                panelType="Corporate Contact"
                                cancelModalToggle = {this.handleDeleteToggle}
                                handleState={this.handleToggleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withHooksHOC(CorpContactProfile);
