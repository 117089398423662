import { Component, useContext,  useEffect,  useReducer, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { useNotification } from "./Notifications/NotificationProvider";
import { RiAlertFill } from "react-icons/ri";
import { IoMdAlert } from "react-icons/io";
import { GoCheck } from "react-icons/go";
import { BsInfoCircleFill } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai"

export function QuickAction(props: any) {
  
 
  const [hide, setHide] = useState(false);
  const [exit, setExit] = useState(" translate-y-0 ");

 

  const hideOptions = () => {
    
    if(hide){
      setExit(" translate-y-20 ")
      setTimeout(() => {
        setHide(false);
      }, 800);
      
      
    }
    else{
      //temporary fix below, not sure if we need to add an actual amount? This is due to updating npm package
      clearTimeout(-1);
      setExit(" translate-y-0 ")
      setHide(true);
      
    }
    
  
  };

  let optionStyles = " "
  let pointerDir = " "
  if (exit === " translate-y-20 ") {
    pointerDir = " -rotate-180 "
  }
  else{
    pointerDir = " "
  }
  
 
  

  
  return (
    
    
    <div  className="flex flex-row fixed bottom-0 right-0 z-40 transform -translate-y-12 lg:translate-y-0  ">
      {hide ? <MyComponent ChangeUserType={props.ChangeUserType} CurrentUserType={props.CurrentUserType} optionStyles={exit} /> : null}
    
      <button
        onClick={hideOptions}
        className={ pointerDir +  " transition-all duration-300 appear text-white lg:text-base text-sm h-10 w-10  flex flex-row items-center align-center transform justify-center  rounded-full pb-1  z-40 mr-2 lg:mr-10 mb-6 "}
      >
        <AiOutlineCaretDown className="text-3xl pt-1 hover:text-gray-600 text-gray-800" />
      </button>


    </div>
  );
}

export default QuickAction;




export function MyComponent(props : any) {

  const dispatch = useNotification();

  

  const handleSuccess = () => {
    dispatch({
      type: "SUCCESS",
      title: "Entry Successful",
      message: "Nice its works!",
    });
  };

  const handleError = () => {
    dispatch({
      type: "ERROR",
      title: "An Error Has Occured",
      message: "Please Double Check All The Fields",
    });
  };

  const handleInfo = () => {
    dispatch({
      type: "INFO",
      title: "Did You Know?",
      message: "Hey did you know you could press back!",
    });
  };

  const handleWarn = () => {
    dispatch({
      type: "WARNING",
      title: "UH Oh ... Heads Up",
      message: "There could be issues with these dates",
    });
  };


  // useEffect(() => {
  //   console.log('effect');
  // }, );

  // useEffect(() => {
    
  //   return () => {
      
  //     console.log('cleanup');
  //   };
  // }, []);

  return(
    <div className={ "  transition-all duration-200 flex flex-row transform " + props.optionStyles }>
    <button
      onClick={handleSuccess}
      className=" appear text-white lg:text-base text-sm h-10 w-10 hover:bg-green-400 bg-green-500 flex flex-row items-center align-center transform justify-center shadow-xl rounded-full pb-1  z-40   mr-4 mb-6"
    >
      <GoCheck className="text-2xl text-white" />
    </button>

    <button
      onClick={handleError}
      className=" appear text-white lg:text-base text-sm h-10 w-10 hover:bg-red-400 bg-red-500 flex flex-row items-center align-center transform justify-center shadow-xl rounded-full pb-1  z-40 mr-4 mb-6"
    >
      <FiAlertTriangle className="text-2xl text-white" />
    </button>

    <button
      onClick={handleInfo}
      className=" appear text-white lg:text-base text-sm h-10 w-10 hover:bg-blue-400 bg-blue-500 flex flex-row items-center align-center transform justify-center shadow-xl rounded-full pb-1  z-40 mr-4 mb-6"
    >
      <BsInfoCircleFill className="text-2xl text-white" />
    </button>

    <button
      onClick={handleWarn}
      className=" appear text-white lg:text-base text-sm h-10 w-10 hover:bg-yellow-400 bg-yellow-500 flex flex-row items-center align-center transform justify-center shadow-xl rounded-full pb-1  z-40 mr-4 mb-6"
    >
      <IoMdAlert className="text-2xl text-white" />
    </button>

    <button
      onClick={props.ChangeUserType}
      className=" appear text-white lg:text-base text-sm bg-indigo-500 flex flex-row items-center align-center  justify-center w-auto shadow-xl rounded-full  pt-1 pb-2  px-6 mr-4 mb-6"
    >
      Current UserType :{" " + props.CurrentUserType}
    </button>

    </div>
    
  )
  
}