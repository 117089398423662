import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Waterclub/Waterclub Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Waterclub/Waterclub Parking.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Waterclub/Waterclub Parking 2.png";
import ThirdStreetView from "../../../client-facing/img/ParkingPhotos/Waterclub/Waterclub View.png";

function Waterclub() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-2/3">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            Parking garage for Waterclub condos is located in the rear of the
            building and can be accessed via Harbour St. in the north or Queens
            Quay in the south. If entering via Harbour street, make the turn off
            Harbour just before hitting York St. and the ramp down to the
            parking garage will be immediately on your left.
          </p>
          <p className="mt-3">
            Proceed down the ramp and use your remote to open the door. You will
            need to aim the remote at the little black target on the wall in
            order to open the garage door. Once inside, proceed down to your
            designated parking space. Make sure that both the letter and the
            number match the spot you are assigned, and the spot you park in.
          </p>
          <p className="mt-3 mb-5 md:mb-2">
            If accessing via Queens Quay, the entrance to the parking area is
            just beside the Pizza Pizza store. If taking this route, enter
            through the overpass and turn right at the stop sign. Follow the
            laneway past the roundabout, and the ramp down to the parking garage
            will be on your right just before hitting Harbour St.
          </p>
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className=""
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-2/5">
          <img
            src={ThirdStreetView}
            alt="Google Maps Street View"
            className="h-1/4 w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point form summary:
            <p className="my-3">
              <strong>
                Entrance Accessible via laneway behind building, off Harbour St
                or Queens Quay
              </strong>
            </p>
            <ul className="list-disc ml-5">
              <li className="mt-1">
                Access laneway via Harbour Street just west of York, or Queens
                Quay via overpass beside Pizza store.
              </li>
              <li className="mt-1">
                If coming in via Harbour, ramp down to resident parking will be
                immediately on left. If coming in via Queens Quay, turn right at
                first stop sign and proceed past roundabout. Ramp down to
                parking will be on the right.
              </li>
              <li className="mt-1">
                At bottom of ramp, aim parking remote at black receiver on the
                wall, then push the button to open the resident garage door.
              </li>
              <li className="mt-1">
                Proceed to designated parking space, making sure that both the
                letter and the number match the spot you are assigned, and the
                spot you park in.
              </li>
            </ul>
          </p>
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-1/2 mt-2 pt-5"
          />
        </div>
      </div>
    </div>
  );
}

export default Waterclub;
