import { Component } from "react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";

type Props = {
  data: any;
};

interface KeysInfoState {
  num_outstanding_invoices: number;
  bottom_bar_toggle: boolean;
}

class KeysInfo extends Component<any, KeysInfoState> {
  constructor(props: any) {
    super(props);
    console.log(this.props)
    this.state = {
      num_outstanding_invoices: 0,
      bottom_bar_toggle: true,
    };
  }

  handleIconToggle = () => {
    this.setState({ bottom_bar_toggle: !this.state.bottom_bar_toggle })
  };

  componentDidMount = async () => {
    const bookingId = Number(new URLSearchParams(window.location.search).get("id"));
    let allInvoices = await fetchGetJSON(`${Constants.API_PATH_INVOICE_BY_BOOKING}${bookingId}`);

    if (allInvoices.status === "Success") {
      for (let i = 0; i < allInvoices.data.length; i++) {
        let this_invoice = allInvoices.data[i];
        if (this_invoice.booking_id === bookingId && this_invoice.paid === false) {
          this.setState({ num_outstanding_invoices: this.state.num_outstanding_invoices + 1 })
        }
      }
    }
    console.log(this.state.num_outstanding_invoices);
  }

  render() {
    let hideInfo = (this.state.bottom_bar_toggle) ? "fade-in" : "opacity-0 ";
    let expandBar = (this.state.bottom_bar_toggle) ? "h-24 upperShadow bg-white" : "h-24 upperShadow bg-white";

    return (
      <div
        key={this.props.data}
        className={`${expandBar} hidden transition-all duration-200 bottom-0 fixed z-30  font-display 
          pl-12 border-solid  w-full lg:flex flex-row items-center `}
      >
        {/* To use Router Link to change page */}
        <div className={" max-w-screen-lg w-full flex mx-auto flex-row items-center  "}>
          <div className={hideInfo + " flex flex-row w-auto  items-center justify-between "}>
            <div className="flex flex-col items-center mr-6 ">
              <span className="fade-in text-blue-500 text-xl font-sofia font-extrabold">
                {this.props.data.fob_count === -1 ? 0 : this.props.data.fob_count}
              </span>
              <span className="font-sofia text-gray-700 text-sm">Fobs Given</span>
            </div>

            <div className="flex flex-col items-center mr-6 ">
              <span className="text-blue-500 text-xl font-sofia font-extrabold">
                {this.props.data.key_count === -1 ? 0 : this.props.data.key_count}
              </span>
              <span className="font-sofia text-gray-700 text-sm">Keys Given</span>
            </div>

            <div className="flex flex-col items-center mr-6 ">
              <span className="text-blue-500 text-xl font-sofia font-extrabold">
                {this.props.data.mailkey_count === -1 ? 0 : this.props.data.mailkey_count}
              </span>
              <span className="font-sofia text-gray-700 text-sm">Mail Keys</span>
            </div>

            <div className="flex flex-col items-center mr-6 ">
              <span className="text-blue-500 text-xl font-sofia font-extrabold">
                {this.props.data.park_fob_count === -1 ? 0 : this.props.data.park_fob_count}
              </span>
              <span className="font-sofia text-gray-700 text-sm">Parking Fobs</span>
            </div>

            <div className="flex flex-col items-center mr-12 ">
              <span className="text-yellow-500 text-xl font-sofia font-extrabold">
                {this.props.data.num_outstanding_invoices}
              </span>
              <span className="font-sofia text-gray-700 text-sm">Outstanding Invoices</span>
            </div>
          </div>

          <div className={`${hideInfo} flex flex-row self-end`}>
            <Tooltip content={<UnitBubble suite_number={""} blackout={true} heat={true}/>} delay="" direction="">
              <div className=" cursor-pointer  mr-2 bg-gray-700 text-white font-medium text-sm rounded-full py-1
                w-auto flex flex-col items-center text-center px-6">
                {" "}
                {this.props.data.unit_name}{" "}
              </div>
            </Tooltip>

            <div className="flex flex-col items-center ml-12 ">
              <span className="text-gray-400 text-xs leading-tight font-regular ">
                {this.props.data.pricing_type} Rate:
              </span>
              <span className=" text-gray-700 text-xs font-medium">
                {formatAmountForDisplay(this.props.data.monthly_rate, "CAD", false)}
              </span>
            </div>
          </div>

          <button
            onClick={this.handleIconToggle}
            className=" transition duration-300 text-3xl ml-10 text-gray-500"
          >
            {(this.state.bottom_bar_toggle) ? <MdExpandMore className="fade-in" />
              : <MdExpandLess className="fade-in" />}
          </button>
        </div>
      </div>
    );
  }
}

export default KeysInfo;
