import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DashBar from "../DashBar";


type Props = {
  userData: any;
};

type State = {};

class CleaningForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-screen lg:rounded-tl-lg py-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-14  ">
          <div className=" flex flex-row justify-between font-circular pr-2  w-full pt-16 lg:pt-6 pb-4">
          <DashBar placeholder={""}/>

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Cleaning Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          {/* Heading Info Area  */}

          <div className="px-2 my-8 ">
            <div className=" my-4 grid grid-cols-1 bg-white max-w-3xl px-6 py-8 shadow-sm rounded-lg border border-gray-400 mx-auto">
              <span className="text-gray-300">{}</span>
              <span className="text-gray-300 mx-auto">
                Additional Content / Functions can be added in column
              </span>
            </div>

            <div className=" my-4 grid grid-cols-1 bg-white max-w-3xl px-6 py-8 shadow-sm rounded-lg border border-gray-400 mx-auto">
              <div className="grid grid-cols-3 gap-3 w-full">
                <button className="  whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center">
                  <span className="font-normal text-sm ">
                    Update Deloitte RV
                  </span>
                </button>

                <button className="  whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center">
                  <span className="font-normal text-sm ">
                    One Time Update Line Type
                  </span>
                </button>

                <button className="  whitespace-nowrap shadow-md transition duration-100 w-auto px-5 bg-gray-500 hover:bg-green-400 text-white font-semibold py-2 rounded  flex flex-row justify-center align-center">
                  <span className=" font-normal text-sm ">
                    Send Cleaning Notification
                  </span>
                </button>
              </div>

              <form className="mt-6 py-3 border-b w-full border-gray-400">
                <input type="file" />
              </form>
            </div>

            <div className=" my-4 grid grid-cols-1 bg-white max-w-3xl px-6 py-8 shadow-sm rounded-lg border border-gray-400 mx-auto">
              <span className="text-gray-300 mx-auto">
                Additional Content / Functions can be added in column
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CleaningForm;

export function InvoiceList(props: any) {
  let currentDate = props.todaysDate;
  let testDueDate = new Date(2021, 7, 8, 4, 20);
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;
  let OverDueStyle = "text-gray-700";
  let OverDueStyle2 = "text-gray-700";
  let OverDue = <></>;

  for (var i = 0; i < length; i++) {
    if (testDueDate > currentDate) {
      OverDueStyle = "text-white text-sm  w-72";
      OverDueStyle2 = "text-red-400 mr-10";
      OverDue = (
        <span className="bg-red-500 py-1 px-4 rounded-full">Overdue</span>
      );
    }

    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    Payments = (
      <tr
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="py-4 pl-5 text-purple-400 font-thin ">{i}</td>
        <td className={" py-3 font-thin  "}>Riley Densmore</td>
        <td className="py-3 text-gray-400 font-thin ">
          {formatFullDate(testDueDate)}
        </td>
        <td className="py-3  font-thin ">Adelaide C</td>
        <td className=" text-green-600  ">Turn Over</td>
        <td className=" "></td>
        <td className=" "></td>
        <td className=" text-right ">
          <button
            onClick={props.viewInvoice}
            className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
          >
            View Form
          </button>
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}

function CleanDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace("/Archive-Forms" + currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}
