import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import { MdPlaylistAdd } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import Label from "../LabelWrap";
import InvoiceList from "../Invoices/InvoiceList";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import store from "../../redux/store";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { idText } from "typescript";
import ExpensesList from "./ExpensesList";
import TransactionList from './TransactionList';
import NewExpense from "./NewExpense";
import NewTransaction from "./NewTransaction";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import Modal from "../Modal";
import AppStage from "../../utils/Types";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = { ErrorNote: any; };

type State = {
  booking_id: string;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  toggleInvoice: boolean;
  toggleConsolidated: boolean;
  editToggle: boolean;
  info: any;
  csv_open: boolean;
  newExpense: boolean;
  newTransaction: boolean;
  selected_button: string;
  selectedTransactionId: number;
  deleteModalToggle: boolean;
  deletedTransactionId: number;
  csv_url: string;
};

class Finance extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: "",
      check_in: "",
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      toggleInvoice: false,
      editToggle: false,
      info: undefined,
      csv_open: false,
      toggleConsolidated: false,
      newExpense: false,
      newTransaction: false,
      selected_button: "expenses",
      selectedTransactionId: -1,
      deletedTransactionId: -1,
      deleteModalToggle: false,
      csv_url: ""
    };
  }

  handleButtonSelect = (name: string) => {
    this.setState({ selected_button: name })
  }

  handleNewExpense = async () => {
    this.setState({ newExpense: !this.state.newExpense });
  }

  handleNewTransaction = async () => {
    this.setState({ newTransaction: !this.state.newTransaction });
  }

  toggleCSVOpen = async () => {
    this.setState({ csv_open: !this.state.csv_open });
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleCancelModal = (transactionId: any) => {
    this.setState({ selectedTransactionId: transactionId })
    this.setState({ deleteModalToggle: !this.state.deleteModalToggle })
  }


  handleConfirmModal = async () => {
    let NotificationHandler = this.props.ErrorNote;
    let res = await fetchRequestJSON("DELETE", Constants.API_PATH_TRANSACTION_DELETE + this.state.selectedTransactionId, {})
    this.setState({ deleteModalToggle: !this.state.deleteModalToggle })
    if (res.status == "Success") {
      // this.updateList();
      NotificationHandler("Success", 'Successfully Deleted Transaction')
      this.setState({ deletedTransactionId: this.state.selectedTransactionId })

    }
    else {
      NotificationHandler("Error", 'Error Deleting Transaction')
    }
  }

  handleDownloadTransactionCSV = async () => {
    const request = await fetchGetJSON(Constants.API_PATH_TRANSACTION_CSV);
    if (request.status == "Success") {

      let data = new Blob([request.data], { type: 'text/plain' });
      return window.URL.createObjectURL(data);
    }
    return "#";
  }

  componentDidMount = async () => {
    let url = await this.handleDownloadTransactionCSV();
    this.setState({ csv_url: url });
  }

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let editFocus = "opacity-100";
    let bgFocus;


    let deleteTransactionModal = <></>;
    let deleteTransactionMsg =
      <span>Would you like to delete this transaction with id:
        <span className="font-bold"> {this.state.selectedTransactionId}</span> ?
      </span>
    if (this.state.deleteModalToggle) {
      deleteTransactionModal = (
        <Modal
          title="Delete Transaction"
          content={deleteTransactionMsg}
          onoff={this.state.deleteModalToggle}
          handleModalToggle={this.handleCancelModal}
          confirmButton={this.handleConfirmModal}
        ></Modal>
      );
    }


    if (this.state.editToggle === true || this.state.newExpense === true || this.state.newTransaction) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={() => {
            if (this.state.editToggle) {
              this.handleEditBarToggle()
            }
            if (this.state.newExpense) {
              this.handleNewExpense()
            }
            if (this.state.newTransaction) {
              this.handleNewTransaction()
            }
          }}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }
    return (
      <div className=" font-display bg-white  lg:rounded-tl-lg  ">
        {deleteTransactionModal}
        <NewExpense
          toggle={this.state.newExpense}
          handleEditBarToggle={this.handleNewExpense}
        />
        <NewTransaction
          toggle={this.state.newTransaction}
          handleEditBarToggle={this.handleNewTransaction}
        />

        {bgFocus}
        <BreadCrumb
          rootPage="Finance"
          subPage=""
          sideButton=""
          buttonAction={""}
        />

        <div className="fade-in px-3 pt-24 lg:pt-0 lg:px-10  ">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2">
            {/* Left Side  */}
            {/* <div className="flex gap-4  px-2">
              {(this.state.selected_button == "expenses") && 
                <button
                  onClick={this.handleNewExpense}
                  className="  shadow-md transition duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Expense</span>
                </button>
              }
              {(this.state.selected_button == "transactions") && 
                <button
                  onClick={this.handleNewTransaction}
                  className="  shadow-md transition duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
                >
                  <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                  <span className=" text-md ">New Transaction</span>
                </button>
              }
            </div> */}
          </div>

          {/* <TabList warning={false} tabSelectButton={this.handleButtonSelect}>
            <Label label="Expenses">
              <ExpensesList />
            </Label>
            <Label label="Transactions">
              <TransactionList />
            </Label>
          </TabList> */}
          <div className="flex flex-row mb-2 mt-14 lg:mt-0 px-2">
            {/* Left Side  */}
            <div className="flex flex-row justify-between w-full text-center items-center lg:items-start lg:flex-col lg:w-1/2">
              <button
                onClick={this.handleNewTransaction}
                className="  shadow-md transition duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center"
              >
                <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
                <span className=" text-md ">New Transaction</span>
              </button>
            </div>
            {/* Right Side */}
            <div className="hidden lg:flex flex-row w-1/2 justify-end px-3 ">
              <a
                className="transition duration-100 border-2 border-solid w-36 bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                href={AppStage === "staywell" ? this.state.csv_url : undefined}
                download={AppStage === "staywell" ? `${this.state.selected_button}.csv` : undefined}
              >
                <AiOutlineDownload className="  text-xl mr-1 self-center" />
                <span className=" text-xs self-center ">Download CSV</span>
              </a>
            </div>
          </div>
          <TransactionList handleCancelModal={this.handleCancelModal} deletedTransactionId={this.state.deletedTransactionId} />
        </div>
      </div>
    );
  }
}

export default withHooksHOC(Finance);
