import { Component } from "react";
import { MdEdit, MdPlaylistAdd } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { inputISO } from "../../utils/DateFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { AiOutlineConsoleSql } from "react-icons/ai";
import EditInvoiceLine from "./EditInvoiceLine";
import axios from "axios";
import { withHooksHOC } from "../Notifications/NotificationProvider";
const line_types = ["Rent", "Damages", "Parking", "Other"];

type InvoiceLine = {
  id: number;
  line_type: string;
  entry: string;
  cost: number;
};

type Props = {
  ErrorNote: any;
  handleToggleChange: any;
  handleDateChange: any;
  handleInputChange: any;
  invoiceData: any;
  toggle: any;
  handleEditBarToggle: any;
};

interface QuickViewModalState {
  invoice_id: number;
  invoice_type: string;
  payment_method: string;
  issue_date: Date;
  rent_invoice: boolean;
  start_date: any;
  due_date: any;
  end_date: any;
  invoice_lines: InvoiceLine[];
  new_type: string;
  new_entry: string;
  new_cost: number;
  file: boolean | string;
  name: string;
  currentInvoiceLine: InvoiceLine;

  // activate_tenant: boolean;
  // selected: number;
  // newfname: string;
  // newlname: string;
  // newemail: string;
  // newphone: number;
  toggleEditInvoiceLine: boolean;

  invoice_notes: string;
  // all_tenants: any[];
}

class EditInvoice extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);
    console.log(props);
    this.state = {
      invoice_id: 1234,
      invoice_type: null,
      payment_method: "",
      issue_date: new Date(2020, 6, 10, 0, 0, 0, 0),
      rent_invoice: this.props.invoiceData.rent_invoice,
      start_date: null,
      due_date: null,
      end_date: null,
      invoice_lines: [],
      new_type: null,
      new_entry: null,
      new_cost: 0,
      toggleEditInvoiceLine: false,
      currentInvoiceLine: null,
      file: "",
      name: "",
      // activate_tenant: false,
      // selected: -1,
      // newfname: "",
      // newlname: "",
      // newemail: "",
      // newphone: 0,
      invoice_notes: null,
      // all_tenants: [],
    };

  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
    // if (event.target.id == "selected" && event.target.value != -1) {
    //   let ten = this.state.all_tenants.find(
    //     (tenant: any) => tenant.id == event.target.value
    //   );
    //   this.setState({
    //     newfname: ten.first_name,
    //     newlname: ten.last_name,
    //     newemail: ten.email,
    //     newphone: ten.phone_number,
    //   });
    // }
  };
  handleNumberChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  }

  handleTypeChange = async (event: any) => {
    if (event.target.value !== "Other" || event.target.value !== "Rent") {
      this.setState({
        new_type: event.target.value,
        new_entry: event.target.value,
      });
    } else {
      console.log("Setting type")
      await this.setState({ new_type: event.target.value });
    }
  };
  handlePaymentChange = (event: any) => {
    this.setState({ payment_method: event.target.value })
  }

  handleAddNewLine = async (event: any) => {
    event.preventDefault();
    let hst = this.state.invoice_lines.find(
      (line) => line.line_type.toLowerCase() == "hst"
    );
    if (!hst || this.state.new_type.toLowerCase() != "hst") {
      let lines = this.state.invoice_lines;
      let roundedCost = this.state.new_cost;
      let template_line = {
        id: -1,
        line_type: this.state.new_type,
        cost: Math.round(roundedCost * 100) / 100,
        entry: this.state.new_entry,
      };
      lines.push(template_line);
      await this.setState({
        invoice_lines: lines,
      });
      console.log(this.state.invoice_lines)
      this.handleHST();
      let update = store.getState().invoiceReducer.quickViewUpdate;
      update();
    } else {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler("Error", "You cannot have more than 1 HST line per invoice", "");
    }
  };

  handleDeleteLine = async (event: any) => {
    event.preventDefault();
    let index = event.currentTarget.id.split("+")[1];
    let line = this.state.invoice_lines[index];
    let lines = this.state.invoice_lines;

    // Splice deletes the line from the list and then updates the invoice list
    lines.splice(index, 1);
    this.setState({
      invoice_lines: lines,
    });

    if (line.id != -1) {
      // put the delete stuff here
      let res = await fetchRequestJSON(
        "DELETE",
        `${Constants.API_PATH_INVOICE_LINE_DELETE}${line.id}`,
        {}
      );
    }
    this.handleHST();
  };


  editLine = async (event: any) => {
    event.preventDefault();
    const index = event.currentTarget.id.split("+")[1];
    console.log(index);
    await this.setState({
      currentInvoiceLine: this.state.invoice_lines[index],
    });

    console.log(this.state.currentInvoiceLine)

    this.handleToggleChange("toggleEditInvoiceLine", this.state.toggleEditInvoiceLine);
  };


  // Create the invoice edit body and send it as an API request to the edit route
  handleClick = async () => {
    const NotificationHandler = this.props.ErrorNote;
    let invoiceEditBody: any = {};
    if (this.state.rent_invoice) {
      invoiceEditBody = {
        // fields:{
        start_date: this.state.start_date ? this.state.start_date.toLocaleString('en-US', {timeZone: 'America/New_York'}) : null,
        due: this.state.due_date ? this.state.due_date.toLocaleString('en-US', {timeZone: 'America/New_York'}) : null,
        end: this.state.end_date ? this.state.end_date.toLocaleString('en-US', {timeZone: 'America/New_York'}) : null,
        payment_method_invoice: this.state.payment_method ? this.state.payment_method : null,
        line_type: this.state.new_type ? this.state.new_type : null,
        entry: this.state.new_entry ? this.state.new_entry : null,
        invoice_notes: this.state.invoice_notes ? this.state.invoice_notes : null,
        // }
      };
    } else {
      invoiceEditBody = {
        // fields:{
        // start_date: this.state.start_date ? this.state.start_date : null,
        due: this.state.due_date ? this.state.due_date.toLocaleString('en-US', {timeZone: 'America/New_York'}) : null,
        // end: this.state.end_date ? this.state.end_date : null,
        payment_method_invoice: this.state.payment_method ? this.state.payment_method : null,
        line_type: this.state.new_type ? this.state.new_type : null,
        entry: this.state.new_entry ? this.state.new_entry : null,
        invoice_notes: this.state.invoice_notes ? this.state.invoice_notes : null,
        // }
      };
    }
    console.log(invoiceEditBody);
    const editInvoiceStatus = await this.editInvoice(invoiceEditBody);
    //const editInvoiceLinesStatus = await this.editInvoiceLines();
    if (editInvoiceStatus.status === "Success") {
      NotificationHandler("Success", "This invoice has been successfully edited", "");
    } else {
      NotificationHandler("Error", "This invoice cannot be edited", `${editInvoiceStatus.status}`);
    }
    /*
    if (createInvoiceBody.invoice.id == -1) {
      let body = {
        id: this.state.selected,
        first_name: this.state.newfname,
        last_name: this.state.newlname,
        email: this.state.newemail,
        phone: this.state.newphone,
      }
      if (this.state.newfname == "" || this.state.newlname == "" || this.state.newemail == "" || this.state.newphone == 0) {
        alert("Missing Info")
      } else {
        let res = await fetchRequestJSON(
          "POST",
          Constants.API_PATH_BILLING_CHANGE,
          {
            invoice_id: createInvoiceStatus.data,
            tenant: 
          }
        )
      }
    }
      */
    const update = store.getState().invoiceReducer.quickViewUpdate;
    const list = store.getState().invoiceReducer.listUpdate;
    const invoiceProfile = store.getState().invoiceReducer.update;
    update();
    if (list) {
      list();
    } else if (invoiceProfile) {
      invoiceProfile();
    }
  };

  handleHST = async () => {
    let sum = 0;
    this.state.invoice_lines.forEach((line) => {
      if (line.line_type != "HST") {
        if (typeof line.cost == "string") {
          sum += parseFloat(line.cost);
        } else {
          sum += line.cost;
        }
        //console.log(sum);
      }
    });
    let hst = this.state.invoice_lines.find(
      (line) => line.line_type.toLowerCase() == "hst"
    );
    //console.log(hst);
    if (hst) {
      let res = await fetchRequestJSON(
        "PUT",
        Constants.API_PATH_INVOICE_LINE_EDIT + hst.id,
        {
          line_type: hst.line_type,
          entry: hst.entry,
          cost: (sum * 0.13).toFixed(2),
        }
      );
    }
    await this.editInvoiceLines();
    let list = store.getState().invoiceReducer.listUpdate;
    let quickview = store.getState().invoiceReducer.quickViewUpdate;
    // list();
    // quickview();
    // window.location.reload();
  };

  editInvoice = async (invoiceEditBody: any) => {
    return await fetchRequestJSON(
      "PUT",
      `${Constants.API_PATH_INVOICE_EDIT}${store.getState().invoiceReducer.curId
      }`,
      invoiceEditBody
    );
  };

  editInvoiceLines = async () => {
    for (let i = 0; i < this.state.invoice_lines.length; i++) {
      let line = this.state.invoice_lines[i];
      if (line.id == -1) {
        // If not an existing invoice line (if just created)
        let res = await fetchRequestJSON(
          "PUT",
          `${Constants.API_PATH_INVOICE_LINE_NEW_BLANK}${this.props.invoiceData.invoice_id}`
        );
        let res2 = await fetchRequestJSON(
          "PUT",
          `${Constants.API_PATH_INVOICE_LINE_EDIT}${res?.data?.id}`,
          line
        );
      }
    }
    this.updateList();
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleTextChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  }

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  handleFileChange = async (event: any) => {
    let NotificationProvider = this.props.ErrorNote
    const form = new FormData();
    form.append("id", this.props.invoiceData.invoice_id);
    form.append("category", "invoice");
    form.append("file", event.target.files[0]);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_AWS_API_GATEWAY_LINK}${Constants.API_PATH_FILES_UPLOAD}`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      })
    }
    catch (err) {
      NotificationProvider("Error", err);
    }
    NotificationProvider("Success", "Successfully Uploaded File");
    this.buttonUpdate();
  };

  handleDownload = async (key: string, event: any) => {
    let NotificationProvider = this.props.ErrorNote
    event.preventDefault()
    const request = await fetchGetJSON(
      `${Constants.API_PATH_FILES_DOWNLOAD}?key=${key}`,
      // { key: key }
    );
    window.open(request.data);
    NotificationProvider("Success", 'Successfully Downloaded File')
    this.buttonUpdate();
  };

  // Fill out current info from existing invoice
  componentDidMount = async () => {
    // let tenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    //console.log(tenants.data);
    // this.setState({
    //   all_tenants: tenants.data,
    // });
    this.updateList();
    this.buttonUpdate();
  };

  buttonUpdate = async () => {
    const fileInvoice = await fetchGetJSON(
    `${Constants.API_PATH_FILES_LIST}?id=${this.props.invoiceData.invoice_id}&category=invoice`,
      // `${Constants.API_PATH_FILES_LIST}`,
      // {
      //   id: this.props.invoiceData.invoice_id,
      //   category: "invoice",
      // }
    );
    if (fileInvoice.status === 'Success' && fileInvoice.data.Contents.length > 0) {
      let content: string = fileInvoice.data.Contents[0].Key;
      if (
        fileInvoice.data.Contents.length > 1 &&
        fileInvoice.data.Contents[1].Key.length > content.length
      ) {
        content = fileInvoice.data.Contents[1].Key;
      }
      this.setState({
        file: true,
        name: content,
      });
    } else {
      this.setState({ file: false });
    }
  };

  // Delete the file, calls the API route for it
  handleDelete = async (key: string, event: any) => {
    let NotificationProvider = this.props.ErrorNote
    // console.log("delete");
    if (window.confirm("Are you sure you wish to delete this file?")) {
      event.preventDefault()
      try {
        await fetchRequestJSON("DELETE", `${Constants.API_PATH_FILES_DELETE}?key=${key}`);
      }
      catch (err) {
        NotificationProvider("Error", err)
      }
      NotificationProvider("Success", "Successfully Deleted File")
      this.buttonUpdate();
    }
  };

  updateList = async () => {
    const curId = store.getState().invoiceReducer.curId;
    //const allInvoices = await fetchGetJSON(Constants.API_PATH_INVOICE_GET_ALL);
    let invoiceData = await fetchGetJSON(`${Constants.API_PATH_INVOICE_GET}${curId}`)
    /* const invoiceData = allInvoices.data.find(
      (invoiceItem: any) =>
        invoiceItem.id === store.getState().invoiceReducer.curId
    ); */
    let allInvoiceLines = await fetchGetJSON(
      `${Constants.API_PATH_INVOICE_LINE_GET_ALL}${invoiceData.data.id}`
    );
    console.log(allInvoiceLines.data);
    if (allInvoiceLines.data) {
      this.setState({
        start_date: invoiceData.data.start_date ? invoiceData.data.start_date : null,
        due_date: invoiceData.data.due,
        end_date: invoiceData.data.end,
        invoice_type: invoiceData.data.invoice_types,
        payment_method: invoiceData.data.payment_method_invoice,
        invoice_lines: allInvoiceLines.data,
        invoice_notes: invoiceData.data.invoice_notes,
      });

    }
    if (invoiceData.booking_id == -1) {
      /*
      let billinginfo = await fetchGetJSON(Constants.API_PATH_BILLING_GET + invoiceData.id);
      let tenant_id = billinginfo.data.tenant_id;
      
      let tenant = this.state.all_tenants.find((ten: any) => ten.id == tenant_id)
      this.setState({
        activate_tenant: true,
        selected: tenant.id,
        newfname: tenant.first_name,
        newlname: tenant.last_name,
        newemail: tenant.email,
        newphone: tenant.phone_number,
      })
      */
    }
  };

  render() {
    console.log("this.attet", this.state.file);
    let invoiceID = this.props.invoiceData.invoice_id.toString();
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Invoice";
    let editclassName =
      "opacity-0 z-0 translate-x-96 pointer-events-none overflow-y-scroll scroll-hide";
    let content;
    let inactive = "";
    let tabIndex;

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    let rentEnd = <></>;
    let startDate = <></>;
    if (this.props.invoiceData.rent_invoice === true) {
      rentEnd = (
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Invoice End Date</span>
          <input
            id="end_date"
            onChange={this.handleDateChange}
            value={inputISO(this.state.end_date)}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>
      );
      startDate = (
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Invoice Start Date</span>
          <input
            id="start_date"
            onChange={this.handleDateChange}
            value={this.state.start_date ? inputISO(this.state.start_date): "yyyy-MM-dd"}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>
      );
    }

    let rows = [];
    let altBg = "";
    //console.log(this.state.invoice_lines);
    for (let i = 0; i < this.state.invoice_lines.length; i++) {
      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }

      rows.push(
        <tr
          key={i.toString()}
          className={altBg + " w-full   h-12 border-b border-gray-400  "}
        >
          <td className="py-4 pl-4 pr-2 text-gray-700 font-thin text-sm border-b">
            <div className="flex flex-row">
              <button id={"delete+" + i} onClick={this.handleDeleteLine}>
                <TiDelete className=" text-red-500 hover:text-red-400 text-2xl mr-1 self-center" />
              </button>
              {this.state.invoice_lines[i].line_type}
            </div>
          </td>
          <td className="py-3 font-thin text-sm  border-b">
            {this.state.invoice_lines[i].entry}
          </td>
          <td className="py-3 text-green-500 font-thin border-b">
            <div>${this.state.invoice_lines[i].cost}</div>
          </td>
          <td className="py-3 text-green-500 font-thin px-2 border-b">
            <div>
              <button id={"edit+" + i} onClick={this.editLine}>
                <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl" />
              </button>
            </div>
          </td>
        </tr>
      );
    }

    content = (
      <div>
        <form className="  px-6  py-4">
          <span className="text-gray-600 text-md font-bold mb-4 mt-2">
            General Info
          </span>

          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Invoice Due Date</span>
            <input
              id="due_date"
              onChange={this.handleDateChange}
              value={inputISO(this.state.due_date)}
              type="date"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          {startDate}

          {rentEnd}

          <div className="block pl-3  mb-6 ">
            <span className="text-gray-400 text-xs">Payment Method</span>
            <select
              id="payment_method"
              onChange={this.handlePaymentChange}
              value={this.state.payment_method}
              className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            >
              <option value="TBD">TBD</option>
              <option value="Online Credit">Online Credit</option>
              <option value="Terminal (No Charge, Includes Cash)">Terminal (No Charge, Includes Cash)</option>
              <option value="Terminal Credit (3%)">Terminal Credit (3%)</option>
              <option value="Cheque">Cheque</option>
              <option value="Certified Cheque">Certified Cheque</option>
              <option value="Wire">Wire</option>
              <option value="EMT">EMT</option>
              <option value="Direct Deposit">Direct Deposit</option>
              <option value="Rent Deduction">Rent Deduction</option>
              <option value="Rent Trasfer Credit">Rent Transfer Credit</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="flex  items-center justify-start bg-grey-lighter">
            {this.state.file === false && (
              <input
                type="file"
                name="file"
                id="file-upload"
                className="w-full"
                onChange={this.handleFileChange}
              />
            )}
            {this.state.file && (
              <td className=" text-left ">
                <div className="flex flex-col">
                  <div className="text-xs items-left mb-1">
                    {this.state.name.split("/").pop()}
                  </div>
                  <div className="flex flex-row">
                    <button
                      onClick={(e) => this.handleDownload(this.state.name, e)}
                      className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white cursor-pointer "
                    >
                      Download
                    </button>
                    <button
                      onClick={(e) => this.handleDelete(this.state.name, e)}
                      // onClick={()=> console.log("clicked")}
                      className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            )}
          </div>
          <label className="block mb-5 mt-3">
            <span className="text-gray-600 text-md font-bold mb-4 mt-2">Invoice Notes:</span>
            <textarea
              id="invoice_notes"
              value={this.state.invoice_notes}
              onChange={this.handleTextChange}
              className="mt-4 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={3}
            ></textarea>
          </label>

          <div className="flex flex-row items-center justify-start pl-3 mb-8 ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Rent
            </span>

            <ToggleSwitch
              id="rent_invoice"
              handleStateToggle={this.props.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.props.invoiceData.rent_invoice}
            />
          </div>

          <span className="text-gray-600 text-md font-bold mb-4 mt-2">
            Invoice Lines
          </span>
          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">
              <b>New Type*</b>
            </span>
            <select
              id="new_type"
              onChange={this.handleTypeChange}
              value={this.state.new_type}
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            >
              <option value="Rent">Rent</option>
              <option value="HST">HST</option>
              <option value="Parking">Parking</option>
              <option value="Rent Refund">Rent Refund</option>
              <option value="Cleaning Fee">Cleaning Fee</option>
              <option value="Referral Fee">Referral Fee</option>
              <option value="StayWell Referral Fee">StayWell Referral Fee</option>
              <option value="Regular Cleaning Fee">Regular Cleaning Fee</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">
              <b>New Entry* </b>
            </span>
            <span className="text-gray-400 text-xs">Line Entry</span>
            {/* {this.state.new_type === "Rent" && this.props.fromBooking && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                {this.state.check_in.toISOString().substr(0, 10)} to{" "}
                {this.state.check_out.toISOString().substr(0, 10)}{" "}
                {this.state.booking_unit}
              </span>
            )} */}
            {this.state.new_type === "HST" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                HST
              </span>
            )}
            {this.state.new_type === "Parking" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                Parking
              </span>
            )}
            {this.state.new_type === "Rent Refund" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                Rent Refund
              </span>
            )}
            {this.state.new_type === "Cleaning Fee" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                Cleaning Fee
              </span>
            )}
            {this.state.new_type === "Referral Fee" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                Referral Fee
              </span>
            )}
            {this.state.new_type === "StayWell Referral Fee" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                StayWell Referral Fee
              </span>
            )}
            {this.state.new_type === "Regular Cleaning Fee" && (
              <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                Regular Cleaning Fee
              </span>
            )}
            {this.state.new_type === "Other" && (
              <input
                id="new_entry"
                onChange={this.handleInputChange}
                value={this.state.new_entry}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              />
            )}
          </label>
          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">
              <b>New Cost*</b>
            </span>
            <input
              id="new_cost"
              onChange={this.handleNumberChange}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              value={this.state.new_cost}
              type="number"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder="0"
              step=".01"
            />
          </label>

          <button
            className="ml-3 transition duration-200 w-auto hover:bg-green-400 px-10 py-2 bg-gray-500 text-sm  rounded-sm text-white  mb-10 flex flex-row"
            onClick={this.handleAddNewLine}
          >
            <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
            <span>Add New Line</span>
          </button>
          <table
            style={{ borderSpacing: "0" }}
            className="border-separate border rounded-sm mt-6 relative w-full table-fixed bg-white     "
          >
            <thead className="border-b border-gray-400 ">
              <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                <th className="sticky top-0  py-3 pl-5  font-thin border-b border-gray-400  ">
                  Item
                </th>

                <th className=" sticky top-0 py-3 font-thin border-b border-gray-400 ">
                  Entry
                </th>
                <th className=" sticky top-0 py-3 font-thin border-b border-gray-400">
                  Price
                </th>
                <th className=" sticky top-0 py-3 font-thin border-b border-gray-400">
                  Edit
                </th>
              </tr>
            </thead>

            <tbody className="divide-y">{rows}</tbody>
          </table>
          <button
            type="button"
            className="text-xs text-gray-400 underline"
            onClick={this.handleHST}
          >
            Manual Recalculate HST
          </button>

          {/* {
            this.state.activate_tenant && (
              <>
                <div className="block pl-3 mt-8 mb-4">
                  <span className="text-gray-600 text-md font-bold">
                    Add Billing Contact
                  </span>
                </div>

                <div className="block pl-3  mb-6">
                  <select
                    id="selected"
                    onChange={this.handleInputChange}
                    className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  >
                    {this.state.all_tenants.map((ten: any) => (
                      <option value={ten.id}>
                        {ten.first_name + " " + ten.last_name}
                      </option>
                    ))}
                    <option selected value={-1}>
                      Create new tenant
                    </option>
                  </select>
                </div>

                <label className="block pl-3 mb-5">
                  <span className="text-gray-400 text-xs">Tenant First Name</span>
                  <input
                    id="newfname"
                    value={this.state.newfname}
                    onChange={this.handleInputChange}
                    type="text"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder=""
                    disabled={!(this.state.selected == -1)}
                  />
                </label>

                <label className="block pl-3 mb-5">
                  <span className="text-gray-400 text-xs">Tenant Last Name</span>
                  <input
                    id="newlname"
                    value={this.state.newlname}
                    onChange={this.handleInputChange}
                    type="text"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder=""
                    disabled={!(this.state.selected == -1)}
                  />
                </label>

                <label className="block pl-3 mb-5">
                  <span className="text-gray-400 text-xs">Tenant Email</span>
                  <input
                    id="newemail"
                    value={this.state.newemail}
                    onChange={this.handleInputChange}
                    type="text"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder=""
                    disabled={!(this.state.selected == -1)}
                  />
                </label>

                <label className="block pl-3 mb-5">
                  <span className="text-gray-400 text-xs">Tenant Phone</span>
                  <input
                    id="newphone"
                    value={this.state.newphone}
                    onChange={this.handleInputChange}
                    type="text"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder=""
                    disabled={!(this.state.selected == -1)}
                  />
                </label>
              </>
            )
          } */}
        </form >
      </div >
    );

    return (
      <div>
        <div
          className={
            editclassName +
            " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll scroll-hide z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
          }
        >
          {
            this.state.toggleEditInvoiceLine &&
            <div>
              <EditInvoiceLine
                handleModalToggle={() => this.handleToggleChange("toggleTransfer", this.state.toggleEditInvoiceLine)}
                onoff={this.state.toggleEditInvoiceLine}
                title={`Edit Invoice Line`}
                invoiceLine={this.state.currentInvoiceLine}
              />
            </div>
          }

          {/* Top Title Bar */}
          <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
            <button
              onClick={this.props.handleEditBarToggle}
              className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
            >
              <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
            </button>

            {/* <button onClick={this.props.openSettings}  className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
                  <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 "/>   
              </button>   */}
          </div>
          <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
            <span className=" font-black text-xl text-gray-700">
              {ProfileType}{" "}
            </span>
            <span className="italic font-sofia text-gray-400 text-lg font-thin">
              #{this.props.invoiceData.invoice_id}
            </span>
          </div>

          {content}

          <div className="h-24 flex flex-row ">
            <button
              onClick={() => {
                this.handleClick();
                this.props.handleEditBarToggle();
              }}
              className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded text-white font-bold shadow-sm mb-10 "
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(EditInvoice);
