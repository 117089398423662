import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Link, useRouteMatch } from "react-router-dom";
import PaymentsQuickView from "./PaymentsQuickView";
import InvoiceQuickView from "./InvoiceQuickView";
import { formatFullDate } from "../../../utils/DateFormat";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DashBar from "../DashBar";
import { ReportSelection } from "../../Reporting/ReportSelection";


type Props = {
  userData: any;
};

type State = {
  selectedReport: string;
};

class FinReports extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedReport: "Corporate Invoicing",
    };
  }

  handleSelectedReport = (event: any) => {
    event.preventDefault();
    let target = event.target.id;
    this.setState({ selectedReport: target });
  };

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    let selectedClass = "";

    return (
      <div className=" font-display bg-f9 h-screen  lg:rounded-tl-lg py-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1  w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""} />
            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Finance Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>
          <ReportSelection></ReportSelection>

          {/* Heading Info Area  */}

        </div>  
      </div>
    );
  }
}

export default FinReports;

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let linkEnd = props.linkEnd;
  let toPath = "";
  let currentPage = url.substring(url.lastIndexOf("/"));

  if (path.endsWith(linkEnd) && linkEnd != "") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
    toPath = url;
  } else {
    toPath = url.replace(currentPage, linkEnd);
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={toPath}>{props.text}</Link>
    </div>
  );
}
