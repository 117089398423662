export abstract class Constants {
  static readonly API_PATH_PAYMENTS_CREATE = "/payments/";
  static readonly API_PATH_DEPOSITS_CREATE = "/payments/deposit/";
  static readonly API_PATH_INVOICE_GET_ALL = "/invoices/get-all";
  static readonly API_PATH_INVOICE_GET = "/invoices/getInvoice/";
  static readonly API_PATH_INVOICE_FULLINFO = "/invoices/fullinfo/";
  static readonly API_PATH_INVOICE_AMOUNT = "/invoices/amount/";
  static readonly API_PATH_INVOICE_APPENDING = "/invoices/payment-approval/";
  static readonly API_PATH_BOOKING_GET_ALL = "/booking/get-all";
  static readonly API_PATH_BOOKING_STATBAR = "/booking/statbar";
  static readonly API_PATH_BOOKING_GET = "/booking/get/";
  static readonly API_PATH_BOOKING_FULLINFO = "/booking/fullinfo/";
  static readonly API_PATH_BOOKING_PROFILE = "/booking/bookingprofile/"
  static readonly API_PATH_CHECKOUT_FORM = "/booking/checkout/getpublic/";
  static readonly API_PATH_EARLY_CHECKIN = "/booking/earlycheckin";
  static readonly API_PATH_LATE_CHECKIN = "/booking/latecheckin";
  static readonly API_PATH_BOOKING_PROSPECTIVE_REPORT =
    "/booking/getprospectivemonthlyreporting";
  static readonly API_PATH_BOOKING_CORPORATE_MONTHLY_REV =
    "/booking/getcorporatemonthlyrevenue";
  static readonly API_PATH_BOOKING_CORPORATE_BOOKING_REPORT =
  "/booking/getcorporatebookingsreport";
  static readonly API_PATH_TENANT_GET_ALL = "/tenant/get-all";
  static readonly API_PATH_TENANT_BOOKINGS = "/tenant/bookings/";
  static readonly API_PATH_TENANT_GET = "/tenant/get/";
  static readonly API_PATH_TENANT_SEARCH = "/tenant/search";
  static readonly API_PATH_CORP_GET_ALL = "/corporate/get-all";
  static readonly API_PATH_CORP_GET = "/corporate/get/";
  static readonly API_PATH_CORP_BOOKINGS = "/booking/getcorpbooking";
  static readonly API_PATH_CORP_CANCEL = "/corporate/cancel";
  static readonly API_PATH_CORP_CSV = "/corporate/CorporateCSV";
  static readonly API_PATH_CORP_CONTACT_CANCEL = "/corporate-contact/cancel";
  static readonly API_PATH_CORP_CONTACT_CSV =
    "/corporate-contact/Corporate-ContactCSV";
  static readonly API_PATH_BLACKOUT_CREATE = "/blackout/create";
  static readonly API_PATH_BLACKOUT_GET_ALL = "/blackout/get-all";
  static readonly API_PATH_BLACKOUT_GET = "/blackout/get/";
  static readonly API_PATH_BLACKOUT_DELETE = "/blackout/cancel";
  static readonly API_PATH_BLACKOUT_SHORTEN = "/blackout/shortenblackout";
  static readonly API_PATH_BLACKOUT_EDIT = "/blackout/edit";
  static readonly API_PATH_BLACKOUT_CSV = "/blackout/get-csv";
  static readonly API_PATH_CORP_CONTACT_GET_ALL = "/corporate-contact/get-all";
  static readonly API_PATH_CORP_CONTACT_GET_CORPORATE_CONTACT =
    "/corporate-contact/get/";
  static readonly API_PATH_OWNER_CANCEL = "/unit-owner/cancel";
  static readonly API_PATH_OWNER_CSV = "/unit-owner/get-csv";
  static readonly API_PATH_BOOKING_CANCEL = "/booking/cancel";
  static readonly API_PATH_UNIT_GET_SUITE_NAME = "/unit/suite_name";
  // static readonly API_PATH_UNIT_CANCEL = "/unit/cancel"; // we do not allow for deletion of units, we deactivate them instead (currently only used in an unused view)
  static readonly API_PATH_BOOKING_SEARCH = "/booking/search";
  static readonly API_PATH_BOOKING_CREATE = "/booking/create";
  static readonly API_PATH_TENANT_CREATE = "/tenant/create";
  static readonly API_PATH_TENANT_EDIT = "/tenant/edit";
  static readonly API_PATH_INVOICE_CANCEL = "/invoices/delete/";
  static readonly API_PATH_BUILDING_CANCEL = "/building/cancel";
  static readonly API_PATH_TENANT_CANCEL = "/tenant/cancel";
  static readonly API_PATH_TENANT_CSV = "/tenant/TenantCSV";
  static readonly API_PATH_BOOKING_SHORTEN = "/booking/shorten";
  static readonly API_PATH_ASSOCIATED_TENANTS = "/booking/associatedtenants/";
  static readonly API_PATH_GET_INVOICE_BOOKING_ID = "/invoices/data/booking/";
  static readonly API_PATH_GET_BOOKING_CALENDAR_DATA = "/booking/calendarinfo";
  static readonly API_PATH_GET_BOOKING_PORTAL_INFO = "/booking/bookingPortal/";
  static readonly API_PATH_BOOKING_CONFIRMATION_DATA =
    "/booking/bookingconfirmationinfo/";
  static readonly API_PATH_BOOKING_CONFIRMATIONFORM_SUBMIT =
    "/booking/bookingconfirmation/";
  static readonly API_PATH_SEND_BOOKING_CONFIRMATIONFORM =
    "/booking/sendbookingconfirmationreminder/";
  static readonly API_PATH_GET_BOOKING_CHECK_IN =
    "/booking/upcomingcheckinsfrontend";
  static readonly API_PATH_GET_BOOKING_CHECK_OUT =
    "/booking/upcomingcheckoutsfrontend";
    static readonly API_PATH_BOOKING_PORTAL_URL =
    "/booking/bookingPortalURL/";

  //Unified Routes
  static readonly API_PATH_PROFILE = "/profile"

  //Extensions
  static readonly API_PATH_EXTENSION_CLIENT_DETAILS = "/extension/requestform";
  static readonly API_PATH_EXTENSION_CREATE = "/extension/create";
  static readonly API_PATH_EXTENSION_GET_ALL = "/extension/get-all";
  static readonly API_PATH_EXTENSION_GET_ALL_UNCONFIRMED =
    "/extension/get-all-unconfirmed";
  static readonly API_PATH_EXTENSION_GET_ALL_UNAPPROVED =
    "/extension/get-all-unapproved";
  static readonly API_PATH_EXTENSION_CONFIRM = "/extension/confirm";
  static readonly API_PATH_EXTENSION_DECLINE = "/extension/decline";
  static readonly API_PATH_EXTENSION_ACCEPT = "/extension/accept";
  static readonly API_PATH_EXTENSION_REJECT = "/extension/delete";
  static readonly API_PATH_EXTENSION_BOOKINGID = "/extension/booking/";
  static readonly API_PATH_EXTENSION_SUBMITFORM = "/extension/submitform/";
  static readonly API_PATH_BOOKING_EDIT = "/booking/edit";
  static readonly API_PATH_BOOKING_EDIT_RATE = "/booking/editrate";
  static readonly API_PATH_FORCE_KEYCAFE = "/booking/forcekeycafe";
  static readonly API_PATH_CORP_CONTACT_EDIT = "/corporate-contact/edit";
  static readonly API_PATH_CORP_CONTACT_GET_BOOKING =
    "/corporate-contact/get/booking/";
  static readonly API_PATH_UNIT_GET_ALL = "/unit/get-all";
  static readonly API_PATH_BOOKING_TRANSFER = "/booking/transfer";
  static readonly API_PATH_BOOKING_PRETRANSFER = "/booking/pretransfer";
  static readonly API_PATH_BOOKING_SWAP = "/booking/swap";
  static readonly API_PATH_BUILDING_GET_ALL = "/building/get-all";
  static readonly API_PATH_BUILDING_GET_UNITS = "/building/units/"
  static readonly API_PATH_CORP_EDIT = "/corporate/edit";
  static readonly API_PATH_CORP_CREATE = "/corporate/create";
  static readonly API_PATH_DECLINE_INVOICE = "/payments/decline/";
  static readonly API_PATH_INVOICE_EDIT = "/invoices/edit/";
  static readonly API_PATH_INVOICE_CREATE = "/invoices/create";
  static readonly API_PATH_BUILDING_GET = "/building/";
  static readonly API_PATH_BUILDING_EDIT = "/building/edit";
  static readonly API_PATH_GET_UNIT_DATA_PUBLIC = "/unit/getpublic/";
  static readonly API_PATH_GET_UNIT_DATA_URL = "/unit/getpublicURL/";
  static readonly API_PATH_OWNER_GET = "/unit-owner/";
  static readonly API_PATH_OWNER_GET_UNITS = "/unit-owner/units/";
  static readonly API_PATH_OWNER_EDIT = "/unit-owner/edit";
  static readonly API_PATH_UNIT_EDIT = "/unit/edit";
  static readonly API_PATH_OWNER_GET_ALL = "/unit-owner/get-all";
  static readonly API_PATH_UNIT_GET = "/unit/";
  static readonly API_PATH_BUILDING_CREATE = "/building/create";
  static readonly API_PATH_OWNER_CREATE = "/unit-owner/create";
  static readonly API_PATH_UNIT_CREATE = "/unit/create";
  static readonly API_PATH_CORP_CONTACT_CREATE = "/corporate-contact/create";
  static readonly API_PATH_INVOICE_SIGNATURE_CHECK = "/invoices/data/";
  static readonly API_PATH_PAYMENT_CONFIRM = "/payments/transaction-confirm";
  static readonly API_PATH_FILES_UPLOAD = "/files/upload";
  static readonly API_PATH_FILES_DOWNLOAD = "/files/download";
  static readonly API_PATH_FILES_LIST = "/files/list";
  static readonly API_PATH_FILES_DELETE = "/files/delete";
  static readonly API_PATH_OCCUPANCY = "/booking/occupancy";
  static readonly API_PATH_INVOICE_LINE_GET_ALL = "/invoices/line-data/";
  static readonly API_PATH_INVOICE_LINE_EDIT = "/invoices/lines/edit/";
  static readonly API_PATH_INVOICE_LINE_DELETE = "/invoices/lines/delete/";
  static readonly API_PATH_INVOICE_LINE_NEW_BLANK =
    "/invoices/lines/new-blank-line/";
  static readonly API_PATH_SEND_INVOICE_EMAIL = "/invoices/send-invoice-email";
  static readonly API_PATH_INVOICE_URL = "/invoices/signed/invoice-url/";
  static readonly API_PATH_INVOICE_CORPORATE_INVOICING =
    "/invoices/corporateInvoicing";
  static readonly API_PATH_INVOICE_CORPORATE_REVENUE =
    "/invoices/corporaterevenue";
  static readonly API_PATH_UNIT_GET_CSV = "/unit/UnitCSV";
  static readonly API_PATH_INVOICE_GET_CSV = "/get/invoiceCSV";
  static readonly API_PATH_BUILDING_GET_CSV = "/building/buildingCSV";
  static readonly API_PATH_BUILDING_BOOKING_GET_CSV = "/building/csv";
  static readonly API_PATH_BOOKING_CHECK_OUT = "/booking/checkout";
  static readonly API_PATH_BOOKING_HARD_CHECK_OUT = "/booking/hardcheckout";
  static readonly API_PATH_INVOICE_CONFIRM = "/payments/single-confirm/";
  static readonly API_PATH_INVOICE_MARK_PAID = "/payments/paid/";
  static readonly API_PATH_INVOICE_UNMARK_PAID = "/payments/unpaid/";
  static readonly API_PATH_INVOICE_BY_BOOKING = "/invoices/data/booking/";
  static readonly API_PATH_INVOICE_FIRST_RENT = "/invoices/firstrent/";
  static readonly API_PATH_CREATE_BOOKING_INVOICE =
    "/invoices/createbookinginvoice";
  static readonly API_PATH_CLEANING_CREATE = "/cleaning/create";
  static readonly API_PATH_CLEANING_ASSIGN_CLEANER = "/cleaning/assign-cleaner";
  static readonly API_PATH_CLEANING_GET = "cleaning/"
  static readonly API_PATH_CLEANING_GET_ALL = "/cleaning/get-all";
  static readonly API_PATH_CLEANING_CALENDAR = "/cleaning/calendar"
  static readonly API_PATH_CLEANING_BY_BOOKING_ID = "/cleaning/booking/";
  static readonly API_PATH_CLEANING_EDIT = "/cleaning/edit";
  static readonly API_PATH_CLEANING_MOVE = "/cleaning/move";
  static readonly API_PATH_CLEANING_CONFIRM = "/cleaning/confirm";
  static readonly API_PATH_CLEANING_DELETE = "/cleaning/delete/";
  static readonly API_PATH_CLEANING_UNASSIGN_CLEANER = "/cleaning/unassign-cleaner/";
  static readonly API_PATH_CLEANER_CREATE = "/cleaner/create";
  static readonly API_PATH_CLEANER_GET_ALL = "/cleaner/get-all";
  static readonly API_PATH_CLEANER_SCHEDULE = "/cleaner/schedule/";
  static readonly API_PATH_CLEANER_DEACTIVATE = "/cleaner/deactivate/";
  static readonly API_PATH_TODAY_GET_CHECK_IN = "/booking/today/checkins";
  static readonly API_PATH_TODAY_GET_CHECK_OUT = "/booking/today/checkouts";
  static readonly API_PATH_TODAY_GET_VACANT = "/booking/today/vacants";
  static readonly API_PATH_CORP_TOGGLE_ACTIVE = "/corporate/toggleActive";
  static readonly API_PATH_CORP_ACTIVATE = "/corporate/activate";
  static readonly API_PATH_CORP_DEACTIVATE = "/corporate/deactivate";
  static readonly API_PATH_OUTSTANDING_AR = "/invoice-lines/outstandingAR";
  static readonly API_PATH_TRUEAR = "/invoices/truear";
  static readonly API_PATH_OUTSTANDING_ARLite =
    "/invoice-lines/outstandingARlite";
  static readonly API_PATH_OUSTANDING_AR_CSV = "/invoice-lines/getarcsv";
  static readonly API_PATH_MONTHLY_MANAGED_CSV = "/booking/monthManagedCSV";
  static readonly API_PATH_PREPAID = "/get/prepaid";
  static readonly API_PATH_CORPAR = "/get/corpar";
  static readonly API_PATH_REV_MONTH = "/get/rev-month";
  static readonly API_PATH_REV_CORP = "/get/rev-corp";
  static readonly API_PATH_CONSOLIDATED_GET_ALL =
    "/invoices/consolidated/getAll";
  static readonly API_PATH_CONSOLIDATED_LINKED =
    "/invoices/consolidated/linkedInvoices";
  static readonly API_PATH_CONSOLIDATED_DELETE =
    "/invoices/consolidated/delete/";
  static readonly API_PATH_CONSOLIDATED_MARK_PAID =
    "/payments/consolidated/markPaid/";
  static readonly API_PATH_CONSOLIDATED_LINK =
    "/invoices/consolidated/clientInvoice/";
  static readonly API_PATH_CONSOLIDATED_PAYMENT_DATA =
    "/payments/consolidated/paymentData/";
  static readonly API_PATH_CONSOLIDATED_DATA = "/payments/consolidated/data/";
  static readonly API_PATH_CONSOLIDATED_PAID_ONLINE =
    "/invoices/consolidated/onlinePaid/";
  static readonly API_PATH_CONSOLIDATED_CREATE_FROM_INVOICES =
    "/invoices/consolidated/createFromInvoices";
  static readonly API_PATH_CONSOLIDATED_AMOUNT =
    "/invoices/consolidated/amount/";
  static readonly API_PATH_CONSOLIDATED_DUE = "/invoices/consolidated/dueDate/";
  static readonly API_PATH_CONSOLIDATED_GET = "/invoices/consolidated/get/";
  static readonly API_PATH_DRH = "/get/deprevhst";
  static readonly API_PATH_BILLING_CREATE = "/invoices/billings/create";
  static readonly API_PATH_BILLING_GET_ALL = "/invoices/billings/get-all";
  static readonly API_PATH_BILLING_GET = "/invoices/billings/get/";
  static readonly API_PATH_EXPENSES_GET_LIST = "/invoices/expenses/get-list";
  static readonly API_PATH_EXEPENSES_CREATE = "/invoices/expenses/create";
  static readonly API_PATH_REVPAR = "/booking/revPAR";
  static readonly API_PATH_CONSOLIDATED_PAYMENT_CONFIRM =
    "/payments/consolidated/markConfirm/";
  static readonly API_PATH_FINANCE_DASHBOARD = "/get/fin-dash";
  static readonly API_PATH_TRANSACTION_GET_ALL =
    "/payments/transactions/get-all";
  static readonly API_PATH_TRANSACTION_GET = "/payments/transactions/get/";
  static readonly API_PATH_TRANSACTION_CREATE = "/payments/transactions/create";
  static readonly API_PATH_TRANSACTION_DELETE =
    "/payments/transactions/delete/";
  static readonly API_PATH_UNIT_TOGGLE_ACTIVE = "/unit/toggleActive";
  static readonly API_PATH_SALES_DASHBOARD = "/booking/salesDashboardData";
  static readonly API_PATH_EXPENSES_DELETE = "/invoices/expenses/delete/";
  static readonly API_PATH_TRANSACTION_CSV = "/get/transactionCSV";

  // ListViewSearches
  static readonly API_PATH_LISTVIEW_INVOICES = "/display/invoiceList";
  static readonly API_PATH_LISTVIEW_TRANSACTIONS = "/display/transactionList";
  static readonly API_PATH_LISTVIEW_BOOKINGS = "/display/bookingList";
  static readonly API_PATH_LISTVIEW_UNITS = "/display/unitList";
  static readonly API_PATH_LISTVIEW_BUILDINGS = "/display/buildingList";
  static readonly API_PATH_LISTVIEW_CONTACTS = "/display/contactList";
  static readonly API_PATH_LISTVIEW_CORPORATES = "/display/corporateList";
  static readonly API_PATH_LISTVIEW_TENANTS = "/display/tenantList";
  static readonly API_PATH_LISTVIEW_EXPENSES = "/display/expenseList";
  static readonly API_PATH_LISTVIEW_CONSOLIDATED = "/display/consolidatedList";
  static readonly API_PATH_LISTVIEW_UNIT_OWNERS = "/display/unitownerlist";
  static readonly API_PATH_LISTVIEW_BLACKOUTS = "/display/blackoutlist";

  //Reporting
  static readonly API_PATH_MONTHLY_REPORTING = "/booking/getmonthlyreporting";

  //Parking
  static readonly API_PATH_PARKING_CALENDAR = "/parking/calendarinfo"
  static readonly API_PATH_PARKING_CREATE = "/parking/create"
  static readonly API_PATH_PARKING_LIST = "/parking/listparking"
  static readonly API_PATH_PARKING_FULLINFO = "/parking/get/fullinfo/"
  static readonly API_PATH_PARKING_CONFIRM = "/parking/confirm/"
  static readonly API_PATH_PARKING_CANCEL = "/parking/cancel"
  static readonly API_PATH_PARKING_EDIT = "/parking/edit"
  static readonly API_PATH_PARKING_EARLY_CHECK_IN= "/parking/earlyCheckIn" 
  static readonly API_PATH_PARKING_LATE_CHECK_IN= "/parking/lateCheckIn" 
  static readonly API_PATH_PARKING_SHORTEN= "/parking/shorten" 
  static readonly API_PATH_PARKING_EXTEND= "/parking/extend" 
  static readonly API_PATH_PARKING_TRANSFER= "/parking/transfer" 
  static readonly API_PATH_PARKING_PRETRANSFER= "/parking/pretransfer" 
  static readonly API_PATH_PARKING_SWAP= "/parking/swap" 
  static readonly API_PATH_PARKING_OVERLAP_CHECK= "/parking/overlap" 
  static readonly API_PATH_PARKING_BOOKING_ID= "/parking/get/booking/" 

  //Parking Spots
  static readonly API_PATH_PARKING_SPOT_CREATE = "/parking-spots/create"
  static readonly API_PATH_PARKING_SPOT_GET_ALL = "/parking-spots/get/all"
  static readonly API_PATH_PARKING_SPOT_LIST = "/parking-spots/display/parkingspot"
  static readonly API_PATH_PARKING_SPOT_GET_ALL_ACTIVE = "/parking-spots/get/all/active"
  static readonly API_PATH_PARKING_SPOT_DEACTIVATION = "/parking-spots/deactivate"
  static readonly API_PATH_PARKING_SPOT_GET_ID = "/parking-spots/single/"
  static readonly API_PATH_PARKING_SPOT_GET_FULL_INFO = "/parking-spots/get/fullinfo/"
  static readonly API_PATH_PARKING_SPOT_EDIT= "/parking-spots/edit" 
  static readonly API_PATH_PARKING_SPOT_GET_PARKING = "/parking-spots/get/parking/"

  //Parking Spots Blackouts
  static readonly API_PATH_PARKING_SPOT_BLACKOUTS_CREATE = "/parking-spots-blackout/create"
  static readonly API_PATH_PARKING_SPOT_BLACKOUTS_GET_ALL = "/parking-spots-blackout/get/all"
  static readonly API_PATH_PARKING_SPOT_BLACKOUTS_DISPLAY = "/parking-spots-blackout/display/blackoutlist"
  static readonly API_PATH_PARKING_SPOT_BLACKOUTS_DEACTIVATE = "/parking-spots-blackout/deactivate"
  static readonly API_PATH_PARKING_SPOT_BLACKOUTS_GET_SINGLE = "/parking-spots-blackout/get/single/"
  static readonly API_PATH_PARKING_SPOT_BLACKOUTS_EDIT = "/parking-spots-blackout/edit"

  //History Logging
  static readonly API_PATH_LOGGING_BOOKING_HISTORY_FULLINFO = "/logging/get/bookings/fullinfo"
  static readonly API_PATH_LOGGING_INVOICE_HISTORY_FULLINFO = "/logging/get/invoices/fullinfo"

  //Amazon testing
  static readonly API_PATH_AMAZON_REQUEST = "/booking/testamazonrequest"
}
