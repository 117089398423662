import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";

type Props = {
  buildingData: any;
  toggle: any;
  handleEditBarToggle: any;
  handleInputChange: any;
  handleToggleChange: any;
};

type EditUnitState = {
  building_name: string;
  address: string;
  id: number;
  building_notes: string;
  flagged: boolean;
  min_stay: number;
  min_type: string;
  concierge_number: number;
  property_manager_number: number;
  special_instructions: string;
  amenities: string;
  district: string;

  // edited: boolean
};

class EditBuilding extends Component<Props, EditUnitState> {
  constructor(props: any) {
    super(props);
    this.state = {
      building_name: "",
      address: "",
      id: -1,
      building_notes: "",
      flagged: false,
      min_stay: 0,
      min_type: "",
      concierge_number: 0,
      property_manager_number: 0,
      special_instructions: "",
      amenities: "",
      district: "",

      // edited: false
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // Update fields with the current information
  componentDidMount = async () => {
    const buildingId = Number(
      new URLSearchParams(window.location.search).get("id")
    );
    const building = await fetchGetJSON(
      `${Constants.API_PATH_BUILDING_GET}${buildingId}`
    );

    this.setState({
      building_name: building.data.building_name,
      id: buildingId,
      address: building.data.address,
      building_notes: building.data.svs_notes,
      min_stay: building.data.minimum_stay,
      concierge_number: building.data.concierge_number,
      property_manager_number: building.data.property_manager_number,
      special_instructions: building.data.special_instructions,
      amenities: building.data.amenities,
      district: building.data.district,
      // edited: false
    });
  };

  // Fill out fields for edit request body and send the edit post request
  handleClick = async () => {
    const buildingId = Number(
      new URLSearchParams(window.location.search).get("id")
    );
    const building = await fetchGetJSON(
      `${Constants.API_PATH_BUILDING_GET}${buildingId}`
    );

    let buildingEditBody;
    if (this.state.building_name !== building.data.building_name) {
      buildingEditBody = {
        id: this.state.id,
        fields: {
          building_name: this.state.building_name,
          address: this.state.address,
          svs_notes: this.state.building_notes,
          minimum_stay: this.state.min_stay,
          concierge_number: this.state.concierge_number,
          property_manager_number: this.state.property_manager_number,
          special_instructions: this.state.special_instructions,
          amenities: this.state.amenities,
          district: this.state.district,
        },
      };
    } else {
      buildingEditBody = {
        id: this.state.id,
        fields: {
          address: this.state.address,
          svs_notes: this.state.building_notes,
          minimum_stay: this.state.min_stay,
          concierge_number: this.state.concierge_number,
          property_manager_number: this.state.property_manager_number,
          special_instructions: this.state.special_instructions,
          amenities: this.state.amenities,
          district: this.state.district,
        },
      };
    }
    const editBuildingStatus = await this.editBuilding(buildingEditBody);
    if (editBuildingStatus.status === "Success") {
      window.location.reload();
    }

    this.props.handleEditBarToggle();
    // console.log(editRequest)
  };

  editBuilding = async (buildingEditBody: any) => {
    return await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BUILDING_EDIT,
      buildingEditBody
    );
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Building";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry = [];

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Building Name</span>
          <input
            id="building_name"
            onChange={this.handleInputChange}
            value={this.state.building_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Address</span>
          <input
            id="address"
            onChange={this.handleInputChange}
            value={this.state.address}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <div className=" pl-3 w-full grid grid-cols-2 gap-3 mb-5">
          <label className="block  ">
            <span className="text-gray-400 text-xs">Minimum Stay</span>
            <input
              id="min_stay"
              onChange={this.handleInputChange}
              onWheel={(e) => {e.currentTarget.blur()}}
              value={this.state.min_stay}
              type="number"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <div className="block   ">
            <span className="text-gray-400 text-xs">Suite Type</span>
            <select
              id="min_type"
              onChange={this.handleInputChange}
              value={this.state.min_type}
              className="  block w-full mt-0 py-1 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            >
              <option>Days</option>
              <option>Weeks</option>
              <option>Months</option>
              <option>Year(s)</option>
              <option>None</option>
            </select>
          </div>
        </div>

        <div className={" block pl-3 w-full my-10 "}>
          <div className="flex flex-row items-center justify-between ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Heat Building
            </span>

            <ToggleSwitch
              id="flagged"
              handleStateToggle={this.props.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.flagged}
            />
          </div>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Concierge Number</span>
          <input
            id="concierge_number"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.concierge_number}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">
            Property Management Number
          </span>
          <input
            id="property_manager_number"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.property_manager_number}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Building Notes</span>
          <textarea
            id="building_notes"
            onChange={this.handleInputChange}
            value={this.state.building_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Special Instructions</span>
          <textarea
            id="special_instructions"
            onChange={this.handleInputChange}
            value={this.state.special_instructions}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Amenities</span>
          <textarea
            id="amenities"
            onChange={this.handleInputChange}
            value={this.state.amenities}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">District</span>
          <textarea
            id="district"
            onChange={this.handleInputChange}
            value={this.state.district}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.props.buildingData.notes}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => this.handleClick()}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default EditBuilding;

export function inputISO(date: Date) {
  let year = date.getFullYear().toString();
  let month = date.getMonth();
  let dt = date.getDate();
  let day = "";
  let month2 = "";

  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt.toString();
  }
  if (month < 10) {
    month2 = "0" + month;
  } else {
    month2 = month.toString();
  }

  return year + "-" + month2 + "-" + day;
}
