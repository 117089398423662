import { Component } from "react";

interface Props {
  BugReportToggle: any;
  onoff: boolean;
}

interface GlobalBugReportingState {
  onoff: boolean;
  corporate: boolean;
  booking_id: number;
  corporate_id: number;
  corporate_name: string;
  tenant_name: string;
  check_in: Date;
  check_out: Date;
  suite: string;
  editToggle: boolean;
}

class GlobalBugReporting extends Component<Props, GlobalBugReportingState> {
  constructor(props: any) {
    super(props);

    this.state = {
      onoff: this.props.onoff,
      corporate: true,
      booking_id: -1,
      corporate_id: -1,
      corporate_name: "",
      tenant_name: "",
      check_in: new Date(),
      check_out: new Date(),
      suite: "",
      editToggle: false,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.BugReportToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full bg-gray-600 bg-opacity-70 z-40 "
        }
      >
        <div className="  pullUp absolute font-sofia bg-white shadow-xl rounded-lg h-80 w-full max-w-md left-1/2  py-4 px-4  ">
          <div className="flex flex-col">
            <span className="font-display text-3xl text-gray-700 font-bold">
              Settings
            </span>
            <span className="font-sofia text-base text-gray-800 ">Log Out</span>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalBugReporting;
