import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/MLS/MLS MAP.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/MLS/MLS View.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/MLS/MLS View 2.png";

function MLSParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-2/3">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-1/2"
          />
          <p className="mt-3">
            Access to the parking garage is just to the left of the main
            building entrance on Bremner blvd when facing the building. This
            ramp down will lead you to a first gate; hold the black puck parking
            transponder up towards the sensor on the ceiling above the gate,
            making sure to hold the correct side up. The light on the sensor
            will turn green, and the gate will open. Proceed to the right,
            following the signs down to P2 for resident parking.
          </p>
          <p className="mt-3">
            When you reach the blue and yellow gate to resident parking, hold
            the transponder up the same way you did at the first gate and it
            will open automatically.
          </p>
          <p className="mt-3">
            Please note that once you use the remote to get into the first gate,
            you will have 10 minutes to get through the second into resident
            parking. If you do not go into resident parking after accessing the
            first gate, you will not be able to leave the parking lot and will
            need to speak with an attendant to have the remote programmed again.
          </p>
          <p className="mt-3 mb-5 md:mb-0">
            Also note that the parking remote is required to exit the garage
            each time, so please keep the remote in your vehicle at all times.
          </p>
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className=""
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-2/5">
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="h-1/2 w-full mb-5"
          />
          <p className="bg-green-400 text-white px-5 py-5">
            Point Form Summary: 
            <p className="my-3">
                <strong>
                    Entrance accessible via Bremner Blvd, to left of
                    main building entrance
                </strong>
            </p>
            <ul className="list-disc ml-5">
              <li>
                Enter the street level parking garage door and proceed down to
                P1 gates
              </li>
              <li>
                Hold transponder up to the sensor on ceiling, making sure to
                hold correct side up (noted on transponder)
              </li>
              <li>
                Proceed to the right, past the paid parking, and down to P2, to
                resident parking garage door. Door is blue with yellow posts
                around it.
              </li>
              <li>
                Hold transponder up to the sensor (the same way as the gates on
                P1) to open this door
              </li>
              <li>Proceed down to designated parking space.</li>
              <li>
                Note that you require the remote to leave the parking garage,
                please keep it in your vehicle at all times
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default MLSParking;
