import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AiOutlineDownload, AiOutlineFileDone } from "react-icons/ai";
import { BiBookOpen } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import { useNotification } from "../Notifications/NotificationProvider";
import store from "../../redux/store";
import { gridDensityValueSelector } from "@mui/x-data-grid";
import { messagePayload } from "../../utils/Types";



export const TrueARReport = (): JSX.Element => {
    const [startDate, setStartDate] = useState<string>(new Date().toISOString());
    const [arCost, setARCost] = useState<number>(0);
    const [prePaid, setPrePaid] = useState<number>(0);
    const [arCostNoRent, setARCostNoRent] = useState<number>(0);
    const [prePaidNoRent, setPrePaidNoRent] = useState<number>(0);

    const [corporateID, setCorporateID] = useState<number>(null);
    const [corporate, setCorporate] = useState<any>([]);

    //CSV
    const [arCSV, setARCSV] = useState<string>('');
    const [prePaidCSV, setPrePaidCSV] = useState<string>('');
    const [arNonRentCSV, setARNonRentCSV] = useState<string>('');
    const [prePaidNonRentCSV, setPrePaidNonRentCSV] = useState<string>('');


    const dispatch: (props: messagePayload) => void = useNotification();

    function HandleError(type: string, title: string, message: string,) {
        dispatch({
          type: type.toUpperCase(),
          title: title,
          message: message
        })
    }

    useEffect (() => {
        //Iniotialize Corporate Dropdown
        fetchCorporate();

    },[])

    const fetchCorporate = async() => {
        const corporate = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL)
        console.log(corporate)
        setCorporate(corporate.data.sort(function(firstCorp:any, secondCorp:any) {
            if(firstCorp.corporate_name < secondCorp.corporate_name) {
                return -1
            }
            if(firstCorp.corporate_name > secondCorp.corporate_name) {
                return 1
            }
            return 0
        }))
    }

    const searchTrueAR = async() => {
        HandleError(
            "Processing",
            `Fetching AR`,
            "Please wait while we process the information",
        );

        const allOutstandingAR = await fetchGetJSON(`${Constants.API_PATH_TRUEAR}?selected_date=${startDate}&corporate_id${corporateID ? `=${corporateID}` : ''}`,)

        if(allOutstandingAR.status == "Success") {
            HandleError(
                "Success",
                `Success Fetching AR`,
                "Both AR Cost and Pre Paid Results will now be Displayed",
            );
            setARCost(allOutstandingAR.arLineCost);
            setPrePaid(allOutstandingAR.prePaid);
            setARCostNoRent(allOutstandingAR.arLineCostNonRent)
            setPrePaidNoRent(allOutstandingAR.prePaidNonRent)

            //CSV Logic:
            let csvData = new Blob([allOutstandingAR.arReportCSV], { type: 'text/plain' });
            setARCSV(window.URL.createObjectURL(csvData));
            
            csvData = new Blob([allOutstandingAR.prePaidReportCSV], { type: 'text/plain' });
            setPrePaidCSV(window.URL.createObjectURL(csvData));

            csvData = new Blob([allOutstandingAR.arReportNonRentCSV], { type: 'text/plain' });
            setARNonRentCSV(window.URL.createObjectURL(csvData))

            csvData = new Blob([allOutstandingAR.prePaidNonRentCSV], { type: 'text/plain' }); 
            setPrePaidNonRentCSV(window.URL.createObjectURL(csvData))

        } else {
            HandleError(
                "Error",
                `Error Fetching AR`,
                "Please notify dev team",
            );
        } 
    }

    const handleDropdown = (event: any) => {
        setCorporateID(event.target.value)
    }
    return (
        <div>
          <div className="font-display bg-white lg:rounded-tl-lg">
            <div className="mt-10 text-center text-lg"><h1 className="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-1xl  ">AR Report</h1></div>
            <div className="fade-in px-10 mt-16 lg:mt-0 max-w-5xl pb-3 m-auto w-auto">
                <div className="flex flex-row items-center mt-10 justify-center">
                    <label className="flex-initial pl-3 mb-3 mx-6">
                        <span className="text-gray-400 text-xs">Select Date</span>
                        <input
                            id="check_in"
                            onChange={(input) => {
                                setStartDate(input.target.value);
                            }}
                            value={startDate}
                            type="date"
                            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                            placeholder=""
                        />
                    </label>
                    <button onClick={searchTrueAR}>
                        Update
                    </button>
                </div>
            </div>
            <div className="flex flex-row mt-5 items-center justify-center">
                <label className="mr-5">Select Corporate Dropdown: </label>
                <select
                    onChange={handleDropdown}
                    className="  block w-64 mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder="Select a corporate"
                >
                    <option value="" disabled selected>--Select a Corporate--</option>
                    {corporate.map((corporation:any) => (
                        <option value={corporation.id}>{corporation.corporate_name}</option>
                    ))}
                </select>
            </div>
            <div className="mt-10 text-center text-lg mb-3"><strong>Please Note:</strong> By default, no corporate is selected.<br/> If a corporate is selected, AR Cost and PrePaid Cost will change but <strong>Non-Rent Values can be ignored.</strong></div>
            <hr className="mb-3"/>
            <div className="fade-in px-10 lg:mt-0 max-w-5xl pb-6 m-auto w-auto">
                <div className="flex flex-row items-center justify-evenly">
                    <span>
                        AR Cost: <span className="text-green-500">${Math.trunc(arCost).toLocaleString('en-US')}</span>
                        <div className="hidden lg:flex w-full px-3 my-5 ">
                            <a
                                className="transition duration-100 border-2 border-solid w-36 bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                                href={arCSV}
                                download={`AR Cost for Rent Report for ${startDate}.csv`}
                            >
                                <AiOutlineDownload className="text-xl mr-1 self-center" />
                                <span className="text-xs self-center ">Download AR CSV</span>
                            </a>
                        </div>
                    </span>
                    <span>
                        Pre Paid Cost: <span className="text-green-500">${Math.trunc(prePaid).toLocaleString('en-US')}</span>
                        <div className="hidden lg:flex w-full px-3 my-5 ">
                            <a
                                className="transition duration-100 border-2 border-solid w-full bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                                href={prePaidCSV}
                                download={`Pre Paid Non-Report for ${startDate}.csv`}
                            >
                                <AiOutlineDownload className="text-xl mr-1 self-center" />
                                <span className="text-xs self-center ">Download PrePaid CSV</span>
                            </a>
                        </div>
                    </span>
                </div>              
                <div className="flex flex-row items-center justify-evenly">
                    <span>
                        AR Cost (Non-Rent): <span className="text-green-500">${Math.trunc(arCostNoRent).toLocaleString('en-US')}</span>
                        <div className="hidden lg:flex w-full px-3 my-5 ">
                            <a
                                className="transition duration-100 border-2 border-solid w-full bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                                href={arNonRentCSV}
                                download={`AR Cost Non-Rent Report for ${startDate}.csv`}
                            >
                                <AiOutlineDownload className="text-xl mr-1 self-center" />
                                <span className="text-xs self-center ">Download AR Non Rent CSV</span>
                            </a>
                        </div>
                    </span>
                    <span>
                        Pre Paid Cost (Non-Rent): <span className="text-green-500">${Math.trunc(prePaidNoRent).toLocaleString('en-US')}</span>
                        <div className="hidden lg:flex w-full px-3 my-5 ">
                            <a
                                className="transition duration-100 border-2 border-solid w-full bg-white border-green-500 hover:bg-green-100 text-green-500 font-medium py-1 rounded pr-1 flex flex-row justify-center align-center"
                                href={prePaidNonRentCSV}
                                download={`Pre Paid Non-Rent Report for ${startDate}.csv`}
                            >
                                <AiOutlineDownload className="text-xl mr-1 self-center" />
                                <span className="text-xs self-center ">Download PrePaid Non Rent CSV</span>
                            </a>
                        </div>
                    </span>
                </div>
            </div>
            <div className="mt-10 text-center text-lg">This AR Report will return back two values: AR Cost and Pre-Paid Cost based on the User's Selected Date.<br/><strong>Please Note: </strong><u>Pending Invoices</u> will <strong>NOT</strong> be included in the <u>AR Values</u> for this report.<br/><u>Pending Invoices</u> <strong>ARE</strong> included in the <u>PrePaid Values</u> for this report.</div>
            <hr className="mt-5 mx-20"/>
            <h1 className="mt-3 flex items-center justify-center text-xl font-bold">Summary of Calculations:</h1>
            <div className="text-center text-lg mt-3">An AR Cost is determined based on any invoices that have not been paid yet that SkyView has <strong>earned</strong> prior to the selected date</div>
            <div className="text-center text-lg mt-2">A PrePaid Cost is determined based on any invoices that have their paid date before {startDate.substring(0,10)} and the invoice is either ongoing or not earned yet.</div>
            <div className="text-center text-lg mt-10"><strong>Ongoing Invoices</strong> are also considered in this report.</div>
            <div className="text-center text-lg mt-2"><strong>AR Cost (if ongoing):</strong> If <span className="text-green-500">{startDate.substring(0,10)}</span> is selected, we will calculate accumulated cost from the amount of days from an ONGOING invoice's <span className="text-green-500">start date</span> to <span className="text-green-500">{startDate.substring(0,10)}</span></div>
            <div className="text-center text-lg my-2"><strong>PrePaid Cost (if ongoing):</strong> If <span className="text-green-500">{startDate.substring(0,10)}</span> is selected, we will calculate accumulated cost from <span className="text-green-500">{startDate.substring(0,10)}</span> to an ONGOING invoice's <span className="text-green-500">end date</span></div>
          </div>
        </div >
      ); 
};
