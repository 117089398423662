import React, { Component } from "react";
import { MdSearch } from "react-icons/md";
import Tooltip from "../Tooltip";
import UnitBubble from "../UnitBubble";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { FiSettings, FiPauseCircle } from "react-icons/fi";
import { FaBuilding } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { HiFire } from "react-icons/hi";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";
import { IdentityStore } from "aws-sdk";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import {listview} from "../../models/modelList";
import PageSelector from "../../utils/PageSelector";

// Notes:
type Props = {
  handleState: any;
};

type State = {
  buildings: Building[];
  search: string;
  loaded: boolean;

  curpage: number;
  perpage: number;
  totalpages: number;
  authorization: boolean;
};

type Building = {
  id: number;
  building: string;
  address: string;
  notes: string;
  heat: boolean;
};

class BuildingList extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      buildings: [],
      search: "",
      loaded: false,

      curpage: 1,
      perpage: 10,
      totalpages: 0,
      authorization: true,
    };
  }

  pageUp = async () => {
    let page = this.state.curpage + 1;
    if (page > this.state.totalpages) {
      page -= 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
  }

  pageDown = async () => {
    let page = this.state.curpage - 1;
    if (page == 0) {
      page += 1;
    }
    this.setState({
      curpage: page,
      loaded: false,
    }, async () => {
      await this.updateList();
    })
    
  }

  setPerPage = async (val: number) => {
    await this.setState({perpage: val, loaded: false,}, async () => {
      await this.updateList();
      if (this.state.curpage > this.state.totalpages) {
        this.setState({
          curpage: this.state.totalpages
        }, async () => {
          await this.updateList();
        })
      }
    })
  }

  // Fetches the whole list of buildings and populates the list
  componentDidMount = async () => {
    this.updateList();
    store.dispatch({ type: "buildingListUpdate", listUpdate: this.updateList });
  };

  updateList = async () => {
    const filter = {
      search: this.state.search.trim()
    };
    const allBuildings: listview<any[]> = await fetchGetJSON(`${Constants.API_PATH_LISTVIEW_BUILDINGS}?search${this.state.search ? `=${this.state.search.trim()}`: ''}&page=${this.state.curpage}&perpage=${this.state.perpage}`,
    // {filters: filter, page: this.state.curpage, perpage: this.state.perpage}
    )
    console.log(allBuildings)
    let buildingList = []; // Make a copy of the state invoices list so I can reset the state and rerender the page

    if (allBuildings.status != "Success") {
      this.setState({
        loaded: true,
        authorization: allBuildings.status == "Error Unauthorized" ? false : true
      })

      return;
    }
    this.setState({totalpages: allBuildings.data.pages})

    // Create building objects off of each building entry from database
    for (let building of allBuildings.data.rows) {
      let buildingObj: Building;
      buildingObj = {
        id: building.id,
        building: building.building_name,
        address: building.address,
        notes: building.svs_notes,
        heat: building.heat,
      };

      buildingList.push(buildingObj);
    }
    this.setState({ buildings: buildingList, loaded: true });
  };

  handleNext = (id: number) => {
    // console.log(id)
    store.dispatch({ type: "buildingIdTransfer", id: id });
    const { location, history } = this.props;
    if (history)
      history.push({
        pathname: location.pathname + "/buildingprofile",
        search: `id=${id}`,
      });
  };

  handleModal = (id: string) => {
    this.props.handleState(id, false);
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  
    // this.updateList(this.state.search);
  };

  handleKeyDown = (event:any) => {
    if(event.key == 'Enter') {
      this.updateList()
    }
  }

  render() {
    let TableRows = <></>;
    var rows = [];
    for (var i = 0; i < this.state.buildings.length; i++) {
      let HeatBuilding = this.state.buildings[i].heat;
      // if (!(this.state.search == "" || this.state.buildings[i].building.toLowerCase().includes(this.state.search.toLowerCase()))) { continue; }

      let blackoutStyles = "";
      let Warnings = "-";
      let blackoutToggle = (
        <HiFire
          className={
            "text-2xl text-gray-700 hover:text-gray-500  cursor-pointer "
          }
        />
      );

      if (HeatBuilding) {
        Warnings = "Heat Building";
        blackoutToggle = (
          <HiFire
            className={
              "text-2xl text-red-500 hover:text-red-400 cursor-pointer"
            }
          />
        );
      }

      let buildingId = this.state.buildings[i].id;

      TableRows = (
        <tr className="border-t border-gray-400 w-full">
          {/* Suite Name */}

          <td
            onClick={() => this.handleNext(buildingId)}
            className={" cursor-pointer lg:pl-4 px-1 py-4 text-purple-400"}
          >
            <span>{this.state.buildings[i].id}</span>
          </td>

          {/* Suite Number */}

          <td
            onClick={() => this.handleNext(buildingId)}
            className={
              " cursor-pointer  font-light text-sm text-gray-500 px-1 py-4 flex flex-row items-center"
            }
          >
            <h3 className="">{this.state.buildings[i].building}</h3>
          </td>

          {/* Min. Stay */}

          <td
            onClick={() => this.handleNext(buildingId)}
            className={
              " cursor-pointer font-thin text-gray-500 px-1 py-4 text-sm"
            }
          >
            {this.state.buildings[i].address}
          </td>

          {/* Bedrooms */}

          <td
            onClick={() => this.handleNext(buildingId)}
            className={
              "  cursor-pointer font-thin text-sm  text-gray-400 px-1  py-4"
            }
          >
            {this.state.buildings[i].notes}
          </td>

          {/* Type */}

          {/* Booking Warnings */}

          <td
            onClick={() => this.handleNext(buildingId)}
            className=" cursor-pointer w-28 text-red-400 px-1 py-4 text-sm font-semibold"
          >
            {this.state.buildings[i].heat}
          </td>

          {/* Booking Warnings */}

          <td className=" w-32 px-1 py-4  ">
            <div className="flex flex-row justify-end">
              <FiSettings
                className={
                  blackoutStyles +
                  "text-gray-700 text-2xl mr-2 hover:text-gray-500"
                }
              />
              {blackoutToggle}
            </div>
          </td>
        </tr>
      );

      rows.push(TableRows);
    }

    return (
      <div className=" slide-in-blurred-top  font-display py-4">
        <div className="flex flex-row">
          {/* Left Side */}
          <div className="flex flex-col w-2/3 sm:flex-row align-center items-center">
            {/* Search Bar */}
            <div className="flex flex-row border rounded-sm w-1/3 py-1 mb-6 sm:mb-0 h-8">
              <MdSearch className="text-xl text-gray-400 mx-1" />
              <input
                className="text-sm searchBox w-full"
                placeholder="Search by building id or name"
                id="search"
                value={this.state.search}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <button className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white" onClick={() => {this.updateList()}}>Search</button>
          </div>
          <PageSelector
              curpage={this.state.curpage}
              totalpages={this.state.totalpages}
              increase={this.pageUp}
              decrease={this.pageDown}
              setPerPage={this.setPerPage}
              perPageOptions={[10,20,30,40,50]}
            />
        </div>

        {/* Invoice Table */}
        <table className="table-auto w-full mb-8">
          <thead>
            <tr className=" hidden lg:table-row text-left font-normal text-gray-500 ">
              <th className="py-2 lg:pl-4 ">ID</th>
              <th className="py-2  ">Building </th>
              <th className="py-2  ">Address</th>
              <th className="py-2  ">Notes</th>
              <th className="py-2  ">Heat Building</th>
              {/* Options */}
              <th className="py-2  "></th>
            </tr>
          </thead>

          <tbody>{rows.length == 0 ? <LoadingGeneral count={10} cols={4} empty={this.state.loaded} authorization={this.state.authorization}/> : rows}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(BuildingList);
