import {Amplify} from "aws-amplify";
import {signOut} from "aws-amplify/auth"
import store from "../redux/store";
import { connect } from "react-redux";

// const awsmobile = {
//   aws_project_region: process.env.REACT_APP_AWS_REGION,
//   aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
//   aws_cognito_region: process.env.REACT_APP_AWS_REGION,
//   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
//   aws_user_pools_web_client_id:
//     process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
//   federationTarget: "COGNITO_USER_POOLS",
// };
// Amplify.configure(awsmobile, {
//   ssr: true
// });

const deleteCookies = () => {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

const mapStateToProps = (state: any) => ({});

export const LogOut = () => {
  signOut();
  store.dispatch({ type: "logOut", message: "Does this work for both cases" });
  localStorage.clear();
  deleteCookies();

  return <div className=" mx-auto mt-1">Loading...</div>;
};

export default connect(mapStateToProps)(LogOut);
