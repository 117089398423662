import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Calendar } from "@fullcalendar/core";
import { useEffect, useState } from "react";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { MdPlaylistAdd } from "react-icons/md";
import ToggleSwitch from "../ToggleSwitch";

function BookingCalendar() {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [bookingResources, setBookingResources] = useState([]);
  const [toggleMonth, setToggleMonth] = useState(false);

  useEffect(() => {
    fetchGetJSON(`${Constants.API_PATH_GET_BOOKING_CALENDAR_DATA}`).then(
      (resp) => {
        console.log(resp);
        setCalendarEvents(resp.events);
        setBookingResources(resp.resources[0]);
      }
    );
  }, []);
  // const resourceLabelClassNames = (resource:any) => {
  //   return resource.extendedProps.quad_status ? 'text-green-500' : '';
  // };

  const toggleMonthlyView = () => {
    setToggleMonth(!toggleMonth)
  }

  if (calendarEvents?.length && bookingResources?.length) {
    return (
      <div>
        <div className="flex flex-row mb-2 sm:pr-2">
          <span className={" transition-all duration-600 text-green-500 text-s mr-3 pl-3"}>Toggle for Monthly View</span>
          <ToggleSwitch
              id="selectDate"
              handleStateToggle={() => toggleMonthlyView()}
              className="justify-center align-center"
              size="lg"
              onoff={toggleMonth}
          />
        </div>
        <h1>Hover on either Tentative or Active Bookings to View Corporate Name, NTV, and Days Until Booking</h1>
        <h2 className="text-green-500">PLEASE NOTE: Any Unit that is higlighted green is a QUADABLE unit</h2>
        <style>
          {`
          .fc-scrollgrid thead {
            position: sticky;
            z-index: 99;
            top: 0;
            background: white;
          }
        `}
        </style>
        <FullCalendar
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          initialView="resourceTimelineMonth"
          views={{resourceTimelineMonth: {
            type: "resourceTimelineMonth",
            // duration: {days: 30}
            ...(!toggleMonth && {duration : {days: 30}})
          }}}
          selectable={true}
          events={calendarEvents}
          resources={bookingResources}
          // resourceLaneContent={resourceRender}
          resourceLabelClassNames={function (resource) {
            if(resource.resource._resource.extendedProps.isQuadable || resource.resource._resource.extendedProps.superQuad || resource.resource._resource.extendedProps.superQuadBunk) {
              return 'text-green-500'
            } else {
              return ''
            }
          }}
          resourceOrder={"title"}
          headerToolbar={{
            left: "title",
            right: "today prev,next",
          }}
          eventClick={function (info) {
            info.jsEvent.preventDefault();
            if (info.event.url) {
              window.open(info.event.url);
            }
          }}
          // Display Corporate Name, NTV, and Days til vacate if the event is an activeBooking or tentativeBooking
          eventMouseEnter={(info) => {
            let content = ""
            if (info.event.backgroundColor === "#03937F" || info.event.backgroundColor === "#7e697b") {
              const name = info.event._def.extendedProps.cName;
              const today = new Date();
              const start = info.event.start;
              const end = info.event.end;
              //NTV calculations
              const ntv = info.event._def.extendedProps.ntv;
              let ntvDate = new Date(info.event.end);
              ntvDate.setDate(ntvDate.getDate() - ntv);
              const ntvDisplay = ntvDate.toLocaleString('default', {month: 'long'}) + " " + ntvDate.getDate() + ", " + ntvDate.getFullYear();

              //If event has ended, output days since vacate. If event started, output days til vacate. Otherwise, output days til event starts
              if (end < today) {
                const daysSinceVacate = Math.round((today.getTime() - end.getTime())/(1000*60*60*24));
                info.el.title = "Corporate Name: "+ name + "\n NTV: "+ntvDisplay + "\n Days Since Vacate: "+ daysSinceVacate;
                content = info.el.title
              }
              else if (start <= today) {
                const daysTilVacate = Math.round((end.getTime() - today.getTime())/(1000*60*60*24));
                info.el.title = "Corporate Name: "+ name + "\n NTV: "+ntvDisplay + "\n Days Until Vacate: "+ daysTilVacate;
                content = info.el.title
              } else {
                const daysTilStart = Math.round((start.getTime() - today.getTime())/(1000*60*60*24));
                info.el.title = "Corporate Name: "+ name + "\n NTV: "+ntvDisplay + "\n Days Until Start: "+ daysTilStart;
                content = info.el.title
              }
            } else if (info.event.backgroundColor === "#374151") {
              info.el.title = "Blackout Reason: " + info.event._def.extendedProps.reason;
              content = info.el.title
            }

            showTooltip(info.jsEvent, content);
          }}
          eventMouseLeave={hideTooltip}
        />
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

//Playing aroudn with css for hover, code below can be removed if necessary
function showTooltip(event: MouseEvent, content: string) {
  let tooltip = document.getElementById("custom-tooltip");
  if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.id = "custom-tooltip";
      tooltip.className = "tooltip";
      document.body.appendChild(tooltip);
  }
  tooltip.textContent = content;
  tooltip.style.left = event.pageX + "px";
  tooltip.style.top = (event.pageY + 10) + "px";
  tooltip.style.display = "block";
}

function hideTooltip() {
  const tooltip = document.getElementById("custom-tooltip");
  if (tooltip) {
      tooltip.style.display = "none";
  }
}

function resourceRender(eventInfo:any) {
  console.log(eventInfo)
  // console.log(eventInfo.resource._resource)
  // if(eventInfo.resource._resource.quad_status) {
  //   return (
  //     <div>
  //       <span className="text-green-500">{eventInfo.resource._resource.title}</span>
  //     </div>
  //   )
  // } else {
  //   return (
  //     <div>
  //       <span>{eventInfo.resource._resource.title}</span>
  //     </div>
  //   )
  // }
}
  
export default BookingCalendar;

