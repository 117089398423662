import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


export default function PageSelector(props: {
  curpage: number;
  totalpages: number;
  increase: any;
  decrease: any;
  setPerPage: any;
  perPageOptions: number[];
}) {
  return (
    <div
      className="ml-auto flex items-center"
    >
      <select
        onChange={(event: any) => {
          props.setPerPage(event.target.value)
        }}
      >
        {props.perPageOptions.map((val: number) => <option value={val} key = {val.toString()}>{val}</option>)}
      </select>
      <button
        className="bg-white rounded-full shadow-md h-6 w-6 ml-4"
        onClick={props.decrease}
      >
        <IoIosArrowBack className="pl-1" />
      </button>
      <span className="px-4 text-gray-500">{props.curpage} of {props.totalpages}</span>
      <button
        className="bg-white rounded-full shadow-md h-6 w-6 flex flex-row items-center justify-center align-center"
        onClick={props.increase}
      >
        <IoIosArrowForward className="text-sm" />
      </button>
    </div>
  )
}