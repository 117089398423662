import { Component } from "react";
import { BsFillFlagFill } from "react-icons/bs";
import { FiCornerUpRight } from "react-icons/fi";
import { HiPause } from "react-icons/hi";
import { BsPersonFill } from "react-icons/bs";
import { RiExchangeDollarLine } from "react-icons/ri";
import { ImCancelCircle } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { TiBusinessCard } from "react-icons/ti";
import { BiMailSend, BiMoney } from "react-icons/bi";
import { AiOutlineFileDone } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { HiFire } from "react-icons/hi";
import { fetchRequestJSON, fetchGetJSON } from "../api-requests/apiCallHelper";
import { Constants } from "../api-requests/apiLinkConstants";
import store from "../redux/store";
import { withHooksHOC } from "../components/Notifications/NotificationProvider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Modal from "./Modal";
import { getCurrentUser } from "aws-amplify/auth";

type Props = {
  handleFlag: any;
  handlePay?: any;
  parentData: any;
  panelType: string;
  handleEditBarToggle: any;
  handlePopup: any
  handleState: any;
  ErrorNote: any;
  history: any;
  cancelModalToggle?: any;
};

type EditState = {
  cancelModalToggle: boolean;
  invoice_paid: boolean;
  invoiceClientLink: string;
  authId: string;
}

class EditOptionPanel extends Component<Props & RouteComponentProps, EditState> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      cancelModalToggle: false,
      invoice_paid: this.props.parentData.invoice_paid,
      invoiceClientLink: "",
      authId: "",
    }
  }

  componentDidMount = async () => {
    if (this.props.panelType === "Invoice") {
      let link = await fetchGetJSON(Constants.API_PATH_INVOICE_URL + store.getState().invoiceReducer.curId);
      if (link.data) {
        console.log(link.data)
        this.setState({
          invoiceClientLink: link.data
        });
      }
      this.setState({ invoice_paid: this.props.parentData.invoice_paid });
    }
    else if (this.props.panelType === "Consolidated") {
      let link = await fetchRequestJSON("GET", `${Constants.API_PATH_CONSOLIDATED_LINK}${store.getState().consolidatedInvoiceReducer.curId}`);
      if (link.data) {
        this.setState({
          invoiceClientLink: link.data
        });
      }
      this.setState({ invoice_paid: this.props.parentData.paid });
    }
    try {
      let res = await getCurrentUser();
      this.setState({ authId: res.username })
    } catch {

    }
  }

  handleModal = (id: string) => {
    console.log("handle state", id)
    this.props.handleState(id, false);
  };

  handleCancelModal = () => {
    if (this.state.cancelModalToggle) {
      this.setState({ cancelModalToggle: false });
    } else {
      this.setState({ cancelModalToggle: true });
    }
  }

  confirmCancelModal = async () => {
    let NotificationHandler = this.props.ErrorNote;
    const { location, history } = this.props;
    let path = location.pathname.split("/");
    const cancelBookingStatus = await this.cancelBookingModel(
      Constants.API_PATH_BOOKING_CANCEL
    );
    // console.log(cancelBookingStatus)
    if (cancelBookingStatus.status === "Success") {
      NotificationHandler("Success", `Successfully Deleted Booking`, "");
      let bookingId = new URLSearchParams(window.location.search).get("id");
      if (bookingId) {
        history.push(`/${path[1]}/bookings`);
      } else {
        let update = store.getState().bookingReducer.listUpdate;
        update();
        this.props.handlePopup()
      }
    } else {
      NotificationHandler(
        "Warning",
        `Booking Deletion Unsuccessful`,
        "Already deleted booking"
      );
      let bookingId = new URLSearchParams(window.location.search).get("id");
      if (bookingId) {
        history.push(`/${path[1]}/bookings`);
      } else {
        let update = store.getState().bookingReducer.listUpdate;
        update();
        this.props.handlePopup()
      }
    }
  }

  // Delete all the corporate contacts
  deleteCorpContacts = async () => {
    console.log(this.props.parentData)
    let allContacts = await fetchGetJSON(
      Constants.API_PATH_CORP_CONTACT_GET_ALL
    );
    // console.log("allContacts: " + allContacts.data)

    for (let i = 0; i < allContacts.data.length; i++) {
      if (allContacts.data[i].corporate_id === this.props.parentData.id) {
        const contactDelete = await fetchRequestJSON(
          "DELETE",
          `${Constants.API_PATH_CORP_CONTACT_CANCEL}/${Number(new URLSearchParams(window.location.search).get("contact_id"))}`,
        );
        console.log(contactDelete);
      }
    }
    // return corpData.id;
  };

  cancelModel = async (link: string, cancelId?: number) => {
    let id = cancelId
      ? cancelId
      : Number(new URLSearchParams(window.location.search).get("id"));

    const res = await fetchRequestJSON("DELETE", `${link}/${id}`, {
      id: id,
    });
    // console.log(res)
    return res;
  };

  cancelBookingModel = async (link: string) => {
    let bookingId = new URLSearchParams(window.location.search).get("id");
    bookingId = bookingId ? bookingId : store.getState().bookingReducer.curId;
    const res = await fetchRequestJSON("PUT", link, {
      id: bookingId,
    });
    // console.log(res)
    return res;
  };

  // Delete button functionality
  handleClick = async () => {
    let NotificationHandler = this.props.ErrorNote;
    const { location, history } = this.props;
    let path = location.pathname.split("/");

    // Delete function if this panel exists on corporate page
    // NO LONGER USED, now done through confirmation modal

    // if (this.props.panelType === "Corporate") {
    //   // console.log("this is being called")
    //   this.deleteCorpContacts();
    //   const cancelCorpStatus = await this.cancelModel(
    //     Constants.API_PATH_CORP_CANCEL,
    //     this.props.parentData.id
    //   );
    //   if (cancelCorpStatus.status === "Success") {
    //     NotificationHandler("Success", `Successfully Deleted Corporate`, "");
    //     history.push(`/${path[1]}/contacts`);
    //   } else {
    //     NotificationHandler(
    //       "Warning",
    //       `Corporate Deletion Unsuccessful`,
    //       "Already deleted corporate"
    //     );
    //     history.push(`/${path[1]}/contacts`);
    //   }
    // }

    if (this.props.panelType === "Booking") {
      this.setState({ cancelModalToggle: true });
    }

    // Delete function if this panel exists on unit owner page
    // if (this.props.panelType === "Owner") {
    //   // Find unit owner from list entry click from owner list
    //   // console.log("Delete owner clicked")

    //   const cancelOwnerStatus = await this.cancelModel(
    //     Constants.API_PATH_OWNER_CANCEL
    //   );
    //   // console.log(cancelOwnerStatus.status)
    //   if (cancelOwnerStatus.status === "Success") {
    //     NotificationHandler("Success", `Successfully Deleted Owner`, "");
    //     history.push(`/${path[1]}/units`);

    //     // alert(cancelOwnerStatus.status);
    //   } else {
    //     NotificationHandler(
    //       "Warning",
    //       `Owner Deletion Unsuccessful`,
    //       "Already deleted owner"
    //     );
    //     history.push(`/${path[1]}/units`);
    //   }
    // }

    if(this.props.panelType === "Parking Spot") {
      const id =  Number(new URLSearchParams(window.location.search).get("id"));

      const res = await fetchRequestJSON("PUT", Constants.API_PATH_PARKING_SPOT_DEACTIVATION, {
        parking_spot_id: id,
      });

      if(res.status == "Success") {
        //Indicate notification handler than close the modal 
        NotificationHandler('Success', 'Success', `Parking ID ${id} succesfully deactivated`)
        //Redux below:
        let update = store.getState().parkingSpotReducer.profileUpdate;
        update()

        //Need to close the modal 
      } else {
          NotificationHandler('Error', 'Error', 'There are active or upcoming parkings occupying the parking spot. Please remove them in order for deactivation to proceed.')
          //Need to close the modal 
      }
    }

    if (this.props.panelType === "Invoice") {
      NotificationHandler(
        "Checking Invoice",
        `Checking to see if Invoice Payment Method Exist and if File Exist`,
      );
      let id = this.props.parentData.invoice_id;
      const fileInvoice =  await fetchGetJSON(`${Constants.API_PATH_FILES_LIST}?id=${id}&category=invoice`)
      console.log(this.props.parentData);
      if(this.props.parentData.payment_method == "TBD" || fileInvoice.data.Contents.length <= 0) {
        //Don't allow for marking of paid
        NotificationHandler(
          "Warning",
          `Invoice Not Eligible to be Marked as Pending`,
          `Invoice is either missing a payment method (cannot be TBD) or is missing a file for receipt`
        );
        return
      }

      let res: any;
      if (!this.state.invoice_paid) {
        console.log("MARK")
        res = await fetchRequestJSON("PUT",
          `${Constants.API_PATH_INVOICE_MARK_PAID}${id}`,
          { authId: this.state.authId });
      } else {
        console.log("UNMARK")
        res = await fetchRequestJSON("PUT",
          `${Constants.API_PATH_INVOICE_UNMARK_PAID}${id}`,
          {
            authId: this.state.authId,
            reason: "placeholder",
          });
        console.log(res)
      }
      if (res.status === "Success") {
        this.togglePay();
        this.props.handlePay();
        const update = store.getState().invoiceReducer.listUpdate;
        update();
      } else {
        console.log(res)
      }
    }

    if (this.props.panelType === "Consolidated") {
      console.log("about to mark as paid");
      let consolidatedId = this.props.parentData.consolidatedId;
      console.log("auth id")
      console.log(this.state.authId);
      let res = await fetchRequestJSON("POST", `${Constants.API_PATH_CONSOLIDATED_MARK_PAID}${consolidatedId}`, { authId: this.state.authId })
      console.log("response: ");
      console.log(res);
      if (res.status === "Success") {
        this.togglePay();
        this.props.handlePay();
      }
    }
  };

  togglePay = () => {
    // let toggle = this.state.invoice_paid;
    // this.setState({ invoice_paid: !toggle });
  }

  render() {
    let flagColour;

    if (this.props.parentData.flagged === true) {
      flagColour = "text-red-400 hover:text-red-400";
    } else {
      flagColour = "text-gray-500 group-hover:text-gray-400 ";
    }

    let PanelType = this.props.panelType;
    let PanelArray = [
      <button
        key={this.props.parentData.flagged}
        className="mx-2 transition-all duration-300 group pb-1"
      >
        <span
          className={
            " text-gray-500 font-display  group-hover:text-gray-400 text-xs mx-auto "
          }
        >
          Flag
        </span>
        <BsFillFlagFill
          key={this.props.parentData}
          onClick={this.props.handleFlag}
          className={flagColour + "  text-xl  mx-auto"}
        />
      </button>,

      <button
        onClick={() => this.handleModal("toggleTransfer")}
        key={"Transfer"}
        className="mx-2 group pb-1"
      >
        <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
          Transfer
        </span>
        <FiCornerUpRight className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
      </button>,

      <button
        onClick={() => this.handleModal("toggleRate")}
        key={"Rate"}
        className="mx-2 group pb-1"
      >
        <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
          Rate
        </span>
        <RiExchangeDollarLine className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
      </button>,

      <button
        // onClick={() => this.handleModal("toggleCancel")}
        onClick={() => this.props.cancelModalToggle()}
        //onClick={() => this.handleClick()}
        key={"Cancel"}
        className="mx-2 group pb-1"
      >
        <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
          Cancel
        </span>
        <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
      </button>,

      <button
        key={"Edit"}
        onClick={this.props.handleEditBarToggle}
        className="mx-2 group pb-1"
      >
        <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
          Edit
        </span>
        <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
      </button>,
    ];

    if (PanelType === "Unit") {
      PanelArray = [
        // Maybe also use this button for cancelling since it is a pause button thing?

        // This needs to be deactivate instead
        // <button
        //   onClick={() => {
        //     this.handleModal("deleteToggle");
        //     //this.handleClick();
        //   }}
        //   key={"Delete"}
        //   className="mx-2 group pb-1"
        // >
        //   <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
        //     Delete
        //   </span>
        //   <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        // </button>,

        PanelArray[4],
      ];
    }

    if (PanelType === "Parking Spot") {
      PanelArray = [
        // Maybe also use this button for cancelling since it is a pause button thing?
        <button
          onClick={() => {
            this.props.handleState()
            //this.handleClick();
          }}
          key={"Delete"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Delete
          </span>
          <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        </button>,

        PanelArray[4],
      ];
    }

    if (PanelType === "Lead") {
      PanelArray = [
        <button
          onClick={this.props.handleFlag}
          key={"Flag"}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Flag
          </span>
          <BsFillFlagFill className={flagColour + "   text-xl  mx-auto"} />
        </button>,

        <button key={"Corporate"} className="mx-2 group pb-1">
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Corporate
          </span>
          <TiBusinessCard className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
        </button>,

        <button
          onClick={() => this.handleModal("toggleDelete")}
          key={"Delete"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Delete
          </span>
          <FaTrashAlt className="text-red-400 group-hover:text-red-300 text-lg  mx-auto" />
        </button>,

        PanelArray[4],
      ];
    }

    if (PanelType === "Invoice") {

      PanelArray = [
        <button
          key={"Paid"}
          onClick={() => this.handleClick()}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Mark as Paid
          </span>
          <BiMoney className={"text-green-500 group-hover:text-green-400 text-xl  mx-auto"} />
        </button>,

        <button
          key={"View"}
          onClick={this.props.handleFlag}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Flag
          </span>
          <BsFillFlagFill className={flagColour + "   text-xl  mx-auto"} />
        </button>,

        <button
          onClick={() => this.handleModal("toggleSend")}
          key={"Send"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Send
          </span>
          <BiMailSend className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
        </button>,

        <button onClick={() => { window.location.href = (this.state.invoiceClientLink) }} className="mx-2 group pb-1" key={"Client"}>
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Client
          </span>
          <AiOutlineFileDone className="text-gray-500 group-hover:text-gray-400 text-xl mx-auto" />
        </button>,

        <button
          onClick={() => this.handleModal("toggleCancel")}
          key={"Delete"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Delete
          </span>
          <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        </button>,

        PanelArray[4],
      ];
    }

    if (PanelType === "Consolidated") {
      PanelArray = [
        <button
          key={"Paid"}
          onClick={() => this.handleClick()}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Mark as Paid
          </span>
          <BiMoney className={"text-green-500 group-hover:text-green-400 text-xl  mx-auto"} />
        </button>,

        <button
          key={"View"}
          onClick={this.props.handleFlag}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Flag
          </span>
          <BsFillFlagFill className={flagColour + "   text-xl  mx-auto"} />
        </button>,

        <button onClick={() => { window.location.href = (this.state.invoiceClientLink) }} className="mx-2 group pb-1" key={"Client"}>
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Client
          </span>
          <AiOutlineFileDone className="text-gray-500 group-hover:text-gray-400 text-xl mx-auto" />
        </button>,

        <button
          onClick={() => this.handleModal("toggleCancel")}
          key={"Delete"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Delete
          </span>
          <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        </button>,
      ];
    }

    if (PanelType === "Owner") {
      PanelArray = [
        // <button
        //   onClick={() => this.handleModal("deleteToggle")}
        //   key={"Delete"}
        //   className="mx-2 group pb-1"
        // >
        //   <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
        //     Delete
        //   </span>
        //   <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        // </button>,

        PanelArray[4],
      ];
    }

    if (PanelType === "Corporate Contact") {
      PanelArray = [
        <button
          onClick={() => this.handleModal("deleteToggle")}
          key={"Delete"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Delete
          </span>
          <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        </button>,
        <button
        key={"Edit"}
        onClick={this.props.handleEditBarToggle}
        className="mx-2 group pb-1"
      >
        <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
          Edit
        </span>
        <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
      </button>
      ];
    }

    if (PanelType === "Building") {
      PanelArray = [
        <button
          key={this.props.parentData.flagged}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Heat Building
          </span>

          <HiFire
            key={this.props.parentData}
            onClick={this.props.handleFlag}
            className={flagColour + "   text-xl  mx-auto"}
          />
        </button>,

        <button
          onClick={() => this.handleModal("toggleCancel")}
          key={"Delete"}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Delete
          </span>
          <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
        </button>,

        PanelArray[4],
      ];
    }

    if (PanelType === "Corporate") {
      // include flag, cancel, and edit only
      PanelArray = [
        <button
          key={this.props.parentData.flagged}
          className="mx-2 group pb-1 "
        >
          <span className=" font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Flag
          </span>
          <BsFillFlagFill
            key={this.props.parentData}
            onClick={this.props.handleFlag}
            className={flagColour + "   text-xl  mx-auto"}
          />
        </button>,

        <button
          key={"Edit"}
          onClick={this.props.handleEditBarToggle}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Edit
          </span>
          <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
        </button>,
      ];
    }

    //100% Needs to be refactored better, ask Newman about this --> This is for edit booking portal consolidation 
    if(this.props.panelType === "Booking") {
      PanelArray = [  
        <button
          key={"Edit"}
          onClick={this.props.handleEditBarToggle}
          className="mx-2 group pb-1"
        >
          <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
            Edit Booking
          </span>
          <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
        </button>,
      ];

      return (
        <div className="w-auto px-4  flex flex-row items-center justify-around shadow-none h-16 rounded-full lg:px-0  lg:rounded-none   lg:shadow-none bg-gray-100  lg:justify-end lg:bg-transparent ">
          {PanelArray}
        </div>
      );
    }

    return (
      <div className="w-auto px-4  flex flex-row items-center justify-around shadow-none h-16 rounded-full lg:px-0  lg:rounded-none   lg:shadow-none bg-gray-100  lg:justify-end lg:bg-transparent ">
        {PanelArray}
      </div>
    );
  }
}

export default withRouter(withHooksHOC(EditOptionPanel));