import React, { Component } from "react";
import PropTypes from "prop-types";

type Props = {
  warning: boolean;
  label: string;
  onClick: Function;
  activeTab: string;
};

type State = {
  warning: boolean;
};

class Tab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      warning: this.props.warning,
    };
  }

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    warning: PropTypes.bool.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    let warningPing = [];
    let warning = this.state.warning;

    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let tabClass =
      "transition-transform relative text-gray-400 duration-200 font-display font-regular pb-2 pt-2 inline-block list-none px-2 mr-4 cursor-pointer";
    let tabAdd = " border-green-500  border-b-4 text-green-500 font-bold";
    if (activeTab === label) {
      tabClass = tabClass.concat(tabAdd);
    }

    if (warning === true) {
      warningPing.push(
        <div>
          <div className="absolute right-0 h-3 w-3 bg-yellow-500  border-solid border-2 border-white rounded-full "></div>
          <div className="animate-ping  absolute right-0 h-3 w-3 bg-yellow-400  border-solid border-2 border-white rounded-full "></div>
        </div>
      );
    }

    return (
      <li
        style={{ marginBottom: "-2px" }}
        className={tabClass}
        onClick={onClick}
      >
        {warningPing}
        {label}
      </li>
    );
  }
}

export default Tab;
