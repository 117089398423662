import { Component } from "react";
import { CgProfile } from "react-icons/cg";
import { BsFillFlagFill } from "react-icons/bs";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import store from "../../../redux/store";
import { Constants } from "../../../api-requests/apiLinkConstants";

const unitDataApiPath = "/unit/get-all";
const ownerDataApiPath = "/unit-owner/get-all";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.
type Props = {
  history: any;
  unitId: any;
};

type State = {
  booking_id: number;
  check_in: Date;
  check_out: Date;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  tenant_phone: number;
  admin_contact: string;
  finance_contact: string;
  cleaning_contact: string;
  editToggle: boolean;
  key_count: number;
  fob_count: number;
  park_fob_count: number;
  mail_key: number;
  outsanding_invoice: number;
  monthly_rate: number;
  toggleAgreement: boolean;
  dick: boolean;
  owner_name: string;
  owner_id: number;
  owner_email: string;
  owner_phone: number;
};

class OwnerContact extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: -1,
      check_in: new Date(),
      check_out: new Date(),
      corporate_id: "",
      tenant_name: "",
      tenant_email: "",
      admin_contact: "",
      finance_contact: "",
      cleaning_contact: "",
      tenant_phone: 0,
      editToggle: false,
      key_count: 0,
      fob_count: 0,
      park_fob_count: 0,
      mail_key: 0,
      outsanding_invoice: 0,
      monthly_rate: 0,
      toggleAgreement: false,
      dick: false,
      owner_email: "",
      owner_name: "",
      owner_id: -1,
      owner_phone: 0,
    };
  }
  componentDidMount = async () => {
    // let unitId = store.getState().unitReducer.curId;
    let unitId = this.props.unitId;
    let unitData = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${unitId}`)
    let ownerData = await fetchGetJSON(`${Constants.API_PATH_OWNER_GET}${unitData.data.unit_owner_id}`)
    // console.log(ownerData)
    // make sure owner exists (isnt deleted)
    this.setState({
      owner_email: ownerData.data.email,
      owner_name: `${ownerData.data.first_name} ${ownerData.data.last_name}`,
      owner_phone: ownerData.data.phone_number,
      owner_id: ownerData.data.id,
    });
  };

  handleProfileClick = (id: number) =>{
    console.log("Click");
    store.dispatch({ type: "ownerIdTransfer", id: id });
    console.log(id);
    const { history } = this.props;
    const location = window.location;
    if (history)
      history.push({
        pathname: location.pathname + `/ownerprofile/`,
        search: `id=${id}`,
      });
  };

  render() {
    let flagState = "text-gray-200";
    if (this.state.dick === true) {
      flagState = "text-red-500";
    }

    return (
      <div className=" slide-in-blurred-top font-display  px-2 py-4">
        <h1 className=" text-2xl font-bold my-1">Owner</h1>
        <table className=" hidden lg:table table-auto w-full mb-8 ">
          <thead>
            <tr className="text-left text-gray-500 ">
              <th className="py-2 font-thin ">ID</th>
              <th className="py-2 font-thin ">Full Name</th>
              <th className="py-2 font-thin ">Email </th>
              <th className="py-2 font-thin ">Phone</th>
              <th className="py-2 font-thin ">Profile</th>
              <th className="py-2 font-thin ">Flagged?</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t-2 text-gray-800  ">
              <td className="  text-sm lg:text-md text-purple-400 px-1 py-4">
                {this.state.owner_id}
              </td>
              <td className="  text-sm lg:text-md  px-1 py-4 text-gray-500">
                {this.state.owner_name}
              </td>
              <td className=" font-thin text-xs lg:text-sm text-gray-600 px-1  py-4">
                {this.state.owner_email}
              </td>
              <td className="  text-xs lg:text-sm px-1  py-4">
                {this.state.owner_phone}
              </td>
              <td className="  text-xs lg:text-sm px-1  "
                onClick={()=> this.handleProfileClick(this.state.owner_id)}>
                <CgProfile className="text-2xl transition-all cursor-pointer text-gray-700 hover:text-gray-300" />{" "}
              </td>
              <td className="  text-xs lg:text-sm px-1  py-4">
                <BsFillFlagFill
                  className={
                    flagState +
                    " text-2xl transition-all cursor-pointer  hover:text-gray-300"
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className=" flex flex-col lg:hidden my-3">
          <span className="font-thin text-xs text-gray-400">Full Name</span>
          <span className="font-semibold  text-gray-800">
            {this.state.tenant_name}
          </span>
          <div className="w-full border-b my-3"></div>

          <span className="font-thin text-xs text-gray-400">Email</span>
          <span className="font-semibold  text-gray-800">
            {this.state.tenant_email}
          </span>
          <div className="w-full border-b my-3"></div>

          <span className="font-thin text-xs text-gray-400">Phone</span>
          <span className="font-semibold  text-gray-800">
            {this.state.tenant_phone}
          </span>
          <div className="w-full border-b my-3"></div>
        </div>
      </div>
    );
  }
}

export default OwnerContact;
