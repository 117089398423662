import { Component } from "react";
import { render } from "react-dom";
type Props = {
    type: string;
    size: string;
};

class Loading extends Component<Props, {}> {
    constructor(props: Props) {
        super(props);
    }


    render() {
        if (this.props.type === "pacman") {
            return (
            <div className={`flex justify-center items-center ${this.props.size == "large" ? "mr-16" : "mr-8"}`}>
                <div className={`la-pacman la-dark ${this.props.size == "large" ? "la-2x" : ""}`}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div >)
        }
        else if (this.props.type === "spiral") {
            return (
                <div className={`flex justify-center items-center ${this.props.size == "large" ? "la-2x" : ""}`}>
                    <div className="la-ball-spin-clockwise la-dark">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>)

        } else if (this.props.type === "big-spiral") {
            return (
                <div className="flex justify-center items-center">
                    <div className={`la-ball-spin-clockwise la-green ${this.props.size == "3x-large" ? "la-3x" : ""}`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )
        }
    }
}
export default Loading;
