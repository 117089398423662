import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import blackoutReducer from "./blackoutReducer";
import bookingReducer from "./bookingReducer";
import buildingReducer from "./buildingReducer";
import checkInReducer from "./checkInReducer";
import cleaningReducer from "./cleaningReducer";
import consolidatedInvoiceReducer from "./consolidatedReducer";
import corpContactReducer from "./corpContactReducer";
import fileReducer from "./fileReducer";
import financeReducer from "./financeReducer";
import invoiceReducer from "./invoiceReducer";
import reducer from "./loginReducer";
import { parkingReducer } from "./parkingReducer";
import { parkingSpotBlackoutReducer } from "./parkingSpotBlackoutReducer";
import { parkingSpotReducer } from "./parkingSpotReducer";
import registerReducer from "./registerReducer";
import tenantReducer from "./tenantReducer";
import unitOwnerReducer from "./unitOwnerReducer";
import unitReducer from "./unitReducer";
import userReducer from "./userReducer";

export const rootReducer = combineReducers({
    reducer,
    registerReducer,
    authReducer,
    unitOwnerReducer,
    unitReducer,
    bookingReducer,
    invoiceReducer,
    consolidatedInvoiceReducer,
    corpContactReducer,
    buildingReducer,
    tenantReducer,
    fileReducer,
    blackoutReducer, 
    userReducer,
    checkInReducer,
    cleaningReducer,
    financeReducer,
    parkingSpotReducer,
    parkingSpotBlackoutReducer,
    parkingReducer
  });

export type RootState = ReturnType<typeof rootReducer>;