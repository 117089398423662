import React, { useState } from "react";
import AWS from "aws-sdk";
import { fetchGetJSON, fetchRequestJSON, } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import Files from "./Files";
import { IoMdAdd } from "react-icons/io";

const REGION = process.env.REACT_APP_AWS_REGION;
const axios = require("axios");

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ID,
// });

export function BookingFiles(props: any) {
  const hiddenFileInput = React.useRef(null);

  const handleClick = async (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event: any) => {
    const fileUploaded = event.target.files[0];
    uploadFile(fileUploaded);
  };

  const uploadFile = async (file: any) => {
    const form = new FormData();
    form.append("id", new URLSearchParams(window.location.search).get("id"));
    form.append("category", "booking");
    form.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_AWS_API_GATEWAY_LINK}${Constants.API_PATH_FILES_UPLOAD}`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response: any) {
        //handle success
        alert("Success");
      })
      .catch(function (response: any) {
        //handle error
        alert("Fail To Upload");
      });
    const update = store.getState().fileReducer.listUpdate;
    update();
  };

  return (
    <div className=" slide-in-blurred-top  font-display border px-8 py-4 mt-5">
      <h1 className="text-lg text-gray-700">Files:</h1>

      {/* TODO: Insert a search bar for the files tab... reuse the search bar from the bookings page? */}

      {/* Invoice Table */}
      <table className="table-auto w-full mb-8">
        <thead>
          <tr className="text-left text-gray-500 ">
            <th className="py-3 font-thin border-b border-gray-400 ">File Name</th>
            <th className="py-3 text-center  font-thin border-b border-gray-400 ">
              <button
                onClick={handleClick}
                className=" ml-auto bg-green-500 hover:bg-green-400 h-10 w-10 rounded-full flex flex-col justify-center items-center"
              >
                <IoMdAdd className="text-white text-xl " />
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </th>
          </tr>
        </thead>

        <tbody className="divide-y">
          <Files />
        </tbody>
      </table>
    </div>
  );
}
export default BookingFiles;
