import { Component } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";
import { IoIosArrowBack } from "react-icons/io";
import { BsFillPersonPlusFill } from "react-icons/bs";
import ToggleSwitch from "../ToggleSwitch";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
};

type ModalState = {
  title: string;
  onoff: boolean;
  owner_name: string;
  owner_phone: string;
  owner_email: string;
  do_not_disturb: boolean;
  owner_notes: string;
  chargeBack: number;
};

class NewOwner extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      owner_name: "",
      owner_phone: "",
      owner_email: "",
      do_not_disturb: false,
      owner_notes: "",
      chargeBack: 0,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  // Create owner button functionality
  handleClick = async (event: any) => {
    // Close popup form
    event.preventDefault();
    // Create request body for unit owner create POST call
    let NotificationHandler = this.props.ErrorNote;

    const ownerCreateBody = {
      first_name: this.state.owner_name.split(" ")[0],
      last_name: this.state.owner_name.split(" ")[1],
      email: this.state.owner_email.length ? this.state.owner_email : 'matt@skyviewsuites.com',
      phone_number: this.state.owner_phone,
      owner_notes: this.state.owner_notes,
      do_not_disturb: this.state.do_not_disturb,
      chargeback: this.state.chargeBack,
    };

    if (
      ownerCreateBody.first_name &&
      ownerCreateBody.last_name &&
      ownerCreateBody.phone_number
    ) {

      const createOwnerStatus = await this.createOwner(ownerCreateBody);

      if (createOwnerStatus.status === "Success") {
        let update = store.getState().unitOwnerReducer.listUpdate;
        update();
        this.props.handleModalToggle();
        NotificationHandler(
          "Success",
          `Successfully Added ${ownerCreateBody.first_name}`,
          ""
        );
      }
    } else {
      NotificationHandler(
        "Warning",
        "Required Fields Missing",
        "Please fill out the following fields: Owner Name , Phone, Email "
      ); //Types : SUCCESS, ERROR, INFO, WARNING
    }
  };

  createOwner = async (ownerCreateBody: any) => {
    return await fetchRequestJSON(
      "POST",
      Constants.API_PATH_OWNER_CREATE,
      ownerCreateBody
    );
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    // let inactive = "";

    // if (this.state.building === ''){
    //   inactive = "opacity-50 pointer-events-none "
    // }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          {/* <IoIosArrowBack className="text-white text-3xl absolute transform -translate-x-28 -translate-y-6" /> */}
          <BsFillPersonPlusFill className="text-gray-600 text-3xl mb-3" />

          <span className="text-xl font-bold text-gray-800 text-left">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form>
            <div className="grid grid-cols-3 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  <b>Owner Name*:</b>
                </span>
                <input
                  id="owner_name"
                  onChange={this.handleInputChange}
                  value={this.state.owner_name}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="eg. Smiles Davis"
                />
              </label>

              <label className="block ">
                <span className="text-gray-400 text-xs">
                  <b>Phone*:</b>
                </span>
                <input
                  id="owner_phone"
                  onChange={this.handleInputChange}
                  value={this.state.owner_phone}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="###"
                />
                {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
              </label>

              <label className="block ">
                <span className="text-gray-400 text-xs">
                  <b>Email*:</b>
                </span>
                <input
                  id="owner_email"
                  onChange={this.handleInputChange}
                  value={this.state.owner_email}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="eg. smiles.davis@gmail.com"
                />
                {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
              </label>
            </div>
            <div className="grid grid-cols-3 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  <b>Chargeback Deductible:</b>
                </span>
                <input
                  id="chargeBack"
                  onChange={this.handleInputChange}
                  value={this.state.chargeBack}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                />
              </label>
            </div>

            <label className="block  mb-5 mt-2">
              <span className="text-gray-400 text-xs">Additional Notes</span>
              <textarea
                id="owner_notes"
                onChange={this.handleInputChange}
                value={this.state.owner_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>

            <div className={" block  w-44 my-5 "}>
              <div className="flex flex-row items-center justify-between ">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Do Not Disturb
                </span>

                <ToggleSwitch
                  id="do_not_disturb"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.do_not_disturb}
                />
              </div>
            </div>

            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={this.handleClick}
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Add Owner
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewOwner);
