import AWS from "aws-sdk";
import sigV4Client from "./sigV4Client";
import { get, post, del, put } from "@aws-amplify/api";
import { returnJWTToken } from "../login/LoginForm";
import Body from "../components/Units/InfoSheetComponents/Body";
import { AuthenticationErrorMessage } from "../utils/AuthenticationErrorMessage";

// GET requests with authorization
export async function fetchGetJSON(path: string) {
  // let method = "GET";
  // let headers = {
  //   "Content-Type": "application/json",
  //   "Accept": "application/json",
  //   /* "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Credentials": "true",
  //   "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  //   "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" */
  // };

  // const restOperation = get({
  //   'Admin System',
  //   path,
  //   {}
  // })
  // return await restOperation.response;
  const idToken = await returnJWTToken();

  console.log("In API Fetch Get JSON")
  const apiName = 'Admin System';
  const options = {
      headers: {
        "X-Cognito-Token": idToken
      } 
  };

  try {
    const restOperation = get({apiName, path, options})    
    const { body } = await restOperation.response;
    // consume as a string:
    const responseObj:any = await body.json();

    return responseObj;
  } catch(err:any) {

    // Check if the error message contains a specific substring
    if (err.message.includes('is not authorized to perform: execute-api:Invoke')) {
      //alert(`Unauthorized access to resources, contact dev team if this is a mistake`) //Return empty object
      return {
        status: 'Error Unauthorized',
        data: []
      }
    } else {
      // Handle other errors or rethrow them
      return `Error: ${err.message}`;
    }
  }
}

// GET requests without authorization
export async function regGet(url: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_LINK}${url}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'x-amzn-RequestId': '2ca85206-9799-4f15-9b2a-ead5ae90e67a',
          // 'x-amz-apigw-id': 'CHEoxFXIIAMFUpw=',
          // 'X-Amzn-Trace-Id': 'Root=1-60e5e7d1-57237ac21149614b2381e722',
          // 'Authorization': "AWS" + " " + getAccessKey() + ":" + await getSignature()
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
      }
    ).then((res) => res.json());
    // console.log(response)
    return response;
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function regRequest(type: string, url: string, data?: {}) {
  try {
    // Default options are marked with *
    // console.log(`${API_LINK}${url}`)
    const response = await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_LINK}${url}`,
      {
        method: type, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'x-amz-security-token': await getSessionToken(),
          // 'Authorization': "AWS" + " " + getAccessKey() + ":" + await getSignature()
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
      }
    );
    const info = await response.json(); // parses JSON response into native JavaScript objects
    console.log(info);
    return info;
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
}

// General requests with authorization
export async function fetchRequestJSON(
  type: string,
  path: string,
  reqBody?: any
) {
  let method = type;
  let headers = {
    // "Content-Type": "application/json",
    Accept: "application/json",
  };
  let queryParams = {};
  let body = reqBody;

  const idToken = await returnJWTToken();


  const apiName = 'Admin System';
  const options = {
    body,
    headers: {
      "X-Cognito-Token": idToken
    } 
  };

  //Can 100% refactor the below, for now keep this until permissions has been set throughout the admin system
  if(type == "PUT") {
    try {
      const restOperation = put({apiName, path, options})
      const { body } = await restOperation.response;
      const responseObj:any = body.json();
      return responseObj;
    } catch (err:any) {
      // Check if the error message contains a specific substring
      if (err.message.includes('is not authorized to perform: execute-api:Invoke')) {
       alert(`Unauthorized access to resources, contact dev team if this is a mistake`)
       return `Authorization Error`;
     } else {
       // Handle other errors or rethrow them
       return `Error: ${err.message}`;
     }
   }
  }
  else if(type == "DELETE") {
    try {
      //Work Around for now, its disgusting but should work:
      const apiEndPoint = `${process.env.REACT_APP_AWS_API_GATEWAY_LINK}/${path}`

      // const response = await fetch(apiEndPoint, {
      //   method: 'DELETE',
      //   headers: {
      //     'email': email.toString(),
      //     'name': name.toString(),
      //   }
      // });

      // Create an instance of the RestApi class  

      await del({
        apiName: 'Admin System',
        path: path,
        options: {
          headers: {
            "X-Cognito-Token": idToken
          }
        }
      }).response;
      // await restOperation.response
      return {
        status: 'Success',
        data: []
      } 
    } catch (err:any) {
      console.log(err)
      // Check if the error message contains a specific substring
      if (err.message.includes('is not authorized to perform: execute-api:Invoke')) {
       alert(`Unauthorized access to resources, contact dev team if this is a mistake`)
       return `Authorization Error`;
     } else {
       // Handle other errors or rethrow them
       return `Error: ${err.message}`;
     }
   }
  
  }
  else if(type == "GET") {
    try {
      const encodedReqBody = encodeURIComponent(reqBody);

      const restOperation = get({
        apiName: `Admin System`,
        path: path,
        options: {
          queryParams: {
            reqBody: encodedReqBody
          },
        }
      })
  
      const responseObj:any = body.json();
      return responseObj;
    } catch (err:any) {
      // Check if the error message contains a specific substring
      if (err.message.includes('is not authorized to perform: execute-api:Invoke')) {
       alert(`Unauthorized access to resources, contact dev team if this is a mistake`)
       return `Authorization Error`;
     } else {
       // Handle other errors or rethrow them
       return `Error: ${err.message}`;
     }
   }

  }
  else {
    try {
      const restOperation = post({
        apiName: 'Admin System',
        path: path,
        options: options
      })
      const { body } = await restOperation.response;
      const responseObj:any = body.json();
      return responseObj;
    } catch (err:any) {
       // Check if the error message contains a specific substring
       if (err.message.includes('is not authorized to perform: execute-api:Invoke')) {
        alert(`Unauthorized access to resources, contact dev team if this is a mistake`)
        return `Authorization Error`;
      } else {
        // Handle other errors or rethrow them
        return `Error: ${err.message}`;
      }
    }

  }
}