import { useState } from "react";
import store from "../../redux/store";
import BreadCrumb from "../BreadCrumb";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import { ARReport } from "./ARReport";
import { ReportSelection } from "./ReportSelection";

type Props = {
    userData?: any;
};

export const Reporting = (props: Props): JSX.Element => {
  const [toggleInvoice, setToggleInvoice] = useState(false);

  //Opens the Modal or Closes the Modal
  const handleInvoiceView = async () => {
    if (toggleInvoice === false) {
      setToggleInvoice(true)
    } else {
      setToggleInvoice(false);
      //Definitely need to change this code, for now itl'l allow for auto refresh when a modal closes incase they edit something - Newman 
      let update = store.getState().invoiceReducer.ARUpdate;
      update();
    }
  };

  return (
    <div className="font-display bg-white  lg:rounded-tl-lg">
      <InvoiceQuickView
          handleModalToggle={handleInvoiceView}
          onoff={toggleInvoice}
      />
      <BreadCrumb
        rootPage="Reporting"
        subPage=""
        sideButton=""
        buttonAction={""}
      />
      <div className="fade-in px-4  ">
        {/* <ARReport
          handleModalToggle={handleInvoiceView}
        /> */}
        <ReportSelection 
          handleModalToggle={handleInvoiceView}
          // onoff={toggleInvoice}
        />
      </div>
    </div>
  );
};


