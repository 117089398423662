import React from "react";
import MapPicture from "../../../client-facing/img/ParkingPhotos/Studio/Studio Map.png";
import FirstStreetView from "../../../client-facing/img/ParkingPhotos/Studio/Studio Parking.png";
import SecondStreetView from "../../../client-facing/img/ParkingPhotos/Studio/Studio View 2.png";
import ThirdStreetView from "../../../client-facing/img/ParkingPhotos/Studio/Studio View.png";

function StudioParking() {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-5 w-full">
        <div className="flex flex-col w-full basis-2/3">
          <img
            src={MapPicture}
            alt="Google Maps View for Parking Location"
            className="h-2/5 p-5"
          />
          <p className="mt-3">
            199 Richmond Street and 30 Nelson Street are two different buildings
            with a connected parking garage.
          </p>
          <p className="mt-3">
            Parking can be accessed by turning onto Nelson Street and turn right
            onto Twilight lane. Parking entrance is located on Twilight Lane.
          </p>
          <p className="mt-3">
            Once you are in the garage, look for residential parking signs. You
            will need to use your parking remote to open the garage.
          </p>
          <p className="mt-3 mb-5 md:mb-0">
            If you are staying at 30 Nelson Street, look for ‘Studio 2’. If you
            are staying at 199 Richmond Street, look for ‘Studio 1’.
          </p>
          <img
            src={FirstStreetView}
            alt="Google Maps Street View"
            className="mt-2 h-2/5 p-5"
          />
        </div>
        <div className="w-full md:ml-5 flex flex-col mt-5 md:mt-0 basis-2/5">
          <img
            src={SecondStreetView}
            alt="Google Maps Street View"
            className="h-1/4 w-full p-5"
          />
          <div className="p-5">
            <p className="bg-green-400 text-white p-5">
              Please Note: 199 Richmond Street is also referred to as ‘Studio
              1’.
            </p>
          </div>
          <img
            src={ThirdStreetView}
            alt="Google Maps Street View"
            className="h-1/4 w-full p-5"
          />
          <div className="p-5">
            <p className="bg-green-400 text-white p-5">
              Please Note: 30 Nelson Street is also referred to as ‘Studio 2’.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudioParking;
