import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../../components/Notifications/NotificationProvider";

type Props = {
  handleEditBarToggle: any;
  toggle: any;
  bookingId?: number;
  updateList: boolean;
  fromBooking?: boolean;
  ErrorNote: any;
};

interface NewInvoiceState {
  booking_id: number;
  check_in: any;
  check_out: any;
  corporate_id: string;
  tenant_name: string;
  corporate: boolean;
  deposit_poli: boolean;
  credit_fee: boolean;
  payment_type: string;
  payment_terms: 5;
  confirmed: boolean;
  parking: boolean;
  cleaning: boolean;
  pet: boolean;
  first_rent: boolean;
  damages: boolean;
  amount: number;
  due_date: string;
  editToggle: boolean;
  disabled: string;
  check_in_time: string;
  booking_unit: string;
  booking_notes: string;
  tenant_email: string;
  tenant_phone: string;
  admin_contact: string;
  cleaning_contact: string;
  finance_contact: string;
  booking_related: boolean;
  //rent_start_date: string;
  rent_end_date: string;
  inv_start_date: string;

  //new_email: string;
  new_type: string;
  new_payment_method: string;
  new_entry: string;
  new_hst: boolean;

  selected: number;
  newfname: string;
  newlname: string;
  newemail: string;
  newphone: number;

  all_tenants: any[];
}
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
class NewInvoice extends Component<any, NewInvoiceState> {
  constructor(props: any) {
    super(props);

    this.state = {
      booking_id: this.props.booking_id ? this.props.booking_id : -1,
      booking_unit: "",
      check_in: new Date(),
      check_out: new Date(),
      corporate_id: "",
      tenant_name: "",
      corporate: true,
      deposit_poli: true,
      credit_fee: false,
      payment_type: "",
      payment_terms: 5,
      confirmed: false,
      parking: false,
      cleaning: false,
      pet: false,
      first_rent: false,
      damages: false,
      amount: 0,
      due_date: "",
      editToggle: false,
      disabled: "",
      check_in_time: "",
      booking_notes: "",
      tenant_email: "",
      tenant_phone: "",
      admin_contact: "",
      cleaning_contact: "",
      finance_contact: "",
      booking_related: false,
      //rent_start_date: new Date().toString(),
      rent_end_date: "",
      inv_start_date: "",

      //new_email: "",
      new_type: "Rent",
      new_payment_method: "",
      new_entry: "",
      new_hst: false,

      selected: -1,
      newfname: "",
      newlname: "",
      newemail: "",
      newphone: 0,

      all_tenants: [],
    };
  }

  //     handleCorpToggle = (event) => {
  //       event.preventDefault();
  //       if ( this.state.corporate === false ) {
  //          this.setState({corporate: true});
  //       }

  //       else {
  //          this.setState({corporate: false});
  //      }

  //     }
  getDueDate = (startDate: Date) => {
    startDate.setHours(startDate.getHours()+5);
    let due_date = startDate;
    due_date.setDate(due_date.getDate() - 14);

    let month = "" + (due_date.getMonth() + 1);
    let day = "" + due_date.getDate();
    const year = "" + due_date.getFullYear();
    
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join("-");
  }

  handleInputChange = async (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    await this.setState(stateObject);

    if (event.target.id == "selected" && event.target.value != -1) {
      let tenant = this.state.all_tenants.find(
        (tenant: any) => tenant.id == event.target.value
      );
      this.setState({
        newfname: tenant.first_name,
        newlname: tenant.last_name,
        newemail: tenant.email,
        newphone: tenant.phone_number,
      });
    }

    if (event.target.id === "inv_start_date") {
      const due_date = this.getDueDate(new Date(this.state.inv_start_date));
      await this.setState({due_date: due_date});
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    const toggleStates = [
      "damages",
      "parking",
      "first_rent",
      "confirmed",
      "cleaning",
      "pet",
    ];
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      for (let i = 0; i < toggleStates.length; i++) {
        if (id !== toggleStates[i]) {
          returnObj[toggleStates[i]] = false;
        }
      }
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();

    this.setState(stateObject);
  };

  handleTypeChange = (event: any) => {
    this.setState({ new_type: event.target.value });
  };

  handlePaymentChange = (event: any) => {
    this.setState({ new_payment_method: event.target.value });
  };

  componentDidMount = async () => {
    //Need this get all for our tenant dropdown list 
    let tenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    this.setState({
      all_tenants: tenants.data,
    });

    let bookingId = Number(
      new URLSearchParams(window.location.search).get("id")
    );
    // let allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_GET_ALL);
    let bookingData: any;
    // if (allBookings.status != "Success") {
    //   alert("Error getting all the bookings");
    // } else {
    if (bookingId && bookingId != -1) {
      let singleBooking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${bookingId}`)
      bookingData = singleBooking.data[0];
      
      //billing info
      const tenantInfo = await fetchGetJSON(
        `${Constants.API_PATH_TENANT_GET}${bookingData.tenant_id}`
      );
      if (tenantInfo.status !== "Success") {
        const NotificationHandler = this.props.ErrorNote;
        NotificationHandler("Error", "Error getting booking tenant");
      } else {
        this.setState ({
          booking_id: bookingData.id,
          selected: 1,
          newfname: tenantInfo?.data?.first_name,
          newlname: tenantInfo?.data?.last_name,
          newemail: tenantInfo?.data?.email ? tenantInfo.data.email : "",
          newphone: tenantInfo?.data?.phone ? tenantInfo.data.phone : "",
        });
      }

      let singleUnit = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${bookingData.unit_id}`)
      if (singleUnit.status !== "Success") {
        const NotificationHandler = this.props.ErrorNote;
        NotificationHandler("Error", "Error getting booking tenant");
      } else {
        let unitData: any = singleUnit.data;

        const inv_due_date = this.getDueDate(new Date(bookingData.check_in));
        this.setState({
          due_date: inv_due_date,
          inv_start_date: bookingData.check_in,
          rent_end_date: bookingData.check_out,
          check_in: new Date(bookingData.check_in),
          check_out: new Date(bookingData.check_out),
          booking_unit: unitData.suite_name
        });
      }
    }
    // }
  };

  // Create a invoice for a booking manually
  handleClick = async () => {
    let NotificationHandler = this.props.ErrorNote;
    if (this.props.bookingId) {
      this.setState({ booking_id: this.props.bookingId });
    }
    let createInvoiceBody: any;
    if (this.state.booking_related || this.props.bookingId > 0) {
      const potentialBooking = await fetchGetJSON(
        `${Constants.API_PATH_BOOKING_GET}${this.props.bookingId}`
      );
      console.log(potentialBooking)
      const bookingData = potentialBooking.data[0];
      if (!bookingData) {
        NotificationHandler("Error","Invalid Booking ID", "Please enter a valid booking id");
        return;
      } else {
        let line_type = "";

        if (this.state.new_type === "HST") {
          line_type = "HST";
        }
        else if (this.state.new_type === "Parking") {
          line_type = "Parking";
        }
        else if (this.state.new_type === "Rent Refund") {
          line_type = "Rent Refund";
        }
        else if (this.state.new_type === "Cleaning Fee") {
          line_type = "Cleaning Fee";
        }
        else if (this.state.new_type === "Referral Fee") {
          line_type = "Referral Fee";
        }
        else if (this.state.new_type === "StayWell Referral Fee") {
          line_type = "Staywell Referral Fee";
        }
        else if (this.state.new_type === "Regular Cleaning Fee") {
          line_type = "Regular Cleaning Fee";
        }
        console.log(this.state);

        if (this.state.due_date === this.getDueDate(new Date(this.state.inv_start_date))) {
          createInvoiceBody = {
            invoice: {
              due: this.state.inv_start_date,
              paid: false,
              first_rent: this.state.first_rent,
              payment_method_invoice: "TBD",
              booking_id: +this.state.booking_id,
              invoice_types: this.state.new_type,
              end:
                this.state.rent_end_date == "" ? null : this.state.rent_end_date,
            },
            invoice_lines: [
              {
                entry: this.state.new_type === "Other" || this.state.new_type == "Rent Refund" ? this.state.new_entry : line_type,
                cost: +this.state.amount,
                line_type: this.state.new_type,
              },
            ],
            hst: this.state.new_hst,
          };
        } else {
          createInvoiceBody = {
            invoice: {
              due: dayjs.tz(this.state.due_date, 'America/Toronto').format('YYYY-MM-DD'),
              paid: false,
              first_rent: this.state.first_rent,
              payment_method_invoice: "TBD",
              booking_id: +this.state.booking_id,
              invoice_types: this.state.new_type,
              start_date: this.state.new_type == 'Rent' ? this.state.inv_start_date : null,
              end:
               this.state.new_type == 'Rent'? this.state.rent_end_date == "" ? null : this.state.rent_end_date : null,
            },
            invoice_lines: [
              {
                entry: this.state.new_type === "Other" || this.state.new_type == "Overpayments" || this.state.new_type == "Rent Refund" ? this.state.new_entry : line_type,
                cost: +this.state.amount,
                line_type: this.state.new_type,
              },
            ],
            hst: this.state.new_hst,
          };
            
          //If invoice type is not rent, do not set an end date
          if(this.state.new_type != "Rent") {
             createInvoiceBody.end = null;
          }
        }
      }
    } else {
      if (
        this.state.newfname == "" ||
        this.state.newlname == "" ||
        this.state.newemail == "" 
      ) {
        NotificationHandler("Warning", `No Tenant associated with Invoice`, "")
        return;
      }
      else if (
        this.state.new_type == "Rent" &&
        this.state.rent_end_date == ""
      ) {
        NotificationHandler("Warning", `No end date associated with Invoice`, "")
        return;
      }
      else if (
        this.state.new_type == "Rent" &&
        new Date(this.state.due_date).toISOString() > new Date(this.state.rent_end_date).toISOString()
      ) {
        NotificationHandler("Warning", `End date should be later than the due date`, "")
        return;
      }
      createInvoiceBody = {
        invoice: {
          due: new Date(this.state.due_date).toISOString(),
          paid: false,
          // rent: this.state.new_type.toLowerCase() === "rent",
          first_rent: false,
          // amount: +this.state.amount,
          payment_method_invoice: 'TBD',
          booking_id: -1,
          // deposit: this.state.new_type.toLowerCase() === "deposit",
          invoice_types: this.state.new_type,
          start_date: this.state.new_type == "Rent" ? this.state.inv_start_date : null,
          //start: this.state.rent_start_date,
          end: this.state.new_type == "Rent" ? this.state.rent_end_date : null, //only add end date if invoice type is rent
        },
        invoice_lines: [
          {
            entry: this.state.new_type !== "Rent" ? this.state.new_entry : "",
            cost: +this.state.amount,
            line_type: this.state.new_type,
          },
        ],
        hst: this.state.new_hst,
      };
    }
    console.log(createInvoiceBody)
    const createInvoiceStatus = await this.createInvoice(createInvoiceBody);

    if (createInvoiceStatus.status === "Success" || (createInvoiceStatus.status === "success")) {
      if (createInvoiceBody.invoice.booking_id == -1) {
        let body = {
          id: this.state.selected,
          first_name: this.state.newfname,
          last_name: this.state.newlname,
          email: this.state.newemail,
          phone_number: this.state.newphone,
        };
        let res = await fetchRequestJSON(
          "POST",
          Constants.API_PATH_BILLING_CREATE,
          {
            invoice_id: createInvoiceStatus.data.id,
            tenant: body,
          }
        );
        NotificationHandler("Success", `Succesfully create Invoices`, "Please Check the Invoice Dashboard for an Update")
      }
      if (!this.props.updateList) {
        let updateList = store.getState().invoiceReducer.update;
        await updateList();
      } else {
        let updateQuickView = store.getState().invoiceReducer.quickViewUpdate;
        await updateQuickView();
        let updateList = store.getState().invoiceReducer.listUpdate;
        await updateList();
      }
      // alert("Succesfully created an invoice")
    } else {
      // alert("Error with your invoice creation, please review your data.");
      NotificationHandler("Warning", `Required Fields Missing create Invoices:`, createInvoiceStatus.status)
    }

    this.props.handleEditBarToggle();
  };

  createInvoice = async (createInvoiceBody: any) => {
    let NotificationHandler = this.props.ErrorNote;
    NotificationHandler("Creating Invoice", `Please do not double click, invoice being created`, "")
    const msg = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_INVOICE_CREATE,
      createInvoiceBody
    );

    if(msg?.status === "Success") {
      NotificationHandler("Success", `Please view the newly created invoice`, "")
    }
    return msg;
  };

  render() {
    // This makes it slightly more efficient but it removes the animation of sliding in
    /*
    if (!this.props.toggle) {
      return null;
    }
    */
    let toggleEdit = this.props.toggle;
    let ProfileType = "New Invoice";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    // let editType = "booking";
    let content;
    // let inactive = "";
    // let tabIndex;
    // let corporateInfo;
    // let BookingID = <></>;

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    /*
    if (this.state.booking_related == true || this.state.booking_id != -1) {
      BookingID = (
        <label className=" fade-in block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Booking ID</span>
          <input
            id="booking_id"
            onChange={this.handleInputChange}
            value={this.state.booking_id}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
            readOnly={this.props.bookingId  ? true : false}
          />
        </label>
      );
    }
    */

    let date_input = <></>;
    if (this.state.first_rent) {
      date_input = (
        <>
          {/*<label className="block pl-3 mb-5">
        <span className="text-gray-400 text-xs">Rent Start Date</span>
        <input
          id="rent_start_date"
          onChange={this.handleInputChange}
          type="date"
          className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          placeholder=""
        />
      </label>
      */}
          {/* <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Rent End Date</span>
            <input
              id="rent_end_date"
              onChange={this.handleInputChange}
              type="date"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label> */}
        </>
      );
    }


    let startDate = <></>;
    let endDate = <></>
    if (this.state.new_type == "Rent") {
      endDate =
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">End Date</span>
          <input
            id="rent_end_date"
            onChange={this.handleInputChange}
            value={this.state.rent_end_date}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>;
      startDate = 
        <label className="block pl-3 mb-5">
        <span className="text-gray-400 text-xs">Invoice Start Date</span>
        <input
          id="inv_start_date"
          onChange={this.handleInputChange}
          value={this.state.inv_start_date}
          type="date"
          className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          placeholder=""
        />
      </label>;
    }

    let amount = <></>
    let hstToggle = <></>
    if (this.state.new_type !== "Rent") {
      amount = 
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Amount</span>
          <input
            id="amount"
            onChange={this.handleInputChange}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={this.state.amount}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>
      hstToggle = 
        <div className={" pl-3 block w-1/2  my-5 "}>
          <div className="flex flex-row items-center justify-between ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Add HST
            </span>

            <ToggleSwitch
              id="new_hst"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.new_hst}
              readonly={false}
            />
          </div>
        </div>
    }

    let contactDisplay = <></>
    if (this.state.selected != -1) {
      contactDisplay =
        <option value={this.state.selected}>
          {this.state.newfname + " " + this.state.newlname}
        </option>
    } else {
      contactDisplay =
        <option value={-1}>
            Create New Contact
        </option>
    }

    content = (
      <form className=" flex flex-col pl-6 pr-12 py-4">
        {/* <span className="text-gray-600 text-md font-bold mb-4 mt-2">General Info</span> */}

        <div className={" pl-3 block w-1/2  my-5 "}>
          <div className="flex flex-row items-center justify-between ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Booking Related
            </span>

            <ToggleSwitch
              id="booking_related"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.props.bookingId ? true : this.state.booking_related}
              readonly={this.props.bookingId ? true : false}
            />
          </div>
        </div>

        {(this.state.booking_related == true || this.props.bookingId) && (
          <label className=" fade-in block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Booking ID</span>
            <input
              id="booking_id"
              onChange={this.handleInputChange}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              value={
                this.props.bookingId
                  ? this.props.bookingId
                  : this.state.booking_id
              }
              type="number"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
              readOnly={this.props.bookingId ? true : false}
            />
          </label>
        )}

        {/* {this.props.fromBooking && ( */}
        {/* <label className=" fade-in block pl-3 mb-5"> */}
        {/* <span className="text-gray-400 text-xs">End Date</span> */}
        {/* <br></br> */}
        {/* <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"> */}
        {/* {this.state.check_in.toISOString().substr(0, 10)} to{" "} */}
        {/* {this.state.check_out.toISOString().substr(0, 10)} */}
        {/* </span> */}
        {/* </label> */}
        {/* )} */}

        {/* no amount if rent */}
        {amount}

        {this.state.booking_related == false && (
          <>
            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Type</span>
              <select
                onChange={this.handleTypeChange}
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              >
                <option value="Rent">Rent</option>
                <option value="Rent Refund">Rent Refund</option>
                <option value="HST">HST</option>
                <option value="Parking">Parking</option>
                <option value="Overpayments">Overpayments</option>
                <option value="Cleaning Fee">Cleaning Fee</option>
                <option value="Referral Fee">Referral Fee</option>
                <option value="StayWell Referral Fee">
                  StayWell Referral Fee
                </option>
                <option value="Regular Cleaning Fee">
                  Regular Cleaning Fee
                </option>
                <option value="Other">Other</option>
                <option value="Deposit">Deposit</option>
              </select>
            </label>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Line Entry</span>
              {this.state.new_type === "Rent" && this.props.fromBooking && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  {this.state.check_in.toISOString().substr(0, 10)} to{" "}
                  {this.state.check_out.toISOString().substr(0, 10)}{" "}
                  {this.state.booking_unit}
                </span>
              )}
              {this.state.new_type === "HST" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  HST
                </span>
              )}
              {this.state.new_type === "Parking" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  Parking
                </span>
              )}
              {this.state.new_type === "Cleaning Fee" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  Cleaning Fee
                </span>
              )}
              {this.state.new_type === "Referral Fee" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  Referral Fee
                </span>
              )}
              {this.state.new_type === "StayWell Referral Fee" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  StayWell Referral Fee
                </span>
              )}
              {this.state.new_type === "Regular Cleaning Fee" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  Regular Cleaning Fee
                </span>
              )}
              {this.state.new_type === "Overpayments" || this.state.new_type === "Rent Refund" && (
                <input
                  id="new_entry"
                  onChange={this.handleInputChange}
                  value={this.state.new_entry}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              )}
              {this.state.new_type === "Other" && (
                <input
                  id="new_entry"
                  onChange={this.handleInputChange}
                  value={this.state.new_entry}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              )}
              {this.state.new_type === "Deposit" && (
                <span className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black">
                  {this.state.booking_unit} Deposit
                </span>
              )}
            </label>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Payment Method</span>
              <select
                onChange={this.handlePaymentChange}
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              >
                <option value="TBD">TBD</option>
                <option value="online credit">
                  Online Credit
                </option>
                <option value="terminal (no charge, includes cash)">
                  Terminal (No Charge, Includes Cash)
                </option>
                <option value="terminal credit (3%)">
                  Terminal Credit (3%)
                </option>
                <option value="cheque">Cheque</option>
                <option value="certified cheque">Certified Cheque</option>
                <option value="EMT">EMT</option>
                <option value="direct deposit">Direct Deposit</option>
                <option value="wire">Wire</option>
                <option value="rent deduction">Rent Deduction</option>
                <option value="rent transfer credit">
                  Rent Transfer Credit
                </option>
                <option value="other">Other</option>
              </select>
            </label>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Due Date</span>
              <input
                id="due_date"
                onChange={this.handleInputChange}
                value={this.state.due_date}
                type="date"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
              />
            </label>

            {/* start date only for rent */}
            {startDate}

            {/* end date only for rent */}
            {endDate}

            {/* no hst toggle if rent */}
            {hstToggle}

            <span className="text-gray-600 text-md font-bold mb-4 mt-2">
              Add Billing Contact
            </span>
            <div className="block pl-3  mb-6 ">
              <select
                id="selected"
                onChange={this.handleInputChange}
                className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              >
                {this.state.all_tenants != undefined && this.state.all_tenants.map((ten: any) => (
                  <option value={ten.id} key = {ten.id}>
                    {ten.first_name + " " + ten.last_name}
                  </option>
                ))
                }
                {contactDisplay}
              </select>
            </div>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Tenant First Name</span>
              <input
                id="newfname"
                value={this.state.newfname}
                onChange={this.handleInputChange}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
                disabled={!(this.state.selected == -1)}
              />
            </label>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Tenant Last Name</span>
              <input
                id="newlname"
                value={this.state.newlname}
                onChange={this.handleInputChange}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
                disabled={!(this.state.selected == -1)}
              />
            </label>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Tenant Email</span>
              <input
                id="newemail"
                value={this.state.newemail}
                onChange={this.handleInputChange}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
                disabled={!(this.state.selected == -1)}
              />
            </label>

            <label className="block pl-3 mb-5">
              <span className="text-gray-400 text-xs">Tenant Phone</span>
              <input
                id="newphone"
                value={this.state.newphone}
                onChange={this.handleInputChange}
                type="text"
                className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
                disabled={!(this.state.selected == -1)}
              />
            </label>
          </>
        )}

        {this.state.booking_related == true && (
          <div className="ml-3 mb-5 px-3 border rounded-sm border-solid py-4 shadow-sm flex flex-col">
            <div className=" flex flex-row mb-5">
              <div className={" block mr-10 w-32  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-3"
                    }
                  >
                    Confirmed?
                  </span>

                  <ToggleSwitch
                    id="confirmed"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.confirmed}
                  />
                </div>
              </div>

              <div className={" block w-32  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-3"
                    }
                  >
                    Parking?
                  </span>

                  <ToggleSwitch
                    id="parking"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.parking}
                  />
                </div>
              </div>
            </div>

            <div className=" flex flex-row mb-5">
              <div className={"block mr-10 w-32 "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-3"
                    }
                  >
                    First Rent
                  </span>

                  <ToggleSwitch
                    id="first_rent"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.first_rent}
                  />
                </div>
              </div>
              <div className={" block w-32  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-3"
                    }
                  >
                    Damages
                  </span>
                  <ToggleSwitch
                    id="damages"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.damages}
                  />
                </div>
              </div>
            </div>

            <div className=" flex flex-row mb-5">
              <div className={"block mr-10 w-32 "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-3"
                    }
                  >
                    Cleaning Fee?
                  </span>

                  <ToggleSwitch
                    id="cleaning"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.cleaning}
                  />
                </div>
              </div>
              <div className={" block w-32  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-3"
                    }
                  >
                    Pet Fee?
                  </span>
                  <ToggleSwitch
                    id="pet"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.pet}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {date_input}
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button
            onClick={this.props.openSettings}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.props.bookingId}
          </span>
        </div>

        {content}

        <div className="h-24 flex flex-row ">
          <button
            onClick={() => this.handleClick()}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Done
          </button>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewInvoice);
