export type State = {
  units: any[];
  curId: number;
  listUpdate: any;
};

const initialState: State = {
  units: [],
  curId: -1,
  listUpdate: undefined,
};

type Action =
  | { type: "addUnit"; newUnit: { id: number } }
  | { type: "removeUnit"; id: number }
  | { type: "unitIdTransfer"; id: number }
  | { type: "unitListUpdate"; listUpdate: any };

export const unitReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "addUnit":
      state.units.push(action.newUnit);
      return state;
    case "unitIdTransfer":
      state.curId = action.id;
      return state;
    case "unitListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    default:
      return state;
  }
};

export default unitReducer;
