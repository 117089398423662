import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { addDays, formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { CostExplorer } from "aws-sdk";
import { MdSearch } from "react-icons/md";
import { BiBookAdd } from "react-icons/bi";
import { localRoute } from "../../api-requests/api-gateway";

type BookingFields = {
  booking_notes?: string;
  booking_type?: string;
  check_in?: string;
  check_in_notes?: string;
  check_out_notes?: string;
  cleaning_frequency?: string;
  flag_reason: string;
  flagged: boolean;
  light?: string;
  hard_checkout?: boolean;
  // monthly_rate: number;
  parking_requested?: boolean;
  pets?: boolean;
  unit_id?: number;
  confirmed?: boolean;
  keys_confirm: boolean;
  payment_confirm: boolean;
  cleaning_confirm: boolean;
  other_tenants: number[];
}

type BookingEditBody = {
  id: number; 
  fields: BookingFields; 
}

type Props = {
  bookingData: any;
  handleDateChange: any;
  stateChange: any;
  toggle: any;
  handleEditBarToggle: any;
  handleToggleChange: any;
  ErrorNote: any;
};

type ReturnData = {
  status: string;
  data: any;
};

type Tenant = {
  id: number; 
  name: string;
  email: string;
  phone: number;
}

type Unit = {
  id: number;
  name: string;
}

type Corporate = {
  id: number;
  corporate_name: string;
}

type EditBookingState = {
  // general info section
  booking_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: number;  /* TODO: Make tenant_phone a string instead to avoid formatting issues. */

  // corporate info section
  corporate_id: number;
  corporate_name: string;

  admin_contact: string;
  cleaning_contact: string;
  finance_contact: string;

  admin_contact_email: string;
  cleaning_contact_email: string;
  finance_contact_email: string;

  admin_contact_phone: string;
  cleaning_contact_phone: string;
  finance_contact_phone: string;
  corporates: Corporate[];

  // notes section
  booking_notes: string;
  check_in_notes: string;
  check_out_notes: string;

  // booking details section
  corporate: boolean;
  parking_requested: boolean;
  pets: boolean;
  hard_checkout: boolean;
  flagged: boolean;

  // miscellaneous
  all_units: Unit[];
  auto_HST: boolean;
  building: string;
  check_in: any;
  check_in_time: Date;
  check_out: any;
  check_out_confirmed: boolean;
  cleaning_fee: boolean;
  cleaning_frequency: string;
  confirmed: boolean;
  confirmed_checkin_time: string;
  credit_fee: boolean;
  deposit_policy: boolean;
  first_rent_required: boolean;
  flag_reason: string;
  // light: string;
  manual_invoicing: boolean;
  search: string;
  showCreateNewTenantButton: boolean;
  showCreateNewTenantForm: boolean;
  tenantList: any[],
  other_tenants: number[];
  selectedAssociatedTenant: any;
  newemail: string;
  newfname: string;
  newlname: string;
  newphone: string;
  payment_terms: number;
  payment_type: string;
  pricing_type: string;
  rate: number;  // TODO: Isn't rate the same as monthly_rate?
  suite: string;
  suite_number: string;

  // fobs and keys section
  key_count: number;
  fob_count: number;
  mailkey_count: number;
  park_fob_count: number;
  // monthly_rate: number;
  outstanding_invoice: number;

  //light status
  keys_confirm: boolean,
  cleaning_confirm: boolean,
  payment_confirm: boolean

  netZero: boolean,

  originalBookingData: any,
  [key: string]: any; // Added this line to allow indexing by string keys

  userType: string[];
};

class EditBooking extends Component<Props, EditBookingState> {
  constructor(props: any) {
    super(props);
    console.log(props);
    this.state = {
      // general info section
      booking_id: this.props.bookingData.booking_id,
      tenant_email: this.props.bookingData.tenant_email,
      tenant_id: this.props.bookingData.tenant_id,
      tenant_name: this.props.bookingData.tenant_name,
      tenant_phone: this.props.bookingData.tenant_phone,  /* TODO: Make tenant_phone a string instead to avoid formatting issues. */

      // corporate info section
      corporate_id: this.props.bookingData.corporate_id,
      corporate_name: this.props.bookingData.corporate_name,

      admin_contact: this.props.bookingData.admin_contact,
      cleaning_contact: this.props.bookingData.cleaning_contact,
      finance_contact: this.props.bookingData.finance_contact,

      admin_contact_email: this.props.bookingData.admin_contact_email,
      cleaning_contact_email: this.props.bookingData.cleaning_contact_email,
      finance_contact_email: this.props.bookingData.finance_contact_email,

      admin_contact_phone: this.props.bookingData.admin_contact_phone,
      cleaning_contact_phone: this.props.bookingData.cleaning_contact_phone,
      finance_contact_phone: this.props.bookingData.finance_contact_phone,
      corporates: [],

      // notes section
      booking_notes: this.props.bookingData.booking_notes,
      check_in_notes: this.props.bookingData.check_in_notes,
      check_out_notes: this.props.bookingData.check_out_notes,

      // booking details section
      corporate: this.props.bookingData.corporate,
      parking_requested: this.props.bookingData.parking_required,
      pets: this.props.bookingData.pets,
      hard_checkout: this.props.bookingData.hard_check_out,
      flagged: this.props.bookingData.flagged,

      // miscellaneous
      all_units: [],
      auto_HST: this.props.bookingData.auto_HST,
      building: this.props.bookingData.building,
      check_in: this.props.bookingData.check_in,
      check_in_time: this.props.bookingData.check_in_time,
      check_out: this.props.bookingData.check_out,
      check_out_confirmed: this.props.bookingData.check_out_confirmed,
      cleaning_fee: this.props.bookingData.cleaning_fee,
      cleaning_frequency: this.props.bookingData.cleaning_frequency,
      // confirmed: this.props.bookingData.confirmed,
      confirmed: this.props.bookingData.confirmed == null ? false : this.props.bookingData.confirmed,
      confirmed_checkin_time: this.props.bookingData.confirmed_checkin_time,
      credit_fee: this.props.bookingData.credit_fee,
      deposit_policy: this.props.bookingData.deposit_policy,
      first_rent_required: this.props.bookingData.first_rent_required,
      flag_reason: this.props.bookingData.flag_reason,
      // light: this.props.bookingData.light,
      manual_invoicing: this.props.bookingData.manual_invoicing,
      search: "",
      showCreateNewTenantButton: false,
      showCreateNewTenantForm: false,
      tenantList: null,
      other_tenants: this.props.bookingData.other_tenants,
      selectedAssociatedTenant: null,
      newemail: "",
      newfname: "",
      newlname: "",
      newphone: "",
      payment_terms: this.props.bookingData.payment_terms,
      payment_type: this.props.bookingData.payment_type,
      pricing_type: this.props.bookingData.booking_type,
      rate: this.props.bookingData.rate,  // TODO: Isn't rate the same as monthly_rate?
      
      suite: this.props.bookingData.suite,
      suite_number: this.props.bookingData.suite_number,

      // fobs and keys section
      key_count: this.props.bookingData.key_count,
      fob_count: this.props.bookingData.fob_count,
      mailkey_count: this.props.bookingData.mailkey_count,
      park_fob_count: this.props.bookingData.park_fob_count,
      // monthly_rate: this.props.bookingData.monthly_rate,
      outstanding_invoice: this.props.bookingData.outstanding_invoice,
      keys_confirm: this.props.bookingData.keys_confirm,
      cleaning_confirm: this.props.bookingData.cleaning_confirm,
      payment_confirm: this.props.bookingData.payment_confirm,
      netZero: this.props.bookingData.netZero,

      originalBookingData: this.props.bookingData,
      userType: [],
    };
  }

  componentDidMount = async () => {
    let bookingId = Number(new URLSearchParams(window.location.search).get("id"));
    bookingId = bookingId ? bookingId : store.getState().bookingReducer.curId;

    this.loadUserType();

    // let allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_GET_ALL);
    let singleBooking = await fetchGetJSON(`${Constants.API_PATH_BOOKING_GET}${bookingId}`)
    let bookingData: any;


    if (singleBooking.status != "Success") {
      alert("Error getting all the bookings");
    } else {
      bookingData = singleBooking.data[0];
      if (!bookingData) {
        console.log("NO BOOKING DATA");
      } else {

        this.setState({
          // general info section
          booking_id: bookingData.id,
          // tenant_email: tenantData.email,
          // tenant_id: tenantData.id,
          // tenant_name: `${tenantData.first_name} ${tenantData.last_name}`,
          // tenant_phone: tenantData.phone_number,

          // corporate info section
          corporate_id: bookingData.corporate_id,
          corporate_name: bookingData.corporate_name,

          admin_contact: "",
          cleaning_contact: "",
          finance_contact: "",

          admin_contact_email: "",
          cleaning_contact_email: "",
          finance_contact_email: "",

          admin_contact_phone: "",
          cleaning_contact_phone: "",
          finance_contact_phone: "",

          // notes section
          booking_notes: bookingData.booking_notes,
          check_in_notes: bookingData.check_in_notes,
          check_out_notes: bookingData.check_out_notes,

          // booking details section
          corporate: bookingData.corporate_id ? true: false,
          cleaning_frequency: bookingData.cleaning_frequency,
          parking_requested: bookingData.parking_requested,
          pets: bookingData.pets,
          hard_checkout: bookingData.hard_checkout,

          // miscellaneous
          // all_units: all_units,
          building: this.props.bookingData.building,
          check_in: bookingData.check_in,
          check_out: bookingData.check_out,
          check_out_confirmed: bookingData.confirmed_check_out,
          confirmed: bookingData.confirmed == null? false : bookingData.confirmed,
          pricing_type: bookingData.booking_type,
          suite_number: bookingData.unit_id,
          // light: bookingData.light ? bookingData.light : "red",
          // suite: unitData.suite_name,

          // fobs and keys section
          key_count: bookingData.keys,
          fob_count: bookingData.fobs,
          mailkey_count: bookingData.mail_keys,
          park_fob_count: bookingData.parking_fobs,
          // monthly_rate: bookingData.monthly_rate,

          keys_confirm: bookingData.keys_confirm,
          cleaning_confirm: bookingData.cleaning_confirm,
          payment_confirm: bookingData.payment_confirm,

          other_tenants: bookingData.other_tenants,

          originalBookingData: singleBooking.data[0]
        });
      }
    }

    // If corporate booking, then fill out its info in the menu
    if (bookingData) {
      if (bookingData.corporate_id) {
        // let allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
        // let corpData = allCorps.data.find((corpItem: any) => corpItem.id === bookingData.corporate_id);
        const corp = await fetchGetJSON(`${Constants.API_PATH_CORP_GET}${bookingData.corporate_id}`);
        if (corp.status === "Success") {
          const corpData = corp.data;
          const allContacts = await fetchGetJSON(`${Constants.API_PATH_CORP_CONTACT_GET_BOOKING}${this.state.booking_id}`)
          console.log(allContacts);
          let admin = allContacts.data.find(
            (contactItem: any) => contactItem &&
              contactItem.corporate_id === corpData.id &&
              contactItem.admin &&
              contactItem.primary
          );
          let finance = allContacts.data.find(
            (contactItem: any) => contactItem &&
              contactItem.corporate_id === corpData.id &&
              contactItem.finance &&
              contactItem.primary
          );
          let cleaning = allContacts.data.find(
            (contactItem: any) => contactItem &&
              contactItem.corporate_id === corpData.id &&
              contactItem.cleaning &&
              contactItem.primary
          );

          if (admin && corpData && finance) {
            this.setState({
              corporate_name: corpData.corporate_name,
              admin_contact: `${admin.first_name} ${admin.last_name}`,
              finance_contact: `${finance.first_name} ${finance.last_name}`,
    
              admin_contact_email: admin.email,
              finance_contact_email: finance.email,
    
              admin_contact_phone: admin.phone_number,
              finance_contact_phone: finance.phone_number,
            });
          }

          //Some bookings don't have a cleaning contact for some reason, this is just to add this here so it doesn't crash
          if(cleaning) {
            this.setState({
              cleaning_contact: `${cleaning.first_name} ${cleaning.last_name}`,
              cleaning_contact_email: cleaning.email,
              cleaning_contact_phone: cleaning.phone_number
            })
          }
        }
      }
    }

    let corporates = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
    this.setState({ corporates: corporates.data })
  };

  // TODO: Refactor this function by rewriting this code without using bind().
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      if (this.target.id === "monthly_rate") {
        returnObj[this.target.id] = parseInt(this.target.value); 
      } else {
        returnObj[this.target.id] = this.target.value;
      }
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleCleaningChange = async (event: any) => {
    event.preventDefault();
    await this.setState({
      cleaning_frequency: event.target.value,
    })
    console.log(this.state.cleaning_frequency)
  }

  // TODO: Refactor this function by rewriting this code without using bind().
  handleToggleChange = async (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    }();
    this.setState(stateObject);
  };

  handleConfirmed = async () => {
      
      let check = !this.state.confirmed
      console.log(check);
      if(this.state.hard_checkout == true){
        await this.setState({
          confirmed: check
        })
      }
      await this.setState({
        confirmed: check,
      })
      console.log(this.state.confirmed)
  }

  // TODO: Refactor this function.
  // In case booking is a corporate booking and edits are to be made here
  handleCorpEdit = async () => {
    if (this.state.corporate && (!this.state.corporate_id || this.state.corporate_id === -1)) {
      alert("This is not a corporate booking, changes made inside corporate fields are not applied");
      return { status: "Not a corporate booking" };
    }
    let potentialCorporation = await fetchGetJSON(`${Constants.API_PATH_CORP_GET}${this.state.corporate_id}`);
    let corpData = potentialCorporation.data;
    if (!corpData) {
      alert("Corporation not found");
      return { status: "Corporate not found" };
    }
    // Find associated
    // let allContacts = await fetchGetJSON(Constants.API_PATH_CORP_CONTACT_GET_ALL);
    let allContacts = await fetchGetJSON(`${Constants.API_PATH_CORP_CONTACT_GET_BOOKING}${this.state.booking_id}`)

    //NOTE: THIS NEEDS TO BE CHANGED IN THE FUTURE BECAUSE WE HAVE VARIABLE POLICY, YOU CAN'T USE THE ALLCONTACTS STATIC DATA HERE 
    let admin = allContacts?.data.find(
      (contactItem: any) =>
        contactItem.admin 
    );
    console.log(admin);
    let finance = allContacts?.data.find(
      (contactItem: any) =>

        contactItem.finance 
    );
    console.log(finance);
    let cleaning = allContacts?.data.find(
      (contactItem: any) =>
        contactItem.cleaning 
    );

    let adminEditBody = {
      id: admin?.id,
      fields: {
        first_name: admin?.first_name,
        last_name: admin?.last_name,
        phone_number: admin?.phone_number,
        email: admin?.email,
      },
    };

    let financeEditBody = {
      id: finance?.id,
      fields: {
        first_name: finance?.first_name,
        last_name: finance?.last_name,
        phone_number: finance?.phone_number,
        email: finance?.email,
      },
    };

    //For some reason, some of the old bookings in our old system didn't contain a cleaning corporate id associated to corpoate bookings
    if(cleaning)
    {
      let cleaningEditBody = {
        id: cleaning?.id,
        fields: {
          first_name: cleaning?.first_name,
          last_name: cleaning?.last_name,
          phone_number: cleaning?.phone_number,
          email: cleaning?.email,
        },
      };

      const editCleaningStatus = await fetchRequestJSON("PUT", Constants.API_PATH_CORP_CONTACT_EDIT, cleaningEditBody);
      if (editCleaningStatus.status !== "Success") {
        alert("Unsuccessfully edited corporate cleaning contact information.");
        return;
      }
    }

    console.log(adminEditBody);

    const editAdminStatus = await fetchRequestJSON("PUT", Constants.API_PATH_CORP_CONTACT_EDIT, adminEditBody);
    if (editAdminStatus.status !== "Success") {
      alert("Unsuccessfully edited corporate admin contact information.");
      return;
    }

    const editFinanceStatus = await fetchRequestJSON("PUT", Constants.API_PATH_CORP_CONTACT_EDIT, financeEditBody);
    if (editFinanceStatus.status !== "Success") {
      alert("Unsuccessfully edited corporate finance contact information.");
      return;
    }

    alert("Successfully edited corporate admin, cleaning, and finance contact information.");
    return "Success";
  };
  
  // TODO: Refactor this function.
  handleClick = async () => {
    if (this.state.fob_count < 0 || this.state.fob_count > 4 || this.state.fob_count % 1 !== 0) {
      alert("The number of FOBs requested must be an integer between 0 and 4");
      return;
    } else if (this.state.key_count < 0 || this.state.key_count > 4 || this.state.key_count % 1 !== 0) {
      alert("The number of keys requested must be an integer between 0 and 4");
      return;
    } else if (this.state.park_fob_count < 0 || this.state.park_fob_count > 4 || this.state.park_fob_count % 1 !== 0) {
      alert("The number of parking FOBs requested must be an integer between 0 and 4");
      return;
    } else if (this.state.mailkey_count < 0 || this.state.mailkey_count > 4 || this.state.mailkey_count % 1 !== 0) {
      alert("The number of mail keys requested must be an integer between 0 and 4");
      return;
    }

    // let bookingRate: number = this.state.monthly_rate;
    // if (this.state.pricing_type === "Nightly" || this.state.pricing_type === "nightly") {
    //   //nightly bookings need to be multiplied by 30 because they're not stored as nightly in the backend
    //   bookingRate = bookingRate * 30; 
    // }

    let booking_type = "monthly";
    if (this.state.pricing_type !== "Monthly" && this.state.pricing_type !== "monthly") {
      booking_type = "thirty_day";
    }
    
    let unit_id = -1;
    for (let i = 0; i < this.state.all_units.length; i++) {
      if (this.state.all_units[i].name === this.state.suite) {
        unit_id = this.state.all_units[i].id; 
      }
    }
    let addedTenant = false;
    let allAssociatedTenants: number[] = this.state.other_tenants;
    if (this.state.selectedAssociatedTenant) {
      
      if (!this.state.other_tenants.includes(this.state.selectedAssociatedTenant.id) && this.state.selectedAssociatedTenant.id !== this.state.tenant_id) {
        allAssociatedTenants = [...allAssociatedTenants, this.state.selectedAssociatedTenant.id,].sort();
        addedTenant = true;
      }
      else {
        alert("This tenant is already associated with the booking");
        return;
      }
    }
    // technically a tenant can be made with only a first name, but it would be extremely rare and is up to the sales representative's discretion
    else if (this.state.newfname !== "") {
      const newTenantBody = {
        first_name: this.state.newfname,
        last_name: this.state.newlname ?? "",
        email: this.state.newemail ?? "",
        phone_number: this.state.newphone ?? null
      }

      const newTenantStatus = await fetchRequestJSON("POST", Constants.API_PATH_TENANT_CREATE, newTenantBody);
      if (newTenantStatus.status !== "Success") {
        alert(newTenantStatus.data);
        this.props.handleEditBarToggle();
        return;
      }
      else {
        allAssociatedTenants = [...allAssociatedTenants, newTenantStatus.data.id,].sort();
        addedTenant = true;
      }
    }

    const { originalBookingData } = this.state;
    let fieldsToUpdate:any = {};

    const keysToCheck = [
      'booking_notes', 'check_in_notes', 'check_out_notes', 'confirmed', 
      'hard_checkout', 'pets', 'parking_requested', 'cleaning_frequency',
      'flag_reason', 'flagged', 'keys_confirm', 'payment_confirm', 
      'cleaning_confirm', 'other_tenants'
    ];
  
    keysToCheck.forEach((key:any) => {
      if (this.state[key] !== originalBookingData[key]) {
        fieldsToUpdate[key] = this.state[key];
      }
    });
  


    // let bookingEditBody: BookingEditBody = {
    //   id: this.state.booking_id,
    //   fields: {
    //     booking_notes: this.state.booking_notes,
    //     check_in_notes: this.state.check_in_notes,
    //     check_out_notes: this.state.check_out_notes,
    //     confirmed: this.state.confirmed,
    //     // booking_type: booking_type,
    //     // check_in: this.state.check_in,  /* TODO: Is this supposed to represent the check in date? */
    //     hard_checkout: this.state.hard_checkout,
    //     pets: this.state.pets,
    //     parking_requested: this.state.parking_requested,

    //     cleaning_frequency: this.state.cleaning_frequency,
    //     flag_reason: this.state.flag_reason,
    //     flagged: this.state.flagged,
    //     // monthly_rate: bookingRate,
    //     // light: this.state.light,

    //     keys_confirm: this.state.keys_confirm,
    //     payment_confirm: this.state.payment_confirm,
    //     cleaning_confirm: this.state.cleaning_confirm,

    //     other_tenants: allAssociatedTenants,
    //   },
    // };
    let bookingEditBody: BookingEditBody = {
      id: this.state.booking_id,
      fields: fieldsToUpdate
    };

    if(addedTenant) {
      console.log("Inside associated tenants")
      bookingEditBody.fields.other_tenants = allAssociatedTenants
    } 

    // Check if fieldsToUpdate is empty
    if (this.isEmptyObject(fieldsToUpdate)) {
      alert("No changes to save.");
      return;
    }
    
    const NotificationHandler = this.props.ErrorNote;
    const editBookingStatus = await fetchRequestJSON("PUT", Constants.API_PATH_BOOKING_EDIT, bookingEditBody);
    if (editBookingStatus.status !== "Success") {
      NotificationHandler("Error", "Error", "Unsuccessfully edited booking information");
      this.props.handleEditBarToggle();
      return;
    }
    
    NotificationHandler("Success", "Success", "Succesfully Edited Booking!");
    this.props.handleEditBarToggle();

    let update = store.getState().bookingReducer.profileUpdate;
    update();
  };

  loadUserType = async() => {
    let type = await store.getState().userReducer.userType;
    console.log(type)
    this.setState({
      userType: type
    })
  }
  // Helper function to check if an object is empty
  isEmptyObject = (obj: object) => {
    return Object.keys(obj).length === 0;
  }

  // addNewTenant = async () => {
  //   let body = {
  //     booking_id: this.state.booking_id,
  //     tenant_data: {
  //       id: this.state.selected,
  //       first_name: this.state.newfname,
  //       last_name: this.state.newlname,
  //       email: this.state.newemail,
  //       phone: this.state.newphone,
  //     }
  //   }

  //   if (this.state.newfname == "" || this.state.newlname == "" || this.state.newemail == "" || this.state.newphone == 0) {
  //     alert("Missing Info")
  //     return;
  //   }

  //   let newTenant = await fetchRequestJSON("POST", Constants.API_PATH_ADD_OTHER_TENANT, body);
  //   if (newTenant.status == "Success") {
  //     alert("Added new tenant")
  //   }
  // }

  // TODO: Refactor this function by rewriting this code without using bind().
  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // TODO: Refactor this function by rewriting this code without using bind().
  handleFobsAndKeys = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = parseInt(this.target.value);
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  }

  searchTenant = async (event: any) => {
    event.preventDefault();
    this.setState({
      showCreateNewTenantButton: true,
      showCreateNewTenantForm: false,
      newfname: "",
      newlname: "",
      newemail: "",
      newphone: "",
      selectedAssociatedTenant: null,
      tenantList: null,
    })

    let tenants = await fetchGetJSON(`${Constants.API_PATH_TENANT_SEARCH}?q=${this.state.search.trim()}`,);
    console.log(tenants);

    if (tenants.status === "Success"){
      let tenantsFound = [];
      for (let i = 0; i < tenants.data.length; i++) {
        const tenant = {
          id: tenants.data[i].id,
          email: tenants.data[i].email ? tenants.data[i].email : "N/A",
          first_name: tenants.data[i].first_name ? tenants.data[i].first_name : "N/A",
          last_name: tenants.data[i].last_name ? tenants.data[i].last_name : "N/A",
          phone_number: tenants.data[i].phone_number ? tenants.data[i].phone_number : "N/A"
        };
        tenantsFound.push(tenant);
      }

      this.setState({tenantList: tenantsFound});
      console.log(tenantsFound);
    } 
    else {
      this.setState({tenantList: []});
    }
  };

  selectTenant = (event: any, tenantId: number) => {
    event.preventDefault();

    const tenant = this.state.tenantList.filter((tenant: any) => tenant.id === tenantId);

    if (tenant.length) {
      this.setState({
        selectedAssociatedTenant: tenant[0],
        newfname: tenant[0]?.first_name ?? "N/A",
        newlname: tenant[0]?.last_name ?? "N/A",
        newemail: tenant[0]?.email ?? "N/A",
        newphone: tenant[0]?.phone_number ?? "N/A",
      });
    }
    else {
      this.setState({
        selectedAssociatedTenant: null,
        newfname: "",
        newlname: "",
        newemail: "",
        newphone: null,
      });
    }
  }

  createNewTenantForm = (event: any) => {
    event.preventDefault()
    this.setState({ 
      showCreateNewTenantForm: true,
      selectedAssociatedTenant: null,
      newfname: "",
      newlname: "",
      newemail: "",
      newphone: "",
    });
  }

  handleKeyDown = (event: any) => {
    if(event.key == 'Enter') {
      this.searchTenant(event)
    }
  }

  tableRow = () => {
    return (
      <>
        { 
          this.state.tenantList.map((tenant: any) => {
            return (
              <tr className="cursor-pointer border-t border-gray-400 w-full" key={tenant.id}>                        
                {/* Full Name */}
                <td className="w-16 font-light text-sm text-gray-500 px-1 py-4">
                  <div className="flex flex-col">
                    <span className="text-base text-gray-600 font-semibold truncate w-32">
                      {tenant.first_name} {tenant.last_name}
                    </span>
                  </div>
                </td>

                {/* Email */}
                <td className="hidden lg:table-cell w-24 px-1 py-4 text-sm">
                  {tenant.email}
                </td>
                
                <td className="w-16 px-1 py-4">
                  <div className="flex flex-row justify-end">
                    <BiBookAdd
                      onClick={(e) => {this.selectTenant(e, tenant.id)}}
                      className={"text-green-500 text-2xl mr-2 hover:text-green-400"}
                    />
                  </div>
                </td>
              </tr>
            );
          })
        }
      </>
    )
  }

  /* TODO: Extract the edit bookings form components into their own component files, like the textboxes,labels, and form sections. */
  render() {
    let editclassName = "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    if (this.props.toggle) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }
    //     let editType = "booking";
    //     let inactive = "";
    //     let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let CorporateDetails = [];
    if (!this.state.corporate) {
      ManagedModel = "text-gray-300 ";
      RentalModel = "font-bold text-gray-600";
      CorporateDetails = [""];
    } else {
      ManagedModel = "font-medium text-gray-600 ";
      RentalModel = "text-gray-300 ";
      CorporateDetails = [
        <label className="block pl-3 mb-8">
          <span className="text-gray-400 text-xs">Corporate Name</span>
          <select
            id="corporate_name"
            onChange={this.handleInputChange}
            value={this.state.corporate_name}
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          >
            <option value={this.state.corporate_name}>{this.state.corporate_name}</option>
            {this.state.corporates.map((corp: Corporate) => {
              return <option value={corp.corporate_name}>
                {corp.corporate_name}
              </option>
            })}
          </select>
        </label>,
      ];
    }

    //this is to make sure the check in and check out dates are correct and formatted properly
    // let proper_check_in_string = this.state.check_in
    //   .toISOString();
    // let proper_check_out_string = this.state.check_out
    //   .toISOString();

    let content = (
      <form className="  flex flex-col pl-4 sm:pl-6 pr-6 sm:pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">General Info</span>

        {/* <label className="block pl-3 mb-5">
                <span className="text-gray-400 text-xs">Management Start Date</span>
                <input id="check_in" onChange={this.handleDateChange} value={ inputISO(this.state.check_in) } type="date" className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" placeholder=""/>
         </label> */}

        {/*REMOVE BELOW, SHOULD NEVER ALLOW FOR EDIT OF TENANT IN BOOKING */}
        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Tenant Name</span>
          <input
            id="tenant_name"
            onChange={this.handleInputChange}
            value={this.state.tenant_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Tenant Email</span>
          <input
            id="tenant_email"
            onChange={this.handleInputChange}
            value={this.state.tenant_email}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Tenant Phone</span>
          <input
            id="tenant_phone"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.tenant_phone}
            type="string"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Check In</span>
          <input
            id="check_in"
            onChange={this.handleDateChange}
            value={proper_check_in_string}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Check out</span>
          <input
            id="check_out"
            onChange={this.handleDateChange}
            value={proper_check_out_string}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Unit</span>
          <select
            id="suite"
            onChange={this.handleInputChange}
            value={this.state.suite}
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder={this.state.suite}
          >
            <option selected disabled hidden></option>
            {this.state.all_units.map((name) => 
              <option value={name.name}>{name.name}</option>
            )}
          </select>
        </label> */}

        {/* <div className=" flex flex-row pl-3 my-5 ">
          <span className={RentalModel + " transition-all duration-300 text-sm pr-3 "}>Retail Client</span>
          <ToggleSwitch
            id="corporate"
            handleStateToggle={this.handleToggleChange}
            size="lg"
            onoff={this.state.corporate}
          />
          <span className={ManagedModel + "transition-all duration-300 text-sm pl-3 "}>Corporate Client</span>
        </div> */}

        {CorporateDetails}

        <span className="text-gray-600 text-md font-bold mb-4 mt-10">Booking Details</span>
        {
          (!this.state.netZero || this.state.userType.includes("Admin")) && 

          <div className="ml-3 mb-5">
            <input 
              id="bordered-checkbox-1" 
              type="checkbox" 
              // value={this.state.confirmed} 
              checked={this.state.confirmed}
              onChange={this.handleConfirmed}
              name="bordered-checkbox" 
              className="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 focus:ring-green-500 dark:focus:ring-green-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            <label 
              htmlFor="bordered-checkbox-1" 
              className="py-4 ml-2 w-full text-sm font-medium text-gray-600 dark:text-gray-300"
              >
              Confirm Booking
            </label>
          </div>
        }


        {/* <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Pricing Type</span>
          <select
            id="pricing_type"
            onChange={this.handleInputChange}
            value={this.state.pricing_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Monthly</option>
            <option>30-Day</option>
            <option>Nightly</option>
            <option>Other</option>
          </select>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Rate</span>
          <input
            id="monthly_rate"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.monthly_rate}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        <div className="ml-3 mb-5 px-3 border rounded-sm border-solid py-4 shadow-sm grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex flex-row items-center justify-between mb-2 sm:pr-2">
            <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>Pets</span>
            <ToggleSwitch
              id="pets"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.pets}
            />
          </div>

          {/* <div className="flex flex-row items-center justify-between  mb-2  ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Manual Invoicing
            </span>
            <ToggleSwitch
              id="manual_invoicing"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.manual_invoicing}
            />
          </div> */}

          {/* <div className="flex flex-row items-center justify-between  mb-2 sm:pr-2">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Auto HST
            </span>
            <ToggleSwitch
              id="auto_HST"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.auto_HST}
            />
          </div> */}

          <div className="flex flex-row items-center justify-between  mb-2 ">
            <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>Hard Check Out</span>
            <ToggleSwitch
              id="hard_checkout"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.hard_checkout}
            />
          </div>

          {/* <div className="flex flex-row items-center justify-between mb-2 sm:pr-2 ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Cleaning Fee
            </span>
            <ToggleSwitch
              id="cleaning_fee"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.cleaning_fee}
            />
          </div> */}

          <div className="flex flex-row items-center justify-between mb-2 ">
            <span className=" transition-all duration-600 text-gray-600 text-sm mr-3">Parking Required</span>
            <ToggleSwitch
              id="parking_requested"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.parking_requested}
            />
          </div>
        </div>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Cleaning Frequency</span>
          <select
            id="cleaning_frequency"
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            onChange={this.handleCleaningChange}
            value={this.state.cleaning_frequency}
          >
            <option value="bi_weekly">Bi-Weekly</option>
            <option value="weekly">Weekly</option>
            <option value="none">Never</option>

          </select>
        </div>

        {/* <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Agreement Document</span>
          <select
            id="pricing_type"
            onChange={this.handleInputChange}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Deposit + Pet + Cleaning</option>
            <option>Deposit + No Pet </option>
            <option>No Deposit + No Pet</option>
          </select>
        </div> */}

        {/*THIS IS COMMENTED OUT BELOW BECAUSE WE HAVE A FUNCITONALITY THAT ALRAEDY DOES THIS IN CHECK IN TAB */}
        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Fobs Needed</span>
          <input
            id="fob_count"
            onChange={this.handleFobsAndKeys}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.fob_count}
            type="number"
            min="0"
            step="1"
            max="4"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="0"
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Keys Needed</span>
          <input
            id="key_count"
            onChange={this.handleFobsAndKeys}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.key_count}
            type="number"
            min="0"
            step="1"
            max="4"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="0"
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Parking Fobs Needed</span>
          <input
            id="park_fob_count"
            onChange={this.handleFobsAndKeys}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.park_fob_count}
            type="number"
            min="0"
            step="1"
            max="4"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="0"
          />
        </label> */}

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Mail Keys Needed</span>
          <input
            id="mailkey_count"
            onChange={this.handleFobsAndKeys}
            onWheel={(e) => {e.currentTarget.blur()}}
            value={this.state.mailkey_count}
            type="number"
            min="0"
            step="1"
            max="4"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="0"
          />
        </label > */}

        <span className="text-gray-400 text-xs ml-3">Booking Light Status</span>
        <br />
        <div className="  grid grid-cols-1 gap-1 px-3 ml-3 py-2 bg-gray-50 border rounded-sm">
          <div className={" block pt-1 pb-2 border-b   "}>
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Key
              </span>
              <ToggleSwitch
                id="keys_confirm"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.keys_confirm}
              />
            </div>
          </div>

          <div className={" block  py-2 border-b   "}>
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Cleaning
              </span>
              <ToggleSwitch
                id="cleaning_confirm"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.cleaning_confirm}
              />
            </div>
          </div>

          <div className="py-2">
            <div className="flex flex-row items-center justify-between ">
              <span
                className={
                  " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                }
              >
                Payment Confirmed
              </span>
              <ToggleSwitch
                id="payment_confirm"
                handleStateToggle={this.handleToggleChange}
                className="justify-center align-center"
                size="sm"
                onoff={this.state.payment_confirm}
              />
            </div>
          </div>
        </div>

        {/* <div className="block pl-3 mb-6 ">
          <span className="text-gray-400 text-xs">Suite Status</span>
          <select
            className="block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            onChange={this.handleLightChange}
            defaultValue={this.state.light}
          >
            <option value="red">Red</option>
            <option value="blue">Blue</option>
            <option value="green">Green</option>
          </select>
        </div> */}

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Booking Notes</span>
          <textarea
            id="booking_notes"
            onChange={this.handleInputChange}
            value={this.state.booking_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Check In Notes</span>
          <textarea
            id="check_in_notes"
            onChange={this.handleInputChange}
            value={this.state.check_in_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Check Out Notes</span>
          <textarea
            id="check_out_notes"
            onChange={this.handleInputChange}
            value={this.state.check_out_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>

        <span className="text-gray-600 text-md font-bold mb-4 mt-2">Add Associated Tenants</span>

        <div>
          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Tenant</span>
            
            {/* Changed */}
            <section className="flex flex-row">
              <span className="flex flex-row border rounded-sm w-full lg:w-3/3 h-8 py-1">
                <MdSearch className="text-xl text-gray-400 mx-1" />
                <input
                  className="text-sm searchBox w-full"
                  onChange={this.handleInputChange}
                  placeholder= "Search Tenant by Email or Name"
                  id="search"
                  value={this.state.search}
                  onKeyDown={this.handleKeyDown}
                />
              </span>
              <button className="ml-2 bg-green-500 hover:bg-green-400 rounded-sm text-sm py-1 px-2 text-white" onClick={this.searchTenant}>Search</button>
            </section>
          </label>{" "}
        </div>

        {/* Tenants Table */}
        {
        this.state.tenantList &&
        <div className="">
          <div className="text-green-500 font-bold text-lg underline">Tenant Search Results</div>
          <div className="flex flex-row mt-3">
            <table className="table-auto w-full mb-2">
              <thead>
              <tr className="text-left font-normal text-gray-700 " key={0}>
                <th className="py-2  ">Full Name</th>
                <th className="py-2 hidden lg:table-cell">Email</th>
                {/* Options */}
                <th className="py-2  "></th>
              </tr>
              </thead>
                <tbody>
                  {this.tableRow()}
                </tbody>
            </table>
          </div>
          {/* {
              this.state.selectedAssociatedTenant &&
              <>
                  <p className="text-gray-700 font-bold">Selected Tenant: </p>
                  <p>Full Name: <u><span className="text-green-400">{this.state.selectedAssociatedTenant?.first_name} {this.state.selectedAssociatedTenant?.last_name}</span></u>, Email: <u><span className="text-green-400">{this.state.selectedAssociatedTenant?.email}</span></u></p>
              </>
          } */}
      </div>
        }

        { this.state.showCreateNewTenantButton && <div className="flex flex-row justify-center mt-3 pl-6">
          <button
            onClick={this.createNewTenantForm}
            className="shadow-sm transition text-md duration-200 w-48 bg-green-500 hover:bg-green-400 
            text-white font-bold mb-10 py-2 rounded-md flex flex-row justify-center"
          >
            Create a New Tenant
          </button>
        </div>
        }

        { (this.state.showCreateNewTenantForm || this.state.selectedAssociatedTenant) && <>
          <label className="block pl-3 mb-5 mt-5">
            <span className="text-gray-400 text-xs">Tenant First Name</span>
            <input
              id="newfname"
              value={this.state.newfname}
              onChange={this.handleInputChange}
              type="text"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              placeholder=""
              disabled={this.state.selectedAssociatedTenant}
            />
          </label>

          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Tenant Last Name</span>
            <input
              id="newlname"
              value={this.state.newlname}
              onChange={this.handleInputChange}
              type="text"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              placeholder=""
              disabled={this.state.selectedAssociatedTenant}
            />
          </label>

          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Tenant Email</span>
            <input
              id="newemail"
              value={this.state.newemail}
              onChange={this.handleInputChange}
              type="text"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              placeholder=""
              disabled={this.state.selectedAssociatedTenant}
            />
          </label>

          <label className="block pl-3 mb-5">
            <span className="text-gray-400 text-xs">Tenant Phone</span>
            <input
              id="newphone"
              value={this.state.newphone}
              onChange={this.handleInputChange}
              type="text"
              className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              placeholder=""
              disabled={this.state.selectedAssociatedTenant}
            />
          </label>
        </>
        }

        {/* <label className="block pl-3 mb-5">
          <button
            className="transition duration-200 w-40 hover:bg-gray-400 px-6 py-2 bg-gray-500 mx-auto rounded-md text-white font-bold shadow-sm mb-4 "
            onClick={this.addNewTenant}
            type="button"
          >
            Add Tenant
          </button>
        </label> */}
      </form>
    );

    return (
      <div className={editclassName + " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "}>
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>

        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">Edit Booking</span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">{this.state.booking_id}</span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row mt-3">
          <button
            onClick={() => this.handleClick()}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default EditBooking;
