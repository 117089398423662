import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import EditOptionPanel from "../EditOptionPanel";
import { BsFillFlagFill } from "react-icons/bs";
import EditOwner from "./EditOwner";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { ImCancelCircle } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import EditBlackout from "./EditBlackout";
import { Route, withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";

type Props = {
  //   id: number;
  //   start_date: string;
  //   end_date: string;
  //   reason: string;
  //   unit_id: number;
  //   history: any;
  ErrorNote: any;
};

type State = {
  id: number;
  start_date: string;
  end_date: string;
  reason: string;
  unit_id: number;
  editToggle: boolean;
  redirect: boolean; 
};

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

const dayjs = require('dayjs');
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

class BlackoutProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      id: -1,
      start_date: "",
      end_date: "",
      reason: "",
      unit_id: -1,
      editToggle: false,
      redirect: false
    };
  }

  componentDidMount = async () => {
    let blackoutID: number = +new URLSearchParams(window.location.search).get("id");
    await this.getProfileData(blackoutID); 
    store.dispatch({ type: "blackoutProfileUpdate", profileUpdate: this.getProfileData });
    // let all_blackouts = await fetchGetJSON(
    //     Constants.API_PATH_BLACKOUT_GET_ALL
    // ); 
    // if(all_blackouts.status == "Success") {
    //     for(let i = 0; i < all_blackouts.data.length; i++) {
    //         let this_blackout = all_blackouts.data[i]; 
    //         if(this_blackout.id === blackoutID) {
    //             this.setState({
    //                 id: blackoutID, 
    //                 start_date: this_blackout.start_date, 
    //                 end_date: this_blackout.end_date, 
    //                 reason: this_blackout.reason, 
    //                 unit_id: this_blackout.unit_id,
    //                 redirect: false
    //             }); 
    //             break;
    //         }
    //     }
    // }else {
    //     alert("Error pulling blackouts"); 
    // }
    // let owner = await fetchGetJSON(
    //   `${Constants.API_PATH_BLACKOUT_GET_ALL}${this.state.id}`
    // );
    // let ownerData = owner.data;
    // this.setState({
    //   owner_name: `${ownerData.first_name} ${ownerData.last_name} (ID: ${ownerData.id})`,
    //   owner_email: ownerData.email,
    //   owner_phone: ownerData.phone_number,
    //   owner_notes: ownerData.notes,
    //   // flagged: false,
    //   // editToggle: false,
    //   // toggleAgreement : false,
    // });
    // this should be in create unit owner in the future but for now this is a test
    // store.dispatch({type: 'addOwner', newOwner: {id: 1, email: this.state.owner_email}})
    // console.log(store.getState().unitOwnerReducer.unitOwners)
  };

  getProfileData = async(blackoutID: number) => {
    const blackout = await fetchGetJSON(
      `${Constants.API_PATH_BLACKOUT_GET}${blackoutID}`
    ); 
    console.log(blackout);
    if (blackout.status === "Success") {
      this.setState({
        id: blackoutID,
        start_date: blackout.data?.start_date ? blackout.data.start_date : "N/A",
        end_date: blackout.data?.end_date ? blackout.data.end_date : "N/A",
        reason: blackout.data?.reason ? blackout.data.reason : "N/A",
        unit_id: blackout.data?.unit_id ? blackout.data.unit_id : -1,
        redirect: false
      })
    } else {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler("Error", "Blackout profile data failed to load", "")
    }
  }

  deleteBlackout = async () => {
    let del = await fetchRequestJSON('PUT', Constants.API_PATH_BLACKOUT_SHORTEN, { id: this.state.id }); 
    
    const NotificationHandler = this.props.ErrorNote;
    
    if(del.status === "Success") {
      this.setState({ redirect: true });
      NotificationHandler(del.status, del.status, del.data?.id ? `Shortened the blackout with ID ${del.data.id} from ${this.state.end_date} to ${del.data.end_date}` : 'Successfully deleted blackout')
    }
    else {
      NotificationHandler(del.status, del.status, del.data)
    }
  };

  // handleClick = () => {
  //   console.log("click");
  // };

  handleEdit = () => {
      this.setState({editToggle: true}); 
  }

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
      // alert("Edit options for owner should not here right now ")
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();
    this.setState(stateObject);
  };

  render() {
    let bgFocus;

    if (this.state.editToggle === true) {
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }
    let editBlackout = <></>; 

    if(this.state.editToggle === true) {
        editBlackout = <EditBlackout
        toggle={this.state.editToggle}
        handleEditBarToggle={this.handleEditBarToggle}
        blackoutData={this.state}
        handleInputChange={this.handleInputChange}
        handleToggleChange={this.handleToggleChange}
      />; 
    }
    if (this.state.redirect) {
        return(
            <Redirect to="/User/Units"></Redirect>
        ); 
    }
    return (
        
      <div className=" bg-white font-display lg:rounded-tl-lg  ">
          
        {bgFocus}
        {editBlackout}

        <BreadCrumb
          rootPage="Units > BlackoutProfile"
          subPage={" Blackout(ID: " + this.state.id.toString() + ")"}
          sideButton=""
          buttonAction={""}
        />

        <div className="  px-4 lg:px-10 mx-auto border rounded-lg py-10 bg-white">
          <div className="flex flex-row mb-0">
            <div className="flex flex-col w-1/2">
              <h2 className="px-2 -mt-1 mb-1 font-bold">
                Blackout ID: {this.state.id}
              </h2>
              <div className="mx-2 mt-4 rounded-sm py-2 px-3 border grid grid-cols-2 gap-1 mb-1">
                <div className="flex flex-col">
                    <span className="text-sm pb-1">Start Date:</span>
                    <span className="text-sm text-gray-500">
                    {this.state.start_date.substr(0, 10)}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm pb-1">End Date:</span>
                    <span className="text-sm text-gray-500">
                    {this.state.end_date.substr(0, 10)}
                    </span>
                </div>
              </div>
              <div className="mx-2 mt-4 rounded-sm py-2 px-3 border grid grid-cols-2 gap-1 mb-1">
                <div className="flex flex-col">
                    <span className="text-sm pb-1">Unit ID:</span>
                    <span className="text-sm text-gray-500">
                    {this.state.unit_id}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm pb-1">Reason:</span>
                    <span className="text-sm text-gray-500">
                    {this.state.reason}
                    </span>
                </div>
              </div>
            </div>

            {/* Right Side */}

            <div className="flex flex-row w-1/2 items-start justify-end">
              <button
                key={"Edit"}
                onClick={() => this.handleEdit()}
                className="mx-2 group pb-1"
              >
                <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
                  Edit
                </span>
                <MdEdit className="text-gray-500 group-hover:text-gray-400 text-xl  mx-auto" />
              </button>
              <button
                onClick={() => this.deleteBlackout()}
                key={"Delete"}
                className="mx-2 group pb-1"
              >
                <span className="font-display text-gray-500 group-hover:text-gray-400 text-xs mx-auto ">
                  Delete
                </span>
                <ImCancelCircle className="text-red-400 group-hover:text-red-300 text-xl  mx-auto" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(BlackoutProfile);
