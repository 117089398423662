import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { RouteProps } from "react-router";
import Handler from "../utils/authVerify";
import store from "../redux/store";
import { connect } from "react-redux";
import { getCurrentUser } from "aws-amplify/auth";

export function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export type ProtectedRouteProps = {} & RouteProps;

const mapStateToProps = (state: any) => ({
  routeProps: state.authReducer.isLoggedIn,
  helperText: state.authReducer.helperText,
});

async function privateViewCanEnter() {
  try {
    await getCurrentUser();
    return true;
  } catch {
    return false;
  }
}

function PrivateRoute({ ...routeProps }: ProtectedRouteProps) {
  useEffect(() => {
    const cookie = getCookie("accessToken");
    const getData = async () => {
      // const result = await Handler({ token: cookie });
      const result = await privateViewCanEnter();
      if (result == true) {
        console.log("success auth");
        store.dispatch({
          type: "authSuccess",
          message: "Successfully authenticated",
        });
      } else {
        store.dispatch({ type: "authError", message: "Error authenticating" });
      }
    };
    getData();
  }, []);
  console.log(window.location.pathname);
  if (
    routeProps.path === "/Client-Invoice/" ||
    (window.location.pathname === "/Client-Invoice/" &&
      new URLSearchParams(window.location.search).get("signature")) ||
    (window.location.pathname === "/Client-Invoice/" &&
      new URLSearchParams(window.location.search).get("trnId")) ||
      window.location.pathname.includes("/extension/requestform/") ||// WE NEED TO REMOVE THIS BAD BAD BAD BAD BAD
      window.location.pathname.includes("/unit/getpublic/") ||// WE NEED TO REMOVE THIS BAD BAD BAD BAD BAD
      window.location.pathname.includes("/bookings/checkout/getpublic/") ||// WE NEED TO REMOVE THIS BAD BAD BAD BAD BAD
      window.location.pathname.includes("/Consolidated-Client-Invoice/") || //WE NEED TO REMOVE THIS BAD BAD BAD BAD BAD
      window.location.pathname.includes("/paymentconfirm") ||
      window.location.pathname.includes("/bookings/bookingconfirmation/") ||
      window.location.pathname.includes("/bookings/bookingPortal/")
  ) {
    return <Route {...routeProps} />;
  }

  const authState = store.getState().authReducer;
  const loginState = store.getState().reducer;
  if (
    authState.helperText === "not authenticated" &&
    authState.isLoggedIn == false
  ) {
    //return <div> Loading</div>;
    return <></>
  } else if (authState.isLoggedIn || loginState.isLoginSuccess) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: "/" }} />;
  }
}

export default connect(mapStateToProps)(PrivateRoute);
