import { Component } from "react";
import { BiMailSend } from "react-icons/bi";
import { fetchGetJSON, fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";

interface Props {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  invoice_id: number; 
  tenant_id: number;
  email: string;
  name: string;
}

interface ModalState {
  title: string;
  onoff: boolean;
  rate_change_date: any;
  rate: number;
  email_list: string;
  tenant_email: string;
  tenant_name: string;
}

class SendInvoice extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      rate_change_date: "",
      rate: 0,
      email_list: "",
      tenant_email: "",
      tenant_name: ""
    };
  }

  handleError = (type : string, title : string, message : string, ) => {
    store.dispatch({
        type: type.toUpperCase(),
        title: title,
        message: message

        }
    )
  }

  sendInvoice = async () => {
    let NotificationHandler = this.handleError; 
    let res = await fetchRequestJSON("POST", Constants.API_PATH_SEND_INVOICE_EMAIL, {invoice_id: this.props.invoice_id});
    console.log(res);
    if(res.status) {
      if(res.status === "Success") {
        NotificationHandler("Success", `Successfully Sent Email`, "");
      } else {
        NotificationHandler("Error", `Could not send Email`, ""); 
      }
    }
  }

  componentDidUpdate = async () => {
    // if(this.state.tenant_email === "" && this.state.tenant_name === "") {
    //   let res = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL); 
    //   let t_email = ""; 
    //   let t_name = "";
    //   if(res.data) {
    //     for(let i = 0; i < res.data.length; i++) {
    //       let cur_id = res.data[i].id; 
    //       if(cur_id === this.props.tenant_id) {
    //         t_email = res.data[i].email; 
    //         t_name = res.data[i].first_name + " " + res.data[i].last_name;
    //         console.log("HII");
    //         this.setState({
    //           tenant_email: t_email,
    //           tenant_name: t_name
    //         })
    //         break; 
    //       }
    //     }
    //   }
    // }
    
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    // let inactive = "";

    // if (this.state.building === ''){
    //   inactive = "opacity-50 pointer-events-none "
    // }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <BiMailSend className="text-gray-600 text-3xl mb-3" />

          <span className="text-xl font-bold text-gray-800 text-left">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form>
            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block w-full">
                <span className="text-gray-400 text-m">Send To: </span>
                <span className="text-m pt-1 text-black ">
                  {this.props.name}
                </span>

                <br></br>

                <span className="text-gray-400 text-m">Email: </span>
                <span className="text-m pt-1 text-black ">
                  {this.props.email}
                </span>
                {/* <input
                  id="rate"
                  onChange={this.handleInputChange}
                  value={this.state.email_list}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="eg. Opus"
                />
                <span className="text-2xs pt-1 text-gray-300 ">
                  Auto Searches Email
                </span> */}
              </label>
            </div>
            <div className="flex flex-row justify-start w-full mt-6">
              <button
                onClick={() => {
                  this.sendInvoice(); 
                  this.props.handleModalToggle() }
                }
                className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Send Invoice
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SendInvoice;
