export type State = {
    parking: any[];
    curId: number;
    listUpdate: any;
    profileUpdate: undefined;
    calendarUpdate: undefined;
};
const initialState: State = {
    parking: [],
    curId: -1,
    listUpdate: undefined,
    profileUpdate: undefined,
    calendarUpdate: undefined
};

type Action = 
 | {type: "parkingIdTransfer"; id: number}
 | {type: "parkingListUpdate"; listUpdate: any}
 | {type: "parkingProfileUpdate"; profileUpdate: any}
 | {type: "parkingCalendarUpdate"; calendarUpdate: any}

export const parkingReducer = (state = initialState, action:Action): State => {
    switch (action.type) {
        case "parkingCalendarUpdate":
            state.calendarUpdate = action.calendarUpdate
            return state;
        case "parkingProfileUpdate":
            state.profileUpdate = action.profileUpdate
            return state;
        case "parkingIdTransfer":
            state.curId = action.id;
            return state;
        case "parkingListUpdate":
            state.listUpdate = action.listUpdate;
            return state;
        default:
            return state;
    }
}