import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { getCurrentUser } from "aws-amplify/auth";


type Props = {
  handleEditBarToggle: any;
  toggle: any;
}

interface NewInvoiceState {
  submitted_by: string;
  transaction_date: string;
  amount: number;
  payment_method: string;
  unit_id: number;
  account: string;
  suite_name: string;
  approved_by_owner: boolean;
  hst: boolean;
  chargeback_to_owner: boolean;
  notes: string;
  department: string;
}

const paymentMethods = [
  "Credit",
  "Cheque",
  "Certified Cheque",
  "Cash",
  "TD Tool",
  "EFT",
];
const accounts = [
  "1000 Bank",
  "1090 Petty cash",
  "1100 Accounts receivable",
  "1200 Prepaid rent",
  "1290 Prepaid supplies",
  "1300 Suspense account",
  "1500 New Unit Setup",
  "1501 Replacement furn&fix",
  "1505 Accumulated depreciation",
  "1550 Intangible assets",
  "1555 Accumulated amortization",
  "2000 Accounts payable and accrued liabilites",
  "2005 Credit card payable",
  "2010 HST payable",
  "2020 Unearned rent",
  "2040 Security deposits",
  "2050 Due to property owners",
  "2090 Income tax payable",
  "3000 Loans payable",
  "3500 Common stock",
  "4000 Retained earnings",
  "5000 Rental revenue",
  "5010 Furnishing project revenue",
  "5020 Real estate commissions",
  "5040 Referrals",
  "6000 Owners rent",
  "6002 Refunded rent",
  "6001 Parking rent",
  "6010 Unit costs general",
  "6020 Cleaning",
  "6030 Hydro",
  "6040 Cable and internet",
  "6050 Steam cleaning",
  "6065 Contractor",
  "6080 Maintenance supplies",
  "6090 Laundry",
  "6903 Furnishing project costs",
  "7001 Advertising online",
  "7030 Advertising offline",
  "7100 Tech stuff",
  "7200 Business general",
  "7205 Business rent",
  "7206 Furniture storage",
  "7210 Business transport",
  "7220 Professional fees",
  "7225 Business insurance",
  "7230 Offshore team",
  "7231 Business travel",
  "7235 Business meals",
  "7240 Business memberships",
  "7250 Business bank charges",
  "7260 Business referrals",
  "7265 Business CC fees",
  "7300 Call service",
  "7301 Business phone",
  "8000 Salaries full time",
  "8500 Depreciation and amortization",
  "8800 Loan interest",
  "9000 Corporate tax",
  "9500 Dividends",
];

const departments = ["Sky View Suites", "SVS Relo", "SVS Realty", "StayWell"];

class NewExpense extends Component<Props, NewInvoiceState> {
  constructor(props: any) {
    super(props);

    this.state = {
      submitted_by: "",
      transaction_date: "",
      amount: 0,
      payment_method: "",
      unit_id: -1,
      account: "",
      suite_name: "",
      approved_by_owner: false,
      hst: false,
      chargeback_to_owner: false,
      notes: "",
      department: "",
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleCheckboxChange = (event: any) => {
    // Excluding our usual event.preventDefault() statement enables single-click
    // (rather than double-click) checkbox checking and unchecking.
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.checked;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  }

  handleToggleChange = (id: any, state: boolean) => {
    let toggleStates = ['damages', 'parking', 'first_rent', 'confirmed']
    let stateObject = function (this: typeof id) {
      let returnObj: any = {};
      for(let i = 0; i < toggleStates.length; i++) {
        if(id !== toggleStates[i]) {
          returnObj[toggleStates[i]] = false 
        }
      }
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    }();

    this.setState(stateObject);
  };

  componentDidMount = async () => {
    try {
      let res = await getCurrentUser();
      console.log(res)
      this.setState({submitted_by: res.username})
    } catch {
    }
  }

  createAccountItems() {
    let items = [];
    for (let i = 0; i < accounts.length; i++) {
      items.push(
        <option
        >
          {accounts[i]}
        </option>
      );
    }
    return items;
  }

  createPaymentItems() {
    let items = [];
    for (let i = 0; i < paymentMethods.length; i++) {
      items.push(
        <option
        >
          {paymentMethods[i]}
        </option>
      );
    }
    return items;
  }

  createDepartmentItems() {
    let items = [];
    for (let i = 0; i < departments.length; i++) {
      items.push(
        <option
        >
          {departments[i]}
        </option>
      );
    }
    return items;
  }
  
  handleSubmit = async () => {
    let body = {
      submitted_by: this.state.submitted_by,
      transaction_date: this.state.transaction_date.split("T")[0],
      amount: this.state.amount,
      payment_method: this.state.payment_method,
      account: this.state.account,
      suite_name: this.state.suite_name,
      approved_by_owner: this.state.approved_by_owner,
      hst: this.state.hst,
      chargeback_to_owner: this.state.chargeback_to_owner,
      notes: this.state.notes,
      department: this.state.department
    }
    let message = "Missing: ";
    if (body.submitted_by == "") { message += "Submitted By, "; }
    if (body.transaction_date == "") { message += "Transaction Date, "; }
    if (body.payment_method == "") { message += "Payment Method, "; }
    if (body.account == "") { message += "Account, "; }
    if (body.suite_name == "") { message += "Suite Name, "; }
    // if (body.approved_by_owner == false) { message += "Approved By Owner, "; }
    // if (body.hst == false) { message += "HST Included, "; }
    // if (body.chargeback_to_owner == false) { message += "Chargeback To Owner, "; }
    if (body.department == "") { message += "Department "; }
    if (message != "Missing: ") {
      alert(message);
    } else {
      let res = await fetchRequestJSON("POST", Constants.API_PATH_EXEPENSES_CREATE, body);
      if (res.status == "Success") {
        alert("Success");
        this.props.handleEditBarToggle();
        let updateList = store.getState().financeReducer.expenseUpdate;
        await updateList();
        this.clearForm();
      }
    }
  };

  clearForm() {
    let inputBoxes = document.querySelectorAll("input");
    for (let i = 0; i < inputBoxes.length; i++) {
      // Delete input box content for text, date, and checkbox separately.
      if (inputBoxes[i].type === "text") {
        inputBoxes[i].value = "";
      } else if (inputBoxes[i].type === "date") {
        inputBoxes[i].value = "";
      } else if (inputBoxes[i].type === "checkbox") {
        inputBoxes[i].checked = false;
      }
    }

    let textAreas = document.querySelectorAll("textarea");
    for (let i = 0; i < textAreas.length; i++) {
      textAreas[i].value = "";
    }
  }

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "New Expense";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let corporateInfo;
    let BookingID = <></>;

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className=" flex flex-col pl-6 pr-12 py-4">
        {/*
        <div className={" pl-3 block w-1/2  my-5 "}>
          <div className="flex flex-row items-center justify-between ">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Booking Related
            </span>

            <ToggleSwitch
              id="booking_related"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.props.bookingId ? true : this.state.booking_related}
              readonly={this.props.bookingId ? true : false}
            />
          </div>
        </div>
        

        (this.state.booking_related == true || this.props.bookingId) && <label className=" fade-in block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Booking ID</span>
          <input
            id="booking_id"
            onChange={this.handleInputChange}
            value={this.props.bookingId ? this.props.bookingId : this.state.booking_id}
            type="number"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
            readOnly={this.props.bookingId  ? true : false}
          />
            </label>*/}

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Transaction Date *</span>
          <input
            id="transaction_date"
            onChange={this.handleInputChange}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Amount *</span>
          <input
            id="amount"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Suite Name *</span>
          <input
            id="suite_name"
            onChange={this.handleInputChange}
            onWheel={(e) => {e.currentTarget.blur()}}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Payment Method *</span>
          <input
            list="payment_methods"
            id={"payment_method"}
            name="payment_method"
            onChange={this.handleInputChange}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
          <datalist id={"payment_methods"}>
            {this.createPaymentItems()}
          </datalist>
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Account *</span>
          <input
            list="accounts"
            id={"account"}
            name="account"
            onChange={this.handleInputChange}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
          <datalist id={"accounts"}>
            {this.createAccountItems()}
          </datalist>
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Notes</span>
          <textarea
            id="notes"
            name="notes"
            onChange={this.handleInputChange}
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
            rows={3}
          >
            {this.state.notes}
          </textarea>
        </label>

        {/*
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Submitted By *</span>
          <input
            id="submitted_by"
            onChange={this.handleInputChange}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>
        */}

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Department *</span>
          <input
            list="departments"
            id={"department"}
            name="department"
            onChange={this.handleInputChange}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
          <datalist id={"departments"}>
            {/* <option value={"Marketing"} />
            <option value={"Sales"} />
            <option value={"Accounting"} />
            <option value={"Human Resources"} /> */}
            {this.createDepartmentItems()}
          </datalist>
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Approved by Owner? </span>
          <input
            id={"approved_by_owner"}
            name={'approved-by-owner'}
            onChange={this.handleCheckboxChange}
            type={"checkbox"}
            placeholder={""}
          />
          <br />
          <br />

          <span className="text-gray-400 text-xs">Owner Chargeback? </span>
          <input
            id={"chargeback_to_owner"}
            name={'owner-chargeback'}
            onChange={this.handleCheckboxChange}
            type={"checkbox"}
            placeholder={""}
          />
          <br />
          <br />

          <span className="text-gray-400 text-xs">HST Included? </span>
          <input
            id={"hst"}
            name={'hst-included'}
            onChange={this.handleCheckboxChange}
            type={"checkbox"}
            placeholder={""}
          />
        </label>

      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
        </div>

        {content}

        <div className="h-24 flex flex-row ">
          <button
            onClick={this.handleSubmit}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default NewExpense;
